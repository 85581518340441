import { CollectionViewBase } from "@amzn/api-parity-react-component/lib/models/collections/CollectionViewBase";
import { FeatureCollection } from "./FeatureCollection";
import { FeatureVO } from "../vos/FeatureVO";
import { AwsServiceVO } from "@amzn/api-parity-react-component";

export class FeatureCollectionView extends CollectionViewBase<FeatureVO, FeatureCollection | FeatureCollectionView> {
  byService(service: AwsServiceVO): readonly FeatureVO[] {
    return this.applyFilterAndSort(this.source.byService(service));
  }

  byMarkedAsFrom(service?: AwsServiceVO): readonly FeatureVO[] {
    return this.applyFilterAndSort(this.source.byMarkedAsFrom(service));
  }

  byIsGlobal(isGlobal: boolean): readonly FeatureVO[] {
    return this.applyFilterAndSort(this.source.byIsGlobal(isGlobal));
  }
}
