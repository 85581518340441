import { createSelector, Selector } from "reselect";
import { IResourceParityBootstrapInput } from "@amzn/api-parity-react-component/lib/models/ResourceParityBootstrapInput";
import { bootstrapResourceParityData } from "@amzn/api-parity-react-component/lib/utils/resourceParityGridBootstrap";
import { AwsServiceVO } from "@amzn/api-parity-react-component/lib/models/vos/AwsServiceVO";
import { AwsServiceCollectionView } from "@amzn/api-parity-react-component/lib/models/collections/AwsServiceCollectionView";
import { AwsServiceCollection } from "@amzn/api-parity-react-component/lib/models/collections/AwsServiceCollection";
import { RegionVO } from "@amzn/api-parity-react-component/lib/models/vos/RegionVO";
import { isResourceParityRegion } from "../../../utils/resourceParityUtil";
import { RegionCollectionView } from "@amzn/api-parity-react-component/lib/models/collections/RegionCollectionView";
import { RegionCollection } from "@amzn/api-parity-react-component/lib/models/collections/RegionCollection";
import { ServiceBuildPlanVO } from "@amzn/api-parity-react-component/lib/models/vos/ServiceBuildPlanVO";
import { isApiParityExemptFromBuildPlan } from "@amzn/api-parity-react-component/lib/utils/serviceBuildPlanUtil";
import { ServiceBuildPlanCollectionView } from "@amzn/api-parity-react-component/lib/models/collections/ServiceBuildPlanCollectionView";
import { AwsResourceTypeCollectionView } from "@amzn/api-parity-react-component/lib/models/collections/AwsResourceTypeCollectionView";
import { AwsResourcePropertyParityCollectionView } from "@amzn/api-parity-react-component/lib/models/collections/AwsResourcePropertyParityCollectionView";
import { AwsResourceParityCollectionView } from "@amzn/api-parity-react-component/lib/models/collections/AwsResourceParityCollectionView";
import { AwsResourcePropertyTypeCollectionView } from "@amzn/api-parity-react-component/lib/models/collections/AwsResourcePropertyTypeCollectionView";
import { AwsResourceTypeCollection } from "@amzn/api-parity-react-component/lib/models/collections/AwsResourceTypeCollection";
import { AwsResourceParityCollection } from "@amzn/api-parity-react-component/lib/models/collections/AwsResourceParityCollection";
import { AwsResourcePropertyTypeCollection } from "@amzn/api-parity-react-component/lib/models/collections/AwsResourcePropertyTypeCollection";
import {
  ISingleServiceResourceParityViewModel,
  SingleServiceResourceParityProps
} from "./SingleServiceResourceParity.types";
import { Optional } from "../../../models/types/Optional";
import { AwsResourcePropertyParityCollection } from "@amzn/api-parity-react-component/lib/models/collections/AwsResourcePropertyParityCollection";
import { ServiceBuildPlanCollection } from "@amzn/api-parity-react-component/lib/models/collections/ServiceBuildPlanCollection";

export type PropSelector<R> = Selector<SingleServiceResourceParityProps, R>;

const emptyResources: AwsResourceTypeCollectionView = new AwsResourceTypeCollectionView(
  new AwsResourceTypeCollection()
);

const emptyResourcesParities: AwsResourceParityCollectionView = new AwsResourceParityCollectionView(
  new AwsResourceParityCollection()
);

const emptyProperties: AwsResourcePropertyTypeCollectionView = new AwsResourcePropertyTypeCollectionView(
  new AwsResourcePropertyTypeCollection()
);

const emptyPropertyParities: AwsResourcePropertyParityCollectionView = new AwsResourcePropertyParityCollectionView(
  new AwsResourcePropertyParityCollection()
);

const emptyBuildPlans: ServiceBuildPlanCollectionView = new ServiceBuildPlanCollectionView(
  new ServiceBuildPlanCollection()
);

// region input selector
const dataInputSelector: PropSelector<IResourceParityBootstrapInput> = (props) => props.data;
const serviceIdInputSelector: PropSelector<string> = (props) => props.serviceId;
// endregion

const parsedDataSelector = createSelector(
  dataInputSelector,
  (data) => bootstrapResourceParityData(data)
);

const servicesSelector: PropSelector<AwsServiceCollectionView> = createSelector(
  parsedDataSelector,
  (parsed) => {
    const services: AwsServiceVO[] = parsed.serviceMap.items.map((map) => map.service);
    return new AwsServiceCollectionView(
      new AwsServiceCollection(services)
    );
  }
);

export const selectedServiceSelector: PropSelector<Optional<AwsServiceVO>> = createSelector(
  parsedDataSelector,
  servicesSelector,
  serviceIdInputSelector,
  (
    parsed,
    services,
    serviceId,
  ) => {
    const service = services.getById(serviceId);
    if (!service) {
      return;
    }
    // return the service only when it is mapped
    if (parsed.serviceMap.items.find((item) => item.service.id === service.id)) {
      return service;
    }
    return;
  }
)

export const regionsSelector: PropSelector<RegionCollectionView> = createSelector(
  parsedDataSelector,
  (parsed) => {
    const regions: RegionVO[] = parsed.regions.items.filter(isResourceParityRegion);
    return new RegionCollectionView(
      new RegionCollection(regions)
    );
  }
);

export const nonGaBuildPlansSelector: PropSelector<ServiceBuildPlanCollectionView> = createSelector(
  parsedDataSelector,
  selectedServiceSelector,
  regionsSelector,
  (
    parsed,
    selectedService,
    regions,
  ) => {
    if (!selectedService) {
      return emptyBuildPlans;
    }
    return new ServiceBuildPlanCollectionView(parsed.serviceBuildPlans,{
      filter: (plan: ServiceBuildPlanVO) =>
        plan.service.id === selectedService.id
        && regions.hasId(plan.region.id)
        && isApiParityExemptFromBuildPlan(plan),
    });
  }
);

export const resourcesSelector: PropSelector<AwsResourceTypeCollectionView> = createSelector(
  parsedDataSelector,
  selectedServiceSelector,
  (
    parsed,
    selectedService,
  ) => {
    if (!selectedService) {
      return emptyResources;
    }
    return new AwsResourceTypeCollectionView(
      parsed.resourceTypes, {
        filter: (resource) => resource.service.id === selectedService.id
      }
    );
  }
);

export const resourceParitiesSelector: PropSelector<AwsResourceParityCollectionView> = createSelector(
  parsedDataSelector,
  regionsSelector,
  selectedServiceSelector,
  (
    parsed,
    regions,
    selectedService
  ) => {
    if (!selectedService) {
      return emptyResourcesParities
    }
    return new AwsResourceParityCollectionView(parsed.resourceParities, {
      filter: (parity) => parity.resource.service.id === selectedService.id
      && regions.exists(parity.region)
    });
  }
);

export const propertyTypesSelector: PropSelector<AwsResourcePropertyTypeCollectionView> = createSelector(
  parsedDataSelector,
  selectedServiceSelector,
  (
    parsed,
    selectedService,
  ) => {
    if (!selectedService) {
      return emptyProperties;
    }
    return new AwsResourcePropertyTypeCollectionView(parsed.propertyTypes, {
      filter: (property) => property.resource.service.id === selectedService.id
    });
  }
);

export const propertyParitiesSelector: PropSelector<AwsResourcePropertyParityCollectionView> = createSelector(
  parsedDataSelector,
  regionsSelector,
  selectedServiceSelector,
  (
    parsed,
    regions,
    selectedService,
  ) => {
    if (!selectedService) {
      return emptyPropertyParities;
    }
    return new AwsResourcePropertyParityCollectionView(parsed.propertyParities, {
      filter: (parity) => parity.property.resource.service.id === selectedService.id
        && regions.exists(parity.region)
    });
  }
);

export const viewModelSelector: PropSelector<ISingleServiceResourceParityViewModel> = createSelector(
  selectedServiceSelector,
  regionsSelector,
  resourcesSelector,
  resourceParitiesSelector,
  propertyTypesSelector,
  propertyParitiesSelector,
  nonGaBuildPlansSelector,
  (
    service,
    regions,
    resources,
    resourceParities,
    propertyTypes,
    propertyParities,
    nonGaBuildPlans,
  ) => {
    return {
      service,
      regions,
      resources,
      resourceParities,
      propertyTypes,
      propertyParities,
      nonGaBuildPlans,
    };
  }
);
