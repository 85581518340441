import * as React from "react";
import { IServiceInRegionHistory } from "../../daos/types/IServiceInRegionHistory";
import { hasAnyAttributes } from "../../utils/objectUtil";
import { toShortDate } from "../../utils/dateUtil";
import { PlanConfidence } from "../PlanConfidence/PlanConfidence";
import { UserAbilities } from "../../models/UserAbilities";
import { Table } from "@amzn/awsui-components-react";
import { displayOr } from "../../utils/displayUtil";

const RELEVANT_HISTORY_ATTRIBUTES = ["disposition", "note", "confidence", "date"]

export interface IColumnDefinitionWithAbility<T> extends Table.ColumnDefinition<T> {
  canReadDates?: boolean;
}


//this function can sort history that is missing versions
export function getSortedHistory(history: IServiceInRegionHistory[]): IServiceInRegionHistory[] {
  //shallow clone
  let sortedHistory: IServiceInRegionHistory[] = [...history];

  sortedHistory.sort((a,b) => {return a.versionInstance - b.versionInstance})

  return sortedHistory
}


export function isLaunchDateChanged(oldVersion: IServiceInRegionHistory, newVersion: IServiceInRegionHistory) {
  return oldVersion?.date !== newVersion?.date;
}


export function isNoteChanged(oldVersion: IServiceInRegionHistory, newVersion: IServiceInRegionHistory) {
  return oldVersion?.note !== newVersion?.note;
}


export function isConfidenceChanged(oldVersion: IServiceInRegionHistory, newVersion: IServiceInRegionHistory) {
  return oldVersion?.confidence !== newVersion?.confidence;
}


/**
 * Return a list of history where date, note, or confidence has changed
 * @param history
 */
export function getRelevantHistoryVersions(history: IServiceInRegionHistory[]) : IServiceInRegionHistory[]{
  if (history.length === 0){
    return []
  }

  // sort history
  let sortedHistory: IServiceInRegionHistory[] = getSortedHistory(history);

  // add the first version to the history as our starting record
  let relevantHistory: IServiceInRegionHistory[] = [sortedHistory[1]]

  // iterate through sortedHistory starting at 2nd version, probably versionInstance 2 unless we're missing data or paginating
  for (let newVersionInstance = 2; newVersionInstance < sortedHistory.length; newVersionInstance++){
    let oldVersion: IServiceInRegionHistory = sortedHistory[newVersionInstance - 1]
    let newVersion: IServiceInRegionHistory = sortedHistory[newVersionInstance]

    if (isLaunchDateChanged(oldVersion, newVersion) || isNoteChanged(oldVersion, newVersion) ||
        isConfidenceChanged(oldVersion, newVersion)){

      relevantHistory.push(newVersion);
    }
  }

  return relevantHistory
}

export const filterServiceInRegionHistory = (serviceInRegionHistory: IServiceInRegionHistory[]): IServiceInRegionHistory[] => {
  return serviceInRegionHistory.filter(serviceInRegion => hasAnyAttributes(serviceInRegion, RELEVANT_HISTORY_ATTRIBUTES))
}

export function getColumnDefinitions(abilities: UserAbilities): Table.ColumnDefinition<IServiceInRegionHistory>[] {
  const definitions: IColumnDefinitionWithAbility<IServiceInRegionHistory>[] = [
    {
      id: "updated",
      header: "Date Modified",
      cell: (history: IServiceInRegionHistory) => {
        return (
          <div className="awsui-util-t-l">
            {toShortDate(history.updated, "-")}
          </div>
        );
      }
    },
    {
      id: "date",
      header: "Service Team’s Previous Launch Dates",
      cell: (history: IServiceInRegionHistory) => {
        const priorDateOrDisposition = displayOr(history.disposition, () => history.disposition as string, toShortDate(history.date, "-"));

        return (
          <div className="awsui-util-t-l">
            { priorDateOrDisposition }
          </div>
        );
      },
    },
    {
      id: "confidence",
      header: "Prior Confidence",
      cell: (planHistory) => (
        <PlanConfidence plan={planHistory}/>
      ),
    },
    {
      id: "description",
      header: "Prior Note",
      cell: ({ note }) => <div className="awsui-util-t-l">{ displayOr(note) }</div>,
      canReadDates: abilities.canReadDates,
    },
    {
      id: "modifiedBy",
      header: "Modified By",
      cell: ({ updater }) => (
        <div className="awsui-util-t-l">{ displayOr(updater) }</div>
      ),
    },
  ];

  return definitions.filter(columnDefinition => columnDefinition.canReadDates !== false);
}
