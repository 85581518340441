import { useEffect, useState } from "react";

export interface IWindowSize {
  width: number;
  height: number;
}

export const defaultInitialWindowSize: Readonly<IWindowSize> = Object.freeze({
  width: 100,
  height: 100,
});

function getCurrentWindowSize(): IWindowSize {
  return {
    width: window.innerWidth,
    height: window.innerHeight,
  };
}

export function useWindowSize() {
  const [windowSize, setWindowSize] = useState<IWindowSize>(getCurrentWindowSize());

  // windows resize hooks
  useEffect(() => {
    function handleResize() {
      setWindowSize(getCurrentWindowSize());
    }
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []); // Empty array ensures that effect is only run on mount

  return windowSize;
}
