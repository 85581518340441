import * as React from "react";
import { ProductVO } from "../models/vos/ProductVO";

export interface IProductContext {
  product: ProductVO;
  setProduct: React.Dispatch<React.SetStateAction<ProductVO>>;
}

// provide a React context provider to serve
//  a product and product state dispatch function
const ProductContext = React.createContext<IProductContext>(
  { product: new ProductVO(), setProduct: () => {} }
);

export function useProductContext() {
  return React.useContext<IProductContext>(ProductContext);
}

export default ProductContext;
