import { IChangeDto } from "./types/IChangeDto";
import { getALMApiClient, PaginationResponse } from "./daoUtil";
import { decodeAllStringFields } from "../parsers/parseDecodeHelpers";

export class ChangeDao {
  async getChanges(resourceId: string, maxResults?: number, nextToken?: string): Promise<PaginationResponse<IChangeDto>> {
    const client = getALMApiClient()
    const urlParams = new URLSearchParams();

    maxResults && urlParams.append("MaxResults", encodeURIComponent(maxResults));
    nextToken && urlParams.append("NextToken", nextToken);

    const res = await client.get<PaginationResponse<IChangeDto>>(`/api/changes/${encodeURIComponent(resourceId)}?${urlParams.toString()}`);

    return decodeAllStringFields(res.data);
  }
}
