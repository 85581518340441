import * as React from "react";
import * as awsui from "@amzn/awsui-design-tokens/polaris";
import { ServiceInRegionLinkViaService } from "../common/Links";
import { getRegionCode } from "../../utils/regionUtil";
import styled from "styled-components";
import { toShortDate } from "../../utils/dateUtil";
import { IRegionMetadata } from "../../daos/types/IRegionMetaData";

const RegionLaunchDate = styled("span")`
  display: inline-block;
  margin-left: 0.25rem;
  color: ${awsui.colorTextBodySecondary};
`;

const PlanLink = styled("span")`
  display: inline-block;
  min-width: 3.25rem;
`;

export const PlanRegionTestId = {
  launchDate: "PlanRegion-launchDate"
}

export interface RegionProp {
  region: IRegionMetadata;
  nameRip: string;
}

export const Region: React.FunctionComponent<RegionProp> = (props) => {
  const { region, nameRip } = props;
  const regionCode = getRegionCode(region);
  return (
    <PlanLink>
      <ServiceInRegionLinkViaService
        regionName={regionCode}
        serviceName={nameRip}
      >
        {regionCode}
      </ServiceInRegionLinkViaService>
    </PlanLink>
  )
}


export interface RegionLaunchProp {
  region: IRegionMetadata;
}

export const RegionLaunch: React.FunctionComponent<RegionLaunchProp> = ( props ) => {
  const { region } = props;
  const hasLaunchDate = !!region.date;
  if (hasLaunchDate) {
    return (<RegionLaunchDate data-testid={PlanRegionTestId.launchDate}>{toShortDate(region.date)}</RegionLaunchDate>)
  }

  return <React.Fragment />
}
