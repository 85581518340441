import React, { FunctionComponent } from "react";
import { ServiceContactsVO } from "../../../../models/vos/ServiceContactsVO";
import { IBulkEditFormField } from "./BulkEditServiceContactWizard.types"
import { Pagination, Table } from "@amzn/awsui-components-react-v3";
import styled from "styled-components"
import { contactChanges } from "./BulkEditContactWizardUtils";
import { useCollection } from "@amzn/awsui-collection-hooks";

const tableHeaderSize = 112
const tableRowSize = 40
const pageSize = 5

const StyledContainer = styled("div")`
  height: ${tableHeaderSize + (tableRowSize * pageSize)}px;
`;

export interface BulkEditChangesTableProps {
  watchChanges: any;
  existingValues: ServiceContactsVO[];
  editableFields: IBulkEditFormField[];
}

export interface IServiceContactChanges {
  serviceName: string;
  attribute: string;
  previousValue: string;
  newValue: string;
}

export const BulkEditChangesTableTestId = {
  table: "BulkEditChangesTableTestId-table"
}

export const columnDefinitions = [
  {
    id: "serviceName",
    header: "Service name",
    cell: ({ serviceName }) => serviceName,
  },
  {
    id: "attribute",
    header: "Attribute",
    cell: ({ attribute }) => attribute,
  },
  { 
    id: "previousValue", 
    header: "Prev Value", 
    cell: ({ previousValue }) => previousValue 
  },
  {
    id: "newValue",
    header: "New Value",
    cell: ({ newValue }) => newValue
  }
]

export const BulkEditChangesTable: FunctionComponent<BulkEditChangesTableProps> = (props) => {
  const displayItems = contactChanges(props.existingValues, props.editableFields, props.watchChanges);

  const { items, paginationProps } = useCollection(
    displayItems,
    {
      pagination: { pageSize: 5 },
    }
  );

  return (
    <StyledContainer>
      <Table
        data-testid={BulkEditChangesTableTestId.table}
        pagination={<Pagination {...paginationProps} />} 
        //@ts-ignore
        columnDefinitions={columnDefinitions} items={items}>
      </Table>
    </StyledContainer>
  )
}