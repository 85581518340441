import styled, { css } from "styled-components";
import * as awsui from "@amzn/api-parity-react-component/lib/components/design-tokens"

const messageContainer = css`
  margin: ${awsui.Space.medium} ${awsui.Space.large};
  padding-bottom: ${awsui.Space.large};
`;

export const DataLoaderContainer = styled("div")`
  ${messageContainer};
`;

export const NoDataContainer = styled("div")`
  ${messageContainer};
  color: ${awsui.colorTextBodySecondary};
`;

export const ApiViewContainer = styled("div")`
  // This allows time to be absolutely position
  position: relative;
`;

export const DateFreshness = styled("div")`
  position: absolute;
  z-index: 2;
  color: ${awsui.colorTextBodySecondary};
  right: 2rem;
  top: 2rem;
`
