import * as React from "react";
import { FunctionComponent, useMemo } from "react";
import { useParams } from "react-router-dom";
import { Container, Tabs, TabsProps } from "@amzn/awsui-components-react-v3";
import { AccessibleSingleServiceExpansionContainer } from "./SingleServiceExpansion/SingleServiceExpansionContainer";
import { AccessibleSingleServiceContactContainer } from "./SingleServiceContact/SingleServiceContactContainer";
import { NoAccessError, NoAccessErrorTestId } from "../../components/common/NoAccessError";
import { UserAbilities } from "../../models/UserAbilities";
import { useUserPermission } from "../../hooks/useUserPermission";
import { SingleServiceHeaderContainer } from "./SingleServiceHeader/SingleServiceHeaderContainer";
import { SingleServiceFeatureParityContainer } from "./SingleServiceFeatureParity/SingleServiceFeatureParityContainer";
import { SingleServiceApiParity } from "./SingleServiceApiParity/SingleServiceApiParity";
import { ENABLE_RESOURCE_PARITY } from "../../constants";
import { SingleServiceResourceParityContainer } from "./SingleServiceResourceParity/SingleServiceResourceParityContainer";
import { StringParam, useQueryParams, withDefault } from "use-query-params";
import { useCallback } from "react";
import { NonCancelableCustomEvent } from "@amzn/awsui-components-react-v3/polaris/internal/events";
import { fixFormatServiceId } from "../../utils/urlUtil";

export const SingleServiceContainerTestId = {
  header:  "SingleServiceContainer-header",
  noAccess: NoAccessErrorTestId.flash,
}

/**
 * Constants used to enumerate all the view ids
 */
export const SingleServiceViewId = Object.freeze({
  expansion: "expansion",
  contact: "contact",
  apiParity: "api-parity",
  featureParity: "feature-parity",
  resourceParity: "resource-parity",
});
export const defaultViewId = SingleServiceViewId.expansion;

export interface ISingleServiceContainerPathParam {
  serviceName: string;
}

export interface IAccessibleSingleServiceContainerProps {
  serviceId: string;
  userAbilities: UserAbilities;
}

// This view is only for unified ux
export const SingleServiceContainer: FunctionComponent = () => {
  const { serviceName } = useParams<ISingleServiceContainerPathParam>();

  const userAbilities: UserAbilities = useUserPermission(serviceName);
  const canAccess = !!userAbilities.canRead;
  return canAccess ?
    <AccessibleSingleServiceContainerNew
      serviceId={serviceName}
      userAbilities={userAbilities}
    />
    : <NoAccessError />;
}

export const AccessibleSingleServiceContainerNew: FunctionComponent<IAccessibleSingleServiceContainerProps> = (props): JSX.Element => {
  const {
    serviceId,
    userAbilities,
  } = props;


  const [pageParams, setPageParams] = useQueryParams({
    view: withDefault(StringParam, defaultViewId),
  });

  const viewTab_onChange = useCallback((evt: NonCancelableCustomEvent<TabsProps.ChangeDetail>) => {
    const tabId = evt.detail.activeTabId;
    setPageParams({
      view: tabId,
    });
  }, [setPageParams]);

  const parityHeight = "calc(100vh - 27rem)";

  const tabs: TabsProps.Tab[] = useMemo(() => {
    const result: TabsProps.Tab[] = [
      {
        label: "Expansion Tracking",
        id: SingleServiceViewId.expansion,
        content: <AccessibleSingleServiceExpansionContainer
          key={serviceId}
          serviceId={serviceId}
          userAbilities={userAbilities}
          showHeader={false}
        />
      },
      {
        label: "Contacts",
        id: SingleServiceViewId.contact,
        content: <AccessibleSingleServiceContactContainer
          key={serviceId}
          serviceId={serviceId}
          userAbilities={userAbilities}
          showHeader={false}
        />
      },
      {
        label: "Feature Parity",
        id: SingleServiceViewId.featureParity,
        content: <Container disableContentPaddings={true}>
          <SingleServiceFeatureParityContainer serviceId={serviceId} />
        </Container>
      },
      {
        label: "API Parity",
        id: SingleServiceViewId.apiParity,
        content: <Container disableContentPaddings={true}>
          <SingleServiceApiParity constraintHeight={parityHeight} serviceId={serviceId} />
        </Container>
      },
    ];

    if (ENABLE_RESOURCE_PARITY) {
      result.push(
        {
          label: "Resource Parity",
          id: SingleServiceViewId.resourceParity,
          content: <Container disableContentPaddings={true}>
            <SingleServiceResourceParityContainer constraintHeight={parityHeight} serviceId={serviceId} />
          </Container>
        }
      )
    }

    return result;
  }, [serviceId, userAbilities]);

  return <React.Fragment>
    <SingleServiceHeaderContainer data-testid={SingleServiceContainerTestId.header} serviceId={fixFormatServiceId(props.serviceId)} />
    <Tabs tabs={tabs} activeTabId={pageParams.view} onChange={viewTab_onChange} />
  </React.Fragment>
}
