import { QaItemDefinition } from "../models/QaItemDefinition";
import * as read from "./abilityItems/abilityRead";
import * as readDate from "./abilityItems/abilityReadDate";
import * as noAbility from "./abilityItems/noAbility";

export const contactMaintainerQaItems: QaItemDefinition<any>[] = [
  read.listRegionHasItems,
  readDate.listRegionHasNoGaRegionAndDates,
  read.listServiceHasItems,
  noAbility.cannotUpdateServicePlan,
  noAbility.cannotUpdateBusinessCaseOverride,

  // TODO: Update to noAbility to update estimated date
  // write.updateEstimatedDate,
  noAbility.cannotRemoveEstimatedDate,
];
