import React, { FunctionComponent } from "react";
import {
  Button,
  Container,
  DatePicker,
  DatePickerProps,
  FormField,
  Grid,
  Header,
  SelectProps,
  Textarea
} from "@amzn/awsui-components-react-v3";
import { Controller } from "react-hook-form";
import styled from "styled-components";
import { Attribute } from "@amzn/api-parity-react-component/lib/components/attribute/Attribute";
import { SelectController } from "../../SelectController/SelectController";
import { enumToSelectOptions } from "../../../../ProductLaunches/LaunchWizard/LaunchWizardFormFields";
import { RemoveChoice } from "../../../EditLaunch/EditLaunchFormFields";
import { LaunchReviewLevel, LaunchReviewStatus } from "../../EditLaunchDocReviews";
import { LaunchDocumentReviewFormProps } from "./LaunchDocumentReviewForm.types";
import { REQUIRED } from "../../../../IndividualFormFieldWithController/FormConstants";
import { getDataTestIdProp } from "../../../../../utils/testUtil";

export const LaunchDocumentReviewFormTestIdSuffixes = {
  removeButton: "-removeButton",
  dateForm: "-dateForm",
  dateInput: "-dateInput",
  textForm: "-textForm",
  textInput: "-textInput",
}

const MaxWidthDatePicker = styled(DatePicker)<DatePickerProps>`
  max-width: unset !important;
`;

export const LaunchDocumentReviewForm: FunctionComponent<LaunchDocumentReviewFormProps> = (props) => {
  const { review, remove, root, control, errors } = props;

  return (
    <Container
      header={
        <Header
          actions={
            <Button
              variant="normal"
              onClick={() => remove()}
              {...getDataTestIdProp(props, LaunchDocumentReviewFormTestIdSuffixes.removeButton)}
            >
              Remove review
            </Button>
          }
        >
          Review
        </Header>
      }
    >
      <Grid
        gridDefinition={[
          { colspan: { default: 12, xxs: 4 } }, { colspan: { default: 12, xxs: 4 } }, {
            colspan: {
              default: 12,
              xxs: 4
            }
          },
          { colspan: { default: 12, xxs: 12 } }
        ]}
      >
        <SelectController
          id={`${root}.level`}
          control={control}
          label="Review level"
          options={enumToSelectOptions({ ...RemoveChoice, ...LaunchReviewLevel })}
          rules={{
            validate: (value: SelectProps.Option) => value.value !== "" || "Required"
          }}
          defaultValue={review.level}
          error={errors?.level?.message}
        />
        <Controller
          name={`${root}.date`}
          control={control}
          defaultValue={review.date}
          rules={REQUIRED}
          render={(
            { onChange, onBlur, value, ref },
            { invalid }
          ) => (
            <FormField
              stretch
              label={<Attribute label="Meeting"/>}
              errorText={(invalid && errors?.date?.message)}
              {...getDataTestIdProp(props, LaunchDocumentReviewFormTestIdSuffixes.dateForm)}
            >
              <MaxWidthDatePicker
                onChange={({ detail }) => onChange(detail.value)}
                onBlur={onBlur}
                value={value}
                key={`${root}.dateDate`}
                placeholder="YYYY/MM/DD"
                nextMonthAriaLabel="Next month"
                previousMonthAriaLabel="Previous month"
                todayAriaLabel="Today"
                {...getDataTestIdProp(props, LaunchDocumentReviewFormTestIdSuffixes.dateInput)}
              />
            </FormField>
          )}
        />
        <SelectController
          id={`${root}.status`}
          control={control}
          label="Status"
          options={enumToSelectOptions({ ...RemoveChoice, ...LaunchReviewStatus })}
          rules={{
            validate: (value: SelectProps.Option) => value.value !== "" || "Required"
          }}
          defaultValue={review.status}
          error={errors?.status?.message}
        />
        <Controller
          name={`${root}.notes`}
          control={control}
          defaultValue={review.notes}
          render={(
            { onChange, onBlur, value, ref },
            { invalid }
          ) => (
            <FormField
              label={<Attribute label={<span>Notes <i>- optional</i></span>}/>}
              errorText={(invalid && errors?.notes?.message)}
              {...getDataTestIdProp(props, LaunchDocumentReviewFormTestIdSuffixes.textForm)}
            >
              <Textarea
                ariaRequired
                onChange={({ detail }) => onChange(detail.value)}
                onBlur={onBlur}
                value={value}
                ref={ref}
                invalid={invalid}
                {...getDataTestIdProp(props, LaunchDocumentReviewFormTestIdSuffixes.textInput)}
              />
            </FormField>
          )}
        />
      </Grid>
    </Container>
  );
}
