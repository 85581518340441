import * as React from "react";
import { useLocation } from "react-router-dom";
import { Link, SpaceBetween } from "@amzn/awsui-components-react-v3";
import { SinglePageHeader } from "../../components/common/SinglePageHeader";
import { FAQ_URL, RMS_URL_DIMENSIONS } from "../../constants";
import ServiceAvailabilityReport from "../../components/Reports/ServiceAvailabilityReport";
import { ServiceByRegionReport } from "../../components/Reports/ServiceByRegionReport";
import useBreadcrumbs from "../../components/Breadcrumbs/useBreadcrumbs";
import { NoAccessError } from "../../components/common/NoAccessError";
import { getAppState } from "../../models/ApplicationState";
import BlueprintConflictsReport from "../../components/Reports/BlueprintConflictsReport";
import { ServiceByRegionReportDao } from "../../daos/ServiceByRegionReportDao";
import { ServiceAvailabilityReportDao } from "../../daos/ServiceAvailabilityReportDao";

const serviceByReportDao: ServiceByRegionReportDao = new ServiceByRegionReportDao();
const downloadServiceByReportDelegate = serviceByReportDao.getServiceByRegionReport.bind(serviceByReportDao);

const serviceAvailabilityReportDao = new ServiceAvailabilityReportDao();
const downloadServiceAvailabilityReportDelegate = serviceAvailabilityReportDao.handleServiceAvailabilityReport.bind(serviceAvailabilityReportDao);

export const ReportsContainer = (): JSX.Element => {
  useBreadcrumbs([ "Reports" ]);
  const userAbilities = getAppState().userAbilities;
  const location = useLocation();
  const { pathname } = location;
  const headerText = "Reports";
  const subtext = (
    <>
      Please see our{" "}
      <Link external={true} href={FAQ_URL}>FAQ</Link>
      {" "}
      for questions regarding reports.
    </>
  );

  if (userAbilities.canViewReports) {
    return (
      <>
        <SinglePageHeader
          headerText={headerText}
          subText={subtext}
          href={`${RMS_URL_DIMENSIONS}${pathname}`}
        />
        <ServiceAvailabilityReport onReport={downloadServiceAvailabilityReportDelegate} />

        {/*TODO: Convert ServiceAvailabilityReport to Polaris V3 so it can be*/}
        {/*put within SpaceBetween for more consistent layout*/}
        
        <SpaceBetween size={"xl"} direction={"vertical"}>
          <ServiceByRegionReport onReport={downloadServiceByReportDelegate} />
          <BlueprintConflictsReport />
        </SpaceBetween>
      </>
    );
  }
  return <NoAccessError/>
};
