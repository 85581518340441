import React, { FunctionComponent, useEffect, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import useSWR from "swr";
import { Alert, Link } from "@amzn/awsui-components-react-v3";
import { AxiosResponse } from "axios";
import {
  EditLaunchDocReviews,
  EditLaunchDocumentsFormValues,
  LaunchDocumentType,
  LaunchReviewLevel,
  LaunchReviewStatus
} from "../../components/LaunchDetails/EditLaunchDocReviews/EditLaunchDocReviews";
import { MappedLaunchDocument } from "../../components/LaunchDetails/EditLaunchDocReviews/EditLaunchDocReviews.types";
import { ILaunchDocument } from "../../daos/types/ILaunchDto";
import { LaunchDao } from "../../daos/LaunchDao";
import { launchDtoToVO } from "../../parsers/launchParser";
import { LaunchVO } from "../../models/vos/LaunchVO";
import { useProduct } from "../../utils/productUtil";
import { getAppState } from "../../models/ApplicationState";
import { REPORT_A_BUG_URL } from "../../constants";
import { NoAccessError } from "../../components/common/NoAccessError";
import { useLaunchContext } from "../../contexts/LaunchContext";
import { useProductContext } from "../../contexts/ProductContext";
import { ILocationState } from "../../models/LocationState";
import { ProductVO } from "../../models/vos/ProductVO";
import {
  EditLaunchErrorAlerts
} from "../../components/LaunchDetails/EditLaunch/EditLaunchErrorAlerts/EditLaunchErrorAlerts.helpers";
import useTitleBanner from "../../components/unified/TitleBanner/useTitleBanner";
import { getSwrConfig } from "../../daos/swrUtil";

const emptyValue = { label: "-", value: "" };

export interface IEditLaunchDocReviewsContainerParam {
  launchId: string;
}

export const EditLaunchDocReviewsContainer: FunctionComponent = () => {
  const { launchId } = useParams<IEditLaunchDocReviewsContainerParam>();

  const [documents, setDocuments] = useState<MappedLaunchDocument[]>([]);
  const [loaded, setLoaded] = useState(false);
  const [formError, setFormError] = useState({ status: 200, text: {} });
  const [validatingSubmit, setValidatingSubmit] = useState(false);
  const { launch, setLaunch } = useLaunchContext();
  const { product, setProduct } = useProductContext();

  const userAbilities = getAppState().userAbilities;
  const history = useHistory();
  const location = useLocation<ILocationState>();

  const {
    data: launchData,
    error,
    isValidating: isValidatingLaunch
  } = useSWR<LaunchVO>(() => `launch/${launchId}`, async () => {
    const dao = new LaunchDao();
    const dto = await dao.getLaunch(launchId);

    return launchDtoToVO(dto);
  }, getSwrConfig());

  const { data: productData } = useProduct(launchData?.productId);

  const mapValue = (value: string | undefined, valueEnum) => {
    return value && value in valueEnum
      ? { label: valueEnum[value], value: value }
      : { label: value, value: value } || emptyValue
  }

  const setTitleBanner = useTitleBanner({ visible: true });

  useEffect(() => {
    setTitleBanner({
      visible: true,
      title: launch?.name,
      details: {
        info: launch?.description
      }
    });
  }, [launch?.description, launch?.name, setTitleBanner]);

  useEffect(() => {
    if (launch.id !== launchId) {
      const newLaunch = launchData
        ?? (location.state?.launch?.id === launchId ? location.state?.launch : null)
        ?? new LaunchVO();

      setLaunch(newLaunch);
    }
  }, [launch.id, launchData, launchId, location.state?.launch, setLaunch])

  useEffect(() => {
    if (product.id !== launch?.productId) {
      const newProduct = productData
        ?? (location.state?.product?.id === launch?.productId ? location.state?.product : null)
        ?? new ProductVO();

      setProduct(newProduct);
    }
  }, [launch?.productId, location.state?.product, product.id, productData, setProduct])

  useEffect(() => {
    if (!loaded && launchData?.docReviews) {
      const newDocuments: MappedLaunchDocument[] = launchData.docReviews.map((document) => ({
        docReviewType: mapValue(document.docReviewType, LaunchDocumentType),
        docLink: document.docLink,
        reviews: document.reviews?.map((review) => ({
          level: mapValue(review.level, LaunchReviewLevel),
          date: review.date,
          status: mapValue(review.status, LaunchReviewStatus),
          notes: review.notes,
        }))
      }));

      setDocuments(newDocuments);
      setLoaded(true);
    }
  }, [launchData, loaded]);

  const returnToRootPage = () => {
    const queryParams = new URLSearchParams(location.search);
    queryParams.set("tabId", "docs_reviews");
    history.push({
      search: queryParams.toString(),
      pathname: `/alm/launches/${launchId}`
    });
  }

  const submitHandler = async (data: EditLaunchDocumentsFormValues) => {
    setValidatingSubmit(true);
    const fixedFormValues: ILaunchDocument[] = data.documents?.map((document) => ({
      docReviewType: document.docReviewType?.value!,
      docLink: document.docLink,
      reviews: document.reviews?.map((review) => ({
        level: review.level?.value,
        date: review.date,
        status: review.status?.value,
        notes: review.notes,
      }))
    }));

    const dao = new LaunchDao();

    const response: AxiosResponse = await dao.updateLaunch(
      launchId,
      { docReviews: fixedFormValues }
    );

    setValidatingSubmit(false);
    if (response.status === 200 || response.status === 201) {
      setLaunch(new LaunchVO()); // clear the loaded state.
      returnToRootPage();
    } else {
      setFormError({ status: response.status, text: response.data });
    }
  }

  // cancelling just sends us back to root page for launch
  const cancelHandler = () => returnToRootPage()

  if (true) {
    if (error && !isValidatingLaunch) {
      return (
        <Alert
          type="error"
          header="Data Fetching Error"
        >
          {"There was an error fetching launch documents & reviews, please try again later or submit a "}
          <Link
            external
            externalIconAriaLabel="Opens in a new tab"
            href={REPORT_A_BUG_URL}
          >
            ticket
          </Link>.
        </Alert>
      )
    }
    return (
      <>
        {EditLaunchErrorAlerts[formError.status] &&
        EditLaunchErrorAlerts[formError.status](formError.text)}
        <EditLaunchDocReviews
          loading={!launchData && isValidatingLaunch}
          docReviews={documents}
          onSubmit={submitHandler}
          onCancel={cancelHandler}
          validatingSubmit={validatingSubmit}
        />
      </>
    );
  }
  return <NoAccessError/>
}
