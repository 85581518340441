import * as React from "react";
import { FunctionComponent, useCallback, useState } from "react";
import { EditGmVpProps } from "./EditGmVp.types";
import { Button, Flashbar, FormField, Input, Modal } from "@amzn/awsui-components-react-v3";
import { DialogFooterButtonBar } from "../../common/Dialog/DialogFooterButtonBar";
import { Controller, useForm } from "react-hook-form";
import { IServiceLeaderUpdate } from "../../../models/delegates/UpdateServiceLeaderDelegate";
import { VALID_ALIAS_CHAR_REGEX_AND_MAXLENGTH } from "../EnterServiceContact/AddOrEditServiceContactModal/AddOrEditServiceContactModalConstants";
import styled from "styled-components";
import { ServiceContactUpdateError } from "../../../orchestrators/ServiceContactOrchestrator";
import { FlashbarProps } from "@amzn/awsui-components-react-v3/polaris/flashbar";
import { getUpdateError } from "../serviceContactUtil";
import { PermissionError } from "../../common/PermissionError";
import { UpdateVpGmSimTemplateLink } from "../../common/SimLinks/UpdateVpGmSimTemplateLink";
import { ServiceContactsVO } from "../../../models/vos/ServiceContactsVO";
import { Optional } from "../../../models/types/Optional";
import { compareStringIgnorePaddingSpace } from "../../../utils/modelUtil";
import { getDataTestIdProp } from "../../../utils/testUtil";
import { Displayable } from "@amzn/api-parity-react-component/lib/models/Displayable";

export const EditGmVpTestId = {
  editButton: "EditGmVp-edit",
  editDialog: "EditGmVp-dialog",
  cancelButton: "EditGmVp-cancel",
  submitButton: "EditGmVp-submit",
  gmField: "EditGmVp-gmField",
  gmInput: "EditGmVp-gmInput",
  vpField: "EditGmVp-vpField",
  vpInput: "EditGmVp-vpInput",
  updateError: "EditGmVp-updateError",
  permissionError: {
    modal: "EditGmVp-permissionError-modal",
    alert: "EditGmVp-permissionError-alert"
  }
};

export const FormFieldListLayout = styled.div`
  display: grid;
  grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
  grid-column-gap: 1.5rem;
`;


export function hasInputChanged(input: IServiceLeaderUpdate, vo: ServiceContactsVO): boolean {
  return !compareStringIgnorePaddingSpace(input.gm, vo.gm) || !compareStringIgnorePaddingSpace(input.vp, vo.vp);
}

export function getLeaderInput(contact: Optional<ServiceContactsVO>): IServiceLeaderUpdate {
  return {
    gm: contact?.gm ?? "",
    vp: contact?.vp ?? "",
  };
}

export const EditGmVp: FunctionComponent<EditGmVpProps> = (props) => {
  const [showEditForm, setShowEditForm] = useState<boolean>(false);
  const [showPermissionError, setShowPermissionError] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [errorMessages, setErrorMessages] = useState<FlashbarProps.MessageDefinition[]>([]);
  const [modalKey, setModalKey] = useState<string>("1");

  const { onSubmit, contact, permission } = props;
  const { control, errors, trigger, getValues, reset } = useForm<IServiceLeaderUpdate>({
    mode: "onChange", defaultValues: getLeaderInput(contact)
  });

  const edit_onClick = useCallback(() => {
    if (permission.canWriteBusinessCaseOverride) {
      setModalKey(new Date().getTime().toString());
      setShowEditForm(true);
      setErrorMessages([]);
      reset(getLeaderInput(contact));
    } else {
      setShowPermissionError(true);
    }
  }, [
    contact,
    reset,
    permission,
    setShowEditForm,
    setShowPermissionError,
  ]);

  const permissionError_onDismiss = useCallback(() => {
    setShowPermissionError(false);
  }, [setShowPermissionError]);

  const editForm_dismiss = useCallback(() => {
    if (isLoading) {
      return;
    }
    setShowEditForm(false);
  }, [setShowEditForm, isLoading]);

  const editForm_submit = useCallback(async () => {
    if (!contact) {
      return;
    }
    const update: IServiceLeaderUpdate = getValues();
    if (!hasInputChanged(update, contact)) {
      setShowEditForm(false);
      return;
    }
    const isValid: boolean = await trigger();
    if (isValid && onSubmit) {
      setIsLoading(true);
      setErrorMessages([]);
      try {
        await onSubmit(contact, update);
        setIsLoading(false);
        setShowEditForm(false);
      } catch (err) {
        setIsLoading(false);
        const errorDetail: ServiceContactUpdateError = err as ServiceContactUpdateError;
        const message: Displayable = getUpdateError(contact.instance, errorDetail.errors);
        setErrorMessages([
          {
            type: "error",
            content: <div data-testid={EditGmVpTestId.updateError}>{message}</div>
          }
        ]);
      }
    }
  }, [
    setShowEditForm,
    trigger,
    getValues,
    onSubmit,
    contact,
  ]);

  return (
    <React.Fragment>
      <Button
        {...getDataTestIdProp(props, EditGmVpTestId.editButton)}
        disabled={!props.contact}
        onClick={edit_onClick}>Edit GM/VP</Button>
      <PermissionError
        header="Access Denied"
        body={<>
          Need Admin privileges to update service's gm/vp,
          please use {UpdateVpGmSimTemplateLink} to submit an update request.
        </>}
        visible={showPermissionError}
        testIds={EditGmVpTestId.permissionError}
        onDismiss={permissionError_onDismiss}
      />
      <Modal
        key={modalKey}
        data-testid={EditGmVpTestId.editDialog}
        visible={showEditForm}
        onDismiss={editForm_dismiss}
        size={"medium"}
        header={`Edit GM/VP`}
        footer={<DialogFooterButtonBar>
          <Button
            data-testid={EditGmVpTestId.cancelButton}
            loading={isLoading}
            variant="link"
            onClick={editForm_dismiss}>Cancel</Button>
          <Button
            variant="primary"
            loading={isLoading}
            onClick={editForm_submit}
            data-testid={EditGmVpTestId.submitButton}>Submit</Button>
        </DialogFooterButtonBar>}
      >
        <Flashbar items={errorMessages}/>
        <FormFieldListLayout>
          <Controller
            name="gm"
            id="gm"
            control={control}
            rules={VALID_ALIAS_CHAR_REGEX_AND_MAXLENGTH}
            defaultValue={props.contact?.gm}
            render={({ onChange, value }, { invalid }) =>
              <FormField
                label="GM"
                data-testid={EditGmVpTestId.gmField}
                errorText={invalid && errors.gm?.message}>
                <Input
                  data-testid={EditGmVpTestId.gmInput}
                  onChange={(evt) => onChange(evt.detail.value)}
                  value={value}
                  invalid={invalid}
                  placeholder={"Enter alias of GM"}/>
              </FormField>
            }>
          </Controller>

          <Controller
            name="vp"
            id="vp"
            control={control}
            rules={VALID_ALIAS_CHAR_REGEX_AND_MAXLENGTH}
            defaultValue={props.contact?.vp}
            render={({ onChange, value }, { invalid }) =>
              <FormField
                label="VP"
                data-testid={EditGmVpTestId.vpField}
                errorText={invalid && errors.vp?.message}>
                <Input
                  data-testid={EditGmVpTestId.vpInput}
                  onChange={(evt) => onChange(evt.detail.value)}
                  value={value}
                  invalid={invalid}
                  placeholder={"Enter alias of VP"}/>
              </FormField>
            }>
          </Controller>
        </FormFieldListLayout>
      </Modal>
    </React.Fragment>
  );
};
