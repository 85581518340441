import { config, IEnvironmentConfig } from "../configs/environmentConfig";
import { Lookup } from "@amzn/api-parity-react-component/lib/models/Lookup";
import { Optional } from "../models/types/Optional";
import { Stage } from "@amzn/region-services-react-library-v4";

export function getAllConfigs(cfg: Lookup<IEnvironmentConfig> = config): IEnvironmentConfig[] {
  // Object.values doesn't work in IE 11, Opera Mini and Baidu Browser, use polyfill if we run into any issues
  return Object.values(cfg);
}

export function isDevEnv(): boolean {
  const foundConfig: Optional<IEnvironmentConfig> = getConfig();
  return foundConfig?.stage === "default";
}

export function isBetaOrDevEnv(): boolean {
  const foundConfig: Optional<IEnvironmentConfig> = getConfig();
  return foundConfig?.stage === "beta" || isDevEnv();
}

export function isNotProdEnv(): boolean {
  const foundConfig: Optional<IEnvironmentConfig> = getConfig();
  return foundConfig?.stage !== "prod";
}

export function getConfig(url = undefined): Optional<IEnvironmentConfig> {
  const urlToTest: string = url ?? window.location.origin;
  const allConfigs: IEnvironmentConfig[] = getAllConfigs();
  const currentConfig = allConfigs.find((c) => {
    if (!c.urlTest) {
      return false;
    }
    return c.urlTest.test(urlToTest);
  });

  if (!currentConfig) {
    alert(
      `Cannot find environment configuration for ${urlToTest}, this most likely \n has to do with the environment not registered in ./configs/environmentConfigs.js`
    );
  }

  return currentConfig;
}

export function setFavicon(): void {
  const config = getConfig();
  const headTitle = document.querySelector("head");
  const faviconEl = document.createElement("link");
  faviconEl.setAttribute("rel", "shortcut icon");
  faviconEl.setAttribute("href", config?.favicon);
  headTitle?.appendChild(faviconEl);
}

let impersonation;
let impersonationApi;

export function setupImpersonation(): void {
  if (process.env.LOCAL) {
    const impersonateParam = (new URLSearchParams(document.location.search)).get("impersonate");
    if (impersonateParam) {
      [impersonation, impersonationApi] = impersonateParam.split("|");
      if (!impersonationApi) {
        impersonationApi = document.location.origin;
      } else {
        impersonationApi = decodeURIComponent(impersonationApi);
        if (!impersonationApi.startsWith("http")) {
          impersonationApi = "https://" + impersonationApi;
        }
      }
    }
  }
}

export function isImpersonating(): boolean {
  return !!impersonation;
}

export function getImpersonation(): string {
  return impersonation;
}

/**
 * Return the API URL to call that supports impersonation
 */
export function getImpersonationApiUrl(): string {
  return impersonationApi;
}


export function getUnifiedStage(config: Optional<IEnvironmentConfig> = getConfig()): Stage {
  return config?.unifiedStage ?? Stage.BETA;
}
