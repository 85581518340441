import { immerable } from "immer";
import { hydrateModel } from "../../utils/modelUtil";

export const REASON_OPTIONS = [
    "Blocked - Missing Service Dependency",
    "Blocked - Missing Feature Dependency",
    "Regulatory Restrictions",
    "Lack of Resources",
    "Lack of Hardware Capacity",
    "Lack of Customer Demand",
    "Cost",
    "Deprecated",
    "Other",
] as const;

export type Reason = typeof REASON_OPTIONS[number];


export class FeatureInRegionCommentVO {
  [immerable]? = true;
  readonly feature!: string;
  readonly service!: string;
  readonly region!: string;
  readonly reason!: Reason;
  readonly sim!: string;
  readonly whatsNewLink?: string;
  readonly note!: string;

  readonly author?: string;
  readonly timestamp?: string;
  readonly resolved?: string;

  constructor(source: Partial<FeatureInRegionCommentVO>) {
    hydrateModel(source, this);
  }
}
