import { Optional } from "./types/Optional";

/**
 * Generic TreeItem model
 */
export class TreeItem {
  protected _childItems?: TreeItem[];
  public get childItems(): Optional<readonly TreeItem[]> {
    return this._childItems;
  };

  parent?: TreeItem;

  addChild(item: TreeItem): void {
    if (!this._childItems) {
      this._childItems = [];
    }
    item.parent = this;
    this._childItems.push(item);
  }


  get parents(): readonly TreeItem[] {
    const result: TreeItem[] = [];
    let cursor: TreeItem = this;
    while (cursor.parent) {
      result.push(cursor.parent);
      cursor = cursor.parent;
    }
    return result;
  }

  /**
   * Depth of a node.  Root node is zero
   */
  get depth(): number {
    return this.parents.length;
  }

}
