import { LaunchVO } from "../../models/vos/LaunchVO";
import { LaunchTableVM } from "./ProductLaunches.types";
import {
  LaunchAudience,
  LaunchDisclosure,
  LaunchGoalType,
  LaunchIteration,
  LaunchScope,
  LaunchState,
  LaunchStatus,
  LaunchTier,
  LaunchType
} from "./LaunchWizard/LaunchWizard.types";
import { formatLaunchDate } from "../LaunchDetails/launchDetailsUtils";

export const LaunchVOToLaunchTableVM = (items: LaunchVO[]): LaunchTableVM[] => {
  return items.map((item) => {
    const launch = new LaunchTableVM();

    launch.sourceItem = item;

    launch.date = item.date ? formatLaunchDate(item.date) : undefined;

    launch.id = item.id;
    launch.name = !!item.name ? item.name : "No launch name";
    launch.codename = item.codename;
    launch.ripId = item.ripId;
    launch.productId = item.productId;
    launch.slips = item.slips;
    launch.daysLate = item.daysLate;
    launch.description = item.description;
    launch.trackerLink = item.trackerLink;
    launch.trackerLinkType = item.trackerLinkType;
    launch.goalLink = item.goalLink;
    launch.originalLaunchDate = item.originalLaunchDate;
    launch.previousLaunchDate = item.previousLaunchDate;
    launch.rowId = item.rowId;
    launch.owner = item.contacts?.productManager;
    launch.blueprintUid = item.blueprintUid;

    // Enum processing
    launch.type = item.type ? LaunchType[item.type!] ?? item.type : undefined;
    launch.iteration = item.iteration ? LaunchIteration[item.iteration!] ?? item.iteration : undefined;
    launch.tier = item.tier ? LaunchTier[item.tier] ?? item.tier : undefined;
    launch.scope = item.scope ? LaunchScope[item.scope] ?? item.scope : undefined;
    launch.status = item.status ? LaunchStatus[item.status] ?? item.status : undefined;
    launch.audience = item.audience ? LaunchAudience[item.audience] ?? item.audience : undefined;
    launch.state = item.state ? LaunchState[item.state] ?? item.state : undefined;
    launch.disclosureLevel = item.disclosureLevel ? LaunchDisclosure[item.disclosureLevel] ?? item.disclosureLevel : undefined;
    launch.goal = item.goal ? LaunchGoalType[item.goal] ?? item.goal : undefined;

    // Boolean processing
    launch.confidential = item.confidential ? "Yes" : "No";
    launch.regulated = item.questionnaire?.regulated ? "Yes" : "No";
    launch.pricingChangeInvolved = item.questionnaire?.pricingChangeInvolved ? "Yes" : "No";
    launch.namingInvolved = item.questionnaire?.namingInvolved ? "Yes" : "No";
    launch.prfaqRequired = item.questionnaire?.prfaqRequired ? "Yes" : "No";

    // Array processing
    launch.regions = Array.isArray(item.regions) && item.regions.length > 0
      ? item.regions?.join(", ")
      : undefined;
    launch.tags = Array.isArray(item.tags) && item.tags.length > 0
      ? item.tags?.join(", ")
      : undefined;
    launch.readGroups = Array.isArray(item.readGroups) && item.readGroups.length > 0
      ? item.readGroups?.join(", ")
      : undefined;
    launch.writeGroups = Array.isArray(item.writeGroups) && item.writeGroups.length > 0
      ? item.writeGroups?.join(", ")
      : undefined;

    return launch;
  });
}
