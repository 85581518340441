import styled from "styled-components";
import * as awsui from "@amzn/awsui-design-tokens/polaris";

interface IContentFrameProps {
  width: string;
  height: string;
}

export const legacyDashboardHeight = "calc(100vh - 19rem)";

export const ContentFrame = styled("iframe")<IContentFrameProps>`
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  border: 1px solid ${awsui.colorBorderControlDefault};
`;
export const ContentLayout = styled("div")`
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
`;
export const DashboardControlBar = styled("div")`
  display: flex;  
  flex-direction: row;
  align-items: center;
  gap: 0.25rem;
`;
export const HelpSlot = styled("div")`
  flex-grow:1;
`;
export const ControlSlot = styled("div")`
  flex-grow:0
`;
