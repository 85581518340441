import * as React from "react";
import { useCallback, useEffect, useState } from "react";
import produce from "immer";
import { IDataExportable } from "@amzn/api-parity-react-component/lib/models/IDataExportable";
import { IWindowSize, useWindowSize } from "../../../../../hooks/useWindowSize";
import {
  FeatureParityOverviewGridProps,
  FeatureParityOverviewGridPropsWithUserInput, IFeatureParityOverviewGridUserInput,
  IFeatureParityOverviewGridViewModel
} from "./FeatureParityOverviewGrid.types";
import { viewModelSelector } from "./FeatureParityOverviewGrid.view.model.composer";
import { FeatureTreeItem } from "../../../../../models/FeatureTreeItem";
import { StyledTable, StyledLegend, StyledLegendItem } from "./FeatureParityOverviewGrid.style";
import { viewModelToDataBook } from "./FeatureParityOverviewGrid.export";
import { RowDataType, RowKeyType, SortType  } from "rsuite-table";
import { toSortByDirection, toSortType } from "../../../../../utils/rsuiteUtil";
import { Icon } from "@amzn/awsui-components-react-v3";

export const greenCheck = <Icon variant="success" name="status-positive" />
export const inProgressEllipses = <Icon variant="link" name="status-in-progress" />
export const redX = <Icon variant="error" name="status-negative" />

export const defaultTableHeight: number = 360;
export const minTableHeight: number = 100;

export const FeatureParityOverviewGridComponent: React.ForwardRefRenderFunction<IDataExportable, FeatureParityOverviewGridProps> = (props, forwardedRef) => {
  const {
    overheadHeight
  } = props;

  const initialUserInput: IFeatureParityOverviewGridUserInput = {
    requestedExpandedIds: new Set<string>(),
    requestedCollapsedIds: new Set<string>(),
  };

  const windowSize: IWindowSize = useWindowSize();
  const [tableHeight, setTableHeight] = useState<number>(defaultTableHeight);

  const [userInput, setUserInput] = useState<IFeatureParityOverviewGridUserInput>(initialUserInput);
  const [viewModel, setViewModel] = useState<IFeatureParityOverviewGridViewModel>(viewModelSelector({
    ...props,
    ...initialUserInput,
  }));

  useEffect(() => {
    let tabHeight: number = windowSize.height - (overheadHeight ?? 0);
    tabHeight = Math.max(minTableHeight, tabHeight);
    setTableHeight(tabHeight);
  }, [
    overheadHeight,
    windowSize,
  ])

  useEffect(() => {
    const input: FeatureParityOverviewGridPropsWithUserInput = {
      ...props,
      ...userInput,
    };
    setViewModel(viewModelSelector(input));
  }, [
    userInput,
    props,
  ]);

  // @ts-ignore
  React.useImperativeHandle(forwardedRef, () => {
    return {
      getDataBook: () => viewModelToDataBook(viewModel)
    }
  }, [
    viewModel,
  ]);

  const table_onExpand = useCallback((isOpen: boolean, data: RowDataType) => {
    const rowData  = data as FeatureTreeItem;
    const id: string = rowData.id;
    const newUserInput = produce(userInput, (draft) => {
      if (isOpen) {
        draft.requestedExpandedIds.add(id);
        draft.requestedCollapsedIds.delete(id);
      } else {
        draft.requestedExpandedIds.delete(id);
        draft.requestedCollapsedIds.add(id);
      }
    });
    setUserInput(newUserInput);
  }, [
    userInput,
    setUserInput,
  ]);

  const table_onSort = useCallback((dataKey: string, sortType?: SortType) => {
    const newUserInput = produce(userInput, (draft) => {
      draft.sortKey = dataKey;
      draft.sortDirection = toSortByDirection(sortType);
    });
    setUserInput(newUserInput);
  }, [
    userInput,
    setUserInput,
  ])

  return (
    <React.Fragment>
      <StyledTable
        isTree={true}
        rowKey="id"
        headerHeight={90}
        height={tableHeight}
        data={viewModel.data}
        virtualized={true}
        shouldUpdateScroll={false}
        sortColumn={viewModel.sortKey}
        sortType={toSortType(viewModel.sortDirection)}
        expandedRowKeys={viewModel.expandedIds as RowKeyType[]}
        onExpandChange={table_onExpand}
        onSortColumn={table_onSort}
      >
        {
          viewModel.columns.map((col) => col)
        }
      </StyledTable>
      <StyledLegend columns={6}>
        <StyledLegendItem label="Available" icons={[greenCheck]} />
        <StyledLegendItem label="Not at Parity" icons={[redX]} />
        <StyledLegendItem label="Partial Parity" icons={[inProgressEllipses]} />
        <StyledLegendItem label="Planned" icons={[<div>Pl</div>]} />
        <StyledLegendItem label="Exception - Not Launching" icons={[<div>NL</div>]} />
        <StyledLegendItem label="Exception - ATO" icons={[<div>ATO</div>]} />
      </StyledLegend>
    </React.Fragment>
  );
};

export const FeatureParityOverviewGrid: React.ForwardRefExoticComponent<React.PropsWithoutRef<FeatureParityOverviewGridProps> &  React.RefAttributes<IDataExportable>> =
  React.forwardRef(FeatureParityOverviewGridComponent);
