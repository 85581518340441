import { QaChecker } from "../models/QaChecker";
import { successCallChecker } from "./checkerHelper";
import { getErrorResult, successResult } from "../models/QaItemResult";
import { IGetSingleServicePlanResponseLegacy } from "../../daos/types/IGetSingleServicePlanResponse";
import { IServiceRegionPlanDto } from "../../daos/types/IServiceRegionPlanDto";
import { IServiceInRegionHistory } from "../../daos/types/IServiceInRegionHistory";
import { GENERALLY_AVAILABLE } from "../../constants";

export const servicePlanListHasServiceChecker: QaChecker<IGetSingleServicePlanResponseLegacy> = (val) => {
  return successCallChecker(
    val,
    ( val: IGetSingleServicePlanResponseLegacy ) => {
      return (val.serviceMetadata) ? successResult : getErrorResult("No service are returned")
    },
    "Error calling GET /api/services/.../plans"
  );
}

export const servicePlanListHasPlansChecker: QaChecker<IGetSingleServicePlanResponseLegacy> = (val) => {
  return successCallChecker(
    val,
    ( val: IGetSingleServicePlanResponseLegacy ) => {
      return (val.serviceInstances?.length > 0) ? successResult : getErrorResult("No plans are included")
    },
    "Error calling GET /api/services/.../plans"
  );
}

export function isServicePlanGA(plan: IServiceRegionPlanDto | IServiceInRegionHistory): boolean {
  return plan.status === GENERALLY_AVAILABLE;
}

export function doesServicePlanHaveNote(plan: IServiceRegionPlanDto | IServiceInRegionHistory): boolean {
  return !!plan.note || !!(plan as IServiceRegionPlanDto).blueprintNote;
}

export function doesServicePlanHaveNonGAConfidence(plan: IServiceRegionPlanDto | IServiceInRegionHistory): boolean {
  if (isServicePlanGA(plan)) {
    return false;
  }
  return !!plan.confidence;
}

export function doesServicePlanHaveNonGADate(plan: IServiceRegionPlanDto | IServiceInRegionHistory): boolean {
  if (isServicePlanGA(plan)) {
    return false;
  }
  return !!(plan as IServiceRegionPlanDto).blueprintEstLaunchDate || !!plan.date;
}


export const servicePlanListHasNoNoteChecker: QaChecker<IGetSingleServicePlanResponseLegacy> = (val) => {
  return successCallChecker(
    val,
    ( val: IGetSingleServicePlanResponseLegacy ) => {
      const hasNoNote: boolean = !val.serviceInstances.some(doesServicePlanHaveNote);
      return (hasNoNote) ? successResult : getErrorResult("Note is detected in return data, it should not happen.")
    },
    "Error calling GET /api/services/.../plans"
  );
}

export const servicePlanListHasNoNonGAConfidenceChecker: QaChecker<IGetSingleServicePlanResponseLegacy> = (val) => {
  return successCallChecker(
    val,
    ( val: IGetSingleServicePlanResponseLegacy ) => {
      const hasNoNote: boolean = !val.serviceInstances.some(doesServicePlanHaveNonGAConfidence);
      return (hasNoNote) ? successResult : getErrorResult("Confidence is seen in non GA plan, it should not happen.")
    },
    "Error calling GET /api/services/.../plans"
  );
}

export const servicePlanListHasNoNonGADateChecker: QaChecker<IGetSingleServicePlanResponseLegacy> = (val) => {
  return successCallChecker(
    val,
    ( val: IGetSingleServicePlanResponseLegacy ) => {
      const hasNoNote: boolean = !val.serviceInstances.some(doesServicePlanHaveNonGADate);
      return (hasNoNote) ? successResult : getErrorResult("Date is seen in non GA plan, it should not happen.")
    },
    "Error calling GET /api/services/.../plans"
  );
}
