import { emptyExportValue } from "../components/ExportTableToCSVExcel/ExportTableToCSVExcel";

export const SLICE_SIZE = 512;

/**
 * Convert a Base64-encoded string into chunks of byte arrays.
 *
 * See https://stackoverflow.com/questions/16245767/creating-a-blob-from-a-base64-string-in-javascript
 * for the source of this.  Essentially:
 *     1. atob() to convert BASE64 string into decoded string
 *     2. Use charCodeAt() to convert each character into it's number (code point)
 *     3. Use Uint8Array to turn an array of numbers into a typed array suitable for Blob()
 *
 * @param b64Data base64 formatted data
 * @param chunkSize
 */
export function getByteArrayChunks(b64Data: string, chunkSize: number = SLICE_SIZE): Uint8Array[] {
  const byteCharacters = atob(b64Data);
  const byteArrays: Uint8Array[] = [];

  for (let offset = 0; offset < byteCharacters.length; offset += chunkSize) {
    const slice = byteCharacters.slice(offset, offset + chunkSize);

    const byteNumbers: number[] = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }
  return byteArrays;
}

/*
 * Convert a Base64-encoded string into a JavaScript Blob object.
 */
export const b64toBlob = (b64Data: string, contentType: string = ""): Blob => {
  const parts: Uint8Array[] = getByteArrayChunks(b64Data, SLICE_SIZE);
  return new Blob(parts, { type: contentType });
};

export const formulaEscape = (values: any[]): string[] => {
  const testRegex = /(^[=@+-])/;

  return values.map((value) => value !== emptyExportValue && typeof value === "string" && testRegex.test(value) ? `'${value}` : value);
}
