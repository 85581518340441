import * as React from "react";
import { FunctionComponent, useCallback, useEffect, useState } from "react";
import { SingleServiceApiParityProps } from "./SingleServiceApiParity.types";
import { Optional } from "../../../models/types/Optional";
import {
  IApiParityBootstrapDataInput,
} from "@amzn/api-parity-react-component/lib/models/";
import { ApiParityLoader } from "../../ApiParityLoader/ApiParityLoader";
import {
  IParityGridBootstrapData
} from "@amzn/api-parity-react-component/lib/utils/parityGridBootstrap";
import { IApiViewNoApiSelection } from "@amzn/api-parity-react-component/lib/models/ApiViewSelection";
import { AwsServiceVO } from "@amzn/api-parity-react-component/lib/models/vos/AwsServiceVO";
import { getRecommendedSetting } from "@amzn/api-parity-react-component/lib/components/parity-grid/parityGridUtil";
import { ApiParityByServiceView, bootstrapApiParityData } from "@amzn/api-parity-react-component/lib";
import { setFeature } from "@amzn/api-parity-react-component/lib/feature/featureToggle";
import { ApiViewContainer, DataLoaderContainer, DateFreshness, NoDataContainer } from "./SingleServiceApiParity.style";
import { getTimeAgo } from "../../../utils/dateUtil";
import { legacyParityReportHeight } from "../SingleServiceParity/SingleServiceParityContainer.style";

const setting = getRecommendedSetting().apiView;
const emptyDateFreshness = "N/A";


export const SingleServiceApiParity: FunctionComponent<SingleServiceApiParityProps> = (props) => {
  const [data, setData] = useState<Optional<IParityGridBootstrapData>>();
  const [selection, setSelection] = useState<Optional<IApiViewNoApiSelection>>();
  const [dateFreshnessLabel, setDateFreshnessLabel] = useState<string>(emptyDateFreshness);

  setFeature({
    commenting: false
  });

  const loader_onDataLoaded = useCallback((rawData: Partial<IApiParityBootstrapDataInput>) => {
    setData(bootstrapApiParityData(rawData));
  }, []);


  useEffect(() => {
    const service: Optional<AwsServiceVO> = data?.services?.getById(props.serviceId);
    if (service) {
      setSelection({
        service,
        api: undefined,
        region: undefined,
      });
    } else {
      setSelection(undefined);
    }

    if (data?.latestDataDate?.cecm) {
      setDateFreshnessLabel(getTimeAgo(data.latestDataDate.cecm));
    } else {
      setDateFreshnessLabel(emptyDateFreshness);
    }
  }, [data, props.serviceId]);

  if (!data) {
    return <DataLoaderContainer>
      <ApiParityLoader onDataLoaded={loader_onDataLoaded} />
    </DataLoaderContainer>;
  }

  if (selection) {
    return <ApiViewContainer>
      <DateFreshness>Data Freshness: {dateFreshnessLabel}</DateFreshness>
      <ApiParityByServiceView
        constraintHeight={ props.constraintHeight ?? legacyParityReportHeight}
        selection={selection}
        apis={data.apis}
        parities={data.parities}
        parityOverrides={data.parityOverrides}
        regions={data.regions}
        comments={data.comments}
        setting={setting}
        buildPlans={data.serviceBuildPlans}
        exemptedApi={data.exemptedApi}
        hideBackToServiceButton={true}
      />
    </ApiViewContainer>
  }

  return <NoDataContainer>
    No API Parity Data is found for the service, it is likely the service is
    not mapped to SDK under the same name or the service is not onboarded with
    CloudTrail yet.
  </NoDataContainer>;
};
