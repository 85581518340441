import React, { FunctionComponent } from "react";
import { HelpPanelButtonProps } from "./HelpPanelButton.types";
import { useHistory, useLocation } from "react-router-dom";
import { ILocationState } from "../../../models/LocationState";
import { Button } from "@amzn/awsui-components-react-v3";

export const HelpPanelButton: FunctionComponent<HelpPanelButtonProps> = (props) => {
  const location = useLocation<ILocationState>();
  const history = useHistory();

  return (
    <Button
      variant="inline-icon"
      iconName="status-info"
      formAction="none"
      onClick={() => {
        history.replace({
          state: {
            ...location.state,
            selectInfo: props.helpId
          }
        })
      }}
    />
  );
}
