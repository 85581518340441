/* eslint-disable */
"use strict";
/*
  The `content-security-policy` header's `script-src` directive that lacks the `'unsafe-eval'` value which is not allowed by AppSec. The `ajv.compile()` npm
  package function violates that security. This file is the result of using the
  `ajv-cli` npm package to create the validation function from the
  `cosApiListEventStatusForOneRegionDtoSchema` definition.

  1.  Create a JSON file from the cosApiListEventStatusForOneRegionDtoSchema
      TypeScript definition in `types/ICosApiDto.ts` to a temp file.
      a.  Create a /tmp/schema.json (or whatever) temp file.
      b.  Copy the cosApiListEventStatusForOneRegionDtoSchema
      c.  Replace its references to other objects, in place
      d.  Replace functions with their literal results; i.e., the `regexToString()`
          convert the regex to a string of everything except the bounding `/`s and
          escape `\`-->`\\`.
      e.  Convert the syntax to strict JSON syntax
  2.  Use the ajv command to compile the file to this file.
      ```
      npx ajv -s /tmp/schema.json -o CosSchemaDataValidate.js
      ```
  3.  Add these comments to the new version of this file
      - Prettify the file, if desired.
*/
module.exports = validate20;
module.exports.default = validate20;
const schema22 = {
  type: "object",
  properties: {
    airportCode: {
      type: "string",
      minLength: 3,
      maxLength: 3,
      pattern: "^[A-Z]{3}$",
    },
    usages: {
      type: "array",
      items: {
        type: "object",
        properties: {
          api: {
            type: "string",
            minLength: 3,
            maxLength: 128,
            pattern: "^[[\\].:A-Za-z0-9_-]*$",
          },
          service: {
            type: "string",
            minLength: 2,
            maxLength: 50,
            pattern: "^[[\\].:A-Za-z0-9_-]*$",
          },
          lastCecm: {
            type: "string",
            minLength: 10,
            maxLength: 30,
            pattern:
              "^([+-]?\\d{4}(?!\\d{2}\\b))((-?)((0[1-9]|1[0-2])(\\3([12]\\d|0[1-9]|3[01]))?|W([0-4]\\d|5[0-2])(-?[1-7])?|(00[1-9]|0[1-9]\\d|[12]\\d{2}|3([0-5]\\d|6[1-6])))([T\\s]((([01]\\d|2[0-3])((:?)[0-5]\\d)?|24:?00)([.,]\\d+(?!:))?)?(\\17[0-5]\\d([.,]\\d+)?)?([zZ]|([+-])([01]\\d|2[0-3]):?([0-5]\\d)?)?)?)?$",
          },
        },
        required: ["api", "service", "lastCecm"],
        additionalProperties: false,
      },
    },
    errors: {
      type: "array",
      items: {
        type: "object",
        properties: {
          entry: {
            type: "object",
            properties: {
              eventName: { type: "string" },
              serviceName: { type: "string" },
              region: { type: "string" },
              lastCecmDate: { type: "string" },
            },
            required: ["eventName", "serviceName"],
          },
          errorFields: { type: "array", items: { type: "string" } },
        },
      },
    },
  },
  required: ["usages", "airportCode"],
  additionalProperties: false,
};
const func4 = require("ajv/dist/runtime/ucs2length").default;
const pattern0 = new RegExp("^[A-Z]{3}$", "u");
const pattern1 = new RegExp("^[[\\].:A-Za-z0-9_-]*$", "u");
const pattern3 = new RegExp(
  "^([+-]?\\d{4}(?!\\d{2}\\b))((-?)((0[1-9]|1[0-2])(\\3([12]\\d|0[1-9]|3[01]))?|W([0-4]\\d|5[0-2])(-?[1-7])?|(00[1-9]|0[1-9]\\d|[12]\\d{2}|3([0-5]\\d|6[1-6])))([T\\s]((([01]\\d|2[0-3])((:?)[0-5]\\d)?|24:?00)([.,]\\d+(?!:))?)?(\\17[0-5]\\d([.,]\\d+)?)?([zZ]|([+-])([01]\\d|2[0-3]):?([0-5]\\d)?)?)?)?$",
  "u"
);
function validate20(
  data,
  { instancePath = "", parentData, parentDataProperty, rootData = data } = {}
) {
  let vErrors = null;
  let errors = 0;
  if (errors === 0) {
    if (data && typeof data == "object" && !Array.isArray(data)) {
      let missing0;
      if (
        (data.usages === undefined && (missing0 = "usages")) ||
        (data.airportCode === undefined && (missing0 = "airportCode"))
      ) {
        validate20.errors = [
          {
            instancePath,
            schemaPath: "#/required",
            keyword: "required",
            params: { missingProperty: missing0 },
            message: "must have required property '" + missing0 + "'",
          },
        ];
        return false;
      } else {
        const _errs1 = errors;
        for (const key0 in data) {
          if (
            !(key0 === "airportCode" || key0 === "usages" || key0 === "errors")
          ) {
            validate20.errors = [
              {
                instancePath,
                schemaPath: "#/additionalProperties",
                keyword: "additionalProperties",
                params: { additionalProperty: key0 },
                message: "must NOT have additional properties",
              },
            ];
            return false;
            break;
          }
        }
        if (_errs1 === errors) {
          if (data.airportCode !== undefined) {
            let data0 = data.airportCode;
            const _errs2 = errors;
            if (errors === _errs2) {
              if (typeof data0 === "string") {
                if (func4(data0) > 3) {
                  validate20.errors = [
                    {
                      instancePath: instancePath + "/airportCode",
                      schemaPath: "#/properties/airportCode/maxLength",
                      keyword: "maxLength",
                      params: { limit: 3 },
                      message: "must NOT have more than 3 characters",
                    },
                  ];
                  return false;
                } else {
                  if (func4(data0) < 3) {
                    validate20.errors = [
                      {
                        instancePath: instancePath + "/airportCode",
                        schemaPath: "#/properties/airportCode/minLength",
                        keyword: "minLength",
                        params: { limit: 3 },
                        message: "must NOT have fewer than 3 characters",
                      },
                    ];
                    return false;
                  } else {
                    if (!pattern0.test(data0)) {
                      validate20.errors = [
                        {
                          instancePath: instancePath + "/airportCode",
                          schemaPath: "#/properties/airportCode/pattern",
                          keyword: "pattern",
                          params: { pattern: "^[A-Z]{3}$" },
                          message: 'must match pattern "' + "^[A-Z]{3}$" + '"',
                        },
                      ];
                      return false;
                    }
                  }
                }
              } else {
                validate20.errors = [
                  {
                    instancePath: instancePath + "/airportCode",
                    schemaPath: "#/properties/airportCode/type",
                    keyword: "type",
                    params: { type: "string" },
                    message: "must be string",
                  },
                ];
                return false;
              }
            }
            var valid0 = _errs2 === errors;
          } else {
            var valid0 = true;
          }
          if (valid0) {
            if (data.usages !== undefined) {
              let data1 = data.usages;
              const _errs4 = errors;
              if (errors === _errs4) {
                if (Array.isArray(data1)) {
                  var valid1 = true;
                  const len0 = data1.length;
                  for (let i0 = 0; i0 < len0; i0++) {
                    let data2 = data1[i0];
                    const _errs6 = errors;
                    if (errors === _errs6) {
                      if (
                        data2 &&
                        typeof data2 == "object" &&
                        !Array.isArray(data2)
                      ) {
                        let missing1;
                        if (
                          (data2.api === undefined && (missing1 = "api")) ||
                          (data2.service === undefined &&
                            (missing1 = "service")) ||
                          (data2.lastCecm === undefined &&
                            (missing1 = "lastCecm"))
                        ) {
                          validate20.errors = [
                            {
                              instancePath: instancePath + "/usages/" + i0,
                              schemaPath: "#/properties/usages/items/required",
                              keyword: "required",
                              params: { missingProperty: missing1 },
                              message:
                                "must have required property '" +
                                missing1 +
                                "'",
                            },
                          ];
                          return false;
                        } else {
                          const _errs8 = errors;
                          for (const key1 in data2) {
                            if (
                              !(
                                key1 === "api" ||
                                key1 === "service" ||
                                key1 === "lastCecm"
                              )
                            ) {
                              validate20.errors = [
                                {
                                  instancePath: instancePath + "/usages/" + i0,
                                  schemaPath:
                                    "#/properties/usages/items/additionalProperties",
                                  keyword: "additionalProperties",
                                  params: { additionalProperty: key1 },
                                  message:
                                    "must NOT have additional properties",
                                },
                              ];
                              return false;
                              break;
                            }
                          }
                          if (_errs8 === errors) {
                            if (data2.api !== undefined) {
                              let data3 = data2.api;
                              const _errs9 = errors;
                              if (errors === _errs9) {
                                if (typeof data3 === "string") {
                                  if (func4(data3) > 128) {
                                    validate20.errors = [
                                      {
                                        instancePath:
                                          instancePath +
                                          "/usages/" +
                                          i0 +
                                          "/api",
                                        schemaPath:
                                          "#/properties/usages/items/properties/api/maxLength",
                                        keyword: "maxLength",
                                        params: { limit: 128 },
                                        message:
                                          "must NOT have more than 128 characters",
                                      },
                                    ];
                                    return false;
                                  } else {
                                    if (func4(data3) < 3) {
                                      validate20.errors = [
                                        {
                                          instancePath:
                                            instancePath +
                                            "/usages/" +
                                            i0 +
                                            "/api",
                                          schemaPath:
                                            "#/properties/usages/items/properties/api/minLength",
                                          keyword: "minLength",
                                          params: { limit: 3 },
                                          message:
                                            "must NOT have fewer than 3 characters",
                                        },
                                      ];
                                      return false;
                                    } else {
                                      if (!pattern1.test(data3)) {
                                        validate20.errors = [
                                          {
                                            instancePath:
                                              instancePath +
                                              "/usages/" +
                                              i0 +
                                              "/api",
                                            schemaPath:
                                              "#/properties/usages/items/properties/api/pattern",
                                            keyword: "pattern",
                                            params: {
                                              pattern: "^[[\\].:A-Za-z0-9_-]*$",
                                            },
                                            message:
                                              'must match pattern "' +
                                              "^[[\\].:A-Za-z0-9_-]*$" +
                                              '"',
                                          },
                                        ];
                                        return false;
                                      }
                                    }
                                  }
                                } else {
                                  validate20.errors = [
                                    {
                                      instancePath:
                                        instancePath + "/usages/" + i0 + "/api",
                                      schemaPath:
                                        "#/properties/usages/items/properties/api/type",
                                      keyword: "type",
                                      params: { type: "string" },
                                      message: "must be string",
                                    },
                                  ];
                                  return false;
                                }
                              }
                              var valid2 = _errs9 === errors;
                            } else {
                              var valid2 = true;
                            }
                            if (valid2) {
                              if (data2.service !== undefined) {
                                let data4 = data2.service;
                                const _errs11 = errors;
                                if (errors === _errs11) {
                                  if (typeof data4 === "string") {
                                    if (func4(data4) > 50) {
                                      validate20.errors = [
                                        {
                                          instancePath:
                                            instancePath +
                                            "/usages/" +
                                            i0 +
                                            "/service",
                                          schemaPath:
                                            "#/properties/usages/items/properties/service/maxLength",
                                          keyword: "maxLength",
                                          params: { limit: 50 },
                                          message:
                                            "must NOT have more than 50 characters",
                                        },
                                      ];
                                      return false;
                                    } else {
                                      if (func4(data4) < 2) {
                                        validate20.errors = [
                                          {
                                            instancePath:
                                              instancePath +
                                              "/usages/" +
                                              i0 +
                                              "/service",
                                            schemaPath:
                                              "#/properties/usages/items/properties/service/minLength",
                                            keyword: "minLength",
                                            params: { limit: 2 },
                                            message:
                                              "must NOT have fewer than 2 characters",
                                          },
                                        ];
                                        return false;
                                      } else {
                                        if (!pattern1.test(data4)) {
                                          validate20.errors = [
                                            {
                                              instancePath:
                                                instancePath +
                                                "/usages/" +
                                                i0 +
                                                "/service",
                                              schemaPath:
                                                "#/properties/usages/items/properties/service/pattern",
                                              keyword: "pattern",
                                              params: {
                                                pattern:
                                                  "^[[\\].:A-Za-z0-9_-]*$",
                                              },
                                              message:
                                                'must match pattern "' +
                                                "^[[\\].:A-Za-z0-9_-]*$" +
                                                '"',
                                            },
                                          ];
                                          return false;
                                        }
                                      }
                                    }
                                  } else {
                                    validate20.errors = [
                                      {
                                        instancePath:
                                          instancePath +
                                          "/usages/" +
                                          i0 +
                                          "/service",
                                        schemaPath:
                                          "#/properties/usages/items/properties/service/type",
                                        keyword: "type",
                                        params: { type: "string" },
                                        message: "must be string",
                                      },
                                    ];
                                    return false;
                                  }
                                }
                                var valid2 = _errs11 === errors;
                              } else {
                                var valid2 = true;
                              }
                              if (valid2) {
                                if (data2.lastCecm !== undefined) {
                                  let data5 = data2.lastCecm;
                                  const _errs13 = errors;
                                  if (errors === _errs13) {
                                    if (typeof data5 === "string") {
                                      if (func4(data5) > 30) {
                                        validate20.errors = [
                                          {
                                            instancePath:
                                              instancePath +
                                              "/usages/" +
                                              i0 +
                                              "/lastCecm",
                                            schemaPath:
                                              "#/properties/usages/items/properties/lastCecm/maxLength",
                                            keyword: "maxLength",
                                            params: { limit: 30 },
                                            message:
                                              "must NOT have more than 30 characters",
                                          },
                                        ];
                                        return false;
                                      } else {
                                        if (func4(data5) < 10) {
                                          validate20.errors = [
                                            {
                                              instancePath:
                                                instancePath +
                                                "/usages/" +
                                                i0 +
                                                "/lastCecm",
                                              schemaPath:
                                                "#/properties/usages/items/properties/lastCecm/minLength",
                                              keyword: "minLength",
                                              params: { limit: 10 },
                                              message:
                                                "must NOT have fewer than 10 characters",
                                            },
                                          ];
                                          return false;
                                        } else {
                                          if (!pattern3.test(data5)) {
                                            validate20.errors = [
                                              {
                                                instancePath:
                                                  instancePath +
                                                  "/usages/" +
                                                  i0 +
                                                  "/lastCecm",
                                                schemaPath:
                                                  "#/properties/usages/items/properties/lastCecm/pattern",
                                                keyword: "pattern",
                                                params: {
                                                  pattern:
                                                    "^([+-]?\\d{4}(?!\\d{2}\\b))((-?)((0[1-9]|1[0-2])(\\3([12]\\d|0[1-9]|3[01]))?|W([0-4]\\d|5[0-2])(-?[1-7])?|(00[1-9]|0[1-9]\\d|[12]\\d{2}|3([0-5]\\d|6[1-6])))([T\\s]((([01]\\d|2[0-3])((:?)[0-5]\\d)?|24:?00)([.,]\\d+(?!:))?)?(\\17[0-5]\\d([.,]\\d+)?)?([zZ]|([+-])([01]\\d|2[0-3]):?([0-5]\\d)?)?)?)?$",
                                                },
                                                message:
                                                  'must match pattern "' +
                                                  "^([+-]?\\d{4}(?!\\d{2}\\b))((-?)((0[1-9]|1[0-2])(\\3([12]\\d|0[1-9]|3[01]))?|W([0-4]\\d|5[0-2])(-?[1-7])?|(00[1-9]|0[1-9]\\d|[12]\\d{2}|3([0-5]\\d|6[1-6])))([T\\s]((([01]\\d|2[0-3])((:?)[0-5]\\d)?|24:?00)([.,]\\d+(?!:))?)?(\\17[0-5]\\d([.,]\\d+)?)?([zZ]|([+-])([01]\\d|2[0-3]):?([0-5]\\d)?)?)?)?$" +
                                                  '"',
                                              },
                                            ];
                                            return false;
                                          }
                                        }
                                      }
                                    } else {
                                      validate20.errors = [
                                        {
                                          instancePath:
                                            instancePath +
                                            "/usages/" +
                                            i0 +
                                            "/lastCecm",
                                          schemaPath:
                                            "#/properties/usages/items/properties/lastCecm/type",
                                          keyword: "type",
                                          params: { type: "string" },
                                          message: "must be string",
                                        },
                                      ];
                                      return false;
                                    }
                                  }
                                  var valid2 = _errs13 === errors;
                                } else {
                                  var valid2 = true;
                                }
                              }
                            }
                          }
                        }
                      } else {
                        validate20.errors = [
                          {
                            instancePath: instancePath + "/usages/" + i0,
                            schemaPath: "#/properties/usages/items/type",
                            keyword: "type",
                            params: { type: "object" },
                            message: "must be object",
                          },
                        ];
                        return false;
                      }
                    }
                    var valid1 = _errs6 === errors;
                    if (!valid1) {
                      break;
                    }
                  }
                } else {
                  validate20.errors = [
                    {
                      instancePath: instancePath + "/usages",
                      schemaPath: "#/properties/usages/type",
                      keyword: "type",
                      params: { type: "array" },
                      message: "must be array",
                    },
                  ];
                  return false;
                }
              }
              var valid0 = _errs4 === errors;
            } else {
              var valid0 = true;
            }
            if (valid0) {
              if (data.errors !== undefined) {
                let data6 = data.errors;
                const _errs15 = errors;
                if (errors === _errs15) {
                  if (Array.isArray(data6)) {
                    var valid3 = true;
                    const len1 = data6.length;
                    for (let i1 = 0; i1 < len1; i1++) {
                      let data7 = data6[i1];
                      const _errs17 = errors;
                      if (errors === _errs17) {
                        if (
                          data7 &&
                          typeof data7 == "object" &&
                          !Array.isArray(data7)
                        ) {
                          if (data7.entry !== undefined) {
                            let data8 = data7.entry;
                            const _errs19 = errors;
                            if (errors === _errs19) {
                              if (
                                data8 &&
                                typeof data8 == "object" &&
                                !Array.isArray(data8)
                              ) {
                                let missing2;
                                if (
                                  (data8.eventName === undefined &&
                                    (missing2 = "eventName")) ||
                                  (data8.serviceName === undefined &&
                                    (missing2 = "serviceName"))
                                ) {
                                  validate20.errors = [
                                    {
                                      instancePath:
                                        instancePath +
                                        "/errors/" +
                                        i1 +
                                        "/entry",
                                      schemaPath:
                                        "#/properties/errors/items/properties/entry/required",
                                      keyword: "required",
                                      params: { missingProperty: missing2 },
                                      message:
                                        "must have required property '" +
                                        missing2 +
                                        "'",
                                    },
                                  ];
                                  return false;
                                } else {
                                  if (data8.eventName !== undefined) {
                                    const _errs21 = errors;
                                    if (typeof data8.eventName !== "string") {
                                      validate20.errors = [
                                        {
                                          instancePath:
                                            instancePath +
                                            "/errors/" +
                                            i1 +
                                            "/entry/eventName",
                                          schemaPath:
                                            "#/properties/errors/items/properties/entry/properties/eventName/type",
                                          keyword: "type",
                                          params: { type: "string" },
                                          message: "must be string",
                                        },
                                      ];
                                      return false;
                                    }
                                    var valid5 = _errs21 === errors;
                                  } else {
                                    var valid5 = true;
                                  }
                                  if (valid5) {
                                    if (data8.serviceName !== undefined) {
                                      const _errs23 = errors;
                                      if (
                                        typeof data8.serviceName !== "string"
                                      ) {
                                        validate20.errors = [
                                          {
                                            instancePath:
                                              instancePath +
                                              "/errors/" +
                                              i1 +
                                              "/entry/serviceName",
                                            schemaPath:
                                              "#/properties/errors/items/properties/entry/properties/serviceName/type",
                                            keyword: "type",
                                            params: { type: "string" },
                                            message: "must be string",
                                          },
                                        ];
                                        return false;
                                      }
                                      var valid5 = _errs23 === errors;
                                    } else {
                                      var valid5 = true;
                                    }
                                    if (valid5) {
                                      if (data8.region !== undefined) {
                                        const _errs25 = errors;
                                        if (typeof data8.region !== "string") {
                                          validate20.errors = [
                                            {
                                              instancePath:
                                                instancePath +
                                                "/errors/" +
                                                i1 +
                                                "/entry/region",
                                              schemaPath:
                                                "#/properties/errors/items/properties/entry/properties/region/type",
                                              keyword: "type",
                                              params: { type: "string" },
                                              message: "must be string",
                                            },
                                          ];
                                          return false;
                                        }
                                        var valid5 = _errs25 === errors;
                                      } else {
                                        var valid5 = true;
                                      }
                                      if (valid5) {
                                        if (data8.lastCecmDate !== undefined) {
                                          const _errs27 = errors;
                                          if (
                                            typeof data8.lastCecmDate !==
                                            "string"
                                          ) {
                                            validate20.errors = [
                                              {
                                                instancePath:
                                                  instancePath +
                                                  "/errors/" +
                                                  i1 +
                                                  "/entry/lastCecmDate",
                                                schemaPath:
                                                  "#/properties/errors/items/properties/entry/properties/lastCecmDate/type",
                                                keyword: "type",
                                                params: { type: "string" },
                                                message: "must be string",
                                              },
                                            ];
                                            return false;
                                          }
                                          var valid5 = _errs27 === errors;
                                        } else {
                                          var valid5 = true;
                                        }
                                      }
                                    }
                                  }
                                }
                              } else {
                                validate20.errors = [
                                  {
                                    instancePath:
                                      instancePath + "/errors/" + i1 + "/entry",
                                    schemaPath:
                                      "#/properties/errors/items/properties/entry/type",
                                    keyword: "type",
                                    params: { type: "object" },
                                    message: "must be object",
                                  },
                                ];
                                return false;
                              }
                            }
                            var valid4 = _errs19 === errors;
                          } else {
                            var valid4 = true;
                          }
                          if (valid4) {
                            if (data7.errorFields !== undefined) {
                              let data13 = data7.errorFields;
                              const _errs29 = errors;
                              if (errors === _errs29) {
                                if (Array.isArray(data13)) {
                                  var valid6 = true;
                                  const len2 = data13.length;
                                  for (let i2 = 0; i2 < len2; i2++) {
                                    const _errs31 = errors;
                                    if (typeof data13[i2] !== "string") {
                                      validate20.errors = [
                                        {
                                          instancePath:
                                            instancePath +
                                            "/errors/" +
                                            i1 +
                                            "/errorFields/" +
                                            i2,
                                          schemaPath:
                                            "#/properties/errors/items/properties/errorFields/items/type",
                                          keyword: "type",
                                          params: { type: "string" },
                                          message: "must be string",
                                        },
                                      ];
                                      return false;
                                    }
                                    var valid6 = _errs31 === errors;
                                    if (!valid6) {
                                      break;
                                    }
                                  }
                                } else {
                                  validate20.errors = [
                                    {
                                      instancePath:
                                        instancePath +
                                        "/errors/" +
                                        i1 +
                                        "/errorFields",
                                      schemaPath:
                                        "#/properties/errors/items/properties/errorFields/type",
                                      keyword: "type",
                                      params: { type: "array" },
                                      message: "must be array",
                                    },
                                  ];
                                  return false;
                                }
                              }
                              var valid4 = _errs29 === errors;
                            } else {
                              var valid4 = true;
                            }
                          }
                        } else {
                          validate20.errors = [
                            {
                              instancePath: instancePath + "/errors/" + i1,
                              schemaPath: "#/properties/errors/items/type",
                              keyword: "type",
                              params: { type: "object" },
                              message: "must be object",
                            },
                          ];
                          return false;
                        }
                      }
                      var valid3 = _errs17 === errors;
                      if (!valid3) {
                        break;
                      }
                    }
                  } else {
                    validate20.errors = [
                      {
                        instancePath: instancePath + "/errors",
                        schemaPath: "#/properties/errors/type",
                        keyword: "type",
                        params: { type: "array" },
                        message: "must be array",
                      },
                    ];
                    return false;
                  }
                }
                var valid0 = _errs15 === errors;
              } else {
                var valid0 = true;
              }
            }
          }
        }
      }
    } else {
      validate20.errors = [
        {
          instancePath,
          schemaPath: "#/type",
          keyword: "type",
          params: { type: "object" },
          message: "must be object",
        },
      ];
      return false;
    }
  }
  validate20.errors = vErrors;
  return errors === 0;
}
