import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useLocation } from "react-router";
import { SideNavigationProps } from "@amzn/awsui-components-react-v3";
import {
  AppLayout,
  FeedbackButton,
  LaunchEditDocsReviewsPage,
  LaunchEditPage,
  LaunchEditRegionsPage,
  LaunchPropertiesPage,
  ProductCreateLaunchPage,
  ProductLaunchesPage,
  ProductPropertiesPage,
} from "@amzn/region-services-react-library-v4";
import routes from "../../../Routes";
import { handleAnalytics, useUnifiedUIViewModel } from "../unifiedUtil";
import { getAppState } from "../../../models/ApplicationState";
import { ReconRouter } from "../../../ReconRouter";
import { useProductContext } from "../../../contexts/ProductContext";
import { useLaunchContext } from "../../../contexts/LaunchContext";
import { TitleBanner } from "../TitleBanner/TitleBanner";
import { HelpPanel } from "../../HelpPanel/HelpPanel";
import { ILocationState } from "../../../models/LocationState";

/**
 * UnifiedLayout is a component that wraps region services' centralized AppLayout
 */
export const UnifiedLayout: React.FunctionComponent = () => {
  const history = useHistory();
  const { product } = useProductContext();
  const { launch } = useLaunchContext();
  const [showTools, setShowTools] = useState<boolean>(false);
  const [selectedItem, setSelectedItem] = useState<string | undefined>(undefined);
  const [showNavigation, setShowNavigation] = useState<boolean>(true);

  const location = useLocation<ILocationState>();
  useEffect(() => {
    if (location.state?.selectInfo) {
      setShowTools(true);
      setSelectedItem(location.state.selectInfo);
      history.replace({
        state: {
          ...location.state,
          selectInfo: undefined
        }
      });
    }
  }, [history, location.state]);

  const { activeContext, page, bannerVisible } = useUnifiedUIViewModel(routes, {
    product,
    launch
  });

  const followHandler = (event: CustomEvent<SideNavigationProps.FollowDetail>) => {
    const regex = /^(((f|ht)tp[s]?:\/\/)[-a-zA-Z0-9@:%_+.~#?&/=;\\]+)/i

    if (!regex.test(event.detail.href)) {
      event.preventDefault();
      history.push(event.detail.href.replace("/insights", ""));
    }
  }

  const sidebarFollowHandler = (event: CustomEvent<SideNavigationProps.FollowDetail>) => {
    // Special handling to open links to honeycode in a new tab.
    if (event.detail.href.includes("https://app.honeycode.aws")) {
      event.preventDefault();
      const newWindow = window.open(event.detail.href, "_blank", "noopener, noreferrer");
      if (newWindow) {
        newWindow.opener = null;
      }
    } else {
      followHandler(event);
    }
  }

  let helpType: string | undefined;

  const pageMatch = {
    [new LaunchPropertiesPage().name]: "launch",
    [new LaunchEditPage().name]: "launch",
    [new LaunchEditRegionsPage().name]: "launch",
    [new LaunchEditDocsReviewsPage().name]: "launch",
    [new ProductCreateLaunchPage().name]: "launch",
    [new ProductPropertiesPage().name]: "product",
    [new ProductLaunchesPage().name]: "product",
  }

  helpType = page?.page.name ? pageMatch[page?.page.name] : undefined;

  const helpResourceLinks = [
    {
      href: "https://w.amazon.com/bin/view/AWSRegionBuildEngineering/Recon/LaunchManager",
      label: "Documentation",
    },
    {
      href: "https://amzn-aws.slack.com/archives/C02KGPN898D",
      label: "Slack channel",
    },
    {
      href: "https://issues.amazon.com/issues/create?template=14b8bff9-b633-4f51-8d07-b5a6fbcab538",
      label: "Report an issue",
    },
    {
      href: "https://sim.amazon.com/issues/create?template=50eeda63-7acc-477b-ba2c-5ea68b764d02",
      label: "Request a feature",
    },
    {
      href: "https://w.amazon.com/bin/view/AWSRegionBuildEngineering/RBE_CX/NewUnifiedRegionServicesUI",
      label: "Help center",
    },
  ];

  return (
    <AppLayout
      activeContext={activeContext}
      boundPage={page}
      toolsHide={!helpType}
      tools={<HelpPanel selected={selectedItem} topic={helpType}/>}
      toolsOpen={showTools}
      onToolsChange={({ detail }) => setShowTools(detail.open)}
      hideBreadcrumbs={bannerVisible}
      navigationOpen={showNavigation}
      onNavigationChange={({ detail }) => setShowNavigation(detail.open)}
      content={
        <>
          {bannerVisible &&
            <TitleBanner
              activeContext={activeContext}
              boundPage={page}
              overrideBreadcrumbsProps={{
                onFollow: (event) => followHandler(event)
              }}
            />
          }
          <ReconRouter hasApplicationStateLoaded={getAppState().hasApplicationStateLoaded}/>
        </>
      }
      overrideSidebarProps={{
        onFollow: (event) => sidebarFollowHandler(event),
        helpResourceLinks,
        offsetHeader: true,
        footerElement:
          <FeedbackButton
            handleAnalytics={handleAnalytics}
            location={history.location}
          />
      }}
      overrideBreadcrumbsProps={{
        onFollow: (event) => followHandler(event)
      }}
    />
  );
};
