import * as React from "react";
import { useCallback, useEffect, useState } from "react";
import {
  Alert,
  Button,
  Container,
  FormField,
  Header,
  SelectProps,
  SpaceBetween
} from "@amzn/awsui-components-react-v3";
import useSWR from "swr";
import { getSwrConfig } from "../../daos/swrUtil";
import { getDateForFileName } from "../../utils/dateUtil";
import {
  estimatedDate,
  IServiceInRegionReportOption,
  serviceInRegionReportOptions
} from "../../models/ServiceInRegionReportOptions";
import { ReportSelect } from "./report.style";
import { NonCancelableCustomEvent } from "@amzn/awsui-components-react-v3/polaris/internal/events";
import { ServiceByRegionReportProps } from "./ServiceByRegionReport.types";

export const ServiceByRegionReportTestId = {
  error: "ServiceByRegionReport-error",
  typeSelect: "ServiceByRegionReport-typeSelect",
  button: "ServiceByRegionReport-button",
};

const defaultReportType: IServiceInRegionReportOption = estimatedDate;

export const ServiceByRegionReport: React.FunctionComponent<ServiceByRegionReportProps> = (props) => {
  const headerText = "Service By Region Report";
  const description = "This is the .xlsx report which provides a broad overview of all services across regions.";

  const [fetchError, setFetchError] = useState(false);
  const [shouldFetch, setShouldFetch ] = useState(0);
  const [reportType, setReportType] = useState(defaultReportType);

  const { data, isValidating: loading = true, error } = useSWR( shouldFetch ? shouldFetch.toString() : null, async () => {
    const filename = `${getDateForFileName()}_ServiceByRegion (${reportType.filenamePart}).xlsx`;
    return await props.onReport(reportType, filename);
  }, getSwrConfig());

  const type_onChange = useCallback((evt: NonCancelableCustomEvent<SelectProps.ChangeDetail>) => {
    const selected: IServiceInRegionReportOption =
      serviceInRegionReportOptions.find((option) => option === evt.detail.selectedOption)
      ?? defaultReportType;
    setReportType(selected);
  }, [])

  useEffect(() => {
    if (data) {
      setShouldFetch(0);
    } else if (error) {
      setFetchError(error);
    }
  }, [data, error]);

  const handleClick = async () => {
    setShouldFetch(Date.now());
  };

  const errorText = `There was an issue generating the report: ${fetchError}`;

  return (
    <Container header={<Header description={description}>{headerText}</Header>}>
      <SpaceBetween size={"xl"} direction={"vertical"}>
        {(fetchError) && <Alert data-testid={ServiceByRegionReportTestId.error} type="error">{errorText}</Alert>}
        <FormField
          label={"Please choose a report to generate"}>
          <ReportSelect
            data-testid={ServiceByRegionReportTestId.typeSelect}
            options={serviceInRegionReportOptions}
            selectedOption={reportType}
            onChange={type_onChange}
          />
        </FormField>

        <Button
          data-testid={ServiceByRegionReportTestId.button}
          loading={loading}
          iconName="download"
          iconAlign="right"
          variant="primary"
          onClick={handleClick}
        >Generate Service By Region Report</Button>
      </SpaceBetween>
    </Container>
  );
}
