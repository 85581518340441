import { aliasValidation } from "../BulkEditServiceContactWizard/BulkEditServiceContactWizardConstants";
import { IReactHookFormValidation } from "../../../common/FormTypes";

export const REQUIRED_ERROR_MESSAGE: string = "Required"

export const INVALID_ALIAS_REGEX_ERROR_MESSAGE: string = "Invalid character. Aliases can only contain lowercase letters 'a' through 'z'.";
export const ALIAS_EXCEEDS_MAX_LENGTH_ERROR_MESSAGE: string = "Exceeds max length. Aliases can be up to eight characters long."

export const VALID_ALIAS_CHAR_REGEX_AND_MAXLENGTH: IReactHookFormValidation = {
  maxLength: {
    value: aliasValidation.maxLength,
    message: ALIAS_EXCEEDS_MAX_LENGTH_ERROR_MESSAGE
  },
  pattern: {
    value: aliasValidation.pattern,
    message: INVALID_ALIAS_REGEX_ERROR_MESSAGE
  }
};

export const VALID_CHAR_REGEX: RegExp = /^[A-Za-z0-9._+/ -]+$/;
export const INVALID_CHAR_REGEX_ERROR_MESSAGE: string = "Invalid character. Entry must contain only letters, numbers, .-_+/ punctuation, and cannot be only spaces.";

export const REQUIRED_AND_VALID_CHAR_REGEX: IReactHookFormValidation = {
  required: REQUIRED_ERROR_MESSAGE,
  pattern: {
    value: VALID_CHAR_REGEX,
    message: INVALID_CHAR_REGEX_ERROR_MESSAGE,
  }
};

export enum EnterServiceContactsFormType {
  Add,
  Edit,
  Delete,
}

export const contactsTypeMessage: string = "Is this contact registered with RIP?"

export const EnterServiceContactTestId = {
  permissionError: {
    modal: "EnterServiceContactPermissionError-modal",
    alert: "EnterServiceContactPermissionError-alert"
  },
  errorBanners: {
    invalidRip: "EnterServiceContactErrorBanner-invalidRip"
  }
}

/**
 * Field names that map between UI field and the data field in IAddOrEditFormInput
 * This is used only in ServiceContactOrchestrator for now, in the future,
 * the error from the orchestrator can also be mapped to the relevant field, so user
 * can see the error in-place.
 */
export const FormFieldName = {
  serviceName: "serviceName",
  buildTask: "buildTask",
}

export const VALID_RIP_CONTACTS_ENTRY: RegExp = /^[A-Za-z0-9_+\-,]*$/;
