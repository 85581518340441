import * as React from "react";
import { useState } from "react";

import { AppLayout as AppLayoutLegacy } from "@amzn/awsui-components-react";
import { RegionServicesHeader } from "@amzn/region-services-react-library";

import { BrowserRouter, Route } from "react-router-dom";
import { QueryParamProvider } from "use-query-params";
import { NotificationRouter, ReconRouter } from "./ReconRouter";
import { getConfig } from "./utils/environmentUtil";
import Breadcrumbs from "./components/Breadcrumbs/Breadcrumbs";
import { EnvironmentDecorator } from "./components/common/EnvironmentDecorator";
import useSWR from "swr";
import { getSwrConfig } from "./daos/swrUtil";
import { AppStateDao } from "./daos/AppStateDao";
import { getAppState } from "./models/ApplicationState"
import { ServerAbilityName, UserAbilities } from "./models/UserAbilities";
import { useDispatch } from "react-redux"
import { ensureServiceList } from "./redux/actions/serviceActionCreator";
import { IEnvironmentConfig } from "./configs/environmentConfig";
import { isUnifiedUx, UNIFIED_UX_PATH } from "./utils/unifiedUxUtil";
import { UnifiedLayout } from "./components/unified/Layout/UnifiedLayout";
import Sidebar from "./components/Sidebar/Sidebar";
import { setFeature } from "@amzn/api-parity-react-component/lib/feature/featureToggle";
import { IGetAppStateResponse } from "./daos/types/IGetAppStateResponse";
import { ENABLE_ANALYTICS } from "./constants";
import { initPinpointAnalytics } from "@amzn/regions-unified-frontend-analytics-tracking-platform";
import ProductContext from "./contexts/ProductContext";
import LaunchContext from "./contexts/LaunchContext";
import { ProductVO } from "./models/vos/ProductVO";
import { LaunchVO } from "./models/vos/LaunchVO";

const App = () => {
  const envConfig = getConfig() as IEnvironmentConfig;

  React.useEffect(() => {
    (ENABLE_ANALYTICS && envConfig.unifiedTrackingStage) && 
  initPinpointAnalytics(["Recon"], envConfig.unifiedTrackingStage);
  }, [])

  const dispatch = useDispatch();
  const unifiedUx: boolean = isUnifiedUx();

  const [product, setProduct] = useState<ProductVO>(new ProductVO());
  const [launch, setLaunch] = useState<LaunchVO>(new LaunchVO());

  const oneHourInMilliseconds = 3600000

  const appStateSwr = useSWR<IGetAppStateResponse>("appState", async () => {
    const appStateDao = new AppStateDao();
    const state = await appStateDao.getAppState();
    dispatch(ensureServiceList());
    return state;
  }, getSwrConfig(oneHourInMilliseconds));


  getAppState().userAbilities = new UserAbilities((appStateSwr.data?.abilities || []) as ServerAbilityName[]);
  getAppState().hasApplicationStateLoaded = !!(appStateSwr.data || appStateSwr.error);
  const highWaterMark = appStateSwr.data?.blueprintHighWaterMarkUtc;
  getAppState().blueprintHighWaterMarkUtc = highWaterMark ? new Date(highWaterMark) : undefined;
  getAppState().userAlias = appStateSwr.data?.user?.alias ?? "";
  setFeature({
    export: getAppState().userAbilities.canWriteBusinessCaseOverride,
  })

  if (unifiedUx) {
    return (
      <div className="awsui">
        <BrowserRouter basename={UNIFIED_UX_PATH}>
          <QueryParamProvider ReactRouterRoute={Route}>
            <LaunchContext.Provider value={{ launch, setLaunch }}>
              <ProductContext.Provider value={{ product, setProduct }}>
                <UnifiedLayout />
              </ProductContext.Provider>
            </LaunchContext.Provider>
          </QueryParamProvider>
        </BrowserRouter>
      </div>
    )
  }

  return (
    <div className="awsui">
      <RegionServicesHeader current="RECON" stage={envConfig?.stage} />
      <BrowserRouter>
        <QueryParamProvider ReactRouterRoute={Route}>
          <ProductContext.Provider value={{ product, setProduct }}>
            <AppLayoutLegacy
              className="awsui-util-no-gutters"
              content={<div className="awsui-util-m-xl"><ReconRouter hasApplicationStateLoaded={getAppState().hasApplicationStateLoaded}/></div>}
              navigationWidth={220}
              navigation={<Sidebar/>}
              notifications={<NotificationRouter/>}
              toolsHide
              breadcrumbs={<Breadcrumbs/>}
            />
          </ProductContext.Provider>
        </QueryParamProvider>
      </BrowserRouter>
      <EnvironmentDecorator config={envConfig} />
    </div>
  );
};

export default App;
