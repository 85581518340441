import * as React from "react";
import { PropertyFilterProps, TableProps } from "@amzn/awsui-components-react-v3";
import * as awsui from "@amzn/awsui-design-tokens";
import { LaunchLink } from "./LaunchLink/LaunchLink";
import { displayOr } from "../../utils/displayUtil";
import User from "../common/User";
import { LaunchTableVM } from "./ProductLaunches.types";

export const CUSTOM_FILTERING_GROUPS: PropertyFilterProps.GroupText[] = [
  {
    properties: "Questionnaire",
    values: "Questionnaire",
    group: "questionnaire"
  }
]

export const FILTERING_PROPERTIES: PropertyFilterProps.FilteringProperty[] = [
  {
    key: "name",
    operators: ["=", "!=", ":", "!:"],
    propertyLabel: "Launch name",
    groupValuesLabel: "Names"
  },
  {
    key: "owner",
    operators: ["=", ":"],
    propertyLabel: "Launch owner",
    groupValuesLabel: "Launch owners"
  },
  {
    key: "audience",
    operators: ["=", "!="],
    propertyLabel: "Audience",
    groupValuesLabel: "Audience"
  },
  {
    key: "blueprintUid",
    operators: ["="],
    propertyLabel: "Blueprint id",
    groupValuesLabel: "Blueprint ids"
  },
  {
    key: "codename",
    operators: ["=", "!=", ":", "!:"],
    propertyLabel: "Codename",
    groupValuesLabel: "Codenames",
  },
  {
    key: "confidential",
    operators: ["=", "!="],
    propertyLabel: "Confidential",
    groupValuesLabel: "Confidential"
  },
  {
    key: "disclosure",
    operators: ["=", "!="],
    propertyLabel: "Customer disclosure level",
    groupValuesLabel: "Customer disclosure level"
  },
  {
    key: "daysLate",
    operators: ["=", "!=", ">", "<", ">=", "<="],
    propertyLabel: "Days late",
    groupValuesLabel: "Days late",
  },
  {
    key: "date",
    operators: ["=", "!=", ">", "<"],
    propertyLabel: "Launch date",
    groupValuesLabel: "Launch dates",
  },
  {
    key: "id",
    operators: ["=", ":"],
    propertyLabel: "Launch id",
    groupValuesLabel: "Launch ids"
  },
  {
    key: "iteration",
    operators: ["=", "!="],
    propertyLabel: "Launch iteration",
    groupValuesLabel: "Launch iterations"
  },
  {
    key: "regions",
    operators: [":"],
    propertyLabel: "Launch region",
    groupValuesLabel: "Launch regions",
  },
  {
    key: "scope",
    operators: ["=", "!="],
    propertyLabel: "Launch scope",
    groupValuesLabel: "Launch scopes"
  },
  {
    key: "slips",
    operators: ["=", "!=", ">", "<", ">=", "<="],
    propertyLabel: "Launch slips",
    groupValuesLabel: "Launch slips",
  },
  {
    key: "state",
    operators: ["="],
    propertyLabel: "Launch state",
    groupValuesLabel: "Launch states"
  },
  {
    key: "status",
    operators: ["=", "!="],
    propertyLabel: "Launch status",
    groupValuesLabel: "Launch statuses"
  },
  {
    key: "type",
    operators: ["=", "!="],
    propertyLabel: "Launch type",
    groupValuesLabel: "Launch types"
  },
  {
    key: "tier",
    operators: ["=", "!="],
    propertyLabel: "Marketing tier",
    groupValuesLabel: "Marketing tiers"
  },
  {
    key: "ripId",
    operators: ["=", ":"],
    propertyLabel: "RIP name",
    groupValuesLabel: "RIP names",
  },
  {
    key: "tags",
    operators: [":"],
    propertyLabel: "Tag",
    groupValuesLabel: "Tags",
  },
  {
    key: "pricingChangeInvolved",
    operators: ["=", "!="],
    propertyLabel: "Pricing change?",
    groupValuesLabel: "Pricing change?",
    group: "questionnaire"
  },
  {
    key: "namingInvolved",
    operators: ["=", "!="],
    propertyLabel: "Naming involved?",
    groupValuesLabel: "Naming involved?",
    group: "questionnaire"
  },
  {
    key: "prfaqRequired",
    operators: ["=", "!="],
    propertyLabel: "PRFAQ required?",
    groupValuesLabel: "PRFAQ required?",
    group: "questionnaire"
  },
  {
    key: "regulated",
    operators: ["=", "!="],
    propertyLabel: "Regulated elements?",
    groupValuesLabel: "Regulated elements?",
    group: "questionnaire"
  }
];

// Example color stuff, replace later
const statusColors = {
  "GREEN": awsui.colorTextStatusSuccess,
  "YELLOW": "#fde16c", // we need a yellow
  "RED": awsui.colorTextStatusError,
}

// using type TableProps.ColumnDefinition<any>[]
//   until I can find a better replacement
export const COLUMN_DEFINITIONS: TableProps.ColumnDefinition<any>[] = [
  {
    id: "name",
    header: "Name",
    cell: (launch: LaunchTableVM) => (
      <LaunchLink
        displayName={launch.name}
        id={launch.id}
        launch={launch.sourceItem}
      />
    ),
    sortingField: "name",
    maxWidth: 400,
  },
  {
    id: "owner",
    header: "Owner",
    cell: (launch: LaunchTableVM) => displayOr(launch.owner, () => <User alias={launch.owner!}/>),
    sortingField: "owner"
  },
  {
    id: "audience",
    header: "Audience",
    cell: (launch: LaunchTableVM) => displayOr(launch.audience),
    sortingField: "audience"
  },
  {
    id: "blueprintId",
    header: "Blueprint id",
    cell: (launch: LaunchTableVM) => displayOr(launch.blueprintUid),
    sortingField: "blueprintUid"
  },
  {
    id: "codename",
    header: "Codename",
    cell: (launch: LaunchTableVM) => displayOr(launch.codename),
    sortingField: "codename"
  },
  {
    id: "confidential",
    header: "Confidential",
    cell: (launch: LaunchTableVM) => launch.confidential,
    sortingField: "confidential",
  },
  {
    id: "disclosure",
    header: "Customer disclosure level",
    cell: (launch: LaunchTableVM) => displayOr(launch.disclosureLevel),
    sortingField: "disclosureLevel",
  },
  {
    id: "date",
    header: "Launch date",
    cell: (launch: LaunchTableVM) => displayOr(launch.date),
    sortingField: "date"
  },
  {
    id: "daysLate",
    header: "Days late",
    cell: (launch: LaunchTableVM) => displayOr(launch.daysLate),
    sortingField: "daysLate"
  },
  {
    id: "description",
    header: "Description",
    cell: (launch: LaunchTableVM) => displayOr(launch.description)
  },
  {
    id: "goal",
    header: "Goal type",
    cell: (launch: LaunchTableVM) => displayOr(launch.goal),
    sortingField: "goal",
  },
  {
    id: "id",
    header: "Launch id",
    cell: (launch: LaunchTableVM) => launch.id,
    sortingField: "id"
  },
  {
    id: "iteration",
    header: "Launch iteration",
    cell: (launch: LaunchTableVM) => displayOr(launch.iteration),
    sortingField: "iteration"
  },
  {
    id: "regions",
    header: "Launch regions",
    cell: (launch: LaunchTableVM) => displayOr(launch.regions)
  },
  {
    id: "scope",
    header: "Launch scope",
    cell: (launch: LaunchTableVM) => displayOr(launch.scope),
    sortingField: "scope"
  },
  {
    id: "slips",
    header: "Launch slips",
    cell: (launch: LaunchTableVM) => displayOr(launch.slips),
    sortingField: "slips"
  },
  {
    id: "state",
    header: "Launch state",
    cell: (launch: LaunchTableVM) => displayOr(launch.state),
    sortingField: "state"
  },
  {
    id: "status",
    header: "Launch status",
    /*cell: (launch: LaunchVO) => (
      <ColorProgress
        status="in-progress"
        value={Math.floor(Math.random() * 100)}
        description={launch.status}
        progressBarColor={statusColors[launch.status ?? "GREEN"]}
      />
    ),*/
    cell: (launch: LaunchTableVM) => displayOr(launch.status),
    sortingField: "status",
    width: 150
  },
  {
    id: "type",
    header: "Launch type",
    cell: (launch: LaunchTableVM) => displayOr(launch.type),
    sortingField: "type"
  },
  {
    id: "tier",
    header: "Marketing tier",
    cell: (launch: LaunchTableVM) => displayOr(launch.tier),
    sortingField: "tier"
  },
  {
    id: "ripId",
    header: "RIP name",
    cell: (launch: LaunchTableVM) => displayOr(launch.ripId),
    sortingField: "ripId",
  },
  {
    id: "tags",
    header: "Tags",
    cell: (launch: LaunchTableVM) => displayOr(launch.tags)
  },
  {
    id: "pricingChangeInvolved",
    header: "Pricing change?",
    cell: (launch: LaunchTableVM) => launch.pricingChangeInvolved,
    sortingField: "pricingChangeInvolved",
  },
  {
    id: "namingInvolved",
    header: "Naming involved?",
    cell: (launch: LaunchTableVM) => launch.namingInvolved,
    sortingField: "namingInvolved",
  },
  {
    id: "prfaqRequired",
    header: "PRFAQ required?",
    cell: (launch: LaunchTableVM) => launch.prfaqRequired,
    sortingField: "prfaqRequired",
  },
  {
    id: "regulated",
    header: "Regulated elements?",
    cell: (launch: LaunchTableVM) => launch.regulated,
    sortingField: "regulated",
  },
];

export const DEFAULT_COLLECTION_PREFERENCES = {
  title: "Preferences",
  confirmLabel: "Confirm",
  cancelLabel: "Cancel",
  pageSizePreference: {
    title: "Select page size",
    options: [
      { value: 10, label: "10 items" },
      { value: 20, label: "20 items" },
      { value: 50, label: "50 items" }
    ]
  },
  visibleContentPreference: {
    title: "Select visible content",
    options: [
      {
        label: "Always visible",
        options: [
          {
            id: "name",
            label: "Name",
            editable: false
          },
          {
            id: "owner",
            label: "Owner",
            editable: false
          },
        ]
      },
      {
        label: "Launch properties",
        options: [
          { id: "audience", label: "Audience" },
          { id: "blueprintId", label: "Blueprint id" },
          { id: "codename", label: "Codename" },
          { id: "confidential", label: "Confidential" },
          { id: "disclosure", label: "Customer disclosure level" },
          { id: "daysLate", label: "Days late" },
          { id: "description", label: "Description" },
          { id: "goal", label: "Goal type" },
          { id: "date", label: "Launch date" },
          { id: "id", label: "Launch id" },
          { id: "iteration", label: "Launch iteration" },
          { id: "regions", label: "Launch regions" },
          { id: "scope", label: "Launch scope" },
          { id: "slips", label: "Launch slips" },
          { id: "state", label: "Launch state" },
          { id: "status", label: "Launch status" },
          { id: "type", label: "Launch type" },
          { id: "tier", label: "Marketing tier" },
          { id: "ripId", label: "RIP name" },
          { id: "tags", label: "Tags" },
        ]
      },
      {
        label: "Questionnaire",
        options: [
          { id: "namingInvolved", label: "Naming involved?" },
          { id: "prfaqRequired", label: "PRFAQ required?" },
          { id: "pricingChangeInvolved", label: "Pricing change?" },
          { id: "regulated", label: "Regulated elements?" },
        ]
      }
    ]
  }
}

export const DEFAULT_TABLE_PREFERENCES = {
  pageSize: 10,
  visibleContent: [
    "name",
    "owner",
    "date",
    "ripId",
    "iteration",
    "scope",
    "status",
    "tier",
    "type",
  ]
};

export const DEFAULT_I18N_STRINGS = {
  filteringAriaLabel: "your choice",
  dismissAriaLabel: "Dismiss",
  filteringPlaceholder: "Search",
  groupValuesText: "Values",
  groupPropertiesText: "Properties",
  operatorsText: "Operators",
  operationAndText: "and",
  operationOrText: "or",
  operatorLessText: "Less than",
  operatorLessOrEqualText: "Less than or equal",
  operatorGreaterText: "Greater than",
  operatorGreaterOrEqualText:
    "Greater than or equal",
  operatorContainsText: "Contains",
  operatorDoesNotContainText: "Does not contain",
  operatorEqualsText: "Equals",
  operatorDoesNotEqualText: "Does not equal",
  editTokenHeader: "Edit filter",
  propertyText: "Property",
  operatorText: "Operator",
  valueText: "Value",
  cancelActionText: "Cancel",
  applyActionText: "Apply",
  allPropertiesLabel: "All properties",
  tokenLimitShowMore: "Show more",
  tokenLimitShowFewer: "Show fewer",
  clearFiltersText: "Clear filters",
  removeTokenButtonAriaLabel: () => "Remove token",
  enteredTextLabel: text => `Use: "${text}"`
};
