import { getReconApiClient } from "./daoUtil";
import { b64toBlob } from "../utils/reportsUtils";
import { MIME_TYPE_XLSX } from "../constants";
import { saveAs } from "file-saver";

export class BlueprintConflictsReportDao {

  async getBlueprintConflictsReport(filename: string): Promise<string> {
    const axiosResponse = await getReconApiClient().get<string>(this.getBlueprintConflictsReportUrl());
    const data: string = axiosResponse.data;
    const blob = b64toBlob(data, MIME_TYPE_XLSX);
    saveAs(blob, filename);
    return data;
  }

  getBlueprintConflictsReportUrl(): string {
    return ("/api/reports/blueprintconflicts");
  }

}
