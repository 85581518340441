import { QaChecker } from "../models/QaChecker";
import { IGenericMutationResponseDto } from "../../daos/types/IGenericMutationResponseDto";
import { genericMutationSuccessChecker } from "./checkerHelper";

export const updateBusinessCaseOverrideSuccessChecker: QaChecker<IGenericMutationResponseDto> = (val) => {
  return genericMutationSuccessChecker(val, "Error calling PATCH /api/services/.../plans/... to update business case override");
}

export const removeBusinessCaseOverrideSuccessChecker: QaChecker<IGenericMutationResponseDto> = (val) => {
  return genericMutationSuccessChecker(val, "Error calling PATCH /api/services/.../plans/... to remove business case override");
}

export const updateEstimatedDateSuccessChecker: QaChecker<IGenericMutationResponseDto> = (val) => {
  return genericMutationSuccessChecker(val, "Error calling PATCH /api/services/.../plans/... to update estimated date");
}

export const removeEstimatedDateSuccessChecker: QaChecker<IGenericMutationResponseDto> = (val) => {
  return genericMutationSuccessChecker(val, "Error calling PATCH /api/services/.../plans/... to remove estimated date");
}
