import React from "react";
import { Icon } from "@amzn/awsui-components-react";
import { getAppState } from "../../models/ApplicationState"


const Permission = ({ ability, positive, negative }) => {
  if (ability && !!positive)
    return <li>{positive}</li>;
  if (!ability && !!negative)
    return <li>{negative}</li>;

  return <></>;
}

export const ExplainAbilities = () => {
  /*
    * Non-obvious logic!  This returns JSX suitable for showing the logged-in human what they can do
    * here in Recon.  It's not just enumerating what is in the getAppState().userAbilities.can*
    * attributes!  Telling the human that they can read data is actually confusing.  That's assumed.
    * It's the *lack* of read that needs attention: tell them that they aren't a user of Recon.
    * Similarly, don't bother telling a person that they can't see reports since there isn't a reports
    * link visible to them.  That would just invite more questions.  Instead, ignore that ability entirely.
    */
  const appState = getAppState();
  const abilities = appState.userAbilities;

  if (!appState.hasApplicationStateLoaded)
    return (<></>);

  if (!abilities.canRead) {
    return (
        <>
          <Icon name="lock-private" alt="No access" variant="warning" />
          <span style={{ color: "red" }}>You don't have access to Recon</span>
        </>
    );
  }

  return (
    <div>
      <ul>
        <Permission ability={abilities.canReadDates} positive={false} negative="Dates for unlaunched services are redacted" />
        <Permission ability={abilities.canWrite} positive={false} negative="You won't be able to update delivery dates for services you don't own" />
        <Permission
            ability={abilities.canReadUndisclosedRegions}
            positive="You can see regions that aren't publicly announced yet"
            negative="Regions that aren't publicly announced or GA won't be shown (that you don't own)"
        />
        <Permission ability={abilities.canWriteCategories} positive="You can change the category of any service" negative={false} />
        <Permission ability={abilities.canWriteContacts} positive="You can change contacts on any service" negative={false} />
        <Permission ability={true} positive="You can always manage a service if you are on a Bindle that owns that service." negative={false} />
      </ul>
    </div>
  );
}
