import { getReconApiClient, normalizeServiceName } from "./daoUtil";
import { IRipBindleUserResponse } from "./types/IRipBindleUserResponse";


export class RipBindleUserDao {

  async getRipBindleUser(ripService: string): Promise<IRipBindleUserResponse> {
    const client = getReconApiClient();
    const res = await client.get<IRipBindleUserResponse>(this.getUrl(ripService));
    return res.data;
  }

  getUrl(ripService: string): string {
    const normalizedService = normalizeServiceName(ripService);
    const encodedService = encodeURIComponent(normalizedService);
    return `/api/ripbindleuser/${encodedService}`;
  }
}
