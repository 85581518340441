import { UserAbilities } from "../../models/UserAbilities";
import { IRipBindleUserResponse } from "../../daos/types/IRipBindleUserResponse";
import { IServicePlan, ServicePlanAndStubbed } from "../../models/types/IServicePlan";
import { GENERALLY_AVAILABLE, INTERNALLY_AVAILABLE } from "../../constants";

export const mergeUserAbilitiesWithBindle = (userAbilities: UserAbilities, bindleResponse: IRipBindleUserResponse) => {
  const permissionsToMerge: Partial<UserAbilities> = bindleResponse.authorized
    ? { canRead: true, canReadDates: true, canWrite: true, canWriteContacts: true, }
    : {}

    return userAbilities.merge(permissionsToMerge);
}
export const getRegionsForFeaturesContainer = (combinedInstances: ServicePlanAndStubbed[]): string[] => {
  const completedRegions: string[] = [];
  combinedInstances.forEach((instance) => {
    const servicePlan: IServicePlan = instance as IServicePlan;
    if (servicePlan.status === GENERALLY_AVAILABLE || servicePlan.status === INTERNALLY_AVAILABLE) {
      completedRegions.push(servicePlan.belongsToInstance);
    }
  });
  return completedRegions;
};
