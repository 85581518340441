import personFavicon from "../images/favicon-personal.svg";
import betaFavicon from "../images/favicon-beta.svg";
import gammaFavicon from "../images/favicon-gamma.svg";
import productionFavicon from "../images/favicon.svg";
import { Lookup } from "@amzn/api-parity-react-component/lib/models/Lookup";
import * as awsui from "@amzn/awsui-design-tokens";
import { Stage } from "@amzn/region-services-react-library-v4";
import { RegionsUnifiedTrackingStage } from "@amzn/regions-unified-frontend-analytics-tracking-platform";

export const REGION = "us-east-1";


export interface IEnvironmentConfig {
  label: string,
  urlTest: RegExp,
  ribbon: boolean,
  stage: "default" | "beta" | "gamma" | "prod",
  unifiedStage: Stage;
  cognitoIdentityPoolId: string,
  apiUrl: string,
  ripApiUrl?: string,
  rmsApiUrl: string,
  almApiUrl?: string,
  favicon: any,
  theme: string,
  unifiedTrackingStage?: RegionsUnifiedTrackingStage,
  blueprintWorkbookId: string,
}

// defaultConfig use mostly for personal development environment
// that helps reduce the work to manage ever growing number of
// configs across growing number of personal dev environments
export const defaultConfig: IEnvironmentConfig = {
  label: "dev",
  urlTest: /\.recon-beta\./i,
  ribbon: true,
  stage: "default",
  unifiedStage: Stage.ALPHA,
  cognitoIdentityPoolId: "us-east-1:41e65020-cd9d-49c5-9abd-3bb6849c44e4",
  apiUrl: "https://api.recon-beta.region-services.aws.a2z.com/",
  ripApiUrl: "https://api-us-east-1.rip-gamma.region-services.aws.a2z.com/awsauth/graphql",
  rmsApiUrl: "https://regions-management.us-east-1.amazonaws.com",
  almApiUrl: "https://api.alm.recon-beta.region-services.aws.a2z.com/",
  favicon: personFavicon,
  theme: awsui.colorChartsRed600,
  unifiedTrackingStage: RegionsUnifiedTrackingStage.alpha,
  blueprintWorkbookId: "d9e54922-2e3a-4022-bceb-6ac125ce5164"
};

const cfg: Lookup<IEnvironmentConfig> = {
  localhost: {
    ...defaultConfig,
    label: "localhost",
    urlTest: /localhost/i,
    cognitoIdentityPoolId: "us-east-1:40bd0268-e307-47e6-ae31-93995d270eb8",
    apiUrl: "https://localhost:3000/",
  },
  abipat: {
    ...defaultConfig,
    label: "abipat",
    urlTest: /abipat/i,
    cognitoIdentityPoolId: "us-east-1:41e65020-cd9d-49c5-9abd-3bb6849c44e4",
    apiUrl: "https://api.abipat.people.aws.dev/",
    // apiUrl: "https://4vwws75kg8.execute-api.us-east-1.amazonaws.com/beta",
  },
  adj: {
    ...defaultConfig,
    label: "adj",
    urlTest: /adj/i,
    cognitoIdentityPoolId: "us-east-1:c5659199-816f-4629-a37f-c85439c43090",
    apiUrl: "https://api.recon.adj.people.aws.dev/",
    almApiUrl: "https://api.alm.recon.adj.people.aws.dev/",
  },
  bensman: {
    ...defaultConfig,
    label: "bensman",
    urlTest: /bensman/i,
    cognitoIdentityPoolId: "us-east-1:1b036206-5af6-49f1-8138-5cab0954cb0e",
    apiUrl: "https://api.bensman.people.aws.dev/",
    almApiUrl: "https://api.alm.bensman.people.aws.dev",
  },
  bleeii: {
    ...defaultConfig,
    label: "bleeii",
    urlTest: /bleeii/i,
    cognitoIdentityPoolId: "us-east-1:ed045f36-20d1-49e3-b32d-e96e1fe9e960",
    apiUrl: "https://api.bleeii.people.aws.dev",
    almApiUrl: "https://api.alm.bleeii.people.aws.dev",
    // beta:
    // cognitoIdentityPoolId: "us-east-1:41e65020-cd9d-49c5-9abd-3bb6849c44e4",
    // apiUrl: "https://api.recon-beta.region-services.aws.a2z.com/",
  },
  jamestw: {
    ...defaultConfig,
    label: "jamestw",
    urlTest: /jamestw/i,
    cognitoIdentityPoolId: "us-east-1:9cffebdf-42b9-4cb7-9ab9-66bdb4343215",
  },
  jsoumya: {
    ...defaultConfig,
    label: "jsoumya",
    urlTest: /jsoumya/i,
    cognitoIdentityPoolId: "us-east-1:528e4617-54d9-4e2b-8a45-f4acb617e194",
    apiUrl: "https://api.jsoumya.people.aws.dev/",
    almApiUrl: "https://api.alm.jsoumya.people.aws.dev/",
  },
  trzimm: {
    ...defaultConfig,
    label: "trzimm",
    urlTest: /trzimm/i,
    cognitoIdentityPoolId: "us-east-1:a215f471-47e4-4c14-aaca-7affe299c883",
    apiUrl: "https://api.trzimm.people.aws.dev/",
    almApiUrl: "https://api.alm.trzimm.people.aws.dev/",
  },
  kenneyky: {
    ...defaultConfig,
    label: "kenneyky",
    urlTest: /kenneyky/i,
  },
  setafoya: {
    ...defaultConfig,
    label: "setafoya",
    urlTest: /setafoya/i,
    stage: "beta",
    unifiedStage: Stage.BETA,
    almApiUrl: "https://api.alm.setafoya.people.aws.dev/",
    cognitoIdentityPoolId: "us-east-1:9c38256b-06fd-487f-95f5-598375122691",
    apiUrl: "https://api.setafoya.people.aws.dev/",
  },
  kambsaya: {
    ...defaultConfig,
    label: "kambsaya",
    urlTest: /kambsaya/i,
    almApiUrl: "https://api.alm.kambsaya.people.aws.dev/",
    //apiUrl: "https://api.kambsaya.people.aws.dev/",
    //cognitoIdentityPoolId: "us-east-1:69843bcc-ec10-43e7-8e0e-47eec39aefb8",
    // beta:
    cognitoIdentityPoolId: "us-east-1:41e65020-cd9d-49c5-9abd-3bb6849c44e4",
    apiUrl: "https://api.recon-beta.region-services.aws.a2z.com/",
  },
prishasi: {
    ...defaultConfig,
    label: "prishasi",
    urlTest: /prishasi/i,
	cognitoIdentityPoolId: "us-east-1:42527160-a0d7-4193-a279-83c07413c17c",
    almApiUrl: "https://api.alm.prishasi.people.aws.dev/",
    apiUrl: "https://api.prishasi.people.aws.dev/",
},
// All RIP API URL are region-specific because the global endpoint would not work correctly with Cognito
  beta: {
    label: "beta",
    urlTest: /\.recon-beta\./i,
    ribbon: true,
    stage: "beta",
    unifiedStage: Stage.BETA,
    cognitoIdentityPoolId: "us-east-1:41e65020-cd9d-49c5-9abd-3bb6849c44e4",
    apiUrl: "https://api.recon-beta.region-services.aws.a2z.com/",
    ripApiUrl: "https://api-us-east-1.rip-gamma.region-services.aws.a2z.com/awsauth/graphql",
    rmsApiUrl: "https://regions-management.us-east-1.amazonaws.com",
    almApiUrl: "https://api.alm.recon-beta.region-services.aws.a2z.com/",
    favicon: betaFavicon,
    theme: awsui.colorChartsBlue1500,
    unifiedTrackingStage: RegionsUnifiedTrackingStage.beta,
    blueprintWorkbookId: "d9e54922-2e3a-4022-bceb-6ac125ce5164",
  },
  gamma: {
    label: "gamma",
    urlTest: /\.recon-gamma\./i,
    ribbon: true,
    stage: "gamma",
    unifiedStage: Stage.GAMMA,
    cognitoIdentityPoolId: "us-east-1:4204b555-a58b-406f-88f2-e7cff47df912",
    apiUrl: "https://api.recon-gamma.region-services.aws.a2z.com/",
    ripApiUrl: "https://api-us-east-1.rip-gamma.region-services.aws.a2z.com/awsauth/graphql",
    rmsApiUrl: "https://regions-management.us-east-1.amazonaws.com",
    almApiUrl: "https://api.alm.recon-gamma.region-services.aws.a2z.com/",
    favicon: gammaFavicon,
    theme: awsui.colorChartsGreen500,
    unifiedTrackingStage: RegionsUnifiedTrackingStage.gamma,
    blueprintWorkbookId: "a504591f-46b7-492c-a1f8-984b157d9dea",
  },
  production: {
    label: "production",
    urlTest: /\.recon\.region/i,
    ribbon: false,
    stage: "prod",
    unifiedStage: Stage.PROD,
    cognitoIdentityPoolId: "us-east-1:4e9a6c0a-f043-43f1-a55b-11ce3841dc83",
    apiUrl: "https://api.recon.region-services.aws.a2z.com/",
    ripApiUrl: "https://api-us-east-1.rip.region-services.aws.a2z.com/awsauth/graphql",
    rmsApiUrl: "https://regions-management.us-east-1.amazonaws.com",
    almApiUrl: "https://api.alm.recon.region-services.aws.a2z.com/",
    favicon: productionFavicon,
    theme: awsui.colorTextAccent,
    unifiedTrackingStage: RegionsUnifiedTrackingStage.prod,
    blueprintWorkbookId: "910865ba-efa8-44ad-acb4-247de2540df9"
  },
};

export const config: Readonly<Lookup<IEnvironmentConfig>> = Object.freeze(cfg);

