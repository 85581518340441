import { useEffect, useState } from "react";
import PubSub from "pubsub-js";
import { ITitleBannerProperties } from "./TitleBanner";

const useTitleBanner = (initialProps?: ITitleBannerProperties) => {
  const [initialized, setInitialized] = useState(false);
  const [bannerProps, setBannerProps] = useState<ITitleBannerProperties>(initialProps ?? { visible: false });

  if (!initialized) {
    PubSub.publish("TITLEBANNER", bannerProps);
    setInitialized(true);
  }

  useEffect(() => {
    PubSub.publish("TITLEBANNER", bannerProps)
  }, [bannerProps]);

  return setBannerProps;
}

export default useTitleBanner;
