import { AwsServiceExpansionPlanEnum } from "@amzn/api-parity-react-component";

export enum ServiceCategoryVariant {
  full = "full",
  abbreviation = "abbreviation",
}

export interface IServiceCategoryInput {
  category: AwsServiceExpansionPlanEnum;
  variant?: ServiceCategoryVariant;
}

export interface IServiceCategoryEvent {
}

export type ServiceCategoryProps = IServiceCategoryInput & IServiceCategoryEvent;
