import { ServiceContactsDao } from "../daos/ServiceContactsDao";
import { MutationResultType, RemoteMutationError, RemoteMutationResult } from "../models/remoteMutation";
import { ServiceContactsVO } from "../models/vos/ServiceContactsVO";
import { IAddOrEditFormInput } from "../components/ServiceContacts/EnterServiceContact/AddOrEditServiceContactModal/AddOrEditServiceContactModal.types";
import { Optional } from "../models/types/Optional";
import { IServiceContactsDto } from "../daos/types/IServiceContactsDto";
import { parseServiceContactsDto } from "../parsers/serviceContactsParser";
import { FormFieldName } from "../components/ServiceContacts/EnterServiceContact/AddOrEditServiceContactModal/AddOrEditServiceContactModalConstants";
import { IServiceLeaderUpdate } from "../models/delegates/UpdateServiceLeaderDelegate";
import { AxiosError } from "axios";

export enum ServiceContactUpdateErrorCode {
  /**
   * Happens when user attempts to add a service contact already exists
   */
  DuplicateRip= "DuplicateRip",
  DuplicateBuildTask="DuplicateBuildTask",
  /**
   * Happens when a user enters a ripShortname that cannot be matched with an existing rip service
   */
  InvalidRipName = "InvalidRipName",
  /**
   * In rare circumstance, updated result cannot be retrieved
   */
  UpdateCannotBeValidated="UpdateCannotBeValidated",
  AccessDenied="AccessDeniedException",
  Unknown= "Unknown",
}

export type ServiceContactUpdateResult = RemoteMutationResult<ServiceContactsVO, ServiceContactUpdateErrorCode>;
export type ServiceContactUpdateError = RemoteMutationError<ServiceContactUpdateErrorCode>;
export const unknownError: ServiceContactUpdateResult = {
  type: MutationResultType.Error,
  errors: [
    {
      code: ServiceContactUpdateErrorCode.Unknown,
    }
  ]
};


export class ServiceContactOrchestrator {
  constructor(private contactDao: ServiceContactsDao = new ServiceContactsDao()) {
  }

  /**
   * Add new Service contact
   */
  async add(info: IAddOrEditFormInput): Promise<ServiceContactUpdateResult> {
    const serviceName: string = info.serviceName;
    const partition: string = info.partition;
    try {
      // TODO: Validate Build Task, right now it assumes adding rip
      const contactExists: boolean = await this.doesRipServiceContactExist(serviceName, partition);
      if (contactExists) {
        return Promise.reject(this.getSimpleError(ServiceContactUpdateErrorCode.DuplicateRip, FormFieldName.serviceName));
      }
    } catch {
      return Promise.reject(unknownError);
    }

    try {
      await this.contactDao.addOrEditServiceContact(info);
    } catch (exception) {
      if ((exception as AxiosError).response?.data?.message === "InvalidRipNameForContactException"){
        return Promise.reject(this.getSimpleError(ServiceContactUpdateErrorCode.InvalidRipName, FormFieldName.serviceName))
      }
      else if ((exception as AxiosError).response?.data?.message === "AccessDeniedException") {
        return Promise.reject(this.getSimpleError(ServiceContactUpdateErrorCode.AccessDenied, FormFieldName.serviceName))
      }
      else{
        return Promise.reject(unknownError);
      }
    }

    try {
      const added: Optional<ServiceContactsVO> = await this.getParsed(serviceName, info.partition);
      if (added) {
        return Promise.resolve({
          type: MutationResultType.Success,
          entity: added,
        } as ServiceContactUpdateResult);
      } else {
        return Promise.reject(this.getSimpleError(ServiceContactUpdateErrorCode.UpdateCannotBeValidated));
      }
    } catch {
      return Promise.reject(unknownError);
    }
  }

  /**
   * Update existing Service Contacts
   * @param contact
   * @param info
   */
  async update(contact: ServiceContactsVO, info: IAddOrEditFormInput): Promise<ServiceContactUpdateResult> {
    try {
      await this.contactDao.addOrEditServiceContact(info, contact);
    } catch {
      return Promise.reject(unknownError);
    }

    try {
      const updated: Optional<ServiceContactsVO> = await this.getParsed(info.serviceName, contact.partition);
      if (updated) {
        return Promise.resolve({
          type: MutationResultType.Success,
          entity: updated,
        } as ServiceContactUpdateResult);
      } else {
        return Promise.reject(this.getSimpleError(ServiceContactUpdateErrorCode.UpdateCannotBeValidated));
      }
    } catch {
      return Promise.reject(unknownError);
    }
  }

  async updateLeader(contact: ServiceContactsVO, update: IServiceLeaderUpdate): Promise<ServiceContactUpdateResult> {
    if (!contact.dto) {
      // This should not happen, when dto is missing from the VO, that means the VO is not parsed from
      // the dto, thus it loses the context of the original VO, this could be a programming error.
      // though we could also force retrieve the DTO so we can bypass this error, but it may mask
      // and underlying code issue
      // https://i.amazon.com/issues/RECON-9173
      return Promise.reject(unknownError);
    }
    try {
      await this.contactDao.updateLeader(contact.dto, update);
    } catch {
      return Promise.reject(unknownError);
    }

    try {
      const updated: Optional<ServiceContactsVO> = await this.getParsed(contact.instance, contact.partition);
      if (updated) {
        return Promise.resolve({
          type: MutationResultType.Success,
          entity: updated,
        } as ServiceContactUpdateResult);
      } else {
        return Promise.reject(this.getSimpleError(ServiceContactUpdateErrorCode.UpdateCannotBeValidated));
      }
    } catch {
      return Promise.reject(unknownError);
    }
  }

  /**
   * Check if the updated result has been saved correct, if so
   * returns the updated result, otherwise an UpdateCannotBeValidated error is returned
   * @param serviceName
   */
  async getParsed(serviceName: string, partition: string): Promise<Optional<ServiceContactsVO>> {
    const dto: Optional<IServiceContactsDto> = await this.contactDao.getForPartition(serviceName, partition);
    if (!dto) {
      return Promise.resolve(undefined);
    }
    return parseServiceContactsDto(dto);
  }

  async doesRipServiceContactExist(serviceName: string, partition: string): Promise<boolean> {
    const newContact: Optional<IServiceContactsDto> = await this.contactDao.getForPartition(serviceName, partition);
    return !!newContact;
  }

  getSimpleError(code: ServiceContactUpdateErrorCode, field?: string): ServiceContactUpdateResult {
    return {
      type: MutationResultType.Error,
      errors: [
        {
          code,
          field,
        }
      ]
    }
  }
}
