import React, { FunctionComponent } from "react"
import { Icon } from "@amzn/awsui-components-react-v3";
import styled from "styled-components";
import { UserLink } from "@amzn/api-parity-react-component/lib/components/user/UserLink";
import { LxsVO } from "../../../models/vos/ServiceContactsVO";
import { Optional } from "@amzn/api-parity-react-component/lib/models/Nullable";
import { SpaceBetween } from "@amzn/awsui-components-react-v3";
import { SpaceBetweenProps } from "@amzn/awsui-components-react-v3/polaris/space-between/interfaces";

const MAX_CHARACTERS_USER_LABEL = 22

// TODO - add back in when we decide on GM/VP determination strategy
// const GM = (<Badge color="grey">GM</Badge>)
// const VP = (<Badge color="grey">VP</Badge>)

type LeadershipChainComponentProps = {
  leadershipChain: LxsVO[];
  gm: Optional<string>;
  vp: Optional<string>;
  style?: SpaceBetweenProps.Direction;
}

type UserLinkWithGmVpProps = {
  alias: string;
  level: number;
  leadershipChainLength: number;
  gm: Optional<string>;
  vp: Optional<string>;
  className?: string;
  style?: SpaceBetweenProps.Direction;
}

interface IGridContainerProps {
  columns: number;
}
const GridContainer = styled("div")<IGridContainerProps>`
  display: inline-grid;
  grid-template-columns: repeat(${ (props) => props.columns }, auto);
  grid-column-gap: 1rem;
  grid-row-gap: 4px;
  align-items: center;
`;

export const UserLinkWithGmVp: FunctionComponent<UserLinkWithGmVpProps> = (props) => {
  return (
    <div data-testid={`LeadershipChainLeader-${props.alias}`}>
      <SpaceBetween className={props.className} direction={props.style} size="xs">
        <UserLink userId={props.alias} withAvatar={true}>{props.alias} (L{props.level})</UserLink>
        {/* Hiding GM and VP for now as we have discovered some issues with automating this field */}
        {/* {props.alias === props.gm && GM}
        {props.alias === props.vp && VP} */}
      </SpaceBetween>
    </div>
  )
}

UserLinkWithGmVp.defaultProps = {
  style: "horizontal"
};

const StyledLink = styled(UserLinkWithGmVp)`
  width: ${MAX_CHARACTERS_USER_LABEL}ch;
`

export const LeadershipChainComponent: FunctionComponent<LeadershipChainComponentProps> = (props) => {
  let content: JSX.Element[];
  content = props.leadershipChain.map((user, index) => {
    return (
      <React.Fragment key={user.alias}>
        {
          index > 0 && props.style==="horizontal" &&
            <Icon
              data-testid={`LeadershipChainArrowIcon-${index}`}
              name="angle-right"
              size="normal"
              variant="subtle" 
            />
        }
        <StyledLink
          alias={user.alias} 
          level={user.level} 
          gm={props.gm} 
          vp={props.vp} 
          leadershipChainLength={props.leadershipChain?.length}
          style={props.style}
        />
      </React.Fragment>
    )
  })

  //2x-1 is the amount of arrow icons + leaders there will be in the chain
  const horizontalColumns = props.leadershipChain.length * 2 - 1
  const verticalColumns = 1
  return (
    <GridContainer columns={props.style === "horizontal" ? horizontalColumns : verticalColumns}>
      {content}
    </GridContainer> 
  )
}

LeadershipChainComponent.defaultProps = {
  style: "horizontal"
}
