import { IServiceComponentDto } from "./IGetServiceComponentsResponse";
import { Visibility } from "./Visibility";
import { AwsServiceAvailabilityLevelEnum } from "@amzn/api-parity-react-component/lib/models/vos";

export interface RipFeatureMetadata {
  ripId: string;
  longName: string;
  belongsToService: string;
  visibility: string;
  availabilityLevel?: AwsServiceAvailabilityLevelEnum[];
}

export interface RipFeatureInstance {
  ripId: string;
  belongsToDimensionName: string;
  belongsToDimensionType: string;
  status: string; // "GA", "IA", "PLANNED", "NOT_PLANNED"
  parentId: string;
  parentType: string;
  estimatedLaunchDate?: string;
}

export interface RipGQLDataResponse {
  metadata: RipFeatureMetadata[];
  instances: RipFeatureInstance[];
}

export interface ReconFeatureRegionInstance {
  region: string;
  status: string;
  estLaunchDate?: string
}

export interface ReconFeatureComposed {
  id: string;
  longName?: string;
  parentId: string;
  visibility: string;
  type: "Feature" | "Service";
  regions: ReconFeatureRegionInstance[];
}

export interface TaggedFeaturePlanInstance {
  belongsToInstance: string;
  instance: string;
  status: string;
}

export interface IGetTaggedFeaturePlansResponse {
  service: {
    instance: string;
    nameLong: string;
    visibility: string;
  },
  plans: TaggedFeaturePlanInstance[];
}

export type GetTaggedFeaturePlansResponseObject = IGetTaggedFeaturePlansResponse[];


// same as IGetTaggedFeaturePlansResponse.service
export interface ITaggedFeaturePlanService {
  instance: string;
  nameLong: string;
  visibility: Visibility;
}


export interface ITaggedFeaturePlan {
  service: ITaggedFeaturePlanService;
  plans: TaggedFeaturePlanInstance[];
}


export interface IFeatureParityRaw {
  features: RipGQLDataResponse;
  taggedFeatures: IServiceComponentDto[];
  taggedFeaturePlans: ITaggedFeaturePlan[];
}


export const featureParityOverrideStatusInDto = {
  available: "AVAILABLE",
  notLaunching: "NOT_LAUNCHING",
  ato: "ATO",
}
