import * as React from "react";
import { useCallback, useEffect, useState } from "react";
import { ComponentServiceSelectorProps } from "./ComponentServiceSelector.types";
import { Button, Modal, StatusIndicator } from "@amzn/awsui-components-react-v3";
import { DialogFooterButtonBar } from "../../../components/common/Dialog/DialogFooterButtonBar";
import { PolarisMultiSelect } from "../../../components/MultiSelect/PolarisMultiSelect/PolarisMultiSelect";
import { ComponentFeatureVO } from "../../../models/vos/ComponentFeatureVO";
import { MultiSelectItemId } from "../../../components/MultiSelect/IMultiSelectComponent";
import { LoadStateEnum } from "../../../models/LoadStateEnum";
import { AwsServiceVO } from "@amzn/api-parity-react-component";

export const serviceIdExtractor = (service: AwsServiceVO): MultiSelectItemId => service.id;
export const serviceNameExtractor = (service: AwsServiceVO): MultiSelectItemId => service.name;

export const componentIdExtractor = (x: ComponentFeatureVO): MultiSelectItemId => x.id;
export const componentLabelExtractor = (x: ComponentFeatureVO): MultiSelectItemId => x.longName;

export const componentTestId = {
  submit: "ReconComponentService-ComponentSubmit",
  componentSelect: "ReconComponentService-ComponentSelect",
  allServicesSelect: "ReconComponentService-ComponentSelectAll"
}

export const ComponentServiceSelector: React.FunctionComponent<ComponentServiceSelectorProps> = (props) => {
  const [currentlyLoading, setCurrentlyLoading] = useState(props.isFeatureComponentLoading);
  const [selectedComponents, setSelectedComponents] = useState(props.initiallySelectedComponents);
  const [selectedServices, setSelectedServices] = useState(props.initiallySelectedServices);

  useEffect(() => {
    setSelectedServices(props.initiallySelectedServices)
  }, [props.initiallySelectedServices])

  const [isSubmitLoading, setSubmitLoading] = useState(false);

  if (props.isFeatureComponentLoading !== currentlyLoading) {
    setSelectedComponents(props.initiallySelectedComponents);
    setCurrentlyLoading(props.isFeatureComponentLoading);
  }

  const onComponentSelectedChange = useCallback((selectedComponent) => setSelectedComponents(selectedComponent), []);
  const onServiceSelectedChange = useCallback((selectedServices) => setSelectedServices(selectedServices), []);

  const modal_onSubmit = useCallback(() => {
    setSubmitLoading(true);
    props.onSubmit(selectedComponents, selectedServices).then(() => {
      setSubmitLoading(false);
    })
  }, [props.onSubmit, selectedComponents, selectedServices]);

  const modalFooter = (
    <DialogFooterButtonBar>
      <Button variant={"normal"} onClick={props.onDismiss}>Cancel</Button>
      <Button
        variant={"primary"}
        data-testid={componentTestId.submit}
        onClick={modal_onSubmit}
        loading={isSubmitLoading || (props.allServicesLoadingState === LoadStateEnum.Loading)}
      >
        Submit
      </Button>
    </DialogFooterButtonBar>
  );

  return (
    <Modal
      header={"Tag/Untag RIP services as features"}
      footer={modalFooter}
      visible={props.visible}
      onDismiss={props.onDismiss}
    >
      <div>
        <div>Component services of {props.serviceName}</div>
        <div data-testid={componentTestId.componentSelect}>
          <PolarisMultiSelect
            items={props.allComponents}
            selected={selectedComponents}
            idFunction={componentIdExtractor}
            labelFunction={componentLabelExtractor}
            onChange={onComponentSelectedChange}
          />
        </div>

        <br /><br />
        <div>All Services</div>
        <div data-testid={componentTestId.allServicesSelect}>
          { (props.allServicesLoadingState === LoadStateEnum.Never) && <StatusIndicator type={"pending"}>Services are not loaded</StatusIndicator> }
          { (props.allServicesLoadingState === LoadStateEnum.Loading) && <StatusIndicator type={"loading"}>Please wait while services are being loaded...</StatusIndicator> }
          { (props.allServicesLoadingState === LoadStateEnum.Loaded) &&
          <PolarisMultiSelect
            items={props.allServices}
            selected={selectedServices}
            idFunction={serviceIdExtractor}
            labelFunction={serviceNameExtractor}
            onChange={onServiceSelectedChange}
          />
          }
        </div>
      </div>
    </Modal>
  );
};
