import * as React from "react";
import { useCallback, useState } from "react";
import RegionsTable from "../../components/common/TableComponents/RegionsTable";
import { defaultTableConfig, sortRipKind } from "../../utils/tableUtils"
import { Select } from "@amzn/awsui-components-react";
import { FeatureWithRIPLink, SingleServiceWithRIPLink } from "../../components/common/Links"
import { ParityCell } from "./ReconFeatureParityCell"
import {
  calculateCompletedRegions,
  calculateParityPercentage,
  getCommentToDisplay,
  getItemKind,
  isItemComponent,
  isItemService,
  orderRegionsBasedOnParity
} from "./FeatureTableUtils"
import styled from "styled-components";
import { SpaceBetween, ButtonDropdown } from "@amzn/awsui-components-react-v3";
import { FeatureParityKey } from "./SingleServiceFeatureParity/FeatureParityKey";
import { ComponentServiceSelector } from "./ComponentServiceSelector/ComponentServiceSelector";
import { Toolbar } from "@amzn/api-parity-react-component/lib/components/styles";
import { ComponentFeatureVO } from "../../models/vos/ComponentFeatureVO";
import { AwsServiceVO } from "@amzn/api-parity-react-component";
import { createSaveDataSheet, generateWorkbook } from "./ReconFeatureTableCSVHelpers";

import { ActionMenu } from "@amzn/api-parity-react-component/lib/components/ActionMenu/ActionMenu";
import { IActionMenuItem } from "@amzn/api-parity-react-component/lib/components/ActionMenu/ActionMenu.types";
import { ExportFormat } from "@amzn/excelerator";
import { IFeatureServiceItem } from "../../daos/types/IGetFeatureDto";
import { FeatureInRegionCommentModal } from "./FeatureInRegionCommentModal";
import { FeatureInRegionCommentVO } from "../../models/vos/FeatureInRegionCommentVO";
import { getCreateFeatureUrl } from "../../utils/unifiedUxUtil";
import { CommentModalAction, NO_ACTION } from "./FeatureInRegionCommentModal.types";
import { PermissionError } from "../../components/common/PermissionError";
import { WriteAccessSimTemplateLink } from "../../components/common/SimLinks/WriteAccessSimTemplateLink";

export const actionMenu = [
  { id: ExportFormat.Csv, text: "Export to CSV" },
  { id: ExportFormat.Xlsx, text: "Export to Excel" },
]

export const SSFeatureParityTableTestId = {
  table: "SSFeatureParityTable-table",
  addCommentPermissionError: {
    modal: "SingleServiceFeatureParityTable-addCommentPermissionError-modal",
    alert: "SingleServiceFeatureParityTable-addCommentPermissionError-alert"
  },
  tagUntagPermissionError: {
      modal: "SingleServiceFeatureParityTable-tagUntagPermissionError-modal",
      alert: "SingleServiceFeatureParityTable-tagUntagPermissionError-alert"
  },
  createFeatureButton: "SingleServiceFeatureParityTable-createFeature",
  commentButtonDropdown: "SingleServiceFeatureParityTable-commentButtonDropdown",
  featureButtonDropdown: "SingleServiceFeatureParityTable-featureButtonDropdown",
}

const componentButtonTestId = {
  add: "ReconComponentService-ComponentAdd",
  addNewComment: "ReconComponentService-addNewComment",
  download: "ReconComponentService-ComponentDownload",
}

export const FeatureToolbar = styled(Toolbar)`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
`;

export const ControlSlot = styled("div")`
  flex-grow: 1;
`;

export const ActionSlot = styled("div")`
  flex-grow: 0;
  flex-shrink: 0;
`;

const FeatureSelectStyle = styled("div")`
  width: 34rem;
`;

const SORTABLE_COLUMNS = [
  { id: "featureType", field: "featureType", comparator: sortRipKind },
  { id: "featureParity", field: "parityPercentage" },
  { id: "featureName", field: "longName" },
];

const FeatureSelect = (props) => {
  const { featureSelectOptions, selectedOption, handleFilterSelectionChange } = props;
  return (
    <FeatureSelectStyle>
      <Select
        data-testid={"ReconFeatureTable-FilterViewSelect"}
        options={featureSelectOptions}
        selectedOption={selectedOption}
        onChange={handleFilterSelectionChange}
        selectedLabel="Selected"
      />
    </FeatureSelectStyle>
  )
}

const featureSelectOptions = [
  {
    "text": "Show Features Out of Parity",
    "label": "Show Features Out of Parity",
    "id": "default"
  },
  {
    "text": "Show All Features",
    "label": "Show All Features",
    "id": "all"
  },
];

export const COMMENTS_BUTTON_DROPDOWN_IDS ={
  ADD_FEATURE_IN_REGION_COMMENT: "SingleServiceFeatureParityTable-commentButtonDropdown-addFeatureInRegionCommentId",
  TOGGLE_RESOLVED_COMMENTS: "SingleServiceFeatureParityTable-commentButtonDropdown-toggleResolvedCommentsId",
}

export const FEATURE_BUTTON_DROPDOWN_IDS ={
  TAG: "SingleServiceFeatureParityTable-featureButtonDropdown-tagFeatureId",
  CREATE_NEW: "SingleServiceFeatureParityTable-featureButtonDropdown-createNewId",
}

export const ReconFeatureTable = (props) => {
  const { starterRegions, serviceName, displayName, loading, selectedComponents, selectedServices, allComponents, userAbilities } = props;
  const { allServices, allServicesLoadingState, onComponentModalSubmit, getComment, onCommentSubmit, onCommentResolve } = props;
  let { allFeatureData, partitionToRegions } = props;

  // add percentage data to data coming in
  allFeatureData = allFeatureData.map(item => {
    item["parityPercentage"] = calculateParityPercentage(item, starterRegions);
    return item
  });

  const displayFeatures = allFeatureData.map((item: IFeatureServiceItem) => {
    return {
      label: item.longName,
      value: item.id,
    }
  })

  const [selectedOption, setSelectedOption] = useState(featureSelectOptions[1]);
  const handleFilterSelectionChange = (props) => {
    const { detail: { selectedOption: eventSelectedOption } } = props;
    setSelectedOption(eventSelectedOption);
  };

  // filter complete data depending on selection state
  if (selectedOption.id === "default") {
    allFeatureData = allFeatureData.filter(item => item.parityPercentage !== 100);
  }


  // Calculate which columns to show based on filter and show columns in most needed order
  const featureColumnDefs = [
    {
      id: "featureName",
      header: "Name",
      width: 260,
      minWidth: "260px",
      cell: (item) => {
        if (isItemComponent(item) || isItemService(item)) {
          const serviceObject = { nameRip: item.id, nameLong: item.longName }
          return <SingleServiceWithRIPLink serviceObject={serviceObject} showSmall={false} />
        } else {
          return <FeatureWithRIPLink serviceName={serviceName} feature={item} />
        }
      }
    },
    {
      id: "featureType",
      header: "RIP Type",
      width: 120,
      minWidth: "120px",
      cell: (item) => getItemKind(item)
    },
    {
      id: "featureParity",
      header: "Parity %",
      width: 120,
      minWidth: "120px",
      cell: (item) => item.parityPercentage + "%"
    }
  ];

  const completedRegions = calculateCompletedRegions(starterRegions, allFeatureData);
  const regionsOrdered = orderRegionsBasedOnParity(starterRegions, completedRegions, allFeatureData, selectedOption.id);


  const [commentModalAction, setCommentModalAction] = React.useState<CommentModalAction>(NO_ACTION)
  const onCommentDisplayModalDismiss = () => setCommentModalAction(NO_ACTION);
  const commentIconOnClick = (comment: FeatureInRegionCommentVO) => {
    setCommentModalAction({
      action: "edit",
      comment,
      onResolve: onCommentResolve,
    })
  }
  const addCommentOnClick = useCallback(() => {
    setCommentModalAction({
      action: "add",
      service: serviceName,
      features: displayFeatures,
      regions: regionsOrdered,
      partitionToRegions: partitionToRegions,
    })
  }, [displayFeatures, regionsOrdered, serviceName, partitionToRegions])

  const [showResolvedComments, setShowResolvedComments] = React.useState<boolean>(false);
  const [showCommentAddPermissionError, setShowCommentAddPermissionError] = React.useState<boolean>(false);
  const [showTagUntagPermissionError, setShowTagUntagPermissionError] = React.useState<boolean>(false);

  // There are some issues with the typing of the Polaris event
  const onCommentButtonDropdownClick = useCallback((event) => {
    switch (event.detail.id) {
      case COMMENTS_BUTTON_DROPDOWN_IDS.ADD_FEATURE_IN_REGION_COMMENT:
        if (!userAbilities.canWrite) {
          setShowCommentAddPermissionError(true);
        } else {
          addCommentOnClick();
        }
        break;
      case COMMENTS_BUTTON_DROPDOWN_IDS.TOGGLE_RESOLVED_COMMENTS:
        setShowResolvedComments(!showResolvedComments)
        break;
    }
  }, [addCommentOnClick, showResolvedComments, userAbilities.canWrite])

  regionsOrdered.forEach((region) => {
    const showAllRegions = selectedOption.id === "all";
    const regionIsntComplete = (selectedOption.id === "default" && !completedRegions.includes(region));
    const shouldAddRegion = (showAllRegions || regionIsntComplete) && allFeatureData.length > 0;

    if (shouldAddRegion) {
      featureColumnDefs.push({
        id: region,
        width: 115,
        minWidth: "80px",
        header: region,
        cell: (item: IFeatureServiceItem) => <ParityCell
          item={item}
          region={region}
          comment={getCommentToDisplay(showResolvedComments, getComment, item.id as string, region)}
          onClick={commentIconOnClick}
        />
      });
    }
  });

  const generatedVisibleColumnsFromDef = featureColumnDefs.map(def => ({
    id: def.id,
    label: def.header,
    visible: true
  }));

  const tableContentSelectorOptions = {
    title: "Select visible columns",
    options: [
      {
        options: generatedVisibleColumnsFromDef,
      }
    ]
  }

  const tableConfig = defaultTableConfig({
    items: allFeatureData,
    variant: "borderless",
    loading: loading,
    columnDefinitions: featureColumnDefs,
    contentSelectorOptions: tableContentSelectorOptions,
    filteringOptions: [],
    sortableColumns: SORTABLE_COLUMNS,
    sortingColumn: "featureParity",
  });

  const newFeatureHref = getCreateFeatureUrl(serviceName);

  const [componentServiceSelectorModalVisible, setComponentServiceSelectorVisible] = React.useState(false)
  const open_onClick = useCallback(() => {
    setComponentServiceSelectorVisible(true)
  }, []);
  const componentServiceSelectorModalOnDismiss = () => setComponentServiceSelectorVisible(false)

  const featureButtonDropdownItems = [
    {
      text: "Tag/Untag RIP services as features",
      id: FEATURE_BUTTON_DROPDOWN_IDS.TAG,
      disabled: false
    },
  ]

  if (newFeatureHref) {
    featureButtonDropdownItems.push({
      text: "Create New Feature",
      id: FEATURE_BUTTON_DROPDOWN_IDS.CREATE_NEW,
      disabled: false
    })
  }

  const onFeatureButtonDropdownClick = useCallback((event) => {
    switch (event.detail.id) {
      case FEATURE_BUTTON_DROPDOWN_IDS.TAG:
        if (!userAbilities.canWrite) {
          setShowTagUntagPermissionError(true);
        } else {
          open_onClick();
        }
        break;
      case FEATURE_BUTTON_DROPDOWN_IDS.CREATE_NEW:
        window.location.assign(newFeatureHref as string);
        break;
    }
  }, [userAbilities.canWrite, newFeatureHref, open_onClick])

  const onAddComponentSubmit = useCallback((modalSelectedComponents: ComponentFeatureVO[], modalSelectedServices: AwsServiceVO[]) => {
    return onComponentModalSubmit(modalSelectedComponents, modalSelectedServices).then(() => {
      setComponentServiceSelectorVisible(false);
      props.onSuccessfulComponentModalSubmit();
    });
  }, [onComponentModalSubmit, props]);


  const actionMenuOnChange = useCallback(async (item: IActionMenuItem) => {
    const dataSheet = generateWorkbook(serviceName, allFeatureData, regionsOrdered, displayName, getComment);
    await createSaveDataSheet(item, serviceName, dataSheet);
  }, [serviceName, allFeatureData, regionsOrdered, displayName, getComment]);

  return (
    <React.Fragment>
      <FeatureToolbar>
        <ControlSlot>
          <FeatureSelect
            featureSelectOptions={featureSelectOptions}
            selectedOption={selectedOption}
            handleFilterSelectionChange={handleFilterSelectionChange}
          />
        </ControlSlot>

        <ActionSlot>
          <SpaceBetween direction="horizontal" size="xs">
            <ButtonDropdown
              items={[
                {
                  text: "Add Comment",
                  id: COMMENTS_BUTTON_DROPDOWN_IDS.ADD_FEATURE_IN_REGION_COMMENT,
                  disabled: false
                },
                {
                  text: `${showResolvedComments ? "Hide" : "Show"} Resolved Comments`,
                  id: COMMENTS_BUTTON_DROPDOWN_IDS.TOGGLE_RESOLVED_COMMENTS,
                  disabled: false
                },
              ]}
              loading={loading}
              data-testid={SSFeatureParityTableTestId.commentButtonDropdown}
              onItemClick={onCommentButtonDropdownClick}
            >
              Comments
            </ButtonDropdown>
            <ButtonDropdown
              items={featureButtonDropdownItems}
              loading={loading}
              data-testid={SSFeatureParityTableTestId.featureButtonDropdown}
              onItemClick={onFeatureButtonDropdownClick}
            >
              Actions
            </ButtonDropdown>
            <ActionMenu
              items={actionMenu}
              icon={"download"}
              onChange={actionMenuOnChange}
              data-testid={componentButtonTestId.download}
            />
          </SpaceBetween>
          <PermissionError
            header="Access Denied"
            body={<>
              Need bindle permission for {displayName} or Recon Write permission to add comments, please use {WriteAccessSimTemplateLink} to request access to Recon Write permission.
            </>}
            visible={showCommentAddPermissionError}
            onDismiss={() => setShowCommentAddPermissionError(false)}
            testIds={SSFeatureParityTableTestId.addCommentPermissionError}
          />
          <PermissionError
            header="Access Denied"
            body={<>
              Need bindle permission for {displayName} or Recon Write permission to tag/untag RIP services as features, please use {WriteAccessSimTemplateLink} to request access to Recon Write permission.
            </>}
            visible={showTagUntagPermissionError}
            onDismiss={() => setShowTagUntagPermissionError(false)}
            testIds={SSFeatureParityTableTestId.tagUntagPermissionError}
          />
          <ComponentServiceSelector
            visible={componentServiceSelectorModalVisible}
            onDismiss={componentServiceSelectorModalOnDismiss}
            allComponents={allComponents}
            allServices={allServices}
            initiallySelectedComponents={selectedComponents}
            initiallySelectedServices={selectedServices}
            isFeatureComponentLoading={loading}
            allServicesLoadingState={allServicesLoadingState}
            onSubmit={onAddComponentSubmit}
            serviceName={displayName}
          />
          <FeatureInRegionCommentModal
            onDismiss={onCommentDisplayModalDismiss}
            serviceLongName={displayName}
            {...commentModalAction}
            onSubmit={(comments: FeatureInRegionCommentVO[]): Promise<boolean> => {
              return onCommentSubmit(comments)
            }}
          />
        </ActionSlot>
      </FeatureToolbar>

      <RegionsTable
        testId={SSFeatureParityTableTestId.table}
        {...tableConfig}
        TablePagination={{ pageSize: 20 }}
      />
      <FeatureParityKey />
    </React.Fragment>
  );
};
