import {
  BoundPage,
  ExternalAppNames,
  HandleAnalyticsProps,
  LaunchResource,
  Page,
  ParityPage,
  ProductResource,
  Resource,
  ServiceResource,
} from "@amzn/region-services-react-library-v4"
import { recordFeedback } from "@amzn/regions-unified-frontend-analytics-tracking-platform";
import { useMemo } from "react";
import { match, matchPath, useLocation } from "react-router";
import { Nullable } from "../../models/types/Nullable";
import { Optional } from "../../models/types/Optional";
import { IRouteDefinition } from "../../routes/IRouteDefinition";
import { getUnifiedStage } from "../../utils/environmentUtil";
import { IRouteParam, IUnifiedUIViewModel, IUnifiedUIViewModelState } from "./unified.types";
import { getAppState } from "../../models/ApplicationState";
import { generateALCLink } from "../LaunchDetails/launchDetailsUtils";

export const getUnifiedResourceArgs = (
  constructor: { new(...args: any[]): Resource },
  params: Optional<IRouteParam>,
  state: IUnifiedUIViewModelState
): any[] => {
  switch (constructor) {
    case ServiceResource:
      return [
        params?.serviceName
      ];
    case ProductResource:
      return [
        params?.productId,
        formatName(state.product.name || "Loading...", 40)
      ];
    case LaunchResource:
      return [
        params?.launchId,
        formatName(state.launch.name || "Loading...", 40),
        state.launch.productId ?? "",
        state.product.name || "Loading...",
        undefined,
        generateALCLink(state.launch),
      ];
    default:
      return [
        params?.regionName
      ]
  }
}

export const handleAnalytics = ({
  name,
  path,
  query,
  detail,
}: HandleAnalyticsProps) => {
  return recordFeedback({
    name: name,
    path: path,
    query: query,
    detail: detail,
  });
};

const formatName = (name, length) => {
  if (name.length > length) {
    let lastIndex = name.lastIndexOf(" ", length-1);
    lastIndex = lastIndex > length/2 ? lastIndex : length-3;

    return name.substring(0, lastIndex) + "...";
  }
  return name;
}

export const useUnifiedUIViewModel = (
  routes: IRouteDefinition[],
  state: IUnifiedUIViewModelState
): IUnifiedUIViewModel => {
  const location = useLocation();
  const { userAlias: userId } = getAppState();

  return useMemo(() => {
    const stage = getUnifiedStage();
    const activeContext = {
      stage,
      app: ExternalAppNames.RECON,
      userId,
    }

    let resource: Nullable<Resource> = null;
    let page: Optional<BoundPage>;
    let constructor: Nullable<{new(...args: any[]): Resource}> = null;
    let bannerVisible = false;

    const matchedRoute: Optional<IRouteDefinition> = routes.find((route: IRouteDefinition) =>
      matchPath(location.pathname, {
        path: route.path,
        exact: route.exact
      }
    ));

    const routeWithParam: Nullable<match<IRouteParam>> = matchPath<IRouteParam>(
      location.pathname,
      matchedRoute?.path as string
    );

    if (matchedRoute) {
      constructor = matchedRoute?.resourceConstructor;
      resource = new constructor(...getUnifiedResourceArgs(constructor, routeWithParam?.params, state));

      if (matchedRoute.unifiedHeader) {
        bannerVisible = true;
      }

      const pageClass: new () => Page = matchedRoute.unifiedPage ?? ParityPage;
      page = new pageClass().applyTo(resource);
    }

    return {
      activeContext,
      resource,
      page,
      constructor,
      bannerVisible,
    }
  }, [location.pathname, routes, state, userId]);
}

