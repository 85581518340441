import styled from "styled-components";

const ModalHeader = styled.div`
  h5.inline-header-subtext {
    color: #879596;
    font-style: italic;
  }
`;

export default ModalHeader;
