import React, { useCallback, useEffect, useState } from "react";
import PubSub from "pubsub-js";
import _ from "lodash";
import { BoundPage, TitleBanner as BaseTitleBanner } from "@amzn/region-services-react-library-v4";

export interface ITitleBannerProperties {
  visible: boolean;
  title?: string;
  details?: {
    info?: string | JSX.Element;
    favorite?: boolean;
    favoriteClick?: () => void;
    badgeText?: string;
    badgeColor?: "blue" | "grey" | "green" | "red";
  }
}

export const TitleBanner = ({ activeContext, boundPage, overrideBreadcrumbsProps }) => {
  const [props, setProps] = useState<ITitleBannerProperties>({ visible: false });

  const handleMessage = (topic, data) => {
    if (data === null || _.isEmpty(data))
      setProps({ visible: false });
    else {
      setProps(data);
    }
  }

  const getTitleBannerProps = useCallback((props: ITitleBannerProperties) => {
    let titleProps: any = {
      title: props.title ?? "",
      variant: "main"
    };
    if (props.details) {
      titleProps = {
        ...titleProps,
        variant: "main",
        info: props.details.info ?? "No info",
        badgeProps: {
          text: props.details.badgeText ?? "",
          color: props.details.badgeColor ?? "blue",
        },
        favoriteProps: {
          isFavorite: props.details.favorite,
          onClick: props.details.favoriteClick
        }
      }
    }

    return titleProps;
  }, []);

  useEffect(() => {
    const token = PubSub.subscribe("TITLEBANNER", handleMessage);

    return () => {
      PubSub.unsubscribe(token);
    }
  }, []);

  return (
    props.visible
      ? <BaseTitleBanner
          activeContext={activeContext}
          boundPage={boundPage as BoundPage}
          overrideBreadcrumbsProps={overrideBreadcrumbsProps}
          {...getTitleBannerProps(props)}
        />
      : <></>
  );
}
