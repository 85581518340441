export const noDeleteInputError: string = "Enter the phrase in the text field above.";
export const deleteInputInvalidError: string = "Incorrect input. Please make sure the entry matches the text above.";

export const getDeleteInputErrorIfInvalid = (value: string, deleteInputPhrase: string) : string | boolean => {
  if (value !== deleteInputPhrase){
    return deleteInputInvalidError;
  }
  else {
    return true;
  }
}
