import { QaItemDefinition } from "../../models/QaItemDefinition";
import { updateServicePlanOperation } from "../../remoteOperations/serviceOperation";
import { updateServicePlanSuccessChecker } from "../../checkers/updateServiceChecker";
import { IGenericMutationResponseDto } from "../../../daos/types/IGenericMutationResponseDto";
import {
  removeBusinessCaseOverrideInGAOperation,
  removeEstimatedDateOperation,
  updateBusinessCaseOverrideInGAOperation
} from "../../remoteOperations/planOperation";
import {
  removeBusinessCaseOverrideSuccessChecker,
  removeEstimatedDateSuccessChecker,
  updateBusinessCaseOverrideSuccessChecker
} from "../../checkers/updatePlanChecker";

export const updateServicePlan = new QaItemDefinition<IGenericMutationResponseDto>(
  "PATCH /api/services Update service category",
  updateServicePlanOperation,
  updateServicePlanSuccessChecker
);

export const updateBusinessCaseOverrideInGA = new QaItemDefinition<IGenericMutationResponseDto>(
  "PATCH /api/services/.../plans/... Business Case Override",
  updateBusinessCaseOverrideInGAOperation,
  updateBusinessCaseOverrideSuccessChecker
);

export const removeBusinessCaseOverrideInGA = new QaItemDefinition<IGenericMutationResponseDto>(
  "PATCH /api/services/.../plans/... Remove Business Case Override",
  removeBusinessCaseOverrideInGAOperation,
  removeBusinessCaseOverrideSuccessChecker
);


export const removeEstimatedDate = new QaItemDefinition<IGenericMutationResponseDto>(
  "PATCH /api/services/.../plans/... Remove estimated date",
  removeEstimatedDateOperation,
  removeEstimatedDateSuccessChecker
);
