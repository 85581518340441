import { Audience } from "./Audience";

export enum ProductContactType {
  PM = "PM",
  PMM = "PMM",
  SDM = "SDM",
  GM = "GM",
  VP = "VP",
  OTHER = "Other",
}

export interface IProductContact {
  contactType: ProductContactType;
  alias: string;
}

// TODO: - update metadata object with more accurate types
//       - verify this model matches product schema
export interface IProductDto {
  id: string;
  parentProductId?: string;
  activeState?: string;
  productName?: string;
  codename?: string;
  ripId?: string;
  blueprintUid?: string;
  description?: string;
  gm?: string;
  vp?: string;
  awsCategory?: string;
  audience?: Audience;
  productType?: string;
  prfaqLink?: string;
  productCti?: string;
  confidential?: boolean;
  primaryOwner?: IProductContact;
  secondaryOwners?: IProductContact[];
  productApprovalDate?: string;
  productApprovedBy?: string;
  childProductIds?: string[];
  primaryLaunchIds?: string[];
  docReviews?: string[];
  followers?: string[];
  formerName?: string[];
  lastUpdateTime?: string;
  lastModifiedBy?: string;
  tags?: string[];
  writeGroups?: string[];
  readGroups?: string[];
  rowId?: string;
}
