import * as React from "react";
import { LegendItem } from "@amzn/api-parity-react-component/lib/components/status-grid/legend-item/LegendItem";
import { Legend } from "@amzn/api-parity-react-component/lib/components/status-grid/grid/StatusGrid.style";
import { Icon } from "@amzn/awsui-components-react-v3";
import styled, { css } from "styled-components";
import * as awsui from "@amzn/awsui-design-tokens/polaris";
import { GENERALLY_AVAILABLE, INTERNALLY_AVAILABLE } from "../../../constants";

const sharedFontSize = css`
  font-size: 10px;
`

const StyledNp = styled("div")`
  color: ${awsui.colorTextStatusError};
  ${sharedFontSize};
`;

const StyledSharedIcon = styled("div")`
  ${sharedFontSize};
`;

const npAsIcon = <StyledNp>NP</StyledNp>;

const inProgressAsIcon = (status) => <StyledSharedIcon>{status}</StyledSharedIcon>;

const gaAsIcon = (
  <StyledSharedIcon>
    <Icon variant="success" name="status-positive" />
  </StyledSharedIcon>
);

const StyledLegend = styled(Legend)`
  margin-top: 0;
  padding-top: 0;
`

const StyledLegendItem = styled(LegendItem)`
  background-color: ${awsui.colorBackgroundContainerContent};
  padding: 5px 10px;
`;

export const FeatureParityKey = () => (
  <StyledLegend columns={5}>
    <StyledLegendItem
      icons={[ npAsIcon ]}
      tooltip={"The Service Team has not provided any plans"}
      label={"No Plan"} />
    <StyledLegendItem
      icons={[ inProgressAsIcon("Pl") ]}
      tooltip={"The Service Team has Planned the work"}
      label={"Planned"} />
    <StyledLegendItem
      icons={[ inProgressAsIcon("Bu") ]}
      tooltip={"The Service Team is Building"}
      label={"Build"} />
    <StyledLegendItem
      icons={[ inProgressAsIcon("IA") ]}
      tooltip={"The Feature is Internally Available"}
      label={INTERNALLY_AVAILABLE} />
    <StyledLegendItem
      icons={[ gaAsIcon ]}
      tooltip={"The Feature has Launched in the Region"}
      label={GENERALLY_AVAILABLE} />
  </StyledLegend>
);
