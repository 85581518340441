import * as React from "react";
import { Box, ExpandableSection, Header, Link } from "@amzn/awsui-components-react-v3";
import { Columnize } from "@amzn/api-parity-react-component/lib/components/containers/Columnize";
import { Attribute } from "@amzn/api-parity-react-component/lib/components/attribute/Attribute";
import {
  azServiceOwnerField,
  buildTPMField,
  ctiField,
  gmField,
  inRmsBuildGraphField,
  leadershipChainField,
  opsDashboardField
} from "../SingleServiceContacts/SingleServiceContactsFieldData";
import { localZoneServiceOwnerField, notificationAliasField, oncallTeamField, primaryBuildPocField } from "../SingleServiceContacts/SingleServiceContactsFieldData";
import { ripContactsField, secondaryBuildPocField, simGuidField, vpceBuildOwnerField, vpField, wikiField } from "../SingleServiceContacts/SingleServiceContactsFieldData";
import { LeadershipChainComponent } from "../ServiceContactsChainComponent/LeadershipChainComponent";
import User from "../../common/User";
import { displayOr } from "../../../utils/displayUtil";
import { CtiVO } from "../../../models/vos/CtiVO";
import { PARTITION_TO_LONG_NAME } from "../EnterServiceContact/AddOrEditServiceContactModal/PartitionFormFields/PartitionFormFields";
import { ButtonBar } from "../../common/ButtonBar/ButtonBar";
import { AddOrEditServiceContactModal } from "../EnterServiceContact/AddOrEditServiceContactModal/AddOrEditServiceContactModal";
import { EditGmVp } from "../EditGmVp/EditGmVp";
import { UserAbilities } from "../../../models/UserAbilities";
import { CopyToClipboardButton } from "../../common/CopyToClipboardButton";
import { ServiceContactsVO } from "../../../models/vos/ServiceContactsVO";
import { Optional } from "../../../models/types/Optional";
import { normalizeServiceName } from "../../../daos/daoUtil";
import { FunctionComponent } from "react";
import { CTIComponentProps } from "../ServiceContactsListTable/ServiceContactsTableOptions";
import { CtiTicketButton } from "../../CtiTicketButton/CtiTicketButton";
import { composeDataTestId } from "@amzn/api-parity-react-component/lib/utils/testUtil";
import { AuditTable } from "../../AuditTable/AuditTable";

export const SingleServiceContactCardsConfigTestId = {
  editContact: "SingleServiceContactsCards-editContact",
  newCtiTicket: "SingleServiceContactsCards-newCtiTicket",
  newPartition: "SingleServiceContactsCards-newPartition",
  editGmVp: "SingleServiceContactsCards-editGmVp",
  empty: "SingleServiceContactsCards-empty",
  header: "SingleServiceContactsCards-header",
  leadership: "SingleServiceContactsCards-leadership",
  teamPoc: "SingleServiceContactsCards-teamPoc",
  emails: "SingleServiceContactsCards-emails",
  operations: "SingleServiceContactsCards-operations",
  auditTable: "SingleServiceContactsCards-auditTable",
}

export const NoContactDisplay = "No Contacts For Service";

export const EMPTY_CONTACT = (callbacks, userPermissions: UserAbilities, ripName: string) => {
  return (
    <Box data-testid={SingleServiceContactCardsConfigTestId.empty} textAlign="center" color="inherit">
      <b>No Contacts</b>
      <Box padding={{ bottom: "s" }} variant="p" color="inherit">
        {NoContactDisplay}
      </Box>
      {userPermissions.canWriteContacts &&
        <AddOrEditServiceContactModal
          data-testid={SingleServiceContactCardsConfigTestId.newPartition}
          isAdding={true}
          isAddingNewPartition={true}
          userAbilities={userPermissions}
          onSuccessfulSubmit={callbacks.onSubmit}
          onAddOrEditServiceContactSubmit={callbacks.onEditServiceContactsSubmit}
          nameRip={ripName}/>
      }
    </Box>
  )
}

export const CTIComponentWithSIM: FunctionComponent<CTIComponentProps> = (props) => {
  return (
    <React.Fragment>
      <Link
        href={`https://cti.amazon.com/cti/category/${props.cti.category}/type/${props.cti.type}/item/${props.cti.item}`}
        external={true}>
        {`${props.cti.category}/${props.cti.type}/${props.cti.item}`}
      </Link>
      {" "}
      <CtiTicketButton cti={props.cti} data-testid={SingleServiceContactCardsConfigTestId.newCtiTicket} />
    </React.Fragment>
  )
}

function commaSeparatedRipContacts(selectedContact: ServiceContactsVO): string {
  return humanReadableRipContacts(selectedContact.ripContacts, "@", ", ", "-");
}

function outlookFriendlyRipContacts(selectedContact: ServiceContactsVO): string {
  return humanReadableRipContacts(selectedContact.ripContacts, "@amazon.com", "; ");
}

function ripContactsDisplay(selectedContact: ServiceContactsVO) {
  return (
    <React.Fragment>
      {displayOr(selectedContact.ripContacts, () => commaSeparatedRipContacts(selectedContact))}
      {selectedContact.ripContacts && selectedContact.ripContacts?.length > 0 &&
        (<CopyToClipboardButton
          text={outlookFriendlyRipContacts(selectedContact)}
          notification="Copied emails"
        />)}
    </React.Fragment>
  )
}

function notificationAliasDisplay(selectedContact: ServiceContactsVO) {
  return (
    <React.Fragment>
      {displayOr(selectedContact.notifications, () => selectedContact.notifications + "@")}
      {selectedContact.notifications &&
        (<CopyToClipboardButton
          text={selectedContact.notifications + "@amazon.com;"}
          notification="Copied emails"
        />)}
    </React.Fragment>
  )
}

export const humanReadableRipContacts = (
  ripContacts: Optional<string[]>,
  suffix: string,
  joinString: string,
  defaultValueIfEmpty: string = ""): string => {
  if (ripContacts && ripContacts.length > 0) {         // "if (ripContacts) alone doesn't match an empty array
    return ripContacts
      .filter(x => !!x)                        // Filter out null, "", etc.
      .map(x => `${x.trim()}${suffix || ""}`)  // append the suffix passed in (don't let output string have "null" in it!)
      .join(joinString || "");                 // make comma-separated string (don't let output string have "null" in it!)
  } else {
    return defaultValueIfEmpty;
  }
};

export function removeWikiPrefix(fullWikiUrl: Optional<string>): string {
  if (!fullWikiUrl) {
    return ""
  }

  const wikiPrefix = "https://w.amazon.com/bin/view/"
  const characterToRemove = wikiPrefix.length;

  return (fullWikiUrl.startsWith(wikiPrefix) ? fullWikiUrl.substr(characterToRemove) : fullWikiUrl)
}

export function generateCommercialPartitionLink(serviceName: string): string {
  const validUriServiceName: string = encodeURI(serviceName)
  const sanitizedServiceName: string = normalizeServiceName(validUriServiceName);
  const serviceCommercialPartitionLink = `https://chainreaction.corp.amazon.com/services/${sanitizedServiceName}/builds/COMMERCIAL_PARTITION_TEMPLATE`

  return serviceCommercialPartitionLink
}

export function generateInRmsBuildGraphAttrValue(serviceContact: Optional<ServiceContactsVO>) {
  const IN_RMS_GRAPH: string = "Yes"
  const NOT_IN_RMS_GRAPH: string = "No"

  if (serviceContact?.inRmsGraph) {
    return (
      <Link
        href={generateCommercialPartitionLink(serviceContact.serviceName)}
        external={true}>
        {IN_RMS_GRAPH}
      </Link>
    )
  }
  return NOT_IN_RMS_GRAPH
}

export function cardLayoutDefinition(callbacks, userPermissions: UserAbilities) {
  return {
    header: selectedContact =>
      <Header
        data-testid={composeDataTestId(SingleServiceContactCardsConfigTestId.header, selectedContact.partition)}
        actions={
          <ButtonBar>
            {userPermissions.canWriteContacts &&
              <AddOrEditServiceContactModal
                data-testid={composeDataTestId(SingleServiceContactCardsConfigTestId.editContact, selectedContact.partition)}
                selected={selectedContact}
                isAdding={false}
                userAbilities={userPermissions}
                onSuccessfulSubmit={callbacks.onSubmit}
                onAddOrEditServiceContactSubmit={callbacks.onEditServiceContactsSubmit}
              />}

            {userPermissions.canWriteBusinessCaseOverride &&
              <EditGmVp
                data-testid={composeDataTestId(SingleServiceContactCardsConfigTestId.editGmVp, selectedContact.partition)}
                contact={selectedContact}
                permission={userPermissions}
                onSubmit={callbacks.onEditGmVpCallback}
              />}

          </ButtonBar>
        }>
        {PARTITION_TO_LONG_NAME[selectedContact.partition] || selectedContact.partition}
      </Header>,
    sections: [
      {
        content: selectedContact => (
          <ExpandableSection data-testid={composeDataTestId(SingleServiceContactCardsConfigTestId.leadership, selectedContact.partition)} defaultExpanded header="Leaderships">
            <Columnize columns={3}>
              <Attribute label={gmField.label} value={displayOr(selectedContact.gm, () => <User alias={selectedContact.gm as string} />)} info={gmField.info} />
              <Attribute label={vpField.label} value={displayOr(selectedContact.vp, () => <User alias={selectedContact.vp as string} />)} info={vpField.info} />
              <Attribute label={leadershipChainField.label}
                value={displayOr(selectedContact.leadershipChain, () =>
                  <LeadershipChainComponent gm={selectedContact.gm}
                    vp={selectedContact.vp}
                    leadershipChain={selectedContact.leadershipChain}
                    style="vertical"
                  />)}
                info={leadershipChainField.info}
              />
            </Columnize>
          </ExpandableSection>
        )
      },
      {
        content: selectedContact => (
          <ExpandableSection data-testid={composeDataTestId(SingleServiceContactCardsConfigTestId.teamPoc, selectedContact.partition)} defaultExpanded header="Team Point of Contacts">
            <Columnize columns={3}>
              <Attribute label={primaryBuildPocField.label} value={displayOr(selectedContact.primaryBuildPoc, () => <User alias={selectedContact.primaryBuildPoc} />)} info={primaryBuildPocField.info} />
              <Attribute label={secondaryBuildPocField.label} value={displayOr(selectedContact.secondaryBuildPoc, () => <User alias={selectedContact.secondaryBuildPoc} />)} info={secondaryBuildPocField.info} />
              <Attribute label={buildTPMField.label} value={displayOr(selectedContact.rbtServiceManager, () => <User alias={selectedContact.rbtServiceManager} />)} info={buildTPMField.info} />
            </Columnize>
            <Columnize columns={3}>
              <Attribute label={azServiceOwnerField.label} value={displayOr(selectedContact.azServiceOwner, () => <User alias={selectedContact?.azServiceOwner as string} />)} info={azServiceOwnerField.info} />
              <Attribute label={localZoneServiceOwnerField.label} value={displayOr(selectedContact.localZoneServiceOwner, () => <User alias={selectedContact?.localZoneServiceOwner as string} />)} info={localZoneServiceOwnerField.info} />
              <Attribute label={vpceBuildOwnerField.label} value={displayOr(selectedContact.vpceBuildOwner, () => <User alias={selectedContact?.vpceBuildOwner as string} />)} info={vpceBuildOwnerField.info} />
            </Columnize>
          </ExpandableSection>
        )
      },
      {
        content: selectedContact => (
          <ExpandableSection data-testid={composeDataTestId(SingleServiceContactCardsConfigTestId.emails, selectedContact.partition)} defaultExpanded header="Emails">
            <Columnize columns={3}>
              <Attribute label={notificationAliasField.label} value={notificationAliasDisplay(selectedContact)} info={notificationAliasField.info} />
              <Attribute label={ripContactsField.label} value={ripContactsDisplay(selectedContact)} info={ripContactsField.info} />
            </Columnize>
          </ExpandableSection>
        )
      },
      {
        content: selectedContact => (
          <ExpandableSection data-testid={composeDataTestId(SingleServiceContactCardsConfigTestId.operations, selectedContact.partition)} defaultExpanded header="Operations">
            <Columnize columns={3}>
              <Attribute label={ctiField.label} value={displayOr(selectedContact?.cti?.category, () => <CTIComponentWithSIM cti={selectedContact.cti as CtiVO} />)} info={ctiField.info} />
              <Attribute label={wikiField.label} value={displayOr(selectedContact?.wikiUrl, () =>
                <Link
                  href={selectedContact?.wikiUrl}
                  external={true}>
                  {removeWikiPrefix(selectedContact.wikiUrl)}
                </Link>)}
                info={wikiField.info} />
              <Attribute label="Oncall Team" value={displayOr(selectedContact.oncallTeam, () =>
                <Link
                  href={"https://oncall.corp.amazon.com/#/view/" + selectedContact.oncallTeam}
                  external={true}>
                  {selectedContact.oncallTeam}
                </Link>)}
                info={oncallTeamField.info} />
            </Columnize>
            <Columnize columns={3}>
              <Attribute label={inRmsBuildGraphField.label} value={generateInRmsBuildGraphAttrValue(selectedContact)} info={inRmsBuildGraphField.info} />
              <Attribute label={simGuidField.label} value={displayOr(selectedContact.simGuid)} info={simGuidField.info} />
              <Attribute label={opsDashboardField.label} value={displayOr(selectedContact.opsDashboard, () =>
                <Link
                  href={selectedContact.opsDashboard}
                  external={true}>
                  Dashboard
                </Link>)}
                info={opsDashboardField.info} />
            </Columnize>
          </ExpandableSection>
        )
      },
      {
        content: selectedContact => (
          <ExpandableSection header={"Audit"}>
            <AuditTable data-testid={composeDataTestId(SingleServiceContactCardsConfigTestId.auditTable, selectedContact.partition)} audit={selectedContact.audit}/>
          </ExpandableSection>
        )
      }
    ]
  }
}
