import styled from "styled-components";
import { Table } from "rsuite-table";
import * as awsui from "@amzn/awsui-design-tokens";
import { Legend } from "@amzn/api-parity-react-component/lib/components/status-grid/grid/StatusGrid.style";
import { LegendItem } from "@amzn/api-parity-react-component/lib/components/status-grid/legend-item/LegendItem";

export const StyledTable = styled(Table)`
  * {
    box-sizing: border-box;
  }
  
  [data-depth="0"] {
    border-top: solid 1px ${awsui.colorBorderDividerDefault};
    font-weight: bold;
  }
  
  .rs-table-cell-header {
    [role="columnheader"] {
      .rs-table-cell-content {
        font-size: 14px;
        font-weight: bold;
        color: ${awsui.colorTextBodySecondary};
        vertical-align: bottom !important; // Important is to override the inline style
      }
      background-color: ${awsui.colorBackgroundContainerHeader};
    }
    .rs-table-column-resize-spanner {
      border-left-color: ${awsui.colorBorderDividerDefault};
      border-left-width: 1px;
    }
  }
  
  [role="gridcell"][aria-colindex="1"] {
    pointer-events: none;
    .rs-table-cell-expand-icon {
      pointer-events: auto;
      display: inline-block;
    }
    a {
      pointer-events: auto;
    }
  }
  
  .rs-table-cell-first {
    padding-left: 1.5rem;
  }
  
  color: ${awsui.colorTextBodyDefault};
`;

export const RegionHeader = styled.div`
  transform: rotate(180deg);
  writing-mode: vertical-lr;
  text-align: start;
  margin-left: -5px; // Shift the text by 2px to make it align better
`;

export const RegionHeaderPercentage = styled.span`
  font-weight: normal;
  display: inline-block;
  min-height: 3.25rem;
  text-align: right;
`;

export const RegionHeaderText = styled.span`
  display: inline-block;
  min-height: 3rem;
`;

export const ParityScore = styled.div`
  margin-right: 2.5rem;
`;


export const RipId = styled.small`
  display: inline-block;
  margin-left: 0.25rem;
`;


export interface IItemNameProp {
  hasChildren?: boolean
}

const noChildItemIndentation = 2.5;
export const ItemName = styled.span<IItemNameProp>`
  display: inline-block;
  margin-left: ${(props) => (props.hasChildren) ? 0 : noChildItemIndentation}rem;
`;


export interface IParityDateCompactProps {
  delinquent?: boolean;
}
export const ParityDateCompact = styled.span<IParityDateCompactProps>`
  color: ${ (props) => (props.delinquent) ? awsui.colorTextStatusError : "inherit" };
  letter-spacing: -0.1rem;
`;

export const StyledLegend = styled(Legend)`
  margin-top: 0;
  padding-top: 0;
`

export const StyledLegendItem = styled(LegendItem)`
  background-color: ${awsui.colorBackgroundContainerContent};
  padding: 5px 10px;
`;


