import { createSelector, Selector } from "reselect";
import { Column } from "rsuite-table";
import {
  FeatureParityOverviewGridPropsWithUserInput,
  IFeatureParityOverviewGridViewModel
} from "./FeatureParityOverviewGrid.types";
import { FeatureTreeItem } from "../../../../../models/FeatureTreeItem";
import {
  AwsServiceCollectionView,
  RegionCollectionView, ServiceBuildPlanCollectionView,
} from "@amzn/api-parity-react-component";
import { getColumns } from "./FeatureParityOverviewGrid.util";
import { sort } from "@amzn/api-parity-react-component/lib/utils/sortUtil";
import { ISortComparator, SortByDirection } from "@amzn/api-parity-react-component/lib/models/SortBy";
import { getAutoExpandItems } from "../../../../../utils/treeItemUtil";
import { Optional } from "../../../../../models/types/Optional";
import {
  FeatureParityDataSortKey,
  getCategorySort,
  getGmSort,
  getParityScoreSort,
  getVisibilitySort,
  getVpSort
} from "../models/FeatureParityDataSortKey";
import {
  FeatureCollectionView,
  FeatureParityCollectionView,
  ServiceInRegionRollupStatusCollectionView
} from "../../../../../models/collections";
import {
  FeatureParityScoreCalculator,
  FeatureParityScoreCalculatorResult
} from "../../../../../utils/FeatureParityScoreCalculator";

type PropSelector<R> = Selector<FeatureParityOverviewGridPropsWithUserInput, R>;

// region input selector
const featuresInputSelector: PropSelector<FeatureTreeItem[]> = (props) => props.features;
const regionsInputSelector: PropSelector<RegionCollectionView> = (props) => props.regions;
const requestedCollapsedIdsInputSelector: PropSelector<ReadonlySet<string>> = (props) => props.requestedCollapsedIds;
const requestedExpandedIdsInputSelector: PropSelector<ReadonlySet<string>> = (props) => props.requestedExpandedIds;
const sortKeyInputSelector: PropSelector<Optional<string>> = (props) => props.sortKey;
const sortDirectionInputSelector: PropSelector<Optional<SortByDirection>> = (props) => props.sortDirection;
const featureParitiesInputSelector: PropSelector<FeatureParityCollectionView> = (props) => props.featureParities;
const serviceParitiesInputSelector: PropSelector<ServiceBuildPlanCollectionView> = (props) => props.serviceParities;
const featureParityRollupByServiceInputSelector: PropSelector<ServiceInRegionRollupStatusCollectionView> = (props) => props.featureParityRollupByService;
const zeroAvailabilityFeaturesInputSelector: PropSelector<FeatureCollectionView> = (props) => props.zeroAvailabilityFeatures;
const zeroAvailabilityServicesInputSelector: PropSelector<AwsServiceCollectionView> = (props) => props.zeroAvailabilityServices;
// endregion

export const sortKeySelector: PropSelector<string> = createSelector(
  sortKeyInputSelector,
  (sortKey) => sortKey ?? FeatureParityDataSortKey.category
);

export const sortDirectionSelector: PropSelector<SortByDirection> = createSelector(
  sortDirectionInputSelector,
  (direction) => direction ?? SortByDirection.Ascending
);

export const parityScoreResultSelector: PropSelector<FeatureParityScoreCalculatorResult> = createSelector(
  featuresInputSelector,
  regionsInputSelector,
  featureParityRollupByServiceInputSelector,
  featureParitiesInputSelector,
  zeroAvailabilityFeaturesInputSelector,
  zeroAvailabilityServicesInputSelector,
  (
    featureTreeItems,
    regions,
    featureParityRollupByService,
    featureParities,
    zeroAvailabilityFeatures,
    zeroAvailabilityServices,
  ) => {
    const calculator: FeatureParityScoreCalculator = new FeatureParityScoreCalculator({
      regions,
      featureParityRollupByService,
      featureParities,
      zeroAvailabilityFeatures,
      zeroAvailabilityServices,
    });

    calculator.calculate(featureTreeItems);
    return calculator.result;
  }
);

export const getSortDefinition = (
  scoreResult: FeatureParityScoreCalculatorResult,
  sortKey: string,
  sortDirection: SortByDirection,
) => {
  switch (sortKey) {
    case FeatureParityDataSortKey.gm:
      return getGmSort(sortDirection);

    case FeatureParityDataSortKey.vp:
      return getVpSort(sortDirection);

    case FeatureParityDataSortKey.category:
      return getCategorySort(sortDirection, scoreResult.byEntity);

    case FeatureParityDataSortKey.parityScore:
      return getParityScoreSort(sortDirection, scoreResult.byEntity);

    case FeatureParityDataSortKey.visibility:
      return getVisibilitySort(sortDirection);

    default:
      return getCategorySort(sortDirection, scoreResult.byEntity);
  }
};

export const sortDefinitionSelector: PropSelector<readonly ISortComparator<FeatureTreeItem>[]> = createSelector(
  parityScoreResultSelector,
  sortKeySelector,
  sortDirectionSelector,
  (
    scoreResult,
    sortKey,
    sortDirection,
  ) => {
    return getSortDefinition(scoreResult, sortKey, sortDirection);
  }
)


export const sortedFeaturesSelector: PropSelector<FeatureTreeItem[]> = createSelector(
  featuresInputSelector,
  sortDefinitionSelector,
  (
    features: readonly FeatureTreeItem[],
    sortDefinition: readonly ISortComparator<FeatureTreeItem>[],
  ) => {
    const sorted = sort(features, sortDefinition);
    sorted.forEach((item) => item.applySort(sortDefinition));
    return sorted;
  }
)

// @ts-ignore
export const columnsSelector: PropSelector<readonly typeof Column[]> = createSelector(
  regionsInputSelector,
  featureParitiesInputSelector,
  featureParityRollupByServiceInputSelector,
  parityScoreResultSelector,
  (
    regions,
    featureParities,
    featureParityRollupByService,
    scoreResult,
  ) => getColumns(regions, featureParities, featureParityRollupByService, scoreResult)
);

export const suggestedAutoExpandIdsSelector: PropSelector<Set<string>> = createSelector(
  featuresInputSelector,
  (items) => {
    const autoExpandItems: readonly FeatureTreeItem[] = getAutoExpandItems(items) as readonly FeatureTreeItem[];
    return new Set( autoExpandItems.map((item) => item.id) );
  }
)

/**
 * Combine suggested auto expand ids with what users explicitly open/close ids together,
 * so those open/close by users remain the state as intended
 * and those not explicitly open/close by user would be auto expanded
 */
export const finalExpandedIdsSelector: PropSelector<ReadonlySet<string>> = createSelector(
  suggestedAutoExpandIdsSelector,
  requestedCollapsedIdsInputSelector,
  requestedExpandedIdsInputSelector,
  (
    autoExpandedIds,
    requestedCollapsedIds,
    requestedExpandedIds,
  ) => {
    const result: Set<string> = new Set(autoExpandedIds);
    for (const id of Array.from(requestedExpandedIds.values())) {
      result.add(id);
    }
    for (const id of Array.from(requestedCollapsedIds.values())) {
      result.delete(id);
    }
    return result;
  }
);

export const viewModelSelector: PropSelector<IFeatureParityOverviewGridViewModel> = createSelector(
  sortedFeaturesSelector,
  columnsSelector,
  regionsInputSelector,
  finalExpandedIdsSelector,
  sortKeySelector,
  sortDirectionSelector,
  parityScoreResultSelector,
  serviceParitiesInputSelector,
  featureParitiesInputSelector,
  featureParityRollupByServiceInputSelector,
  (
    features,
    columns,
    regions,
    expandedIds,
    sortKey,
    sortDirection,
    parityScore,
    serviceParities,
    featureParities,
    featureParityRollupByService,
  ) => {
    return {
      columns,
      data: features,
      regions,
      expandedIds: Array.from(expandedIds),
      sortKey,
      sortDirection,

      // for export
      parityScore,
      serviceParities,
      featureParities,
      featureParityRollupByService,
    }
  }
);
