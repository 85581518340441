import { LaunchVO } from "../../models/vos/LaunchVO";
import { IHasDataTestId } from "../../models/IHasDataTestId";
import { NonCancelableCustomEvent } from "@amzn/awsui-components-react-v3/polaris/internal/events";
import { CollectionPreferencesProps, PaginationProps, PropertyFilterProps } from "@amzn/awsui-components-react-v3";
import { hydrateModel } from "../../utils/modelUtil";
import {
  LaunchAudience,
  LaunchDisclosure,
  LaunchGoalType,
  LaunchIteration,
  LaunchScope,
  LaunchState,
  LaunchStatus,
  LaunchTier,
  LaunchType
} from "./LaunchWizard/LaunchWizard.types";

// "View model" for the table, turns VO into table readable values
//  so sorting/filtering works correctly
export class LaunchTableVM {
  id: string = "";
  name: string = "";
  codename: string = "";
  ripId: string = "";
  productId: string = "";
  type?: LaunchType;
  iteration?: LaunchIteration;
  tier?: LaunchTier;
  scope?: LaunchScope;
  date?: string;
  status?: LaunchStatus;
  audience?: LaunchAudience;
  confidential: string = "No";
  state?: LaunchState;
  slips?: number;
  daysLate?: number;
  disclosureLevel?: LaunchDisclosure;
  regions?: string;
  description?: string;
  trackerLink?: string;
  trackerLinkType?: string;
  goal?: LaunchGoalType;
  goalLink?: string;
  originalLaunchDate?: string;
  previousLaunchDate?: string;
  rowId?: string;
  owner?: string;
  blueprintUid?: string;

  regulated: string = "No";
  pricingChangeInvolved: string = "No";
  namingInvolved: string = "No";
  prfaqRequired: string = "No";

  tags?: string;

  readGroups?: string;
  writeGroups?: string;

  sourceItem: LaunchVO = new LaunchVO();

  constructor(from?: Partial<LaunchTableVM>) {
    hydrateModel(from, this);
  }
}

export interface IProductLaunchesInput extends IHasDataTestId {
  items: LaunchVO[];
  loading?: boolean;
  preferences?: any;
  filter?: any;
  page?: number;
}

export interface IProductLaunchesEvent {
  onPreferenceChange?: (evt: NonCancelableCustomEvent<CollectionPreferencesProps.Preferences>) => void;
  onPaginationChange?: (evt: NonCancelableCustomEvent<PaginationProps.ChangeDetail>) => void;
  onFilterChange?: (evt: NonCancelableCustomEvent<PropertyFilterProps.Query>) => void;
}

export type ProductLaunchesProps = IProductLaunchesInput & IProductLaunchesEvent;
