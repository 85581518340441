import { AwsServiceVO, CollectionViewBase, RegionVO } from "@amzn/api-parity-react-component";
import { ServiceInRegionRollupStatusVO } from "../vos"
import { ServiceInRegionRollupStatusCollection } from "./ServiceInRegionRollupStatusCollection";
import { Optional } from "../types/Optional";

export class ServiceInRegionRollupStatusCollectionView extends CollectionViewBase<ServiceInRegionRollupStatusVO, ServiceInRegionRollupStatusCollection | ServiceInRegionRollupStatusCollectionView> {
  byService(service: AwsServiceVO): readonly ServiceInRegionRollupStatusVO[] {
    return this.applyFilterAndSort(this.source.byService(service))
  }

  byRegion(region: RegionVO): readonly ServiceInRegionRollupStatusVO[] {
    return this.applyFilterAndSort(this.source.byRegion(region));
  }

  byServiceRegion(service: AwsServiceVO, region: RegionVO): Optional<ServiceInRegionRollupStatusVO> {
    return this.returnItemIfPassesFilter(
      this.source.byServiceRegion(service, region)
    );
  }
}
