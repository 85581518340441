import { useCallback } from "react";
import { useHistory } from "react-router-dom";
import { SideNavigationProps } from "@amzn/awsui-components-react-v3";

const useOnFollow = () => {
  const history = useHistory();
  return useCallback(
    (event: CustomEvent<SideNavigationProps.FollowDetail>) => {
      if (!event.detail.external) {
        event.preventDefault();
        history.push(event.detail.href);
        return;
      }
    },
    [history]
  );
};

export default useOnFollow;
