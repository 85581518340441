
import * as React from "react";
import { FunctionComponent, useCallback, useState } from "react";
import { Button, SpaceBetween, Box, Modal } from "@amzn/awsui-components-react-v3";
import { LaunchVO } from "../../../../models/vos/LaunchVO";
import { ButtonBar } from "../../../common/ButtonBar/ButtonBar";
import { getDataTestIdProp } from "../../../../utils/testUtil";
import { IHasDataTestId } from "../../../../models/IHasDataTestId";
import { ReactRouterButton } from "../../../common/RouterComponents/ReactRouterButton";
import { launchVOToUpdateDto } from "../../../../parsers/launchParser";
import { ILaunchDto, IUpdateLaunchDto } from "../../../../daos/types/ILaunchDto";
import { formFieldSpacing } from "../EditLaunchForm.style";
import { convertFormValuesToLaunchVO, warnUserAboutShippedLaunch } from "./EditLaunchSubmit.helpers";
import { editLaunchFormFields } from "../EditLaunchFormFields";
import { ISelectOptions } from "../../../ProductLaunches/LaunchWizard/LaunchWizard.types";
import { getPstDateToday } from "../../../../utils/dateUtil";

export const EditLaunchSubmitTestIdSuffix = {
  submit: "-submit",
  cancel: "-cancel",
  warningModal: "-warningModal"
}

export interface EditLaunchSubmitProps extends IHasDataTestId {
  launch: LaunchVO,
  onFormSubmit: (launchId:string, launch: Partial<IUpdateLaunchDto>) => Promise<ILaunchDto>,
  getValues,
  trigger,
  isValid,
}


export const EditLaunchSubmit: FunctionComponent<EditLaunchSubmitProps> = (props) =>{

  const { launch, onFormSubmit, getValues, trigger, isValid } = props;
  const [validatingSubmit, setValidatingSubmit] = useState(false);
  const [dateWarning, setDateWarning] = React.useState(false);

  const handleSubmit = async (event) => {
    await trigger();
    if (isValid && warnUserAboutShippedLaunch((getValues(editLaunchFormFields.state.id) as ISelectOptions).label, getValues(editLaunchFormFields.date.id))) {
      setValidatingSubmit(false)
      setDateWarning(true)
    } else {
      await onSubmit(event)
    }
  }

  const onSubmit = useCallback(async (event) => {
    event.preventDefault();
    console.debug(`date is ${getValues().date}`) // should be removed once we feel confident that date validation non-determinism is past
    await trigger();
    setValidatingSubmit(true)
    if (isValid === true) {
      const { launch: launchVO, reasonForChange } = convertFormValuesToLaunchVO(launch.id, getValues)
      const launchDto: IUpdateLaunchDto = launchVOToUpdateDto(launchVO, reasonForChange);
      const response: ILaunchDto = await onFormSubmit(launch.id,launchDto);
      setValidatingSubmit(false)
      setDateWarning(false)
      return response;
    }
    else {
      setValidatingSubmit(false)
      setDateWarning(false)
    }
  }, [isValid, getValues, launch.id, onFormSubmit, trigger]);

  return (
    <>
      <SpaceBetween direction={"vertical"} size={formFieldSpacing.section}/>
      <Box float="right">
        <ButtonBar >
          <ReactRouterButton variant="link" to={`/alm/launches/${launch.id}`} {...getDataTestIdProp(props, EditLaunchSubmitTestIdSuffix.cancel)}>
            Cancel
          </ReactRouterButton>
          <Button loading={validatingSubmit} onClick={(event) => handleSubmit(event)} variant="primary" {...getDataTestIdProp(props, EditLaunchSubmitTestIdSuffix.submit)}>
            Submit
          </Button >
        </ButtonBar>
      </Box>
      <Modal
        {...getDataTestIdProp(props, EditLaunchSubmitTestIdSuffix.warningModal)}
        onDismiss={() => setDateWarning(false)}
        visible={dateWarning}
        closeAriaLabel="Close modal"
        footer={
          <Box float="right">
            <SpaceBetween direction="horizontal" size="xs">
              <Button variant="link" onClick={() => setDateWarning(false)}>Cancel</Button>
              <Button variant="primary" loading={validatingSubmit} onClick={onSubmit}>Proceed</Button>
            </SpaceBetween>
          </Box>
        }
        header="Modifying Launch Date"
      >
        <SpaceBetween size={"xs"} direction={"vertical"}>
          {
            `Shipped launch's date cannot be in future (${getValues(editLaunchFormFields.date.id)}). Clicking 'Proceed' will update launch date to ${getPstDateToday()}.`
          }
          {
            <small>Note: Date is set to current date in the PST timezone.</small>
          }
        </SpaceBetween>
      </Modal>
    </>
  )
}
