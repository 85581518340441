import * as React from "react";
import { FunctionComponent, useCallback, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Alert, CollectionPreferencesProps, Link, TabsProps } from "@amzn/awsui-components-react-v3";
import { getAppState } from "../../models/ApplicationState";
import { ChildProductVO, ProductVO } from "../../models/vos/ProductVO";
import { ProductDetails } from "../../components/ProductDetails/ProductDetails";
import { NoAccessError } from "../../components/common/NoAccessError";
import useBreadcrumbs from "../../components/Breadcrumbs/useBreadcrumbs";
import { REPORT_A_BUG_URL } from "../../constants";
import { useProductContext } from "../../contexts/ProductContext";
import { useLocation } from "react-router";
import { useProduct } from "../../utils/productUtil";
import { ILocationState } from "../../models/LocationState";
import { NonCancelableCustomEvent } from "@amzn/awsui-components-react-v3/polaris/internal/events";
import useTitleBanner from "../../components/unified/TitleBanner/useTitleBanner";
import { ProductDao } from "../../daos/ProductDao";
import { productDtosToVOs } from "../../parsers/productParser";
import { DEFAULT_TABLE_PREFERENCES } from "../../components/ProductsList/ProductsListProperties";
import { useLoadInfinitePages } from "../../utils/swrUtil";
import { getSwrConfig } from "../../daos/swrUtil";

export interface IProductDetailsContainerParam {
  productId: string;
}

export const ProductDetailsContainer: FunctionComponent = () => {
  const { productId } = useParams<IProductDetailsContainerParam>();
  const location = useLocation<ILocationState>();
  const history = useHistory();
  const { search } = useLocation();
  const { product, setProduct } = useProductContext();
  const [childProducts, setChildProducts] = useState<ChildProductVO[]>([]);

  const userAbilities = getAppState().userAbilities;

  const { data: productData, error, isValidating } = useProduct(productId);

  const { data: flatChildProducts } = useLoadInfinitePages<ProductVO>(
    "/alm/products",
    async (request, token) => {
      const dao = new ProductDao();
      const dto = await dao.getProducts(200, token);
      return { items: productDtosToVOs(dto.items), nextToken: dto.nextToken };
    },
    { ...getSwrConfig() }
  );

  const setBreadcrumbs = useBreadcrumbs();
  const setTitleBanner = useTitleBanner({ visible: true });

  useEffect(() => {
    if (flatChildProducts && productData) {
      setChildProducts(flatChildProducts.reduce((previous, current) => {
        if (current.parentProductId === productData.id) {
          previous.push(new ChildProductVO({ ...current, parentProduct: productData }))
        }
        return previous;
      }, [] as ChildProductVO[]));
    }
  }, [productData, flatChildProducts]);

  useEffect(() => {
    setTitleBanner({
      visible: true,
      title: product?.name,
      details: {
        info: product?.description,
      }
    });
  }, [product?.description, product?.name, setTitleBanner])

  useEffect(() => {
    setBreadcrumbs([
      "Products=/alm/products",
      product?.name || ""
    ]);
  }, [product?.name, setBreadcrumbs]);

  useEffect(() => {
    if (product.id !== productId) {
      const newProduct = productData
        ?? (location.state?.product?.id === productId ? location.state?.product : null)
        ?? new ProductVO();

      setProduct(newProduct);
    }
  }, [location.state?.product, setProduct, productId, product.id, productData]);

  const getChildProductsPreferences = useCallback(() => {
    try {
      const storedPreferences = JSON.parse(localStorage.getItem("almChildProductsListPreferences") ?? "{}");

      if (storedPreferences.visibleContent.includes("name")) {
        return storedPreferences;
      }
    } catch (e) {
      localStorage.setItem("almChildProductsListPreferences", JSON.stringify(DEFAULT_TABLE_PREFERENCES));
      return DEFAULT_TABLE_PREFERENCES;
    }
  }, []);

  // callback handler for ProductDetails onTabsChange
  //  sets urlQueryParam "tabId" to the currently selected tab
  //  to persist selected tab between reloads.
  const tabsHandler = useCallback((event: NonCancelableCustomEvent<TabsProps.ChangeDetail>) => {
    const queryParams = new URLSearchParams(search);
    queryParams.set("tabId", event.detail.activeTabId);
    history.push({
      search: queryParams.toString()
    });
  }, [history, search]);

  const childProductsPreferenceChangeHandler = useCallback((event: NonCancelableCustomEvent<CollectionPreferencesProps.Preferences>) => {
    localStorage.setItem("almChildProductsListPreferences", JSON.stringify(event.detail))
  }, [])

  if (true) {
    if (error && !isValidating) {
      return (
        <Alert
          type="error"
          header="Data Fetching Error"
        >
          {"There was an error fetching product details, please try again or submit a "}
          <Link
            external
            externalIconAriaLabel="Opens in a new tab"
            href={REPORT_A_BUG_URL}
          >
            ticket
          </Link>.
        </Alert>
      )
    }
    return <ProductDetails
      onTabChange={tabsHandler}
      selectedTab={new URLSearchParams(search).get("tabId") ?? undefined}
      product={productData ?? new ProductVO()}
      services={[]}
      features={[]}
      loading={!productData && isValidating}
      childProductProps={{
        preferences: getChildProductsPreferences(),
        onPreferenceChange: childProductsPreferenceChangeHandler,
      }}
      childProducts={childProducts}
    />
  }
  return <NoAccessError/>
}
