import { parse } from "query-string";

export function getQueryParameter(query?: string): object {
  const queryString: string = (query === undefined) ? window.location.search : query;
  const noQuery: boolean = (queryString.trim().length === 0);
  if (noQuery) {
    return {};
  }

  return parse(queryString);
}

export function fixFormatServiceId(serviceIdWithSlash: string) {
  return serviceIdWithSlash.split("+")?.join("/");
}
