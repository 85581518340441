import { IFeatureParityBootStrapData, IFeatureParityDataInput } from "../models/featureParityBootstrap";
import { Timer } from "@amzn/api-parity-react-component/lib/utils/Timer";
import {
  AwsServiceCollection,
  AwsServiceCollectionView,
  AwsServiceVO,
  RegionCollection,
  RegionCollectionView,
  RegionVO,
  ServiceBuildPlanCollection,
  ServiceBuildPlanCollectionView
} from "@amzn/api-parity-react-component";
import { IReconServiceParityItemDto } from "@amzn/api-parity-react-component/lib/models/dtos/recon";
import {
  generateInferredNotLaunchingPlans,
  reconParityItemListToVOs,
  reconServiceInParityDtoToVO,
  ReconServiceParser
} from "@amzn/api-parity-react-component/lib/parsers";
import { mergeList } from "@amzn/api-parity-react-component/lib/utils/modelUtil";
import { parseRegionDtoList } from "@amzn/api-parity-react-component/lib/parsers/reconDataParser";
import { FeatureParityParser } from "../parsers/FeatureParityParser";
import {
  FeatureCollection,
  FeatureCollectionView,
  FeatureParityCollectionView, FeatureParityOverrideCollection,
  FeatureParityOverrideCollectionView, ServiceInRegionRollupStatusCollectionView
} from "../models/collections";
import { FeatureParityOverrideParser } from "../parsers/FeatureParityOverrideParser";
import { applyServiceParities, combineFeatureParityOverrides, getInitialServices } from "./featureUtil";
import { FeatureRollup } from "./FeatureRollup";
import memoize from "micro-memoize";
import { FeatureParityGlobalAvailabilityFiller } from "./FeatureParityGlobalAvailabilityFiller";

function bootstrapFeatureParityDataImp(
  data: IFeatureParityDataInput,
  logger: (...data: any) => void =  () => {},
): IFeatureParityBootStrapData {
  const timer = new Timer("parse and correlate data");
  timer.start();

  const allServices: AwsServiceCollection = new AwsServiceCollection();
  let allRegions: RegionVO[];

  // @ts-ignore
  const reconParities: IReconServiceParityItemDto[] = data.serviceParity;
  const reconServicesFromParity: AwsServiceVO[] = reconParities
    .map((x) => x.serviceMetadata)
    .map(reconServiceInParityDtoToVO)
    .filter((s) => s.name);

  // This comes in second because services from parity contains information such as gm/vp
  // RIP-sourced services don't have gm/vp information
  const reconServiceParser: ReconServiceParser = new ReconServiceParser();
  // @ts-ignore
  const allReconServices: AwsServiceVO[] = reconServiceParser.parse(data.services, reconServicesFromParity);
  mergeList(allReconServices, allServices);

  allRegions = parseRegionDtoList(data.regions);
  const regions: RegionCollectionView = new RegionCollectionView(
    new RegionCollection(allRegions)
  );

  const serviceBuildPlans: ServiceBuildPlanCollection = reconParityItemListToVOs(reconParities, allServices, regions.source);
  serviceBuildPlans.addRange(generateInferredNotLaunchingPlans(serviceBuildPlans, allServices, regions.source));

  const featureParityParser: FeatureParityParser = new FeatureParityParser({
    regions: regions,
    services: allServices,
    serviceParities: serviceBuildPlans,
  });
  featureParityParser.parse(data.features);
  const buildPlansView: ServiceBuildPlanCollectionView = new ServiceBuildPlanCollectionView(serviceBuildPlans);

  const globalFiller: FeatureParityGlobalAvailabilityFiller = new FeatureParityGlobalAvailabilityFiller({
    services: allServices,
    regions,
    features: featureParityParser.result.features,
  });
  globalFiller.fill(
    featureParityParser.result.featureParities,
    featureParityParser.result.serviceParities,
  );

  let featureParityOverrides: FeatureParityOverrideCollection = new FeatureParityOverrideCollection();
  if (data.featureParityOverride) {
    const overrideParser: FeatureParityOverrideParser = new FeatureParityOverrideParser({
      regions,
      features: featureParityParser.result.features,
      parities: featureParityParser.result.featureParities,
    });
    overrideParser.parse(data.featureParityOverride);
    featureParityOverrides = overrideParser.result.parityOverrides;

    if (overrideParser.result.errors.length) {
      console.log({
        featureParityOverrideErrors: overrideParser.result.errors,
      })
    }
  }

  // post process
  const combinedParities = combineFeatureParityOverrides(
    featureParityParser.result.featureParities,
    featureParityOverrides,
  );

  const serviceView = new AwsServiceCollectionView(allServices);

  // Apply build plans to child features
  applyServiceParities(
    serviceBuildPlans,
    combinedParities,
    serviceView,
    featureParityParser.result.features
  );

  // Rollup
  const featureRollup: FeatureRollup = new FeatureRollup({
    services: allServices,
    regions,
    features: featureParityParser.result.features,
    serviceParities: buildPlansView,
    featureParities: combinedParities,
  });
  const rootServices = getInitialServices(serviceView);
  featureRollup.rollup(rootServices);


  // log errors of feature parity parsing
  logger({
    featureParityErrors: featureParityParser.result.errors
  });

  return {
    features: new FeatureCollectionView(featureParityParser.result.features),
    regions,
    services: serviceView,
    buildPlans: buildPlansView,
    parities: new FeatureParityCollectionView(combinedParities),
    featureParityOverrides: new FeatureParityOverrideCollectionView(featureParityOverrides),

    featureParityRollupByService: new ServiceInRegionRollupStatusCollectionView(featureRollup.result.rollups),
    zeroAvailabilityFeatures: new FeatureCollectionView(
      new FeatureCollection( Array.from(featureRollup.result.zeroAvailabilityFeatures) )
    ),
    zeroAvailabilityServices: new AwsServiceCollectionView(
      new AwsServiceCollection( Array.from(featureRollup.result.zeroAvailabilityServices) )
    ),
  }
}

export const bootstrapFeatureParityData = memoize(bootstrapFeatureParityDataImp, {
  maxSize: 10,
});
