import * as React from "react";
import { PropsWithChildren, ReactElement, useCallback, useEffect, useState } from "react";
import { PolarisMultiSelectProps } from "./PolarisMultiSelect.types";
import { areCollectionContentIdentical } from "../../../utils/collectionUtil";
import { NonCancelableCustomEvent, SelectProps } from "@amzn/awsui-components-react-v3";
import MultiSelect from "@amzn/awsui-components-react-v3/polaris/multiselect";
import { itemToOption, optionsToItems, selectedToOptions, shouldUseVirtualScrolling } from "./polarisMultiSelectUtil";
import { MultiselectProps } from "@amzn/awsui-components-react-v3/polaris/multiselect/interfaces";

export function PolarisMultiSelect<T>(props: PropsWithChildren<PolarisMultiSelectProps<T>>) : ReactElement<any, any> | null {
  const [rawItems, rawItemsSet] = useState<T[]>(props.items);
  const [rawSelected, rawSelectedSet] = useState<string[] | T[]>(props.selected ?? []);
  const [options, optionsSet] = useState<SelectProps.Option[]>([]);
  const [selectedOptions, selectedOptionsSet] = useState<SelectProps.Option[]>([]);

  useEffect(() => {
    // Only update if the contents of the array are not identical
    if (!areCollectionContentIdentical(props.items, rawItems)) {
      rawItemsSet(props.items);
    }
  }, [props.items, rawItems]);

  useEffect(() => {
    const updateSelected: string[] | T[] = props.selected ?? [];
    // Only update if the contents of the array are not identical
    // ts-ignore the types because the union type bothers TypeScript.  :)
    // @ts-ignore
    if (!areCollectionContentIdentical(updateSelected, rawSelected)) {
      rawSelectedSet(updateSelected);
    }
  }, [props.selected, rawSelected]);

  useEffect(() => {
    optionsSet(
      rawItems.map((x: T) => itemToOption(x, props.idFunction, props.labelFunction))
    );
  }, [rawItems, props.idFunction, props.labelFunction]);

  useEffect(() => {
    selectedOptionsSet(
      selectedToOptions(rawSelected, rawItems, props.idFunction, props.labelFunction)
    );
  }, [rawItems, rawSelected, props.idFunction, props.labelFunction]);


  const select_onChange = useCallback((event: NonCancelableCustomEvent<MultiselectProps.MultiselectChangeDetail>): void => {
    const selectedOptions: SelectProps.Option[] = event.detail.selectedOptions as SelectProps.Option[];
    const selectedItems: T[] = optionsToItems(selectedOptions, rawItems, props.idFunction);
    selectedOptionsSet(selectedOptions);
    props.onChange?.(selectedItems);
  }, [rawItems, props.idFunction, props.onChange]);

  return (
    <MultiSelect
      filteringType={"auto"}
      virtualScroll={shouldUseVirtualScrolling(options)}
      options={options}
      selectedOptions={selectedOptions}
      onChange={select_onChange}
      placeholder={props.placeholder}
    />
  );
}
