import { Optional } from "@amzn/react-bot";
import { EventDateVO } from "../../../../../models/vos/EventDateVO";

export const isOnBlockedDay = (blockedDates: Optional<EventDateVO[]>, expectedLaunchDate: Optional<string>): false | string => {
  const selectedDateInBlockedDates: Optional<EventDateVO> = (blockedDates ?? []).find((entry) => entry.date === expectedLaunchDate)
  if (selectedDateInBlockedDates) {
    return ("Date conflicts with existing event(s): " + selectedDateInBlockedDates.events?.map((event) => event.name).join(", ") ?? "")
  } else {
    return false
  }
}
