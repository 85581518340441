import * as React from "react";
import { Component } from "react";
import {
  Table,
  TablePropertyFiltering,
  TableSorting,
  TableSelection,
  TablePagination,
  TablePreferences,
  TableWrapLines,
  TableContentSelector,
  TablePageSizeSelector,
} from "@amzn/awsui-components-react";
import TableHeader from "./TableHeader";
import TableEmpty from "./TableEmpty";
import TableNoMatch from "./TableNoMatch";
import { Optional } from "../../../models/types/Optional";

const TABLE_EMPTY = (
  <TableEmpty primary="No items" secondary="Nothing to display." />
);

const TABLE_NO_MATCH = (
  <TableNoMatch
    primary="No matches found"
    secondary="We can’t find a item that matches filter."
  />
);

const filteringCountTextFn = count =>
  `${count} ${count === 1 ? "match" : "matches"}`;


// TODO get stronger type than any
class RegionsTable extends Component<any> {
  static getTableNumberOfRowsOptions = values => {
    return values.map(value => ({
      value,
      label: `${value} items`,
    }));
  };

  static getTableHeader = ({ primary, secondary, actionButtons }) => (
    <TableHeader {...{ primary, secondary, actionButtons }} />
  );

  static getTableEmpty = ({ primary, secondary }) => (
    <TableEmpty {...{ primary, secondary }} />
  );

  static getTableNoMatch = ({ primary, secondary }) => (
    <TableNoMatch {...{ primary, secondary }} />
  );

  static defaultProps = {
    DefaultFeatures: [
      "TablePropertyFiltering",
      "TablePagination",
      "TableSorting",
      "TableSelection",
      "TablePreferences",
      "TablePageSizeSelector",
      "TableWrapLines",
      "TableContentSelector",
    ],
    DefaultTable: {
      loading: true,
      loadingText: "Loading...",
      variant: "default",
      wrapLines: true,
      stickyHeader: true,
      resizableColumns: true,
      empty: TABLE_EMPTY,
      noMatch: TABLE_NO_MATCH,
    },
    DefaultTablePropertyFiltering: {
      groupValuesText: "Values",
      groupPropertiesText: "Properties",
      operationAndText: "and",
      operationNotAndText: "and not",
      operationOrText: "or",
      operationNotOrText: "or not",
      clearFiltersText: "Clear filter",
      placeholder: "Filter resources by property or value",
      allowFreeTextFiltering: true,
      filteringCountTextFunction: filteringCountTextFn,
    },
    DefaultTablePagination: {
      pageSize: 20,
    },
    DefaultTableSorting: {
      sortingDescending: false,
      disabled: false,
    },
    DefaultTableSelection: {
      keepSelection: true,
    },
    DefaultTablePreferences: {
      title: "Table preferences",
      confirmLabel: "Confirm",
      cancelLabel: "Cancel",
    },
    DefaultTablePageSizeSelector: {
      title: "Items per page: ",
      options: RegionsTable.getTableNumberOfRowsOptions([10, 20, 50]),
    },
    DefaultTableWrapLines: {
      label: "Wrap lines",
      description:
        "Enable to wrap table cell content, disable to truncate text.",
    },
    DefaultTableContentSelector: {}
  };

  static getDefaultFilters = (tokens: Optional<TablePropertyFiltering.FilteringToken[]>) : TablePropertyFiltering.Props => {
    const defaultFiltering: TablePropertyFiltering.Props = {
      ...RegionsTable.defaultProps.DefaultTablePropertyFiltering,
    }

    if (!!tokens?.length) {
      defaultFiltering.tokens = tokens
    }

    return defaultFiltering
  }

  render() {
    const features =
      typeof this.props.Features === "undefined" || this.props.Features === "*"
      ? this.props.DefaultFeatures
      : this.props.Features;


    let reconPaginationComponent: Optional<Partial<TablePagination>> = undefined;
    if (features.includes("TablePagination")) {
      reconPaginationComponent = (
        <TablePagination
          {...this.props.DefaultTablePagination}
          {...this.props.TablePagination}
        />
      );
    }


    return (
      <Table data-testid={this.props.testId} {...{ ...this.props.DefaultTable, ...this.props.Table }}>
        {features.includes("TablePropertyFiltering") ? (
          <TablePropertyFiltering
            {...{
              ...RegionsTable.getDefaultFilters(this.props.DefaultFilters),
              ...this.props.TablePropertyFiltering,
            }}
          />
        ) : (
          ""
        )}
        {reconPaginationComponent}
        {features.includes("TableSorting") ? (
          <TableSorting
            {...{
              ...this.props.DefaultTableSorting,
              ...this.props.TableSorting,
            }}
          />
        ) : (
          ""
        )}
        {features.includes("TableSelection") ? (
          <TableSelection
            {...{
              ...this.props.DefaultTableSelection,
              ...this.props.TableSelection,
            }}
          />
        ) : (
          ""
        )}
        {features.includes("TablePreferences") ? (
          <TablePreferences
            {...{
              ...this.props.DefaultTablePreferences,
              ...this.props.TablePreferences,
            }}
          >
            {features.includes("TablePageSizeSelector") ? (
              <TablePageSizeSelector
                {...{
                  ...this.props.DefaultTablePageSizeSelector,
                  ...this.props.TablePageSizeSelector,
                }}
              />
            ) : (
              ""
            )}
            {features.includes("TableWrapLines") ? (
              <TableWrapLines
                {...{
                  ...this.props.DefaultTableWrapLines,
                  ...this.props.TableWrapLines,
                }}
              />
            ) : (
              ""
            )}
            {features.includes("TableContentSelector") ? (
              <TableContentSelector
                {...{
                  ...this.props.DefaultTableContentSelector,
                  ...this.props.TableContentSelector,
                }}
              />
            ) : (
              ""
            )}
          </TablePreferences>
        ) : (
          ""
        )}
      </Table>
    );
  }
}

export default RegionsTable;
