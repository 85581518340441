import * as React from "react"
import { Spinner } from "@amzn/awsui-components-react-v3"
import styled from "styled-components";

const LoadingTestId = {
  component: "Loading"
}

const Container = styled.div`
  position: fixed;
  left: 50%;
  top: 30%;
`;

/**
 * @deprecated Use <LoadingIndicator /> component instead
 */
export const Loading = () => {
  return (
    <Container data-testid={LoadingTestId.component}>
      <h2>Loading&hellip;</h2>
      <Spinner size="large" />
    </Container>
  );
}
