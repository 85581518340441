import { IRegionMetadata } from "../daos/types/IRegionMetaData";
import { mapBy, OneLevelLookup } from "@amzn/api-parity-react-component/lib/utils/lookupUtil";
import { InstanceName } from "../models/InstanceName";
import { fromInstanceText } from "../parsers/instanceParser";
import * as _ from "lodash";
import memoize from "micro-memoize";

// TODO Replace these hardcoded partition long names to RIP calls
export const PARTITION_TO_LONG_NAME = {
  "aws": "Public AWS Partition",
  "aws-us-gov": "AWS GovCloud",
  "aws-cn": "AWS China",
  "aws-iso": "aws-iso",
  "aws-iso-b": "aws-iso-b",
  "aws-iso-e": "aws-iso-e",
  "aws-iso-f": "aws-iso-f",
}


/**
 * Return code of the region
 */
export function getRegionCode(region: IRegionMetadata): string {
  return region.nameSortable ?? region.airportCode;
}

/**
 * Return a map of region object
 */
export function getRegionMap(regions: IRegionMetadata[]): OneLevelLookup<IRegionMetadata> {
  return mapBy(regions, (region): string => {
    const instanceModel: InstanceName = fromInstanceText(region.instance);
    return instanceModel.names[0];
  });
}

export function filterRegionsByAirportCodes(regions: IRegionMetadata[], airportCodesToInclude: string[]) {
  const airportCodes = new Set(airportCodesToInclude);
  return regions.filter(region => airportCodes.has(region.airportCode));
}

export function mapPartitionToRegions(regions: IRegionMetadata[]): OneLevelLookup<string[]> {
  const partitionToRegion = new Map<string, string[]>();

  const partitionToRegionMetadata = _.groupBy(regions, "partition")

  for (let partition in PARTITION_TO_LONG_NAME) {
    if (partitionToRegionMetadata[partition]) {
      partitionToRegion.set(
        PARTITION_TO_LONG_NAME[partition],
        partitionToRegionMetadata[partition].map(region => region.airportCode)
      )
    }
  }

  return partitionToRegion;
}

export function isAirportCodeImp(key: string): boolean {
  return key?.length === 3 && key.toUpperCase() === key;
}

export const isAirportCode = memoize(isAirportCodeImp, {
  maxSize: 100
});
