import * as React from "react";
import { FunctionComponent, useCallback, useState } from "react";
import {
  FeatureParityOverviewControlBarProps,
  IFeatureParityOverviewControlBarStatisticsInput
} from "./FeatureParityOverviewControlBar.types";
import { ActionSlot, Container, SearchContainer } from "@amzn/api-parity-react-component/lib/components/control-bar/control-bar.styles";
import { Header } from "@amzn/awsui-components-react-v3";
import { TokenSearch } from "../../../../TokenSearch/TokenSearch";
import { debounce } from "lodash";
import { FeatureParityOverviewSettingVO } from "../../../../../models/vos/FeatureParityOverviewSettingVO";
import produce from "immer";
import { defaultSearchDelayInMs } from "@amzn/api-parity-react-component/lib/components/control-bar/controlBarUtil";
import { toPercent } from "@amzn/api-parity-react-component/lib/utils/modelUtil";

export const FeatureParityOverviewControlBarTestId = {
  search: "FeatureParityOverviewControlBar-search",
  actions: "FeatureParityOverviewControlBar-actions",
};

export const FeatureParityOverviewControlBarStatistics: FunctionComponent<IFeatureParityOverviewControlBarStatisticsInput> = (props) => {
  return <React.Fragment>
    Overall Parity: { toPercent(props.statistics.overallParityRatio, 1) } | { props.statistics.serviceCount } services | { props.statistics.featureCount } features
  </React.Fragment>
}

export const FeatureParityOverviewControlBar: FunctionComponent<FeatureParityOverviewControlBarProps> = (props) => {
  const [setting, setSetting] = useState<FeatureParityOverviewSettingVO>(props.setting);

  const {
    onChange
  } = props;

  const filter_onChange = useCallback(debounce((terms: string[]) => {
    const newSetting = produce(setting, (draft) => {
      draft.searchTerms = terms;
    });
    setSetting(newSetting);
    onChange?.(newSetting);
  }, props.searchDelayInMs ?? defaultSearchDelayInMs), [
    setting,
    setSetting,
  ]);

  return (
    <Container>
      <Header
        description={!props.isLoading && <FeatureParityOverviewControlBarStatistics statistics={props.statistics} />}>
        {props.title}
      </Header>
      <SearchContainer>
        <TokenSearch
          data-testid={FeatureParityOverviewControlBarTestId.search}
          disabled={props.isLoading}
          placeholder="Find features/services"
          onSearch={filter_onChange}
        />
        {
          (props.actions) &&
          <ActionSlot data-testid={FeatureParityOverviewControlBarTestId.actions}>{props.actions}</ActionSlot>
        }
      </SearchContainer>
    </Container>
  );
};
