import * as React from "react";
import { FunctionComponent, useEffect, useState } from "react";
import { Alert, Container, Header, Link } from "@amzn/awsui-components-react-v3";
import { EditLaunchPropertiesFormFields } from "./EditLaunchPropertiesForm/EditLaunchPropertiesForm";
import { IHasDataTestId } from "../../../../models/IHasDataTestId";
import { getDataTestIdProp } from "../../../../utils/testUtil";
import { EventDateVO } from "../../../../models/vos/EventDateVO";
import { REPORT_A_BUG_URL } from "../../../../constants";
import { IEditLaunchPropertiesProps } from "./EditLaunchProperties.types";
import { useLoadInfinitePages } from "../../../../utils/swrUtil";
import { EventDateDao } from "../../../../daos/EventDateDao";
import { eventDateDtosToVos } from "../../../../parsers/eventDateParser";
import { getSwrConfig } from "../../../../daos/swrUtil";

export const EditLaunchPropertiesTestIdSuffix = {
  header: "-header",
  form: "-form",
}

export interface IEditLaunchPropertiesContainerProps extends IHasDataTestId {
  control: any,
  errors: any,
}


export const EditLaunchProperties: FunctionComponent<IEditLaunchPropertiesProps> = (props) =>{

  const [ blockedDates, setBlockedDates ] = useState<EventDateVO[]>([]);
  const [ dateApiError, setDateApiError ] = useState(false);

  const { data: dates, error: datesError, loadingMore } = useLoadInfinitePages<EventDateVO>(
    `eventDates`,
    async (request, token) => {
      const dao = new EventDateDao();
      const dto = await dao.getAllEventDates(200, token);
      return { items: eventDateDtosToVos(dto.items[0]), nextToken: dto.NextToken }
    },
    { ...getSwrConfig() }
  );


  useEffect(() => {
    if(dates && !loadingMore && !datesError) {
      setDateApiError(false)
      setBlockedDates(dates)
    }
    if(datesError) {
      setDateApiError(true)
    }
  }, [setBlockedDates, setDateApiError, dates, datesError, loadingMore])


  if(dateApiError) {
    return (
      <Alert
        type="error"
        header="Data Fetching Error"
      >
        {"There was an error fetching blocked dates. If you'd like to change this launch's date, please try again later or submit a "}
        <Link
          external
          externalIconAriaLabel="Opens in a new tab"
          href={REPORT_A_BUG_URL}
        >
          ticket
        </Link>.
      </Alert>
    )
  }

  return (
    <>
      <Container header={
        <Header variant="h2" {...getDataTestIdProp(props, EditLaunchPropertiesTestIdSuffix.header)}>
          Edit Launch Properties
        </Header>
      }
      >
        <form {...getDataTestIdProp(props, EditLaunchPropertiesTestIdSuffix.form)}>
          <EditLaunchPropertiesFormFields control={props.control} errors={props.errors} dateWatch={props.dateWatch} blockedDates={blockedDates}/>
        </form>
      </Container>
    </>
  )
}
