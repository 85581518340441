import * as React from "react";
import { FunctionComponent } from "react";
import { IndividualContactFormFieldWithControllerProps } from "./IndividualContactFormFieldWithController.types";
import {
  getDefaultValue
} from "../AddOrEditServiceContactModalUtils";
import { FormField, Input } from "@amzn/awsui-components-react-v3";
import { Controller } from "react-hook-form";
import { getDataTestIdProp } from "../../../../../utils/testUtil";
import { Attribute } from "@amzn/api-parity-react-component/lib/components/attribute/Attribute";
import { IReactHookFormValidation } from "../../../../common/FormTypes";

export const requireFieldNotation = "*"

export function getIndividualFormFieldLabel(placeholder: string, validation?: IReactHookFormValidation) {
  return placeholder + (validation?.required ? requireFieldNotation : "");
}

export const IndividualContactFormFieldWithControllerTestIdSuffix = {
  formField: "-formField",
  input: "-input"
}

export const IndividualContactFormFieldWithController: FunctionComponent<IndividualContactFormFieldWithControllerProps> = (props) => {
  return (
    <Controller
      name={props.formField.id}
      id={props.formField.id}
      control={props.control}
      defaultValue={props.nameRip ?? getDefaultValue(props.selected, props.formField)}
      rules={
        props.formField.validation
      }
      render={({ onChange, value }, { invalid }) =>
        <FormField
          {...getDataTestIdProp(props, IndividualContactFormFieldWithControllerTestIdSuffix.formField)}
          label={<Attribute label={getIndividualFormFieldLabel(props.formField.placeholder, props.formField.validation)} info={props.popoverInfo}/>}
          errorText={(invalid && props.errors[props.formField.id].message)}
          key={props.formField.id}
        >
          <Input
            {...getDataTestIdProp(props, IndividualContactFormFieldWithControllerTestIdSuffix.input)}
            onChange={(e) => onChange(e.detail.value)}
            value={value}
            key={props.formField.id + "Input"}
            invalid={invalid}
            placeholder={props.formField.placeholder}
          />
        </FormField>
      }
    />
  );
};
