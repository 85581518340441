import { getErrorResult, IQaItemResult, successResult } from "../models/QaItemResult";
import { RemoteOperationResult } from "../models/QaRemoteOperation";
import { IGenericMutationResponseDto } from "../../daos/types/IGenericMutationResponseDto";

/**
 * A wrapper function that checks a remote operation to be successful
 * if so, it would invoke the checkT function.
 * @param value
 * @param checkSuccessResult
 * @param unsuccessfulCallMessage
 */
export function successCallChecker<T>(value: RemoteOperationResult<T>, checkSuccessResult: (T) => IQaItemResult, unsuccessfulCallMessage: string): IQaItemResult {
  if (!value.success) {
    return getErrorResult(unsuccessfulCallMessage);
  }

  return checkSuccessResult(value.result as T);
}


export function genericMutationSuccessChecker(value: RemoteOperationResult<IGenericMutationResponseDto>, errorMessage: string): IQaItemResult {
  return successCallChecker(
    value,
    (val: IGenericMutationResponseDto) => {
      return (val?.message?.toLowerCase() === "success") ? successResult : getErrorResult(errorMessage)
    },
    errorMessage
  );
}
