import { ServiceDao } from "../../daos/ServiceDao";
import { call, fork, put, select, takeLatest } from "redux-saga/effects";
import { AwsServiceVO } from "@amzn/api-parity-react-component";
import { loadServiceList, loadServiceListSuccess } from "../actions/serviceActionCreator";
import { ENSURE_SERVICE_LIST, LOAD_SERVICE_LIST } from "../actions/serviceAction";
import { LoadStateEnum } from "../../models/LoadStateEnum";
import { serviceLoadStateSelector } from "../selectors/serviceSelector";
import { noOperation } from "../actions/specialActionCreator";
import { fromReconServiceDto } from "../../parsers/serviceParser";
import { IServiceMetaData } from "../../daos/types/IServiceMetaData";

export function* loadServiceListSaga() {
  // TODD: Error handling
  const dao: ServiceDao = new ServiceDao();

  const reconServiceDtos: IServiceMetaData[] = yield call(dao.getAllServices.bind(dao));
  const vos: AwsServiceVO[] = reconServiceDtos.map(fromReconServiceDto);

  yield put( loadServiceListSuccess(vos) );
}

export function* ensureServiceListSaga() {
  const serviceLoadState: LoadStateEnum = yield select(serviceLoadStateSelector);
  const isServiceLoadedOrBeingLoaded = serviceLoadState === LoadStateEnum.Loaded || serviceLoadState === LoadStateEnum.Loading;
  if (isServiceLoadedOrBeingLoaded) {
    yield put(noOperation());
  } else {
    yield put(loadServiceList());
  }
}

function* onLoadServiceList() {
  yield takeLatest(LOAD_SERVICE_LIST, loadServiceListSaga);
}

function* onEnsureServiceList() {
  yield takeLatest(ENSURE_SERVICE_LIST, ensureServiceListSaga);
}

export const serviceSaga = [
  fork(onLoadServiceList),
  fork(onEnsureServiceList),
];
