import { getReconApiClient, sanitizePathValue } from "./daoUtil";
import { getRipName } from "../utils/serviceMetadataUtil";
import { getServiceBusinessPlanPatch, parseGetServiceRegionHistoryData } from "../utils/apiUtil";
import { IServiceMetaData } from "./types/IServiceMetaData";
import {
  IGetServiceRegionHistoryResponse,
  IGetServiceRegionHistoryResponseLegacy
} from "./types/IGetServiceRegionHistoryResponse";
import { IGenericMutationResponseDto } from "./types/IGenericMutationResponseDto";
import { Optional } from "../models/types/Optional";

export interface IServicePaginationBehavior {
  continuePaging: boolean;
  nextPageToken?: string;
}

export class ServiceDao {
  // region Get Services
  async getServices(allServices: boolean): Promise<IServiceMetaData[]> {
    return (allServices) ? this.getAllServices() : this.getCategorizedServices();
  }

  async getCategorizedServices(): Promise<IServiceMetaData[]> {
    const client = getReconApiClient();
    const res = await client.get<IServiceMetaData[]>(this.getCategorizedServicesUrl());
    const data = res.data;
    data.forEach((service) =>{
      service.nameRip = getRipName(service.instance)
    });
    return data;
  }

  getCategorizedServicesUrl() {
    return "/api/services";
  }

  async getAllServices(): Promise<IServiceMetaData[]> {
    const client = getReconApiClient();
    const pageSize = 10000;
    let startAfter: Optional<string> = undefined;
    let continuePaging = true;
    let result: IServiceMetaData[] = [];
    while (continuePaging) {
      const res = await client.get<IServiceMetaData[]>(this.getAllServicesUrl(pageSize, startAfter));
      const data: IServiceMetaData[] = res.data;
      data.forEach((service) =>{
        service.nameRip = getRipName(service.instance)
      });
      result = result.concat(data);

      const paginationBehavior = this.getPaginationBehavior(data, pageSize);
      continuePaging = paginationBehavior.continuePaging;
      startAfter = paginationBehavior.nextPageToken;
    }
    return result;
  }

  getPaginationBehavior(currentPaginationRecords: IServiceMetaData[], pageSize: number): IServicePaginationBehavior {
    if (currentPaginationRecords.length < pageSize) {
      return {
        continuePaging: false,
      }
    } else {
      const nextPageToken = currentPaginationRecords[currentPaginationRecords.length - 1].instance;
      return {
        continuePaging: true,
        nextPageToken,
      }
    }
  }

  getAllServicesUrl(pageSize: number, startAfter: Optional<string>): string {
    let url = `/api/services?allServices=true&pagesize=${pageSize}`;
    if (startAfter) {
      url = url + `&startafter=${encodeURIComponent(startAfter)}`;
    }
    return url;
  }

  // endregion

  // region GetServiceRegionHistory
  /**
   * Get a service's metadata and its region history
   * @param serviceName
   * @param regionName
   */
  async getServiceRegionHistory(serviceName: string, regionName: string): Promise<IGetServiceRegionHistoryResponseLegacy> {
    const client = getReconApiClient();
    const res = await client.get<IGetServiceRegionHistoryResponse>(this.getServiceRegionHistoryUrl(serviceName, regionName));
    return parseGetServiceRegionHistoryData(res.data)
  }

  getServiceRegionHistoryUrl(serviceName, regionName) {
    return `/api/services/${sanitizePathValue(serviceName)}/plans/${sanitizePathValue(regionName)}/history`;
  }
  // endregion


  // patchServicePlan
   /**
    * Update estimated date of a plan
    * @param service
    * @param service
    * @param newPlan
    */
  async patchServicePlan(service: IServiceMetaData, newPlan: string): Promise<IGenericMutationResponseDto> {
    const client = getReconApiClient();
    const patch = getServiceBusinessPlanPatch(service, newPlan);
    const serviceName: string = getRipName(service.instance);
    const res = await client.patch<IGenericMutationResponseDto>(this.getUpdateServicePlanUrl(serviceName), patch);
    return res.data;
  }

  getUpdateServicePlanUrl(serviceName) {
    return `/api/services/${sanitizePathValue(serviceName)}`;
  }
  // End patchServicePlan

}
