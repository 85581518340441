import * as React from "react";
import { FunctionComponent, useCallback, useEffect, useState } from "react";
import { ServiceContactsVO } from "../../../models/vos/ServiceContactsVO";
import { UserAbilities } from "../../../models/UserAbilities";
import { AddOrEditServiceContactModal } from "../EnterServiceContact/AddOrEditServiceContactModal/AddOrEditServiceContactModal";
import { ButtonBar } from "../../common/ButtonBar/ButtonBar";
import { Optional } from "../../../models/types/Optional";
import { IAddOrEditFormInput } from "../EnterServiceContact/AddOrEditServiceContactModal/AddOrEditServiceContactModal.types";
import { ServiceContactUpdateResult } from "../../../orchestrators/ServiceContactOrchestrator";
import styled from "styled-components";
import { UpdateServiceLeaderDelegate } from "../../../models/delegates/UpdateServiceLeaderDelegate";
import { SingleServiceContactsCard } from "../SingleServiceContactsCard/SingleServiceContactsCard";

export const NoContactDisplay = "(No Contacts For Service)";

export const SingleServiceContactTestId = {
  component: "SingleServiceContacts-component",
  editContact: "SingleServiceContacts-editContact",
  header: "SingleServiceContacts-header",
  contactDetail: "SingleServiceContacts-contactDetail",
  partitionChooser: "SingleServiceContacts-partitionChooser",
  newCtiTicket: "SingleServiceContacts-newCtiTicket",
  newPartition: "SingleServiceContacts-newPartition"
}

export const Content = styled.div`
  min-height: 22rem; // Allocate enough space helps prevent the container from bouncing too much during loading
`;

const Layout = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-bottom: 0.5rem;
`;

type SingleServiceContactsProps = {
  loading?: boolean;
  contacts: readonly ServiceContactsVO[];
  userAbilities: UserAbilities
  editServiceContactsSubmit?: (data: IAddOrEditFormInput, prevContactInfo: Optional<ServiceContactsVO>) => Promise<ServiceContactUpdateResult>;
  onRefreshServiceContact?: () => void;
  onUpdateLeader?: UpdateServiceLeaderDelegate;
  nameRip: string;
}

export interface SingleServiceContactsHeaderProps {
  loading: boolean;
  contacts: ServiceContactsVO | undefined;
  userAbilities: UserAbilities;
  onSubmit: (successfulServices: ServiceContactsVO[], failedServices: ServiceContactsVO[]) => void;
  editServiceContactsSubmit?: (data: IAddOrEditFormInput, prevContactInfo: Optional<ServiceContactsVO>) => Promise<ServiceContactUpdateResult>;
  onUpdateLeader?: UpdateServiceLeaderDelegate;
  existingPartitions: string[];
}

export const SingleServiceContactsHeader: FunctionComponent<SingleServiceContactsHeaderProps> = (props) => {
  return (
    <Layout>
      <ButtonBar>
        {
          (props.userAbilities.canWriteContacts) &&
          <AddOrEditServiceContactModal
            data-testid={SingleServiceContactTestId.newPartition}
            selected={props.contacts}
            isAdding={true}
            isAddingNewPartition={true}
            existingPartitions={props.existingPartitions}
            userAbilities={props.userAbilities}
            onSuccessfulSubmit={props.onSubmit}
            onAddOrEditServiceContactSubmit={props.editServiceContactsSubmit}
          />
        }
      </ButtonBar>
    </Layout>
  );
}

export const SingleServiceContacts: FunctionComponent<SingleServiceContactsProps> = (props) => {
  const {
    contacts
  } = props;

  const [contactPartitions, setContactPartitions] = useState<string[]>([]);

  useEffect(() => {
    const partitions: string[] = contacts.map((contact) => contact.partition);
    setContactPartitions(partitions);
  }, [
    contacts,
    setContactPartitions,
  ]);

  const onSubmit = useCallback((successfulServices: ServiceContactsVO[], failedServices: ServiceContactsVO[]): void => {
    props.onRefreshServiceContact?.();
  }, [props.onRefreshServiceContact]);

  return (
    <React.Fragment>
      {contacts && contacts.length > 0 &&
        <SingleServiceContactsHeader
          loading={!!props.loading}
          contacts={contacts[0]}
          userAbilities={props.userAbilities}
          onSubmit={onSubmit}
          editServiceContactsSubmit={props.editServiceContactsSubmit}
          onUpdateLeader={props.onUpdateLeader}
          existingPartitions={contactPartitions}
        />}

      <Content data-testid={SingleServiceContactTestId.contactDetail}>
        <SingleServiceContactsCard
          loading={!!props.loading}
          partitionContacts={contacts ?? []}
          userPermissions={props.userAbilities}
          editGmVpSubmit={props.onUpdateLeader}
          editServiceContactsSubmit={props.editServiceContactsSubmit}
          onSubmit={onSubmit}
          nameRip={props.nameRip}
        />
      </Content>
    </React.Fragment>

  );
}

