import styled from "styled-components";
import { SpaceBetweenProps } from "@amzn/awsui-components-react-v3";

export const formMinHeight = "428px";
export const formMaxheight = "550px";
const modalSizeOverhead = "25rem";

export const FormContainer = styled("div")`
  height: min(calc(100vh - ${modalSizeOverhead}), ${formMaxheight});
`;
export const FormScrollableContainer = styled("div")`
  max-height: min(calc(100vh - ${modalSizeOverhead}), ${formMaxheight});
  overflow-x: hidden;
  overflow-y: auto;
`;

export interface IFormFieldSpacing {
  horizontal: SpaceBetweenProps.Size;
  vertical: SpaceBetweenProps.Size;
  section: SpaceBetweenProps.Size;
  header: SpaceBetweenProps.Size;
}

export const formFieldSpacing: IFormFieldSpacing = {
  horizontal: "s",
  vertical: "s",
  section: "m",
  header: "xxxs",
};

