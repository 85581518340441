import React from "react";
import { Button, Popover, StatusIndicator } from "@amzn/awsui-components-react-v3";


export const CopyToClipboardButtonTestId = {
  popover: "CopyToClipboardButton-popover",
  button: "CopyToClipboardButton-button"
};


export const CopyToClipboardButton = ({ text, notification }) => (
  <Popover
    data-testid={CopyToClipboardButtonTestId.popover}
    size="small"
    position="top"
    triggerType="custom"
    dismissButton={false}
    content={<StatusIndicator type="success">{notification}</StatusIndicator>}
  >
    <Button
      data-testid={CopyToClipboardButtonTestId.button}
      iconName="copy"
      variant="icon"
      onClick={(event) => navigator.clipboard.writeText(text)}
    />
  </Popover>
);
