export interface IBusinessCaseOverrideOption {
  id: string;
  label: string;
  value: string;
}

export const None: IBusinessCaseOverrideOption = Object.freeze({
  label: "No Override | Default",
  value: "None",
  id: "1"
});

export const NotLaunching: IBusinessCaseOverrideOption = Object.freeze({
  label: "Not Launching",
  value: "Not Launching",
  id: "2"
});

export const Ato: IBusinessCaseOverrideOption = Object.freeze({
  label: "ATO",
  value: "ATO",
  id: "3",
});

export const businessCaseOverrideOptions: readonly IBusinessCaseOverrideOption[] = Object.freeze([
  None, NotLaunching, Ato,
]);
