import { ProductVO } from "../../models/vos/ProductVO";
import { IHasDataTestId } from "../../models/IHasDataTestId";
import { CollectionPreferencesProps, PaginationProps, PropertyFilterProps } from "@amzn/awsui-components-react-v3";
import { NonCancelableCustomEvent } from "@amzn/awsui-components-react-v3/polaris/internal/events";
import { hydrateModel } from "../../utils/modelUtil";

export class ProductTableVM {
  id: string = "";
  name: string = "";
  type?: string;
  vp?: string;
  gm?: string;
  audience?: string;
  description?: string;
  state?: string;
  confidential?: string;
  category?: string;
  codename?: string;
  ripId?: string;
  cti?: string;
  primaryOwner?: string;
  secondaryOwners?: string;
  tags?: string;
  blueprintUid?: string;
  parentProductId?: string;
  productApprovalDate?: string;
  productApprovedBy?: string;

  sourceItem: ProductVO = new ProductVO();

  constructor(from?: Partial<ProductTableVM>) {
    hydrateModel(from, this);
  }
}

export interface IProductsListInput extends IHasDataTestId {
  items: ProductVO[];
  loading?: boolean;
  preferences?: any;
  page?: number;
  filter?: any;
  header: JSX.Element;
}

export interface IProductsListEvent {
  onPreferenceChange?: (evt: NonCancelableCustomEvent<CollectionPreferencesProps.Preferences>) => void;
  onPaginationChange?: (evt: NonCancelableCustomEvent<PaginationProps.ChangeDetail>) => void;
  onFilterChange?: (evt: NonCancelableCustomEvent<PropertyFilterProps.Query>) => void;
}

export type ProductsListProps = IProductsListInput & IProductsListEvent;
