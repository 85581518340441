import * as React from "react";
import { FunctionComponent } from "react";
import { Box, FormField } from "@amzn/awsui-components-react-v3";
import { Attribute } from "@amzn/api-parity-react-component/lib/components/attribute/Attribute";
import { Controller } from "react-hook-form";
import { formFieldTypeMapper } from "./FormFieldTypeMapper";
import { getDataTestIdProp } from "../../utils/testUtil";
import { FormFieldTypes, IIndividualFormFieldWithControllerInput } from "./IndividualFormFieldWithController.types";
import { IReactHookFormValidation } from "../common/FormTypes";
import { HelpPanelButton } from "../HelpPanel/HelpPanelButton/HelpPanelButton";

export const requireFieldNotation = "*"

export function getIndividualFormFieldLabel(placeholder: string, validation?: IReactHookFormValidation) {
  return placeholder + (validation?.required ? requireFieldNotation : "");
}

export const IndividualFormFieldWithControllerTestIdSuffix = {
  formField: "-formField",
  input: "-input",
  textArea: "-textArea",
  radio: "-radio",
  checkbox: "-checkbox",
  tokenGroup: "-tokenGroup",
  select: "-select",
  date: "-date"
}

export const IndividualFormFieldWithController: FunctionComponent<IIndividualFormFieldWithControllerInput> = (props) => {
  return (
    <Controller
      name={props.formField.id}
      id={props.formField.id}
      control={props.control}
      defaultValue={props.defaultValue}
      rules={
        props.formField.validation
      }
      render={({ onChange, value, onBlur }, { invalid }) =>
        <FormField
          {...getDataTestIdProp(props, IndividualFormFieldWithControllerTestIdSuffix.formField)}
          {
            ...(props.fieldType !== FormFieldTypes.CHECKBOX_ENTRY ?
                {
                  label: <>
                    <Box display="inline-block">
                      <Attribute
                        label={
                          getIndividualFormFieldLabel(
                            props.formField.nameOverride ?? props.formField.placeholder,
                            props.formField.validation
                          )
                        }
                        info={props.popoverInfo}
                      />
                    </Box>
                    {props.formField.helpId
                      && <Box display="inline-block">
                        <HelpPanelButton helpId={props.formField.helpId}/>
                      </Box>
                    }
                  </>
                } : {}
            )
          }
          description={props.label}
          errorText={(invalid && props.errors[props.formField.id].message)}
          key={props.formField.id}
        >
          {formFieldTypeMapper[props.fieldType]({ props, value, onChange, onBlur, invalid })}
        </FormField>
      }
    />
  );
};
