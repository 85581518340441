import React, { CSSProperties, useCallback } from "react";
import { getParityCellStyleAndText } from "./FeatureParityCellHelpers"
import * as awsui from "@amzn/awsui-design-tokens/polaris";
import styled from "styled-components";
import { Button } from "@amzn/awsui-components-react-v3";
import { ButtonBar } from "../../components/common/ButtonBar/ButtonBar";
import { ParityCellProps } from "./ReconFeatureParityCell.types";

export const componentTestId = {
  commentButton: "ReconFeatureParityCell-CommentButton",
}

export const ParityCellStyled = styled("div")`
  color: ${ (props) => props.color }
`;

export const featureStatusAndColorMap = {
  "ERROR": { color: awsui.colorTextStatusError },
  "SUCCESS": { color: awsui.colorTextStatusSuccess }
}

const getStyleFromMap = (status): CSSProperties => {
  return featureStatusAndColorMap[status];
}

export const ParityCell: React.FunctionComponent<ParityCellProps> = (props) => {
  const { item, region, comment, onClick } = props;
  let { style, renderedText } = getParityCellStyleAndText(item, region);
  style = getStyleFromMap(style)

  // @ts-ignore, comment is guaranteed to be defined if we create the method
  const parityCellOnClick = useCallback(() => onClick(comment), [comment, onClick])
  return (
    <ButtonBar>
    <ParityCellStyled color={style?.color}> { renderedText } </ParityCellStyled>
    {
      !!comment && <Button
        data-testid={componentTestId.commentButton}
        iconName="contact"
        variant="icon"
        iconAlign="left"
        onClick={parityCellOnClick}
      />
    }
    </ButtonBar>
  )
};
