import { FeatureVO } from "../vos/FeatureVO";
import {
  AwsServiceAvailabilityLevelEnum,
  AwsServiceVO,
  IdCollectionBase,
  IMutableCollection,
  OneLevelGroupIndex
} from "@amzn/api-parity-react-component";

export class FeatureCollection extends IdCollectionBase<FeatureVO> implements IMutableCollection<FeatureVO> {
  private readonly _byServiceIndex: OneLevelGroupIndex<FeatureVO, string>;
  private readonly _byMarkedAsFromIndex: OneLevelGroupIndex<FeatureVO, string>;
  private readonly _byIsGlobalIndex: OneLevelGroupIndex<FeatureVO, boolean>;

  constructor(initialValues?: readonly FeatureVO[]) {
    super();

    this._byServiceIndex = new OneLevelGroupIndex<FeatureVO, string>("byService", (item) => item.service.id);
    this._byMarkedAsFromIndex = new OneLevelGroupIndex<FeatureVO, string>("byMarkedAsFromIndex", (item) => item.markedAsFrom?.id ?? "");
    this._byIsGlobalIndex = new OneLevelGroupIndex<FeatureVO, boolean>(
      "byIsGlobal",
      (feature) => feature.availabilityLevels.has(AwsServiceAvailabilityLevelEnum.Global),
    );

    this.buildIndexes([
      this._byServiceIndex,
      this._byMarkedAsFromIndex,
      this._byIsGlobalIndex
    ]);

    if (initialValues) {
      this.addRange(initialValues);
    }
  }

  byService(service: AwsServiceVO): readonly FeatureVO[] {
    return this._byServiceIndex.getValue(service.id);
  }

  byMarkedAsFrom(service?: AwsServiceVO): readonly FeatureVO[] {
    return this._byMarkedAsFromIndex.getValue(service?.id ?? "");
  }

  byIsGlobal(isGlobal: boolean): readonly FeatureVO[] {
    return this._byIsGlobalIndex.getValue(isGlobal);
  }
}
