import { IRegionMetadata, IRipRegionMetadata } from "../daos/types/IRegionMetaData";
import { RegionMetadataVO } from "../models/vos/RegionMetadataVO";
import { RipStatus } from "../models/types/RipStatus";
import { parseDate } from "../utils/dateUtil";
import { InstanceName } from "../models/InstanceName";
import { fromInstanceText } from "./instanceParser";

export const parseRegionMetadataDto = (regionMetadataDto: Partial<IRegionMetadata>): RegionMetadataVO => {
  const instanceName: InstanceName = fromInstanceText(regionMetadataDto.instance as string);
  const airportCode: string = instanceName.names[0];

  return new RegionMetadataVO({
    airportCode,
    nameLong: regionMetadataDto.nameLong,
    status: regionMetadataDto.status as RipStatus,
    partition: regionMetadataDto.partition,
    description: regionMetadataDto.description,
    codename: regionMetadataDto.nameCodename,
    date: parseDate(regionMetadataDto.date),
    accessibilityAttributes: regionMetadataDto.accessibilityAttributes,
  })
}

export const parseRipRegionMetadataDto = (regionMetadataDto: IRipRegionMetadata): RegionMetadataVO => {
  return new RegionMetadataVO({
    codename: regionMetadataDto.codeName,
    nameLong: regionMetadataDto.regionName,
    description: regionMetadataDto.longName,
    airportCode: regionMetadataDto.key.name,
    status: regionMetadataDto.status as RipStatus,
    partition: regionMetadataDto.arnPartition,
    date: parseDate(regionMetadataDto.estimatedLaunchDate),
  });
}
