import * as React from "react";
import { FunctionComponent, useCallback } from "react";
import { SpaceBetween, ColumnLayout } from "@amzn/awsui-components-react-v3";
import { Optional } from "@amzn/react-bot";
import { formFieldSpacing } from "../../EditLaunchForm.style";
import { Displayable } from "@amzn/api-parity-react-component/lib/models/Displayable";
import { IFormFieldData } from "../../../../common/FormTypes";
import { getDataTestIdProp } from "../../../../../utils/testUtil";
import { editLaunchFormFields } from "../../EditLaunchFormFields";
import { IEditLaunchFormFieldsProps, ISelectableFormInput, } from "../../EditLaunchForm.types";
import { IndividualFormFieldWithController } from "../../../../IndividualFormFieldWithController/IndivudalFormFieldWithController";
import { FormFieldTypes } from "../../../../IndividualFormFieldWithController/IndividualFormFieldWithController.types";

export const EditLaunchQuestionnaireFormFieldsTestIdSuffixes = {
  pricingChangeInvolved: "-pricingChangeInvolved",
  namingInvolved: "-namingInvolved",
  prfaqRequired: "-prfaq",
  regulated: "-regulated",
};

export const EditLaunchQuestionnaireFormFields: FunctionComponent<IEditLaunchFormFieldsProps> = (props) => {

  const { control, errors } = props;

  // instantiates field in form and hooks it up to parent react-hook-form via control + errors
  const getFormField = useCallback((formField: IFormFieldData, fieldType: FormFieldTypes, defaultValue: Optional<string> | string[] | ISelectableFormInput | boolean, info?: Displayable) => {
    return <IndividualFormFieldWithController control={control}
                                              errors={errors}
                                              formField={formField}
                                              defaultValue={defaultValue}
                                              popoverInfo={info}
                                              fieldType={fieldType}
                                              {...getDataTestIdProp(props, EditLaunchQuestionnaireFormFieldsTestIdSuffixes[formField.id])}
    />;
  }, [control, errors, props]);

  return (
    <>
      <SpaceBetween
        {...getDataTestIdProp(props)}
        direction="vertical"
        size={formFieldSpacing.section}>
        <SpaceBetween direction={"vertical"} size={formFieldSpacing.vertical}>
          <ColumnLayout columns={4}>
            {getFormField(editLaunchFormFields.regulated, FormFieldTypes.CHECKBOX_ENTRY, control.getValues().regulated)}
            {getFormField(editLaunchFormFields.pricingChangeInvolved, FormFieldTypes.CHECKBOX_ENTRY, control.getValues().pricingChangeInvolved)}
            {getFormField(editLaunchFormFields.namingInvolved, FormFieldTypes.CHECKBOX_ENTRY, control.getValues().namingInvolved)}
            {getFormField(editLaunchFormFields.prfaqRequired, FormFieldTypes.CHECKBOX_ENTRY, control.getValues().prfaqRequired)}
          </ColumnLayout>
        </SpaceBetween>
      </SpaceBetween>
    </>
  );
};
