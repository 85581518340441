// Collections of tests on someone without Read Date ability
import { QaItemDefinition } from "../../models/QaItemDefinition";
import { IGetRegionsResponse } from "../../../daos/types/IGetRegionsResponse";
import { getRegionListOperation } from "../../remoteOperations/getRegionListOperation";
import { regionListOnlyHasGAChecker } from "../../checkers/regionListChecker";
import { IGetSingleServicePlanResponseLegacy } from "../../../daos/types/IGetSingleServicePlanResponse";
import {
  getServiceInRegionHistoryOperation,
  getServicePlanListOperation
} from "../../remoteOperations/servicePlanOperation";
import {
  servicePlanListHasNoNonGAConfidenceChecker, servicePlanListHasNoNonGADateChecker,
  servicePlanListHasNoNoteChecker,
} from "../../checkers/servicePlanListChecker";
import { IGetServiceRegionHistoryResponseLegacy } from "../../../daos/types/IGetServiceRegionHistoryResponse";
import {
  serviceInRegionHistoryHasNoNonGAConfidenceChecker, serviceInRegionHistoryHasNoNonGADateChecker,
  serviceInRegionHistoryHasNoNoteChecker
} from "../../checkers/serviceInRegionHistoryChecker";

export const listRegionShouldOnlySeeGaRegion = new QaItemDefinition<IGetRegionsResponse>(
  "/api/regions should only return GA regions",
  getRegionListOperation,
  regionListOnlyHasGAChecker,
);

// region service plan
export const getServicePlanListContainsNoNotes = new QaItemDefinition<IGetSingleServicePlanResponseLegacy>(
  "/api/services/.../plans should not contain notes",
  getServicePlanListOperation,
  servicePlanListHasNoNoteChecker
);


export const getServicePlanListContainsNoNonGAConfidence = new QaItemDefinition<IGetSingleServicePlanResponseLegacy>(
  "/api/services/.../plans should not contain confidence if the plan is not GA",
  getServicePlanListOperation,
  servicePlanListHasNoNonGAConfidenceChecker
);

export const getServicePlanListContainsNoNonGADates = new QaItemDefinition<IGetSingleServicePlanResponseLegacy>(
  "/api/services/.../plans should not contain dates if the plan is not GA",
  getServicePlanListOperation,
  servicePlanListHasNoNonGADateChecker
);
// endregion


// region service in region history
export const getServiceInRegionHistoryContainsNoNotes = new QaItemDefinition<IGetServiceRegionHistoryResponseLegacy>(
  "/api/services/.../plans/.../history should not contain notes",
  getServiceInRegionHistoryOperation,
  serviceInRegionHistoryHasNoNoteChecker
);


export const getServiceInRegionHistoryContainsNoNonGAConfidence = new QaItemDefinition<IGetServiceRegionHistoryResponseLegacy>(
  "/api/services/.../plans/.../history should not contain confidence if the plan is not GA",
  getServiceInRegionHistoryOperation,
  serviceInRegionHistoryHasNoNonGAConfidenceChecker,
);

export const getServiceInRegionHistoryContainsNoNonGADates = new QaItemDefinition<IGetServiceRegionHistoryResponseLegacy>(
  "/api/services/.../plans/.../history should not contain dates if the plan is not GA",
  getServiceInRegionHistoryOperation,
  serviceInRegionHistoryHasNoNonGADateChecker,
);
// endregion
