import { GENERALLY_AVAILABLE } from "../../constants";
import { IFeatureServiceItem, IParityScoreMap } from "../../daos/types/IGetFeatureDto"
import { Optional } from "../../models/types/Optional";
import { FeatureInRegionCommentVO } from "../../models/vos/FeatureInRegionCommentVO";

export const isItemComponent = (item) => item?.type === "component";
export const isItemService = (item) => item?.type === "service";

export const getItemKind = (item) => {
  if (isItemComponent(item)) {
    return "Component"
  } else if (isItemService(item)) {
    return "Service"
  } else {
    return "Feature"
  }
}

export function calculateCompletedRegions(starterRegions : string[] , allFeatureData : IFeatureServiceItem[]) : string[] {
  const completedRegions : string[] = [];

  starterRegions.forEach(region => {
    let isComplete = true;

    allFeatureData.forEach(featureInstance => {
      const inRegionStatus = featureInstance[region]?.status;
      if (inRegionStatus !== GENERALLY_AVAILABLE) {
        isComplete = false
      }
    });

    if (isComplete) {
      completedRegions.push(region);
    }
  });

  return completedRegions;
};

export function roudWithPrecision(value: number, precision: number) : number {
  const multiplier = Math.pow(10, precision || 0);
  return Math.round(value * multiplier) / multiplier;
}

export function getParityScoreForRegion(region : string, allFeatureData : IFeatureServiceItem[]) : number {
  let numGAs = 0;
  let totalNumFeatures = allFeatureData.length;
  if (totalNumFeatures === 0) // lets avoid divide by zero issues lol
    totalNumFeatures = 1

  allFeatureData.forEach((feature) => {
    const featureStatus = feature[region]?.status;
    if (featureStatus === GENERALLY_AVAILABLE) {
      numGAs++;
    }
  });

  const score = numGAs / totalNumFeatures * 100;
  const roundedScore = roudWithPrecision(score, 1)
  return roundedScore;
}


export function calculateParityPercentage(item : IFeatureServiceItem, starterRegions : string[]) : number {
  let numLaunchedRegions = 0;
  const denominator = starterRegions.length;

  starterRegions.forEach(region => {
    const inRegionStatus = item[region]?.status;
    if (inRegionStatus === GENERALLY_AVAILABLE) {
      numLaunchedRegions++
    }
  });

  // handle divide by zero
  if (numLaunchedRegions === 0 || denominator === 0) {
    return 0;
  }

  let parityScore = (numLaunchedRegions / denominator) * 100;
  parityScore = Math.floor(parityScore);
  return parityScore
}

export function getNonCompletedRegions(starterRegions : string[], completedRegions : string[]) : string[] {
  const nonCompletedRegions : string[] = [];
  starterRegions.forEach((region) => {
    if (!completedRegions.includes(region)) {
      nonCompletedRegions.push(region);
    }
  });

  return nonCompletedRegions
}

export function getRegionOrderFromParityScoreMap(regionToParityScoreMap: IParityScoreMap) : string[] {
  const orderedRegions : string[] = [];

  const sortedMap = new Map();
  Object.keys(regionToParityScoreMap).forEach((k) => {
    sortedMap.set(k, regionToParityScoreMap[k]);
  });

  const newSortedMap = new Map([...sortedMap].sort((a, b) => a[1] > b[1] ? 1 : -1));
  newSortedMap.forEach((score, region) => {
    orderedRegions.push(region);
  });

  return orderedRegions;
}

export function orderRegionsBasedOnParity(
  starterRegions : string[],
  completedRegions: string[],
  allFeatureData : IFeatureServiceItem[],
  selectedOptionId : string
  ) : string[] {

  const regionToParityScoreMap : IParityScoreMap = {};
  let regionsList = starterRegions;

  if (selectedOptionId === "default") {
    regionsList = getNonCompletedRegions(starterRegions, completedRegions);
  }

  regionsList.forEach((region) => {
    regionToParityScoreMap[region] = getParityScoreForRegion(region, allFeatureData)
  });

  const orderedRegions = getRegionOrderFromParityScoreMap(regionToParityScoreMap)
  return orderedRegions
}

export const getCommentToDisplay = (
  showResolvedComments: boolean,
  getComment: (feature: string, region: string) => Optional<FeatureInRegionCommentVO>,
  feature: string,
  region: string,
  ): Optional<FeatureInRegionCommentVO> => {
  const comment = getComment(feature, region);

  if (!showResolvedComments && comment?.resolved) {
    return undefined;
  }

  return comment;
}
