import { Optional } from "../../models/types/Optional";
import { GENERALLY_AVAILABLE, NON_GLOBALLY_EXPANDING } from "../../constants";
import moment from "moment";
import { hasDisposition, toShortDate } from "../../utils/dateUtil";
import { isRegionApplicable, isServicePlanApplicable } from "../../utils/rmsProjectedDateUtil";
import { UserAbilities } from "../../models/UserAbilities";

export const DATE_DELIVERED_HEADER = "Date Delivered"
export const PLANNED_LAUNCH_HEADER = "Planned Launch Date"

export const shouldShowDateInformation = (userAbilities: UserAbilities, status: string): boolean => {
  return userAbilities.canReadDates || status === GENERALLY_AVAILABLE;
}

export const shouldShowBlueprintDate = (blueprintEstLaunchDate: Optional<string>, isGa: boolean): boolean => {
  return !isGa && blueprintEstLaunchDate !== undefined;
}

export const shouldShowRmsProjectedDate = (regionName: string, serviceMetadataPlan: string, isGa: boolean): boolean => {
  return !isGa && isRegionApplicable(regionName) && isServicePlanApplicable(serviceMetadataPlan);
}

export const getRenderedDate = (isGa: boolean, disposition: Optional<string>, date: Optional<string>, servicePlan: Optional<string>): string => {
  const shortDate = toShortDate(date) as string;

  if (isGa) {
    return shortDate;
  } else if (hasDisposition(disposition)) {
    // @ts-ignore hasDisposition confirms disposition is not undefined, so we can safely ignore
    return disposition;
  } else if (date && moment(date).isValid()) {
    return shortDate;
  } else if (servicePlan === NON_GLOBALLY_EXPANDING) {
    return "Not Launching";
  } else {
    return "Need Date";
  }
}

export const isGaStatus = (status: string): boolean => {
  return status === GENERALLY_AVAILABLE;
}

export const getDateHeader = (isGa: boolean): string => {
  return isGa ? DATE_DELIVERED_HEADER : PLANNED_LAUNCH_HEADER;
}

export const getDisplayNote = (disposition: Optional<string>, note: string, sim: string): string => {
  return disposition ? `${note} : ${sim}` : note;
}
