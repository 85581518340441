import { CtiVO } from "../../../models/vos/CtiVO";
import { LxsVO, ServiceContactsVO } from "../../../models/vos/ServiceContactsVO";
import { emptyExportValue } from "../../ExportTableToCSVExcel/ExportTableToCSVExcel";
import { ripContactsDisplay } from "../ServiceContactsListTable/ServiceContactsTableOptions";

export const serviceContactsCSVHeaders = [
  "Service Name", 
  "Partition",
  "Type", 
  "GM",
  "VP",
  "Primary Build POC", 
  "Secondary Build POC",
  "Build TPM",
  "AZ Service Owner",
  "Local Zone Service Owner",
  "VPCE Build Owner",
  "L7s",
  "L8s",
  "L10s",
  "Leadership Chain",
  "Notifications Alias",
  "RIP Contacts",
  "Build Task",
  "CTI",
  "Oncall Team",
  "Operational Dashbaord",
  "SIM GUID",
  "Wiki",
];

export function convertLeadershipChainToString(leadershipChain: LxsVO[]): string {
  const leaderStrings = leadershipChain.map((leader) => `${leader.alias} (L${leader.level})`);
  return leaderStrings.join(" -> ");
}

export function leadersByLevel(leadershipChain: LxsVO[], level: number): string {
  const leaders: string[] = []
  leadershipChain.forEach((leader) => {
    if (leader.level === level) {
      leaders.push(leader.alias);
    }
  })
  return leaders.join(",");
}

export function convertCtiToString(cti: CtiVO): string {
  return `${cti.category}/${cti.type}/${cti.item}`;
}

export function serviceContactsDataModel(contacts: ServiceContactsVO[]): string[][] {
  return contacts.map((contact) => [
    contact.serviceName,
    contact.partition,
    contact.contactsType,
    contact.gm ?? emptyExportValue,
    contact.vp ?? emptyExportValue,
    contact.primaryBuildPoc ?? emptyExportValue,
    contact.secondaryBuildPoc ?? emptyExportValue,
    contact.rbtServiceManager ?? emptyExportValue,
    contact.azServiceOwner ?? emptyExportValue,
    contact.localZoneServiceOwner ?? emptyExportValue,
    contact.vpceBuildOwner ?? emptyExportValue,
    contact.leadershipChain ? leadersByLevel(contact.leadershipChain, 7) : emptyExportValue,
    contact.leadershipChain ? leadersByLevel(contact.leadershipChain, 8) : emptyExportValue,
    contact.leadershipChain ? leadersByLevel(contact.leadershipChain, 10) : emptyExportValue,
    contact.leadershipChain ? convertLeadershipChainToString(contact.leadershipChain) : emptyExportValue,
    contact.notifications ?? emptyExportValue,
    ripContactsDisplay(contact.ripContacts) ?? emptyExportValue,
    contact.buildTask ?? emptyExportValue,
    contact.cti ? convertCtiToString(contact.cti) : emptyExportValue,
    contact.oncallTeam ?? emptyExportValue,
    contact.opsDashboard ?? emptyExportValue,
    contact.simGuid ?? emptyExportValue,
    contact.wikiUrl ?? emptyExportValue
  ])
}

export const serviceContactsCSVExportFilename: string = `Service Contacts`;
