import { QaItemDefinition } from "../../models/QaItemDefinition";
import { IGenericMutationResponseDto } from "../../../daos/types/IGenericMutationResponseDto";
import { updateEstimatedDateOperation } from "../../remoteOperations/planOperation";
import { updateEstimatedDateSuccessChecker } from "../../checkers/updatePlanChecker";

export const updateEstimatedDate = new QaItemDefinition<IGenericMutationResponseDto>(
  "PATCH /api/services/.../plans/... Update estimated date",
  updateEstimatedDateOperation,
  updateEstimatedDateSuccessChecker
);
