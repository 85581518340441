import { ServiceResource } from "@amzn/region-services-react-library-v4";
import { DiagnosticAdmin } from "../views/Diagnostic/DiagnosticAdmin";
import { DiagnosticContactMaintainer } from "../views/Diagnostic/DiagnosticContactMaintainer";
import { DiagnosticCustomerFacing } from "../views/Diagnostic/DiagnosticCustomerFacing";
import { DiagnosticNoAccess } from "../views/Diagnostic/DiagnosticNoAccess";
import { DiagnosticReadAll } from "../views/Diagnostic/DiagnosticReadAll";
import { DiagnosticServiceOwner } from "../views/Diagnostic/DiagnosticServiceOwner";
import { IRouteDefinition } from "./IRouteDefinition";

export const diagnosticRoutes: IRouteDefinition[] = [
  {
    key: "diagnosticAdmin",
    path: "/diagnostic/s/admin",
    resourceConstructor: ServiceResource,
    exact: true,
    secure: true,
    component: DiagnosticAdmin,
  },
  {
    key: "diagnosticCustomerFacing",
    path: "/diagnostic/s/customerfacing",
    resourceConstructor: ServiceResource,
    exact: true,
    secure: true,
    component: DiagnosticCustomerFacing,
  },
  {
    key: "diagnosticReadAll",
    path: "/diagnostic/s/readall",
    resourceConstructor: ServiceResource,
    exact: true,
    secure: true,
    component: DiagnosticReadAll,
  },
  {
    key: "diagnosticServiceOwner",
    path: "/diagnostic/s/serviceowner",
    resourceConstructor: ServiceResource,
    exact: true,
    secure: true,
    component: DiagnosticServiceOwner,
  },
  {
    key: "diagnosticContactMaintainer",
    path: "/diagnostic/s/contactsmaintainer",
    resourceConstructor: ServiceResource,
    exact: true,
    secure: true,
    component: DiagnosticContactMaintainer,
  },
  {
    key: "diagnosticNoAccess",
    path: "/diagnostic/s/noaccess",
    resourceConstructor: ServiceResource,
    exact: true,
    secure: false,
    component: DiagnosticNoAccess,
  },
];
