import * as React from "react";
import { FunctionComponent } from "react";
import { LoadingIndicatorProps } from "./LoadingIndicator.types";
import { StatusIndicator } from "@amzn/awsui-components-react-v3";

export const LoadingIndicatorTestId = {
  component: "Loading",
}

export const LoadingIndicator: FunctionComponent<LoadingIndicatorProps> = (props) => {
  return (
    <StatusIndicator
      data-testid={LoadingIndicatorTestId.component}
      type={"loading"}>Loading&hellip;</StatusIndicator>
  );
};
