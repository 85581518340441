import * as React from "react";
import { FunctionComponent, useCallback, useState } from "react";
import { IAddOrEditFormInput, IAddOrEditServiceContactModalProps } from "./AddOrEditServiceContactModal.types";
import { Button, Flashbar, Modal, SpaceBetween } from "@amzn/awsui-components-react-v3";
import { contactsWriteAccessSimTemplateLink } from "../../../common/SimLinks/ContactsWriteAccessSimTemplateLink";
import { PermissionError } from "../../../common/PermissionError";
import { useForm } from "react-hook-form";
import { ContactsType } from "../../../../models/vos/ServiceContactsVO";
import { serviceNameFormFields } from "./AddOrEditServiceContactModalFormFields";
import { contactsTypeMessage, EnterServiceContactTestId } from "./AddOrEditServiceContactModalConstants";
import { FormContainer, formFieldSpacing, FormScrollableContainer } from "./AddOrEditServiceContactModal.style";
import {
  getAppearance,
  getContactNameFromForm,
  getDisabled,
  getModalTitle,
  IEditContactAppearance,
  processAddOrEditServiceContactsResponse,
  submitButtonLabel
} from "./AddOrEditServiceContactModalUtils";
import { buildTaskNoSpaces } from "../../../../daos/ServiceContactsDao";
import { EditContactsHeader } from "./EditContactsHeader/EditContactsHeader";
import { ContactsTypeSelectionHeader } from "./ContactsTypeSelectionHeader/ContactsTypeSelectionHeader";
import { MutualFormFields } from "./MutualFormFields/MutualFormFields";
import { CTIFormFields } from "./CTIFormFields/CTIFormFields";
import { PartitionFormFields, PARTITION_TO_LONG_NAME } from "./PartitionFormFields/PartitionFormFields"
import { IndividualContactFormFieldWithController } from "./IndividualContactFormFieldWithController/IndividualContactFormFieldWithController";
import {
  ServiceContactUpdateError,
  ServiceContactUpdateErrorCode,
  ServiceContactUpdateResult,
} from "../../../../orchestrators/ServiceContactOrchestrator";
import { MutationResultType } from "../../../../models/remoteMutation";
import { DialogFooterButtonBar } from "../../../common/Dialog/DialogFooterButtonBar";
import { getUpdateError } from "../../serviceContactUtil";
import { Optional } from "@amzn/excelerator/lib/models/Nullable";
import { LoadingIndicator } from "../../../LoadingIndicator/LoadingIndicator";
import { getDataTestIdProp } from "../../../../utils/testUtil";
import { FlashbarProps } from "@amzn/awsui-components-react-v3/polaris/flashbar";
import { Displayable } from "@amzn/api-parity-react-component/lib/models/Displayable";

export const AddOrEditServiceContactModalTestIdSuffix = {
  submit: "-submit",
  cancel: "-cancel",
  contactTypeEdit: "-contactTypeEdit",
  ripName: "-ripName",
  buildTask: "-buildTask",
  cti: "-cti",
  mutual: "-mutual",
  partition: "-partition",
  dialog: "-modal",
  openButton: "-open",
  errorMessage: "-error",
}


export const AddOrEditServiceContactModal: FunctionComponent<IAddOrEditServiceContactModalProps> = (props) =>{

  const [visible, setVisible] = React.useState<boolean>(false);
  const [showPermissionError, setShowPermissionError] = React.useState(false);
  const [modalLoading, setModalLoading] = React.useState(false);
  const [flashMessage, setFlashMessage] = React.useState<FlashbarProps.MessageDefinition[]>([]);
  const [modalKey, modalKeySet] = useState<number>(1);

  const {
    userAbilities,
    isAdding,
  } = props;

  const { control, errors, trigger, watch, getValues, reset, setValue } = useForm<IAddOrEditFormInput>({ mode: "onChange", shouldUnregister: false })

  const watchContactsType = watch("contactsType");

  if (props.selected){
    (props.selected.contactsType === ContactsType.Rip ?  setValue(serviceNameFormFields.ripShortname.id, props.selected.buildTask) : setValue(serviceNameFormFields.buildTask.id, props.selected.buildTask))
  }

  const open_onClick = useCallback(() => {
    modalKeySet(new Date().getTime());
    reset()
    if (!userAbilities.canWriteContacts && !props.isAdding) {
      setShowPermissionError(true);
      return;
    }
    setVisible(true);
  }, [userAbilities, reset]);

  const cancel_onClick = useCallback(() => {
    setVisible(false);
    reset();
    setFlashMessage([]);
  }, [reset]);

  const dialog_onDismiss = useCallback(() => {
    setVisible(false);
    reset();
    setFlashMessage([]);
  }, [reset, setVisible, setFlashMessage]);

  const permissionError_onDismiss = useCallback(() => {
    setShowPermissionError(false);
  }, [setShowPermissionError]);

  const displayErrorBanner = useCallback((serviceName: string, content: Displayable, testId?:string) => {
    setFlashMessage([{
      header: `Failed to ${isAdding ? "add" : "update"} ${serviceName}`,
      type: "error",
      content: <div data-testid={testId}> {content} </div>,
      dismissible: true,
      onDismiss: () => setFlashMessage([])
    }])
  },[isAdding])

  const submit_onClick = useCallback(async() => {
    if (!props.onAddOrEditServiceContactSubmit){
      return
    }

    if (await trigger()) {
      setModalLoading(true);
      let data = getValues()
      if (props.isAddingNewPartition) {
        data.contactsType = ContactsType.Rip;
      }
      let contactName = getContactNameFromForm(data, props.selected)
      //TODO: move this to dao or orchestrator
      data.serviceName = buildTaskNoSpaces(contactName)

      try {
        let response: ServiceContactUpdateResult;
        if (props.isAddingNewPartition) {
          response = await props.onAddOrEditServiceContactSubmit?.(data, undefined);
        } else {
          response = await props.onAddOrEditServiceContactSubmit?.(data, props?.selected);
        }
        processAddOrEditServiceContactsResponse(response, props.isAdding, contactName, props.onSuccessfulSubmit);
        reset()
        setFlashMessage([]);
        setVisible(false);
      }
      catch (exception) {
        if ((exception as ServiceContactUpdateError).type === MutationResultType.Error){
          const err: ServiceContactUpdateError = exception as ServiceContactUpdateError;
          const code: ServiceContactUpdateErrorCode = err.errors[0].code;
          const message: Displayable = getUpdateError(contactName, err.errors);
          const testId: Optional<string> = (code === ServiceContactUpdateErrorCode.InvalidRipName) ? EnterServiceContactTestId.errorBanners.invalidRip : undefined;
          displayErrorBanner(contactName, message, testId);
        }
      }
      setModalLoading(false);
    }
  }, [props, trigger, getValues, reset, displayErrorBanner]);

  const footer = useCallback(() => {
    return (
      <DialogFooterButtonBar>
        <Button variant="link" onClick={cancel_onClick} {...getDataTestIdProp(props, AddOrEditServiceContactModalTestIdSuffix.cancel)}>
          Cancel
        </Button>
        <Button variant="primary" onClick={submit_onClick} {...getDataTestIdProp(props, AddOrEditServiceContactModalTestIdSuffix.submit)}>
          {submitButtonLabel(isAdding)}
        </Button>
      </DialogFooterButtonBar>
    );
  }, [cancel_onClick, submit_onClick, isAdding, props]);

  const appearance: IEditContactAppearance = getAppearance(isAdding, !!props.isAddingNewPartition);

  function getDisabledNewPartition(): boolean {
    const HIDE_BUTTON = true;
    const ALLOW_BUTTON = false;

    if (props.isAddingNewPartition) {
      const contactHasPartitions = props.selected;
      if (contactHasPartitions) {
        const max_partitions = Object.keys(PARTITION_TO_LONG_NAME).length;
        const exhaustedPartition = props.existingPartitions?.length === max_partitions;
        return exhaustedPartition;
      }
      else if (!contactHasPartitions) {
        return ALLOW_BUTTON
      }
      return HIDE_BUTTON;
    }
    return ALLOW_BUTTON;
  }

  return (
    <React.Fragment>
      <Modal
        key={modalKey.toString()}
        {...getDataTestIdProp(props, AddOrEditServiceContactModalTestIdSuffix.dialog)}
        onDismiss={dialog_onDismiss}
        visible={visible}
        header={getModalTitle(props.isAdding, props.selected)}
        footer={footer()}>
        {modalLoading ? <LoadingIndicator /> :
          <React.Fragment>
            {
              (flashMessage.length > 0) &&
                <Flashbar
                  {...getDataTestIdProp(props, AddOrEditServiceContactModalTestIdSuffix.errorMessage)}
                  items={flashMessage}/>
            }

            <FormScrollableContainer>
              <FormContainer>
                <small>*Required</small>
                <SpaceBetween direction="vertical" size={formFieldSpacing.section}>
                  {!props.selected ? (!props.isAddingNewPartition && <ContactsTypeSelectionHeader {...getDataTestIdProp(props, AddOrEditServiceContactModalTestIdSuffix.contactTypeEdit)}
                                                                  control={control}
                                                                  errors={errors} />) :
                    <EditContactsHeader contactsType={props.selected.contactsType}
                                        contactsTypeLabel={contactsTypeMessage}
                                        serviceName={getContactNameFromForm(getValues(), props.selected)}
                                        partition={props.selected.partition}
                                        isAddingNewPartition={props.isAddingNewPartition}/>}
                  {((watchContactsType === ContactsType.Rip || (props.isAddingNewPartition && !props.selected)) && <IndividualContactFormFieldWithController {...getDataTestIdProp(props, AddOrEditServiceContactModalTestIdSuffix.ripName)}
                                                                                                          control={control}
                                                                                                          errors={errors}
                                                                                                          formField={serviceNameFormFields.ripShortname}
                                                                                                          selected={props.selected}
                                                                                                          nameRip={props.nameRip}/>) ||
                  ((watchContactsType === ContactsType.NonRip) && <IndividualContactFormFieldWithController {...getDataTestIdProp(props, AddOrEditServiceContactModalTestIdSuffix.buildTask)}
                                                                                                            control={control}
                                                                                                            errors={errors}
                                                                                                            formField={serviceNameFormFields.buildTask}
                                                                                                            selected={props.selected}/> )}
                  {(watchContactsType === ContactsType.NonRip || props.selected?.contactsType === ContactsType.NonRip) && <CTIFormFields {...getDataTestIdProp(props, AddOrEditServiceContactModalTestIdSuffix.cti)}
                                                                                                                                         control={control} errors={errors} selected={props.selected}/>}
                  {(!!watchContactsType || props.isAddingNewPartition) && <PartitionFormFields {...getDataTestIdProp(props, AddOrEditServiceContactModalTestIdSuffix.partition)}
                                                                                               control={control} errors={errors} selected={props.selected} removeEntries={props.existingPartitions}/>}
                  {(!!watchContactsType || !!props.selected?.contactsType || props.isAddingNewPartition) && <MutualFormFields {...getDataTestIdProp(props, AddOrEditServiceContactModalTestIdSuffix.mutual)}
                                                                                            control={control} errors={errors} selected={props.isAddingNewPartition ? undefined : props.selected}/>}
                </SpaceBetween>
              </FormContainer>
            </FormScrollableContainer>
          </React.Fragment>
        }
      </Modal>
      <PermissionError
        header="Access Denied"
        body={<>
          Need bindle permission or Recon Service Contacts Write Access to add or update service contacts,
          please use {contactsWriteAccessSimTemplateLink()} to request access to Recon Service Contacts Write permission.
        </>}
        visible={showPermissionError}
        onDismiss={permissionError_onDismiss}
        testIds={EnterServiceContactTestId.permissionError}
      />
      <Button
        {...getDataTestIdProp(props, AddOrEditServiceContactModalTestIdSuffix.openButton)}
        onClick={open_onClick}
        disabled={getDisabledNewPartition() || getDisabled(props.isAdding, props.selected)}
        iconName={appearance.openButtonIcon}
      >
        { appearance.openButtonLabel }
      </Button>
    </React.Fragment>
  )
}
