import { Optional } from "../../models/types/Optional";
import { MultiSelectItemId } from "./IMultiSelectComponent";

// @ts-ignore
const defaultExtractIdFn = <T>(item: T) => item.id as MultiSelectItemId;
export function getIdFromItem<T>(item: T, extractFn: Optional<(T) => MultiSelectItemId> = defaultExtractIdFn): MultiSelectItemId {
  return extractFn(item);
}

// @ts-ignore
const defaultExtractLabelFn = <T>(item: T) => item.label as string | JSX.Element;
export function getLabelFromItem<T>(item: T, extractFn: Optional<(T) => string | JSX.Element> = defaultExtractLabelFn): string | JSX.Element {
  return extractFn(item);
}

// @ts-ignore
const defaultExtractChildrenFn = <T>(item: T) => item.children as Optional<T[]>;
export function getChildrenFromItem<T>(item: T, extractFn: Optional<(T) => Optional<T[]>> = defaultExtractChildrenFn): Optional<T[]> {
  return extractFn(item);
}
