import { FunctionComponent } from "react";
import * as React from "react";
import { ContactsTypeSelectionHeaderProps } from "./ContactsTypeSelectionHeader.types";
import { FormField, Tiles } from "@amzn/awsui-components-react-v3";
import { contactsTypeMessage } from "../AddOrEditServiceContactModalConstants";
import { ContactsType } from "../../../../../models/vos/ServiceContactsVO";
import { Controller } from "react-hook-form";
import { getDataTestIdProp } from "../../../../../utils/testUtil";

export const ContactsTypeSelectionHeaderTestIdSuffix = {
  field: "-field",
  input: "-input",
}

export const ContactsTypeSelectionHeader: FunctionComponent<ContactsTypeSelectionHeaderProps> = (props) => {
  return (
    <Controller
      name="contactsType"
      id="contactsType"
      control={props.control}
      defaultValue=""
      rules={{
        required: "Selection required.",
      }}
      render={({ onChange, value }, { invalid }) =>
        <FormField
          {...getDataTestIdProp(props, ContactsTypeSelectionHeaderTestIdSuffix.field)}
          label={contactsTypeMessage+"*"}
          errorText={(invalid && props.errors.contactsType?.message)}>
          <Tiles
            {...getDataTestIdProp(props, ContactsTypeSelectionHeaderTestIdSuffix.input)}
            onChange={(e) => onChange(e.detail.value)}
            value={value}
            items={[
              { label: "Yes", value: ContactsType.Rip },
              { label: "No", value: ContactsType.NonRip },
            ]}
          />
        </FormField>
      }
    />
  );
};
