import { hydrateModel } from "../../utils/modelUtil";
import { Audience } from "../../daos/types/Audience";
import { IProductContact } from "../../daos/types/IProductDto";

export class ProductVO {
  id: string = "";
  blueprintUid?: string;
  parentProductId?: string;
  name: string = "";
  codename?: string;
  ripId?: string;
  type?: string;
  audience?: Audience;
  confidential?: boolean;
  category?: string;
  vp?: string;
  gm?: string;
  cti?: string;
  tags?: string[];
  children?: string[];
  description?: string;
  primaryOwner?: IProductContact;
  secondaryOwners?: IProductContact[];
  state?: string;
  rowId?: string;
  productApprovalDate?: string;
  productApprovedBy?: string;

  readGroups?: string[];
  writeGroups?: string[];

  constructor(from?: Partial<ProductVO>) {
    hydrateModel(from, this);
  }
}

// Type to facilitate storage of parentProduct
export class ChildProductVO extends ProductVO {
  parentProduct?: ProductVO;

  constructor(from?: Partial<ChildProductVO>) {
    super(from);
    hydrateModel(from, this);
  }
}
