import * as React from "react";
import { useCallback, useEffect, useState } from "react";
import RegionsTable from "../common/TableComponents/RegionsTable";
import { SpaceBetween, Toggle } from "@amzn/awsui-components-react-v3";
import { SingleServiceWithRIPLink } from "../common/Links";
import { defaultTableConfig, sortSortableName, } from "../../utils/tableUtils";
import { ServiceListVM, ServicesListTableProps } from "./ServicesListTable.types";
import { NonCancelableCustomEvent } from "@amzn/awsui-components-react-v3/uxdg";
import { ToggleProps } from "@amzn/awsui-components-react-v3/polaris/toggle";
import { generateServicesListWorkbook } from "./ServiceListTableCsvExport";
import { parseAwsServiceVOToServiceListVM } from "./ServiceListTableUtil";
import { TablePropertyFiltering } from "@amzn/awsui-components-react";
import { getNonEmptyProperties } from "../../utils/collectionUtil";
import { getServiceListDefaultSortConfigs } from "./ServicesListTableSortUtil";
import { ExportTableToCSVExcel } from "../ExportTableToCSVExcel/ExportTableToCSVExcel";


export const ServicesListTableTestId = {
  table: "ServicesListTable-table",
  loadNonCategorizedServiceToggle: "ServicesListTable-loadNonCategorizedServiceToggle",
  exportTable: "ServicesListTable-exportTable",
}

const COLUMN_DEFINITIONS = [
  {
    id: "nameSortable",
    header: "Service Name",
    cell: (service: ServiceListVM) => (<SingleServiceWithRIPLink serviceObject={
      { nameRip: service.nameRip, 
        nameLong: service.nameLong,
        nameSortable: service.nameSortable
      }}/>),
  },
  {
    id: "category",
    header: "Category",
    cell: (service: ServiceListVM) => service.category,
  },
  {
    id: "visibility",
    header: "Visibility",
    cell: (service: ServiceListVM) => service.visibility,
  },
];

const SORTABLE_COLUMNS = [
  { id: "nameSortable", field: "nameSortable", comparator: sortSortableName },
  ...getServiceListDefaultSortConfigs(["category", "visibility"]),
];

const getFilteringOptions = (services: ServiceListVM[]): TablePropertyFiltering.Option[] => {
  const filteringOptions = [
    {
      groupValuesLabel: "Service Names (Sortable)",
      propertyKey: "nameSortable",
      propertyLabel: "Service Name (Sortable)",
      values: getNonEmptyProperties(services, (svc) => svc.nameSortable),
    },
    {
      groupValuesLabel: "Service Names (Long)",
      propertyKey: "nameLong",
      propertyLabel: "Service Name (Long)",
      values: getNonEmptyProperties(services, (svc) => svc.nameLong),
    },
    {
      groupValuesLabel: "RIP Names",
      propertyKey: "nameRip",
      propertyLabel: "RIP Name",
      values: getNonEmptyProperties(services, (svc) => svc.nameRip),
    },
    {
      groupValuesLabel: "Category",
      propertyKey: "category",
      propertyLabel: "Category",
      values: getNonEmptyProperties(services, (svc) => svc.category),
    },
    {
      groupValuesLabel: "Visibility",
      propertyKey: "visibility",
      propertyLabel: "Visibility",
      values: getNonEmptyProperties(services, (svc) => svc.visibility),
    },
  ];

  return filteringOptions
};

const getDefaultFilters = (): TablePropertyFiltering.FilteringToken[] => [{
  label: "EXTERNAL",
  propertyKey: "visibility",
  propertyLabel: "Visibility",
  value: "EXTERNAL",
  isFreeText: false,
  negated: false,
}]

const getTableConfig = (services, loading, header, filteringOptions) => {
  const columnDefinitions = COLUMN_DEFINITIONS ;

  return defaultTableConfig({
    features: [
      "TablePropertyFiltering",
      "TablePagination",
      "TableSorting",
      "TablePreferences",
      "TablePageSizeSelector",
      "TableWrapLines",
    ],
    variant: "default",
    columnDefinitions,
    header,
    items: services,
    loading,
    filteringOptions,
    sortableColumns: SORTABLE_COLUMNS,
    sortingColumn: "nameSortable",
    tableSelection: {},
    contentSelectorOptions: {},
    defaultFilters: getDefaultFilters()
  });
};

export const ServicesListTable: React.FunctionComponent<ServicesListTableProps> = (props) => {
  const { items = [], loading = true, onToggle, loadAllServices } = props;
  const [services, servicesSet] = useState<ServiceListVM[]>([]);
  const [filteringOptions, filteringOptionsSet] = useState<TablePropertyFiltering.Option[]>([]);
  const [tableConfig, tableConfigSet] = useState<any>();

  const loadBehavior_onChange = useCallback(( evt: NonCancelableCustomEvent<ToggleProps.ChangeDetail> ): void => {
    onToggle?.(evt.detail.checked);
  }, [onToggle]);

  const getExportDataBook = useCallback(() => {
    return generateServicesListWorkbook(items)
  }, [items]);

  useEffect(() => {
    const svcs: ServiceListVM[] = parseAwsServiceVOToServiceListVM(items)
    servicesSet(svcs);
    filteringOptionsSet(getFilteringOptions(svcs));
  },[items])

  useEffect(() => {
    const header = RegionsTable.getTableHeader({
      primary: loadAllServices ? "Services" : "Categorized Services",
      secondary: `${services.length}`,
      actionButtons: (
        <SpaceBetween direction='horizontal' size='xl'>
          <Toggle
            data-testid={ServicesListTableTestId.loadNonCategorizedServiceToggle}
            onChange={loadBehavior_onChange}
            checked={loadAllServices}>
            Include Non-Categorized Services
          </Toggle>
          <ExportTableToCSVExcel fileNamePrefix={`ServicesList`} generateDataBook={getExportDataBook} data-testid={ServicesListTableTestId.exportTable}/>
        </SpaceBetween>
      ),
    });
    tableConfigSet(getTableConfig(services, loading, header, filteringOptions));
  }, [
    services,
    filteringOptions,
    loadBehavior_onChange,
    loading,
    loadAllServices,
    getExportDataBook
  ]);

  return <RegionsTable testId={ServicesListTableTestId.table} {...tableConfig} />;
};

export default ServicesListTable;
