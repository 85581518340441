export interface IServiceInRegionReportOption {
  value: string;
  label: string;
  filenamePart: string;
}

export const estimatedDate: IServiceInRegionReportOption = {
  value: "estimated",
  label: "Estimated Launch Dates",
  filenamePart: "Estimated Launch Dates",
};

export const historicalDate: IServiceInRegionReportOption = {
  value: "historical",
  label: "Historical Launch Dates",
  filenamePart: "Historical Launch Dates",
};

export const estimatedAndHistoricalDate: IServiceInRegionReportOption = {
  value: "estimated-historical",
  label: "Estimated Launch Dates + Historical Launch Dates",
  filenamePart: "Estimated and Historical Launch Dates",
};

export const serviceInRegionReportOptions: IServiceInRegionReportOption[] = [
  estimatedDate,
  estimatedAndHistoricalDate,
  historicalDate
];
