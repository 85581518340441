import { getALMApiClient, PaginationResponse } from "./daoUtil";
import { EventType, IEvent, IEventDateDto } from "./types/IEventDateDto";

export type IGetEventsResponse = Record<string, IEvent>;

export class EventDateDao {
  baseUrl = "/api/eventdates?"

  getEventDatesRangeURL(startDate: string, endDate: string, eventType?: EventType): string {
    const query = {
      start: startDate,
      end: endDate,
      ...(eventType && { type: eventType })
    }

    return "/api/eventdates?" + new URLSearchParams(query).toString()
  }

  async getAllEventDates(maxResults?: number, nextToken?: string): Promise<PaginationResponse<IEventDateDto>> {
    const client = getALMApiClient();
    const urlParams = new URLSearchParams();
    maxResults && urlParams.append("MaxResults", encodeURIComponent(maxResults));
    nextToken && urlParams.append("NextToken", nextToken);
    const res = await client.get<PaginationResponse<IEventDateDto>>(`/api/eventdates?${urlParams.toString()}`);
    return res.data;
  }

  async getEventDatesRange(startDate: string, endDate: string, eventType?: EventType): Promise<IEventDateDto> {
    const client = getALMApiClient();
    const res = await client.get<IEventDateDto>(this.getEventDatesRangeURL(startDate, endDate, eventType));
    return res.data;
  }
}
