import * as React from "react";
import { FunctionComponent } from "react";
import { Box } from "@amzn/awsui-components-react-v3";
import { ValueWithLabelProps } from "./ValueWithLabel.types";
import { getDataTestIdProp } from "../../../utils/testUtil";
import { HelpPanelButton } from "../../HelpPanel/HelpPanelButton/HelpPanelButton";

export const ValueWithLabelPropsTestIdSuffixes = {
  label: "-label",
  content: "-content"
}

export const ValueWithLabel: FunctionComponent<ValueWithLabelProps> = (props) => (
  <div {...getDataTestIdProp(props)}>
    <Box>
      <Box
        display="inline-block"
        margin={{ bottom: "xxxs" }}
        color="text-label"
        {...getDataTestIdProp(props, ValueWithLabelPropsTestIdSuffixes.label)}
      >
        {props.label}
      </Box>
      {props.helpId &&
        <Box display="inline-block" padding={{ left: "xxs" }}>
          <HelpPanelButton helpId={props.helpId}>info</HelpPanelButton>
        </Box>
      }
    </Box>
    <div
      {...getDataTestIdProp(props, ValueWithLabelPropsTestIdSuffixes.content)}
    >
      {props.children}
    </div>
  </div>
);
