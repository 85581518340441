import { IFormFieldData } from "../../common/FormTypes";
import {
  REQUIRED,
  REQUIRED_AND_VALID_CHAR_REGEX,
  REQUIRED_ERROR_MESSAGE,
  VALID_ALIAS_CHAR_REGEX_AND_MAXLENGTH,
  VALID_CHAR_REGEX,
  VALID_DATE_NOT_PAST_NOR_WEEKEND,
  VALID_DESCRIPTION_REGEX,
  VALID_LINK
} from "../../IndividualFormFieldWithController/FormConstants";
import {
  LaunchDisclosure,
  LaunchGoalType,
  LaunchIteration,
  LaunchScope,
  LaunchTier,
  LaunchType
} from "./LaunchWizard.types";

const optionsYesNo = [
  { label: "Yes", value: "Yes" },
  { label: "No", value: "No" },
];

export interface ILaunchDetailFormValues {
  launchIteration: IFormFieldData;
  launchType: IFormFieldData;
  launchName: IFormFieldData
  launchDescription: IFormFieldData
  codeName: IFormFieldData
  ripName: IFormFieldData
  expectedLaunchDate: IFormFieldData
  launchScope: IFormFieldData
  tier: IFormFieldData
  disclosureLevel: IFormFieldData
  confidential: IFormFieldData
  goalType: IFormFieldData
  goalLink: IFormFieldData
  audience: IFormFieldData
  productManager: IFormFieldData
  productMarketingManager: IFormFieldData
  engineeringLead: IFormFieldData
  prfaqBoolean: IFormFieldData
  pricingBoolean: IFormFieldData
  regulatoryBoolean: IFormFieldData
  namingBoolean: IFormFieldData
}

const requiredToSubmitFieldNotation = "*"

export function enumToSelectOptions(enumList: any): { label: string; value: string; }[] {
  return Array.from(Object.keys(enumList)).map(key => {
    return { label: `${enumList[key]}`, value: key }
  })
}

export function enumToAutoSuggestOptions(enumList: any): { value: string; }[] {
  return Array.from(Object.keys(enumList)).map(key => {
    return { value: enumList[key] }
  })
}

export const createLaunchPropertiesFormFields: ILaunchDetailFormValues = {
  launchIteration: {
    placeholder: "Launch Iteration",
    id: "launchIteration",
    validation: REQUIRED,
    options: enumToSelectOptions(LaunchIteration),
    helpId: "LAUNCH_ITERATION",
  },
  launchType: {
    placeholder: "Launch Type",
    id: "launchType",
    validation: REQUIRED,
    options: enumToSelectOptions(LaunchType),
    helpId: "LAUNCH_TYPE",
  },
  launchName: {
    placeholder: "Launch Name",
    id: "launchName",
    validation: {
      required: REQUIRED_ERROR_MESSAGE
    },
    helpId: "LAUNCH_NAME",
  },
  launchDescription: {
    placeholder: `Launch Description${requiredToSubmitFieldNotation}`,
    id: "launchDescription",
    validation: VALID_DESCRIPTION_REGEX,
    helpId: "LAUNCH_DESCRIPTION",
  },
  codeName: {
    placeholder: "Code Name",
    id: "codeName",
    helpId: "LAUNCH_CODENAME",
  },
  ripName: {
    placeholder: "RIP Name",
    id: "ripName",
    options: [],
    validation: VALID_CHAR_REGEX,
    helpId: "LAUNCH_RIP",
  },
  expectedLaunchDate: {
    nameOverride: "",
    placeholder: "Launch Date",
    id: "expectedLaunchDate",
    validation: VALID_DATE_NOT_PAST_NOR_WEEKEND,
  },
  launchScope: {
    placeholder: "Launch Scope",
    id: "launchScope",
    options: enumToSelectOptions(LaunchScope),
    helpId: "LAUNCH_SCOPE",
  },
  tier: {
    placeholder: "Tier",
    id: "tier",
    options: enumToSelectOptions(LaunchTier),
    helpId: "LAUNCH_TIER",
  },
  disclosureLevel: {
    placeholder: "Customer Disclosure Level",
    id: "disclosureLevel",
    options: enumToSelectOptions(LaunchDisclosure),
    helpId: "LAUNCH_DISCLOSURE",
  },
  confidential: {
    placeholder: "Confidential",
    id: "confidential",
    options: optionsYesNo,
    helpId: "LAUNCH_CONFIDENTIAL"
  },
  goalType: {
    placeholder: "Goal Type",
    id: "goalType",
    options: enumToSelectOptions(LaunchGoalType),
    helpId: "LAUNCH_GOAL",
  },
  goalLink: {
    nameOverride: "Goal Link",
    placeholder: "https://kingpin.amazon.com/",
    id: "goalLink",
    validation: VALID_LINK,
    helpId: "LAUNCH_GOAL",
  },
  audience: {
    placeholder: "Audience",
    id: "audience",
    options: [
      { label: "External", value: "EXTERNAL" },
      { label: "Internal", value: "INTERNAL" }
    ],
    helpId: "LAUNCH_AUDIENCE",
  },
  productManager: {
    placeholder: "Product manager and primary contact",
    id: "productManager",
    validation: VALID_ALIAS_CHAR_REGEX_AND_MAXLENGTH
  },
  productMarketingManager: {
    placeholder: "Product marketing manager",
    id: "productMarketingManager",
    validation: VALID_ALIAS_CHAR_REGEX_AND_MAXLENGTH
  },
  engineeringLead: {
    placeholder: "Engineering Lead",
    id: "engineeringLead",
    validation: VALID_ALIAS_CHAR_REGEX_AND_MAXLENGTH
  },
  prfaqBoolean: {
    placeholder: `PRFAQ required?${requiredToSubmitFieldNotation}`,
    id: "prfaq",
    options: optionsYesNo,
    helpId: "LAUNCH_PRFAQ"
  },
  pricingBoolean: {
    placeholder: `Are you making changes to pricing?${requiredToSubmitFieldNotation}`,
    id: "pricingBoolean",
    options: optionsYesNo,
    helpId: "LAUNCH_PRICING_INVOLVED",
  },
  regulatoryBoolean: {
    placeholder: `Is this service subject to regulatory review (HIPAA, ISO, etc)?${requiredToSubmitFieldNotation}`,
    id: "regulatoryBoolean",
    options: optionsYesNo,
    helpId: "LAUNCH_REGULATED",
  },
  namingBoolean: {
    placeholder: `Naming required?${requiredToSubmitFieldNotation}`,
    id: "namingBoolean",
    options: optionsYesNo,
    helpId: "LAUNCH_NAMING_INVOLVED",
  }
}
