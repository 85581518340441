import React, { FunctionComponent } from "react";
import { Controller, useFieldArray, useWatch } from "react-hook-form";
import {
  Box,
  Button,
  Container,
  FormField,
  Grid,
  Header,
  Input,
  SelectProps,
  SpaceBetween
} from "@amzn/awsui-components-react-v3";
import { Attribute } from "@amzn/api-parity-react-component/lib/components/attribute/Attribute";
import { SelectController } from "../../SelectController/SelectController";
import { LaunchDocumentFormProps } from "./LaunchDocumentForm.types";
import { MappedLaunchDocumentReview } from "../../EditLaunchDocReviews.types";
import { enumToSelectOptions } from "../../../../ProductLaunches/LaunchWizard/LaunchWizardFormFields";
import { LaunchDocumentType } from "../../EditLaunchDocReviews";
import { REQUIRED, VALID_LINK } from "../../../../IndividualFormFieldWithController/FormConstants";
import { LaunchDocumentReviewFormGroup } from "../../LaunchDocumentReviewFormGroup/LaunchDocumentReviewFormGroup";
import { getDataTestIdProp } from "../../../../../utils/testUtil";

export const LaunchDocumentFormTestIdSuffixes = {
  removeButton: "-removeButton",
  label: "-label",
  docLinkForm: "-docLinkForm",
  docLinkInput: "-docLinkInput",
  appendButton: "-appendButton",
  root: "-root",
}

export const LaunchDocumentForm: FunctionComponent<LaunchDocumentFormProps> = (props) => {
  const { remove: removeDocument, control, errors, document, root } = props;

  const { fields, append, remove } = useFieldArray<MappedLaunchDocumentReview>({
    name: `${root}.reviews`,
    control,
  });

  const documentType = useWatch<SelectProps.Option>({
    control,
    name: `${root}.docReviewType`,
  });

  return (
    <Container
      header={
        <Header
          {...getDataTestIdProp(props, LaunchDocumentFormTestIdSuffixes.label)}
          actions={
            <SpaceBetween size="xs" direction="horizontal">
              <Button
                {...getDataTestIdProp(props, LaunchDocumentFormTestIdSuffixes.removeButton)}
                variant="normal"
                onClick={() => removeDocument()}
              >
                Remove document
              </Button>
            </SpaceBetween>
          }
        >
          {`Document: ${documentType?.label ?? "-"}`}
        </Header>
      }
      {...getDataTestIdProp(props, LaunchDocumentFormTestIdSuffixes.root)}
    >

      <Grid
        gridDefinition={[{ colspan: 6 }, { colspan: 6 }]}
      >
        <SelectController
          id={`${root}.docReviewType`}
          control={control}
          label={"Document Type"}
          defaultValue={document.docReviewType}
          options={enumToSelectOptions({ ...LaunchDocumentType })}
          rules={{
            validate: (value: SelectProps.Option) => value.value !== "" || "Required"
          }}
          error={errors?.docReviewType?.message}
        />
        <Controller // link
          name={`${root}.docLink`}
          control={control}
          defaultValue={document.docLink}
          rules={{ ...VALID_LINK, ...REQUIRED }}
          render={(
            { onChange, onBlur, value, ref },
            { invalid }
          ) => (
            <FormField
              label={<Attribute label="Document link"/>}
              errorText={(invalid && errors?.docLink?.message)}
              {...getDataTestIdProp(props, LaunchDocumentFormTestIdSuffixes.docLinkForm)}
            >
              <Input
                onBlur={onBlur}
                onChange={({ detail }) => onChange(detail.value)}
                value={value}
                ref={ref}
                inputMode="url"
                {...getDataTestIdProp(props, LaunchDocumentFormTestIdSuffixes.docLinkInput)}
              />
            </FormField>
          )}
        />
      </Grid>
      <LaunchDocumentReviewFormGroup
        control={control}
        errors={errors?.reviews}
        root={`${root}.reviews`}
        reviews={fields}
        remove={remove}
      />
      <Box padding={{ top: "l" }}>
        <Button
          onClick={() => append({
            level: { label: "-", value: "" },
            date: "",
            status: { label: "-", value: "" },
            notes: "",
          })}
          {...getDataTestIdProp(props, LaunchDocumentFormTestIdSuffixes.appendButton)}
        >
          {
            document.reviews && document.reviews.length > 0
              ? "Add review"
              : "Add review plan"
          }
        </Button>
      </Box>
    </Container>
  )
}
