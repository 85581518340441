import { CATEGORY_FIELD } from "../../constants";

const getContentSelectorOptions = (regions) => {

  const baseOptions = [
    { id: "nameSortable", label: "Service Name", visible: true },
    { id: "gm", label: "General Manager", visible: true },
    { id: "sTeamDirect", label: "S-Team Direct", visible: true },
    { id: CATEGORY_FIELD, label: "Category", visible: true }
  ]

  const regionOptions = regions.map(region => {
    return {
      id: region?.nameSortable,
      label: region?.nameSortable,
      visible: true
    }
  });

  return {
    title: "Select visible columns",
    options: [
      {
        label: "Columns",
        options: [
          ...baseOptions,
          ...regionOptions
        ]
      }
    ]
  }

};

export { getContentSelectorOptions }
