import { CtiVO } from "./CtiVO"
import { immerable } from "immer"
import { hydrateModel } from "../../utils/modelUtil";
import { IServiceContactAudit, IServiceContactsDto } from "../../daos/types/IServiceContactsDto";
import { RegionPartitionId } from "../RegionPartition";

export const MAX_JOB_LEVEL = 12;

export enum ContactsType {
  Rip = "RIP",
  NonRip = "NONRIP",
}

export class ServiceContactsVO {
  [immerable] = true;
  //TODO: Remove artifact as it is no longer sent from the backend or should be consumed by any means
  artifact: string = "";
  serviceName: string = "";
  instance: string = "";
  nameLong?: string;
  nameSortable?: string;
  contactsType: ContactsType = ContactsType.Rip;
  regions: string = "";
  uid: string = "";
  primaryBuildPoc?: string;
  secondaryBuildPoc?: string;
  rbtServiceManager?: string;
  leadershipChain?: LxsVO[];
  gm?: string;
  vp?: string;
  buildTask?: string;
  cti?: CtiVO;
  simGuid?: string;
  notifications?: string;
  azServiceOwner?: string;
  localZoneServiceOwner?: string;
  vpceBuildOwner?: string;
  ripContacts?: string[];
  partition: string = RegionPartitionId.public;
  wikiUrl?: string;
  oncallTeam?: string;
  inRmsGraph?: string;
  audit?: IServiceContactAudit[]
  opsDashboard?: string;

  dto?: IServiceContactsDto; // The dto that the VO is sourced from

  constructor(from?: Partial<ServiceContactsVO>) {
    hydrateModel(from, this);
  }
}

export class LxsVO {
  alias: string = "";
  level: number = 0
}
