import * as React from "react";
import moment from "moment";
import { ColumnLayout } from "@amzn/awsui-components-react";
import { ProjectedDate } from "../common/ProjectedDate";
import { getRmsProjectedFinishDateFromServiceInRegion } from "../../utils/rmsProjectedDateUtil";
import { PlanConfidence } from "../PlanConfidence/PlanConfidence";
import { toShortDate } from "../../utils/dateUtil";
import {
  getDateHeader,
  getDisplayNote,
  getRenderedDate,
  isGaStatus,
  shouldShowBlueprintDate,
  shouldShowDateInformation,
  shouldShowRmsProjectedDate
} from "./ServiceInRegionDetailsUtil";
import {
  BLUEPRINT_COLUMN_HEADER_LABEL,
  differentBlueprintDateTip,
  hasDifferentBlueprintDate,
  IconHolder
} from "../SingleService/SingleServiceNotCompletedTableUtil"
import { Attribute } from "@amzn/api-parity-react-component/lib/components/attribute/Attribute";


export const ServiceInRegionDetailsTestId = {
  columnLayout: "ServiceInRegionDetails-columnLayout"
}

const ServiceInRegionDetails = props => {
  const { serviceMetadataPlan, latestServiceHistory, ripName, regionName, userAbilities } = props;
  const {
    updated,
    updater,
    status,
    date,
    blueprintEstLaunchDate,
    disposition,
    note,
    sim,
  } = latestServiceHistory;

  const isGa = isGaStatus(status);

  const rmsProjectedDate = getRmsProjectedFinishDateFromServiceInRegion(latestServiceHistory);
  const rmsProjectedDateObj = moment(rmsProjectedDate);
  const rmsProjectedDateValue = (rmsProjectedDate && rmsProjectedDateObj.isValid()) ? rmsProjectedDateObj.toDate() : undefined;

  const formattedUpdated = toShortDate(updated);

  let renderEstDeliveryDate = getRenderedDate(isGa, disposition, date, serviceMetadataPlan);
  const deliveryDateHeader = getDateHeader(isGa)
  const displayNote = getDisplayNote(disposition, note ?? "-", sim ?? "-");

  const showRmsProjectedDate = shouldShowRmsProjectedDate(regionName, serviceMetadataPlan, isGa);

  const showBlueprintDate = shouldShowBlueprintDate(blueprintEstLaunchDate, isGa);
  const blueprintComponent = <>
    <span>{blueprintEstLaunchDate}</span>
    {hasDifferentBlueprintDate(latestServiceHistory) && <IconHolder>{differentBlueprintDateTip}</IconHolder>}
  </>

  const shouldShowDate = shouldShowDateInformation(userAbilities, status);

  return (
    <>
      <div className="awsui-util-container">
        <div className="awsui-util-container-header">
          <h2>Latest Details</h2>
        </div>
        <ColumnLayout data-testid={ServiceInRegionDetailsTestId.columnLayout} columns={3} variant="text-grid">
          <div data-awsui-column-layout-root="true">
            <div>
              <div className="awsui-util-spacing-v-s">
                <div>
                  <div className="awsui-util-label">Category</div>
                  <div>{serviceMetadataPlan ?? "-"}</div>
                </div>
                <div>
                  <div className="awsui-util-label">RIP Build Status</div>
                  <div>{status ?? "-"}</div>
                </div>
              </div>
            </div>
            {shouldShowDate &&
            <div>
              <div className="awsui-util-spacing-v-s">
                <div>
                  <div className="awsui-util-label">{deliveryDateHeader}</div>
                  <div>{ renderEstDeliveryDate }</div>
                </div>
                {
                  !isGa &&
                  <div>
                    <div className="awsui-util-label">Confidence</div>
                    <PlanConfidence plan={latestServiceHistory}/>
                  </div>
                }
                {
                  showRmsProjectedDate &&
                  <div>
                    <div className="awsui-util-label">RMS Projected GA Date</div>
                    <ProjectedDate
                      projected={rmsProjectedDateValue}
                      regionName={regionName}
                      ripName={ripName} />
                  </div>
                }
                {
                  showBlueprintDate &&
                  <Attribute label={ BLUEPRINT_COLUMN_HEADER_LABEL } value={blueprintComponent} />
                }
              </div>
            </div>}
            <div>
              <div className="awsui-util-spacing-v-s">
                <div>
                  <div className="awsui-util-label">Last Updated</div>
                  <div>
                    {formattedUpdated ?? "-"}
                  </div>
                </div>
                <div>
                  <div className="awsui-util-label">Last Updated By</div>
                  <div>{updater ?? "-"}</div>
                </div>
              </div>
            </div>
          </div>
        </ColumnLayout>
        {userAbilities.canReadDates && // Users who cannot read dates cannot view notes, even if the service is GA
          !isGa &&
        <div className="awsui-util-container-header-description">
          <div className="awsui-util-label">Explanation for launch date</div>
          <div>{displayNote}</div>
        </div>}
      </div>
    </>
  );
};

export default ServiceInRegionDetails;
