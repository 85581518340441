import { AsyncResult } from "../models/QaRemoteOperation";

export async function remoteOperationRunner<T>( asyncFn: () => Promise<T> ): AsyncResult<T> {
  try {
    const result: T = await asyncFn();
    return {
      success: true,
      result,
      error: undefined,
    };
  } catch (e) {
    // e.message ends with error code
    return {
      success: false,
      result: undefined,
      error: {
        httpStatusCode: 100 // TODO: Parse error code out
      }
    }
  }
}
