import { SERVICE_CATEGORY_NONE } from "../models/ServiceCategory";
import { getPatches, ModifierFunction } from "./patchUtil";
import { getRipName } from "./serviceMetadataUtil"
import { getRegionFromInstanceField } from "./planUtil";
import { IServiceMetaData } from "../daos/types/IServiceMetaData";
import { IPatchModel } from "../daos/types/IPatchModel";
import {
  IGetSingleServicePlanResponse,
  IGetSingleServicePlanResponseLegacy
} from "../daos/types/IGetSingleServicePlanResponse";
import {
  IGetServiceRegionHistoryResponse,
  IGetServiceRegionHistoryResponseLegacy
} from "../daos/types/IGetServiceRegionHistoryResponse";
import {
  IGetSingleRegionPlanResponse,
  IGetSingleRegionPlanResponseLegacy
} from "../daos/types/IGetSingleRegionPlanResponse";

export function getServiceBusinessPlanPatch(service: IServiceMetaData, newPlan: string): IPatchModel[] {
  return getPatches<IServiceMetaData>( service, getModifyServiceBusinessPlanFn(newPlan) );
}


/**
 * Create a modifier function per the new business plan, the modifier function would accept
 * service as input.
 * @param newPlan
 * @returns {function(*): *}
 */
export function getModifyServiceBusinessPlanFn(newPlan: string): ModifierFunction<IServiceMetaData> {
  return (service: IServiceMetaData) => {
    if (newPlan === SERVICE_CATEGORY_NONE.text) {
      delete(service.plan);
    } else {
      service.plan = newPlan;
    }
    return service
  }
}


/**
 * Convert APIv2 data structure to APIv1 structure, that is because APIv1 structure is actively used by the UI components.
 */
export function parseGetSingleServiceData(data: IGetSingleServicePlanResponse): IGetSingleServicePlanResponseLegacy {
  // TODO: Phase out legacy name like serviceInstances, this requires all the downstream components use the new name
  data.service.nameRip = getRipName(data?.service?.instance);

  data.plans.forEach((plan) => {
    plan.region = getRegionFromInstanceField(plan.instance);
  })

  return {
    serviceMetadata: data.service,
    serviceInstances: data.plans,
  }
}


/**
 * Convert APIv2 data structure to APIv1 structure, that is because APIv1 structure is actively used by the UI components.
 */
export function parseGetServiceRegionHistoryData(data: IGetServiceRegionHistoryResponse): IGetServiceRegionHistoryResponseLegacy {
  return {
    serviceMetadata: data.service,
    servicePlanHistory: data.history,
  }

}


/**
 * Convert APIv2 data structure to APIv1 structure, that is because APIv1 structure is actively used by the UI components.
 */
export function parseGetSingleRegionData(data: IGetSingleRegionPlanResponse): IGetSingleRegionPlanResponseLegacy {
  // TODO: Phase out legacy name like serviceInstances, this requires all the downstream components use the new name

  data.plans = data.plans.map((plan) => {
    plan.nameRip = getRipName(plan.instance);
    return plan;
  });


  return {
    regionMetadata: data.region,
    serviceInstances: data.plans,
  }
}
