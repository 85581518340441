import * as React from "react";
import { FunctionComponent } from "react";
import { Button, Container, Grid, Header, Icon, SpaceBetween } from "@amzn/awsui-components-react-v3";
import { Attribute } from "@amzn/api-parity-react-component/lib/components/attribute/Attribute";
import { LaunchRegions } from "../../../LaunchDetails/Tabs/LaunchRegions/LaunchRegions";
import { IFormFieldData } from "../../../common/FormTypes";
import { getDataTestIdProp } from "../../../../utils/testUtil";
import { ILaunchWizardReviewInput } from "../LaunchWizard.types";
import { createLaunchPropertiesFormFields } from "../LaunchWizardFormFields";
import { Page, RedText, requiredFieldsForSubmission, WarningText } from "../LaunchWizard";

export const ReviewPageTestID = {
  "LaunchEdit" : "-launchEdit",
  "RegionEdit": "-regionEdit",
}

export const Review: FunctionComponent<ILaunchWizardReviewInput> = (props) => {
  const { getValues } = props.control
  const formFields = createLaunchPropertiesFormFields
  const selectedRegions = Object.keys(props.control.getValues()).filter((entry) =>
    entry.startsWith("region:") && props.control.getValues(entry) === true).map
      ((selectedRegion) => selectedRegion.split(":")[2])

  function getFormFieldValue(formField: IFormFieldData) {
    if (getValues(formField.id)) {
      return getValues(formField.id).label ?? getValues(formField.id)
    }
    else if (requiredFieldsForSubmission.includes(formField)) {
      return <WarningText>Required for submission</WarningText>
    }
    else {
      return <RedText>Not Provided</RedText>
    }
  }

  function getFormFieldName(formField: IFormFieldData) {
    return formField.nameOverride ?? formField.placeholder
  }

  return (
    <SpaceBetween size="xxl">
      <Container header={
        <Header
          variant="h2"
          actions={
            <Button
              {...getDataTestIdProp(props, ReviewPageTestID.LaunchEdit)}
              variant="normal"
              onClick={() => props.setPage(Page.LaunchDetails)}
            >
              Edit
            </Button>}>
          Launch details
        </Header>}>
        <Grid
          gridDefinition={[{ colspan: 6 }, { colspan: 6 }, { colspan: 6 }, { colspan: 6 }, { colspan: 6 }]}
        >
          <div>
            <Header variant="h3">Launch overview</Header>
            <SpaceBetween size="s">
              <Attribute label="Service" value={props.serviceName}/>
              <Attribute label={getFormFieldName(formFields.launchIteration)} value={getFormFieldValue(formFields.launchIteration)}/>
              <Attribute label={getFormFieldName(formFields.launchType)} value={getFormFieldValue(formFields.launchType)}/>
              <Attribute label={getFormFieldName(formFields.launchName)} value={getFormFieldValue(formFields.launchName)}/>
              <Attribute label={getFormFieldName(formFields.launchDescription)} value={getFormFieldValue(formFields.launchDescription)}/>
              <Attribute label={getFormFieldName(formFields.codeName)} value={getFormFieldValue(formFields.codeName)}/>
              <Attribute label={getFormFieldName(formFields.ripName)} value={getFormFieldValue(formFields.ripName)}/>
            </SpaceBetween>
          </div>

          <div>
            <Header variant="h3">Launch date</Header>
            <SpaceBetween size="s">
              <Attribute label="Launch Date" value={getFormFieldValue(formFields.expectedLaunchDate)}/>
            </SpaceBetween>
          </div>

          <div>
            <Header variant="h3">Launch properties</Header>
            <SpaceBetween size="s">
              <Attribute label={getFormFieldName(formFields.launchScope)} value={getFormFieldValue(formFields.launchScope)}/>
              <Attribute label={getFormFieldName(formFields.tier)} value={getFormFieldValue(formFields.tier)}/>
              <Attribute label={getFormFieldName(formFields.disclosureLevel)} value={getFormFieldValue(formFields.disclosureLevel)}/>
              <Attribute label={getFormFieldName(formFields.confidential)} value={getFormFieldValue(formFields.confidential)}/>
              <Attribute label={getFormFieldName(formFields.goalType)} value={getFormFieldValue(formFields.goalType)}/>
              <Attribute label={getFormFieldName(formFields.goalLink)} value={getFormFieldValue(formFields.goalLink)}/>
              <Attribute label="External launch?" value={getFormFieldValue(formFields.audience) === "External" ? "Yes" : "No"}/>
            </SpaceBetween>
          </div>

          <div>
            <Header variant="h3">Launch contacts</Header>
            <SpaceBetween size="s">
              <Attribute label={getFormFieldName(formFields.productManager)} value={getFormFieldValue(formFields.productManager)}/>
              <Attribute label={getFormFieldName(formFields.productMarketingManager)} value={getFormFieldValue(formFields.productMarketingManager)}/>
              <Attribute label={getFormFieldName(formFields.engineeringLead)} value={getFormFieldValue(formFields.engineeringLead)}/>
            </SpaceBetween>
          </div>

          <div>
            <Header variant="h3">Launch survey</Header>
            <SpaceBetween size="s">
              <Attribute label={getFormFieldName(formFields.namingBoolean)} value={getFormFieldValue(formFields.namingBoolean)}/>
              <Attribute label={getFormFieldName(formFields.pricingBoolean)} value={getFormFieldValue(formFields.pricingBoolean)}/>
              <Attribute label={getFormFieldName(formFields.regulatoryBoolean)} value={getFormFieldValue(formFields.regulatoryBoolean)}/>
              <Attribute label={getFormFieldName(formFields.prfaqBoolean)} value={getFormFieldValue(formFields.prfaqBoolean)}/>
            </SpaceBetween>
          </div>

        </Grid>
      </Container>

      <LaunchRegions
        regions={selectedRegions}
        headerActionsOverride={
          <Button
            {...getDataTestIdProp(props, ReviewPageTestID.RegionEdit)}
            variant="normal"
            onClick={() => props.setPage(Page.RegionPicker)}
          >
            Edit
          </Button>}
        regionSource={props.regions}
        />
    </SpaceBetween>
  );
}
