import { getPatches, ModifierFunction } from "../utils/patchUtil";
import { getRipName } from "../utils/serviceMetadataUtil";
import { normalizeServiceName, getReconApiClient } from "./daoUtil";
import { None } from "../models/BusinessCaseOverrideOptions";
import { parseGetSingleServiceData } from "../utils/apiUtil";
import { RmsDao } from "./RmsDao";
import { IGetSingleServicePlanResponseLegacy } from "./types/IGetSingleServicePlanResponse";
import { IGetServiceComponentsResponse, IServiceComponentDto } from "./types/IGetServiceComponentsResponse";
import { IServiceInRegion } from "./types/IGetFeatureDto";
import { IServiceInRegionHistory } from "./types/IServiceInRegionHistory";
import { IUpdateServicePlanEstimatedDate } from "../models/types/IUpdateServicePlanEstimatedDate";
import { IGenericMutationResponseDto } from "./types/IGenericMutationResponseDto";
import { IServicePlanBusinessCaseOverride } from "../models/types/IServicePlanBusinessCaseOverride";
import { RECON_COMPONENT_SEPARATOR } from "../models/vos/ComponentFeatureVO"
import { IPatchModel } from "./types/IPatchModel";
import { Optional } from "../models/types/Optional";
import { GENERALLY_AVAILABLE } from "../constants";


const planTypes = {
    plan: "PLAN",
    exception: "EXCEPTION"
}

export class PlanDao {

  /**
   * Get a service's metadata and its region plans
   */
  async getSingleServicePlans(serviceName: string): Promise<IGetSingleServicePlanResponseLegacy> {
    const client = getReconApiClient();
    const res = await client.get(this.getSingleServicePlansUrl(serviceName));
    return parseGetSingleServiceData(res.data);
  }

  async getSingleServiceComponents(serviceName: string): Promise<IGetServiceComponentsResponse> {
    const client = getReconApiClient();
    const url = `/api/services/${encodeURIComponent(serviceName)}/taggedfeatures`
    const res = await client.get<IGetServiceComponentsResponse>(url);
    return res.data;
  }

  async getAllTaggedFeatureComponents(): Promise<IGetServiceComponentsResponse> {
    const client = getReconApiClient();
    const res = await client.get<IGetServiceComponentsResponse>("/api/taggedfeatures");
    return res.data;
  }

  getEstimatedLaunchDateFromComponents(componentFeatures: IServiceComponentDto[] = [], componentServiceName: string, region: string): Optional<string> {
    const matchingComponent = componentFeatures.filter(component => (component.ripComponentServiceName ?? component.ripTopLevelServiceName) === componentServiceName);
    return matchingComponent[0]?.["regions"]?.[region]?.estimatedLaunchDate;
  }

  // TODO - add type notation here
  createFeatureComponentDTO(featureInstancesData: IServiceInRegion[][], componentFeatures: IServiceComponentDto[] = []) {
    const extractedData = {}

    featureInstancesData.flat().forEach((instance: IServiceInRegion) => {
      const componentServiceName = normalizeServiceName(instance.dimensionMetadata.key.name);
      const componentServiceLongname = instance.dimensionMetadata.longName
      const region = instance.parentDimensionKey.name;
      const status = instance.status;

      if (!extractedData[componentServiceName]) {
        extractedData[componentServiceName] = {
          "regions": {},
          "longName": componentServiceLongname
        }
      }

      // For some reason longName is not a reliable attribute to look at when itterating over instances - due to this we must add when we find
      if (!extractedData[componentServiceName].longName && componentServiceLongname !== undefined) {
        extractedData[componentServiceName].longName = componentServiceLongname
      }

      extractedData[componentServiceName]["regions"][region] = { "status": status }

      if (status !== GENERALLY_AVAILABLE) { // dont need estimated launch date if already launched
        const estimatedLaunchDate = this.getEstimatedLaunchDateFromComponents(componentFeatures, componentServiceName, region);
        if (estimatedLaunchDate !== undefined) { // dont add to structure if nothing to add
          extractedData[componentServiceName]["regions"][region]["estimatedLaunchDate"] = estimatedLaunchDate
        }
      }
    });

    // in case we didnt get any data back from rip - make sure we still return the required components
    componentFeatures.forEach(component => {
      const ripName = component.ripComponentServiceName ?? component.ripTopLevelServiceName;

      if (!Object.keys(extractedData).includes(ripName)) {
        extractedData[ripName] = {
          regions: []
        }
      }
    });

    return extractedData;
  }

  // TODO - add type notation here
  createFeatureTableDataObject(extractedData = {}) {
    const extractedDataFormatted = []
    const serviceNames = Object.keys(extractedData);

    serviceNames.forEach((service) => {
      const currentService = extractedData[service];
      const longName = currentService["longName"];

      const dataFormatted = {
        [service]: {
          "id": service,
          "longName": longName
        }
      };

      const regionsList = Object.keys(currentService["regions"]);
      regionsList.forEach(region => {
        const inRegionData = currentService["regions"][region]

        dataFormatted[service][region] = {
          "status": inRegionData?.status,
          "estimatedLaunchDate": inRegionData?.estimatedLaunchDate
        }
      });

      // @ts-ignore
      extractedDataFormatted.push(dataFormatted[service])
    });

    return extractedDataFormatted;
  }

  combineComponentServiceData(componentFeatures: IGetServiceComponentsResponse, instanceData: IGetSingleServicePlanResponseLegacy[]) {
    componentFeatures = componentFeatures.map(component => {
      const ripName = component.ripComponentServiceName ?? component.ripTopLevelServiceName;

      const foundData = instanceData.filter(instance =>
        normalizeServiceName(getRipName(instance.serviceMetadata.instance)) === ripName)

      if (foundData.length > 0) {
        const instances = foundData[0].serviceInstances
        component["regions"] = {}
        instances.forEach((plan) => {
          if (plan.belongsToInstance && plan.date) {
            component["regions"][plan.belongsToInstance] = {}
            component["regions"][plan.belongsToInstance]["estimatedLaunchDate"] = plan.date
          }
        })
      }
      return component
    })

    return componentFeatures
  }

  // This allows promise.all to accept errors when calling api and allow to continue https://stackoverflow.com/questions/43503872/promise-all-alternative-that-allows-failure
  allSkippingErrors<T>(promises: Promise<T>[]): Promise<T[]> {
    // @ts-ignore
    return Promise.all<T>(
      // @ts-ignore
      promises.map(p => p.catch(error => undefined))
    )
  }

  async getSingleServiceComponentsWithInstanceData(serviceName : string) {
    // Part 1 - Get all components tagged as feature from Recon
    const componentFeatures = await this.getSingleServiceComponents(serviceName) ?? [];

    // Part 2 - Using data from Part1 call Recon to get all estimated launch date info
    const reconPromiseArray: Promise<IGetSingleServicePlanResponseLegacy>[] = componentFeatures.map((component) => {
      const serviceId: string = component.ripComponentServiceName ?? component.ripTopLevelServiceName;
      return this.getSingleServicePlans(serviceId);
    });

    const componentFeaturesCombined = await this.allSkippingErrors(reconPromiseArray).then(data => {
      const filteredData = data.filter((x) => (x !== undefined) ); // this removes the empty return objects from errored api calls
      return this.combineComponentServiceData(componentFeatures, filteredData);
    });


    // TODO - addilks - since recon/rip are always in sync we no longer need part3
    // Part 3 - using data from part1 call RIP to get all status info
    const rmsDao = new RmsDao();
    const promiseArray = componentFeaturesCombined.map((component) => {
      const serviceId: string = component.ripComponentServiceName ?? component.ripTopLevelServiceName;
      return rmsDao.getStatusesForService(serviceId);
    });


    // Part 4 - wait for all apis to finish and create intermediary data layer
    const extractedData = await Promise.all(promiseArray).then((featureInstancesData: IServiceInRegion[][]) => {
      return this.createFeatureComponentDTO(featureInstancesData, componentFeaturesCombined);
    });

    // Part 5 - take that data structure and make it polaris capible
    const featureTableObject = this.createFeatureTableDataObject(extractedData);

    // Part 6 - add type info
    featureTableObject.map(item => {
      // @ts-ignore
      item.type = item.id.includes(RECON_COMPONENT_SEPARATOR) ? "component" : "service";
      return item
    })

    return featureTableObject;
  }

  getSingleServicePlansUrl(serviceName: string): string {
    return `/api/services/${encodeURIComponent(serviceName)}/plans`
  }

  // region update estimated delivery date

  /**
   * Update estimated date of a plan
   */
  async updateEstimatedDate(plan: IServiceInRegionHistory, estimatedDateUpdate: IUpdateServicePlanEstimatedDate): Promise<IGenericMutationResponseDto> {
    const patch = this.getUpdateEstimatedDatePatch(plan, estimatedDateUpdate);
    const client = getReconApiClient();
    const res = await client.patch<IGenericMutationResponseDto>(this.updateEstimatedDateUrl(plan), patch);
    return res.data;
  }

  getUpdateEstimatedDatePatch(plan: IServiceInRegionHistory, estimatedDateUpdate: IUpdateServicePlanEstimatedDate): IPatchModel[] {
    return getPatches(plan, this.getUpdateEstimatedDatePatchFn(estimatedDateUpdate));
  }

  /**
   * Return a function that takes a service in region plan as input, and applies the changes described in the estimated date update
   * @param estimatedDateUpdate estimated date change description
   */
  getUpdateEstimatedDatePatchFn(estimatedDateUpdate: IUpdateServicePlanEstimatedDate): ModifierFunction<IServiceInRegionHistory> {
    return (plan: IServiceInRegionHistory): void => {
      plan.note = estimatedDateUpdate.note;
      if (estimatedDateUpdate.removeConfidenceAndDate) {
        delete(plan.confidence);
        delete(plan.date);
        delete(plan.planType);
      } else {
        plan.date = estimatedDateUpdate.date;
        plan.confidence = estimatedDateUpdate.confidence;
        // TODO: updating planType should be done on the server-side
        // @ts-ignore
        plan.planType = planTypes.plan
      }
    }
  }

  /**
   * Update estimated date of a plan
   */
  updateEstimatedDateUrl(plan: IServiceInRegionHistory): string {
    let ripName = plan.instance;
    if (ripName.includes("/")) {
      ripName = normalizeServiceName(ripName)
    }
    const region = plan.region;
    const serviceName = getRipName(ripName);
    return `/api/services/${encodeURIComponent(serviceName)}/plans/${encodeURIComponent(region)}`;
  }


  /**
   * Update estimated date of a plan
   */
  async updateBusinessCaseOverride(plan, overrideUpdate): Promise<IGenericMutationResponseDto> {
    const patch = this.getBusinessCaseOverridePatch(plan, overrideUpdate);
    const client = getReconApiClient();
    const res = await client.patch<IGenericMutationResponseDto>(this.updateBusinessCaseOverrideUrl(plan), patch);
    return res.data;
  }

  getBusinessCaseOverridePatch(plan: IServiceInRegionHistory, overrideUpdate: IServicePlanBusinessCaseOverride) {
    return getPatches(plan, this.getBusinessCaseOverridePatchFn(overrideUpdate));
  }

  /**
   * Return a function that takes a service in region plan as input, and applies the changes described in the business case override update
   * @param overrideUpdate business case override change description
   */
  getBusinessCaseOverridePatchFn(overrideUpdate: IServicePlanBusinessCaseOverride): ModifierFunction<IServiceInRegionHistory> {
    return (plan: IServiceInRegionHistory) => {
      plan.note = overrideUpdate.note;
      plan.disposition = overrideUpdate.disposition;
      // @ts-ignore
      overrideUpdate.disposition === "None" ? delete(plan.planType) : plan.planType = planTypes.exception;
      plan.sim = overrideUpdate.sim;
      if (overrideUpdate.disposition !== "None") {
        delete(plan.confidence);
        delete(plan.date);
      } 
    }
  }

  /**
   * Update estimated date of a plan
   */
  updateBusinessCaseOverrideUrl(plan: IServiceInRegionHistory): string {
    const region = plan.region;
    const serviceName = getRipName(plan.instance);
    return `/api/services/${encodeURIComponent(serviceName)}/plans/${encodeURIComponent(region)}`;
  }

  /**
   * Normalize disposition value for API v1 call
   * @param {'None' | 'Not Launching' | 'ATO' | undefined} disposition
   * @return {string}
   * **/
  normalizeDispositionValue(disposition: string): string {
    let result = disposition;
    if (!result || result === None.value) {
      return "None";
    }
    return result;
  }

  // endregion

}
