import * as React from "react";
import * as awsui from "@amzn/awsui-design-tokens/polaris";
import styled from "styled-components";
import { upperFirst } from "lodash";
import { PlanConfidenceProps } from "./PlanConfidence.types";
import { displayOr } from "../../utils/displayUtil";
import { Optional } from "../../models/types/Optional";

export const PlanConfidenceTestId = {
  component: "PlanConfidence",
};

interface IConfidenceStyleProp {
  color: string;
}

const ConfidenceStyle = styled.div<IConfidenceStyleProp>`
  color: ${ (props) => props.color };
`;

export const confidenceColorMap: ReadonlyMap<string, string> = new Map([
  ["RED", awsui.colorTextStatusError],
  ["YELLOW", awsui.colorTextAccent],
  ["GREEN", awsui.colorTextStatusSuccess],
  ["", awsui.colorTextBodyDefault],
]);

export const defaultConfidenceColor: string = awsui.colorTextBodyDefault;

export const PlanConfidence: React.FunctionComponent<PlanConfidenceProps> = (props) => {
  const confidence: Optional<string> = props.plan?.confidence;
  const color = confidenceColorMap.get(confidence?.toUpperCase() ?? "") ?? defaultConfidenceColor;
  const confidenceLabel = displayOr(confidence, () => upperFirst(confidence?.toLowerCase()));
  return <ConfidenceStyle
    data-testid={PlanConfidenceTestId.component}
    color={color}>{confidenceLabel}</ConfidenceStyle>
};
