export enum QaItemResultType {
  Success = "Success",
  Fail = "Fail",
}

export interface IQaItemResult {
  type: QaItemResultType;
  error?: string;
}

export const successResult: IQaItemResult = {
  type: QaItemResultType.Success
};

export function getErrorResult(error: string): IQaItemResult {
  return {
    type: QaItemResultType.Fail,
    error
  };
}
