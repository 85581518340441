import * as React from "react";
import { useState, useEffect, useCallback } from "react";
import {
  Select, Textarea, Input,
  Form, FormField, ColumnLayout,
  Modal, Button, Flash
} from "@amzn/awsui-components-react";
import ModalHeader from "../common/StyledComponents/ModalHeader";
import HorizontalBar from "../common/StyledComponents/HorizontalBar";
import { HTTP_STATUS, RECON_URL_CREATE_ISSUE } from "../../constants";
import useSWR from "swr";
import { getSwrConfig } from "../../daos/swrUtil";
import { BusinessCaseOverrideModalProps } from "./BusinessCaseOverrideModal.types";
import { IServiceInRegionHistory } from "../../daos/types/IServiceInRegionHistory";
import { IServicePlanBusinessCaseOverride } from "../../models/types/IServicePlanBusinessCaseOverride";
import { Optional } from "../../models/types/Optional";
import { IBusinessCaseOverrideOption } from "../../models/BusinessCaseOverrideOptions";
import { Link } from "@amzn/awsui-components-react-v3";

export const BusinessCaseOverrideTestId = {
  dispositionInput: "BusinessCaseOverride-dispositionInput",
  dispositionFormField: "BusinessCaseOverride-dispositionFormField",
  simInput: "BusinessCaseOverride-simInput",
  simFormField: "BusinessCaseOverride-simFormField",
  noteInput: "BusinessCaseOverride-noteInput",
  noteFormField: "BusinessCaseOverride-noteFormField",

  submit: "BusinessCaseOverride-submit",
  cancel: "BusinessCaseOverride-cancel",

  unknownError: "BusinessCaseOverride-unknownError",
  noPermissionError: "BusinessCaseOverride-noPermission",
  modal: "BusinessCaseOverride-modal"
};

export const BusinessCaseOverrideModal: React.FunctionComponent<BusinessCaseOverrideModalProps> = (props) => {
  const {
    selected,
    region,
    nameSortable,
    nameRip,
    visible,
    handleDismiss,
    overrideOptions,
    currentOverrideOption,
    currentSimLink,
    currentNote,
  } = props;

  const plan = selected;
  const [error403, setError403] = useState<boolean>(false);
  const [unknownError, setUnknownError] = useState<boolean>(false);
  const [selectedOption, setSelectedOption] = useState<Optional<Readonly<IBusinessCaseOverrideOption>>>(currentOverrideOption);
  const [simLinkValue, setSimLinkValue] = useState<string>(currentSimLink);
  const [textAreaValue, setTextAreaValue] = useState<string>(currentNote);
  const [selectValidation, setSelectValidation] = useState<Optional<string>>(currentOverrideOption ? "" : undefined);
  const [simLinkValidation, setSimLinkValidation] = useState<Optional<string>>(currentSimLink ? "" : undefined);
  const [textValidation, setTextValidation] = useState<Optional<string>>(currentNote ? "" : undefined);
  const [formValidation, setFormValidation] = useState<Optional<string>>(undefined);
  const [submitDisabled, setSubmitDisabled] = useState<boolean>(true);

  const [lastSubmitTime, lastSubmitTimeSet] = useState<number>(0);
  const [lastSubmitData, lastSubmitDataSet] = useState<Optional<IServicePlanBusinessCaseOverride>>(undefined);

  // TODO: useSWR is unnecessary
  const { data, isValidating: loading, error } = useSWR(
    (lastSubmitTime && lastSubmitData) ? lastSubmitTime.toString() : null,
    () => props.onSubmit?.(plan as IServiceInRegionHistory, lastSubmitData as IServicePlanBusinessCaseOverride),
    getSwrConfig()
  );

  useEffect(() => {
    setSubmitDisabled(
      !(
        textValidation === "" &&
        selectValidation === "" &&
        simLinkValidation === ""
      )
    );
  }, [simLinkValidation, selectValidation, textValidation]);

  const handleSelectionChange = ({ detail: { selectedOption } }) => {
    const invalidMessage = "An override Type must be selected.";
    if (selectedOption) {
      setSelectedOption(selectedOption);
      setFormValidation("");
      setSelectValidation("");
    } else {
      setSelectValidation(invalidMessage);
    }
  };

  const handleSimInputChange = ({ detail: { value } }) => {
    const invalidMessage = "A SIM link is required.";
    if (value !== "") {
      setSimLinkValue(value);
      setFormValidation("");
      setSimLinkValidation("");
    } else {
      setSimLinkValue(value);
      setSimLinkValidation(invalidMessage);
    }
  };

  const handleTextAreaChange = ({ detail: { value } }) => {
    const invalidMessage = "A note is required.";
    if (value !== "") {
      setTextAreaValue(value);
      setFormValidation("");
      setTextValidation("");
    } else {
      setTextAreaValue(value);
      setTextValidation(invalidMessage);
    }
  };

  function submit(updateDescriptor) {
    lastSubmitDataSet(updateDescriptor);
    lastSubmitTimeSet(Date.now());
  }

  const handleFormSubmit = async () => {
    const submission: IServicePlanBusinessCaseOverride = {
      // @ts-ignore
      disposition: selectedOption?.value,
      sim: simLinkValue || "",
      note: textAreaValue || "",
    }

    submit(submission);
  };

  useEffect(() => {
    if (data) {
      setError403(false);
      setUnknownError(false);
      handleDismiss?.({ detail: { reason: "submit" } });
    } else if (error) {
      const statusCode = parseInt( error.response?.status );
      if (statusCode === HTTP_STATUS.NO_PERMISSION) {
        setError403(true);
        setUnknownError(false);
      } else {
        setError403(false);
        setUnknownError(true);
      }
    }
  }, [
    data,
    error,
    handleDismiss,
  ]);

  const handleCancel = useCallback(() => {
    handleDismiss?.({ detail: { reason: "cancel" } });
  }, [handleDismiss]);

  const modalHeader = (
    <ModalHeader>
      <h3>Business Case Override</h3>
      <h5 className="inline-header-subtext">{`${nameSortable ?? nameRip} in ${region}`}</h5>
    </ModalHeader>
  );

  const formActions = (
    <div>
      <Button
        data-testid={BusinessCaseOverrideTestId.cancel}
        variant="link"
        onClick={handleCancel}
      >
        Cancel
      </Button>
      <Button
        data-testid={BusinessCaseOverrideTestId.submit}
        variant="primary"
        loading={loading}
        disabled={submitDisabled}
        onClick={handleFormSubmit}
      >
        Submit
      </Button>
    </div>
  );

  const noPermissionErrorFlash = (
    <Flash
      data-testid={BusinessCaseOverrideTestId.noPermissionError}
      type="error"
      visible={error403}>
      We're sorry but the change you attempted to make requires
      membership in a specific POSIX group granted only
      to administrators.
    </Flash>
  );

  const unknownErrorFlash = (
    <Flash
      data-testid={BusinessCaseOverrideTestId.unknownError}
      type="error"
      visible={unknownError}>
      An unknown error has occurred, if this continues to happen,
      please{" "}
      <Link
        href={RECON_URL_CREATE_ISSUE}
        color="inverted"
        external={true}>
        file a ticket with us
      </Link>
    </Flash>
  );

  const dispositionFormField = (
    <FormField
      data-testid={BusinessCaseOverrideTestId.dispositionFormField}
      stretch={true}
      label={ "Override:" }
      description="Please select a business case override type."
      errorText={selectValidation}
    >
      <Select
        data-testid={BusinessCaseOverrideTestId.dispositionInput}
        // @ts-ignore
        options={overrideOptions}
        selectedOption={selectedOption}
        selectedLabel="Selected"
        placeholder={"Select an override type..."}
        label={"Select an override type"}
        onChange={handleSelectionChange}
      />
    </FormField>
  );

  const simFormField = (
    <FormField
      data-testid={BusinessCaseOverrideTestId.simFormField}
      stretch={true}
      label="SIM Link:"
      description="Please provide a SIM ticket URL."
      errorText={simLinkValidation}
    >
      <Input
        data-testid={BusinessCaseOverrideTestId.simInput}
        value={simLinkValue}
        placeholder="https://issues.amazon.com/issues/..."
        onChange={handleSimInputChange}
      />
    </FormField>
  );

  const noteFormField = (
    <FormField
      data-testid={BusinessCaseOverrideTestId.noteFormField}
      stretch={true}
      label="Note:"
      errorText={textValidation}
    >
      <Textarea
        data-testid={BusinessCaseOverrideTestId.noteInput}
        value={textAreaValue}
        placeholder="Why you are making a business case override?"
        rows={6}
        onChange={handleTextAreaChange}
        onInput={handleTextAreaChange}
      />
    </FormField>
  );

  const modalContents = (
    <Form errorText={formValidation} actions={formActions}>
      { (error403) && noPermissionErrorFlash }
      { (unknownError) && unknownErrorFlash }

      <div className="awsui-grid awsui-util-m-l">
        <div className="awsui-row">
          <ColumnLayout>
            <div data-awsui-column-layout-root="true">
              { dispositionFormField }
              { simFormField }
              { noteFormField }
            </div>
          </ColumnLayout>
        </div>
      </div>
      <HorizontalBar />
    </Form>
  );

  return (
    <Modal
      data-testid={BusinessCaseOverrideTestId.modal}
      visible={visible}
      size="large"
      header={modalHeader}
      onDismiss={handleDismiss}
    >
      { modalContents }
    </Modal>
  );
};

export default BusinessCaseOverrideModal;
