import { SideNavigationProps } from "@amzn/awsui-components-react-v3";
import {
  EMAIL_US_URL, MAILING_LIST_URL,
  REPORT_A_BUG_URL,
  REQUEST_A_FEATURE_URL,
} from "../../../constants";

export const contactSection: SideNavigationProps.Section = {
  type: "section",
  text: "Contact Us",
  defaultExpanded: false,
  items: [
    {
      type: "link",
      text: "Email",
      href: EMAIL_US_URL,
      external: true,
    },
    {
      type: "link",
      text: "Request a Feature",
      href: REQUEST_A_FEATURE_URL,
      external: true,
    },
    {
      type: "link",
      text: "Report a Bug",
      href: REPORT_A_BUG_URL,
      external: true,
    },
    {
      type: "link",
      text: "Mailing List",
      href: MAILING_LIST_URL,
      external: true,
    }
  ]
};
