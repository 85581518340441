import * as React from "react";
import { FunctionComponent, useCallback } from "react";
import { Controller, useForm } from "react-hook-form"
import {
  IDeleteFormInput,
  IDeleteServiceContactModalEvent,
  IDeleteServiceContactModalInput,
  IDeleteServiceContactModalProps
} from "./DeleteServiceContactModal.types";
import {
  Button,
  Container,
  Flashbar,
  FormField,
  Header,
  Input,
  Modal,
  SpaceBetween,
  Spinner,
} from "@amzn/awsui-components-react-v3";
import { getDeleteInputErrorIfInvalid, noDeleteInputError } from "./DeleteServiceContactModalUtils";
import { contactsWriteAccessSimTemplateLink } from "../../../common/SimLinks/ContactsWriteAccessSimTemplateLink";
import { PermissionError } from "../../../common/PermissionError";
import styled from "styled-components";
import { EnterServiceContactsFormType } from "../AddOrEditServiceContactModal/AddOrEditServiceContactModalConstants";
import { DialogFooterButtonBar } from "../../../common/Dialog/DialogFooterButtonBar";

export const DeleteServiceContactTestId = {
  openModalButton: "DeleteServiceContactTestId-openModalButton",
  modal: "DeleteServiceContactId-modal",
  submitButton: "DeleteServiceContactTestId-submitButton",
  formField: "DeleteServiceContactTestId-formField",
  input: "DeleteServiceContactTestId-input"
}

export const DeleteServiceContactPermissionErrorTestId = {
  alert: "DeleteServiceContactPermissionError-alert",
  modal: "DeleteServiceContactPermissionError-modal"
}

const StyledSpinner = styled(Spinner)`
    margin-left: 50%
  `;

export const DeleteServiceContactModal: FunctionComponent<IDeleteServiceContactModalProps> = (props) =>{
  const [visible, setVisible] = React.useState(false);
  const [showPermissionError, setShowPermissionError] = React.useState(false);
  const [flashMessage, setFlashMessage] = React.useState<any>([]);
  const [modalLoading, setModalLoading] = React.useState(false);

  let deleteInputPhrase = `delete ${props.selected?.serviceName}`;

  const { control, errors, trigger } = useForm<IDeleteFormInput>({ mode: "onSubmit", shouldUnregister: true })

  const open_onClick = useCallback((): void => {
    ifNoPermsShowError(props, setShowPermissionError, setVisible);
  }, [props.userAbilities]);

  const deleteModal_onDismiss = useCallback((): void => {
    setVisible(false);
  }, []);


  const permissionError_onDismiss = useCallback((): void => {
    setShowPermissionError(false);
  }, []);

  const ifNoPermsShowError = (props: IDeleteServiceContactModalInput & IDeleteServiceContactModalEvent & { children?: React.ReactNode },
                              setShowPermissionError: (value: (((prevState: boolean) => boolean) | boolean)) => void,
                              setVisible: (value: (((prevState: boolean) => boolean) | boolean)) => void): void => {
    if (!props.userAbilities.canWriteContacts) {
      setShowPermissionError(true);
    }
    else{
      setVisible(true);
    }
  }

  const deleteModal_onSubmit = useCallback(async (): Promise<void> =>{
      if (!props.selected) {
        return;
      }

      if(await trigger()){
        setModalLoading(true);
        try {
          await props.onDeleteSubmit?.(props?.selected);
          props.onSuccessfulSubmit?.([props?.selected], [], EnterServiceContactsFormType.Delete);
          setVisible(false);
        }
        catch (exception) {
          setFlashMessage([{
            header: `Failed to delete ${props?.selected.serviceName}. Please try again later.`,
            type: "error",
            dismissible: true,
            onDismiss: () => setFlashMessage([])
          }])
        }
        setModalLoading(false);
      }
  }, [props.onSuccessfulSubmit, props.onDeleteSubmit, props.selected, trigger])

  return(
    <React.Fragment>
      <Modal
        data-testid={DeleteServiceContactTestId.modal}
        onDismiss={deleteModal_onDismiss}
        visible={visible}
        closeAriaLabel="Close modal"
        size="medium"
        header="Delete Service Contact"
        footer={
          <DialogFooterButtonBar>
            <Button variant="link" onClick={deleteModal_onDismiss}>Cancel</Button>
            <Button 
              variant="primary" 
              onClick={deleteModal_onSubmit}
              data-testid={DeleteServiceContactTestId.submitButton}
            >
              Delete
            </Button>
          </DialogFooterButtonBar>}
      >
        {modalLoading ? <StyledSpinner size={"big"}/> :
          <SpaceBetween direction="vertical" size="l">
            <Flashbar items={flashMessage}/>
            <Container
              header={
                <React.Fragment>
                  <Header variant="h2">
                    To delete contacts for {props.selected?.serviceName}, please enter
                  </Header>
                  <h3>
                    <i>
                      "delete {props.selected?.serviceName}"
                    </i>
                  </h3>
                  <Header>
                    in the text box:
                  </Header>
                </React.Fragment>
              }
            >
              <Controller
                name="deleteInput"
                id="deleteInput"
                control={control}
                defaultValue=""
                rules={{
                  required: noDeleteInputError,
                  validate: (value) => {
                    return getDeleteInputErrorIfInvalid(value, deleteInputPhrase)
                  }
                }}
                render={({ onChange, value }, { invalid }) =>
                  <FormField
                    errorText={(invalid && errors.deleteInput?.message)}
                    data-testid={DeleteServiceContactTestId.formField}
                  >
                    <Input
                      // @ts-ignore
                      onChange={(e) => onChange(e.detail.value)}
                      value={value}
                      invalid={invalid}
                      placeholder={deleteInputPhrase}
                      data-testid={DeleteServiceContactTestId.input}
                    />
                  </FormField>
                }
              />
            </Container>
          </SpaceBetween>
        }
      </Modal>
      <PermissionError
        header="Access Denied"
        body={<>
          Need Service Contacts Write Access to add or update service contacts,
          please use {contactsWriteAccessSimTemplateLink()} to request access.
        </>}
        visible={showPermissionError}
        onDismiss={permissionError_onDismiss}
        testIds={DeleteServiceContactPermissionErrorTestId}
      />
      <div title="Remove Selected Contact">
        <Button
          onClick={open_onClick}
          disabled={!props.selected}
          data-testid={DeleteServiceContactTestId.openModalButton}
          iconName="status-negative"
        />
      </div>

    </React.Fragment>
  )
}
