import React, { createRef, FunctionComponent, useEffect, useRef, useState } from "react";
import { Box, BoxProps, HelpPanel as PolarisHelpPanel } from "@amzn/awsui-components-react-v3";
import ReactMarkdown from "react-markdown";
import styled from "styled-components";
import remarkRehype from "remark-rehype";
import rehypeExternalLinks from "rehype-external-links";
import HelpMappings from "./HelpMappings.json";
import { HelpPanelProps } from "./HelpPanel.types";
import { getDataTestIdProp } from "../../utils/testUtil";

const getHeaderBarHeight = (defaultValue: number): number => {
  const headerBar = document.querySelector("div[data-tracking='RegionsHeader']") as HTMLElement;
  return headerBar?.offsetHeight ? headerBar.offsetHeight : defaultValue;
}

// creates a fixed element that displays the Help text
const HelpPanelHeader = styled(Box)<BoxProps & { headerHeight: number }>`
  position: fixed !important;
  top: calc(${props => props.headerHeight}px - 5px);
`;

// creates the actual container for the help panel
//  and restricts its size so the header stays in place.
const PanelContainer = styled("div")<{ headerHeight: number }>`
  overflow-y: scroll;
  overflow-x: hidden;
  height: calc(100vh - (${props => props.headerHeight}px + 80px));
  margin-top: 80px !important;
  box-shadow: 0 10px 11px -5px rgba(0, 0, 0, 0.09) inset;
  -webkit-box-shadow: 0 10px 11px -5px rgba(0, 0, 0, 0.09) inset;
  -moz-box-shadow: 0 10px 11px -5px rgba(0, 0, 0, 0.09) inset;
`;

const PanelBottomSpace = styled.div<{ headerHeight: number }>`
  height: calc(100vh - (${props => props.headerHeight}px + 80px))
`;

const MarkdownWrapper = ({ children }) => (
  <ReactMarkdown
    // prevent tab-nabbing and open in new tab
    remarkPlugins={[remarkRehype as any, [rehypeExternalLinks, {
      target: "_blank",
      rel: ["noreferrer", "noopener"]
    }]]}
  >
    {children}
  </ReactMarkdown>
)

export const HelpPanelTestIdSuffixes = {
  panelHeader: "-panelHeader",
  panelContainer: "-panelContainer",
  helpPanel: "-helpPanel",
  helpPanelChildren: "-helpPanelChildren",
  helpPanelEmpty: "-helpPanelEmpty",
}

export const HelpPanel: FunctionComponent<HelpPanelProps> = (props) => {
  const { selected, topic } = props;

  const [helpItems, setHelpItems] = useState<any>([]);
  const [headerBarHeight, setHeaderBarHeight] = useState(getHeaderBarHeight(30));

  // creates a ref of refs, so we can address child component dom for focus.
  const helpRefs = useRef(HelpMappings.reduce((prev, current) => {
    prev[current.id] = createRef();
    return prev;
  }, {}));

  const newHeaderBarHeight = getHeaderBarHeight(70);
  if (headerBarHeight !== newHeaderBarHeight) {
    setHeaderBarHeight(newHeaderBarHeight);
  }

  // if there is a selected prop, attempt to find
  //  a matching ref and scroll that ref into view.
  useEffect(() => {
    if (selected) {
      const focusRef = helpRefs.current[selected];
      focusRef?.current?.scrollIntoView();
    }
  }, [selected, helpRefs]);

  // if there is a provided topic, map help items
  //  to that topic.
  useEffect(() => {
    const newHelpItems = HelpMappings.filter((item) => item.target === topic);
    setHelpItems(newHelpItems);
  }, [topic]);

  // in the odd chance the web page is resized,
  //  attempt to get new header height.
  useEffect(() => {
    function handleResize() {
      setHeaderBarHeight(getHeaderBarHeight(70));
    }

    window.addEventListener("resize", handleResize)
  }, [setHelpItems]);

  return (
    <>
      <HelpPanelHeader
        headerHeight={headerBarHeight}
        padding="l"
        {...getDataTestIdProp(props, HelpPanelTestIdSuffixes.panelHeader)}
      >
        <h1>Help</h1>
      </HelpPanelHeader>
      <PanelContainer
        headerHeight={headerBarHeight}
        {...getDataTestIdProp(props, HelpPanelTestIdSuffixes.panelContainer)}
      >
        <PolarisHelpPanel
          {...getDataTestIdProp(props, HelpPanelTestIdSuffixes.helpPanel)}
        >
          {helpItems.length > 0
            ? <div
              {...getDataTestIdProp(props, HelpPanelTestIdSuffixes.helpPanelChildren)}
            >
              {helpItems.map((item) => {
                return (
                  <div key={item.id} ref={helpRefs.current[item.id]}>
                    <Box padding={{ vertical: "m" }}>
                      <h3>{item.attribute}</h3>
                      <MarkdownWrapper>
                        {item.text}
                      </MarkdownWrapper>
                    </Box>
                  </div>
                )
              })}
              <PanelBottomSpace
                headerHeight={headerBarHeight}
              />
            </div>
            : <>
              <Box
                {...getDataTestIdProp(props, HelpPanelTestIdSuffixes.helpPanelEmpty)}
              >
                No help items for current context.
              </Box>
            </>
          }
        </PolarisHelpPanel>
      </PanelContainer>
    </>
  );
}
