import * as React from "react";
import { useState, useCallback } from "react";
import RegionsTable from "../common/TableComponents/RegionsTable";
import {
  defaultTableConfig,
  genAriaLabel,
  remapColumnWidthsChange,
  sortDeliveryDate,
  sortRegion,
  sortRegionLaunchDate,
  sortStatus,
} from "../../utils/tableUtils";
import { Region, RegionLaunch } from "./SingleService.common";
import { attachRegionObject } from "../../utils/planUtil";
import { toShortDate } from "../../utils/dateUtil";
import {
  ISingleServiceCompletedTableColumnWidth,
  SingleServiceCompletedTableProps
} from "./SingleServiceCompletedTable.types";
import { LoadingIndicator, LoadingIndicatorTestId } from "../LoadingIndicator/LoadingIndicator";
import { IServicePlan } from "../../models/types/IServicePlan";
import { displayOr } from "../../utils/displayUtil";
import { Optional } from "../../models/types/Optional";
import { Table } from "@amzn/awsui-components-react";
import TablePropertyFiltering
  from "@amzn/awsui-components-react/polaris/table-property-filtering/table-property-filtering";
import { getNonEmptyProperties } from "../../utils/collectionUtil";
import { generateSingleServiceCompletedWorkbook } from "./SingleServiceCompletedTableCSVExport";
import { ExportTableToCSVExcel } from "../ExportTableToCSVExcel/ExportTableToCSVExcel";

export const SingleServiceCompletedTableTestId = {
  table: "SingleServiceCompletedListTable-table",
  loading: LoadingIndicatorTestId.component,
  exportTable: "SingleServiceCompletedListTable-exportTable",
}

const fields = {
  0: "region",
  1: "status",
  3: "date",
};

const getColumnDefinitions = (serviceName: string, widths: ISingleServiceCompletedTableColumnWidth): Table.ColumnDefinition<IServicePlan>[] => {
  return [
    {
      id: "region",
      header: "Region",
      cell: (item) => <Region region={item.regionObject} nameRip={serviceName}/>,
      label: sortState => genAriaLabel(sortState, "Airport Code", "region"),
      width: widths.region,
      minWidth: "142",
    },
    {
      id: "regionLaunch",
      header: "Region Launch Date",
      cell: (item) => <RegionLaunch region={item.regionObject}/>,
      label: sortState => genAriaLabel(sortState, "Region Launch Date", "regionLaunch"),
      width: widths.date,
      minWidth: "125",
    },
    {
      id: "status",
      header: "RIP Build Status",
      cell: ({ status }) => <div className="awsui-util-t-l">{displayOr(status)}</div>,
      label: sortState => genAriaLabel(sortState, "RIP Build Status", "status"),
      width: widths.status,
      minWidth: "120",
    },
    {
      id: "date",
      header: "Date Delivered",
      cell: service => {
        const formattedDate: Optional<string> = toShortDate(service.date)
        return (
          <div className="awsui-util-t-l">
            {displayOr(formattedDate)}
          </div>
        );
      },
      label: sortState => genAriaLabel(sortState, "Planned Launch Date", "date"),
      width: widths.date,
      minWidth: "155",
    },
  ];
};

const SORTABLE_COLUMNS = [
  { id: "region", field: "region", comparator: sortRegion },
  { id: "regionLaunch", field: "regionLaunchDate", comparator: sortRegionLaunchDate },
  { id: "status", field: "status", comparator: sortStatus },
  { id: "date", field: "date", comparator: sortDeliveryDate },
];

const getFilteringOptions = (items: IServicePlan[]): TablePropertyFiltering.Option[] => [
  {
    groupValuesLabel: "Airport Codes",
    propertyKey: "region",
    propertyLabel: "Airport Code",
    values: getNonEmptyProperties(items, (plan) => plan.region),
  },
  {
    groupValuesLabel: "Region Launch Dates",
    propertyKey: "regionLaunchDate",
    propertyLabel: "Region Launch Date",
    values: getNonEmptyProperties(items, (plan) => plan.regionLaunchDate),
  },
  {
    groupValuesLabel: "Statuses",
    propertyKey: "status",
    propertyLabel: "Status",
    values: getNonEmptyProperties(items, (plan) => plan.status),
  },
  {
    groupValuesLabel: "Dates Delivered",
    propertyKey: "date",
    propertyLabel: "Date Delivered",
    values: getNonEmptyProperties(items, (plan) => plan.date),
  },
];

const TABLE_CONTENT_SELECTOR_OPTIONS = {
  title: "Select visible columns",
  options: [
    {
      label: "Columns",
      options: [
        { id: "region", label: "Region", visible: true, editable: false },
        { id: "regionLaunch", label: "Region Launch Date", visible: true },
        { id: "status", label: "Status", visible: true },
        { id: "date", label: "Delivery Date", visible: true, editable: false },
      ],
    }
  ]
};

const getTableConfig = args => {
  const {
    items,
    loading,
    columnDefinitions,
    header,
    filteringOptions,
    sortingColumn,
    sortingDescending,
    handleVisibleItemsChange,
    handleColumnWidthsChange,
    handleSortingChange,
  } = args;
  return defaultTableConfig({
    features: [
      "TablePropertyFiltering",
      "TablePagination",
      "TableSorting",
      "TablePreferences",
      "TablePageSizeSelector",
      "TableWrapLines",
      "TableContentSelector",
    ],
    variant: "borderless",
    columnDefinitions,
    header,
    items,
    loading,
    onVisibleItemsChange: handleVisibleItemsChange,
    onColumnWidthsChange: handleColumnWidthsChange,
    onSortingChange: handleSortingChange,
    filteringOptions,
    sortableColumns: SORTABLE_COLUMNS,
    contentSelectorOptions: TABLE_CONTENT_SELECTOR_OPTIONS,
    sortingColumn,
    sortingDescending,
  });
};

export const SingleServiceCompletedTable: React.FunctionComponent<SingleServiceCompletedTableProps> = (props) => {
  const {
    regions,
    serviceName,
    items,
    loading = true,
  } = props;

  const [completedSortingState, setCompletedSortingState] = useState({
    sortingColumn: "date",
    sortingDescending: true,
  });

  const handleCompletedSortingChange = useCallback(({ detail: { sortingColumn, sortingDescending } }) => {
       setCompletedSortingState({ sortingColumn, sortingDescending });
    }, []
  );

  const [columnWidths, setColumnWidths] = useState<ISingleServiceCompletedTableColumnWidth>({
    region: undefined,
    status: undefined,
    date: undefined,
    regionLaunch: undefined,
  });

  const itemsWithRegion = attachRegionObject(items, regions);

  const getExportDataBook = useCallback(() => {
    return generateSingleServiceCompletedWorkbook(itemsWithRegion)
  }, [itemsWithRegion]);

  const header = RegionsTable.getTableHeader({
    primary: "Completed",
    secondary: loading && <LoadingIndicator />,
    actionButtons: <ExportTableToCSVExcel
      fileNamePrefix={`SingleServiceCompleted`}
      generateDataBook={getExportDataBook}
      data-testid={SingleServiceCompletedTableTestId.exportTable}
    />,
  });

  const handleColumnWidthsChange = ({ detail: { widths } }) => {
    const mappedWidths: ISingleServiceCompletedTableColumnWidth = remapColumnWidthsChange(fields, widths) as ISingleServiceCompletedTableColumnWidth;
    setColumnWidths(mappedWidths);
  };

  const columnDefinitions = getColumnDefinitions(serviceName, columnWidths);
  const filteringOptions = getFilteringOptions(itemsWithRegion);
  const { sortingColumn, sortingDescending } = completedSortingState;
  const tableConfig = getTableConfig({
    items: itemsWithRegion,
    loading,
    columnDefinitions,
    header,
    filteringOptions,
    handleColumnWidthsChange,
    handleSortingChange: handleCompletedSortingChange,
    sortingColumn,
    sortingDescending
  });

  return <RegionsTable testId={SingleServiceCompletedTableTestId.table} {...tableConfig} />;
};

export default SingleServiceCompletedTable;
