import React, { FunctionComponent, useCallback, useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import { LaunchDetailsProps } from "./LaunchDetails.types";
import { NonCancelableCustomEvent, SpaceBetween, Tabs, TabsProps } from "@amzn/awsui-components-react-v3";
import { LaunchProperties } from "./LaunchProperties/LaunchProperties";
import { LaunchGeneralProperties } from "./Tabs/LaunchGeneralProperties/LaunchGeneralProperties";
import { LaunchDocReviews } from "./Tabs/LaunchDocReviews/LaunchDocReviews";
import { LaunchHistory } from "./Tabs/LaunchHistory/LaunchHistory";
import { LaunchRegions } from "./Tabs/LaunchRegions/LaunchRegions";
import { getDataTestIdProp } from "../../utils/testUtil";
import { changeReducer } from "../../parsers/changeParser";

export const LaunchDetailsTestIdSuffixes = {
  launchProperties: "-launchProperties",
  tabs: "-tabs",
}

export const LaunchDetails: FunctionComponent<LaunchDetailsProps> = (props) => {
  const {
    launch,
    changes,
    // tasks,
    product,
    regions,
    loading,
    loadingProduct,
    canEdit,
    onChange,
    onDelete
  } = props;
  const history = useHistory();

  const tabs = useMemo(() => [
    /*{
      label: <LaunchHeaderBadge label="Overdue tasks" count={tasks.length}/>,
      id: "tasks",
      content: <LaunchOverdueTasks tasks={tasks} loading={loading}/>
    },*/
    {
      label: "General info",
      id: "info",
      content: <LaunchGeneralProperties
        launch={launch}
        product={product}
        loading={loading}
        loadingProduct={loadingProduct}
        canEdit={canEdit}
      />
    },
    {
      label: "Docs & Reviews",
      id: "docs_reviews",
      content: <LaunchDocReviews
        onEdit={() => history.push(`/alm/launches/${launch.id}/edit/docs_reviews`)}
        docReviews={launch.docReviews ?? []}
        loading={loading}
        canEdit={canEdit}
      />
    },
    {
      label: "Regions",
      id: "regions",
      content: <LaunchRegions
        regions={launch.regions ?? []}
        regionSource={regions}
        loading={loading}
        canEdit={canEdit}
        onEdit={() => history.push(`/alm/launches/${props.launch.id}/edit/regions`)}
      />
    },
    {
      label: "History",
      id: "history",
      content: <LaunchHistory changes={changeReducer(changes)} loading={loading}/>
    }
  ], [canEdit, changes, history, launch, loading, loadingProduct, product, props.launch.id, regions]);

  const [selectedTab, setSelectedTab] = useState(
    tabs.find(tab => tab.id === props.selectedTab) ? props.selectedTab : tabs[0].id
  );

  useEffect(() => {
    setSelectedTab(tabs.find(tab => tab.id === props.selectedTab) ? props.selectedTab : tabs[0].id);
  }, [props.selectedTab, tabs]);

  const tabs_onChange = useCallback((evt: NonCancelableCustomEvent<TabsProps.ChangeDetail>) => {
    const tab = evt.detail.activeTabId;
    setSelectedTab(tab);
    onChange?.(evt);
  }, [onChange]);

  return (
    <SpaceBetween size="l">
      <LaunchProperties
        launch={launch}
        loading={loading}
        onDelete={() => onDelete()}
        canEdit={canEdit}
        {...getDataTestIdProp(props, LaunchDetailsTestIdSuffixes.launchProperties)}
      />
      <Tabs
        tabs={tabs}
        activeTabId={selectedTab}
        onChange={tabs_onChange}
        {...getDataTestIdProp(props, LaunchDetailsTestIdSuffixes.tabs)}
      />
    </SpaceBetween>
  );
};
