import * as React from "react";
import { FunctionComponent, useCallback, useEffect, useState } from "react";
import { ApiParityProps } from "./ApiParity.types";
import {
  ApiParity as ApiParityComponent,
  bootstrapApiParityData,
  NoDataApiReport
} from "@amzn/api-parity-react-component/lib";
import styled from "styled-components";
import { Tabs } from "@amzn/awsui-components-react";
import { Optional } from "@amzn/api-parity-react-component/lib/models/Nullable";
import * as awsui from "@amzn/awsui-design-tokens/polaris";
import { AttentionTip } from "@amzn/api-parity-react-component/lib/components/status-grid/attention-tip/AttentionTip";
import { setFeature } from "@amzn/api-parity-react-component/lib/feature/featureToggle";
import { IDataSourceUsage, reconUsage } from "@amzn/api-parity-react-component/lib/models/BootstrapDataUsage";
import { ParityGridViewSettingVO } from "@amzn/api-parity-react-component/lib/models/vos/ParityGridViewSettingVO";
import { getRecommendedSetting } from "@amzn/api-parity-react-component/lib/components/parity-grid/parityGridUtil";
import { Nullable } from "../../../models/types/Nullable";
import { StringParam, useQueryParam } from "use-query-params";
import { getTimeAgo } from "../../../utils/dateUtil";

export const ApiParityTestId = {
  component: "ApiParityTestId"
}

const FlushedContentTabs = styled(Tabs)`
  .awsui-tabs-variant-container {
    &.awsui-util-container {
      .awsui-tabs-content {
        padding: 0;
      }
    }
  }
`;

const Container = styled("div")`
  position: relative;
`;

const DataFreshness = styled("div")`
  position: absolute;
  z-index: 2;
  color: ${awsui.colorTextBodySecondary};
  right: 2rem;
  top: 2rem;
`;

const uiReservedSize = "300px";
const tabContentMaxHeight = `calc( 100vh - ${uiReservedSize} )`;
const dataUsage: IDataSourceUsage = {
  ...reconUsage,
  useCanary: false,
};

export const ApiParity: FunctionComponent<ApiParityProps> = (props) => {
  setFeature({
    commenting: false
  });

  const [data, dataSet] = useState(bootstrapApiParityData(props.data, dataUsage));
  const [serviceId, serviceIdSet] = useQueryParam<Nullable<string>>("service", StringParam);
  const [apiId, apiIdSet] = useQueryParam<Nullable<string>>("api", StringParam);
  const [regionId, regionIdSet] = useQueryParam<Nullable<string>>("region", StringParam);


  const initialSetting: ParityGridViewSettingVO = getRecommendedSetting();
  // @ts-ignore
  initialSetting.selection = {
    serviceId: serviceId ?? undefined,
    apiId: apiId ?? undefined,
    regionId: regionId ?? undefined,
  };

  const [setting, settingSet] = useState(initialSetting);

  useEffect(() => {
    serviceIdSet(setting.selection?.serviceId);
    apiIdSet(setting.selection?.apiId);
    regionIdSet(setting.selection?.regionId);
  }, [apiIdSet, regionIdSet, serviceIdSet, setting]);

  useEffect(() => {
    dataSet(bootstrapApiParityData(props.data, dataUsage));
  }, [props.data]);


  const grid_onSettingChange = useCallback((newSetting) => {
    settingSet(newSetting);
  }, []);


  let dataFreshnessLabel: Optional<string>;

  if (data.latestDataDate.cecm) {
    dataFreshnessLabel = getTimeAgo(data.latestDataDate.cecm);
  }

  const parityGrid = <ApiParityComponent
    constraintHeight={tabContentMaxHeight}
    services={data.services}
    regions={data.regions}
    parities={data.parities}
    parityOverrides={data.parityOverrides}
    comments={data.comments}
    apis={data.apis}
    buildPlans={data.serviceBuildPlans}
    exemptedApi={data.exemptedApi}
    setting={setting}
    onSettingChange={grid_onSettingChange}
  />;

  const apiWithNoData = <NoDataApiReport
    constraintHeight={tabContentMaxHeight}
    services={data.services}
    parities={data.parities}
    apis={data.apis}
  />;

  const tabs: Tabs.Tab[] = [
    {
      id: "parityGrid",
      label: "API Parity Grid",
      content: parityGrid,
    },
    {
      id: "noDataApi",
      label: <div>API with No Data <AttentionTip tooltip={`APIs without data across the selected regions.`} /></div>,
      content: apiWithNoData,
    }
  ];

  return (
    <Container data-testid={ApiParityTestId.component}>
      {
        !!dataFreshnessLabel &&
          <DataFreshness>Data Freshness: {dataFreshnessLabel}</DataFreshness>
      }
      <FlushedContentTabs tabs={tabs} activeTabId={"parityGrid"} variant={"container"} />
    </Container>

  );
};
