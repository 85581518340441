import { QaItemDefinition } from "../models/QaItemDefinition";
import * as read from "./abilityItems/abilityRead";
import * as noAbility from "./abilityItems/noAbility";
import * as noReadDateAbility from "./abilityItems/noReadDateAbility";

export const customerFacingQaItems: QaItemDefinition<any>[] = [
  read.listRegionHasItems,
  noReadDateAbility.listRegionShouldOnlySeeGaRegion,
  read.listServiceHasItems,
  noAbility.cannotUpdateServicePlan,
  noAbility.cannotUpdateBusinessCaseOverride,


  read.getServicePlanListReturnServiceData,
  read.getServicePlanListReturnPlanData,
  noReadDateAbility.getServicePlanListContainsNoNotes,
  noReadDateAbility.getServicePlanListContainsNoNonGAConfidence,
  noReadDateAbility.getServicePlanListContainsNoNonGADates,


  read.getServiceInRegionHistoryReturnServiceData,
  read.getServiceInRegionHistoryReturnHistoryData,
  noReadDateAbility.getServiceInRegionHistoryContainsNoNotes,
  noReadDateAbility.getServiceInRegionHistoryContainsNoNonGAConfidence,
  noReadDateAbility.getServiceInRegionHistoryContainsNoNonGADates,
];
