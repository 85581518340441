import styled from "styled-components";

const InlineHeader = styled.div`
  display: inline-flex;
  h5.inline-header-subtext {
    padding-left: 1rem;
    line-height: 4.75rem;
    color: #879596;
    font-style: italic;
  }
`;

export default InlineHeader;
