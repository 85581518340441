export const questionnaireFields = [
  "prfaqRequired",
  "pricingChangeInvolved",
  "regulated",
]

export const requiredFieldsForInProgress = [
  "name",
  "description",
  "date",
  "regions",
  "scope",
  "status",
  "tier",
  "type",
  "goal",
  "audience",
  "contacts",
  ...questionnaireFields
];

export const draftBannerTitle = "Draft Launch Notice"
export const draftBannerMessage = "NEXT STEP: Your launch is current in DRAFT state. In order to move to IN-PROGRESS and view a finalized checklist, you must update the following fields: state"
export const draftBannerBaseTestId = "draftBanner";
