import { AxiosResponse } from "axios";
import { getALMApiClient, PaginationResponse, } from "./daoUtil";
import { ILaunchDto, IUpdateLaunchDto } from "./types/ILaunchDto";
import { decodeAllStringFields } from "../parsers/parseDecodeHelpers";

export class LaunchDao {
  async getLaunches(productId?: string, maxResults?: number, nextToken?: string): Promise<PaginationResponse<ILaunchDto>> {
    const client = getALMApiClient();
    const urlParams = new URLSearchParams();

    productId && urlParams.append("parentProductId", encodeURIComponent(productId));
    maxResults && urlParams.append("MaxResults", encodeURIComponent(maxResults));
    nextToken && urlParams.append("NextToken", nextToken);

    const res = await client.get<PaginationResponse<ILaunchDto>>(`/api/launches?${urlParams.toString()}`);

    return decodeAllStringFields(res.data);
  }

  async getLaunch(launchId: string): Promise<ILaunchDto> {
    const client = getALMApiClient();
    const res = await client.get<ILaunchDto>(`/api/launches/${encodeURIComponent(launchId)}`);
    return decodeAllStringFields(res.data);
  }

  async updateLaunch(launchId, launch: Partial<IUpdateLaunchDto>): Promise<AxiosResponse> {
    const client = getALMApiClient();
    const res = await client.post(`/api/launches/${encodeURIComponent(launchId)}`, { ...launch }).catch((error) => {
      return error.response
    });
    return decodeAllStringFields(res);
  }

  async createLaunch(launch: Partial<ILaunchDto>): Promise<AxiosResponse> {
    const client = getALMApiClient();
    const res = await client.post("/api/launches/create", { ...launch }).catch((error) => {
      return error.response
    });
    return decodeAllStringFields(res);
  }

  async deleteLaunch(launchId: string) {
    const client = getALMApiClient();
    return await client.delete(`/api/launches/${encodeURIComponent(launchId)}`).then((res) => decodeAllStringFields(res)).catch((error) => {
      return error.response;
    });
  }
}
