import {
  AwsServiceVO,
  IdCollectionBase,
  IMutableCollection,
  OneLevelGroupIndex,
  RegionVO,
  TwoLevelItemIndex
} from "@amzn/api-parity-react-component";
import { Optional } from "../types/Optional";
import { ServiceInRegionRollupStatusVO } from "../vos";

export class ServiceInRegionRollupStatusCollection extends IdCollectionBase<ServiceInRegionRollupStatusVO> implements IMutableCollection<ServiceInRegionRollupStatusVO> {
  private readonly _byRegionIndex: OneLevelGroupIndex<ServiceInRegionRollupStatusVO, string>;
  private readonly _byServiceIndex: OneLevelGroupIndex<ServiceInRegionRollupStatusVO, string>;
  private readonly _byServiceRegionIndex: TwoLevelItemIndex<ServiceInRegionRollupStatusVO, string, string>;

  constructor(initialValues?: readonly ServiceInRegionRollupStatusVO[]) {
    super();
    this._byRegionIndex = new OneLevelGroupIndex<ServiceInRegionRollupStatusVO, string>("byRegion", (item) => item.region.id);
    this._byServiceIndex = new OneLevelGroupIndex<ServiceInRegionRollupStatusVO, string>("byService", (item) => item.service.id);
    this._byServiceRegionIndex = new TwoLevelItemIndex<ServiceInRegionRollupStatusVO, string, string>("byServiceRegion", (item) => item.service.id, (item) => item.region.id);

    this.buildIndexes([
      this._byRegionIndex,
      this._byServiceIndex,
      this._byServiceRegionIndex,
    ]);

    if (initialValues) {
      this.addRange(initialValues);
    }
  }

  byService(service: AwsServiceVO): readonly ServiceInRegionRollupStatusVO[] {
    return this._byServiceIndex.getValue(service.id);
  }

  byRegion(region: RegionVO): readonly ServiceInRegionRollupStatusVO[] {
    return this._byRegionIndex.getValue(region.id);
  }

  byServiceRegion(service: AwsServiceVO, region: RegionVO): Optional<ServiceInRegionRollupStatusVO> {
    return this._byServiceRegionIndex.getValue(service.id, region.id);
  }
}
