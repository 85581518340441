import { FunctionComponent } from "react";
import * as React from "react";
import { EditContactsHeaderProps } from "./EditContactsHeader.types";
import { SpaceBetween } from "@amzn/awsui-components-react-v3";
import { ContactsType } from "../../../../../models/vos/ServiceContactsVO";
import { ValueWithLabel } from "./ValueWithLabel/ValueWithLabel";

export const EditContactsHeaderTestId = {
  "name": "EditContactsHeader-name",
  "type": "EditContactsHeader-type",
  "partition": "EditContactsHeader-partition",
}


export const EditContactsHeader: FunctionComponent<EditContactsHeaderProps> = (props) => {
  return (
    <SpaceBetween size="l" direction="vertical">
      <ValueWithLabel data-testid={EditContactsHeaderTestId.name} label={"Service Name/ Build Task"}>{props.serviceName}</ValueWithLabel>
      <ValueWithLabel data-testid={EditContactsHeaderTestId.type} label={props.contactsTypeLabel}>{props.contactsType === ContactsType.Rip ? "Yes" : "No"}</ValueWithLabel>
      {!props.isAddingNewPartition && <ValueWithLabel data-testid={EditContactsHeaderTestId.partition} label={"Partition"}>{props.partition}</ValueWithLabel>}
    </SpaceBetween>
  );
};
