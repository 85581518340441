import * as React from "react";
import { FunctionComponent } from "react";
import { Container, Header } from "@amzn/awsui-components-react-v3";
import { EditLaunchQuestionnaireFormFields } from "./EditLaunchQuestionnaireForm/EditLaunchQuestionnaireForm";
import { IHasDataTestId } from "../../../../models/IHasDataTestId";
import { getDataTestIdProp } from "../../../../utils/testUtil";
import { IEditLaunchFormFieldsInput } from "../EditLaunchForm.types";

export const EditLaunchQuestionnaireTestIdSuffix = {
  header: "-header",
  form: "-form",
}

export interface IEditLaunchQuestionnaireContainerProps extends IHasDataTestId {
  control: any,
  errors: any,
}


export const EditLaunchQuestionnaire: FunctionComponent<IEditLaunchFormFieldsInput> = (props) =>{

  return (
    <>
      <Container header={
        <Header variant="h2" {...getDataTestIdProp(props, EditLaunchQuestionnaireTestIdSuffix.header)}>
          Edit Launch Questionnaire
        </Header>
      }
      >
        <form {...getDataTestIdProp(props, EditLaunchQuestionnaireTestIdSuffix.form)}>
          <EditLaunchQuestionnaireFormFields control={props.control} errors={props.errors} />
        </form>
      </Container>
    </>
  )
}
