import { Enum } from "enumify";
import { isBetaOrDevEnv, isNotProdEnv } from "./utils/environmentUtil";

export const GLOBALLY_EXPANDING_LAUNCH_BLOCKING = "Globally Expanding - Launch Blocking";
export const GLOBALLY_EXPANDING_MANDATORY = "Globally Expanding - Mandatory";
export const NON_GLOBALLY_EXPANDING = "Non-Globally Expanding";
export const CATEGORY_FIELD = "plan";
export const CATEGORY_LIST = [GLOBALLY_EXPANDING_LAUNCH_BLOCKING, GLOBALLY_EXPANDING_MANDATORY, NON_GLOBALLY_EXPANDING];


export const NOT_LAUNCHING = "NL";
export const AWAITING_AUTHORITY_TO_OPPERATE = "ATO";

export const NOT_PLANNED = "NP";
export const DEFAULT_DASH = "-";

export const GENERALLY_AVAILABLE = "GA";
export const INTERNALLY_AVAILABLE = "IA";

/**
 * New feature parity dashboard which sports multi-level feature tree, % on regions
 */
export const ENABLE_NEW_FEATURE_PARITY = isBetaOrDevEnv();
export const ENABLE_RESOURCE_PARITY = isBetaOrDevEnv();
export const ENABLE_API_PARITY_JSON_EXPORT = isNotProdEnv();

export const ENABLE_ANALYTICS = true;

class Confidence extends Enum {}
// @ts-ignore
Confidence.initEnum(["Complete", "Green", "Yellow", "Red", "-", undefined]);
class Category extends Enum {}
// @ts-ignore
Category.initEnum([...CATEGORY_LIST, undefined]);
class Status extends Enum {}
// @ts-ignore
Status.initEnum(["NOT_PLANNED", "PLANNED", "BUILD", "BVPC", "IA", "GA", undefined]);
export { Confidence, Category, Status };

export const RMS_URL_BASE = "https://regions.corp.amazon.com";
export const RMS_URL_DIMENSIONS = "https://regions.corp.amazon.com/dimensions";
export const RECON_URL_CREATE_ISSUE =
  "https://issues.amazon.com/issues/create?template=93b36c90-e6b1-4794-8fad-0fe30fe2c5ee";
export const MIME_TYPE_DOCX = "application/vnd.openxmlformats-officedocument.wordprocessingml.document";
export const MIME_TYPE_XLSX = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
export const EMAIL_US_URL = "mailto:regions-recon@amazon.com";
export const REQUEST_A_FEATURE_URL =
  "https://tiny.amazon.com/128njrp89/ReconFeatureRequest";
export const REPORT_A_BUG_URL =
  "https://issues.amazon.com/issues/create?template=14b8bff9-b633-4f51-8d07-b5a6fbcab538";
export const MAILING_LIST_URL =
  "https://permissions.amazon.com/group.mhtml?target=17298392";
export const FAQ_URL =
  "https://w.amazon.com/bin/view/AWSRegionBuildEngineering/Recon/FAQ/";
export const ROADMAP_URL =
  "https://w.amazon.com/bin/view/AWSRegionBuildEngineering/Recon/Roadmap/";


export const CONTENT_TYPE_AMAZON_JSON = "application/x-amz-json-1.1";

export const SHOW_BETA_DASHBOARDS = isBetaOrDevEnv();

export const HTTP_STATUS = {
  NO_PERMISSION: 503,
  NOT_FOUND: 404
}
