export enum MutationResultType {
  Success = "success",
  Error = "error",
}

export interface IErrorLineItem<ErrCodeT, FieldNameT = string> {
  code: ErrCodeT;
  field?: FieldNameT;
}

/**
 * When remote mutation is successful, this data type
 * describes the relevant entity updated by the remote endpoint
 */
export type RemoteMutationSuccess<T> = {
  type: MutationResultType.Success,
  entity: T
}

/**
 * When remote mutation fails, this data type
 * encapsulates all the errors related to the
 */
export type RemoteMutationError<ErrorCodeT, FieldNameT = string> = {
  type: MutationResultType.Error,
  errors: IErrorLineItem<ErrorCodeT, FieldNameT>[],
}

export type RemoteMutationResult<EntityT, ErrorT, ErrorFieldNameT = string> = RemoteMutationSuccess<EntityT> | RemoteMutationError<ErrorT, ErrorFieldNameT>;
