import { IServiceMetaData } from "../daos/types/IServiceMetaData";
import { InstanceName } from "../models/InstanceName";
import { fromInstanceText } from "./instanceParser";
import {
  AwsServiceVO,
  AwsServiceAvailabilityLevelEnum,
  AwsServiceExpansionPlanEnum,
  expansionPlanByRecon
} from "@amzn/api-parity-react-component";
import { Nullable } from "../models/types/Nullable";

export function fromReconServiceDto(dto: IServiceMetaData): AwsServiceVO {
  const instanceName: InstanceName = fromInstanceText(dto.instance);
  const id: string = instanceName.names[0];
  const availabilityLevels: Set<AwsServiceAvailabilityLevelEnum> = new Set<AwsServiceAvailabilityLevelEnum>();
  dto.availabilityLevel?.forEach((level) => {
    availabilityLevels.add(level);
  });
  return new AwsServiceVO({
    id,
    name: (dto.nameSortable ?? dto.nameLong ?? dto.nameRip) as string,
    expansionPlan: getServiceExpansionPlan(dto.plan),
    availabilityLevels,
    nameVariations: {
      ripId: id,
      sortable: dto.nameSortable,
      long: dto.nameLong
    },
    visibility: dto.visibility
  });
}

export function parseAllFromReconServiceDto(dtos: IServiceMetaData[]): AwsServiceVO[] {
  return dtos.map((dto) => fromReconServiceDto(dto));
}

export function getServiceExpansionPlan( expansionPlan: Nullable<string> ): AwsServiceExpansionPlanEnum {
  if (!expansionPlan) {
    return AwsServiceExpansionPlanEnum.None;
  }
  return expansionPlanByRecon.get(expansionPlan)?.id || AwsServiceExpansionPlanEnum.None;
}
