import * as React from "react"
import { FunctionComponent, useCallback } from "react"
import { CTIFormFieldsProps } from "./CTIFormFields.types";
import { SpaceBetween } from "@amzn/awsui-components-react-v3";
import { formFieldSpacing } from "../AddOrEditServiceContactModal.style";
import { IndividualContactFormFieldWithController } from "../IndividualContactFormFieldWithController/IndividualContactFormFieldWithController";
import { CTIContactFormFields } from "../AddOrEditServiceContactModalFormFields";
import { getDataTestIdProp } from "../../../../../utils/testUtil";
import { IFormFieldData } from "../../../../common/FormTypes";

export const CTIFormFieldsTestIdSuffixes = {
  category: "-category",
  type: "-type",
  item: "-item",
}

export const CTIFormFields: FunctionComponent<CTIFormFieldsProps> = (props) => {
  const { control, errors, selected } = props;

  const getFormField = useCallback(( formField: IFormFieldData ) => {
    return <IndividualContactFormFieldWithController control={control}
                                                     errors={errors}
                                                     formField={formField}
                                                     selected={selected}
                                                     {...getDataTestIdProp(props, CTIFormFieldsTestIdSuffixes[formField.id])}
    />
  }, [ control, errors, selected, props ]);

  return (
    <SpaceBetween
      {...getDataTestIdProp(props)}
      direction="horizontal"
      size={formFieldSpacing.horizontal}>
      {getFormField(CTIContactFormFields.category)}
      {getFormField(CTIContactFormFields.type)}
      {getFormField(CTIContactFormFields.item)}
    </SpaceBetween>
  );
};
