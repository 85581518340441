import { Optional } from "./types/Optional";
import { UserAbilities } from "./UserAbilities";

class ApplicationState {
  hasApplicationStateLoaded = false;
  blueprintHighWaterMarkUtc: Optional<Date> = undefined
  userAbilities: UserAbilities = new UserAbilities();
  userAlias: string = "";
}

const appState = new ApplicationState();

export function getAppState() {
  return appState;
}
