import { LoadingStatus } from "../../models/vos/DetailLoadingVO";
import styled from "styled-components";
import { getLoadingStatusColor } from "../../utils/loadingStatusUtil";
import * as awsui from "@amzn/awsui-design-tokens";

export const Container = styled("div")`
  display: grid;
  grid-template-columns: 2rem auto;
  grid-gap: 1rem;
`;

interface ILineItemTextProp {
  status: LoadingStatus;
}

export const LineItemText = styled("div")<ILineItemTextProp>`
  color: ${ (props) => getLoadingStatusColor(props.status) };
`;

export const ErrorDetail = styled.div`
  color: ${awsui.colorTextStatusError};
`
