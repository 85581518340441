import { getReconApiClient } from "./daoUtil";
import { getRipName } from "../utils/serviceMetadataUtil"
import { IGetParityResponse, IParityItem } from "./types/IGetParityResponse";

export class ParityDao {

  async getParity(): Promise<IGetParityResponse> {
    const client = getReconApiClient();
    const res = await client.get<IGetParityResponse>(this.getParityUrl());
    const data = res.data;
    data.forEach((parityItem: IParityItem) => {
      parityItem.serviceMetadata.nameRip = getRipName(parityItem?.serviceMetadata?.instance)
    });
    return data;
  }

  getParityUrl(): string {
    return ("/api/reports/parity");
  }

  async getParityCache(): Promise<IGetParityResponse> {
    const client = getReconApiClient();
    const res = await client.get<IGetParityResponse>(this.getParityCacheUrl());
    const data = res.data;
    data.forEach((parityItem: IParityItem) => {
      parityItem.serviceMetadata.nameRip = getRipName(parityItem?.serviceMetadata?.instance)
    });
    return data;
  }

  getParityCacheUrl(): string {
    return ("/api/reports/parity?cache=true");
  }
}
