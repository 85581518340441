import React, { FunctionComponent, useEffect, useState } from "react";
import { AuditTableProps } from "./AuditTable.types";
import { IServiceContactAudit } from "../../daos/types/IServiceContactsDto";
import User from "../common/User";
import { Box, Pagination, Table } from "@amzn/awsui-components-react-v3";
import { useCollection } from "@amzn/awsui-collection-hooks";
import { getDataTestIdProp } from "../../utils/testUtil";

export const AuditTableTestIdSuffix = {
  auditTable: "-auditTable",
  page: "-auditPage",
}

export const EMPTY_AUDIT_MESSAGE = "No audit history"
export const ERROR_AUDIT_MESSAGE = "Unable to fetch audit data"
export const AUDIT_PAGE_SIZE = 5
const EMPTY_ATTRIBUTE_VALUE = "-"

const attrDict = {
  "sim_guid": "SIM GUID",
  "oncall_team": "Oncall Team",
  "rip_contacts": "RIP Contacts",
  "local_zone_service_owner_map": "Local Zone Service Owner",
  "az_service_owner_map": "AZ Service Owner",
  "primary_build_poc_map": "Primary Build POC",
  "notifications": "Notification Alias",
  "rbt_service_manager_map": "Build TPM",
  "secondary_build_poc_map": "Secondary Build POC",
  "gm": "GM",
  "vp": "VP",
  "leadership_chain": "Leadership Chain",
  "in_rms_graph": "RMS Graph",
  "wiki": "Wiki",
  "vpce_build_owner_map": "VPCE Build Owner",
  "cti": "CTI",
  "ops_dashboard": "Operational Dashboard"
}

const COLUMN_DEFINITIONS = [
  {
    id: "modifiedTimestamp",
    header: "Timestamp",
    width: 125,
    cell: (item: IServiceContactAudit) => new Date(item.timeChanged * 1000).toLocaleString() || "-",
  },
  {
    id: "modifiedAttribute",
    header: "Modified Attribute",
    cell: (item: IServiceContactAudit) => attrDict[item.attribute] || item.attribute || "-",
  },
  {
    id: "priorValue",
    header: "Prior Value",
    cell: (item: IServiceContactAudit) => parseValue(item.oldValue, item.attribute),
  },
  {
    id: "modifiedValue",
    header: "Modified Value",
    cell: (item: IServiceContactAudit) => parseValue(item.newValue, item.attribute),
  },
  {
    id: "modifiedBy",
    header: "Modified By",
    cell: (item: IServiceContactAudit) => <User alias={item.updater}/> || "-"
  }
]

function parseValue(value: any, attrType: string) {
  if (value) {
    if (attrType === "leadership_chain") {
      let joinedAliases: string[] = []
      for (let i = 0; i < value.length; i++) {
        joinedAliases.push(value[i]["alias"])
      }
      return joinedAliases.join("/\n")
    }
    else if (attrType === "cti") {
      return value["c"] ? [value["c"], value["t"], value["i"]].join("/") : EMPTY_ATTRIBUTE_VALUE
    }
    else if (value["alias"]) {
      return <User alias={value["alias"]}/>
    }
    else {
      return value.toString()
    }
  }
  else {
    return EMPTY_ATTRIBUTE_VALUE
  }
}

function auditCompare(a: IServiceContactAudit, b: IServiceContactAudit) {
  return a.timeChanged > b.timeChanged ? -1 : 1
}

function sortData(data?: IServiceContactAudit[]) {
  return data ? [...data].sort(auditCompare) : []
}

function determineErrorMessage(isInErrorState?: boolean) {
  let errorMsg = isInErrorState ? ERROR_AUDIT_MESSAGE : EMPTY_AUDIT_MESSAGE
  return (
    <Box textAlign="center" color="inherit">
      <b>{errorMsg}</b>
    </Box>
  )
}

export const AuditTable: FunctionComponent<AuditTableProps> = (props) => {
  const [sortedData, setSortedData] = useState(sortData(props.audit));
  const [errState, setErrState] = useState(props.audit === undefined);

  useEffect(() => {
    const sorted = sortData(props.audit);
    setSortedData(sorted);
  }, [props.audit, errState]);

  const { items, paginationProps } = useCollection(
    sortedData,
    {
      pagination: { pageSize: AUDIT_PAGE_SIZE },
      sorting: {
        defaultState: {
          sortingColumn: { sortingField: "modifiedTimestamp" },
          isDescending: true
        }
      }
    }
  );
  return (
    <Table
      {...getDataTestIdProp(props, AuditTableTestIdSuffix.auditTable)}
      wrapLines={true}
      resizableColumns
      columnDefinitions={COLUMN_DEFINITIONS}
      empty={determineErrorMessage(errState)}
      items={items}
      pagination={
        <Pagination {...getDataTestIdProp(props, AuditTableTestIdSuffix.page)} {...paginationProps} />
      }
    />
  );
};
