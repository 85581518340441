import styled from "styled-components";

export const Container = styled("div")`
  margin-top: 0.5rem; // xs
  margin-bottom: 1.5rem; // medium
`;

export const UpdateServiceCategoryContainer = styled("div")`
  display: grid;
  grid-template-columns: 34rem 18rem;
  grid-column-gap: 0.5rem;
`;
