import { Alert, Link, SpaceBetween } from "@amzn/awsui-components-react-v3";
import { useSelector } from "react-redux";
import { AwsServiceVO } from "@amzn/api-parity-react-component";
import { REPORT_A_BUG_URL } from "../../constants";
import { LaunchVO } from "../../models/vos/LaunchVO";
import { Loading } from "../../components/common/Loading";
import { EditLaunchProperties } from "../../components/LaunchDetails/EditLaunch/EditLaunchProperties/EditLaunchProperties";
import { useHistory, useParams } from "react-router-dom";
import * as React from "react";
import { useCallback, useEffect, useState } from "react";
import { ILaunchDto, IUpdateLaunchDto } from "../../daos/types/ILaunchDto";
import { useForm } from "react-hook-form";
import {
  IEditLaunchFormInput,
  ISelectableFormInput
} from "../../components/LaunchDetails/EditLaunch/EditLaunchForm.types";
import {
  EditLaunchErrorAlerts
} from "../../components/LaunchDetails/EditLaunch/EditLaunchErrorAlerts/EditLaunchErrorAlerts.helpers";
import { EditLaunchSubmit } from "../../components/LaunchDetails/EditLaunch/EditLaunchSubmit/EditLaunchSubmit";
import { EditLaunchReason } from "../../components/LaunchDetails/EditLaunch/EditLaunchReason/EditLaunchReason";
import { formFieldSpacing } from "../../components/LaunchDetails/EditLaunch/EditLaunchForm.style";
import { EditLaunchGeneral } from "../../components/LaunchDetails/EditLaunch/EditLaunchGeneral/EditLaunchGeneral";
import {
  EditLaunchQuestionnaire
} from "../../components/LaunchDetails/EditLaunch/EditLaunchQuestionnaire/EditLaunchQuestionnaire";
import { EditLaunchContacts } from "../../components/LaunchDetails/EditLaunch/EditLaunchContacts/EditLaunchContacts";
import { convertBooleanToRadioYesNo } from "../../components/IndividualFormFieldWithController/FormFieldTypeMapper";
import {
  LaunchAudience, LaunchDisclosure,
  LaunchGoalType,
  LaunchIteration,
  LaunchScope,
  LaunchState,
  LaunchStatus,
  LaunchTier,
  LaunchType
} from "../../components/ProductLaunches/LaunchWizard/LaunchWizard.types";
import { getOtherContactsForForm, submitLaunchEditToApi } from "./EditLaunchContainer.helpers";
import { useLaunchContext } from "../../contexts/LaunchContext";
import { useProductContext } from "../../contexts/ProductContext";
import { useProduct } from "../../utils/productUtil";
import { ProductVO } from "../../models/vos/ProductVO";
import { Optional } from "@amzn/react-bot";
import { serviceListSelector, serviceLoadStateSelector } from "../../redux/selectors/serviceSelector";
import { LoadStateEnum } from "../../models/LoadStateEnum";
import useTitleBanner from "../../components/unified/TitleBanner/useTitleBanner";
import useSWR from "swr";
import { LaunchDao } from "../../daos/LaunchDao";
import { launchDtoToVO } from "../../parsers/launchParser";
import { getSwrConfig } from "../../daos/swrUtil";
import { getAppState } from "../../models/ApplicationState";

export interface EditLaunchContainerParam {
  launchId: string;
}

export const EditLaunchContainer: React.FunctionComponent = () => {
  const userAbilities = getAppState().userAbilities;
  const { launchId } = useParams<EditLaunchContainerParam>();
  const [updateResponse, setUpdateResponse] = useState({ status: 200, text: {} });
  const { launch, setLaunch } = useLaunchContext();
  const { product, setProduct } = useProductContext();
  const allServices: AwsServiceVO[] = useSelector(serviceListSelector);
  const serviceLoadState: LoadStateEnum = useSelector(serviceLoadStateSelector);

  const {
    data: launchData,
    error,
  } = useSWR<LaunchVO>(() => `launch/${launchId}`, async () => {
    const dao = new LaunchDao();
    const dto = await dao.getLaunch(launchId);

    return launchDtoToVO(dto);
  }, getSwrConfig());

  // call swr to fetch product
  const { data: productData } = useProduct(launch?.productId);

  const setTitleBanner = useTitleBanner({ visible: true });

  useEffect(() => {
    setTitleBanner({
      visible: true,
      title: launch?.name,
      details: {
        info: launch?.description
      }
    });
  }, [launch?.description, launch?.name, setTitleBanner]);

  useEffect(() => {
    if (launch.id !== launchId || launchData) {
      const newLaunch = launchData
        ?? new LaunchVO();

      setLaunch(newLaunch);
    }
  }, [launch.id, launchId, setLaunch, launchData]);

  useEffect(() => {
    if (product.id !== launch?.productId) {
      const newProduct = productData ?? new ProductVO();
      setProduct(newProduct);
    }
  }, [launch.productId, product.id, productData, setProduct])

  const generateDefaultLaunchPropertyFormValues: () => IEditLaunchFormInput = useCallback(()=> {
    return {
      date: launch?.date,
      description: launch?.description,
      tags: launch?.tags ?? [],
      state: { label: LaunchState[launch?.state ?? ""], value: launch?.state },
      status: { label: LaunchStatus[launch?.status ?? ""], value: launch?.status },
      disclosureLevel: { label: LaunchDisclosure[launch?.disclosureLevel ?? ""], value: launch?.disclosureLevel },
      trackerLink: launch?.trackerLink,

      codename: launch?.codename,
      name: launch?.name,
      productId: product.name,
      iteration: { label: LaunchIteration[launch?.iteration ?? ""], value: launch?.iteration },
      type: { label: LaunchType[launch?.type ?? ""], value: launch?.type },
      scope: { label: LaunchScope[launch?.scope ?? ""], value: launch?.scope },
      ripId: launch?.ripId,
      tier: { label: LaunchTier[launch?.tier ?? ""], value: launch?.tier },
      audience: { label: LaunchAudience[launch?.audience ?? ""], value: launch?.audience },
      confidential: convertBooleanToRadioYesNo(launch?.confidential),
      goalLink: launch?.goalLink,
      goal: LaunchGoalType[launch?.goal ?? ""] ?? launch?.goal?.replace("OTHER: ",""),

      productManager: launch?.contacts?.productManager,
      marketingLead: launch?.contacts?.marketingLead,
      engineeringLead: launch?.contacts?.engineeringLead,
      otherContacts: getOtherContactsForForm(launch?.contacts),

      pricingChangeInvolved: launch?.questionnaire?.pricingChangeInvolved,
      namingInvolved: launch?.questionnaire?.namingInvolved,
      prfaqRequired: launch?.questionnaire?.prfaqRequired,
      regulated: launch?.questionnaire?.regulated,
    }
  }, [launch, product]);

  const { control, errors, trigger, watch, getValues, reset, formState, handleSubmit } = useForm<IEditLaunchFormInput>({ mode: "onChange", reValidateMode: "onChange", shouldUnregister: false, defaultValues: generateDefaultLaunchPropertyFormValues() })
  const { isValid } = formState;

  useEffect(() => {
    if (launch) {
      reset(generateDefaultLaunchPropertyFormValues());
    }
  }, [launch, generateDefaultLaunchPropertyFormValues, reset])

  const history = useHistory();

  const onPropertiesSubmit = useCallback(async (launchId: string, launch: Partial<IUpdateLaunchDto>): Promise<ILaunchDto> => {
    return await submitLaunchEditToApi(launchId, launch, setUpdateResponse, history)
  }, [history]);

  const stateWatch: Optional<ISelectableFormInput> = watch("state");
  const statusWatch: Optional<ISelectableFormInput> = watch("status");
  const dateWatch: Optional<string> = watch("date");
  const nameWatch: Optional<string> = watch("name");
  const tierWatch: Optional<ISelectableFormInput> = watch("tier");
  const reasonForChangeWatch: Optional<ISelectableFormInput> = watch("reasonForChange");

  if ((serviceLoadState === LoadStateEnum.Loading && userAbilities.canRead) || (!launch && !error)) {
    return <Loading />
  }

  if (error || (serviceLoadState === LoadStateEnum.Error && userAbilities.canRead) || !launch) {
    return (
      <Alert
        type="error"
        header="Data Fetching Error"
      >
        {"There was an error fetching launch details, please try again later or submit a "}
        <Link
          external
          externalIconAriaLabel="Opens in a new tab"
          href={REPORT_A_BUG_URL}
        >
          ticket
        </Link>.
      </Alert>
    )
  }
  return (
    <>
      <SpaceBetween direction={"vertical"} size={formFieldSpacing.vertical}>
      {
        EditLaunchErrorAlerts[updateResponse.status] && EditLaunchErrorAlerts[updateResponse.status](updateResponse.text)
      }
        <EditLaunchProperties
          control={control}
          errors={errors}
          dateWatch={dateWatch}
        />
        <EditLaunchGeneral
          control={control}
          errors={errors}
          services={allServices}
        />
        <EditLaunchContacts
          control={control}
          errors={errors}
        />
        <EditLaunchQuestionnaire
          control={control}
          errors={errors}
        />
        <EditLaunchReason
          control={control}
          errors={errors}
          formState={formState}
          getValues={getValues}
          trigger={trigger}
          fieldWatchers={{ stateWatch, statusWatch, dateWatch, nameWatch, tierWatch, reasonForChangeWatch }}
        />
        <EditLaunchSubmit
          launch={launch!}
          onFormSubmit={onPropertiesSubmit}
          getValues={getValues}
          trigger={trigger}
          isValid={isValid}
        />
      </SpaceBetween >
    </>
  )
}
