import {
  AwsServiceVO,
  RegionVO,
  IdCollectionBase,
  IMutableCollection,
  OneLevelGroupIndex,
  TwoLevelGroupIndex,
  TwoLevelItemIndex
} from "@amzn/api-parity-react-component";
import { FeatureParityOverrideVO } from "../vos/FeatureParityOverrideVO";
import { FeatureVO } from "../vos/FeatureVO";
import { Optional } from "../types/Optional";

export class FeatureParityOverrideCollection extends IdCollectionBase<FeatureParityOverrideVO> implements IMutableCollection<FeatureParityOverrideVO> {
  private readonly _byRegionIndex: OneLevelGroupIndex<FeatureParityOverrideVO, string>;
  private readonly _byServiceIndex: OneLevelGroupIndex<FeatureParityOverrideVO, string>;
  private readonly _byFeatureIndex: OneLevelGroupIndex<FeatureParityOverrideVO, string>;
  private readonly _byServiceRegionIndex: TwoLevelGroupIndex<FeatureParityOverrideVO, string, string>;
  private readonly _byFeatureRegionIndex: TwoLevelItemIndex<FeatureParityOverrideVO, string, string>;

  constructor(initialValues?: readonly FeatureParityOverrideVO[]) {
    super();

    this._byFeatureRegionIndex = new TwoLevelItemIndex<FeatureParityOverrideVO, string, string>("byFeatureRegion", (item) => item.feature.id, (item) => item.region.id);
    this._byFeatureIndex = new OneLevelGroupIndex<FeatureParityOverrideVO, string>("byFeature", (item) => item.feature.id);
    this._byServiceIndex = new OneLevelGroupIndex<FeatureParityOverrideVO, string>("byService", (item) => item.feature.service.id);
    this._byRegionIndex = new OneLevelGroupIndex<FeatureParityOverrideVO, string>("byRegion", (item) => item.region.id);
    this._byServiceRegionIndex = new TwoLevelGroupIndex<FeatureParityOverrideVO, string, string>("byServiceRegion", (item) => item.feature.service.id, (item) => item.region.id);

    this.buildIndexes([
      this._byRegionIndex,
      this._byServiceIndex,
      this._byFeatureRegionIndex,
      this._byFeatureIndex,
      this._byServiceRegionIndex,
    ]);

    if (initialValues) {
      this.addRange(initialValues);
    }
  }

  byFeature(feature: FeatureVO): readonly FeatureParityOverrideVO[] {
    return this._byFeatureIndex.getValue( feature.id );
  }

  byService(service: AwsServiceVO): readonly FeatureParityOverrideVO[] {
    return this._byServiceIndex.getValue(service.id);
  }

  byRegion(region: RegionVO): readonly FeatureParityOverrideVO[] {
    return this._byRegionIndex.getValue(region.id);
  }

  byFeatureRegion(feature: FeatureVO, region: RegionVO): Optional<FeatureParityOverrideVO> {
    return this._byFeatureRegionIndex.getValue(feature.id, region.id);
  }

  byServiceRegion(service: AwsServiceVO, region: RegionVO): readonly FeatureParityOverrideVO[] {
    return this._byServiceRegionIndex.getValue(service.id, region.id);
  }
  
}
