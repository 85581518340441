import * as React from "react";
import { FunctionComponent, useCallback } from "react";
import { MutualFormFieldsProps } from "./MutualFormFields.types";
import { SpaceBetween } from "@amzn/awsui-components-react-v3";
import { formFieldSpacing } from "../AddOrEditServiceContactModal.style";
import { IndividualContactFormFieldWithController } from "../IndividualContactFormFieldWithController/IndividualContactFormFieldWithController";
import { nonCTIFormFields } from "../AddOrEditServiceContactModalFormFields";
import { getDataTestIdProp } from "../../../../../utils/testUtil";
import styled from "styled-components";
import { Displayable } from "@amzn/api-parity-react-component/lib/models/Displayable";
import { IFormFieldData } from "../../../../common/FormTypes";

const InputSectionHeader = styled.div`
  font-weight: bold;
`;

export const MutualFormFieldsTestIdSuffixes = {
  primaryBuildPoc: "-primaryBuildPoc",
  secondaryBuildPoc: "-secondaryBuildPoc",
  rbtServiceManager: "-rbtServiceManager",
  notifications: "-notifications",
  simGuid: "-simGuid",
  azServiceOwner: "-azServiceOwner",
  localZoneServiceOwner: "-localZoneServiceOwner",
  vpceBuildOwner: "-vpceBuildOwner",
  ripContacts: "-ripContacts",
};

export const MutualFormFields: FunctionComponent<MutualFormFieldsProps> = (props) => {

  const { control, errors, selected } = props;

  const getFormField = useCallback((formField: IFormFieldData, info?: Displayable) => {
    return <IndividualContactFormFieldWithController control={control}
                                                     errors={errors}
                                                     formField={formField}
                                                     selected={selected}
                                                     popoverInfo={info}
                                                     {...getDataTestIdProp(props, MutualFormFieldsTestIdSuffixes[formField.id])}
    />;
  }, [control, errors, selected, props]);

  return (
    <React.Fragment>
      <SpaceBetween
        {...getDataTestIdProp(props)}
        direction="vertical"
        size={formFieldSpacing.section}>

        <SpaceBetween direction={"vertical"} size={formFieldSpacing.header}>
          <InputSectionHeader>Team Point of Contacts</InputSectionHeader>
          <SpaceBetween direction={"vertical"} size={formFieldSpacing.vertical}>
            <SpaceBetween direction="horizontal" size={formFieldSpacing.horizontal}>
              {getFormField(nonCTIFormFields.primaryBuildPoc)}
              {getFormField(nonCTIFormFields.secondaryBuildPoc)}
            </SpaceBetween>

            <SpaceBetween direction="horizontal" size={formFieldSpacing.horizontal}>
              {getFormField(nonCTIFormFields.rbtServiceManager)}
              {getFormField(nonCTIFormFields.azServiceOwner)}
            </SpaceBetween>

            <SpaceBetween direction="horizontal" size={formFieldSpacing.horizontal}>
              {getFormField(nonCTIFormFields.localZoneServiceOwner)}
              {getFormField(nonCTIFormFields.vpceBuildOwner)}
            </SpaceBetween>
          </SpaceBetween>
        </SpaceBetween>


        <SpaceBetween direction={"vertical"} size={formFieldSpacing.header}>
          <InputSectionHeader>Emails</InputSectionHeader>
          <SpaceBetween direction="horizontal" size={formFieldSpacing.horizontal}>
            {getFormField(nonCTIFormFields.notifications, "The Notification Alias is a single service team email distribution used for " +
              "region build activities and escalations. Leave off any “@” or “@amazon.com” or other suffix.")}
            {getFormField(nonCTIFormFields.ripContacts, "The Rip Contacts field separates entries by commas. " +
              "No whitespace is allowed in this field. " +
              "Set each entry to the login name of an Amazon employee or an email distribution list name. " +
              "Leave off any \"@\" or \"@amazon.com\" or other suffix.")}
          </SpaceBetween>
        </SpaceBetween>

        <SpaceBetween direction={"vertical"} size={formFieldSpacing.header}>
          <InputSectionHeader>Operations</InputSectionHeader>
          <SpaceBetween direction={"vertical"} size={formFieldSpacing.vertical} >
            {getFormField(nonCTIFormFields.wikiUrl)}
            {getFormField(nonCTIFormFields.oncallTeam)}
            {getFormField(nonCTIFormFields.simGuid)}
            {getFormField(nonCTIFormFields.opsDashboard)}
          </SpaceBetween>
        </SpaceBetween>
      </SpaceBetween>
    </React.Fragment>
  );
};
