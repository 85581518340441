import moment from "moment";
import _ from "lodash";
import RegionsTable from "../components/common/TableComponents/RegionsTable";
import { Confidence, Category, Status } from "../constants";
import { getStatus } from "./singleRegionUtils";
import { getRipName } from "./serviceMetadataUtil";
import { getItemKind } from "../views/SingleService/FeatureTableUtils"

const sortDeliveryDate = ({ date: l }, { date: r }) => sortDates(l, r);
const sortUpdatedDate = ({ updated: l }, { updated: r }) => sortDates(l, r);
const sortRegionLaunchDate = ({ regionLaunchDate: l }, { regionLaunchDate: r }) => sortDates(l, r);

const comparatorWrapper = (l = undefined, r = undefined, comparator = (l, r) => { return 0; }) => {
  if (l && r) {
    return comparator(l, r);
  } else {
    if (l) {
      return -1;
    } else if (r) {
      return 1;
    }
  }
  return 0;
};

const deepHelper = (item, property) => {
  const regionKey = Object.keys(item?.region ?? {})[0] ?? undefined;
  const region = item?.region ?? {};
  const regionMetadata = region[regionKey] ?? {};
  return regionMetadata[property] ?? undefined;
};

const localeCompare = (l = undefined, r = undefined) =>  comparatorWrapper (l, r, (l, r) => {
  try {
    return l.toString().localeCompare(r);
  } catch (e) {
    if (e instanceof RangeError) {
      console.error(e.message);
      return 0;
    } else {
      throw e;
    }
  }
});

const ordinalCompare = (l = undefined, r = undefined) => {
  if (l && r && ("ordinal" in l) && ("ordinal" in r)) {
    return l.ordinal - r.ordinal;
  }
  return 0;
}
const getRIP = (service) => {
  return service.nameRip || getRipName(service.instance)
}

const getServiceName = (service) => {
  return service.nameSortable || service.nameLong || service.nameRip;
};

const getServiceLongName = (service) => {
  return service.nameLong || service.nameSortable || service.nameRip;
};

const getServiceListCategory = (service) => {
  return service.category;
}

const getServiceCategory = (service) => {
  return service.plan;
}

const sortSortableName = (l = undefined, r = undefined) => comparatorWrapper (l, r, (l, r) => {
  const lName = getServiceName(l);
  const rName = getServiceName(r);
  return localeCompare(lName, rName);
});

export const sortRipKind = (l = undefined, r = undefined) => comparatorWrapper (l, r, (l, r) => {
  const lKind = getItemKind(l);
  const rKind = getItemKind(r);

  return localeCompare(lKind, rKind);
});

const sortLongName = (l = undefined, r = undefined) => comparatorWrapper (l, r, (l, r) => {
  const lName = getServiceLongName(l);
  const rName = getServiceLongName(r);
  return localeCompare(lName, rName);
});

const sortRIP = (l = undefined, r = undefined) => comparatorWrapper (l, r, (l, r) => {
  const lName = getRIP(l);
  const rName = getRIP(r);
  return localeCompare(lName, rName);
});

const sortDeliveryDateDeep = (l, r) => {
  return sortDates(deepHelper(l, "date"), deepHelper(r, "date"));
};

const sortRegion = (l = undefined, r = undefined) => comparatorWrapper (l, r, (l, r) => {
  const lRegion = l.belongsToInstance || l.region;
  const rRegion = r.belongsToInstance || r.region;
  return localeCompare(lRegion, rRegion);
});

const sortDates = (l = undefined, r = undefined) => comparatorWrapper (l, r, (l, r) => {
  let lMoment = new moment(l);
  let rMoment = new moment(r);
  lMoment = lMoment.isValid() ? lMoment : Number.MAX_SAFE_INTEGER;
  rMoment = rMoment.isValid() ? rMoment : Number.MAX_SAFE_INTEGER;
  return lMoment - rMoment;
});

const sortStatus = (l = undefined, r = undefined) => comparatorWrapper (l, r, (l, r) => {
  const { status: lStatus } = l ?? undefined;
  const { status: rStatus } = r ?? undefined;
  return ordinalCompare(Status[`${lStatus}`], Status[`${rStatus}`]);
});

const sortStatusDeep = (l = undefined, r = undefined) => comparatorWrapper (l, r, (l, r) => {
  const lStatus = getStatus(l) ?? undefined;
  const rStatus = getStatus(r) ?? undefined;
  return ordinalCompare(Status[`${lStatus}`], Status[`${rStatus}`]);
});

const sortCategory = (l = undefined, r = undefined) => comparatorWrapper (l, r, (l, r) => {
  const lCategory = getServiceCategory(l);
  const rCategory = getServiceCategory(r);
  return ordinalCompare(Category[`${lCategory}`], Category[`${rCategory}`]);
});

const sortServiceListCategory = (l = undefined, r = undefined) => comparatorWrapper (l, r, (l, r) => {
  const lCategory = getServiceListCategory(l);
  const rCategory = getServiceListCategory(r);
  return ordinalCompare(Category[`${lCategory}`], Category[`${rCategory}`]);
});

const sortConfidence = (l = undefined, r = undefined) => comparatorWrapper (l, r, (l, r) => {
  const { confidence: lConfidence } = l;
  const { confidence: rConfidence } = r;
  return ordinalCompare(Confidence[`${lConfidence}`], Confidence[`${rConfidence}`]);
});

const sortConfidenceDeep = (l = undefined, r = undefined) => comparatorWrapper (l, r, (l, r) => {
  const lConfidence = deepHelper(l, "confidence");
  const rConfidence = deepHelper(r, "confidence");
  return ordinalCompare(Confidence[`${lConfidence}`], Confidence[`${rConfidence}`]);
});

const remapColumnWidthsChange = (fields, widths) => {
  return _.mapKeys(widths, function(value, key) {
    return fields[key];
  });
};

const genAriaLabel = (sortState, header, columnId) => {
  const columnIsSorted = sortState.sortingColumn === columnId;
  const ascending = !sortState.sortingDescending;
  return `${header}, ${
    columnIsSorted
      ? `sorted ${ascending ? "ascending" : "descending"}`
      : "not sorted"
  }.`;
};

const genHeader = ({ title, items }) => {
  const length = items?.length ?? 0;
  return RegionsTable.getTableHeader({
    primary: title,
    secondary: `${length}`,
  });
};

const defaultTableConfig = props => {
  const {
    features,
    variant,
    columnDefinitions,
    header,
    items,
    loading,
    onVisibleItemsChange,
    onColumnWidthsChange,
    onBeforeUnstick,
    paginationOptions,
    pageSizeSelectorOptions,
    filteringOptions,
    sortableColumns,
    tableSelection,
    contentSelectorOptions,
    sortingColumn,
    sortingDescending,
    onSortingChange,
    defaultFilters,
  } = props;

  return {
    DefaultFilters: defaultFilters,
    Features: features,
    Table: {
      variant,
      columnDefinitions,
      header,
      items,
      loading,
      onVisibleItemsChange,
      onColumnWidthsChange,
      onBeforeUnstick
    },
    TablePagination: {
      ...paginationOptions
    },
    TablePageSizeSelector: {
      ...pageSizeSelectorOptions
    },
    TablePropertyFiltering: {
      filteringOptions,
    },
    TableSorting: {
      sortableColumns,
      sortingColumn,
      sortingDescending: sortingDescending ?? false,
      onSortingChange,
    },
    TableSelection: tableSelection,
    TableContentSelector: {
      ...contentSelectorOptions
    },
  };
};

export {
  defaultTableConfig,
  genAriaLabel,
  genHeader,
  remapColumnWidthsChange,
  sortCategory,
  sortConfidence,
  sortConfidenceDeep,
  sortRegionLaunchDate,
  sortDates,
  sortDeliveryDate,
  sortDeliveryDateDeep,
  sortUpdatedDate,
  sortLongName,
  sortRegion,
  sortRIP,
  sortStatus,
  sortStatusDeep,
  sortSortableName,
  sortServiceListCategory
};
