import * as React from "react";
import styled from "styled-components";
import { FunctionComponent, useCallback, useEffect } from "react";
import { TokenSearchProps } from "./TokenSearch.types";
import { Input, InputProps, TokenGroup, TokenGroupProps } from "@amzn/awsui-components-react-v3";
import { NonCancelableCustomEvent } from "@amzn/awsui-components-react-v3/polaris/internal/events";
import { getDataTestIdProp } from "../../utils/testUtil";

export const TokenSearchTestIdSuffix = {
  input: "-input",
  entered: "-entered",
}

const Container = styled.div`
`;

const ENTER_KEY = 13;

export const TokenSearch: FunctionComponent<TokenSearchProps> = (props) => {
  const {
    onSearch,
  } = props;

  const [terms, setTerms] = React.useState<string[]>([""]);
  const [tokens, setTokens] = React.useState<TokenGroupProps.Item[]>([]);
  const [filteringText, setFilteringText] = React.useState("");

  useEffect(() => {
    setTerms(props.terms ?? [""]);
  }, [
    props.terms,
    setTerms,
  ]);

  useEffect(() => {
    let newTokens: TokenGroupProps.Item[] = [];
    let searching: string = "";

    if (terms.length) {
       newTokens = terms.slice(0, terms.length - 1).map((term) => {
         return { label: term };
       });
       searching = terms[terms.length - 1];
    }
    setTokens(newTokens);
    setFilteringText(searching);
  }, [
    terms,
    setTokens,
    setFilteringText,
  ]);

  const input_onChange = useCallback(
    (evt: NonCancelableCustomEvent<InputProps.ChangeDetail>) => {
      const searching = evt.detail.value ?? "";
      const newTerms = [...terms];
      newTerms[newTerms.length - 1] = searching;
      setFilteringText(searching);
      setTerms(newTerms);
      onSearch?.(newTerms);
    } , [
      setFilteringText,
      setTerms,
      onSearch,
      terms,
    ]);

  const input_onKeyUp = useCallback(
    (evt: NonCancelableCustomEvent<InputProps.KeyDetail>) => {
      if (evt.detail.keyCode === ENTER_KEY && terms[terms.length - 1] !== "") {
        const newTerms: string[] = [
          ...terms,
          ""
        ];
        setTerms(newTerms);
      }
    }, [terms]
  );

  const tokens_onDismiss = useCallback((evt: NonCancelableCustomEvent<TokenGroupProps.DismissDetail>) => {
    const newTerms = [
      ...terms
    ];
    newTerms.splice(evt.detail.itemIndex, 1);
    setTerms(newTerms);
    onSearch?.(newTerms);
  }, [
    terms,
    setTerms,
    onSearch,
  ])

  return (
    <Container>
      <Input
        {...getDataTestIdProp(props, TokenSearchTestIdSuffix.input)}
        type="search"
        value={filteringText}
        disabled={props.disabled}
        placeholder={props.placeholder}
        onKeyUp={input_onKeyUp}
        onChange={input_onChange}
      />
      <TokenGroup
        {...getDataTestIdProp(props, TokenSearchTestIdSuffix.entered)}
        items={tokens}
        onDismiss={tokens_onDismiss}
      />
    </Container>
  );
};
