import {
  Button,
  ColumnLayout,
  Container,
  Header, Link,
  Link as PolarisLink,
  SpaceBetween,
  StatusIndicator,
} from "@amzn/awsui-components-react-v3";
import * as React from "react";
import { FunctionComponent } from "react";
import { LaunchPropertiesProps } from "./LaunchProperties.types";
import { getDataTestIdProp } from "../../../utils/testUtil";
import { displayOr } from "../../../utils/displayUtil";
import { LoadingIndicator } from "../../LoadingIndicator/LoadingIndicator";
import { formatLaunchDate, generateALCLink } from "../launchDetailsUtils";
import { ReactRouterButton } from "../../common/RouterComponents/ReactRouterButton";
import { LaunchDisclosure, LaunchState, LaunchStatus } from "../../ProductLaunches/LaunchWizard/LaunchWizard.types";
import { ValueWithLabel } from "../../common/ValueWithLabel/ValueWithLabel";
import { useHistory } from "react-router-dom";

export const LaunchPropertiesTestIdSuffixes = {
  dropdown: "-dropdown",
  date: "-date",
  state: "-state",
  trackerLink: "-trackerLink",
  editButton: "-editButton",
}

const LaunchTagLink = ({ tagValue, productId }) => {
  const history = useHistory();
  return (
    <Link
      onFollow={(event) => {
        event.preventDefault();
        const searchValue = {
          operation: "and",
          tokens: [
            {
              operator: ":",
              propertyKey: "tags",
              value: tagValue
            }
          ]
        }
        history.push({
          pathname: `/alm/products/${productId}/launches`,
          search: `filter=${btoa(JSON.stringify(searchValue))}`,
        });
      }}
    >
      {tagValue}
    </Link>
  );
}

export const LaunchProperties: FunctionComponent<LaunchPropertiesProps> = (props) => (
  <Container
    header={<Header
      variant="h2"
      actions={
        <SpaceBetween size="xs" direction="horizontal">
          <Button
            onClick={() => props.onDelete()}
          >
            Delete launch
          </Button>
          <ReactRouterButton
            {...getDataTestIdProp(props, LaunchPropertiesTestIdSuffixes.editButton)}
            to={`/alm/launches/${props.launch.id}/edit`}
            disabled={!props.canEdit}
          >
            Edit
          </ReactRouterButton>
        </SpaceBetween>
      }
    >
      Launch properties
    </Header>
    }
    {...getDataTestIdProp(props)}
  >
    {props.loading
      ? <LoadingIndicator/>
      : <ColumnLayout columns={3} variant="text-grid">
        <SpaceBetween size="l">
          <ValueWithLabel
            label="Launch date"
            helpId="LAUNCH_CURRENT_DATE"
            {...getDataTestIdProp(props, LaunchPropertiesTestIdSuffixes.date)}
          >
            {displayOr(props.launch.date, () => formatLaunchDate(props.launch.date!))}
          </ValueWithLabel>
          <ValueWithLabel
            label="Description"
            helpId="LAUNCH_DESCRIPTION"
          >
            {displayOr(props.launch.description)}
          </ValueWithLabel>
          <ValueWithLabel
            label="Tags"
          >
            {displayOr(
              props.launch.tags?.length,
              () => <>
                {props?.launch?.tags?.map((tag, index) =>
                  <React.Fragment key={index}>
                    <LaunchTagLink
                      tagValue={tag}
                      productId={props.launch.productId}
                    />
                    {index + 1 !== props?.launch?.tags?.length && ", "}
                  </React.Fragment>
                )}
              </>
            )}
          </ValueWithLabel>
        </SpaceBetween>
        <SpaceBetween size="l">
          <ValueWithLabel
            label="Launch state"
            helpId="LAUNCH_STATE"
            {...getDataTestIdProp(props, LaunchPropertiesTestIdSuffixes.state)}
          >
            <StatusIndicator
              type="success"
            >
              {displayOr(props.launch.state, () => LaunchState[props.launch.state!])}
            </StatusIndicator>
          </ValueWithLabel>
          <ValueWithLabel
            label="Tracker link"
            helpId="LAUNCH_TRACKER_LINK"
          >
            {displayOr(
              props.launch.trackerLink, () =>
                <PolarisLink
                  external
                  externalIconAriaLabel="Opens in a new tab"
                  href={props.launch.trackerLink}
                  {...getDataTestIdProp(props, LaunchPropertiesTestIdSuffixes.trackerLink)}
                >
                  {props.launch.trackerLink}
                </PolarisLink>
            )}
          </ValueWithLabel>
        </SpaceBetween>
        <SpaceBetween size="l">
          <ValueWithLabel
            label="Launch status"
            helpId="LAUNCH_STATUS"
          >
            {displayOr(
              props.launch.status,
              () => LaunchStatus[props.launch.status!] ?? props.launch.status
            )}
          </ValueWithLabel>
          <ValueWithLabel
            label="Customer disclosure level"
            helpId="LAUNCH_DISCLOSURE"
          >
            {displayOr(
              props.launch.disclosureLevel,
              () => LaunchDisclosure[props.launch.disclosureLevel!] ?? props.launch.disclosureLevel
            )}
          </ValueWithLabel>
          <ValueWithLabel
            label="Launch tasks"
            helpId="LAUNCH_TASKS"
          >
            <PolarisLink
              external
              externalIconAriaLabel="Opens in a new tab"
              href={generateALCLink(props.launch)}
            >
              View the ALC in Blueprint
            </PolarisLink>
            {/*<LaunchProgressBar
              status="in-progress"
              value={(complete_tasks / total_tasks) * 100}>
              <Link to="https://is.amazon.com">{`${complete_tasks}/${total_tasks} task complete`}</Link>
            </LaunchProgressBar>*/}
          </ValueWithLabel>
        </SpaceBetween>
      </ColumnLayout>
    }
  </Container>
);
