import * as React from "react";
import * as awsui from "@amzn/awsui-design-tokens/polaris";
import { LoadingStatus } from "./vos/DetailLoadingVO";
import { Icon, Spinner } from "@amzn/awsui-components-react";

// A file that defines what each loading status looks like per loading status
export interface ILoadingStatusDisplay {
  status: LoadingStatus;
  color: string;
  icon: React.ReactElement;
}

export const LoadingStatusTestId = {
  loading: "Loading",
  success: "Success",
  error: "Error",
  pending: "Pending",
  default: "Pending",
}

export const loadingDisplay: ILoadingStatusDisplay = {
  status: LoadingStatus.Loading,
  color: awsui.colorTextBodyDefault,
  icon: <Spinner data-testid={LoadingStatusTestId.loading} size={"normal"}/>
};

export const successDisplay: ILoadingStatusDisplay = {
  status: LoadingStatus.Success,
  color: awsui.colorTextStatusSuccess,
  icon: <Icon data-testid={LoadingStatusTestId.success} name={"status-positive"}/>
}

export const errorDisplay: ILoadingStatusDisplay = {
  status: LoadingStatus.Error,
  color: awsui.colorTextStatusError,
  icon: <Icon data-testid={LoadingStatusTestId.error} name={"status-negative"}/>
}

/**
 * Default can be used as catch-all situation where a new status is added in the enum but no display definition is used here
 */
export const defaultDisplay: ILoadingStatusDisplay = {
  status: LoadingStatus.NotStarted,
  color: awsui.colorTextBodySecondary,
  icon: <Icon data-testid={LoadingStatusTestId.default} name={"status-pending"}/>
}

export const loadingDisplayByStatus = {
  [LoadingStatus.Loading]: loadingDisplay,
  [LoadingStatus.Success]: successDisplay,
  [LoadingStatus.Error]: errorDisplay,
  [LoadingStatus.NotStarted]: defaultDisplay,
};

