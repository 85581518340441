import * as React from "react";
import { FunctionComponent, useCallback, useEffect, useState } from "react";
import { ServiceContactsDao } from "../../daos/ServiceContactsDao";
import { ServiceContactsVO } from "../../models/vos/ServiceContactsVO";
import { parseServiceContactsDto } from "../../parsers/serviceContactsParser";
import { useJsonLoader } from "../../utils/containerUtil";
import { SingleServiceContacts } from "../../components/ServiceContacts/SingleServiceContacts/SingleServiceContacts";
import { UserAbilities } from "../../models/UserAbilities";
import { addOrUpdateServiceContact } from "./orchestratorContainerUtils";
import { responseInterface } from "swr";
import { IServiceLeaderUpdate } from "../../models/delegates/UpdateServiceLeaderDelegate";
import { ServiceContactOrchestrator, ServiceContactUpdateResult } from "../../orchestrators/ServiceContactOrchestrator";
import { MutationResultType } from "../../models/remoteMutation";
import { updateContactInList } from "../../utils/serviceContactUtil";
import { fixFormatServiceId } from "../../utils/urlUtil";

interface SingleServiceContactsContainerProps {
  nameRip: string;
  userAbilities: UserAbilities
}

const orchestrator: ServiceContactOrchestrator = new ServiceContactOrchestrator();

export const SingleServiceContactsContainer: FunctionComponent<SingleServiceContactsContainerProps> = (props) => {
  const [contactsForService, setContactsForService] = useState<ServiceContactsVO[]>([]);
  const serviceContactsSwr = useJsonLoader<ServiceContactsVO[]>(async () => {
    const dao = new ServiceContactsDao();
    const dtos = (await dao.getForAllPartitions(props.nameRip)) ?? [];
    return dtos.map(parseServiceContactsDto);
  }, "single service contacts " + props.nameRip);

  useEffect(() => {
    setContactsForService(serviceContactsSwr.response.data ?? []);
  }, [serviceContactsSwr.response.data])

  const onRefreshServiceContact = useCallback(() => {
    (serviceContactsSwr.response as responseInterface<any, any>).revalidate?.();
  }, [serviceContactsSwr.response]);

  const contact_onUpdateLeader = useCallback(async (contact: ServiceContactsVO, update: IServiceLeaderUpdate): Promise<ServiceContactUpdateResult> => {
    const res: ServiceContactUpdateResult = await orchestrator.updateLeader(contact, update);
    if (res.type === MutationResultType.Success) {
      const updated = updateContactInList(res.entity, contactsForService);
      setContactsForService(updated);
    }
    return res;
  }, [
    contactsForService,
    setContactsForService,
    onRefreshServiceContact
  ])

  return (
    <SingleServiceContacts contacts={contactsForService}
                           loading={serviceContactsSwr.response.isValidating}
                           userAbilities={props.userAbilities}
                           editServiceContactsSubmit={addOrUpdateServiceContact}
                           onUpdateLeader={contact_onUpdateLeader}
                           onRefreshServiceContact={onRefreshServiceContact}
                           nameRip={fixFormatServiceId(props.nameRip)}/>
  );

};
