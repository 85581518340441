import { hydrateModel } from "../../utils/modelUtil";

export enum ChangeType {
  ATTRIBUTE,
  RECORD_OPERATION,
}

export class ChangeVO {
  resourceId: string = "";
  attribute: string = "";
  target: string = ""; // this can be either Attribute or Holistic Record Operation
  type: ChangeType = ChangeType.ATTRIBUTE;
  timeChanged: string = "";
  whoChanged?: string;
  reasonForChange?: string;
  oldValue?: any;
  newValue?: any;
  reason?: string;

  constructor(from?: Partial<ChangeVO>) {
    hydrateModel(from, this);
  }
}

export class ReducedChangeVO {
  resourceId: string = "";
  timeChanged: string = "";
  changes: ChangeVO[] = [];

  whoChanged?: string;
  reasonForChange?: string;
  reason?: string;

  constructor(from?: Partial<ReducedChangeVO>) {
    hydrateModel(from, this);
  }
}
