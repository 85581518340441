import { Displayable } from "@amzn/api-parity-react-component/lib/models/Displayable";
import * as React from "react";
import { Link } from "@amzn/awsui-components-react-v3";
import { hydrateModel } from "../../../utils/modelUtil";

class SingleServiceContactsFieldData {
  label: Displayable = "";
  info?: Displayable = undefined;

  constructor(source: Partial<SingleServiceContactsFieldData>) {
    hydrateModel(source, this);
  }
}

// Leadership
export const gmField = new SingleServiceContactsFieldData({
  label: "GM",
  info: <> The General Manager (GM) is manually set by the Global Product Expansion (GPE) team. See <Link external href="https://w.amazon.com/bin/view/Region_Services/Service_Expansion_and_Product_Parity/Contact_Us/"> more </Link> for contacting GPE to update it. </>
})

export const vpField = new SingleServiceContactsFieldData({
  label: "VP",
  info: <>The Vice President (VP) is manually set by the Global Product Expansion (GPE) team. See <Link external href="https://w.amazon.com/bin/view/Region_Services/Service_Expansion_and_Product_Parity/Contact_Us/"> more </Link> for contacting GPE to update it.</>
})

export const leadershipChainField = new SingleServiceContactsFieldData({
  label: "Leadership Chain",
  info: "The Leadership Chain is automatically determined by the service's CTI. The workflow to build the Leadership Chain begins with a CTI lookup of the Resolver Group, from the Resolver Group we pull the 'Manager Login.' Using the 'Manager Login' we walk the chain in LDAP from L7 through L10. The leadership chain is updated every 24 hours."
})

// Team Point of Contacts
export const primaryBuildPocField = new SingleServiceContactsFieldData({
  label: "Primary Build POC",
  info: <>The Primary Build POC is an SDM/TPM/PM from the service team who is responsible for coordinating and leading build activities for new region builds. This POC is used by automated emailers including the <Link external href="https://w.amazon.com/bin/view/AWSRegionBuildEngineering/RBI/CPN/"> Critical Path Notifications (CPNs) </Link> and <Link external href="https://w.amazon.com/bin/view/AWSRegionBuildEngineering/RBI/Periodic_Notifications/"> Periodic Notifications (PNs) </Link>.</>
})

export const secondaryBuildPocField = new SingleServiceContactsFieldData({
  label: "Secondary Build POC",
  info: <>The Secondary Build POC is an SDM/TPM/PM from the service team who is also responsible for coordinating and leading build activities for new region builds. This POC is used by automated emailers including the <Link external href="https://w.amazon.com/bin/view/AWSRegionBuildEngineering/RBI/CPN/"> Critical Path Notifications (CPNs) </Link> and <Link external href="https://w.amazon.com/bin/view/AWSRegionBuildEngineering/RBI/Periodic_Notifications/"> Periodic Notifications (PNs) </Link>.</>
})

export const buildTPMField = new SingleServiceContactsFieldData({
  label: "Build TPM",
  info: <>The Build TPM is the single threaded owner and the main point-of-contact for service teams and all their region expansion activities. Typically, the Build TPM is the Region Build Team (RBT) in Region Services <Link external href="https://w.amazon.com/bin/view/AWSRegionBuilds/ServiceManagers/"> (see more) </Link>, however the Build TPM can also be outside of Region Services, like in Networking.</>
})

export const azServiceOwnerField = new SingleServiceContactsFieldData({
  label: "AZ Service Owner",
  info: "The AZ Service Owner is responsible for building the service in new AZs."
})

export const localZoneServiceOwnerField = new SingleServiceContactsFieldData({
  label: "Local Zone Service Owner",
  info: "The Local Zone Service Owner is responsible for building the service in new local zones."
})

export const vpceBuildOwnerField = new SingleServiceContactsFieldData({
  label: "VPCE Build Owner",
  info: "The VPCE Build Owner is responsible for building the VPCE portion of the service."
})

// Emails
export const notificationAliasField = new SingleServiceContactsFieldData({
  label: "Notification Alias",
  info: "The Notification Alias is the service team's email distribution used for region build activities and escalations."
})

export const ripContactsField = new SingleServiceContactsFieldData({
  label: "RIP Contacts",
  info: "The RIP Contacts are email addresses (individual aliases or team email distros) used for notifying RIP Status Changes (eg. a status change approval request from 'IA' to 'GA' made on the Regions Website). Additionally, the Safe Dynamic Config (SDC) website requires a RIP Contact to be set before editing dynamic config (Note: SDC syncs RIP contacts every 15 min)."
})

// Operations
export const ctiField = new SingleServiceContactsFieldData({
  label: "CTI",
  info: <>The Category/Type/Item (CTI) is automatically pulled from RIP. To edit a service's CTI, please visit the <Link external href="https://regions.corp.amazon.com/services/"> RMS Website</Link>.</>
})

export const wikiField = new SingleServiceContactsFieldData({
  label: "Wiki",
  info: "The Wiki is a service team's main wiki URL or wiki URL for region build activities."
})

export const oncallTeamField = new SingleServiceContactsFieldData({
  label: "Oncall Team",
  info: <>The Oncall Team is the service team's Oncall team defined <Link external href="https://oncall.corp.amazon.com/"> here </Link>.</>
})

export const simGuidField = new SingleServiceContactsFieldData({
  label: "SIM GUID",
  info: "The SIM GUID is the SIM Folder GUID for the service team's region build issues/problems/allowlisting tickets."
})

export const inRmsBuildGraphField = new SingleServiceContactsFieldData({
  label: "In RMS Build Graph",
  info: <>The In RMS Build Graph is a boolean that states if a service is present in the COMMERCIAL_PARTITION_TEMPLATE build graph in RMS. See RMS FAQ for more information about <Link external href="https://w.amazon.com/bin/view/AWSRegionBuildEngineering/RegionsManagementService/Documentation/FAQ#HTemplates"> build templates</Link>. Note, this field is automatically updated from RMS every hour and cannot be hand-managed through this page.</>
})

export const opsDashboardField = new SingleServiceContactsFieldData({
  label: "Operational Dashboard",
  info: "The Operational Dashboard field is a URL link to a service's ops / metrics dashboards."
})
