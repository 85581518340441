import { Optional } from "./types/Optional";
import { FilterFn } from "@amzn/api-parity-react-component/lib/utils/collectionUtil";
import { TreeItem } from "./TreeItem";
import { ISortComparator } from "@amzn/api-parity-react-component/lib/models/SortBy";
import { sort } from "@amzn/api-parity-react-component/lib/utils/sortUtil";

export enum MatchedState {
  NoFilter = "NoFilter",
  MatchSelf = "MatchSelf",
  /**
   * Match does not occur at self level, but one or some of its descendants matched
   */
  MatchDescendant = "MatchDescendant",
  NoMatch = "NoMatch",
}


/**
 *  which also supports cascaded matching
 */
export class FilterableTreeItem extends TreeItem {

  private _sort: Optional<readonly ISortComparator<FilterableTreeItem>[]>;
  private _matchedState: MatchedState = MatchedState.NoFilter;

  public get matchState(): MatchedState {
    return this._matchedState;
  }

  private _visibleChildren?: FilterableTreeItem[];

  /**
   * Children which are childItems applied with filter
   */
  get matchedChildItems(): Optional<readonly FilterableTreeItem[]> {
    const unsortedItems = this._visibleChildren ?? this.childItems as FilterableTreeItem[];
    return this.getSorted(unsortedItems);
  }

  get childItems(): Optional<readonly FilterableTreeItem[]> {
    // @ts-ignore
    return this.getSorted(this._childItems as readonly FilterableTreeItem[]);
  }

  getSorted(items: Optional<readonly FilterableTreeItem[]>) {
    if (!items) {
      return;
    }

    if (!this._sort) {
      return items;
    }

    return sort(items, this._sort);
  }


  /**
   * Apply filter to the item and its descendants if necessary
   * When the filter match an item, the item's matchState would be MatchSelf, and the rest of its descendants would
   * have NoFilter matchState
   *
   * When the filter does not match an item, but it matches at least one of its descendants, the matched
   * descendants' parents (and ascendants) would have MatchDescendant matchState
   *
   * When a filter does not match an item or any of its descendant, the item and its descendants would
   * all have noMatch status
   *
   * @param filter
   */
  applyFilter(filter: FilterFn<FilterableTreeItem>): MatchedState {
    let matched: boolean = filter(this);
    if (!this.childItems || !this.childItems.length) {
      this._matchedState = (matched) ? MatchedState.MatchSelf : MatchedState.NoMatch;
      return this._matchedState;
    }

    if (matched) {
      this.resetChildFilter();
      this._matchedState = MatchedState.MatchSelf;
      return MatchedState.MatchSelf;
    }

    this._visibleChildren = [];
    this._matchedState = MatchedState.NoMatch;
    for (const child of this.childItems) {
      const childMatched: MatchedState = child.applyFilter(filter);
      if (childMatched !== MatchedState.NoMatch) {
        this._matchedState = MatchedState.MatchDescendant;
        this._visibleChildren.push(child);
      }
    }
    return this._matchedState;
  }


  applySort(sort: Optional<readonly ISortComparator<FilterableTreeItem>[]>): void {
    this._sort = sort;
    this.childItems?.forEach((child) => {
      child.applySort(this._sort);
    });
  }

  resetSort(): void {
    this.applySort(undefined);
  }

  addChild(item: FilterableTreeItem) {
    super.addChild(item);
    item.applySort(this._sort);
  }

  /**
   * Reset children's filter to NoFilter
   */
  resetChildFilter(): void {
    this._matchedState = MatchedState.NoFilter;
    if (!this.childItems || !this.childItems.length) {
      return;
    }

    this._visibleChildren = this.childItems as FilterableTreeItem[];
    for (const child of this._visibleChildren) {
      child.resetFilter();
    }
  }

  /**
   * Reset self and children's filter to NoFilter
   */
  resetFilter(): void {
    this._matchedState = MatchedState.NoFilter;
    this.resetChildFilter();
  }


}



