import * as React from "react";
import { FunctionComponent, useEffect, useState } from "react";
import { FeatureParityLoaderProps } from "./FeatureParityLoader.types";
import { Optional } from "../../../../models/types/Optional";
import { IRegionMetadataDto } from "@amzn/api-parity-react-component/lib/models/dtos/recon";
import { AwsServiceVO } from "@amzn/api-parity-react-component";
import { IFeatureParityRaw } from "../../../../daos/types/FeaturesDaoTypes";
import { IParityItem } from "../../../../daos/types/IGetParityResponse";
import { IFeatureParityDataInput } from "../../../../models/featureParityBootstrap";
import memoize from "micro-memoize";
import { useJsonLoader } from "../../../../utils/containerUtil";
import { IContextualSwrResponse } from "../../../../models/types/IContextualSwrResponse";
import { isFullyLoaded } from "../../../../utils/loadingStatusUtil";
import { DetailLoadingVO } from "../../../../models/vos/DetailLoadingVO";
import { getSwrStatus } from "../../../../utils/swrUtil";
import { loadStateToLoadingStatus } from "../../../../utils/modelUtil";
import { DetailLoader } from "../../../DetailLoader/DetailLoader";

function combineDataImpl(
  features: Optional<IFeatureParityRaw>,
  serviceParity: Optional<IParityItem[]>,
  regions: Optional<IRegionMetadataDto[]>,
  featureParityOverride: Optional<string>,
  services: Optional<AwsServiceVO[]>,
): Partial<IFeatureParityDataInput> {
  return {
    features,
    serviceParity,
    regions,
    services,
    featureParityOverride
  };
}

const combineData = memoize(combineDataImpl);

/**
 * Feature Parity involves loading data from a few API's
 * This component displays the status of each API loading
 * and dispatch onDataLoaded when all data are loaded
 * @param props
 * @constructor
 */
export const FeatureParityLoader: FunctionComponent<FeatureParityLoaderProps> = (props) => {
  const {
    serviceList,
    serviceListLoadState,
    onDataLoaded,
  } = props;

  const serviceParitySwr = useJsonLoader<IParityItem[]>(() => props.getServiceParities(), "Service Launch Parities");
  const regionListSwr = useJsonLoader<IRegionMetadataDto[]>(() => props.getRegions(), "Regions");
  const featuresSwr = useJsonLoader<IFeatureParityRaw>(() => props.getFeatures(), "Features, Tagged Features and Parity Information");
  const featuresParityOverrideSwr = useJsonLoader<string>(() => props.getFeatureParityOverrides(), "Features Parity Overrides");

  const allLoaders: IContextualSwrResponse<any>[] = [
    serviceParitySwr,
    regionListSwr,
    featuresSwr,
    featuresParityOverrideSwr,
  ];

  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const [data, setData] = useState<Optional<Partial<IFeatureParityDataInput>>>();

  useEffect(() => {
    const allDataLoaded: boolean = isFullyLoaded(allLoaders, [serviceListLoadState]);
    setIsLoaded(allDataLoaded);

    if (allDataLoaded) {
      setData(combineData(
        featuresSwr.response.data,
        serviceParitySwr.response.data,
        regionListSwr.response.data,
        featuresParityOverrideSwr.response.data,
        serviceList
      ));
    } else {
      setData(undefined);
    }
  }, [
    serviceParitySwr,
    regionListSwr,
    featuresSwr,
    featuresParityOverrideSwr,
    serviceListLoadState,
    serviceList
  ]);

  useEffect(() => {
    if (data && onDataLoaded) {
      onDataLoaded(data as IFeatureParityDataInput);
    }
  }, [data, onDataLoaded]);

  let content: React.ReactElement;
  if (!isLoaded) {
    const loadingStatus: DetailLoadingVO[] = allLoaders.map((loader) => {
      return {
        label: loader.context,
        status: getSwrStatus(loader.response),
        error: loader.response.error?.message,
      };
    });

    loadingStatus.push({
      label: "All Services",
      status: loadStateToLoadingStatus(serviceListLoadState),
    });

    content = <DetailLoader items={loadingStatus} />;
  } else {
    content = <React.Fragment />
  }

  return content;
};
