import * as React from "react";
import { FunctionComponent } from "react";
import { LaunchLinkProps } from "./LaunchLink.types";
import { Link } from "react-router-dom";

export const LaunchLink: FunctionComponent<LaunchLinkProps> = (props) => {
  return (
    <Link to={{
      pathname: `/alm/launches/${props.id}`,
      state: {
        launch: props.launch
      }
    }}>
      <span>{props.displayName}</span>
    </Link>
  );
};
