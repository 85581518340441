import { LoadingStatus } from "../models/vos/DetailLoadingVO";
import { IEssentialSwrResponse } from "../models/types/IContextualSwrResponse";
import { SWRInfiniteConfigInterface, useSWRInfinite } from "swr";
import { useEffect, useMemo, useRef } from "react";
import { PaginationResponse } from "../daos/daoUtil";
import { last } from "./objectUtil";

export function getSwrStatus(swr: IEssentialSwrResponse<any, any>): LoadingStatus {
  if (swr.isValidating) {
    return LoadingStatus.Loading;
  }

  if (swr.error) {
    return LoadingStatus.Error;
  }

  if (swr.data) {
    return LoadingStatus.Success;
  }

  return LoadingStatus.NotStarted;
}

export const useLoadInfinitePages = <T, >(
  key: string,
  fetcher: (path: string, token: string | undefined) => any | undefined,
  config?: SWRInfiniteConfigInterface
) => {
  const isFetching = useRef(false);

  const { data, error, isValidating, mutate, revalidate, size, setSize } = useSWRInfinite<PaginationResponse<T>>(
    (index, previousPage) => {
      if (previousPage && !previousPage.nextToken) return null;

      if (index === 0) return [key];

      return [key, previousPage?.nextToken];
    },
    async (request, token) => {
      let response: PaginationResponse<T>;
      try {
        isFetching.current = true;
        response = await fetcher(request, token);
        if (isFetching.current) isFetching.current = false;
      } catch (e) {
        if (isFetching.current) isFetching.current = false;
        throw e;
      }
      return response;
    },
    {
      initialSize: 1,
      ...config,
    }
  )

  const flat = useMemo(
    () =>
      data
        ? ([] as T[]).concat.apply(
          [],
          data?.map((item) => item.items) ?? []
        )
        : undefined
    ,
    [data]
  );

  const lastPage = last(data);
  const loadingMore = isValidating || lastPage?.nextToken !== undefined;

  useEffect(() => {
    if (!isFetching.current && lastPage?.nextToken) {
      isFetching.current = true;
      setSize(size + 1).catch(console.error);
    }
  }, [lastPage?.nextToken, setSize, size]);

  return {
    data: flat,
    pages: data,
    error,
    isValidating,
    mutate,
    revalidate,
    size,
    isFetching,
    lastPage,
    loadingMore,
  }
}
