import * as React from "react";
import { useCallback, useEffect, useState } from "react";
import useSWR from "swr";
import { getSwrConfig } from "../../../daos/swrUtil";
import { RmsDao } from "../../../daos/RmsDao";
import { PlanDao } from "../../../daos/PlanDao";
import { ReconFeatureTable } from "../ReconFeatureTable";
import {
  addLongName,
  convertComponentServiceResponse,
  filterNestedComponents,
  filterNotOwnServiceComponents,
  getSelectedComponentFeatures,
  getSelectedServiceFeatures,
} from "../../../parsers/componentFeatureParser"
import { ComponentFeatureDao } from "../../../daos/ComponentFeatureDao"
import { ComponentFeatureVO } from "../../../models/vos/ComponentFeatureVO"
import { AwsServiceVO } from "@amzn/api-parity-react-component";
import { LoadStateEnum } from "../../../models/LoadStateEnum";
import { useSelector } from "react-redux";
import { serviceListSelector, serviceLoadStateSelector } from "../../../redux/selectors/serviceSelector";
import { componentsToAdd, componentsToDelete } from "../../../utils/SingleServiceFeatureUtils";
import { CommentsDao } from "../../../daos/CommentsDao";
import { FeatureInRegionCommentVO } from "../../../models/vos/FeatureInRegionCommentVO";
import {
  createFeatureIdAndRegionToCommentMap,
  getCommentFromMap,
} from "../../../utils/featureUtil";
import { Optional } from "../../../models/types/Optional";

const EMPTY_ARRAY = []

export const SingleServiceFeatureContainer = (props) => {
  const { completedRegions: starterRegions, serviceName, displayName, userAbilities, partitionToRegions } = props;

  const featuresSwr = useSWR(`features_${serviceName}`, () => {
    const rmsDao = new RmsDao();
    return rmsDao.getAllFeatureDataForService(serviceName);
  }, getSwrConfig());

  const reconComponentSwr = useSWR(`components_${serviceName}`, () => {
    const planDao = new PlanDao();
    return planDao.getSingleServiceComponentsWithInstanceData(serviceName);
  }, getSwrConfig());

  const allRmsComponentsSwr = useSWR(`allRmsComponents_${serviceName}`, () => {
    const rmsDao = new RmsDao();
    return rmsDao.getComponentsForService(serviceName);
  }, getSwrConfig());

  const commentsSwr = useSWR(`featureInRegionComments_${serviceName}`, () => {
    return userAbilities.canReadDates
      ? new CommentsDao().getFeatureInRegionCommentsByService(serviceName)
      : [];
  }, getSwrConfig());

  const componentsLoading = reconComponentSwr.isValidating;
  const featuresLoading = featuresSwr.isValidating;
  const rmsComponentsLoading = allRmsComponentsSwr.isValidating;

  let featureData = featuresSwr.data || [];
  let reconComponents = reconComponentSwr.data || [];
  let allComponents = convertComponentServiceResponse(allRmsComponentsSwr.data || []);
  const comments: FeatureInRegionCommentVO[] = commentsSwr.data || EMPTY_ARRAY;
  const [featureIdAndRegionToComment, setFeatureIdAndRegionToComment] = useState(new Map<string, FeatureInRegionCommentVO>());

  useEffect(() => {
    setFeatureIdAndRegionToComment(createFeatureIdAndRegionToCommentMap(comments))
  }, [comments])

  const getComment = useCallback((feature: string, region: string): Optional<FeatureInRegionCommentVO> => {
    return getCommentFromMap(feature, region, featureIdAndRegionToComment);
  }, [featureIdAndRegionToComment])

  const allServicesLoadingState: LoadStateEnum = useSelector(serviceLoadStateSelector);
  let allServices = filterNotOwnServiceComponents(useSelector(serviceListSelector), serviceName);
  allServices = filterNestedComponents(allServices);

  // The longName is missing from reconComponents with a parity 0%, so we add them in from the rmsComponents
  addLongName(reconComponents, allComponents)

  const allFeatureData = featureData.concat(reconComponents)

  const selectedComponents = getSelectedComponentFeatures(reconComponents, allComponents);
  const selectedServices = getSelectedServiceFeatures(reconComponents, allServices, serviceName);

  const onComponentModalSubmit = useCallback((modalSelectedComponents: ComponentFeatureVO[], modalSelectedServices: AwsServiceVO[]) => {
    let toDelete: ComponentFeatureVO[] = componentsToDelete(modalSelectedComponents, modalSelectedServices, selectedComponents, selectedServices, serviceName);
    let toPost: ComponentFeatureVO[] = componentsToAdd(modalSelectedComponents, modalSelectedServices, selectedComponents, selectedServices, serviceName);

    const componentFeatureDao = new ComponentFeatureDao();

    return componentFeatureDao.deleteComponentFeatures(toDelete)
      .then(() => componentFeatureDao.postComponentFeatures(toPost)
          .then(() => {
          }, errorMessage => console.error(errorMessage)),
        errorMessage => console.error(errorMessage));
  }, [selectedComponents, selectedServices, serviceName]);

  const onSuccessfulComponentModalSubmit = useCallback(() => {
    reconComponentSwr.revalidate().then(_ => {}, console.error);
    featuresSwr.revalidate().then(_ => {}, console.error);
    allRmsComponentsSwr.revalidate().then(_ => {}, console.error);
  }, [allRmsComponentsSwr, featuresSwr, reconComponentSwr]);

  const onCommentSubmit = useCallback(async (comments: FeatureInRegionCommentVO[]): Promise<boolean> => {
    const commentsDao = new CommentsDao();
    await commentsDao.postFeatureInRegionComment(comments).then(() => {
      commentsSwr.revalidate();
    });
    return Promise.resolve(true);
  }, [commentsSwr])

  const onCommentResolve = useCallback(async (comment: FeatureInRegionCommentVO): Promise<boolean> => {
    const commentsDao = new CommentsDao();
    await commentsDao.resolveFeatureInRegionComment(comment).then(() => {
      commentsSwr.revalidate();
    });
    return Promise.resolve(true);
  }, [commentsSwr])

  return <ReconFeatureTable
    allComponents={allComponents}
    selectedComponents={selectedComponents}
    selectedServices={selectedServices}
    allFeatureData={allFeatureData}
    allServices={allServices}
    starterRegions={starterRegions}
    partitionToRegions={partitionToRegions}
    serviceName={serviceName}
    displayName={displayName}
    loading={featuresLoading || componentsLoading || rmsComponentsLoading}
    allServicesLoadingState={allServicesLoadingState}
    onComponentModalSubmit={onComponentModalSubmit}
    onSuccessfulComponentModalSubmit={onSuccessfulComponentModalSubmit}
    getComment={getComment}
    onCommentSubmit={onCommentSubmit}
    onCommentResolve={onCommentResolve}
    userAbilities={userAbilities}
  />
}
