import { immerable } from "immer";
import { hydrateModel } from "../../utils/modelUtil";
import { DateLike } from "../DateLike";
import { Nullable } from "../types/Nullable";
import { Optional } from "../types/Optional";
import { RipStatus } from "../types/RipStatus";

export class RegionMetadataVO {
  [immerable] = true;

  airportCode: string = "";
  nameLong: string = "";
  status: Optional<RipStatus> = undefined;
  partition: string = "";
  description: string = "";
  date: Nullable<DateLike> = undefined;
  codename?: string;
  accessibilityAttributes?: string[];

  constructor(from?: Partial<RegionMetadataVO>) {
    hydrateModel(from, this);
  }
}
