import * as React from "react";
import { FunctionComponent, useCallback, useEffect, useState } from "react";
import { Box, Container, FormField, Grid, Header, Link, SpaceBetween } from "@amzn/awsui-components-react-v3";
import { useWatch } from "react-hook-form";
import { Displayable } from "@amzn/api-parity-react-component/lib/models/Displayable";
import { getDataTestIdProp } from "../../../../utils/testUtil";
import { IFormFieldData } from "../../../common/FormTypes";
import { IndividualFormFieldWithController } from "../../../IndividualFormFieldWithController/IndivudalFormFieldWithController";
import { FormFieldTypes } from "../../../IndividualFormFieldWithController/IndividualFormFieldWithController.types";
import { ILaunchWizardDetailsFormProps, ISelectOptions } from "../LaunchWizard.types";
import { createLaunchPropertiesFormFields } from "../LaunchWizardFormFields";
import { Optional } from "../../../../models/types/Optional";
import { EventDateVO } from "../../../../models/vos/EventDateVO";
import { WarningText } from "../LaunchWizard";

export const DetailsTestIds = {
  warningDateText: "-warningDateText"
}

export const Details: FunctionComponent<ILaunchWizardDetailsFormProps> = (props) => {
  const { control, errors, blockedDates } = props;
  const [dateWarning, setDateWarning] = useState<Optional<string>>()
  const getFormField = useCallback((formField: IFormFieldData, fieldType: FormFieldTypes, defaultValue?: string | ISelectOptions, info?: Displayable, label?: Displayable, placeholder?: string) => {
    return <IndividualFormFieldWithController
      control={control}
      errors={errors}
      formField={formField}
      defaultValue={defaultValue}
      popoverInfo={info}
      fieldType={fieldType}
      label={label}
      placeholder={placeholder}
      {...getDataTestIdProp(props)}
    />;
  }, [control, errors, props]);

  const gridDefinition = [
    { colspan: 6 },
    { colspan: 6 },
    { colspan: 6 },
    { colspan: 6 },
    { colspan: 6 },
    { colspan: 6 },
    { colspan: 6 }];

  const launchDateLabel =
    <>
      <b>Note: All dates are currently in PST. </b>
      You will be required to choose another date if it lands on a blocked date or restricted date unless
      VP approval has been acquired. If you wish to continue selecting a blocked/restricted date, please
      review the <Link external fontSize="body-s"
                       href="https://w.amazon.com/bin/view/AWS/ChangeControlPolicy/">
      AWS Change Control Wiki </Link> to review the required approvals.
    </>

  const expectedLaunchDate: Optional<string> = useWatch({
    control,
    name: createLaunchPropertiesFormFields.expectedLaunchDate.id
  });
  useEffect(() => {
    const selectedDateInBlockedDates: Optional<EventDateVO> = blockedDates.find((entry) => entry.date === expectedLaunchDate)
    if (selectedDateInBlockedDates) {
      setDateWarning("Date conflicts with existing event(s): " + selectedDateInBlockedDates.events.map((event) => event.name).join(", "))
    } else {
      setDateWarning(undefined)
    }
  }, [blockedDates, expectedLaunchDate])

  createLaunchPropertiesFormFields.ripName.options = props.services?.map((service) => ({ value: service.id }))

  return (
    <SpaceBetween size="xxl">

      <Container header={<Header variant="h2"> Launch overview </Header>}>
        <SpaceBetween direction="vertical" size="l">
          <FormField label="Product" {...getDataTestIdProp(props, "-product")}>
            {props.serviceName}
          </FormField>
          <Grid gridDefinition={gridDefinition}>
            {getFormField(createLaunchPropertiesFormFields.launchIteration, FormFieldTypes.SELECT_ENTRY, control.getValues().launchIteration)}
            {getFormField(createLaunchPropertiesFormFields.launchType, FormFieldTypes.SELECT_ENTRY, control.getValues().launchType)}
            {getFormField(createLaunchPropertiesFormFields.launchName, FormFieldTypes.INPUT_ENTRY, control.getValues().launchName)}
            {getFormField(createLaunchPropertiesFormFields.codeName, FormFieldTypes.INPUT_ENTRY, control.getValues().codeName)}
            {getFormField(createLaunchPropertiesFormFields.launchDescription, FormFieldTypes.TEXT_ENTRY, control.getValues().launchDescription)}
            {getFormField(createLaunchPropertiesFormFields.ripName, FormFieldTypes.AUTOSUGGEST_ENTRY, control.getValues().ripName)}
          </Grid>
        </SpaceBetween>
      </Container>

      <Container header={<Header variant="h2"> Launch date </Header>}>
        <SpaceBetween direction="vertical" size="xs">
          {getFormField(createLaunchPropertiesFormFields.expectedLaunchDate, FormFieldTypes.DATE_ENTRY, control.getValues().expectedLaunchDate, undefined, launchDateLabel)}
          {dateWarning && <Box {...getDataTestIdProp(props, DetailsTestIds.warningDateText)}><WarningText>{dateWarning}</WarningText></Box>}
        </SpaceBetween>
      </Container>

      <Container header={<Header variant="h2"> Launch properties </Header>}>
        <SpaceBetween direction="vertical" size="l">
          <Grid gridDefinition={gridDefinition}>
            {getFormField(createLaunchPropertiesFormFields.launchScope, FormFieldTypes.SELECT_ENTRY, control.getValues().launchScope)}
            {getFormField(createLaunchPropertiesFormFields.goalType, FormFieldTypes.SELECT_ENTRY, control.getValues().goalType)}
            {getFormField(createLaunchPropertiesFormFields.tier, FormFieldTypes.SELECT_ENTRY, control.getValues().tier)}
            {getFormField(createLaunchPropertiesFormFields.goalLink, FormFieldTypes.INPUT_ENTRY, control.getValues().goalLink)}
            {getFormField(createLaunchPropertiesFormFields.disclosureLevel, FormFieldTypes.SELECT_ENTRY, control.getValues().disclosureLevel)}
            {getFormField(createLaunchPropertiesFormFields.audience, FormFieldTypes.SELECT_ENTRY, control.getValues().audience)}
            {getFormField(createLaunchPropertiesFormFields.confidential, FormFieldTypes.SELECT_ENTRY, control.getValues().confidential)}
          </Grid>
        </SpaceBetween>
      </Container>

      <Container header={<Header variant="h2"> Launch contacts </Header>}>
        <SpaceBetween direction="vertical" size="l">
          <Grid gridDefinition={gridDefinition}>
            {getFormField(createLaunchPropertiesFormFields.productManager, FormFieldTypes.INPUT_ENTRY, control.getValues().productManager)}
            {getFormField(createLaunchPropertiesFormFields.productMarketingManager, FormFieldTypes.INPUT_ENTRY, control.getValues().productMarketingManager)}
            {getFormField(createLaunchPropertiesFormFields.engineeringLead, FormFieldTypes.INPUT_ENTRY, control.getValues().engineeringLead)}
          </Grid>
        </SpaceBetween>
      </Container>

      <Container header={<Header variant="h2"> Launch survey </Header>}>
        <SpaceBetween direction="vertical" size="l">
          <Grid gridDefinition={gridDefinition}>
            {getFormField(createLaunchPropertiesFormFields.namingBoolean, FormFieldTypes.SELECT_ENTRY, control.getValues().namingBoolean)}
            {getFormField(createLaunchPropertiesFormFields.prfaqBoolean, FormFieldTypes.SELECT_ENTRY, control.getValues().prfaqBoolean)}
            {getFormField(createLaunchPropertiesFormFields.pricingBoolean, FormFieldTypes.SELECT_ENTRY, control.getValues().pricingBoolean)}
            {getFormField(createLaunchPropertiesFormFields.regulatoryBoolean, FormFieldTypes.SELECT_ENTRY, control.getValues().regulatoryBoolean)}
          </Grid>
        </SpaceBetween>
      </Container>

    </SpaceBetween>
  );
}
