import { Box, PropertyFilterProps, TableProps } from "@amzn/awsui-components-react-v3";
import { ProductLink } from "./ProductLink/ProductLink";
import * as React from "react";
import { displayOr } from "../../utils/displayUtil";
import { ProductTableVM } from "./ProductsList.types";
import User from "../common/User";

export const FILTERING_PROPERTIES: PropertyFilterProps.FilteringProperty[] = [
  {
    key: "name",
    operators: [":"],
    propertyLabel: "Product Name",
    groupValuesLabel: "Names"
  },
  {
    key: "productApprovedBy",
    operators: ["=", "!="],
    propertyLabel: "Approved by",
    groupValuesLabel: "Approved by",
  },
  {
    key: "productApprovalDate",
    operators: ["=", "!=", ">", "<"],
    propertyLabel: "Approval date",
    groupValuesLabel: "Approval dates",
  },
  {
    key: "audience",
    operators: ["=", "!="],
    propertyLabel: "Audience",
    groupValuesLabel: "Audiences",
  },
  // {
  //   key: "blueprintUid",
  //   operators: ["="],
  //   propertyLabel: "Blueprint id",
  //   groupValuesLabel: "Blueprint ids",
  // },
  {
    key: "codename",
    operators: ["=", "!=", ":"],
    propertyLabel: "Codename",
    groupValuesLabel: "Codenames",
  },
  // {
  //   key: "confidential",
  //   operators: ["=", "!="],
  //   propertyLabel: "Confidential",
  //   groupValuesLabel: "Confidential",
  // },
  {
    key: "description",
    operators: [":"],
    propertyLabel: "Description",
    groupValuesLabel: "Description"
  },
  {
    key: "parentProductId",
    operators: ["="],
    propertyLabel: "Parent id",
    groupValuesLabel: "Parent ids"
  },
  {
    key: "category",
    operators: ["=", "!="],
    propertyLabel: "Product category",
    groupValuesLabel: "Product categories",
  },
  {
    key: "cti",
    operators: [":"],
    propertyLabel: "Product CTI",
    groupValuesLabel: "Product CTIs"
  },
  {
    key: "id",
    operators: [":"],
    propertyLabel: "Product id",
    groupValuesLabel: "Product ids",
  },
  {
    key: "state",
    operators: ["=", "!="],
    propertyLabel: "Product state",
    groupValuesLabel: "Product states",
  },
  {
    key: "type",
    operators: ["=", "!="],
    propertyLabel: "Product type",
    groupValuesLabel: "Product types",
  },
  {
    key: "ripId",
    operators: ["=", "!="],
    propertyLabel: "RIP name",
    groupValuesLabel: "RIP names",
  },
  {
    key: "tags",
    operators: [":"],
    propertyLabel: "Tags",
    groupValuesLabel: "Tags",
  },
  {
    key: "gm",
    operators: ["=", "!="],
    propertyLabel: "GM",
    groupValuesLabel: "GM",
  },
  {
    key: "vp",
    operators: ["=", "!="],
    propertyLabel: "VP",
    groupValuesLabel: "VP"
  },
  {
    key: "primaryOwner",
    operators: ["=", ":"],
    propertyLabel: "Primary owner",
    groupValuesLabel: "Primary owners",
  },
  {
    key: "secondaryOwners",
    operators: ["=", ":"],
    propertyLabel: "Secondary owners",
    groupValuesLabel: "Secondary owners",
  },
];

// using type TableProps.ColumnDefinition<any>[]
//   until I can find a better replacement
export const COLUMN_DEFINITIONS: TableProps.ColumnDefinition<any>[] = [
  {
    id: "name",
    header: "Name",
    cell: (product: ProductTableVM) => (
      <ProductLink
        displayName={product.name ?? "No product name"}
        id={product.id}
        product={product.sourceItem}
      />
    ),
    sortingField: "name"
  },
  {
    id: "productApprovedBy",
    header: "Approved by",
    cell: (product: ProductTableVM) => displayOr(
      product.productApprovedBy,
      () => <User alias={product.productApprovedBy!}/>
    ),
    sortingField: "productApprovedBy",
  },
  {
    id: "productApprovalDate",
    header: "Approval date",
    cell: (product: ProductTableVM) => displayOr(product.productApprovalDate),
    sortingField: "productApprovalDate",
  },
  {
    id: "audience",
    header: "Audience",
    cell: (product: ProductTableVM) => displayOr(product.audience),
    sortingField: "audience"
  },
  // {
  //   id: "blueprintUid",
  //   header: "Blueprint id",
  //   cell: (product: ProductTableVM) => displayOr(product.blueprintUid),
  //   sortingField: "blueprintUid"
  // },
  {
    id: "codename",
    header: "Codename",
    cell: (product: ProductTableVM) => displayOr(product.codename),
    sortingField: "codename",
  },
  // {
  //   id: "confidential",
  //   header: "Confidential",
  //   cell: (product: ProductTableVM) => product.confidential,
  // },
  {
    id: "description",
    header: "Description",
    cell: (product: ProductTableVM) => displayOr(product.description),
  },
  {
    id: "parentProductId",
    header: "Parent id",
    cell: (product: ProductTableVM) => displayOr(product.parentProductId),
    sortingField: "parentProductId",
  },
  {
    id: "category",
    header: "Product category",
    cell: (product: ProductTableVM) => displayOr(product.category),
    sortingField: "category",
  },
  {
    id: "cti",
    header: "Product CTI",
    cell: (product: ProductTableVM) => displayOr(product.cti),
  },
  {
    id: "id",
    header: "Product id",
    cell: (product: ProductTableVM) => product.id,
    sortingField: "id",
  },
  {
    id: "state",
    header: "Product state",
    cell: (product: ProductTableVM) => displayOr(product.state),
    sortingField: "state",
  },
  {
    id: "type",
    header: "Product type",
    cell: (product: ProductTableVM) => displayOr(product.type),
    sortingField: "type",
  },
  {
    id: "ripId",
    header: "RIP name",
    cell: (product: ProductTableVM) => displayOr(product.ripId),
    sortingField: "ripId",
  },
  {
    id: "tags",
    header: "Tags",
    cell: (product: ProductTableVM) => displayOr(product.tags),
  },
  {
    id: "gm",
    header: "GM",
    cell: (product: ProductTableVM) => displayOr(
      product.gm,
      () => <User alias={product.gm!}/>
    ),
    sortingField: "gm"
  },
  {
    id: "vp",
    header: "VP",
    cell: (product: ProductTableVM) => displayOr(
      product.vp,
      () => <User alias={product.vp!}/>
    ),
    sortingField: "vp"
  },
  {
    id: "primaryOwner",
    header: "Primary owner",
    cell: (product: ProductTableVM) => displayOr(
      product.primaryOwner,
      () => <User alias={product.primaryOwner!}/>
    ),
    sortingField: "primaryOwner",
  },
  {
    id: "secondaryOwners",
    header: "Secondary owner(s)",
    cell: (product: ProductTableVM) => displayOr(
      product.secondaryOwners,
      () => <>
        {product.secondaryOwners?.split(",").map((item, index) =>
          <Box display="inline-block" padding={{ right: "s" }}>
            <User alias={item} key={index}/>
          </Box>
        )}
      </>
    ),
  },
];

export const DEFAULT_COLLECTION_PREFERENCES = {
  title: "Preferences",
  confirmLabel: "Confirm",
  cancelLabel: "Cancel",
  pageSizePreference: {
    title: "Select page size",
    options: [
      { value: 10, label: "10 items" },
      { value: 20, label: "20 items" },
      { value: 50, label: "50 items" }
    ]
  },
  visibleContentPreference: {
    title: "Select visible content",
    options: [
      {
        label: "Always visible",
        options: [
          {
            id: "name",
            label: "Name",
            editable: false
          },
        ]
      },
      {
        label: "Product properties",
        options: [
          { id: "productApprovedBy", label: "Approved by" },
          { id: "productApprovalDate", label: "Approval date" },
          { id: "audience", label: "Audience" },
          // { id: "blueprintUid", label: "Blueprint id" },
          { id: "codename", label: "Codename" },
          // { id: "confidential", label: "Confidential" },
          { id: "description", label: "Description" },
          { id: "parentProductId", label: "Parent id" },
          { id: "category", label: "Product category" },
          { id: "cti", label: "Product CTI" },
          { id: "id", label: "Product id" },
          { id: "state", label: "Product state" },
          { id: "type", label: "Product type" },
          { id: "ripId", label: "RIP name" },
          { id: "tags", label: "Tags" },
        ]
      },
      {
        label: "Product contacts",
        options: [
          { id: "gm", label: "GM" },
          { id: "vp", label: "VP" },
          { id: "primaryOwner", label: "Primary owner" },
          { id: "secondaryOwners", label: "Secondary owner(s)" },
        ]
      }
    ]
  }
}

export const DEFAULT_TABLE_PREFERENCES = {
  pageSize: 10,
  visibleContent: [
    "name",
    "primaryOwner",
    "ripId",
    "type",
    "vp",
  ]
};

export const DEFAULT_I18N_STRINGS = {
  filteringAriaLabel: "your choice",
  dismissAriaLabel: "Dismiss",
  filteringPlaceholder: "Search",
  groupValuesText: "Values",
  groupPropertiesText: "Properties",
  operatorsText: "Operators",
  operationAndText: "and",
  operationOrText: "or",
  operatorLessText: "Less than",
  operatorLessOrEqualText: "Less than or equal",
  operatorGreaterText: "Greater than",
  operatorGreaterOrEqualText:
    "Greater than or equal",
  operatorContainsText: "Contains",
  operatorDoesNotContainText: "Does not contain",
  operatorEqualsText: "Equals",
  operatorDoesNotEqualText: "Does not equal",
  editTokenHeader: "Edit filter",
  propertyText: "Property",
  operatorText: "Operator",
  valueText: "Value",
  cancelActionText: "Cancel",
  applyActionText: "Apply",
  allPropertiesLabel: "All properties",
  tokenLimitShowMore: "Show more",
  tokenLimitShowFewer: "Show fewer",
  clearFiltersText: "Clear filters",
  removeTokenButtonAriaLabel: () => "Remove token",
  enteredTextLabel: text => `Use: "${text}"`
};
