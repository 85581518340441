import { immerable } from "immer";

export class InstanceName {
  [immerable] = true;

  names: string[] = [];
  isVersioned: boolean = false;
  version: number = 0;

  constructor(instance?: Partial<InstanceName>) {
    if (instance) {
      Object.assign(this, instance);
    }
  }
}
