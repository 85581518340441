import React, { FunctionComponent } from "react";
import { Header, SpaceBetween } from "@amzn/awsui-components-react-v3";
import { ProductChildProductsProps } from "./ProductChildProducts.types";
import { editButton } from "../../ProductProperties/ProductProperties";
import { ProductsList } from "../../../ProductsList/ProductsList";

const ProductChildProductsHeader = ({ count, ...props }) => (
  <Header
    counter={"(" + count + ")"}
    actions={
      <SpaceBetween size="xs" direction="horizontal">
        {/* TODO (@jsoumya) Replacing ProductTabDropdown until the dropdown components are developed*/}
        {/*{!props.disabled && <ProductTabDropdown/>}*/}
        {!props.disabled && editButton(props.disabled)}
      </SpaceBetween>
    }
  >
    Child products
  </Header>
);

export const ProductChildProducts: FunctionComponent<ProductChildProductsProps> = (props) => {
  const { childProducts, loading, preferences, onPreferenceChange } = props;

  return (
    <ProductsList
      items={childProducts}
      header={<ProductChildProductsHeader {...props} count={childProducts?.length || 0}/>}
      loading={loading}
      preferences={preferences}
      onPreferenceChange={onPreferenceChange}
    />
  );
};
