import * as React from "react";
import { FunctionComponent } from "react";
import { ApiParityLoaderProps } from "./ApiParityLoader.types";
import { ParityDao } from "../../daos/ParityDao";
import { RegionDao } from "../../daos/RegionDao";
import { CecmDao } from "../../daos/CecmDao";
import { AwsServiceVO } from "@amzn/api-parity-react-component";
import { LoadStateEnum } from "../../models/LoadStateEnum";
import { useSelector } from "react-redux";
import { serviceListSelector, serviceLoadStateSelector } from "../../redux/selectors/serviceSelector";
import { ApiParityDao } from "../../daos/ApiParityDao";
import { ApiParityLoader as ApiParityLoaderComponent } from "../../components/Parity/ApiParityLoader/ApiParityLoader";

const cecmDao: CecmDao = new CecmDao();
const apiParityDao: ApiParityDao = new ApiParityDao();
const parityDao: ParityDao = new ParityDao();
const regionDao: RegionDao = new RegionDao();

export const ApiParityLoader: FunctionComponent<ApiParityLoaderProps> = (props) => {
  const serviceListLoadState: LoadStateEnum = useSelector(serviceLoadStateSelector);
  const serviceList: AwsServiceVO[] = useSelector(serviceListSelector);

  return <ApiParityLoaderComponent
    getServiceIdMap={apiParityDao.getServiceIdMap.bind(apiParityDao)}
    getServiceNoDataReason={apiParityDao.getServiceWithNoDataReason.bind(apiParityDao)}
    getAutoAddServices={apiParityDao.getAutoCreateService.bind(apiParityDao)}
    getServiceParities={parityDao.getParity.bind(parityDao)}
    getRegions={regionDao.getRegions.bind(regionDao)}
    getCecmParities={cecmDao.getParities.bind(cecmDao)}
    getCloudTrailExemptedApis={cecmDao.getExemptedApis.bind(cecmDao)}
    getApiParityOverrides={apiParityDao.getParityOverride.bind(apiParityDao)}
    getAwsSdkMetadata={apiParityDao.getSdkApi.bind(apiParityDao)}
    serviceList={serviceList}
    serviceListLoadState={serviceListLoadState}
    onDataLoaded={props.onDataLoaded} />
};
