import React, { CSSProperties } from "react";
import _ from "lodash";
import moment, { Moment } from "moment";
import { DEFAULT_DASH, GENERALLY_AVAILABLE, NON_GLOBALLY_EXPANDING } from "../constants";
import { PlanConfidence } from "../components/PlanConfidence/PlanConfidence";
import { hasDisposition, toShortDate } from "./dateUtil";
import { Optional } from "../models/types/Optional";
import { IServiceRegionPlanDto } from "../daos/types/IServiceRegionPlanDto"
import * as awsui from "@amzn/awsui-design-tokens/polaris";
import { AttentionTip } from "@amzn/api-parity-react-component/lib/components/status-grid/attention-tip/AttentionTip";
import { IServicePlan } from "../models/types/IServicePlan";
import { TextWithStyle } from "../models/types/TextWithStyle";

export const getStatus = (service): Optional<string> => {
  const regionObj = service?.region ?? {};
  const regionKey = Object.keys(regionObj)[0];

  if (regionKey === undefined) {
    return undefined;
  }

  return regionObj[regionKey]?.status
}
export const DEFAULT_STATUS_STRING = "NOT PLANNED"

export const DEFAULT_STATUS = <>{DEFAULT_STATUS_STRING} <AttentionTip tooltip={"There is no RIP record for this item"}/></>

export const getStatusCell = service => {
  const status = getStatus(service);
  return (
    <div className="awsui-util-t-l">
      {status ?? DEFAULT_STATUS}
    </div>
  );
};

function getFirstPlanInService(service) : Optional<IServiceRegionPlanDto> {
  const regionLookup = service?.region ?? {};
  const regionKeys = Object.keys(regionLookup);
  const regionKey = !_.isEmpty(regionKeys) ? regionKeys[0] : undefined;
  return !(regionKey === undefined) ? regionLookup[regionKey] : undefined;
}

export const getConfidence = service => {
  const regionObj = getFirstPlanInService(service);
  return regionObj?.confidence ?? undefined;
}

export const getConfidenceCell = service => {
  const plan = getFirstPlanInService(service);
  return (
    <PlanConfidence plan={plan}/>
  );
};

export function isNotGAAndNotGE(plan: string, service: IServicePlan): boolean {
  return plan === NON_GLOBALLY_EXPANDING && service?.status !== GENERALLY_AVAILABLE;
}

//TODO: This method has duplicated logic with getDateCell method below
export const getEstimatedDate = (service, done = true) : Optional<Date> => {
  const regionObj = service?.region ?? {};
  const exceptionDisposition = service?.exceptions?.[0]?.disposition;
  const plan = service?.plan;
  const regionKeys = Object.keys(regionObj);
  const regionKey = !_.isEmpty(regionKeys) ? regionKeys[0] : undefined;

  const dateObj = !(regionKey === undefined) ? regionObj[regionKey]?.date : undefined;
  const dateMoment = dateObj ? moment(dateObj) : undefined;

  if (exceptionDisposition || isNotGAAndNotGE(plan, service)) {
    return undefined;
  }

  return dateMoment?.isValid() ? dateMoment.toDate() : undefined;
};

export const getDateCellHelper = (service, done: boolean): TextWithStyle => {
  const regionObj = service?.region ?? {};
  const regionKeys = Object.keys(regionObj);

  const hasRegionObject = regionKeys.length && regionObj[regionKeys[0]] !== undefined;
  // The actual object information comes after indexing by region, then indexing the region name like "IAD"
  const indexedRegionObject: any = hasRegionObject ? regionObj[regionKeys[0]] : {};

  const status: Optional<string> = indexedRegionObject.status;
  const exceptionDisposition: Optional<string> = indexedRegionObject.disposition;
  const plan: Optional<string> = service?.plan;

  const dateObj: Optional<string> = indexedRegionObject.date;
  const date: Optional<Moment> = (dateObj) ? moment(dateObj) : undefined;
  const formattedDate: Optional<string> = toShortDate(date);

  let renderedText = "Need Date";
  let style : Optional<CSSProperties>;

  if (status === GENERALLY_AVAILABLE) {
    renderedText = formattedDate ?? DEFAULT_DASH;
  } else if (hasDisposition(exceptionDisposition)) {
    // @ts-ignore hasDisposition confirms non-undefined argument
    renderedText = exceptionDisposition;
  } else if (formattedDate) {
    const diff = moment().diff(date);

    if (diff > 0 && !done) {
      style = { color: awsui.colorTextStatusError };
    }

    renderedText = formattedDate;
  } else if (plan === NON_GLOBALLY_EXPANDING) {
    renderedText = "Not Launching";
  }

  return {
    text: renderedText,
    style: style,
  }
};

export const getDateCellWithoutFormatting = (service, done = true): string => {
  return getDateCellHelper(service, done).text;
};

export const getDateCell = (service, done = true): React.ReactElement => {
  const { text, style } = getDateCellHelper(service, done);
  return (
    <div className="awsui-util-t-l" style={style}>
      { text }
    </div>
  );
};
