import * as React from "react";
import { Alert, Link } from "@amzn/awsui-components-react-v3";
import { REPORT_A_BUG_URL } from "../../../../constants";
import { FunctionComponent } from "react";
import { getDataTestIdProp } from "../../../../utils/testUtil";
import { IHasDataTestId } from "../../../../models/IHasDataTestId";


export interface EditLaunchErrorAlertProps  extends IHasDataTestId {
  header: string,
  description: string
}

export const EditLaunchErrorAlertTestIdSuffix = {
  alert: "-alert",
  link: "-link",
}

export const EditLaunchErrorAlert: FunctionComponent<EditLaunchErrorAlertProps> = (props) =>{
  return (
    <Alert type="error" header={props.header} {...getDataTestIdProp(props, EditLaunchErrorAlertTestIdSuffix.alert)}>
      {`${props.description} If you believe this is an error, please submit a `}
      <Link
        external
        externalIconAriaLabel="Opens in a new tab"
        href={REPORT_A_BUG_URL}
        {...getDataTestIdProp(props, EditLaunchErrorAlertTestIdSuffix.link)}
      >
        ticket
      </Link>.
    </Alert>
  )
}

