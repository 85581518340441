import React, { FunctionComponent } from "react";
import { Box, SpaceBetween } from "@amzn/awsui-components-react-v3";
import { LaunchDocumentReviewFormGroupProps } from "./LaunchDocumentReviewFormGroup.types";
import { LaunchDocumentReviewForm } from "./LaunchDocumentReviewForm/LaunchDocumentReviewForm";
import { getDataTestIdProp } from "../../../../utils/testUtil";

export const LaunchDocumentReviewFormGroupTestIdSuffixes = {
  reviews: "-reviews"
}

export const LaunchDocumentReviewFormGroup: FunctionComponent<LaunchDocumentReviewFormGroupProps> = (props) => {
  const { control, errors, root, reviews, remove } = props

  return (<>
    {reviews.length > 0 && <Box padding={{ top: "l" }}>
      <SpaceBetween
        size="l"
        direction="vertical"
        {...getDataTestIdProp(props, LaunchDocumentReviewFormGroupTestIdSuffixes.reviews)}
      >
        {reviews.map((review, index) =>
          <div key={review.id}>
            <LaunchDocumentReviewForm
              root={`${root}[${index}]`}
              control={control}
              errors={errors?.[index]}
              review={review}
              remove={() => remove(index)}
            />
          </div>
        )}
      </SpaceBetween></Box>}
  </>);
}
