import { getReconApiClient } from "./daoUtil";
import { parseGetSingleRegionData } from "../utils/apiUtil";
import { IGetRegionsResponse, IGetRipRegionsResponse } from "./types/IGetRegionsResponse";
import { IGetSingleRegionPlanResponse, IGetSingleRegionPlanResponseLegacy } from "./types/IGetSingleRegionPlanResponse";
import { queryRip } from "./ripClient";

const REGION_QUERY = `
  query getRegions($offset: String, $limit: Int) {
    regions(offset: $offset, limit: $limit) {
      items {
        key {
          name
        }
        longName
        regionName
        arnPartition
        status
        codeName
        estimatedLaunchDate
        accessibilityAttributes
        tags
      }
      nextOffset
    }
  }
`;

export class RegionDao {
  /**
   * Get a list of regions
   */
  async getRegions(): Promise<IGetRegionsResponse> {
    const client = getReconApiClient();
    const res = await client.get(this.getRegionsUrl());
    return res.data;
  }

  getRegionsUrl(): string {
    return "/api/regions";
  }

  /**
   * Get a service's metadata and its region plans
   * @param region
   */
  async getSingleRegionPlans(region): Promise<IGetSingleRegionPlanResponseLegacy> {
    const client = getReconApiClient();
    const res = await client.get<IGetSingleRegionPlanResponse>(this.getSingleRegionPlansUrl(region));
    return parseGetSingleRegionData(res.data);
  }

  getSingleRegionPlansUrl(region) {
    return `/api/regions/${encodeURIComponent(region)}/plans`;
  }

  async getRipRegions(): Promise<IGetRipRegionsResponse> {
    const data = await queryRip<IGetRipRegionsResponse, any>(REGION_QUERY, { limit: 100 });

    // We don't want to include "CLOSED", "RETAIL", or "TEST" regions
    return {
      regions: {
        items: data.regions.items.filter((region) =>
          region.status !== "CLOSED"
          && !region.accessibilityAttributes?.includes("RETAIL")
          && !region.accessibilityAttributes?.includes("TEST")
        )
      }
    };
  }
}
