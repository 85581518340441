import { FormValues, IBulkEditFormField, OverrideInput } from "./BulkEditServiceContactWizard.types"

export const aliasValidation = {
  pattern: /^[a-z]+$/,
  maxLength: 8
}

export const bulkEditSteps = {
  reviewSelectedServicesStep: 0,
  chooseNewFieldsStep: 1,
  reviewChangesStep: 2
}

export const API_SUCCESS_MESSAGE = "success"

export const aliasErrorText = "Must be a valid amazon employee alias"

export const simGuidValidation = {
  pattern: /^[a-z0-9-]+$/
}

export const MAX_BULK_EDIT_OBJECTS = 20;

export const notificationsValidation = {
  pattern: /^[a-z0-9-]+$/
}
export const partitionValidation = {
  pattern: /^[a-z-]+$/
}
export const bulkEditMaxError = {
  modal: "BulkEditMaxError-modal",
  alert: "BulkEditMaxError-alert"
}

export const selectedServicesColumnLayout = {
  columnLayout: "SelectedServices-columnLayout"
}

export const editableFields: IBulkEditFormField[] = [
  {
    label: "Primary Build POC",
    attribute: "primaryBuildPoc",
    validationRules: aliasValidation,
    errorText: aliasErrorText
  },
  {
    label: "Secondary Build POC",
    attribute: "secondaryBuildPoc",
    validationRules: aliasValidation,
    errorText: aliasErrorText
  },
  {
    label: "Build TPM",
    attribute: "rbtServiceManager",
    validationRules: aliasValidation,
    errorText: aliasErrorText
  },
  {
    label: "SIM GUID",
    attribute: "simGuid",
    validationRules: simGuidValidation,
    errorText: "SIM GUID can only have lowercase letters, numbers and hyphens"
  },
  {
    label: "Notifications Alias",
    attribute: "notifications",
    validationRules: notificationsValidation,
    errorText: "Must be a valid amazon email alias"
  },
  {
    label: "AZ Service Owner",
    attribute: "azServiceOwner",
    validationRules: aliasValidation,
    errorText: aliasErrorText
  },
  {
    label: "Local Zone Service Owner",
    attribute: "localZoneServiceOwner",
    validationRules: aliasValidation,
    errorText: aliasErrorText
  },
  {
    label: "VPCE Build Owner",
    attribute: "vpceBuildOwner",
    validationRules: aliasValidation,
    errorText: aliasErrorText
  }
]

export const defaultOverrideInput: OverrideInput = {
  override: false,
  value: ""
}

export const defaultFormValues: FormValues = {
  primaryBuildPoc: defaultOverrideInput,
  secondaryBuildPoc: defaultOverrideInput,
  rbtServiceManager: defaultOverrideInput,
  simGuid: defaultOverrideInput,
  notifications: defaultOverrideInput,
  localZoneServiceOwner: defaultOverrideInput,
  vpceBuildOwner: defaultOverrideInput,
  azServiceOwner: defaultOverrideInput
}
