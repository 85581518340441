import { QaRemoteOperation } from "../models/QaRemoteOperation";
import { remoteOperationRunner } from "./remoteOperationRunner";
import { gaRegionId, serviceRipId } from "./testData";
import { IGetSingleServicePlanResponseLegacy } from "../../daos/types/IGetSingleServicePlanResponse";
import { PlanDao } from "../../daos/PlanDao";
import { IGetServiceRegionHistoryResponseLegacy } from "../../daos/types/IGetServiceRegionHistoryResponse";
import { ServiceDao } from "../../daos/ServiceDao";

export const getServicePlanListOperation: QaRemoteOperation<IGetSingleServicePlanResponseLegacy> = new QaRemoteOperation<IGetSingleServicePlanResponseLegacy>(
  () => remoteOperationRunner(async () => {
    const dao: PlanDao = new PlanDao();
    return await dao.getSingleServicePlans(serviceRipId);
  })
);


export const getServiceInRegionHistoryOperation: QaRemoteOperation<IGetServiceRegionHistoryResponseLegacy> = new QaRemoteOperation<IGetServiceRegionHistoryResponseLegacy>(
  () => remoteOperationRunner(async () => {
    const dao: ServiceDao = new ServiceDao();
    return await dao.getServiceRegionHistory(serviceRipId, gaRegionId);
  })
);

