import {
  AwsServiceCollection,
  AwsServiceCollectionView,
  AwsServiceVO,
  RegionCollection,
  RegionCollectionView,
  RegionVO
} from "@amzn/api-parity-react-component";
import {
  FeatureCollection,
  FeatureCollectionView,
  FeatureParityCollection,
  FeatureParityCollectionView,
  ServiceInRegionRollupStatusCollection,
  ServiceInRegionRollupStatusCollectionView
} from "../models/collections";
import { FeatureTreeItem } from "../models/FeatureTreeItem";
import { Nullable } from "../models/types/Nullable";
import { ParityRollupStatistics } from "../models/ParityRollupStatistics";
import { accumulateStatistics } from "./parityRollupStatisticsUtil";
import {
  FeatureParityVO,
  FeatureVO,
  ServiceInRegionRollupStatusVO
} from "../models/vos";
import { getFeatureParityRollupScore } from "./featureUtil";
import { Optional } from "../models/types/Optional";
import { getFeatureTreeItemService } from "./featureTreeItemUtil";


/**
 * Values and collections as lookup
 */
export interface IFeatureParityScoreCalculatorLookup {
  regions: RegionCollection | RegionCollectionView;
  featureParityRollupByService: ServiceInRegionRollupStatusCollection | ServiceInRegionRollupStatusCollectionView;
  featureParities: FeatureParityCollection | FeatureParityCollectionView;
  zeroAvailabilityFeatures: FeatureCollection | FeatureCollectionView;
  zeroAvailabilityServices: AwsServiceCollection | AwsServiceCollectionView;
}

export type TreeItemEntity = FeatureTreeItem["entity"];

export class FeatureParityScoreCalculatorResult {
  byEntity: Map<TreeItemEntity, Nullable<number>> = new Map<TreeItemEntity, Nullable<number>>();
  byRegion: Map<RegionVO, Nullable<number>> = new Map<RegionVO, Nullable<number>>();
}


export class FeatureParityScoreCalculator {
  private readonly lookup: IFeatureParityScoreCalculatorLookup;
  private _result: FeatureParityScoreCalculatorResult = new FeatureParityScoreCalculatorResult();
  private byRegionStats: Map<RegionVO, ParityRollupStatistics> = new Map<RegionVO, ParityRollupStatistics>();

  constructor(lookup: IFeatureParityScoreCalculatorLookup) {
    this.lookup = lookup;
  }

  reset(): void {
    this._result = new FeatureParityScoreCalculatorResult();
    this.byRegionStats = new Map<RegionVO, ParityRollupStatistics>();
  }

  calculate(rootTreeItems: FeatureTreeItem[]): void {
    rootTreeItems.forEach((item) => {
      this.traverseItem(item);
      this.accumulateRegionStatistics(item);
    });

    this.lookup.regions.items.forEach((region) => {
      const statsOfRegion: Optional<ParityRollupStatistics> = this.byRegionStats.get(region);
      if (!statsOfRegion) {
        this.result.byRegion.set(region, null);
      } else {
        const score: Nullable<number> = (statsOfRegion.expectedTotal === 0) ?
          null
          : statsOfRegion.ga / statsOfRegion.expectedTotal;
        this.result.byRegion.set(region, score);
      }
    });
  }

  traverseItem(featureTreeItem: FeatureTreeItem): void {
    const entity: TreeItemEntity = featureTreeItem.entity;
    if (this.result.byEntity.has(entity)) {
      return;
    }

    let rollup: ParityRollupStatistics;
    const serviceForItem: Optional<AwsServiceVO> = getFeatureTreeItemService(featureTreeItem);
    if (serviceForItem) {
      const service = serviceForItem as AwsServiceVO;
      if (this.lookup.zeroAvailabilityServices.exists(service) && !featureTreeItem.childItems?.length) {
        this.result.byEntity.set(service, null);
        return;
      }
      rollup = this.computeServiceStatistics(this.lookup.featureParityRollupByService.byService(service));
    } else {
      // The entity is a bona fide feature (aka not service as feature)
      const feature = entity as FeatureVO;
      if (this.lookup.zeroAvailabilityFeatures.exists(feature)) {
        this.result.byEntity.set(feature, null);
        return;
      }
      rollup = this.computeFeatureStatistics(feature)
    }

    const score: Nullable<number> = (rollup.expectedTotal === 0) ?
      null
      : rollup.ga / rollup.expectedTotal;

    this.result.byEntity.set(entity, score);

    if (featureTreeItem.children && featureTreeItem.children.length) {
      featureTreeItem.children.forEach((item) => this.traverseItem(item));
    }
  }

  computeServiceStatistics(statsByRegion: readonly ServiceInRegionRollupStatusVO[]): ParityRollupStatistics {
    const total: ParityRollupStatistics = new ParityRollupStatistics();
    statsByRegion.forEach((byRegion) => {
      if (this.lookup.regions.exists(byRegion.region)) {
        accumulateStatistics(byRegion.statistics, total);
      }
    });

    return total;
  }

  accumulateRegionStatistics(featureTreeItem: FeatureTreeItem): void {
    const serviceForItem: Optional<AwsServiceVO> = getFeatureTreeItemService(featureTreeItem);
    if (!serviceForItem) {
      return;
    }

    const stats: readonly ServiceInRegionRollupStatusVO[] = this.lookup.featureParityRollupByService.byService(serviceForItem);
    if (stats.length === 0) {
      return;
    }

    stats.forEach((statsItem: ServiceInRegionRollupStatusVO) => {
      const region: RegionVO = statsItem.region;
      if (this.lookup.regions.exists(region)) {
        let regionStatus: ParityRollupStatistics;
        if (!this.byRegionStats.has(region)) {
          regionStatus = new ParityRollupStatistics();
          this.byRegionStats.set(region, regionStatus);
        } else {
          regionStatus = this.byRegionStats.get(region) as ParityRollupStatistics;
        }
        accumulateStatistics(statsItem.statistics, regionStatus);
      }
    });
  }

  computeFeatureStatistics(feature: FeatureVO): ParityRollupStatistics {
    const total: ParityRollupStatistics = new ParityRollupStatistics();
    this.lookup.regions.items.forEach((region) => {
      const parity: Optional<FeatureParityVO> = this.lookup.featureParities.byFeatureRegion(feature, region);
      const byRegion: ParityRollupStatistics = getFeatureParityRollupScore(parity);
      accumulateStatistics(byRegion, total);
    });
    return total;
  }


  get result(): FeatureParityScoreCalculatorResult {
    return this._result;
  }
}
