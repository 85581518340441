import { useState, useEffect } from "react";
import PubSub from "pubsub-js";

const useBreadcrumbs = (initialItems) => {
  const [ initialized, setInitialized ] = useState(false);
  const [ breadcrumbs, setBreadcrumbs ] = useState(initialItems);

  if (!initialized) {
    PubSub.publish("BREADCRUMBS", breadcrumbs);
    setInitialized(true);
  }

  useEffect(() => {
    PubSub.publish("BREADCRUMBS", breadcrumbs);
  }, [breadcrumbs]);

  return setBreadcrumbs;
}

export default useBreadcrumbs;
