import { Optional } from "@amzn/api-parity-react-component/lib/models/Nullable";
import { Cards } from "@amzn/awsui-components-react-v3";
import { FunctionComponent } from "hoist-non-react-statics/node_modules/@types/react";
import React from "react";
import { UpdateRipContactsDelegate } from "../../../models/delegates/UpdateRipContactsDelegate";
import { UpdateServiceLeaderDelegate } from "../../../models/delegates/UpdateServiceLeaderDelegate";
import { ServiceContactsVO } from "../../../models/vos/ServiceContactsVO";
import { ServiceContactUpdateResult } from "../../../orchestrators/ServiceContactOrchestrator";
import { hydrateModel } from "../../../utils/modelUtil";
import { LoadingIndicator } from "../../LoadingIndicator/LoadingIndicator";
import { IAddOrEditFormInput } from "../EnterServiceContact/AddOrEditServiceContactModal/AddOrEditServiceContactModal.types";
import { cardLayoutDefinition, EMPTY_CONTACT } from "./cards-config";
import { ISingleServiceContactsCardProps } from "./SingleServiceContactsCard.types";

export const SingleServiceContactCardsTestId = {
  content: "SingleServiceContactsCards-content",
}

class SubmitCallbacks {
  onEditGmVpCallback: Optional<UpdateServiceLeaderDelegate> = undefined;
  onEditServiceContactsSubmit: Optional<(data: IAddOrEditFormInput, prevContactInfo: Optional<ServiceContactsVO>) => Promise<ServiceContactUpdateResult>> = undefined;
  onUpdateRipContacts: Optional<UpdateRipContactsDelegate> = undefined;
  onSubmit: Optional<(successfulServices: ServiceContactsVO[], failedServices: ServiceContactsVO[]) => void> = undefined;

  constructor(source: Partial<SubmitCallbacks>) {
    hydrateModel(source, this);
  }
}

function generateCardsPerRow(CardMultiplier) {
  const POLARIS_MAX_CARDS_PER_ROW = 20;
  return Array.from({ length: POLARIS_MAX_CARDS_PER_ROW }, (_, index) => ({ minWidth: CardMultiplier * index, cards: index }));
}

export const SingleServiceContactsCard: FunctionComponent<ISingleServiceContactsCardProps> = (props) => {
  const cards_width = 600;
  const callbacksBundle = new SubmitCallbacks({
    onEditGmVpCallback: props.editGmVpSubmit,
    onEditServiceContactsSubmit: props.editServiceContactsSubmit,
    onSubmit: props.onSubmit,
  })

  return (props.loading ? <LoadingIndicator /> :
    <div data-testid={SingleServiceContactCardsTestId.content}>
      <Cards
        cardsPerRow={generateCardsPerRow(cards_width)}
        items={props.partitionContacts} cardDefinition={cardLayoutDefinition(callbacksBundle, props.userPermissions)}
        empty={EMPTY_CONTACT(callbacksBundle, props.userPermissions, props.nameRip)}
      />
    </div> )
}
