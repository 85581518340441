import { QaRemoteOperation } from "../models/QaRemoteOperation";
import { IGetRegionsResponse } from "../../daos/types/IGetRegionsResponse";
import { RegionDao } from "../../daos/RegionDao";
import { remoteOperationRunner } from "./remoteOperationRunner";

const dao: RegionDao = new RegionDao();

export const getRegionListOperation: QaRemoteOperation<IGetRegionsResponse> = new QaRemoteOperation<IGetRegionsResponse>(
  () => remoteOperationRunner(() => dao.getRegions())
);
