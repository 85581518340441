import * as React from "react";
import { TableHeaderProp } from "./TableHeader.types";
import { Optional } from "../../../models/types/Optional";
import { CompactHeader } from "../../../styles/polaris";

export function getSecondary(secondary: Optional<React.ReactElement | string | number>): string | React.ReactElement {
  if (!secondary) {
    return <React.Fragment />
  }
  if (typeof secondary === "string" || typeof secondary === "number") {
    return `(${secondary})`;
  }
  return secondary;
}

export const TableHeader: React.FunctionComponent<TableHeaderProp> = (props) => {
  return (
    <CompactHeader
      info={getSecondary(props.secondary)}
      actions={props.actionButtons}>
      {props.primary}
    </CompactHeader>
  );
};

export default TableHeader;
