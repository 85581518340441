import * as React from "react";
import { FunctionComponent } from "react";
import { LimitedContainerProps } from "./LimitedContainer.types";

const noShow = <React.Fragment />

export function isBetween(start: string, end: string, date: Date = new Date()): boolean {
  // Note that the function here does not handle invalidate dates gracefully, if start/end are in invalidate format, code would always return false
  const now = date.getTime();
  const startMs = new Date(start).getTime();

  if (startMs > now) {
    return false;
  }

  const endMs = new Date(end).getTime();
  return (endMs >= now);
}

export const LimitedContainer: FunctionComponent<LimitedContainerProps> = (props) => {

  if (props.limitToAliases && !props.limitToAliases.has(props.currentAlias)) {
    return noShow;
  }

  if (!isBetween(props.startOn, props.endOn)) {
    return noShow;
  }

  return (
    <React.Fragment>
      {props.children}
    </React.Fragment>
  );
};
