import * as React from "react";
import { Link, LinkProps } from "react-router-dom";
import * as _ from "lodash";
import { RMS_URL_BASE } from "../../constants";
import { Link as HyperLink } from "@amzn/awsui-components-react-v3";
import { normalizeServiceName } from "../../daos/daoUtil";
import { getFeatureStatusUrl } from "../../utils/unifiedUxUtil";
import styled from "styled-components";

enum FeatureType {
  Feature = "Feature",
  Service = "Service",
}

export const externalLinkProps: Partial<LinkProps> = {
  target: "_blank",
  rel: "noopener noreferrer",
  referrerPolicy: "no-referrer"
}

const TableIndentStyled = styled("div")`
margin-left: 1.5rem
`;

export const getLinkBasedOnFeatureType = (item) => {
  let returnLink;
  if (item.metadata.type === FeatureType.Feature) {
    returnLink = <TableIndentStyled><FeatureWithRIPLinkDashboard
      serviceName={item.metadata.parent}
      feature={{
        longName: item.content.name,
        id: item.content.id,
      }}
    /></TableIndentStyled>
  }
  else {
    returnLink = <SingleServiceWithRIPLink
      serviceObject={{
        nameRip: item.content.id,
        nameLong: item.content.name
      }}
    />
  }

  return returnLink;
};

export const SingleServiceWithRIPLink = ({ serviceObject, showSmall = true }) => {
  const { nameSortable, nameLong, nameRip } = serviceObject;
  const normalizedRipName = normalizeServiceName(nameRip);

  return(
  <Link to={`/services/${normalizedRipName}`}>
    <span>{`${nameSortable || nameLong || nameRip} `}</span>
    {
      showSmall ?
        <small>{` (${nameRip})`}</small> : ""
    }
  </Link>
)};

export const FeatureWithRIPLink = ({ serviceName, feature }) => {
  const { id, longName } = feature;
  const href = getFeatureStatusUrl(serviceName, id);
  return (
    <HyperLink href={href}>
      {`${longName || id}`}
    </HyperLink>
  );
};

export const FeatureWithRIPLinkDashboard = ({ serviceName, feature }) => {
  const { id, longName } = feature;
  const href = getFeatureStatusUrl(serviceName, id);
  return (
    <HyperLink href={href}>
      <span>{`${longName || id} `}</span>
      <small>{` (${id})`}</small>
    </HyperLink>
  );
};

export const RMSLink = ({ serviceObject }) => {
  const { nameSortable, nameLong, nameRip } = serviceObject
  const normalizedRipName = normalizeServiceName(nameRip);

  let href = `${RMS_URL_BASE}/services/${normalizedRipName}`
  return (
    <HyperLink href={href} external={true}>
      {nameSortable ?? nameLong ?? nameRip}
    </HyperLink>
)};

export const SingleServiceLink = ({ nameSortable, nameLong, nameRip, redirectToContacts = false }) => {
  const normalizedNameRip = normalizeServiceName(nameRip);
  const redirectTo = redirectToContacts ? `/services/${normalizedNameRip}/contact` : `/services/${normalizedNameRip}`
  return (
    <Link {...externalLinkProps} to={redirectTo}>
      {`${nameSortable ?? nameLong ?? normalizedNameRip} `}
    </Link>
  );
};

export const SingleRegionLink = ({ regionName }: { regionName: string }) => {
  const upperRegion = regionName.toUpperCase();
  return <Link to={`/regions/${upperRegion}`}>{upperRegion}</Link>;
};

export const ServiceInRegionLinkViaService = ({ regionName, serviceName, children }) => {
  const normalizedServiceName = normalizeServiceName(serviceName);
  return <Link to={`/services/${normalizedServiceName}/${regionName}`}>{children}</Link>;
};

export const ServiceInRegionLinkViaRegion = ({ regionName, serviceName, children }) => {
  const normalizedServiceName = normalizeServiceName(serviceName);
  return <Link to={`/regions/${regionName}/${normalizedServiceName}`}>{children}</Link>;
};

export const ServiceInRegionLinkViaParity = ({ regionName, serviceName, children }) => {
  const normalizedServiceName = normalizeServiceName(serviceName);
  return <Link to={`/services/${normalizedServiceName}/${_.upperCase(regionName)}`} {...externalLinkProps}>{children}</Link>;
};
