/**
 * No operation action, perfectly do nothing action
 */
export const NO_OPERATION = "@special/NoOperation";

export interface INoOperationAction {
  type: typeof NO_OPERATION
}

export type SpecialActionTypes =
  INoOperationAction;
