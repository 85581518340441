import { hydrateModel } from "../utils/modelUtil";

export class ParityRollupStatistics {
  /**
   * Total number of raw descendants
   */
  rawTotal: number = 0;

  /**
   * Total number of descendants expected to be GA to be seen as full parity
   */
  expectedTotal: number = 0;

  ga: number = 0;
  notAvailable: number = 0;

  ato: number = 0;
  notLaunching: number = 0;
  plannedLaunch: number = 0;

  constructor(source?: Partial<ParityRollupStatistics>) {
    hydrateModel(source, this);
  }
}
