import useSWR, { responseInterface } from "swr";
import { ProductVO } from "../models/vos/ProductVO";
import { ProductDao } from "../daos/ProductDao";
import { productDtoToVO } from "../parsers/productParser";
import { getSwrConfig } from "../daos/swrUtil";

// manually override responseInterface with ProductVO data object
export interface IProductSWR extends responseInterface<ProductVO, any> {
  data: ProductVO | undefined;
}

/**
 * useProduct
 * @description provide an SWR method for getting a single product from Recon api.
 * @param productId id of product to fetch
 */
export const useProduct = (productId?: string): IProductSWR =>
  useSWR<ProductVO>(productId ? () => `products/${productId}` : null, async () => {
    const dao = new ProductDao();
    const dto = await dao.getProduct(productId as string);

    return productDtoToVO(dto);
  }, getSwrConfig()) as IProductSWR;
