import * as he from "he";

const codeAllStringFields = (obj: any, encodeOrDecodeFunc, skipLinks: boolean): any => {
  if (!obj) {
    return obj;
  }
  if (typeof obj === "object") {
    Object.keys(obj).forEach((key) => {
      // eslint-disable-next-line no-prototype-builtins
      if (obj.hasOwnProperty(key)) {
        if (typeof obj[key] === "string") {
          if ((!key.toLowerCase().includes("link")) || !skipLinks) {
            // eslint-disable-next-line no-param-reassign
            obj[key] = encodeOrDecodeFunc(obj[key]);
          }
        }
        if (typeof obj[key] === "object") {
          codeAllStringFields(obj[key], encodeOrDecodeFunc, skipLinks);
        }
      }
    });
  }
  if (typeof obj === "string") {
    return encodeOrDecodeFunc(obj)
  }
  return obj;
};

export const decodeAllStringFields = (obj: any) => codeAllStringFields(obj, he.decode, true) // we should skip links by default for XSS reasons. Manually decode them when the situation is appropriate
