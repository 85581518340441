import { SpaceBetweenProps } from "@amzn/awsui-components-react-v3";
import styled from "styled-components";

export interface IFormFieldSpacing {
  horizontal: SpaceBetweenProps.Size;
  vertical: SpaceBetweenProps.Size;
  section: SpaceBetweenProps.Size;
  header: SpaceBetweenProps.Size;
}

export const formFieldSpacing: IFormFieldSpacing = {
  horizontal: "s",
  vertical: "s",
  section: "m",
  header: "xxxs",
};

export const InputSectionHeader = styled.div`
  font-weight: bold;
`;
