import { QaItemDefinition } from "../../models/QaItemDefinition";
import { IGetRegionsResponse } from "../../../daos/types/IGetRegionsResponse";
import { getRegionListOperation } from "../../remoteOperations/getRegionListOperation";
import { regionListHasNonGADatesChecker } from "../../checkers/regionListChecker";

export const listRegionHasNoGaRegionAndDates = new QaItemDefinition<IGetRegionsResponse>(
  "/api/regions results should contain non-GA dates",
  getRegionListOperation,
  regionListHasNonGADatesChecker
);
