import * as React from "react";
import { FunctionComponent, useCallback } from "react";
import {
  BulkEditServiceContactWizardProps,
  FormValues,
  IBulkEditFieldSelectorProps,
  IBulkEditServiceContactResponse
} from "./BulkEditServiceContactWizard.types"
import { BulkEditChangesTable } from "./BulkEditChangesTable"
import {
  Button,
  Checkbox,
  ColumnLayout,
  Container,
  Flashbar,
  FormField,
  Header,
  Input,
  Modal,
  Wizard
} from "@amzn/awsui-components-react-v3";
import { useForm } from "react-hook-form"
import { useTypedController } from "@hookform/strictly-typed";
import {
  API_SUCCESS_MESSAGE,
  bulkEditMaxError,
  bulkEditSteps,
  defaultFormValues,
  editableFields,
  MAX_BULK_EDIT_OBJECTS,
  selectedServicesColumnLayout
} from "./BulkEditServiceContactWizardConstants";
import { noChangesRequested } from "./BulkEditContactWizardUtils";
import { PermissionError } from "../../../common/PermissionError";
import { contactsWriteAccessSimTemplateLink } from "../../../common/SimLinks/ContactsWriteAccessSimTemplateLink";
import { ServiceContactsVO } from "../../../../models/vos/ServiceContactsVO";
import {
  EnterServiceContactsFormType,
  EnterServiceContactTestId
} from "../AddOrEditServiceContactModal/AddOrEditServiceContactModalConstants";
import { LoadingIndicator } from "../../../LoadingIndicator/LoadingIndicator";

export const bulkEditWizardTestId = {
  wizard: "BulkEditWizard-wizard"
}

export const BulkEditFieldSelector: FunctionComponent<IBulkEditFieldSelectorProps> = (props) => {
  //@ts-ignore
  const TypedController = useTypedController<FormValues>(props.control);

  return (
    <React.Fragment>
      <TypedController
        //@ts-ignore
        name={[props.formField.attribute, "override"]}
        control={props.control}
        render={(
          { onChange, value }
        ) => (
          <Checkbox
            onChange={({ detail }) => {
              onChange(detail.checked)
            }} 
            data-testid={`${props.formField.attribute}-checkbox`}
            checked={value}
          >
            {props.formField.label}
          </Checkbox>
        )}
      />

      <TypedController
        //@ts-ignore
        name={[props.formField.attribute, "value"]}
        control={props.control}
        rules={props.formField.validationRules}
        //@ts-ignore
        render={(
          { onChange, onBlur, value },
          { invalid }
        ) => (
          <div>
            <FormField data-testid={`${props.formField.attribute}-formField`}
                       errorText={invalid && props.formField.errorText}>
              <Input
                onBlur={onBlur}
                data-testid={`${props.formField.attribute}-input`}
                invalid={invalid}
                onChange={({ detail }) => onChange(detail.value)}
                value={value}
                disabled={props.watch[props.formField.attribute] ? !props.watch[props.formField.attribute].override : true } 
              />
            </FormField>
          </div>
        )}
      />
    </React.Fragment>
  );
}

export const BulkEditServiceContactWizard: FunctionComponent<BulkEditServiceContactWizardProps> = (props) => {
  const [activeStepIndex, setActiveStepIndex] = React.useState(bulkEditSteps.reviewSelectedServicesStep);
  const [visible, setVisible] = React.useState(false);
  const [modalLoading, setModalLoading] = React.useState(false);
  const [showPermissionError, setShowPermissionError] = React.useState(false);
  const [showBulkEditMaxError, setShowBulkEditMaxError] = React.useState(false);
  const [validateFormFieldsError, setValidateFormFieldsError] = React.useState<any>([]);
  const { getValues, trigger, watch, control, reset } = useForm<FormValues>({ mode: "onChange", shouldUnregister: false, defaultValues: defaultFormValues });
  const watchChanges = watch();

  const buttonAndModalLabel = `Bulk Edit ${props.selected.length > 1 ? props.selected.length : ""} Contacts`

  const permissionError_onDismiss = useCallback(() => {
    setShowPermissionError(false);
  }, []);

  const bulkEditMaxError_onDismiss = useCallback(() => {
    setShowBulkEditMaxError(false);
  }, []);

  const open_onClick = useCallback(() => {
    if (!props.userAbilities.canWriteContacts) {
      setShowPermissionError(true);
      return;
    }
    if (props.selected.length > MAX_BULK_EDIT_OBJECTS) {
      setShowBulkEditMaxError(true);
      return;
    }
    setVisible(true);
  }, [props.userAbilities, props.selected]);

  const bulkEditModal_onDismiss = useCallback(() => {
    setValidateFormFieldsError([])
    setVisible(false);
    setActiveStepIndex(bulkEditSteps.reviewSelectedServicesStep);
    reset();
  }, [reset]);

  const onSubmit = useCallback(async() => {
    if (!props.onBulkEditServiceContactSubmit){
      return
    }

    const services = props.selected.map((service) => service.uid);
    setModalLoading(true);

    let response: IBulkEditServiceContactResponse[] = await props.onBulkEditServiceContactSubmit(getValues, services);

    const successfulCalls: ServiceContactsVO[] = [];
    const failedCalls: ServiceContactsVO[] = [];

    response.forEach((service) => {
      if (service.message === API_SUCCESS_MESSAGE) {
        const successfulUpdate = props.selected.find((selectedService) => selectedService.serviceName === service.serviceName)
        successfulUpdate && successfulCalls.push(successfulUpdate)
      } else {
        const failedUpdate = props.selected.find((selectedService) => selectedService.serviceName === service.serviceName)
        failedUpdate && failedCalls.push(failedUpdate)
      }
      props.onSuccessfulSubmit?.(successfulCalls, failedCalls, EnterServiceContactsFormType.Edit);

      setModalLoading(false);
      bulkEditModal_onDismiss();
    })

  }, [bulkEditModal_onDismiss, getValues, props.selected]);

  const wizard_onNavigate = (async({ detail }) => {
    if (detail.requestedStepIndex === bulkEditSteps.reviewChangesStep && (!(await trigger()) || noChangesRequested(watchChanges, editableFields))) {
      setValidateFormFieldsError([{
        header: "Field values have errors or no changes were requested",
        type: "error",
        content: "Please fix the errors before continuing to the review step.",
        dismissible: true,
        dismissLabel: "Dismiss message",
        onDismiss: () => setValidateFormFieldsError([])
      }])
      setActiveStepIndex(bulkEditSteps.chooseNewFieldsStep);
    }
    else {
      setActiveStepIndex(detail.requestedStepIndex);
    }
  })

  const i18nStrings = {
    stepNumberLabel: stepNumber =>
      `Step ${stepNumber}`,
    collapsedStepsLabel: (stepNumber, stepsCount) =>
      `Step ${stepNumber} of ${stepsCount}`,
    cancelButton: "Cancel",
    previousButton: "Previous",
    nextButton: "Next",
    submitButton: "Submit",
    optional: "optional"
  }

  return (
    <React.Fragment>
      <Modal
        onDismiss={bulkEditModal_onDismiss}
        visible={visible}
        header={buttonAndModalLabel}
        data-testid={props.testIds.wizardTestId}>
        <Flashbar items={validateFormFieldsError} />
        {modalLoading ? <LoadingIndicator /> : <Wizard
          data-testid={bulkEditWizardTestId.wizard}
          i18nStrings={i18nStrings}
          onNavigate={wizard_onNavigate}
          onCancel={bulkEditModal_onDismiss}
          onSubmit={onSubmit}
          activeStepIndex={activeStepIndex}
          steps={[
            {
              title: "Confirm Services to Change",
              description:
                "Please make sure you have selected all the services you would like to bulk edit and make sure none were selected on accident.  If this list is incorrect, please exit the modal and select/deselect from the table.",
              content: (
                <Container
                  header={
                    <Header variant="h2">
                      Services Selected
                    </Header>
                  }
                >
                  <ColumnLayout data-testid={selectedServicesColumnLayout.columnLayout} borders="horizontal" columns={3}>
                    {props.selected.map((item) => {
                      return <div key={item.uid}>{item.uid}</div>
                    })}
                  </ColumnLayout>
                </Container>
              )
            },
            {
              title: "Enter New Contacts for Services",
              description:
                "Select the fields you would like to edit and then enter the values for the new fields.  If you would like to clear a field, please select it and leave the value blank.",
              content: (
                <Container
                  header={
                    <Header variant="h2">
                      Editable Fields
                    </Header>
                  }
                >
                  <ColumnLayout borders="horizontal" columns={3}>
                    {props.editableFields.map((item) => <BulkEditFieldSelector
                                                          formField={item} 
                                                          control={control}
                                                          watch={watchChanges}
                                                          key={item.attribute}
                                                        />)}
                  </ColumnLayout>
                </Container>
              )
            },
            {
              title: "Review All Changes",
              description:
                "Please review all the following changes before submission.",
              content: (
                <BulkEditChangesTable 
                  watchChanges={watchChanges} 
                  editableFields={props.editableFields}
                  existingValues={props.selected}
                />
              )
            }
          ]}
        />}
      </Modal>
      <PermissionError
        header="Access Denied"
        body={<>
          Need Service Contacts Write Access to add or update service contacts,
          please use {contactsWriteAccessSimTemplateLink()} to request access.
        </>}
        visible={showPermissionError}
        onDismiss={permissionError_onDismiss}
        testIds={EnterServiceContactTestId.permissionError}
      />
      <PermissionError
        header="Bulk Edit Max Services Limit Reached"
        body={<>
          Currently, we only support bulk edit on up to {MAX_BULK_EDIT_OBJECTS} services.  If you need to edit more,
          please do them in separate submissions.  
        </>}
        visible={showBulkEditMaxError}
        onDismiss={bulkEditMaxError_onDismiss}
        testIds={bulkEditMaxError}
      />
      <Button
        data-testid={props.testIds.buttonTestId}
        onClick={open_onClick}
        disabled={!(props.selected.length > 1)}>
        {buttonAndModalLabel}
      </Button>
    </React.Fragment>
  );
}
