import * as React from "react";
import { FunctionComponent, useCallback, useEffect, useState } from "react";
import { FeatureParityOverviewViewProps } from "./FeatureParityOverviewView.types";
import { FeatureParityOverviewControlBar } from "../FeatureParityOverviewControlBar/FeatureParityOverviewControlBar";
import { FeatureParityOverviewSettingVO } from "../../../../../models/vos/FeatureParityOverviewSettingVO";
import { FeatureTreeItem } from "../../../../../models/FeatureTreeItem";
import { FeatureParityOverviewGrid } from "../FeatureParityOverviewGrid/FeatureParityOverviewGrid";
import { Box, Container } from "@amzn/awsui-components-react-v3";
import { IDataExportable } from "@amzn/api-parity-react-component/lib/models/IDataExportable";
import { MatchedState } from "../../../../../models/FilterableTreeItem";
import { ExportTableToCSVExcel } from "../../../../ExportTableToCSVExcel/ExportTableToCSVExcel";
import { LoadingIndicator } from "../../../../LoadingIndicator/LoadingIndicator";
import { alwaysMatch, matchAnyTerm, MatchNodeFn } from "../../../../../utils/stringUtil";
import { RegionCollectionView } from "@amzn/api-parity-react-component/lib/models/collections/RegionCollectionView";
import { RegionCollection } from "@amzn/api-parity-react-component/lib/models/collections/RegionCollection";
import { featureDataToTreeItems, getInitialServices } from "../../../../../utils/featureUtil";
import { AwsServiceCollection, AwsServiceCollectionView, AwsServiceVO } from "@amzn/api-parity-react-component";
import {
  getFeatureServiceCountStatistics,
  getGm,
  getVp,
  IFeatureParityStatistics
} from "../../../../../utils/featureTreeItemUtil";

export const FeatureParityOverviewViewTestId = {
  grid: "FeatureParityOverviewView-grid"
}

const defaultEmptyOverheadHeight = 300;
const searchTermHeight = 49;
const overviewTitle = "Feature Parity Overview";
const overviewFilePrefix = "Feature Parity Overview";

export const FeatureParityOverviewView: FunctionComponent<FeatureParityOverviewViewProps> = (props) => {
  const emptyOverheadHeight: number = props.overheadHeight ?? defaultEmptyOverheadHeight;
  const [setting, setSetting] = useState<FeatureParityOverviewSettingVO>(props.setting);
  const [gaRegions, setGaRegions] = useState<RegionCollectionView>(new RegionCollectionView(new RegionCollection()));
  const [featureTree, setFeatureTree] = useState<FeatureTreeItem[]>([]);
  const [filteredFeatureTree, setFilteredFeatureTree] = useState<FeatureTreeItem[]>([]);
  const [overheadHeight, setOverheadHeight] = useState<number>(emptyOverheadHeight);
  const [title, setTitle] = useState<string>(overviewTitle);
  const [filePrefix, setFilePrefix] = useState<string>(overviewFilePrefix);
  const [countStatistics, setCountStatistics] = useState<IFeatureParityStatistics>({
    featureCount: 0,
    serviceCount: 0,
    overallParityRatio: 0,
  });
  const grid: React.MutableRefObject<IDataExportable | null> =  React.useRef<React.ElementRef<typeof FeatureParityOverviewGrid> | null>(null);

  // TODO: This component has too many derived state, it should
  // use view model paradigm so the derived values can be easily
  // unit tested

  const {
    services,
    features,
    regions,
    featureParities,
    serviceParities,
    featureParityRollupByService,
    zeroAvailabilityFeatures,
    zeroAvailabilityServices,
    selectedServiceId,
  } = props;

  useEffect(() => {
    setGaRegions(new RegionCollectionView(
      regions.source,
      {
        filter: item => item.status === "GA"
      }
    ))
  }, [
    regions,
  ]);

  useEffect(() => {
    let initialServices: AwsServiceCollectionView;
    if (services.hasId(selectedServiceId as string)) {
      const service = services.getById(selectedServiceId as string) as AwsServiceVO;
      initialServices = new AwsServiceCollectionView(
        new AwsServiceCollection([
          service
        ])
      );

      setTitle(`Feature Parity of ${service.name}`);
      setFilePrefix(service.id.replace(/^[0-9a-zA-Z]/, ""));
    } else {
      initialServices = getInitialServices(services);
      setTitle(overviewTitle);
      setFilePrefix(overviewFilePrefix);
    }

    const tree = featureDataToTreeItems(
      initialServices,
    {
      features,
      services,
      regions: gaRegions,
      parities: featureParities
    });
    setFeatureTree(tree);
    setCountStatistics(getFeatureServiceCountStatistics(tree, featureParityRollupByService, gaRegions));
  }, [
    selectedServiceId,
    services,
    features,
    gaRegions,
    featureParities,
    featureParityRollupByService,
  ]);

  const controlBar_onChange = useCallback((newSetting: FeatureParityOverviewSettingVO) => {
    setSetting(newSetting);
    const overhead = (setting.searchTerms.length > 1) ? emptyOverheadHeight + searchTermHeight : emptyOverheadHeight;
    setOverheadHeight(overhead);
  }, [
    emptyOverheadHeight,
    setSetting,
    setOverheadHeight,
  ]);

  useEffect(() => {
    const searchTerms: string[] = setting.searchTerms.filter((term) => term.length > 0).map((term) => term.toLowerCase());
    const ignoreSearch: boolean = !searchTerms.every((term) => term.length > 0) || searchTerms.length === 0;
    const searchFn: MatchNodeFn = (ignoreSearch) ? alwaysMatch : matchAnyTerm(searchTerms);
    const searchFeatureFilter = (item: FeatureTreeItem) => searchFn(item.entity.id, item.name, getGm(item) ?? "", getVp(item) ?? "", item.entity.visibility ?? "");
    const newlyFiltered = featureTree.filter((item: FeatureTreeItem) => {
      const matched = item.applyFilter(searchFeatureFilter);
      return matched !== MatchedState.NoMatch;
    });
    setFilteredFeatureTree(newlyFiltered);
  }, [
    featureTree,
    setting,
    setFilteredFeatureTree,
  ]);

  const generateDataBook = useCallback(() => {
    return grid?.current?.getDataBook();
  }, [grid.current])

  return (
    <Container
      disableContentPaddings={true}
      header={
        <FeatureParityOverviewControlBar
          title={title}
          isLoading={props.isLoading}
          setting={setting}
          statistics={countStatistics}
          searchDelayInMs={props.searchDelayInMs}
          actions={
            <ExportTableToCSVExcel
              // @ts-ignore
              generateDataBook={generateDataBook}
              fileNamePrefix={filePrefix}
            />}
          onChange={controlBar_onChange} />}
    >
      {
        (props.isLoading) ?
          <Box padding="xxl"><LoadingIndicator /></Box> :
          <div
            data-testid={FeatureParityOverviewViewTestId.grid}>
            <FeatureParityOverviewGrid
              // @ts-ignore
              ref={grid}
              features={filteredFeatureTree}
              regions={gaRegions}
              featureParities={featureParities}
              serviceParities={serviceParities}
              featureParityRollupByService={featureParityRollupByService}
              zeroAvailabilityFeatures={zeroAvailabilityFeatures}
              zeroAvailabilityServices={zeroAvailabilityServices}
              overheadHeight={overheadHeight} />
          </div>
      }

    </Container>
  );
};
