import { RegionDao } from "./RegionDao";
import { IRegionMetadata } from "./types/IRegionMetaData";
import { RegionVO } from "@amzn/api-parity-react-component/lib/models/vos/RegionVO";
import { parseRegionDtoList } from "@amzn/api-parity-react-component/lib/parsers/reconDataParser";
import { RegionFetcher } from "./CecmDao";
import {
  IResourceSpecificationByRegionDto,
  IResourceSpecificationDto
} from "@amzn/api-parity-react-component/lib/models/dtos/cloudformation";
import { AxiosError, AxiosInstance } from "axios";
import { getMidwayApiClient, isResponseHtml } from "./daoUtil";
import { isResourceParityRegion } from "../utils/resourceParityUtil";

export class ResourceParityDao {
  getDefaultRegionListFetcher(): RegionFetcher {
    const regionDao: RegionDao = new RegionDao();
    return regionDao.getRegions.bind(regionDao);
  }

  async getParities(regionFetcher?: RegionFetcher): Promise<IResourceSpecificationByRegionDto[]> {
    const getRegions: RegionFetcher = regionFetcher ?? this.getDefaultRegionListFetcher();
    const regionDtos: IRegionMetadata[] = await getRegions();

    const regions: RegionVO[] = parseRegionDtoList(regionDtos);
    return this.getParitiesByRegions(regions);
  }

  async getParitiesByRegions(regions: RegionVO[]): Promise<IResourceSpecificationByRegionDto[]> {
    const regionWithData: RegionVO[] = regions.filter(isResourceParityRegion);

    const fetchPromises: Promise<IResourceSpecificationByRegionDto[]>[] = [
      this.getParityFromCache(regionWithData)
    ];

    return Promise.all(fetchPromises).then(
      ( parityByRegionChunks: IResourceSpecificationByRegionDto[][]) =>
        parityByRegionChunks.reduce((all, chunk) => all.concat(chunk), [])
    );
  }

  async getParityFromCache(regions: RegionVO[]): Promise<IResourceSpecificationByRegionDto[]> {
    const allRegionPromises: Promise<IResourceSpecificationByRegionDto>[] = regions.map(
      (region) => this.getSingleRegionParityFromCache(region)
    );

    return Promise.all(allRegionPromises);
  }

  getSingleRegionParityFromCache(region: RegionVO): Promise<IResourceSpecificationByRegionDto> {
    const client: AxiosInstance = getMidwayApiClient();

    return new Promise<IResourceSpecificationByRegionDto>((resolve, reject) => {
      let emptyEvents: IResourceSpecificationByRegionDto = {
        AirportCode: region.airportCode,
        Specification: {
          ResourceTypes: {},
          PropertyTypes: {},
        }
      }
      client.get<IResourceSpecificationDto>(this.getParityCacheUrl(region)).then((resp) => {
        // Backend may return 404 in the form of a success http call with response body as html
        resolve(
          isResponseHtml(resp.data) ? emptyEvents : {
            AirportCode: region.airportCode,
            Specification: resp.data
          }
        );
      }).catch((e: AxiosError) => {
        // Given the regions are from the database, it is possible that the cache does not have the
        // requested region, when it happens, just return empty events.  Browser console log
        // will still show the 404 error
        if (e.response?.status === 404) {
          resolve(emptyEvents);
        } else {
          reject(e);
        }
      })
    });
  }

  getParityCacheUrl(region: RegionVO): string {
    return `/static-reference-data/cloudformation/${region.airportCode}.json`;
  }

  async getCloudFormationIdMap(): Promise<string> {
    const client: AxiosInstance = getMidwayApiClient();

    const res = await client.get("/static-reference-data/maps/cloudFormationIdMap.txt");
    return res.data;
  }
}
