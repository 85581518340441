import React from "react";
import { Route, Switch } from "react-router-dom";
import routes, { redirects } from "./Routes";
import { SecureRoute } from "./components/SecureRoute/SecureRoute";
import { BannerContainer } from "./views/BannerContainer/BannerContainer";

export const ReconRouter = ({ hasApplicationStateLoaded }) => (
  <Switch>
    { redirects }
    {routes.map((route) => {
      return (route.secure) ?
        <SecureRoute hasApplicationStateLoaded={hasApplicationStateLoaded} {...route} />
        : <Route {...route} />;
    })}
  </Switch>
);

export const NotificationRouter = () => (
  <Switch>
    {routes.map((route) => {
      const notificationComponent = route.notification || BannerContainer;
      const routeProp = {
        ...route,
        component: notificationComponent,
      };
      return <Route {...routeProp} />;
    })}
  </Switch>
);
