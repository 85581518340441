import { FileExtension } from "@amzn/api-parity-react-component/lib/models/FileExtension";
import { ExportDataSheet, ExportFormat, RichDataCell, saveDataSheet } from "@amzn/excelerator";
import { getDataCellRecord, getRichDataCell } from "@amzn/excelerator/lib/excel/excelUtil";
import moment from "moment";
import { IFeatureServiceInstance } from "../../daos/types/IGetFeatureDto";
import { getDateForFileName } from "../../utils/dateUtil";
import { Optional } from "@amzn/api-parity-react-component/lib/models/Nullable";
import { FeatureInRegionCommentVO } from "../../models/vos/FeatureInRegionCommentVO";
import { Nullable } from "../../models/types/Nullable";

type GetComment = (feature: string, region: string) => Optional<FeatureInRegionCommentVO>

export enum RipStatusEnum {
  NOT_PLANNED = "NOT_PLANNED",
  PLANNED = "PLANNED",
  BUILD = "BUILD",
  IA = "IA",
  GA = "GA",
}

export const isRipBuildStatus = (inRegionStatus: IFeatureServiceInstance) : boolean => {
  return inRegionStatus?.status === RipStatusEnum.NOT_PLANNED ||
    inRegionStatus?.status === RipStatusEnum.PLANNED ||
    inRegionStatus?.status === RipStatusEnum.BUILD ||
    inRegionStatus?.status === RipStatusEnum.IA
};

export const getGreenRichDataCell = (statusText) => {
  return new RichDataCell({
    backgroundColor: "#739d6b"
  }).withData( getDataCellRecord(statusText) );
};

export const getRedRichDataCell = (statusText) => {
  return new RichDataCell({
    backgroundColor: "#d58d8a"
  }).withData( getDataCellRecord(statusText) );
};

const commentAttributesToExport = {
  "reason": "Reason",
  "sim": "Sim",
  "whatsNewLink": "What's new Link",
  "note": "Note",
  "author": "Author",
  "timestamp": "Created On",
  "resolved": "This comment is resolved",
}

const timestampCharacters = 10;  // only show the date portion

export const convertFeatureInRegionComment = (comment?: FeatureInRegionCommentVO): Nullable<string> => {
  if (!comment) {
    return null;
  }

  let commentAttributes: string[] = [];

  for (let attribute in commentAttributesToExport) {
    if (comment[attribute]) {
      if (attribute === "resolved") {
        commentAttributes.push(commentAttributesToExport[attribute]);
      } else if (attribute === "timestamp") {
        // @ts-ignore
        commentAttributes.push(`${commentAttributesToExport[attribute]}: ${comment[attribute].substr(0, timestampCharacters)}`)
      } else {
        commentAttributes.push(`${commentAttributesToExport[attribute]}: ${comment[attribute]}`);
      }
    }
  }
  return commentAttributes.join("\n");
}

export const getCellValueBasedOnStatus = (inRegionStatus: IFeatureServiceInstance, comment?: FeatureInRegionCommentVO) => {
  let returnCell;

  if (inRegionStatus?.status === RipStatusEnum.GA) {
    const statusText = inRegionStatus?.status
    returnCell = getGreenRichDataCell(statusText)
  }
  else if (inRegionStatus?.estimatedLaunchDate) {
    const diff = moment().diff(inRegionStatus?.estimatedLaunchDate);
    const date_is_in_past = diff > 0;
    const dateFormatted = new Date(inRegionStatus?.estimatedLaunchDate)
    // @ts-ignore
    returnCell = date_is_in_past ? getRedRichDataCell(dateFormatted) : getRichDataCell(dateFormatted)
  }
  else if (inRegionStatus?.status === RipStatusEnum.NOT_PLANNED) {
    const statusText = inRegionStatus?.status
    returnCell = getRedRichDataCell(statusText)
  }
  else if (isRipBuildStatus(inRegionStatus)) {
    returnCell = getRichDataCell(inRegionStatus.status);
  }
  else {
    returnCell = getRedRichDataCell(inRegionStatus)
  }

  returnCell.comment = convertFeatureInRegionComment(comment);

  return returnCell;
}

export const generateDocumentItem = (serviceName, displayName, featureInstance, regionsOrdered, getComment?: GetComment) => {
  const composedItem = [
    serviceName,
    displayName,
    featureInstance.id,
    featureInstance.longName,
    featureInstance?.type ?? "feature",
    featureInstance?.parityPercentage,
  ];

  regionsOrdered.forEach((region) => {
    const inRegionStatus = featureInstance[region] ?? RipStatusEnum.NOT_PLANNED
    const comment = getComment?.(featureInstance.id, region)
    composedItem.push(getCellValueBasedOnStatus(inRegionStatus, comment))
  });

  return composedItem;
};

export const generateWorkbook = (serviceName, allFeatureData, regionsOrdered, displayName, getComment?: GetComment) => {
  const documentHeader = [
    "Parent Service Shortname",
    "Parent Service Longname",
    "Feature Shortname",
    "Feature Longname",
    "RIP Type",
    "Parity Percentage"
  ].concat(regionsOrdered)

  const csvExportData = [documentHeader];
  const allFeatureDataSorted = allFeatureData.sort((a, b) => (a.parityPercentage - b.parityPercentage));
  allFeatureDataSorted.forEach((item) => { csvExportData.push(generateDocumentItem(serviceName, displayName, item, regionsOrdered, getComment)) });
  const dataSheet: ExportDataSheet = new ExportDataSheet({ rows: csvExportData });
  return dataSheet
}

export const createSaveDataSheet = async (item, serviceName, dataSheet) => {
  const format: ExportFormat = item.id as ExportFormat;
  const extension: string = (format === ExportFormat.Xlsx) ? FileExtension.Excel : FileExtension.Csv;
  const filename: string = `${serviceName} Feature Parity (${getDateForFileName()}).${extension}`;
  await saveDataSheet(dataSheet, filename, format);
}
