import * as React from "react";
import { FunctionComponent } from "react";
import User from "../../common/User";
import { LxsVO, ServiceContactsVO } from "../../../models/vos/ServiceContactsVO";
import { CtiVO } from "../../../models/vos/CtiVO";
import { LeadershipChainComponent } from "../ServiceContactsChainComponent/LeadershipChainComponent";
import { getItemsWithMissingInfo, getLeadershipByLevel, getServiceNameCell } from "./utils/serviceContactsTableUtil";
import { Link } from "@amzn/awsui-components-react-v3";
import { getNonEmptyProperties, getUnique } from "../../../utils/collectionUtil";
import { Table } from "@amzn/awsui-components-react";
import { filterPropertyKey } from "./serviceContactTable.constants";
import TableContentSelector from "@amzn/awsui-components-react/polaris/table-content-selector/table-content-selector";
import TablePropertyFiltering
  from "@amzn/awsui-components-react/polaris/table-property-filtering/table-property-filtering";
import { Nullable } from "../../../models/types/Nullable";
import { displayOr } from "../../../utils/displayUtil";
import Popover from "@amzn/awsui-components-react-v3/polaris/popover";
import { removeWikiPrefix } from "../SingleServiceContactsCard/cards-config";

const WARNING_SIGN_PIXEL_LENGTH = 16
const USER_LINK_LENGTH = 200
const ARROW_ICON_LENGTH = 20
const REDIRECT_TO_CONTACT = true

export const SORTABLE_COLUMNS = [
  { id: "partition", field: "partition" },
  { id: "gm", field: "gm" },
  { id: "vp", field: "vp" },
  { id: "primaryBuildPoc", field: "primaryBuildPoc" },
  { id: "secondaryBuildPoc", field: "secondaryBuildPoc" },
  { id: "rbtServiceManager", field: "rbtServiceManager" },
  { id: "serviceName", field: "serviceName" },
];

export const PAGINATION_OPTIONS = {
  pageSize: 100
};

export const PAGE_SIZE_SELECTOR_OPTIONS = {
  title: "Page size",
  options: [
    { value: 50, label: "50 items" },
    { value: 100, label: "100 items" },
    { value: 200, label: "200 items" }
  ]
};

export type CTIComponentProps = {
  cti: CtiVO
}

export const CTIComponent: FunctionComponent<CTIComponentProps> = (props) => {
  return (
    <Link
      href={`https://cti.amazon.com/cti/category/${props.cti.category}/type/${props.cti.type}/item/${props.cti.item}`}
      external={true}>
      {`${props.cti.category}/${props.cti.type}/${props.cti.item}`}
    </Link>
  )
}

export function getChainColumnWidth(items: ServiceContactsVO[]): number {
  const chainLengths = items.map((item) => item.leadershipChain?.length ?? 0)
  const maxLength = Math.max.apply(null, chainLengths);
  let chainLinks = (maxLength && maxLength > 0) ? maxLength : 1;
  return (chainLinks * USER_LINK_LENGTH) + ((chainLinks - 1) * ARROW_ICON_LENGTH);
}

export function getServiceNameColumnWidth(items: ServiceContactsVO[]): number {
  const serviceNameLengths = items.map((item) => item.serviceName?.length ?? 0);
  const maxLength = Math.max.apply(null, serviceNameLengths);
  return (maxLength * 6) + WARNING_SIGN_PIXEL_LENGTH;
}

export function ripContactsDisplay(ripContacts: string[] | undefined): string {
  if (!ripContacts || ripContacts.length === 0)
    return "-";
  let suffixedContacts = ripContacts.map(c => c + "@");
  suffixedContacts.sort();   // destructive
  return suffixedContacts.join(", ");
}

export const getColumnDefinitions = (items: ServiceContactsVO[], regionsByPartition): Table.ColumnDefinition<ServiceContactsVO>[] => {
  const colDefs = [
    {
      id: "serviceName",
      header: "Service Name",
      cell: item => getServiceNameCell(item, REDIRECT_TO_CONTACT),
      width: getServiceNameColumnWidth(items),
    },
    {
      id: "partition",
      header: "Partition",
      cell: ({ partition }) =>
        displayOr(partition, () => partition)
      ,
      width: 100,
    },
    {
      id: "regions",
      header: "Regions",
      cell: ({ partition }) =>
        displayOr(regionsByPartition[partition], () => partition === "aws" ?
          <Popover
            dismissButton={false}
            position="right"
            size="small"
            triggerType="custom"
            content={regionsByPartition[partition]}
          >
            <p style={{ borderBottom: "1px dotted black" }}>All Commercial</p>
          </Popover> : regionsByPartition[partition])
      ,
      width: 150
    },
    {
      id: "gm",
      header: "GM",
      cell: ({ gm }) =>
        displayOr(gm, () => <User alias={gm as string} />),
      width: 150,
    },
    {
      id: "vp",
      header: "VP",
      cell: ({ vp }) =>
        displayOr(vp, () => <User alias={vp as string} />),
      width: 150,
    },
    {
      id: "primaryBuildPoc",
      header: "Primary Build POC",
      cell: ({ primaryBuildPoc }) =>
        displayOr(primaryBuildPoc, () => <User alias={primaryBuildPoc as string} />)
      ,
      width: 150,
    },
    {
      id: "secondaryBuildPoc",
      header: "Secondary Build POC",
      cell: ({ secondaryBuildPoc }) => (
        displayOr(secondaryBuildPoc, () => <User alias={secondaryBuildPoc as string} />)
      ),
      width: 150,
    },
    {
      id: "rbtServiceManager",
      header: "Build TPM",
      cell: ({ rbtServiceManager }) => (
        displayOr(rbtServiceManager, () => <User alias={rbtServiceManager as string} />)
      ),
      width: 150,
    },
    {
      id: "leadershipChain",
      header: "Leadership Chain",
      cell: ({ leadershipChain, gm, vp }) => (
        displayOr(leadershipChain, () => <LeadershipChainComponent gm={gm} vp={vp} leadershipChain={leadershipChain as LxsVO[]} />)
      ),
      width: getChainColumnWidth(items),
    },
    {
      id: "cti",
      header: "CTI",
      cell: ({ cti }) => (
        displayOr(cti, () => <CTIComponent cti={cti as CtiVO} />)
      ),
      width: 250,
    },
    {
      id: "ripContacts",
      header: "RIP Contacts",
      cell: ({ ripContacts }) => ripContactsDisplay(ripContacts),
      width: 200,
    },
    {
      id: "simGuid",
      header: "Sim GUID",
      cell: ({ simGuid }) => (
        displayOr(simGuid)
      ),
      width: 200,
    },
    {
      id: "notifications",
      header: "Notifications Alias",
      cell: ({ notifications }) => (
        displayOr(notifications)
      ),
      width: 200,
    },
    {
      id: "azServiceOwner",
      header: "AZ Service Owner",
      cell: ({ azServiceOwner }) => (
        displayOr(azServiceOwner, () => <User alias={azServiceOwner as string} />)

      ),
      width: 150,
    },
    {
      id: "localZoneServiceOwner",
      header: "Local Zone Service Owner",
      cell: ({ localZoneServiceOwner }) => (
        displayOr(localZoneServiceOwner, () => <User alias={localZoneServiceOwner as string} />)
      ),
      width: 150,
    },
    {
      id: "vpceBuildOwner",
      header: "VPCE Build Owner",
      cell: ({ vpceBuildOwner }) => (
        displayOr(vpceBuildOwner, () => <User alias={vpceBuildOwner as string} />)
      ),
      width: 150,
    },
    {
      id: "wikiUrl",
      header: "Wiki",
      cell: ({ wikiUrl }) => (
        displayOr(wikiUrl, () =>
          <Link
            href={wikiUrl}
            external={true}>
            {removeWikiPrefix(wikiUrl)}
          </Link>)
      ),
      width: 150,
    },
    {
      id: "oncallTeam",
      header: "Oncall Team",
      cell: ({ oncallTeam }) => (
        displayOr(oncallTeam, () =>
          <Link
            href={"https://oncall.corp.amazon.com/#/view/" + oncallTeam}
            external={true}>
            {oncallTeam}
          </Link>)
      ),
      width: 150,
    },
    {
      id: "opsDashboard",
      header: "Operational Dashboard",
      cell: ({ opsDashboard }) => (
        displayOr(opsDashboard, () =>
          <Link
            href={opsDashboard}
            external={true}>
            Dashboard
          </Link>)
      ),
      width: 150,
    },
  ];
  return colDefs;
};

export interface ITextFilteringStrategy {
  startsWith: (text: string) => boolean,
  includes: (text: string) => boolean,
}

/**
 * Max number of items displayed in text filter per property key
 * The higher the number, the more performance penalty would be because Polaris V2
 * does not have virtualization built in
 */
export interface ITextFilteringOption {
  maxContact: number,
  maxNonContact: number,
}

export const defaultTextFilteringOption: ITextFilteringOption = Object.freeze<ITextFilteringOption>({
  maxContact: 50,
  maxNonContact: 25,
});

/**
 * Turn the input filtering text into lowercase and remove padding whitespace
 * @param filteringText
 */
export function normalizeFilteringText(filteringText: Nullable<string>): string {
  if (!filteringText) {
    return "";
  }
  return filteringText.trim().toLowerCase();
}

/**
 * Return a set of functions which would check if the supplied value would satisfy the filtering text
 * Empty value would be ignore in the filter, thus return functions would always yield true
 * @param filteringText
 * @return An object which has startsWith and includes functions
 */
export function getTextFilteringStrategy(filteringText: string): ITextFilteringStrategy {
  const seek: string = normalizeFilteringText(filteringText);
  if (seek === "") {
    return {
      startsWith: () => true,
      includes: () => true,
    }
  }

  return {
    startsWith: (text: string) => text.startsWith(seek),
    includes: (text: string) => text.includes(seek),
  }
}

/**
 * Optimize non-alias values that would be used for filtering by
 * taking the raw available non alias values,
 * removing the duplicates,
 * filtering them using the strategy supplied by filterStrategy,
 * and trimming the result supplied by the option parameter.
 *
 * The code sorts the values so it would be more usable for end users;
 * it filters the value because there is a bug with Polaris not being able to filter
 * options when custom filter function is supplied AWSUI-13828;
 * it also trims the values to limit the options shown in filter popup which can
 * cause performance issues.
 *
 * @param values
 * @param filterStrategy
 * @param option
 */
export function refineValuesForNonAliasFilter(values: string[], filterStrategy: ITextFilteringStrategy, option: ITextFilteringOption): string[] {
  return getUnique(values)
    .filter(filterStrategy.includes)
    .sort()
    .slice(0, option.maxNonContact);
}

/**
 * Optimize amazon alias (usernames) values that would be used for filtering by
 * taking the raw available non alias values,
 * removing the duplicates,
 * filtering them using the strategy supplied by filterStrategy,
 * and trimming the result supplied by the option parameter.
 *
 * The code sorts the values so it would be more usable for end users;
 * it filters the value because there is a bug with Polaris not being able to filter
 * options when custom filter function is supplied AWSUI-13828;
 * it also trims the values to limit the options shown in filter popup which can
 * cause performance issues.
 *
 * @param values
 * @param filterStrategy
 * @param option
 */
export function refineValuesForAliasFilter(values: string[], filterStrategy: ITextFilteringStrategy, option: ITextFilteringOption): string[] {
  return getUnique(values)
    .filter(filterStrategy.startsWith)
    .sort()
    .slice(0, option.maxContact);
}


export function getFilteringOptions(items: ServiceContactsVO[], filteringText: string, option: ITextFilteringOption = defaultTextFilteringOption): TablePropertyFiltering.Option[] {
  const textFilter: ITextFilteringStrategy = getTextFilteringStrategy(filteringText);
  const opts = [
    {
      groupValuesLabel: "RIP Shortnames",
      propertyKey: filterPropertyKey.serviceName,
      propertyLabel: "RIP Shortname",
      values: refineValuesForNonAliasFilter(
        getNonEmptyProperties(items, (item) => item.serviceName),
        textFilter,
        option)
    },
    {
      groupValuesLabel: "Long Names",
      propertyKey: filterPropertyKey.nameLong,
      propertyLabel: "Long Name",
      values: refineValuesForNonAliasFilter(getNonEmptyProperties(items, (item) => item.nameLong),
        textFilter,
        option)
    },
    {
      groupValuesLabel: "Sortable Names",
      propertyKey: filterPropertyKey.nameSortable,
      propertyLabel: "Sortable Name",
      values: refineValuesForNonAliasFilter(getNonEmptyProperties(items, (item) => item.nameSortable),
        textFilter,
        option)
    },
    {
      groupValuesLabel: "Partitions",
      propertyKey: filterPropertyKey.partition,
      propertyLabel: "Partition",
      values: refineValuesForNonAliasFilter(getNonEmptyProperties(items, (item) => item.partition),
        textFilter,
        option)
    },
    {
      groupValuesLabel: "GMs",
      propertyKey: filterPropertyKey.gm,
      propertyLabel: "GM",
      values: refineValuesForAliasFilter(getNonEmptyProperties(items, (item) => item.gm),
        textFilter,
        option)
    },
    {
      groupValuesLabel: "VPs",
      propertyKey: filterPropertyKey.vp,
      propertyLabel: "VP",
      values: refineValuesForAliasFilter(getNonEmptyProperties(items, (item) => item.vp),
        textFilter,
        option)
    },
    {
      groupValuesLabel: "Primary Build POCs",
      propertyKey: filterPropertyKey.primaryBuildPoc,
      propertyLabel: "Primary Build POC",
      values: refineValuesForAliasFilter(getNonEmptyProperties(items, (item) => item.primaryBuildPoc),
        textFilter,
        option)
    },
    {
      groupValuesLabel: "Secondary Build POCs",
      propertyKey: filterPropertyKey.secondaryBuildPoc,
      propertyLabel: "Secondary Build POC",
      values: refineValuesForAliasFilter(getNonEmptyProperties(items, (item) => item.secondaryBuildPoc),
        textFilter,
        option)
    },
    {
      groupValuesLabel: "Build TPM",
      propertyKey: filterPropertyKey.rbtServiceManager,
      propertyLabel: "Build TPM",
      values: refineValuesForAliasFilter(getNonEmptyProperties(items, (item) => item.rbtServiceManager),
        textFilter,
        option)
    },
    {
      groupValuesLabel: "Level 7s",
      propertyKey: filterPropertyKey.l7s,
      propertyLabel: "Level 7",
      values: refineValuesForAliasFilter(getLeadershipByLevel(items, 7)
        .filter(textFilter.startsWith).sort().slice(0, option.maxContact),
        textFilter,
        option)
    },
    {
      groupValuesLabel: "Level 8s",
      propertyKey: filterPropertyKey.l8s,
      propertyLabel: "Level 8",
      values: refineValuesForAliasFilter(getLeadershipByLevel(items, 8)
        .filter(textFilter.startsWith).sort().slice(0, option.maxContact),
        textFilter,
        option)
    },
    {
      groupValuesLabel: "Level 10s",
      propertyKey: filterPropertyKey.l10s,
      propertyLabel: "Level 10",
      values: refineValuesForAliasFilter(getLeadershipByLevel(items, 10)
        .filter(textFilter.startsWith).sort().slice(0, option.maxContact),
        textFilter,
        option)
    },
    {
      groupValuesLabel: "Missing Leadership Info?",
      propertyKey: filterPropertyKey.missingInfo,
      propertyLabel: "Missing Leadership Info?",
      values: refineValuesForNonAliasFilter(getItemsWithMissingInfo(items)
        .filter(textFilter.startsWith).slice(0, option.maxNonContact),
        textFilter,
        option)
    },
    {
      groupValuesLabel: "RIP Contacts",
      propertyKey: filterPropertyKey.ripContacts,
      propertyLabel: "RIP Contacts",
      values: refineValuesForAliasFilter(getNonEmptyProperties(items, (item) => item.ripContacts).flat(),
        textFilter,
        option)
    },
  ];
  return opts;
};

export const getContentSelectorOptions: TableContentSelector.Props = {
  title: "Select visible columns",
  options: [
    {
      label: "Columns",
      options: [
        { id: "serviceName", label: "Service Name", visible: true },
        { id: "partition", label: "Partition", visible: true },
        { id: "regions", label: "Regions", visible: true },
        { id: "gm", label: "GM", visible: false },
        { id: "vp", label: "VP", visible: false },
        { id: "primaryBuildPoc", label: "Primary Build POC", visible: true },
        { id: "secondaryBuildPoc", label: "Secondary Build POC", visible: true },
        { id: "rbtServiceManager", label: "Build TPM", visible: true },
        { id: "azServiceOwner", label: "AZ Service Owner", visible: false },
        { id: "localZoneServiceOwner", label: "Local Zone Service Owner", visible: false },
        { id: "vpceBuildOwner", label: "VPCE Build Owner", visible: false },
        { id: "notifications", label: "Notifications Alias", visible: true },
        { id: "ripContacts", label: "RIP Contacts", visible: true },
        { id: "cti", label: "CTI", visible: true },
        { id: "oncallTeam", label: "Oncall Team", visible: true },
        { id: "opsDashboard", label: "Operational Dashboard", visible: false },
        { id: "simGuid", label: "Sim GUID", visible: false },
        { id: "wikiUrl", label: "Wiki", visible: false },
        { id: "leadershipChain", label: "Leadership Chain", visible: true },
      ]
    }
  ]
};
