import { hydrateModel } from "../../utils/modelUtil";
import {
  ILaunchContact,
  ILaunchDocument,
  ILaunchState,
} from "../../daos/types/ILaunchDto";

export class LaunchContactsVO {
  productManager?: string;
  engineeringLead?: string;
  marketingLead?: string;
  other?: ILaunchContact[];

  constructor(from?: Partial<LaunchContactsVO>) {
    hydrateModel(from, this);
  }
}

export interface ILaunchQuestionnaire {
  regulated?: boolean;
  pricingChangeInvolved?: boolean;
  namingInvolved?: boolean;
  prfaqRequired?: boolean;
}

// TODO Populate with more keys from ILaunchDto
export class LaunchVO {
  id: string = "";
  checkListIds: string[] = [];
  name: string = "";
  codename: string = "";
  ripId: string = "";
  productId: string = "";
  type?: string;
  iteration?: string;
  tier?: string;
  scope?: string;
  date?: string; // replace with a date type?
  status?: string;
  audience?: string;
  confidential?: boolean;
  state?: ILaunchState;
  slips?: number;
  daysLate?: number;
  disclosureLevel?: string;
  regions?: string[];
  description?: string;
  trackerLink?: string;
  trackerLinkType?: string;
  reasonForChange?: string;
  goal?: string; // update with enum?
  goalLink?: string;
  originalLaunchDate?: string;
  previousLaunchDate?: string;
  rowId?: string;
  ownerType?: string;
  ownerAlias?: string;
  blueprintUid?: string;

  // questionnaire values
  questionnaire: ILaunchQuestionnaire = {};

  contacts?: LaunchContactsVO;
  docReviews?: ILaunchDocument[];
  tags?: string[];

  readGroups?: string[];
  writeGroups?: string[];

  constructor(from?: Partial<LaunchVO>) {
    hydrateModel(from, this);
  }
}
