import * as React from "react";
import { FunctionComponent } from "react";
import { useHistory } from "react-router-dom";
import { Box, Button, Header, Modal, SpaceBetween } from "@amzn/awsui-components-react-v3";
import { useForm } from "react-hook-form";
import styled from "styled-components";
import { RegionPicker } from "../../ProductLaunches/LaunchWizard/stepComponents/step2";
import { IUpdateLaunchDto } from "../../../daos/types/ILaunchDto";
import { ButtonBar } from "../../common/ButtonBar/ButtonBar";
import { getDataTestIdProp } from "../../../utils/testUtil";
import { EditLaunchRegionsProps } from "./EditLaunchRegions.types";

const RegionsGrid = styled("div")`
  max-width: 1000px;
`;

const RightAlign = styled("div")`
  float: right;
`;

export const EditLaunchRegionsTestIds = {
  submitButton: "-submitButton",
  cancelButton: "-cancelButton",
  errorModal: "-errorModal"
}

export const EditLaunchRegions: FunctionComponent<EditLaunchRegionsProps> = (props) => {
  const [visibleErrorModal, setVisibleErrorModal] = React.useState(false);
  const { launch, regions } = props
  const { control, getValues } =
    useForm({
      mode: "all",
      shouldUnregister: false
    })
  const history = useHistory();

  function validateForm() {
    const isARegionSelected = Object.keys(getValues()).filter((entry) => entry.startsWith("region:")).some((regionFormCode) => getValues(regionFormCode))
    if (!isARegionSelected) {
      setVisibleErrorModal(true)
      return false;
    }
    return true;
  }

  function handleOnSubmit() {
    const updatedRegions: IUpdateLaunchDto = {
      id: launch.id,
      regions: Object.keys(getValues()).filter((entry) =>
        entry.startsWith("region:") && getValues(entry) === true).map
      ((selectedRegion) => selectedRegion.split(":")[2])
    }
    props.onSubmit(launch.id, updatedRegions)
  }

  return (
    <RegionsGrid>
      <SpaceBetween size={"xxl"}>

        <Header variant={"h1"}>
          {`Editing regions for ${launch.name}`}
        </Header>

        <RegionPicker {...getDataTestIdProp(props)} control={control} regions={regions} selectedRegions={launch.regions}/>

        <RightAlign>
          <ButtonBar>
            <Button
              {...getDataTestIdProp(props, EditLaunchRegionsTestIds.cancelButton)}
              variant="normal"
              onClick={() => history.push(`/alm/launches/${launch.id}?tabId=regions`)}
              disabled={props.loading}
            >
              Cancel
            </Button>
            <Button
              {...getDataTestIdProp(props, EditLaunchRegionsTestIds.submitButton)}
              variant="primary"
              onClick={() => validateForm() && handleOnSubmit()}
              loading={props.loading}
            >
              Submit
            </Button>
          </ButtonBar>
        </RightAlign>

      </SpaceBetween>
      <Modal
        {...getDataTestIdProp(props, EditLaunchRegionsTestIds.errorModal)}
        onDismiss={() => setVisibleErrorModal(false)}
        visible={visibleErrorModal}
        closeAriaLabel="Close modal"
        size="medium"
        footer={
          <Box float="right">
            <SpaceBetween direction="horizontal" size="xs">
              <Button
                variant="primary"
                onClick={() => setVisibleErrorModal(false)}
              >
                Ok
              </Button>
            </SpaceBetween>
          </Box>
        }
        header="Invalid Selection"
      >
        Please select at least one region
      </Modal>
    </RegionsGrid>


  )
}
