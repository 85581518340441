import * as React from "react";
import { AwsServiceExpansionPlanEnum, RegionCollectionView, RegionVO, } from "@amzn/api-parity-react-component";
import { Cell, Column, HeaderCell } from "rsuite-table";
import { FeatureTreeItem } from "../../../../../models/FeatureTreeItem";
import {
  ParityScore,
  RegionHeader,
  RegionHeaderPercentage,
  RegionHeaderText,
} from "./FeatureParityOverviewGrid.style";
import { FeatureParityDataSortKey } from "../models/FeatureParityDataSortKey";
import { composeDataTestId } from "../../../../../utils/testUtil";
import {
  FeatureParityOverviewGridTestId,
  FeatureParityOverviewGridTestIdSuffix
} from "./FeatureParityOverviewGridTestId";
import {
  FeatureParityCollectionView,
  ServiceInRegionRollupStatusCollectionView
} from "../../../../../models/collections";
import { getFeatureTreeItemService, getGm, getVp } from "../../../../../utils/featureTreeItemUtil";
import { getItemLink, getParityDisplay, getUserDisplay } from "../../featureParityDisplayUtil";
import { ServiceCategory } from "../../../../ServiceCategory/ServiceCategory";
import { ServiceCategoryVariant } from "../../../../ServiceCategory/ServiceCategory.types";
import { FeatureParityScoreCalculatorResult } from "../../../../../utils/FeatureParityScoreCalculator";
import { toPercent } from "@amzn/api-parity-react-component/lib/utils/modelUtil";

export const zeroScoreValue = new Set([undefined, null]);

const categoryColumn = (
  <Column verticalAlign={"top"} key="category" width={100} sortable={true}>
    <HeaderCell>Category</HeaderCell>
    <Cell dataKey={FeatureParityDataSortKey.category}>
      {
        (rowData: FeatureTreeItem) => {
          const service = getFeatureTreeItemService(rowData);
          return (
            <ServiceCategory category={service?.expansionPlan ?? AwsServiceExpansionPlanEnum.None} variant={ServiceCategoryVariant.abbreviation} />
          )
        }
      }
    </Cell>
  </Column>
);

// @ts-ignore
export function getColumns(
  regions: RegionCollectionView,
  featureParities: FeatureParityCollectionView,
  featureParityRollupByService: ServiceInRegionRollupStatusCollectionView,
  scoreResult: FeatureParityScoreCalculatorResult,
): typeof Column[] {
  // large number of ts-ignore is here because
  // there is a wrong type definition on HeaderCell/Cell where
  // it marks width and depth as required
  // This is a known bug acknowledged by the author https://github.com/rsuite/rsuite-table/pull/206
  // @ts-ignore
  const result: Column[] = [
    <Column verticalAlign={"top"} key="name" width={320} resizable={true} fixed={true}>
      <HeaderCell>Feature / Service Name</HeaderCell>
      <Cell>
      {
        (rowData: FeatureTreeItem) => (
          <span
            data-testid={composeDataTestId(
              FeatureParityOverviewGridTestId.component,
              FeatureParityOverviewGridTestIdSuffix.content.name,
              rowData.id,
            )}>
            {getItemLink(rowData)}
          </span>
        )
      }
      </Cell>
    </Column>,
    <Column verticalAlign={"top"} key="score" width={100} fixed={true} sortable={true}>
      <HeaderCell>Parity</HeaderCell>
      <Cell align={"right"} dataKey={FeatureParityDataSortKey.parityScore}>
        {
          (rowData: FeatureTreeItem) => (
            <ParityScore
              data-testid={composeDataTestId(
                FeatureParityOverviewGridTestId.component,
                FeatureParityOverviewGridTestIdSuffix.content.parityScore,
                rowData.id,
              )}>
              {
                // @ts-ignore
                !zeroScoreValue.has(scoreResult.byEntity.get(rowData.entity)) ?  toPercent(scoreResult.byEntity.get(rowData.entity) as number, 1)  : "-"
              }
            </ParityScore>
          )
        }
      </Cell>
    </Column>,
    categoryColumn,
    <Column verticalAlign={"top"} key="visibility" width={100} sortable={true}>
      <HeaderCell>Visibility</HeaderCell>
      <Cell dataKey={FeatureParityDataSortKey.visibility}>
      {
        (rowData: FeatureTreeItem) => {
          return rowData.entity.visibility
        }
      }
      </Cell>
    </Column>,
    <Column verticalAlign={"top"} key="gm" width={120} sortable={true}>
      <HeaderCell>GM</HeaderCell>
      <Cell dataKey={FeatureParityDataSortKey.gm}>
        {
          (rowData: FeatureTreeItem) => getUserDisplay(getGm(rowData))
        }
      </Cell>
    </Column>,
    <Column verticalAlign={"top"} key="vp" width={120} sortable={true}>
      <HeaderCell>VP</HeaderCell>
      <Cell dataKey={FeatureParityDataSortKey.vp}>
        {
          (rowData: FeatureTreeItem) => getUserDisplay(getVp(rowData))
        }
      </Cell>
    </Column>
  ];

  regions.items.forEach((region: RegionVO) => {
    result.push(
      <Column verticalAlign={"top"} key={region.airportCode} width={64}>
        <HeaderCell><RegionHeader><RegionHeaderText>{region.airportCode}</RegionHeaderText> <RegionHeaderPercentage>{
          // @ts-ignore
          scoreResult.byRegion.get(region) !== null ?  toPercent(scoreResult.byRegion.get(region) as number, 1)  : "-"
        }</RegionHeaderPercentage></RegionHeader></HeaderCell>
        <Cell>
          {
            (rowData: FeatureTreeItem) => {
              return getParityDisplay(rowData, region, featureParities, featureParityRollupByService).visual;
            }
          }
        </Cell>
      </Column>
    );
  });

  return result;
}

