import { ExportDataBook, ExportDataCell, ExportDataSheet, ExportFormat, saveDataBook } from "@amzn/excelerator";
import * as React from "react";
import { FunctionComponent, useCallback } from "react";
import { ExportTableToCSVExcelProps } from "./ExportTableToCSVExcel.types";
import { FileExtension } from "@amzn/api-parity-react-component/lib/models/FileExtension";
import { getDateForFileName } from "../../utils/dateUtil";
import { IActionMenuItem } from "@amzn/api-parity-react-component/lib/components/ActionMenu/ActionMenu.types";
import { getDataTestIdProp } from "../../utils/testUtil";
import { ButtonDropdown, Icon } from "@amzn/awsui-components-react-v3";
import { Optional } from "../../models/types/Optional";

export const actionMenu = [
  { id: ExportFormat.Xlsx, text: "Export to Excel" },
  { id: ExportFormat.Csv, text: "Export to CSV" },
];

export const emptyExportValue = "-"

export function getExportFileFormat(fileExtensionType: IActionMenuItem): ExportFormat {
  return fileExtensionType.id as ExportFormat;
}

export function getFileExtension(format: ExportFormat): string {
  return (format === ExportFormat.Xlsx) ? FileExtension.Excel : FileExtension.Csv;
}

export function getExportFileName(fileNamePrefix: string, extension: string): string {
  return `${fileNamePrefix}-${getDateForFileName()}.${extension}`;
}

export function dataToDataBook(excelData: ExportDataCell[][]): ExportDataBook {
  const databook: ExportDataBook = new ExportDataBook();
  let datasheet: ExportDataSheet = new ExportDataSheet({ rows: excelData })

  databook.sheets.push(datasheet);
  return databook;
}

export const ExportTableToCSVExcel: FunctionComponent<ExportTableToCSVExcelProps> = (props) => {
  const actionMenu_onChange: (fileExtensionType: IActionMenuItem) => Promise<void> = useCallback(async (fileExtensionType: IActionMenuItem) => {

    const format: ExportFormat = getExportFileFormat(fileExtensionType);
    const extension: string = getFileExtension(format);
    const filename: string = getExportFileName(props.fileNamePrefix, extension);

    await saveDataBook(props.generateDataBook(), filename, format);
  }, [props]);

  return(
    <div { ...getDataTestIdProp(props) }>
      <ButtonDropdown
        items={actionMenu}
        disabled={props.disabled}
        onItemClick={({ detail }) => {
          const selectedItem: Optional<IActionMenuItem> = actionMenu.find((i) => i.id === detail.id);
          if (selectedItem) {
            actionMenu_onChange(selectedItem);
          }
        }}
      >
        <Icon name="download"/>
      </ButtonDropdown>
    </div>
  )
};
