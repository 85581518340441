import * as React from "react";
import { FunctionComponent } from "react";
import { ServiceCategoryProps, ServiceCategoryVariant } from "./ServiceCategory.types";
import { IAwsServiceExpansionPlan } from "@amzn/api-parity-react-component";
import { expansionPlanById } from "@amzn/api-parity-react-component/lib/models/vos/AwsServiceExpansionPlan";
import { Popover } from "@amzn/awsui-components-react-v3";

/**
 * Display service category
 * @param props
 * @constructor
 */
export const ServiceCategory: FunctionComponent<ServiceCategoryProps> = (props) => {
  const plan: IAwsServiceExpansionPlan = expansionPlanById.get(props.category) as IAwsServiceExpansionPlan;

  if (props.variant === ServiceCategoryVariant.abbreviation) {
    return (<Popover
      dismissButton={false}
      renderWithPortal={true}
      triggerType="text"
      position="top"
      content={plan.name}
    >
      {plan.abbreviation}
    </Popover>);
  }

  return <React.Fragment>{plan.name}</React.Fragment>;
};
