import { Draft, Patch, produceWithPatches } from "immer";
import { snakeCase } from "lodash";
import { IPatchModel } from "../daos/types/IPatchModel";

const pathSeparator = "/";

export type ModifierFunction<T> = (draft: T) => void;

/**
 * Covert patch objects from immer to RFC-6902 patches, the only difference is
 * how they encode the path.
 */
export function toRfc6902Patches( immerPatches: Patch[] ): IPatchModel[] {
  // @ts-ignore
  return immerPatches.map((srcPatch: Patch) => {
    return {
      ...srcPatch,
      path: `${pathSeparator}${srcPatch.path.join(pathSeparator)}`
    };
  });
}


/**
 * Take an object and apply it to the modifier function, and get the RFC-6902 patch objects
 */
export function getPatches<T>( obj: T, modifierFn: ModifierFunction<T> ): IPatchModel[] {
  const [, patches] = produceWithPatches(
    obj,
    (draft: Draft<T>) => {
      // @ts-ignore
      modifierFn(draft);
    }
  );
  return toRfc6902Patches(patches);
}


/**
 * Convert patch path to snake case (because the backend takes snake case)
 * @param {string} path
 * @returns {string}
 */
export function snakeCasePath(path: string): string {
  const snakeParts = path.split(pathSeparator).map((part) => {
    return (part.length > 0) ? snakeCase(part) : part;
  });

  return snakeParts.join(pathSeparator);
}


export function snakeCasePatch(patch: IPatchModel) {
  return {
    ...patch,
    path: snakeCasePath(patch.path)
  };
}

export function snakeCasePatches(patches: IPatchModel[]): IPatchModel[] {
  return patches.map(snakeCasePatch);
}

