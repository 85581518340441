import memoize from "micro-memoize";
import { IChangeDto } from "../daos/types/IChangeDto";
import { ChangeType, ChangeVO, ReducedChangeVO } from "../models/vos/ChangeVO";

const recordOperations = [
  "INSERT",
  "REMOVE",
  "INITIAL_AUDIT"
]

export function changeDtoToVO(dto: IChangeDto): ChangeVO {
  const idParts = dto.attribute.split("+");
  const changeType =
    recordOperations.includes(idParts[1])
      ? ChangeType.RECORD_OPERATION : ChangeType.ATTRIBUTE;

  return new ChangeVO({
    attribute: dto.attribute,
    resourceId: dto.resourceId,
    target: idParts[1],
    type: changeType,
    timeChanged: dto.timeChanged ?? idParts[2],
    whoChanged: dto.whoChanged,
    newValue: dto.newValue,
    oldValue: dto.oldValue,
    reason: dto.reasonForChange,
  })
}

export function changeDtosToVOs(dtos: IChangeDto[]): ChangeVO[] {
  return dtos.map((dto) => changeDtoToVO(dto));
}

const changeReducerImplementation = (vos: ChangeVO[]) => {
  return vos.reduce<ReducedChangeVO[]>((reduced, vo) => {
    //if (vo.type === ChangeType) {
      const foundChange = reduced.find(el => el.resourceId === vo.resourceId && el.timeChanged === vo.timeChanged)
      if (foundChange) {
        foundChange.changes.push(vo);
      } else {
        reduced.push(new ReducedChangeVO({
          whoChanged: vo.whoChanged,
          reason: vo.reason,
          timeChanged: vo.timeChanged,
          resourceId: vo.resourceId,
          changes: [vo]
        }))
      }
    //}
    return reduced;
  }, []);
}

export const changeReducer = memoize(changeReducerImplementation);
