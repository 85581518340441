import * as React from "react";
import { FunctionComponent } from "react";
import { LoadingContainerProps } from "./LoadingContainer.types";
import { Container } from "@amzn/awsui-components-react-v3";
import { LoadingIndicator } from "../../LoadingIndicator/LoadingIndicator";

export const LoadingContainer: FunctionComponent<LoadingContainerProps> = (props) => {
  return (
    <Container {...props}>
      {props.loading
        ? <LoadingIndicator/>
        : props.children
      }
    </Container>
  );
};
