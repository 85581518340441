import React, { FunctionComponent, useEffect, useState } from "react";
import { Box, Button, Input, Link, Modal, SpaceBetween } from "@amzn/awsui-components-react-v3";
import { ReactRouterButton } from "../../common/RouterComponents/ReactRouterButton";
import { DeleteLaunchModalProps } from "./DeleteLaunchModal.types";
import { getDataTestIdProp } from "../../../utils/testUtil";
import { LaunchState } from "../../ProductLaunches/LaunchWizard/LaunchWizard.types";

export const DeleteLaunchModalTestIdSuffixes = {
  cancelButton: "-cancelButton",
  confirmButton: "-confirmButton",
  confirmInput: "-confirmInput",
  editButton: "-editButton",
  modal: "-modal",
}

export const DeleteLaunchModal: FunctionComponent<DeleteLaunchModalProps> = (props) => {
  const { visible, launch, validating, onDelete, onCancel } = props;

  const [inputValue, setInputValue] = useState("");
  const [canDelete, setCanDelete] = useState(false);

  useEffect(() => {
    if (inputValue.toLowerCase() === "delete launch")
      setCanDelete(true);
    else
      setCanDelete(false);
  }, [inputValue]);

  return (
    <Modal
      visible={visible}
      closeAriaLabel="Close modal"
      header="Delete launch"
      onDismiss={() => onCancel()}
      footer={
        <Box float="right">
          <SpaceBetween size="xs" direction="horizontal">
            <Button
              variant="link"
              onClick={() => onCancel()}
              {...getDataTestIdProp(props, DeleteLaunchModalTestIdSuffixes.cancelButton)}
            >
              Cancel
            </Button>
            {(launch.state === "CANCELLED" || launch.state === "DRAFT") &&
              <Button
                disabled={!canDelete}
                loading={validating}
                variant="primary"
                onClick={() => {
                  onDelete();
                }}
                {...getDataTestIdProp(props, DeleteLaunchModalTestIdSuffixes.confirmButton)}
              >
                Delete launch
              </Button>
            }
          </SpaceBetween>
        </Box>
      }
      {...getDataTestIdProp(props, DeleteLaunchModalTestIdSuffixes.modal)}
    >
      {(launch.state === "CANCELLED" || launch.state === "DRAFT")
        ? <>
          <Box>Delete the following launch permanently? This action cannot be undone.
            <ul>
              <li>
                {launch.name} (<Link href={`/insights/alm/launches/${launch.id}`} external>{launch.id}</Link>)
              </li>
            </ul>
          </Box>
          <hr/>
          <Box>
            To confirm deletion, type <i>delete launch</i> in the field below.
          </Box>
          <Box padding={{ top: "s" }}>
            <Input
              onChange={({ detail }) => setInputValue(detail.value)}
              value={inputValue}
              placeholder="delete launch"
              {...getDataTestIdProp(props, DeleteLaunchModalTestIdSuffixes.confirmInput)}
            />
          </Box>
        </>
        : <>
          <Box>
            This launch is currently <b>"{launch.state ? LaunchState[launch.state] : "-"}"</b>. To delete this launch, you must set the 
            launch state to <b>"Cancelled"</b> and provide a reason for the cancellation for teams expecting this launch to happen.
          </Box>
          <Box>
            Once updated, click <b>"Delete Launch"</b> to remove this launch. <b>"Shipped launches"</b> cannot be deleted.
          </Box>
          <Box padding={{ top: "s" }}>
            <ReactRouterButton
              to={`/alm/launches/${props.launch.id}/edit`}
              variant="primary"
              {...getDataTestIdProp(props, DeleteLaunchModalTestIdSuffixes.editButton)}
            >
              Edit launch
            </ReactRouterButton>
          </Box>
        </>
      }
    </Modal>
  );
}
