import styled from "styled-components";
import { BreadcrumbGroup } from "@amzn/awsui-components-react";

const BaseBreadcrumbGroupWithMargins = styled(BreadcrumbGroup)`
  nav.awsui-breadcrumb-group {
    margin: 2rem 0 0 2.5rem;
  }
`;

export default BaseBreadcrumbGroupWithMargins;
