import React from "react";
import RegionsListTable from "../../components/RegionsList/RegionsListTable";
import { Loading } from "../../components/common/Loading"
import useBreadcrumbs from "../../components/Breadcrumbs/useBreadcrumbs";
import useSWR from "swr";
import { getSwrConfig } from "../../daos/swrUtil";
import { RegionDao } from "../../daos/RegionDao";
import { getAppState } from "../../models/ApplicationState";
import { NoAccessError } from "../../components/common/NoAccessError";
import { Flash } from "@amzn/awsui-components-react";

const RegionsListContainer = () => {
  useBreadcrumbs([ "Regions" ]);

  const userAbilities = getAppState().userAbilities;

  const { data: regions = [], isValidating: loading, error } = useSWR("regions", () => {
    const dao = new RegionDao();
    return dao.getRegions();
  }, getSwrConfig());

  if (loading) {
    return <Loading/>;
  }
  if (userAbilities.canRead) {
    if (error) {
      return (
        <Flash
          type="error"
          header="Data Fetching Error"
          content="We are having an issue getting this data right now,
          try refreshing or please reach out to us if you continue to have issues"
        />
      );
    }
    return <RegionsListTable items={regions} loading={loading} />
  }
  return <NoAccessError/>
};

export default RegionsListContainer;
