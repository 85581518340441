import { SideNavigationProps } from "@amzn/awsui-components-react-v3";
import { SHOW_BETA_DASHBOARDS } from "../../../constants";

const dashboardItems = [
  {
    type: "link",
    text: "Build Manager",
    href: "/dashboards/launchmanager",
  },
  {
    type: "link",
    text: "Service Team",
    href: "/dashboards/serviceteam",
  },
  {
    type: "link",
    text: "Leadership",
    href: "/dashboards/leadership",
  },
]


if (SHOW_BETA_DASHBOARDS){
  dashboardItems.push(
    {
      type: "link",
      text: "Mailer Stats",
      href: "/dashboards/mailerstats",
    },
    {
      type: "link",
      text: "Readiness Scores",
      href: "/dashboards/regionreadinessscore",
    },
    {
      type: "link",
      text: "Automation Dashboard",
      href: "/dashboards/automationdashboard",
    },
    {
      type: "link",
      text: "Region Build Health",
      href: "/dashboards/regionbuildhealthdashboard",
    },
    {
      type: "link",
      text: "Dependencies and Successors",
      href: "/dashboards/dependenciesandsuccessors",
    },
    {
      type: "link",
      text: "Unblocked Items",
      href: "/dashboards/unblockeditemsdashboard",
    },
    {
      type: "link",
      text: "Build Manager [BETA]",
      href: "/dashboards/launchmangerbeta",
    },
    {
      type: "link",
      text: "Service Team [BETA]",
      href: "/dashboards/serviceteambeta",
    },
    {
      type: "link",
      text: "Leadership [BETA]",
      href: "/dashboards/leadershipbeta",
    },
    {
      type: "link",
      text: "Mailer Stats [BETA]",
      href: "/dashboards/mailerstatsbeta",
    },
  );
}
 
// The above code is just to keep these dashboards only in beta, for beta testing.
// It allows select users to beta test new quicksight items, without making them public
Object.freeze(dashboardItems)

export const dashboardSection: SideNavigationProps.Section = {
  type: "section",
  text: "Dashboards",
  items: dashboardItems,
} as SideNavigationProps.Section;

