import styled from "styled-components";
import { Tabs } from "@amzn/awsui-components-react";

const TabsNoPadding = styled(Tabs)`
  div.awsui-tabs-variant-container.awsui-util-container {
    div.awsui-tabs-content {
      padding: ${props =>
        props.activeTabId === "inprogress" ? "0 0" : "inherit"};
    }
  }
`;

export default TabsNoPadding;
export const FlushedContentTabs = styled(Tabs)`
  .awsui-tabs-variant-container {
    &.awsui-util-container {
      .awsui-tabs-content {
        padding: 0;
      }
    }
  }
`;
