import React from "react";
import { SideNavigation, SideNavigationProps } from "@amzn/awsui-components-react-v3";
import { useLocation } from "react-router-dom";
import { Optional } from "../../models/types/Optional";
import { sort } from "@amzn/api-parity-react-component/lib/utils/sortUtil";
import { SortByDirection } from "@amzn/api-parity-react-component/lib/models/SortBy";
import { paritySection } from "./sections/paritySection";
import { dashboardSection } from "./sections/dashboardSection";
import { documentSection } from "./sections/documentSection";
import { contactSection } from "./sections/contactSection";
import useOnFollow from "../../hooks/useOnFollow";
import { SHOW_BETA_DASHBOARDS } from "../../constants";

const header = {
  href: "/",
  text: "Recon",
};

export type NavigationDefinition = SideNavigationProps.Item;

export const routes: NavigationDefinition[] = [
  {
    type: "link",
    text: "Services",
    href: "/services",
  },
  {
    type: "link",
    text: "Regions",
    href: "/regions",
  },
  {
    type: "link",
    text: "Reports",
    href: "/reports",
  },
  {
    type: "link",
    text: "Contacts",
    href: "/contacts"
  },
];

if (SHOW_BETA_DASHBOARDS) {
  routes.push({
    type: "link",
    text: "Products [BETA]",
    href: "/alm/products"
  })
}

// Add parity
routes.push(paritySection);

routes.push(dashboardSection);



// Finally add doc and feedbacks
routes.push({
  type: "divider"
});

routes.push(documentSection);
routes.push(contactSection);


export interface IWeightedMatch<T> {
  item: T;
  score: number;
}

/**
 * If the supplied link matches the pathname, then return the weighted match
 * Score of the match is higher if the match is closer
 * otherwise return nothing
 * @param item
 * @param pathname
 */
export function getWeightedMatch(item: SideNavigationProps.Link, pathname: string): Optional<IWeightedMatch<SideNavigationProps.Link>> {
  let score = 0;
  if (pathname.startsWith(item.href)) {
    score = item.href.length / pathname.length;
  }

  if (score > 0) {
    return {
      item,
      score
    };
  }
}

export function getRoutesWeightedMatches( items: Readonly<NavigationDefinition[]>, pathname: string ): IWeightedMatch<SideNavigationProps.Link>[] {
  let matches: IWeightedMatch<SideNavigationProps.Link>[] = [];
  for (let route of items) {
    const group: SideNavigationProps.Section = route as SideNavigationProps.Section;
    if (group.items) {
      matches = matches.concat(getRoutesWeightedMatches(group.items, pathname));
    } else {
      const m = getWeightedMatch(route as SideNavigationProps.Link, pathname);
      if (m) {
        matches.push(m);
      }
    }
  }
  return matches;
}

export function getCurrentActiveHref( items: Readonly<NavigationDefinition[]>, pathname: string ): Optional<string> {
  const matches: IWeightedMatch<SideNavigationProps.Link>[] = getRoutesWeightedMatches(items, pathname);
  if (matches.length === 0) {
    return;
  }

  const sortedMatches = sort(matches, [
    {
      direction: SortByDirection.Descending,
      valueExtractFn: (m) => m.score
    }
  ]);

  const topMatch = sortedMatches[0];
  return topMatch.item.href;
}

const Sidebar = () => {
  const onFollow = useOnFollow();
  const location = useLocation();

  return (
    <SideNavigation
      onFollow={onFollow}
      header={header}
      items={routes}
      activeHref={getCurrentActiveHref(routes, location.pathname)}
    />
  );
};

export default Sidebar;
