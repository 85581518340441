// Constants to be shared between util and configuration
// also add type safe guard
export const filterPropertyKey = {
  serviceName: "serviceName",
  partition: "partition",
  nameLong: "nameLong",
  nameSortable: "nameSortable",
  gm: "gm",
  vp: "vp",
  primaryBuildPoc: "primaryBuildPoc",
  secondaryBuildPoc: "secondaryBuildPoc",
  rbtServiceManager: "rbtServiceManager",
  missingInfo: "missingInfo",
  l7s: "l7s",
  l8s: "l8s",
  l10s: "l10s",
  ripContacts: "ripContacts",
};
