import { ExportDataBook, ExportDataCell } from "@amzn/excelerator";
import { dataToDataBook, emptyExportValue } from "../ExportTableToCSVExcel/ExportTableToCSVExcel";
import {
  getEstimatedDate,
} from "../../utils/singleRegionUtils";

import { IServicePlan } from "../../models/types/IServicePlan";
import { toShortDate } from "../../utils/dateUtil";
import {
  getCategoryFromPlanForDisplay,
  getStatusFromServicePlan
} from "../../utils/planUtil";
import { getServiceDisplayName } from "../../utils/serviceMetadataUtil";

export const singleRegionCompletedHeaders: string[] = [
  "Service Long Name",
  "Category",
  "RIP Build Status",
  "Date Delivered",
];

export const generateSingleRegionCompletedWorkbook = (plans: IServicePlan[]): ExportDataBook => {
  let singleServiceCompletedExcelData: ExportDataCell[][] = [singleRegionCompletedHeaders]

  plans.forEach((plan: IServicePlan) => {
    singleServiceCompletedExcelData.push([
      getServiceDisplayName(plan),
      getCategoryFromPlanForDisplay(plan),
      getStatusFromServicePlan(plan),
      toShortDate(getEstimatedDate(plan), emptyExportValue),
    ]);
  })

  return dataToDataBook(singleServiceCompletedExcelData);
}
