import { IRmsComponentsForServiceDto } from "../daos/types/IRmsComponentsForServiceDto";
import {
  componentFeatureSortFunction,
  ComponentFeatureVO,
  componentIdExtractor,
  RECON_COMPONENT_SEPARATOR
} from "../models/vos/ComponentFeatureVO";
import { IComponentFeatureDto } from "../daos/types/IComponentFeatureDto";
import { mapBy } from "@amzn/api-parity-react-component/lib/utils/lookupUtil";
import { IHasId } from "@amzn/api-parity-react-component/lib/models/IHasId";
import { AwsServiceVO } from "@amzn/api-parity-react-component";
import { charCount } from "../utils/stringUtil";


// The below separator is between the service name and the component name
const RMS_COMPONENT_SEPARATOR = "/";

// A nested component has two separators which are either + or /
const NESTED_COMPONENT_SEPARATOR_COUNT = 2;

export const convertComponentServiceResponse = (rmsComponentsForServiceResponse: IRmsComponentsForServiceDto[]): ComponentFeatureVO[] => {
  return rmsComponentsForServiceResponse
    .map(rmsComponentsForServiceDto => convertComponentServiceDto(rmsComponentsForServiceDto))
    .sort(componentFeatureSortFunction);
}


export const convertComponentServiceDto = (rmsComponentsForServiceDto: IRmsComponentsForServiceDto): ComponentFeatureVO => {
  const id: string = rmsComponentsForServiceDto.key.name.replace(RMS_COMPONENT_SEPARATOR, RECON_COMPONENT_SEPARATOR)
  const serviceName = id.split(RECON_COMPONENT_SEPARATOR)[0]
  return new ComponentFeatureVO(id, rmsComponentsForServiceDto.longName, serviceName, id);
}


export const getSelectedComponentFeatures = (
  reconComponents: IComponentFeatureDto[],
  allComponents: ComponentFeatureVO[]
): ComponentFeatureVO[] => {
  const idToComponentFeature = mapBy(allComponents, componentIdExtractor)

  // @ts-ignore, reconComponentFeatures should be a subset and we double check by removing undefined values
  return reconComponents
    .map(componentFeature => idToComponentFeature.get(componentFeature.id))
    .filter(componentFeature => componentFeature !== undefined);
}


export const convertServicesToComponentFeatures = (
  services: AwsServiceVO[],
  serviceName: string
): ComponentFeatureVO[] => {
  return services.map(service => {
    const id = service.id.replace(RMS_COMPONENT_SEPARATOR,RECON_COMPONENT_SEPARATOR)

    return {
      id: `${serviceName}+${id}`,
      longName: service.name,
      serviceComponentName: id,
      serviceName,
    }
  })
}


export function filterNotOwnServiceComponents<T extends IHasId>(servicesWithComponents: T[], serviceName: string): T[] {
  return servicesWithComponents.filter((item => !item.id.startsWith(`${serviceName}${RECON_COMPONENT_SEPARATOR}`)));
}

export function filterNestedComponents<T extends IHasId>(servicesWithComponents: T[]): T[] {
  return servicesWithComponents.filter((item => {
    return charCount(item.id, RECON_COMPONENT_SEPARATOR) + charCount(item.id, RMS_COMPONENT_SEPARATOR) < NESTED_COMPONENT_SEPARATOR_COUNT;
  }));
}

export const getSelectedServiceFeatures = (
  reconComponents: IComponentFeatureDto[],
  allServices: AwsServiceVO[],
  serviceName: string
): AwsServiceVO[] => {
  let selectedServices = filterNotOwnServiceComponents(reconComponents, serviceName);
  const selectedServiceIds = new Set(selectedServices.map((service) => (service.id.replace(RECON_COMPONENT_SEPARATOR, RMS_COMPONENT_SEPARATOR))));
  return allServices.filter(service => selectedServiceIds.has(service.id))
};


export const addLongName = (reconComponents: IComponentFeatureDto[], allComponents: ComponentFeatureVO[]) => {
  const idToComponentFeature = mapBy(allComponents, componentIdExtractor);

  reconComponents.forEach((component) => {
    if (!component.longName) {
      component.longName = idToComponentFeature.get(component.id)?.longName;
    }
  });
}
