import useSWR from "swr";
import { getSwrConfig } from "../daos/swrUtil";
import { IContextualSwrResponse } from "../models/types/IContextualSwrResponse";

type Fetcher<T> = () => Promise<T>;

interface IJsonLoaderOption<DataT> {
  postProcess?: (input: any) => DataT;
}

export function useJsonLoader<T>(
  jsonPath: string | Fetcher<T>, 
  message: string, 
  option?: IJsonLoaderOption<T>): IContextualSwrResponse<T> {
  const swr = useSWR<T>(message, async () => {
    let remoteData;
    if (typeof(jsonPath) === "function") {
      remoteData = await jsonPath();
    } else {
      const response = await fetch(jsonPath);
      remoteData = await response.json();
    }

    if (option?.postProcess) {
      return option.postProcess(remoteData);
    } else {
      return remoteData as T;
    }
  }, getSwrConfig());

  return {
    context: message,
    response: swr,
  };
}
