import { expansionPlanById } from "@amzn/api-parity-react-component/lib/models/vos/AwsServiceExpansionPlan";
import { AwsServiceVO } from "@amzn/api-parity-react-component";
import { ServiceListVM } from "./ServicesListTable.types";

export function parseAwsServiceVOToServiceListVM(items: AwsServiceVO[]): ServiceListVM[] {
  return items.map((item) => {
    const service = new ServiceListVM();
    service.visibility = item.visibility ?? ""
    const expansionPlan = expansionPlanById.get(item.expansionPlan);
    if (expansionPlan) {
      service.category = expansionPlan.name;
    }
    if (item.nameVariations?.long) {
      service.nameLong = item.nameVariations.long;
    }
    if (item.nameVariations?.ripId) {
      service.nameRip = item.nameVariations.ripId;
    }
    if (item.nameVariations?.sortable) {
      service.nameSortable = item.nameVariations.sortable;
    }
    return service;
  })
}
