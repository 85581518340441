/*
The following is loosely based on how useCollection handles PropertyFiltering
 here: https://tiny.amazon.com/1d95hax17/linktoawsui-collection-hookspropertyFilter
*/

import { PropertyFilterProps, TableProps } from "@amzn/awsui-components-react-v3";
import { ReducedChangeVO } from "../../../../models/vos/ChangeVO";

const filterUsingOperator = (itemValue, tokenValue, operator): boolean => {
  switch (operator) {
    case "=":
      // eslint-disable-next-line eqeqeq
      return itemValue == tokenValue;
    case "!=":
      // eslint-disable-next-line eqeqeq
      return itemValue != tokenValue;
    case ":":
      return (itemValue + "").toLowerCase().indexOf((tokenValue + "").toLowerCase()) > -1;
    default:
      return false;
  }
}

// TODO This function needs to be updated to handle normal text correctly.
function filterByText(
  change: ReducedChangeVO,
  value: string,
  operator: PropertyFilterProps.ComparisonOperator,
  filteringPropertiesMap
) {
  const matches = Object.keys(filteringPropertiesMap).some(function (propertyKey) {
    const operators = filteringPropertiesMap[propertyKey].operators;
    return !!operators[operator] && filterUsingOperator(change[propertyKey], value, ":");
  });
  return operator === ":" ? matches : !matches;
}

const filterByToken = (change: ReducedChangeVO, token: PropertyFilterProps.Token, operation, filteringPropertiesMap): boolean => {
  const { propertyKey, operator, value: tokenValue } = token;
  if (propertyKey) {
    if (!(propertyKey in filteringPropertiesMap) ||
        !(operator in filteringPropertiesMap[propertyKey].operators)) {
      return false;
    }

    switch (propertyKey) {
      case "target":
        let ruleMatched = operator === "!=";
        for (const subChange of change.changes) {
          ruleMatched = operator === "="
            ? ruleMatched = ruleMatched || filterUsingOperator(subChange.target, tokenValue, operator)
            : ruleMatched = ruleMatched && filterUsingOperator(subChange.target, tokenValue, operator)
        }
        return ruleMatched;
      default:
        const itemValue = change[propertyKey];
        return filterUsingOperator(itemValue, tokenValue, operator);
    }
  }
  return filterByText(change, tokenValue, operator, filteringPropertiesMap);
}

/**
 * @description Filters ReducedChangeVOs based on the query from a <PropertyFilter/>
 * @param changes {ReducedChangeVO[]} - array of changes to filter
 * @param query {PropertyFilterProps.Query} - query to filter by
 * @param filteringProperties {PropertyFilterProps.FilteringProperty[]} - default properties
 * @returns {ReducedChangeVO[]} - filtered changes.
 */
export const filterChanges = (
  changes: ReducedChangeVO[],
  query: PropertyFilterProps.Query,
  filteringProperties: PropertyFilterProps.FilteringProperty[]
): ReducedChangeVO[] => {
  const operation = query.operation;
  const tokens = query.tokens;

  const filteringPropertiesMap = filteringProperties.reduce((previous, current) => {
    let operatorSet = {};
    let { key, operators, defaultOperator } = current;
    operatorSet[defaultOperator ? defaultOperator : "="] = true;
    if (operators) {
      operators.forEach((operator) => operatorSet[operator] = true);
    }
    previous[key] = {
      operators: operatorSet
    };
    return previous;
  }, {});

  return changes.filter((change) => {
    let result: boolean = operation === "and" ? true : !tokens.length
    for (const token of query.tokens) {
      result = operation === "and"
        ? result && filterByToken(change, token, operation, filteringPropertiesMap)
        : result || filterByToken(change, token, operation, filteringPropertiesMap);
    }
    return result;
  });
}

/**
 * @description Sorts changes using either the provided sorter or by using the default sort method and then flips based descending
 * @param changes {ReducedChangeVO} - changes to be sorted
 * @param sortingColumn {TableProps.SortingColumn<ReducedChangeVO>} - information about what is being sorted and how
 * @param descending {boolean} - determines if results should be sorted in ascending or descending order
 * @returns {ReducedChangeVO[]} - sorted changes
 */
export const sortChanges = (changes: ReducedChangeVO[], sortingColumn: TableProps.SortingColumn<ReducedChangeVO>, descending?: boolean): ReducedChangeVO[] => {
  const key = sortingColumn.sortingField as string;
  let sortedChanges = changes.sort(
    sortingColumn.sortingComparator // call provided comparator if it exists
      ? sortingColumn.sortingComparator
      : (change1, change2) => {
        if (change1[key] > change2[key])
          return 1;
        if (change1[key] < change2[key])
          return -1

        return 0;
      }
  );

  if (descending)
    sortedChanges = sortedChanges.reverse();

  return sortedChanges;
}

/**
 * @description takes an array of changes and generates an array of filtering options based on their keys
 * @param changes {ReducedChangeVO[]} - changes used to generate filtering options
 * @returns {PropertyFilterProps.FilteringOption[]} - array of filtering options generated from changes
 */
export const generateFilterOptions = (changes: ReducedChangeVO[]): PropertyFilterProps.FilteringOption[] => {
  const newFilteringOptions: PropertyFilterProps.FilteringOption[] = [];
  changes.forEach((change) => {
    Object.keys(change).forEach((key) => {
      // switch handles special fields like "changes" that need special processing.
      switch (key) {
        case "changes":
          change.changes.forEach((subChange) => {
            if (!newFilteringOptions.find((obj) => obj.propertyKey === "target" && obj.value === subChange.target)) {
              newFilteringOptions.push({
                propertyKey: "target",
                value: subChange.target
              });
            }
          });
          break;
        default: // default behavior is to just create filter option using each key.
          if (!newFilteringOptions.find((obj) => obj.propertyKey === key && obj.value === change[key])) {
            newFilteringOptions.push({
              propertyKey: key,
              value: change[key]
            });
          }
          break;
      }
    });
  });

  return newFilteringOptions;
}
