import { LoadStateEnum } from "../../../models/LoadStateEnum";
import { LOAD_SERVICE_LIST, LOAD_SERVICE_LIST_SUCCESS, ServiceActionTypes } from "../../actions/serviceAction";
import { produce } from "immer";
import { AwsServiceVO } from "@amzn/api-parity-react-component";

export interface IServiceState {
  loadState: LoadStateEnum;
  map: {
    [id: string]: AwsServiceVO
  };

  // TODO: tangentl We can index services in the store, or in selectors
  // categorizedServiceIds: Set<string>;
}

export const initialState: IServiceState = {
  loadState: LoadStateEnum.Never,
  map: {},
};

export function serviceReducer(
  state: IServiceState = initialState,
  action: ServiceActionTypes
): IServiceState {
  return produce(state, (nextState) => {
    switch (action.type) {
      case LOAD_SERVICE_LIST:
        nextState.loadState = LoadStateEnum.Loading;
        break;
      case LOAD_SERVICE_LIST_SUCCESS:
        action.payload.services.forEach((svc) => {
          nextState.map[svc.id] = svc;
        });
        nextState.loadState = LoadStateEnum.Loaded;
        break;
    }
  });

}
