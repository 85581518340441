import * as React from "react";
import { FunctionComponent } from "react";
import { ProductLinkProps } from "./ProductLink.types";
import { Link } from "react-router-dom";
import { getDataTestIdProp } from "../../../utils/testUtil";

export const ProductLinkTestIdSuffixes = {
  text: "-text",
}

/**
 * Provides link for specific product
 * @param props
 * @constructor
 */
export const ProductLink: FunctionComponent<ProductLinkProps> = (props) => {
  return (
    <Link
      to={{
        pathname: `/alm/products/${props.id}`,
        state: {
          product: props.product
        }
      }}
      {...getDataTestIdProp(props)}
    >
      <span
        {...getDataTestIdProp(props, ProductLinkTestIdSuffixes.text)}
      >
        {props.displayName}
      </span>
    </Link>
  );
};
