import axios, { AxiosInstance, AxiosRequestConfig, Method } from "axios";
import { getSig4Credentials, normalizeRequestBody, removeUnsafeHeaders, signRequest } from "./daoUtil";
import { normalizeRequestMethod } from "../utils/requestUtil";
import { getConfig } from "../utils/environmentUtil";
import { Endpoint } from "aws-sdk";
import urljoin from "url-join";

export interface IGraphQlQueryResult<T> {
  data: T;
}

export interface RipPaginatedResponse<T> {
  items: T[];
  totalCount?: number;
  nextOffset?: string;
}

export function getRipAxiosClient(): AxiosInstance {
  const instance = axios.create();
  instance.interceptors.request.use(ripRequestInterceptor);
  return instance;
}

export async function ripRequestInterceptor(request: AxiosRequestConfig): Promise<AxiosRequestConfig> {
  const method = normalizeRequestMethod(request.method);
  const endpoint: Endpoint = await getEndpoint(request.url ?? "", getConfig()?.ripApiUrl ?? "", method);
  request.url = endpoint.href;
  request.method = method;
  request.data = normalizeRequestBody(method, request.data);

  const credentials = await getSig4Credentials();
  const signedRequest = signRequest(request, credentials);
  const headers = signedRequest.headers;
  removeUnsafeHeaders(headers);

  Object.assign(request, { headers });
  return request;
}


export async function getEndpoint(urlOrPath: string, baseUrl: string, method: Method): Promise<Endpoint> {
  let endpoint = new Endpoint(urlOrPath);
  const baseUrlAbsent = (endpoint.host === "");
  let url = (baseUrlAbsent) ? urljoin(baseUrl, urlOrPath) : urlOrPath;

  return new Endpoint(url);
}

export async function queryRip<T, VarT extends object>(query: string, variables?: VarT, operationName = null): Promise<T> {
  const data = {
    operationName,
    query,
    variables: variables ?? {},
  };
  const url = getConfig()?.ripApiUrl as string;
  const client = getRipAxiosClient();

  const result = await client.post<IGraphQlQueryResult<T>>(url, data);
  // First data is from axios
  // Second data is from GraphQL standard return
  return result.data.data as T;
}
