import * as React from "react";
import { FunctionComponent, useCallback, useEffect, useState } from "react";
import { Grid, SpaceBetween } from "@amzn/awsui-components-react-v3";
import { Optional } from "@amzn/react-bot";
import { Displayable } from "@amzn/api-parity-react-component/lib/models/Displayable";
import { IFormFieldData } from "../../../common/FormTypes";
import { getDataTestIdProp } from "../../../../utils/testUtil";
import {
  IndividualFormFieldWithController
} from "../../../IndividualFormFieldWithController/IndivudalFormFieldWithController";
import { FormFieldTypes } from "../../../IndividualFormFieldWithController/IndividualFormFieldWithController.types";
import { ISelectableFormInput } from "../EditLaunchForm.types";
import { formFieldSpacing } from "../EditLaunchForm.style";
import { editLaunchFormFields } from "../EditLaunchFormFields";
import { IEditLaunchReasonInput } from "./EditLaunchReason";
import { selectableFormInputToStringValue } from "./EditLaunchReason.helpers";
import { LaunchState } from "../../../ProductLaunches/LaunchWizard/LaunchWizard.types";

export const EditLaunchPropertiesFormFieldsTestIdSuffixes = {
  reasonForChange: "-reasonForChange",
  reasonForChangeDescription: "-reasonForChangeDescription",
  grid: "-grid",
};

export const EditLaunchReasonFormFields: FunctionComponent<IEditLaunchReasonInput> = (props) => {
  const [reasonRequired, setReasonRequired] = useState(false);
  const [tierString, setTierString] = useState("");
  const [stateString, setStateString] = useState("");
  const [statusString, setStatusString] = useState("");

  const {
    control,
    errors,
    formState,
    getValues,
    trigger,
    fieldWatchers: { stateWatch, statusWatch, nameWatch, tierWatch, dateWatch }
  } = props;

  useEffect(() => {
    setTierString(selectableFormInputToStringValue(tierWatch))
  }, [tierWatch])

  useEffect(() => {
    setStateString(selectableFormInputToStringValue(stateWatch))
  }, [stateWatch])

  useEffect(() => {
    setStatusString(selectableFormInputToStringValue(statusWatch))
  }, [statusWatch])

  useEffect(() => {
    trigger()
  }, [trigger, reasonRequired, nameWatch, dateWatch, tierString, stateString, statusString])


  useEffect(() => {
    const currentFormValue = LaunchState[getValues()?.state?.value!];
    const previousFormValue = LaunchState[control.defaultValuesRef.current.state?.value!];

    const dirtyFields = formState.dirtyFields;
    setReasonRequired(
      !( // Check if state is not DRAFT or DRAFT => IN_PROGRESS
        previousFormValue === LaunchState.DRAFT
        && (
          currentFormValue === LaunchState.DRAFT
          || currentFormValue === LaunchState.IN_PROGRESS
        )
      )
      && (
        (!!dirtyFields.state
          && !( // Check that state is not IN_PROGRESS => SHIPPED
            previousFormValue === LaunchState.IN_PROGRESS
            && currentFormValue === LaunchState.SHIPPED
          )
        )
        || !!dirtyFields.date
        || !!dirtyFields.tier
        || !!dirtyFields.name
        || !!dirtyFields.status
      )
    );
  }, [control.defaultValuesRef, formState, getValues]);

  // instantiates field in form and hooks it up to parent react-hook-form via control + errors
  const getFormField = useCallback((formField: IFormFieldData, fieldType: FormFieldTypes, defaultValue: Optional<string> | ISelectableFormInput, info?: Displayable) => {
    return <IndividualFormFieldWithController
      control={control}
      errors={errors}
      formField={formField}
      defaultValue={defaultValue}
      popoverInfo={info}
      fieldType={fieldType}
      {...getDataTestIdProp(props, EditLaunchPropertiesFormFieldsTestIdSuffixes[formField.id])}
    />;
  }, [control, errors, props]);

  return (
    <>
      <SpaceBetween
        {...getDataTestIdProp(props)}
        direction="vertical"
        size={formFieldSpacing.section}>
        <SpaceBetween direction={"vertical"} size={formFieldSpacing.header}>
          <SpaceBetween direction={"vertical"} size={formFieldSpacing.vertical}>
            <Grid {...getDataTestIdProp(props, EditLaunchPropertiesFormFieldsTestIdSuffixes.grid)}
                  gridDefinition={[{ colspan: 6 }, { colspan: 6 }]}>
              {getFormField(reasonRequired ? editLaunchFormFields.reasonForChangeRequired : editLaunchFormFields.reasonForChange, FormFieldTypes.SELECT_ENTRY, control.getValues().reasonForChange)}
              {getFormField(reasonRequired ? editLaunchFormFields.reasonForChangeDescriptionRequired : editLaunchFormFields.reasonForChangeDescription, FormFieldTypes.TEXT_ENTRY, control.getValues().reasonForChangeDescription)}
            </Grid>
          </SpaceBetween>
        </SpaceBetween>

      </SpaceBetween>
    </>
  );
};
