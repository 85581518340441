import * as React from "react";
import { useCallback, useEffect, useMemo, useState } from "react";
import RegionsTable from "../../common/TableComponents/RegionsTable";
import {
  getColumnDefinitions,
  getContentSelectorOptions,
  getFilteringOptions,
  PAGE_SIZE_SELECTOR_OPTIONS,
  PAGINATION_OPTIONS,
  SORTABLE_COLUMNS
} from "./ServiceContactsTableOptions"
import { defaultTableConfig, } from "../../../utils/tableUtils";
import { ParityTableStyleWrapper } from "../../Parity/ParityTableStyleWrapper";
import {
  extractFilteringText,
  generateServiceContactsWorkbook,
  serviceContactCustomFilter
} from "./utils/serviceContactsTableUtil";
import { ServiceContactsVO } from "../../../models/vos/ServiceContactsVO";
import { ServiceContactsTableProps } from "./ServiceContactsTable.types";
import { BulkEditServiceContactWizard } from "../EnterServiceContact/BulkEditServiceContactWizard/BulkEditServiceContactWizard";
import { editableFields } from "../EnterServiceContact/BulkEditServiceContactWizard/BulkEditServiceContactWizardConstants";
import { IBulkEditServiceContactWizardTestIds } from "../EnterServiceContact/BulkEditServiceContactWizard/BulkEditServiceContactWizard.types";
import { DeleteServiceContactModal } from "../EnterServiceContact/DeleteServiceContactModal/DeleteServiceContactModal";
import { Optional } from "../../../models/types/Optional";
import {
  serviceContactsCSVExportFilename,
  serviceContactsCSVHeaders
} from "../ServiceContactsUtils/ServiceContactsCsvExport";
import { Table } from "@amzn/awsui-components-react";
import { EnterServiceContactsFormType } from "../EnterServiceContact/AddOrEditServiceContactModal/AddOrEditServiceContactModalConstants";
import {
  AddOrEditServiceContactModal
} from "../EnterServiceContact/AddOrEditServiceContactModal/AddOrEditServiceContactModal";
import { ButtonBar } from "../../common/ButtonBar/ButtonBar";
import TableContentSelector from "@amzn/awsui-components-react/polaris/table-content-selector/table-content-selector";
import TablePropertyFiltering
  from "@amzn/awsui-components-react/polaris/table-property-filtering/table-property-filtering";
import { Toggle } from "@amzn/awsui-components-react-v3";
import { NonCancelableCustomEvent } from "@amzn/awsui-components-react-v3/polaris/internal/events";
import { ToggleProps } from "@amzn/awsui-components-react-v3/polaris/toggle";
import styled from "styled-components";
import { ExportTableToCSVExcel } from "../../ExportTableToCSVExcel/ExportTableToCSVExcel";
import { CtiTicketButton } from "../../CtiTicketButton/CtiTicketButton";

const StyledToggle = styled(Toggle)`
  // Provide extra space after the toggle so it does not look too clustered from the rest of the buttons
  margin-right: 1rem;
`;


export const ServiceContactsTableTestId = {
  table: "ServiceContactsTable-table",
  addContact: "ServiceContactsTable-addContact",
  editSingleContact: "ServiceContactsTable-editSingleContact",
  rmsGraphInput: "ServiceContactsTable-rmsGraphInput",
  exportTable: "ServiceContactsTable-exportTable",
  newCtiTicket: "ServiceContactsNewCtiTicket-newCtiTicket"
}

export const BulkEditServiceContactWizardTestIds: IBulkEditServiceContactWizardTestIds = {
  buttonTestId: "ServiceContactsTable-bulkEditButton",
  wizardTestId: "ServiceContactsTable-bulkEditModal"
}

/**
 * This should return a service contacts record if only one record is selected in the table.
 * This is because selected determines both what contact we are modifying AND if the modal button is disabled or not.
 * @param selected
 */
export function getSingleContactSelected(selected: ServiceContactsVO[]): Optional<ServiceContactsVO> {
  return selected.length === 1 ? selected[0] : undefined;
}

const getTableConfig = (
  items: ServiceContactsVO[],
  loading: boolean,
  header: JSX.Element,
  columnDefinitions: Table.ColumnDefinition<ServiceContactsVO>[],
  filteringOptions: TablePropertyFiltering.Option[],
  tableSelection: unknown,
  contentSelectorOptions: TableContentSelector.Props,
  propertyFiltering: TablePropertyFiltering.Props<ServiceContactsVO>,
) => {
  const config = defaultTableConfig({
    features: [
      "TablePropertyFiltering",
      "TableSorting",
      "TablePreferences",
      "TableWrapLines",
      "TableSelection",
      "TablePagination",
      "TablePageSizeSelector",
      "TableContentSelector",
    ],
    variant: "default",
    columnDefinitions,
    header,
    items,
    loading,
    filteringOptions,
    tableSelection,
    paginationOptions: PAGINATION_OPTIONS,
    pageSizeSelectorOptions: PAGE_SIZE_SELECTOR_OPTIONS,
    sortableColumns: SORTABLE_COLUMNS,
    sortingColumn: "primaryBuildPoc",
    contentSelectorOptions,
  });
  for (const key in propertyFiltering) {
    config.TablePropertyFiltering[key] = propertyFiltering[key];
  }
  return config;
};

export const ServiceContactsTable: React.FunctionComponent<ServiceContactsTableProps> = (props) => {
  const {
    onDeleteSubmit,
    userAbilities,
    onBulkEditServiceContactSubmit,
    onAddOrEditServiceContactSubmit,
    onSuccessfulSubmitDisplaySuccess,
    onChangeRmsGraphToggle,
    rmsGraphToggle,
    items,
    loading,
    regionsByPartition,
  } = props;

  const [selected, setSelected] = useState<ServiceContactsVO[]>([]);
  const [columnDefinitions, setColumnDefinitions] = useState<Table.ColumnDefinition<ServiceContactsVO>[]>([]);
  const [header, setHeader] = useState<JSX.Element>(<React.Fragment />);
  const [filteringText, setFilteringText] = useState("");

  const [filteringOpts, setFilteringOpts] = useState<TablePropertyFiltering.Option[]>([]);

  // The following two states have to do with untyped parameters from RegionsTable
  // There will be follow up efforts on providing a simpler table implementation along with
  // better typing, until then, unknown type is use for the time being
  const [tableSelection, setTableSelection] = useState<unknown>();
  const [tableConfig, setTableConfig] = useState({});



  useEffect(() => {
    setColumnDefinitions(getColumnDefinitions(items, regionsByPartition));
    setFilteringOpts(getFilteringOptions(items, filteringText));
  }, [items, filteringText, regionsByPartition]);

  const onSubmitAndResetSelected = useCallback((successfulServices: ServiceContactsVO[], failedServices: ServiceContactsVO[], isAddEditOrDelete: EnterServiceContactsFormType): void => {
    const successfulServiceNames = successfulServices.map((successfulService) => successfulService.serviceName);
    const failedServiceNames = failedServices.map((failedService) => failedService.serviceName);
    onSuccessfulSubmitDisplaySuccess?.(successfulServiceNames, failedServiceNames, isAddEditOrDelete);
    setSelected([]);
  }, [onSuccessfulSubmitDisplaySuccess, setSelected]);

  const getExportDataBook = useCallback(() => {
    return generateServiceContactsWorkbook(items, serviceContactsCSVHeaders)
  }, [items]);

  const rmsGraphToggle_onChange = useCallback((evt: NonCancelableCustomEvent<ToggleProps.ChangeDetail>) => {
    onChangeRmsGraphToggle?.(evt.detail.checked);
  }, [onChangeRmsGraphToggle]);

  useMemo(() => {
    const isBulkEditing: boolean = selected.length > 1
    let actionButtons: JSX.Element = (
      <ButtonBar>
        <StyledToggle
          data-testid={ServiceContactsTableTestId.rmsGraphInput}
          checked={rmsGraphToggle}
          onChange={rmsGraphToggle_onChange}
        >
          Only in RMS Build Graph
        </StyledToggle>
        <AddOrEditServiceContactModal
          data-testid={ServiceContactsTableTestId.addContact}
          isAdding={true}
          userAbilities={userAbilities}
          onSuccessfulSubmit={onSubmitAndResetSelected}
          onAddOrEditServiceContactSubmit={onAddOrEditServiceContactSubmit}
        />
        {
          (!isBulkEditing) ?
            <AddOrEditServiceContactModal
              data-testid={ServiceContactsTableTestId.editSingleContact}
              selected={getSingleContactSelected(selected)}
              isAdding={false}
              userAbilities={userAbilities}
              onSuccessfulSubmit={onSubmitAndResetSelected}
              onAddOrEditServiceContactSubmit={onAddOrEditServiceContactSubmit}
            /> :
            <BulkEditServiceContactWizard
              editableFields={editableFields}
              testIds={BulkEditServiceContactWizardTestIds}
              selected={selected}
              userAbilities={userAbilities}
              onSuccessfulSubmit={onSubmitAndResetSelected}
              onBulkEditServiceContactSubmit={onBulkEditServiceContactSubmit}
            />
        }
        <DeleteServiceContactModal
          selected={getSingleContactSelected(selected)}
          userAbilities={userAbilities}
          onSuccessfulSubmit={onSubmitAndResetSelected}
          onDeleteSubmit={onDeleteSubmit}
        />
        <CtiTicketButton cti={getSingleContactSelected(selected)?.cti} data-testid={ServiceContactsTableTestId.newCtiTicket}/>
        <ExportTableToCSVExcel generateDataBook={getExportDataBook} fileNamePrefix={serviceContactsCSVExportFilename} data-testid={ServiceContactsTableTestId.exportTable}/>
      </ButtonBar>
    );
    setHeader(RegionsTable.getTableHeader({
      primary: "Service Contacts",
      secondary: `${items.length}`,
      actionButtons: actionButtons,
    }));
  }, [
    selected,
    rmsGraphToggle,
    rmsGraphToggle_onChange,
    userAbilities,
    onSubmitAndResetSelected,
    onAddOrEditServiceContactSubmit,
    onBulkEditServiceContactSubmit,
    onDeleteSubmit,
    getExportDataBook,
    items.length
  ]);

  useMemo(() => {
    const handleSelectionChange = ({ detail: { selectedItems } }) : void => {
      setSelected(selectedItems);
    };

    setTableSelection({
      selectionType: "multi",
      trackBy: "uid",
      selectedItems: selected,
      onSelectionChange: handleSelectionChange
    });
  }, [
    selected,
    setSelected,
  ]);

  const filter_onFilteringDelayedInput = useCallback((event: CustomEvent<TablePropertyFiltering.InputChangeDetail>) => {
    setFilteringText(extractFilteringText(event.detail.value));
  }, [setFilteringText]);

  const filter_onPropertyFilteringChange = useCallback(() => {
    setFilteringText("");
  }, [setFilteringText]);

  useMemo(() => {
    setTableConfig(
      getTableConfig(
        items,
        loading,
        header,
        columnDefinitions,
        filteringOpts,
        tableSelection,
        getContentSelectorOptions,
        {
          disableDefaultFiltering: true,
          filteringFunction: serviceContactCustomFilter,
          placeholder: "Filter service contacts by property or value",
          onFilteringDelayedInput: filter_onFilteringDelayedInput,
          onPropertyFilteringChange: filter_onPropertyFilteringChange,
        }
      ));
  }, [
    items, loading, header, columnDefinitions, filteringOpts, tableSelection,
    filter_onPropertyFilteringChange, filter_onFilteringDelayedInput,
  ]);

  return (
    <ParityTableStyleWrapper spaceOverhead={undefined}><RegionsTable testId={ServiceContactsTableTestId.table} {...tableConfig} /></ParityTableStyleWrapper>
  );
};
