import * as React from "react";
import styled from "styled-components";
import HomeContent from "../../components/HomeContent/HomeContent";
import useBreadcrumbs from "../../components/Breadcrumbs/useBreadcrumbs";
import { detect } from "detect-browser";
import { SafariAccess } from "../../components/HomeContent/SafariAccess";
import * as awsui from "@amzn/awsui-design-tokens/polaris";
import { getAppState } from "../../models/ApplicationState";
import { Optional } from "../../models/types/Optional";
import { LimitedContainer } from "../../components/seasonal/LimitedContainer";
import { applicableAliases, Nfl } from "../../components/seasonal/Nfl";
import { Snow } from "../../components/seasonal/Snow";

const StyledHeader = styled.div`
  margin-top: -30px;
  margin-left: -40px;
  margin-right: -40px;
  color: ${awsui.colorForegroundControlDefault};
  background-color: ${awsui.colorBackgroundHomeHeader};
  position: relative;
  min-height: 32rem;
`;

const StyledSafariAlert = styled.div`
  // Undo all the margins set by AppLayout to achieve full-bleed edge-to-edge effect
  margin: -2.5rem;
`;

const PaddedDiv = styled.div`
  padding: 6rem 0;
  position: absolute;
  z-index: 2;
  width: 100%;
  height: 100%;
`;

const WiderDiv = styled.div`
  padding: 1rem;
  -webkit-box-flex: 0;
  flex: 0 0 75%;
  max-width: 75%;
  position: relative;
  box-sizing: border-box;
  display: block;
`;

const PaddedSubtitle = styled.div`
  margin-top: 1rem;
`;

const NoPaddingP = styled.p`
  padding: 0;
  -webkit-box-flex: 0;
  flex: 0 0 75%;
  max-width: 75%;
  position: relative;
  box-sizing: border-box;
  display: block;
`;

export function isDesktopSafari(): Optional<boolean> {
  const browser = detect();
  if (browser?.name?.toLowerCase() !== "safari") {
    return false;
  }
  const os = browser?.os?.toLowerCase()
  return (os?.includes("mac os") || os?.includes("windows"));
}

export const getBlueprintTimestampComponentIfPresent = (): Optional<JSX.Element> => {
  return getAppState().blueprintHighWaterMarkUtc ?
    <>
      <br/>
      The last successful Blueprint data retrieval was
      on {getAppState().blueprintHighWaterMarkUtc?.toLocaleString()}, {Intl.DateTimeFormat().resolvedOptions().timeZone}.
    </>
    : undefined
}

const Home = () => {
  useBreadcrumbs(null); // null explicitly tells <Breadcrumbs> to not take any UI space at all

  return (
    <>
      {
        isDesktopSafari() && <StyledSafariAlert><SafariAccess/></StyledSafariAlert>
      }
      <StyledHeader className="awsui-grid awsui-util-p-s">
        {/*Browns vs. Ravens Nov 28, 2021*/}
        <LimitedContainer
          startOn={"2021-11-22"}
          endOn={"2021-11-30"}
          limitToAliases={applicableAliases}
          currentAlias={getAppState().userAlias}>
          <Nfl />
        </LimitedContainer>

        {/*Browns vs. Ravens Dec 12, 2021*/}
        <LimitedContainer
          startOn={"2021-12-08"}
          endOn={"2021-12-15"}
          limitToAliases={applicableAliases}
          currentAlias={getAppState().userAlias}>
          <Nfl />
        </LimitedContainer>

        <LimitedContainer
          startOn={"2021-12-01"}
          endOn={"2022-01-05"}
          currentAlias={getAppState().userAlias}>
          <Snow />
        </LimitedContainer>

        <PaddedDiv className="custom-home__header custom-awsui-util-pt-xxxl awsui-row">
          <div className="col-xxs-12">
            <div className="awsui-row">
              <WiderDiv className="offset-xxs-1 offset-l-2">
                <div className="awsui-text-large">
                  <div>
                    <strong>Recon</strong>
                  </div>
                  <PaddedSubtitle>Finding the best path forward</PaddedSubtitle>
                </div>
                <NoPaddingP>
                  Region Tracking is a set of program management tools dedicated
                  to helping Service Teams, Program Managers, and AWS Leadership
                  build more regions, faster, for maximum customer benefit.
                </NoPaddingP>
                {getBlueprintTimestampComponentIfPresent()}
              </WiderDiv>
            </div>
          </div>
        </PaddedDiv>
      </StyledHeader>
      <HomeContent/>
    </>
  );
}

export default Home;
