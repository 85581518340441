import { hydrateModel } from "../../utils/modelUtil";

export enum LoadingStatus {
  NotStarted = "NotStarted",
  Loading = "Loading",
  Success = "Success",
  Error = "Error"
}

export class DetailLoadingVO {
  public label: string = "";
  public status: LoadingStatus = LoadingStatus.NotStarted;
  public error?: string;

  constructor(source?: Partial<DetailLoadingVO>) {
    hydrateModel(source, this);
  }
}
