import {
  FeatureCollection,
  FeatureCollectionView,
  FeatureParityCollection,
  FeatureParityCollectionView,
  FeatureParityOverrideCollection,
} from "../models/collections";
import {
  FeatureParityOverrideVO,
  FeatureParityVO,
  FeatureVO,
  IFeatureParityOverrideTrail,
  IFeatureParityOverrideValue,
} from "../models/vos";

import { computeFeatureParityId } from "../utils/modelUtil";
import parseCsv from "csv-parse/lib/sync";
import { Optional } from "../models/types/Optional";
import { RegionVO } from "@amzn/api-parity-react-component/lib/models/vos/RegionVO";
import {
  ServiceBuildPlanExceptionEnum, ServiceBuildPlanStatusEnum,
  ServiceBuildPlanVO
} from "@amzn/api-parity-react-component/lib/models/vos/ServiceBuildPlanVO";
import { AwsServiceVO } from "@amzn/api-parity-react-component/lib/models/vos/AwsServiceVO";
import { ParseErrorReason } from "@amzn/api-parity-react-component/lib/models/ParseResult";
import { RegionCollection } from "@amzn/api-parity-react-component/lib/models/collections/RegionCollection";
import { RegionCollectionView } from "@amzn/api-parity-react-component/lib/models/collections/RegionCollectionView";
import { ParityStatusEnum } from "@amzn/api-parity-react-component/lib/models/ParityStatus";
import { computeServiceParityId } from "@amzn/api-parity-react-component/lib/utils/modelUtil";
import { featureParityOverrideStatusInDto } from "../daos/types/FeaturesDaoTypes";
import { computeUniqueFeatureId } from "../utils/featureUtil";

export enum FeatureParityOverrideParseErrorReason {
  UnknownFeature = "Unknown Feature",
  UnknownRegion = "Unknown Region",
  UnknownOverride = "Unknown Override",
  MissingDate = "Missing Date",
  MissingOnBehalf = "Missing OnBehalf",
}

export class FeatureParityOverrideParseError extends ParseErrorReason<string, FeatureParityOverrideParseErrorReason> {}

export class FeatureParityOverrideParseResult {
  parityOverrides: FeatureParityOverrideCollection = new FeatureParityOverrideCollection();
  errors: FeatureParityOverrideParseError[] = [];
}

/**
 * Lookups used by FeatureParityOverride Parser
 */
export interface IFeatureParityOverrideParserUseLookup {
  features: FeatureCollection | FeatureCollectionView;
  regions: RegionCollection | RegionCollectionView;
  parities: FeatureParityCollection | FeatureParityCollectionView;
}

export class FeatureParityOverrideParser {
  readonly result: FeatureParityOverrideParseResult = new FeatureParityOverrideParseResult();
  readonly lookup: IFeatureParityOverrideParserUseLookup;
  private readonly _validOverrideStatuses: Set<string>;

  constructor(lookup: IFeatureParityOverrideParserUseLookup) {
    this.lookup = lookup;
    this._validOverrideStatuses = new Set(Object.values(featureParityOverrideStatusInDto));
  }

  parse(overrideCsv: string): void {
    const records: string[][] = parseCsv(overrideCsv, {
      columns: false,
      skip_empty_lines: true,
      // skip the first line
      // Per https://csv.js.org/parse/options/from_line/#option-code-classlanguage-textfrom_linecode
      // from_line is a 1-base, thus the first line is 1
      from_line: 2,
      trim: true,
    }) as string[][];
    records.forEach((parts: string[], recordIndex: number) => this.parseLine(parts, recordIndex + 2));
  }

  parseLine(parts: string[], line: number = 1): void {
    const [serviceId, featureRipId, delimitedAirportCodes, override, reason, comment, ticket, date, onBehalf] = parts;

    if (!override) {
      return;
    }

    const airportCodes: string[] = FeatureParityOverrideParser.getAirportCodes(delimitedAirportCodes);
    const featureId = computeUniqueFeatureId(serviceId, featureRipId);
    const feature: Optional<FeatureVO> = this.lookup.features.getById(featureId);
    let hasError = false;

    if (!feature) {
      this.appendError(line,  featureId, FeatureParityOverrideParseErrorReason.UnknownFeature);
      hasError = true;
    }

    if (!date) {
      this.appendError(line, "", FeatureParityOverrideParseErrorReason.MissingDate);
      hasError = true;
    }

    if (!onBehalf) {
      this.appendError(line, "", FeatureParityOverrideParseErrorReason.MissingOnBehalf);
      hasError = true;
    }

    if (!this.isValidOverrideStatus(override)) {
      this.appendError(line, override, FeatureParityOverrideParseErrorReason.UnknownOverride);
      hasError = true;
    }

    if (!feature) {
      return;
    }

    for (const airportCode of airportCodes) {
      const region: Optional<RegionVO> = this.lookup.regions.getById(airportCode);

      if (!region) {
        this.appendError(line, airportCode, FeatureParityOverrideParseErrorReason.UnknownRegion);
        hasError = true;
        continue;
      }

      const overrideTrail: IFeatureParityOverrideTrail = {
        date: new Date(date),
        reason,
        comment,
        ticketUrl: ticket,
        overrideBy: onBehalf,
      };

      const overrideBuildPlan: Optional<ServiceBuildPlanVO> = this.deriveOverrideBuildPlan(override, feature, region);
      const originalParity: Optional<FeatureParityVO> = this.lookup.parities.byFeatureRegion(feature, region);
      const overrideStatus: ParityStatusEnum = this.deriveParityStatus(override, overrideBuildPlan, originalParity?.parity);
      const overrideValue: IFeatureParityOverrideValue = {
        parity: overrideStatus,
        overrideTrail,
        overrideBuildPlan,
      }

      this.result.parityOverrides.add(this.deriveOverrideParity(feature, region, overrideValue));
    }
  }

  static getAirportCodes(delimitedAirportCodes: string): string[] {
    return delimitedAirportCodes.split(",")
      .map((code) => code.trim().toUpperCase())
      .filter((code) => code.length !== 0);
  }

  deriveOverrideParity(feature: FeatureVO, region: RegionVO, overrideValue: IFeatureParityOverrideValue): FeatureParityOverrideVO {
    const originalParity: Optional<FeatureParityVO> = this.lookup.parities.byFeatureRegion(feature, region);
    if (originalParity) {
      return FeatureParityOverrideVO.fromFeatureParity(originalParity, overrideValue);
    }

    const id: string = computeFeatureParityId(feature, region);
    return new FeatureParityOverrideVO({
      id,
      feature: feature,
      region,
      parity: overrideValue.parity,
      overrideTrail: overrideValue.overrideTrail,
      overrideBuildPlan: overrideValue.overrideBuildPlan,
    })
  }

  deriveOverrideBuildPlan(status: string, feature: FeatureVO, region: RegionVO): Optional<ServiceBuildPlanVO> {
    if (status !== featureParityOverrideStatusInDto.notLaunching
      && status !== featureParityOverrideStatusInDto.ato) {
      return;
    }

    const buildPlanException: ServiceBuildPlanExceptionEnum = ( status === featureParityOverrideStatusInDto.notLaunching )
      ? ServiceBuildPlanExceptionEnum.NotLaunching
      : ServiceBuildPlanExceptionEnum.ATO;

    const service: AwsServiceVO = feature.service;
    const id: string = computeServiceParityId(service, region)

    return new ServiceBuildPlanVO({
      id,
      region,
      service,
      status: ServiceBuildPlanStatusEnum.IA,
      exception: buildPlanException,
    });
  }

  deriveParityStatus(status: string, buildPlan: Optional<ServiceBuildPlanVO>, originalParity?: ParityStatusEnum): ParityStatusEnum {
    // If there is a build exception, use original parity's availability status
    if (buildPlan?.exception) {
      return originalParity ?? ParityStatusEnum.NoData;
    }

    return (status === featureParityOverrideStatusInDto.available)
      ? ParityStatusEnum.Available
      : ParityStatusEnum.NoData;
  }

  isValidOverrideStatus(override?: string): boolean {
    if (!override) {
      return false;
    }

    return this._validOverrideStatuses.has(override);
  }

  appendError(line: number, relevantValue: string, reason: FeatureParityOverrideParseErrorReason): void {
    const err = new FeatureParityOverrideParseError({
      item: `Line ${line}: ${relevantValue}`,
      reason,
    });
    this.result.errors.push(err);
  }
}
