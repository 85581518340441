import { QaItemDefinition } from "./models/QaItemDefinition";
import { IQaRunItem } from "./models/QaRunItem";

export async function run(items: QaItemDefinition<any>[]): Promise<IQaRunItem[]> {
  const remoteOperationResultCache: Map<string, any> = new Map<string, any>();
  const runnerResult: IQaRunItem[] = [];
  for (const item of items) {
    let result: any;
    if (!remoteOperationResultCache.has(item.remoteOperation.id)) {
      result = await item.remoteOperation.operation();
      remoteOperationResultCache.set(item.remoteOperation.id, result);
    }
    result = remoteOperationResultCache.get(item.remoteOperation.id);
    const checkResult = item.checker(result);
    runnerResult.push({
      item,
      result: checkResult,
    });
  }

  return runnerResult;
}
