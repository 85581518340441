import { parseDate } from "./dateUtil";

const RMS_PROJECTED_DATE_FIELD_NAME = "rmsEarlyFinishDate";
const regionsToPullDates = new Set(["ALE", "APA", "BPM", "CGK", "CPT",
  "DXB", "HYD", "LTW", "MEL", "MXP", "TLV", "ZAZ", "ZRH"])

export function isRegionApplicable(regionName) {
  return regionsToPullDates.has(regionName);
}

export function isServicePlanApplicable(planName) {
  return planName === "Globally Expanding - Launch Blocking" || planName === "Globally Expanding - Mandatory";
}

// Extract rms projected date from payload returned from
// /api/GetServiceMetadataAndInstances/<rip>
export function getRmsProjectedFinishDateFromServiceRegionItem(row) {
  return parseRmsProjectedDate(row[RMS_PROJECTED_DATE_FIELD_NAME]);
}

// /api/GetServicePlanHistory/<rip>/<region>
export function getRmsProjectedFinishDateFromServiceInRegion(history) {
  return parseRmsProjectedDate(history[RMS_PROJECTED_DATE_FIELD_NAME]);
}

// /api/GetServicesPlansInRegion/<region>
export function getRmsProjectedFinishDateFromRegionDetail(row, regionName) {
  const dateStr = row.region?.[regionName.toLowerCase()]?.[RMS_PROJECTED_DATE_FIELD_NAME];
  return parseRmsProjectedDate(dateStr);
}

export function parseRmsProjectedDate(dateString) {
  if (typeof(dateString) !== "string") {
    return;
  }

  if (dateString.toUpperCase() === "COMPLETED") {
    return COMPLETED_DATE;
  }

  return parseDate(dateString);
}

export function isCompletedDate(dt) {
  return dt && dt.getTime() === COMPLETED_DATE.getTime();
}

export const COMPLETED_DATE = new Date(9999, 9, 9);
