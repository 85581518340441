import * as React from "react";
import { Flashbar, Link } from "@amzn/awsui-components-react-v3";

export const ApiParityBannerTestId = {
  slowBrowserWarning: "ApiParityBanner-slowBrowserWarning"
}

export const ApiParityBanner: React.FunctionComponent = () => {
  return (
    <Flashbar
      items={[
        {
          type: "warning",
          content: <React.Fragment>
            The dashboard captures API parity programmatically using CloudTrail data, so ensure your APIs are <Link
              href={"https://w.amazon.com/bin/view/Eagle/Onboarding/"}
              color="inverted"
              external={true}>onboarded to CloudTrail</Link>.
            For details about the dashboard and roadmap, visit the <Link
              href={"https://w.amazon.com/bin/view/AWSRegionBuildEngineering/Recon/FAQ/API_Parity/"}
              color="inverted"
              external={true}>API Parity FAQs</Link>. If you have a question or issue, please <Link
              href={"https://tiny.amazon.com/b3vis0az"}
              color="inverted"
              external={true}>submit a SIM</Link>.
          </React.Fragment>
        },
      ]} />
  )
}
