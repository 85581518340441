import { ExportDataBook, ExportDataCell } from "@amzn/excelerator";
import { IServicePlan } from "../../models/types/IServicePlan";
import { dataToDataBook } from "../ExportTableToCSVExcel/ExportTableToCSVExcel";
import { toShortDate } from "../../utils/dateUtil";
import { getLaunchDateFromServicePlan, getStatusFromServicePlan } from "../../utils/planUtil";

export const singleServiceCompletedHeaders = [
  "Region",
  "Region Launch Date",
  "RIP Build Status",
  "Date Delivered",
];

export const generateSingleServiceCompletedWorkbook = (services: IServicePlan[]): ExportDataBook => {
  let singleServiceCompletedExcelData: ExportDataCell[][] = [singleServiceCompletedHeaders]

  services.forEach((singleServicePlan: IServicePlan) => {
    singleServiceCompletedExcelData.push([
      singleServicePlan.region,
      getLaunchDateFromServicePlan(singleServicePlan),
      getStatusFromServicePlan(singleServicePlan),
      toShortDate(singleServicePlan.date),
    ]);
  })

  return dataToDataBook(singleServiceCompletedExcelData);
}
