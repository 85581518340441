import { QaChecker } from "../models/QaChecker";
import { IGetRegionsResponse } from "../../daos/types/IGetRegionsResponse";
import { getErrorResult, IQaItemResult, successResult } from "../models/QaItemResult";
import { successCallChecker } from "./checkerHelper";
import { GENERALLY_AVAILABLE } from "../../constants";

export const regionListHasItemChecker: QaChecker<IGetRegionsResponse> = (val) => {
  if (!val.success) {
    return getErrorResult("Error calling /api/regions");
  }

  return (val.result.length) ? successResult : getErrorResult("No regions returned");
}

export const regionListHasNonGADatesChecker: QaChecker<IGetRegionsResponse> = (val) => {
  if (!val.success) {
    return getErrorResult("Error calling /api/regions");
  }
  const hasNonGADates = val.result.some((r) => r.status !== GENERALLY_AVAILABLE && r.date);
  return (hasNonGADates) ? successResult : getErrorResult("No non-GA region with dates");
}

export const regionListOnlyHasGAChecker: QaChecker<IGetRegionsResponse> = (val) => {
  const checkFunction = (val: IGetRegionsResponse): IQaItemResult => {
    const hasNonGARegion = val.some((r) => r.status !== GENERALLY_AVAILABLE);
    return (hasNonGARegion) ? successResult : getErrorResult("There should not be any non-GA regions");
  }

  return successCallChecker<IGetRegionsResponse>(val, checkFunction, "Error calling /api/regions");
}
