import { QaChecker } from "../models/QaChecker";
import { IServiceMetaData } from "../../daos/types/IServiceMetaData";
import { successCallChecker } from "./checkerHelper";
import { getErrorResult, successResult } from "../models/QaItemResult";

export const serviceListHasItemChecker: QaChecker<IServiceMetaData[]> = (val) => {
  return successCallChecker(
    val,
    ( val: IServiceMetaData[] ) => {
      return (val.length) ? successResult : getErrorResult("No services are returned")
    },
    "Error calling GET /api/services"
    );
}
