import { getReconApiClient } from "./daoUtil";
import { FeatureInRegionCommentVO } from "../models/vos/FeatureInRegionCommentVO";

export class CommentsDao {

  async getFeatureInRegionCommentsByService(service: string): Promise<FeatureInRegionCommentVO[]> {
    const client = getReconApiClient();
    const response = await client.get(`/api/comments/featureinregionbyservice/${service}`);
    return response.data;
  }

  async postFeatureInRegionComment(featureInRegionComments: FeatureInRegionCommentVO[]): Promise<FeatureInRegionCommentVO[]> {
    const client = getReconApiClient();

    const response = await client.post(
      "/api/comments/add/FeatureInRegion",
      featureInRegionComments
    );

    return response.data;
  }

  async resolveFeatureInRegionComment(comment: FeatureInRegionCommentVO): Promise<FeatureInRegionCommentVO[]> {
    const client = getReconApiClient();

    const { service, feature, region, timestamp } = comment;

    const response = await client.post(
      "/api/comments/resolve/FeatureInRegion",
      {
        service,
        feature,
        region,
        timestamp,
      }
    );

    return response.data;
  }

}
