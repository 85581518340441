import * as React from "react";
import { FunctionComponent, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useLocation } from "react-router";
import { isEmpty, toNumber } from "lodash";
import styled from "styled-components";
import { useForm } from "react-hook-form";
import { Box, Button, Icon, Modal, SpaceBetween, Wizard } from "@amzn/awsui-components-react-v3";
import { IFormFieldData } from "../../common/FormTypes";
import { LaunchVO } from "../../../models/vos/LaunchVO";
import { launchVOToCreateDto } from "../../../parsers/launchParser";
import { Details } from "./stepComponents/step1";
import { RegionPicker } from "./stepComponents/step2";
import { Review } from "./stepComponents/step3";
import {
  ILaunchDetailFormValues,
  ISelectOptions,
  LaunchDisclosure,
  LaunchGoalType,
  LaunchIteration,
  LaunchScope,
  LaunchType,
  LaunchWizardProps
} from "./LaunchWizard.types";
import { createLaunchPropertiesFormFields } from "./LaunchWizardFormFields";
import { getDataTestIdProp } from "../../../utils/testUtil";
import { getAppState } from "../../../models/ApplicationState";

export const LaunchWizardTestIds = {
  launchWizard: "launchWizard",
  details: "detailsPage",
  regions: "regionPickerPage",
  review: "reviewPage",
  errorModal: "errorModal",
  warningIcon: "warningIcon",
}

export const WizardGrid = styled("div")`
  max-width: 1200px;
`;

const i18nStrings = {
  stepNumberLabel: stepNumber => `Step ${stepNumber}`,
  collapsedStepsLabel: (stepNumber, stepsCount) => `Step ${stepNumber} of ${stepsCount}`,
  cancelButton: "Save as draft",
  previousButton: "Previous",
  nextButton: "Next",
  submitButton: "Submit launch",
  optional: "optional"
};

export const formFieldModalErrorMessage = "Please fix the highlighted entries";
export const regionPickerModalErrorMessage = "Please select at least one region";

export const Page = {
  LaunchDetails: 0,
  RegionPicker: 1,
  ReviewPage: 2,
}

// https://polaris.a2z.com/foundation/visual-foundation/data-vis-colors/
export const RedText = styled("div")`
  color: #d63f38;
`;

export function WarningText(props) {
  return (
    <SpaceBetween direction="horizontal" size="xxs">
      <Icon {...getDataTestIdProp(props, LaunchWizardTestIds.warningIcon)} name="status-warning" variant="error"/>
      <RedText>{props.children}</RedText>
    </SpaceBetween>
  )
}

export const requiredFieldsForSubmission: IFormFieldData[] =
  [createLaunchPropertiesFormFields.launchName,
   createLaunchPropertiesFormFields.launchDescription,
   createLaunchPropertiesFormFields.launchType,
   createLaunchPropertiesFormFields.audience,
   createLaunchPropertiesFormFields.regulatoryBoolean,
   createLaunchPropertiesFormFields.pricingBoolean,
   createLaunchPropertiesFormFields.namingBoolean,
   createLaunchPropertiesFormFields.prfaqBoolean]

export const defaultValues: ILaunchDetailFormValues = {
  launchIteration: { label: LaunchIteration.NEW, value: "NEW" },
  launchType: { label: LaunchType.FEATURE, value: "FEATURE" },
  launchScope: { label: LaunchScope.GA, value: "GA" },
  disclosureLevel: { label: LaunchDisclosure.CONFIDENTIAL, value: "CONFIDENTIAL" },
  goalType: { label: LaunchGoalType.TEAM, value: "TEAM" },
  audience: { label: "External", value: "EXTERNAL" },
  confidential: { label: "Yes", value: "Yes" },
}

export const LaunchWizard: FunctionComponent<LaunchWizardProps> = (props) => {
  const [visibleErrorModal, setVisibleErrorModal] = React.useState(false);
  const [modalErrorText, setModalErrorText] = React.useState("");
  const [activeStepIndex, setActiveStepIndex] = useState(Page.LaunchDetails);
  const { control, errors, getValues, trigger, setError } =
    useForm({
      mode: "all",
      shouldUnregister: false,
      defaultValues: defaultValues
    })
  const { product, regions, onFormSubmit } = props
  const history = useHistory()
  const { search } = useLocation();
  const paramsPageValue = new URLSearchParams(search).get("page");

  useEffect(() => {
    control.setValue(createLaunchPropertiesFormFields.productManager.id, getAppState().userAlias)
  }, [])

  useEffect(() => {
    getValues(createLaunchPropertiesFormFields.launchName.id) ? setActiveStepIndex(toNumber(paramsPageValue)) : paramsPageValue && history.push(`?page=${Page.LaunchDetails}`)
  }, [paramsPageValue])


  function validateSubmission() {
    const undefinedValues = requiredFieldsForSubmission.filter((field) => !getValues(field.id))
    if (undefinedValues.length !== 0) {
      setActiveStepIndex(Page.LaunchDetails)
      undefinedValues.map((value) => setError(value.id, {
        type: "manual",
        message: "Required for submission",
      }))
      setModalErrorText(formFieldModalErrorMessage)
      setVisibleErrorModal(true)
      return false
    } else {
      return true
    }
  }

  function getSelectedRegions(): string[] {
    return Object.keys(getValues()).filter((entry) =>
      entry.startsWith("region:") && getValues(entry) === true).map
    ((selectedRegion) => selectedRegion.split(":")[2])
  }

  async function handleSubmission() {
    const launchVO: LaunchVO = new LaunchVO();

    launchVO.iteration = (getValues(createLaunchPropertiesFormFields.launchIteration.id) as ISelectOptions)?.value
    launchVO.type = (getValues(createLaunchPropertiesFormFields.launchType.id) as ISelectOptions)?.value
    launchVO.name = getValues(createLaunchPropertiesFormFields.launchName.id)
    launchVO.description = getValues(createLaunchPropertiesFormFields.launchDescription.id)
    launchVO.codename = getValues(createLaunchPropertiesFormFields.codeName.id)
    launchVO.ripId = getValues(createLaunchPropertiesFormFields.ripName.id)

    launchVO.date = getValues(createLaunchPropertiesFormFields.expectedLaunchDate.id)

    launchVO.scope = (getValues(createLaunchPropertiesFormFields.launchScope.id) as ISelectOptions)?.value
    launchVO.tier = (getValues(createLaunchPropertiesFormFields.tier.id) as ISelectOptions)?.value
    launchVO.disclosureLevel = (getValues(createLaunchPropertiesFormFields.disclosureLevel.id) as ISelectOptions)?.value
    launchVO.confidential = (getValues(createLaunchPropertiesFormFields.confidential.id) as ISelectOptions)?.value === "Yes"

    launchVO.goal = (getValues(createLaunchPropertiesFormFields.goalType.id) as ISelectOptions)?.value
    launchVO.goalLink = isEmpty(getValues(createLaunchPropertiesFormFields.goalLink.id)) ? undefined : getValues(createLaunchPropertiesFormFields.goalLink.id)
    launchVO.audience = (getValues(createLaunchPropertiesFormFields.audience.id) as ISelectOptions)?.value

    launchVO.contacts = {
      productManager: getValues(createLaunchPropertiesFormFields.productManager.id),
      marketingLead: getValues(createLaunchPropertiesFormFields.productMarketingManager.id),
      engineeringLead: getValues(createLaunchPropertiesFormFields.engineeringLead.id)
    }

    launchVO.questionnaire = {
      namingInvolved: (getValues(createLaunchPropertiesFormFields.namingBoolean.id) as ISelectOptions)?.value === "Yes",
      pricingChangeInvolved: (getValues(createLaunchPropertiesFormFields.pricingBoolean.id) as ISelectOptions)?.value === "Yes",
      regulated: (getValues(createLaunchPropertiesFormFields.regulatoryBoolean.id) as ISelectOptions)?.value === "Yes",
      prfaqRequired: (getValues(createLaunchPropertiesFormFields.prfaqBoolean.id) as ISelectOptions)?.value === "Yes",
    }

    launchVO.regions = getSelectedRegions();

    launchVO.state = "DRAFT"

    const launchDto = launchVOToCreateDto(launchVO)
    launchDto.readGroups = product.readGroups;
    launchDto.writeGroups = product.writeGroups;
    launchDto.parentProductId = product.id;

    await onFormSubmit(launchDto)
  }

  return (
    <SpaceBetween size="l" direction="vertical">
      <WizardGrid>
        <Wizard
          data-testid={LaunchWizardTestIds.launchWizard}
          i18nStrings={i18nStrings}
          onNavigate={async ({ detail }) => {
            if (activeStepIndex === Page.LaunchDetails && !await trigger()) {
              setModalErrorText(formFieldModalErrorMessage)
              setVisibleErrorModal(true)
            } else if (activeStepIndex === Page.RegionPicker && detail.requestedStepIndex > activeStepIndex && getSelectedRegions().length === 0) {
              setModalErrorText(regionPickerModalErrorMessage)
              setVisibleErrorModal(true)
            } else {
              history.push(`?page=${detail.requestedStepIndex}`)
              setActiveStepIndex(detail.requestedStepIndex)
            }
          }
          }
          activeStepIndex={activeStepIndex}
          isLoadingNextStep={props.isLoading}
          onCancel={async () => await trigger() && handleSubmission()} // Acting as "Save as draft"
          onSubmit={() => validateSubmission() && handleSubmission()}
          steps={[
            {
              title: "Specify launch details",
              content:
                <Details
                  serviceName={product.name}
                  services={props.services}
                  blockedDates={props.blockedDates}
                  control={control}
                  errors={errors}
                  data-testid={LaunchWizardTestIds.details}
                />
            },
            {
              title: "Select regions",
              content:
                <RegionPicker
                  control={control}
                  regions={regions}
                  data-testid={LaunchWizardTestIds.regions}
                />
            },
            {
              title: "Review progress and submit",
              content:
                <Review
                  serviceName={product.name}
                  control={control}
                  regions={regions}
                  setPage={setActiveStepIndex}
                  data-testid={LaunchWizardTestIds.review}/>
            },
          ]}
        />
      </WizardGrid>
      <Modal
        data-testid={LaunchWizardTestIds.errorModal}
        onDismiss={() => setVisibleErrorModal(false)}
        visible={visibleErrorModal}
        closeAriaLabel="Close modal"
        size="medium"
        footer={
          <Box float="right">
            <SpaceBetween direction="horizontal" size="xs">
              <Button
                variant="primary"
                onClick={() => setVisibleErrorModal(false)}
              >
                Ok
              </Button>
            </SpaceBetween>
          </Box>
        }
        header="Invalid entry"
      >
        {modalErrorText}
      </Modal>
    </SpaceBetween>
  );
}
