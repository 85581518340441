import { getALMApiClient, PaginationResponse } from "./daoUtil";
import { IProductDto } from "./types/IProductDto";
import { decodeAllStringFields } from "../parsers/parseDecodeHelpers";
import { AxiosResponse } from "axios";

export class ProductDao {
  async getProducts(maxResults?: number, nextToken?: string): Promise<PaginationResponse<IProductDto>> {
    const client = getALMApiClient();
    const urlParams = new URLSearchParams();

    maxResults && urlParams.append("MaxResults", encodeURIComponent(maxResults));
    nextToken && urlParams.append("NextToken", nextToken);

    const res = await client.get<IProductDto[]>(`/api/products?${urlParams.toString()}`);
    return decodeAllStringFields(res.data);
  }

  async getProduct(productId: string): Promise<IProductDto> {
    const client = getALMApiClient();
    const res = await client.get<IProductDto>(`/api/products/${productId}`);
    return decodeAllStringFields(res.data);
  }
}

