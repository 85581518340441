import * as React from "react";
import RegionsTable from "../common/TableComponents/RegionsTable";
import { SingleRegionLink } from "../common/Links";
import { defaultTableConfig, sortDeliveryDate, sortStatus } from "../../utils/tableUtils";
import { toShortDate } from "../../utils/dateUtil";
import { getNonEmptyProperties } from "../../utils/collectionUtil";
import { ExportFormat } from "@amzn/excelerator";
import { generateRegionsListWorkbook } from "./RegionsListTableCsvExport";
import { ExportTableToCSVExcel } from "../ExportTableToCSVExcel/ExportTableToCSVExcel";
import { useCallback } from "react";


export const actionMenu = [
  { id: ExportFormat.Csv, text: "Export to CSV" },
  { id: ExportFormat.Xlsx, text: "Export to Excel" },
]

export const RegionsListTableTestId = {
  table: "RegionsListTable-table",
  exportTable: "RegionsListTable-exportTable",
}

const COLUMN_DEFINITIONS = [
  {
    id: "nameSortable",
    header: "Airport Code",
    cell: ({ nameSortable, region }) => (
      <SingleRegionLink regionName={nameSortable ?? region} />
    ),
  },
  {
    id: "nameLong",
    header: "External Name",
    cell: ({ nameLong }) => nameLong,
  },
  {
    id: "regionName",
    header: "Region Name",
    cell: ({ description }) => description,
  },
  {
    id: "partition",
    header: "Partition",
    cell: ({ partition }) => partition,
  },
  {
    id: "status",
    header: "RIP Build Status",
    cell: ({ status }) => status,
  },
  {
    id: "date",
    header: "Region Launch Date",
    cell: ({ date }) => toShortDate(date),
  }
];

const SORTABLE_COLUMNS = [
  { id: "nameSortable", field: "nameSortable" },
  { id: "nameLong", field: "nameLong" },
  { id: "regionName", field: "description" },
  { id: "partition", field: "partition" },
  { id: "status", field: "status", comparator: sortStatus },
  { id: "date", field: "date", comparator: sortDeliveryDate }
];

const getFilteringOptions = items => [
  {
    groupValuesLabel: "Airport Codes",
    propertyKey: "nameSortable",
    propertyLabel: "Airport Code",
    values: getNonEmptyProperties(items, (item) => item.nameSortable),
  },
  {
    groupValuesLabel: "External Names",
    propertyKey: "nameLong",
    propertyLabel: "External Name",
    values: getNonEmptyProperties(items, (item) => item.nameLong),
  },
  {
    groupValuesLabel: "Region Names",
    propertyKey: "description",
    propertyLabel: "Region Name",
    values: getNonEmptyProperties(items, (item) => item.description),
  },
  {
    groupValuesLabel: "Partitions",
    propertyKey: "partition",
    propertyLabel: "Partition",
    values: getNonEmptyProperties(items, (item) => item.partition),
  },
  {
    groupValuesLabel: "Statuses",
    propertyKey: "status",
    propertyLabel: "Status",
    values: getNonEmptyProperties(items, (item) => item.status),
  },
  {
    groupValuesLabel: "Region Launch Dates",
    propertyKey: "date",
    propertyLabel: "Region Launch Date",
    values: getNonEmptyProperties(items, (item) => item.date),
  }
];

const TABLE_CONTENT_SELECTOR_OPTIONS = {
  title: "Select visible columns",
  options: [
    {
      label: "Columns",
      options: [
        { id: "nameSortable", label: "Airport Code", visible: true },
        { id: "nameLong", label: "External Name", visible: true },
        { id: "regionName", label: "Region Name", visible: true },
        { id: "partition", label: "Partition", visible: true },
        { id: "status", label: "Status", visible: true },
        { id: "date", label: "Region Launch Date", visible: true },
      ]
    }
  ]
};

const PAGINATION_OPTIONS = {
  pageSize: 50
};

const getTableConfig = (items, loading, header, filteringOptions) => {
  return defaultTableConfig({
    features: [
      "TablePropertyFiltering",
      "TablePagination",
      "TableSorting",
      "TablePreferences",
      "TablePageSizeSelector",
      "TableWrapLines",
      "TableContentSelector",
    ],
    variant: "default",
    columnDefinitions: COLUMN_DEFINITIONS,
    header,
    items,
    loading,
    filteringOptions,
    sortableColumns: SORTABLE_COLUMNS,
    sortingColumn: "status",
    sortingDescending: true,
    tableSelection: {},
    contentSelectorOptions: TABLE_CONTENT_SELECTOR_OPTIONS,
    paginationOptions: PAGINATION_OPTIONS,
  });
};



const RegionsListTable = ({ items = [], loading = true }) => {

  const getExportDataBook = useCallback(() => {
    return generateRegionsListWorkbook(items)
  }, [items]);

  const header = RegionsTable.getTableHeader({
    primary: "Regions",
    secondary: `${items.length}`,
    actionButtons: <ExportTableToCSVExcel
      generateDataBook={getExportDataBook}
      fileNamePrefix={"RegionsList"}
      // When this file is converted to tsx then we should add a dom test that this component exists
      data-testid={RegionsListTableTestId.exportTable}
    />
  });

  const filteringOpts = getFilteringOptions(items);
  const tableConfig = getTableConfig(items, loading, header, filteringOpts);
  return (
    <React.Fragment>
      <RegionsTable testId={RegionsListTableTestId.table} {...tableConfig} />
    </React.Fragment>
  )
};

export default RegionsListTable;
