import * as React from "react";
import { displayOr } from "../../../utils/displayUtil";
import { UserLink } from "@amzn/api-parity-react-component/lib/components/user/UserLink";
import { FeatureTreeItem } from "../../../models/FeatureTreeItem";
import { RegionVO } from "@amzn/api-parity-react-component/lib/models/vos/RegionVO";
import { FeatureParityCollectionView, ServiceInRegionRollupStatusCollectionView } from "../../../models/collections";
import { Icon, Link } from "@amzn/awsui-components-react-v3";
import { AwsServiceVO } from "@amzn/api-parity-react-component/lib/models/vos/AwsServiceVO";
import { FeatureParityVO, FeatureVO, ServiceInRegionRollupStatusVO } from "../../../models/vos";
import { Optional } from "../../../models/types/Optional";
import { ParityStatusEnum } from "@amzn/api-parity-react-component/lib/models/ParityStatus";
import {
  atoParityFeatureParityDisplay,
  IFeatureParityDisplay,
  inParityFeatureParityDisplay,
  inProgressFeatureParityDisplay,
  notAvailableParityFeatureParityDisplay,
  notLaunchingParityFeatureParityDisplay,
  plannedWithNoDateParityFeatureParityDisplay
} from "../../../models/featureParityDisplay";
import {
  isAto,
  isInParity,
  isInProgressWithDate,
  isNotLaunching
} from "@amzn/api-parity-react-component/lib/utils/serviceBuildPlanUtil";
import { getParityShortDate } from "../../../utils/dateUtil";
import { ServiceBuildPlanVO } from "@amzn/api-parity-react-component";
import { getFeatureTreeItemService } from "../../../utils/featureTreeItemUtil";
import {
  ItemName,
  ParityDateCompact,
  RipId
} from "./FeatureParityOverview/FeatureParityOverviewGrid/FeatureParityOverviewGrid.style";
import { FeatureInRegionStatusEnum } from "../../../models/FeatureInRegionStatus";
import { getFeatureDetailUrl, getFeatureStatusUrl, getServiceDetailUrl } from "../../../utils/unifiedUxUtil";

export const inParity = <Icon variant="success" name="status-positive" />
export const inProgress = <Icon variant="link" name="status-in-progress" />
export const notInParity = <Icon variant="error" name="status-negative" />

export const emptyParityDisplay: IFeatureParityDisplay = {
  plain: "-",
  visual:<span>-</span>
};

export function getUserDisplay(userAlias?: string): string | JSX.Element {
  return displayOr(userAlias, () => <UserLink userId={userAlias as string} withAvatar={true}>{userAlias}</UserLink>);
}

export function getParityDisplay(
  item: FeatureTreeItem,
  region: RegionVO,
  featureParities: FeatureParityCollectionView,
  featureParityRollupByService: ServiceInRegionRollupStatusCollectionView,
): IFeatureParityDisplay {
  const service: Optional<AwsServiceVO> = getFeatureTreeItemService(item);
  if (service) {
    return getServiceParityDisplay(service, region, featureParityRollupByService);
  }


  return getFeatureParityDisplay(item.entity as FeatureVO, region, featureParities);
}

export function getServiceParityDisplay(
  service: AwsServiceVO,
  region: RegionVO,
  featureParityRollupByService: ServiceInRegionRollupStatusCollectionView,
): IFeatureParityDisplay {
  const rollup: ServiceInRegionRollupStatusVO = featureParityRollupByService.byServiceRegion(service, region) as ServiceInRegionRollupStatusVO;

  if (rollup.exception) {
    return getExceptionDisplay(rollup.exception);
  }

  if (rollup.statistics.rawTotal > 0) {
    if (rollup.statistics.ga === 0) {
      return notAvailableParityFeatureParityDisplay;
    } else if (rollup.statistics.ga === rollup.statistics.expectedTotal) {
      return inParityFeatureParityDisplay;
    }

    return inProgressFeatureParityDisplay;

  } else {
    return (rollup.status === ParityStatusEnum.Available) ?
      inParityFeatureParityDisplay : notAvailableParityFeatureParityDisplay;
  }
}

export function getFeatureParityDisplay(
  feature: FeatureVO,
  region: RegionVO,
  featureParities: FeatureParityCollectionView,
): IFeatureParityDisplay {
  const parity: Optional<FeatureParityVO> = featureParities.byFeatureRegion(feature, region);
  if (!parity) {
    return notAvailableParityFeatureParityDisplay;
  }
  if (parity.statusInferredFrom instanceof ServiceBuildPlanVO) {
    const exception = parity.statusInferredFrom;
    if (isInParity(exception)) {
      return inParityFeatureParityDisplay;
    } else {
      return getExceptionDisplay(exception, true);
    }
  }

  if (parity.parity === ParityStatusEnum.Available) {
    return inParityFeatureParityDisplay;
  }

  if (parity.status === FeatureInRegionStatusEnum.Planned) {
    if (parity.plannedDate) {
      return getDateVisual(parity.plannedDate);
    }
    return plannedWithNoDateParityFeatureParityDisplay;
  }

  return notAvailableParityFeatureParityDisplay;
}

export function getExceptionDisplay(serviceParity: ServiceBuildPlanVO, forFeature: boolean = false): IFeatureParityDisplay {
  if (isAto(serviceParity) && !forFeature) {
    return atoParityFeatureParityDisplay;
  }

  if (isNotLaunching(serviceParity) && !isAto(serviceParity)) {
    return notLaunchingParityFeatureParityDisplay;
  }

  if (isInProgressWithDate(serviceParity) && serviceParity.date && !forFeature) {
    return (forFeature) ? emptyParityDisplay : getDateVisual(serviceParity.date as Date);
  }

  return emptyParityDisplay;
}

export function getDateVisual(date: Date): IFeatureParityDisplay {
  const plain: string = getParityShortDate(date);
  const delinquent: boolean = date.getTime() < Date.now();
  return {
    plain,
    visual: <ParityDateCompact delinquent={delinquent}>{plain}</ParityDateCompact>
  }
}

export function getFeatureLink(item: FeatureTreeItem): JSX.Element {
  const feature: FeatureVO = item.entity as FeatureVO;
  const url = getFeatureStatusUrl(feature.service.id, feature.ripId);
  return (
    <Link href={url} external>
      <ItemName hasChildren={!!item.children?.length}>{item.name}</ItemName>
      <RipId> ({feature.ripId})</RipId>
    </Link>
  );
}

export function getServiceLink(item: FeatureTreeItem): JSX.Element {
  const service = getFeatureTreeItemService(item) as AwsServiceVO;
  const url = getServiceDetailUrl(service.id);
  return (
    <Link href={url} external>
      <ItemName hasChildren={!!item.children?.length}>{item.name}</ItemName>
      <RipId> ({service.id})</RipId>
    </Link>
  );
}

export function getItemLink(item: FeatureTreeItem): JSX.Element {
  return getFeatureTreeItemService(item) ? getServiceLink(item) : getFeatureLink(item);
}
