import { getSwrConfig } from "../../daos/swrUtil";
import { stringify } from "query-string"

const QS_URI_PATTERN = "^/embed/[0-9a-f]{32}/dashboards/[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$";
const QS_URI_REGEX = new RegExp(QS_URI_PATTERN); // compiled for efficiency over time

export const QuickSightDashboardErrors = {
  // TODO: tangentl, there are multiple reasons error could happen when trying to open a new tab such as
  // 1. DAO call fails (rare, but likely)
  // 2. Browser blocks the new tab from opening (probability is unknown, each browser setting along with blockers can vary)
  // 3. Highly unlikely, data validation ran into issue
  // We can delineate different errors when some of the rare errors happen more often.
  generic: "Sorry, an error has occurred when trying to open the dashboard in new tab.",
  openTabBlocked: "Sorry, browser has blocked the new dashboard from opening in the new tab, please try to disable popup blocker."
}
/*
 * AppSec requirement: validate on context switching and general best practice: don't trust what
 * quicksight sends back to us!  Make sure we got a well-formed URL to a QuickSight dashboard
 * and that it sends us to a host/DNS that we expect.
 */
export const validUrlRedirect = (url: URL): boolean => {
  // Extract the hostname from data.hostname ... yea.  Hostname might have port at the end :)
  const hostname = url.hostname.split(":")[0]; // if no port, split(':') returns data.hostname
  if (!hostname.endsWith("quicksight.aws.amazon.com")) {
    return false;
  }
  if (!QS_URI_REGEX.test(url.pathname)) {
    return false;
  }
  return url.protocol === "https:";

};



/*
 * Append QuickSight Parameters to the URL string passed in.
 * See https://docs.aws.amazon.com/quicksight/latest/user/parameters-in-a-url.html
 * for background and what's happening here.  This function allows callers to
 * add params to a signed QuickSight redirect URL, such as the region name
 * or service name when drawing a dashboard that requires arguments.
 * These are not query args!  They appear *after* query args in the HASH portion
 * of the URL.  For info on the hash part of a URL, check out
 * https://javascript.info/url
 */
export const addParamsToUrlHash = (base: URL | string, params): string => {
  const baseUrl: URL = (typeof (base) === "string") ? new URL(base) : base;

  if (!params || Object.keys(params).length === 0) {
    return baseUrl.href; // don't add "?" or any params
  }

  baseUrl.hash = stringify(params);
  return baseUrl.href;
};


/*
 * Component to display an <IFRAME> with a URL pointing to QuickSight.
 *
 * This allows Recon to display charts and graphs from "Belter", the separate
 * project that analyzes region data and displays cool visualizations.
 *
 * Arguments:
 *     dashboardId:    Symbolic name of the chart or graph to display.  These are
 *                     defined in BackendLambda to hide Belter internal IDs from
 *                     us and our users.
 *     width, height:  Positive integer dimensions of the screen element to display.
 *                     Embedded QuickSight dashboards don't know how big they are,
 *                     so we can't determine this programmatically.  Set this based
 *                     on viewing the dashboard in QuickSight first.
 *
 * For security, DO NOT display any particular error messages returned from the
 * backend.  If there is a problem, this just uses DEBUG level console statements
 * so some troubleshooting can happen from the browser.
 *
 *
 * Retrieving a signed URL:
 * ------------------------
 * This component needs to call the QuickSightDao to get a signed URL to put in the
 * iframe when the component is first drawn (like a componentDidMount() action).
 * Everytime the Dao is called, it's a round-trip to QuickSight with some latency,
 * so this should be minimized.  Unlike other types of DAOs (like Dynamo), the call
 * can be repeated to refresh with newer data.  For QuickSightDao, that isn't the case.
 * Every call to QuickSight yields a new URL that can/should be used only once to draw
 * the iframe.  So, useSWR() needs to be restricted so it doesn't try to refresh the
 * URL after a set expiration/timeout.  As long as the iframe is visible, don't refresh
 * the URL.  When the component unmounts and remounts in the future, it is MANDATORY
 * to get a new signed URL: the past URL cached in useSWR() is invalid.
 * So, we need useSWR() to use a key in the 1st argument, so it doesn't call QuickSight
 * if there is an innocuous call to QuickSightDashboard() that doesn't unmount the iframe.
 */

export function getQuickSightSwrConfig() {
  const mySwrConfig = getSwrConfig();
  mySwrConfig.refreshInterval = 0;        // disable polling: once we get a URL, we're done
  mySwrConfig.dedupingInterval = 0;       // every mount means get a URL, even if only 1s ago
  mySwrConfig.revalidateOnMount = true;   // being explicit: MUST call DAO when remounting
  mySwrConfig.revalidateOnFocus = false;  // losing and gaining focus doesn't mean get latest data
  return mySwrConfig;
}
