export interface IServiceCategoryOption {
  id: string;
  text: string;
  label: string;
}

export const SERVICE_CATEGORY_NONE: Readonly<IServiceCategoryOption> = Object.freeze({
  "text": "None",
  "label": "None (Removes Category)",
  "id": "4"
});

export const serviceCategories: ReadonlyArray<IServiceCategoryOption> = [
  {
    "text": "Globally Expanding - Launch Blocking",
    "label": "Globally Expanding - Launch Blocking",
    "id": "1"
  },
  {
    "text": "Globally Expanding - Mandatory",
    "label": "Globally Expanding - Mandatory",
    "id": "2"
  },
  {
    "text": "Non-Globally Expanding",
    "label": "Non-Globally Expanding",
    "id": "3"
  },
  SERVICE_CATEGORY_NONE
];
