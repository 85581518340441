import { QaItemDefinition } from "../models/QaItemDefinition";
import * as read from "./abilityItems/abilityRead";
// import * as write from "./abilityItems/abilityWrite"; TODO ralberth
import * as readDate from "./abilityItems/abilityReadDate";
import * as noAbility from "./abilityItems/noAbility";

export const readAllQaItems: QaItemDefinition<any>[] = [
  read.listRegionHasItems,
  readDate.listRegionHasNoGaRegionAndDates,
  read.listServiceHasItems,
  noAbility.cannotUpdateServicePlan,
  noAbility.cannotUpdateBusinessCaseOverride,

  // write.updateEstimatedDate, TODO ralberth
  noAbility.cannotRemoveEstimatedDate,
];
