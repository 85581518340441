import { QaItemDefinition } from "../../models/QaItemDefinition";
import { IServiceMetaData } from "../../../daos/types/IServiceMetaData";
import { getServiceListOperation } from "../../remoteOperations/serviceOperation";
import { serviceListHasItemChecker } from "../../checkers/serviceListChecker";
import { IGetRegionsResponse } from "../../../daos/types/IGetRegionsResponse";
import { getRegionListOperation } from "../../remoteOperations/getRegionListOperation";
import { regionListHasItemChecker } from "../../checkers/regionListChecker";
import { IGetSingleServicePlanResponseLegacy } from "../../../daos/types/IGetSingleServicePlanResponse";
import {
  getServiceInRegionHistoryOperation,
  getServicePlanListOperation
} from "../../remoteOperations/servicePlanOperation";
import {
  servicePlanListHasPlansChecker,
  servicePlanListHasServiceChecker
} from "../../checkers/servicePlanListChecker";
import {
  serviceInRegionHistoryHasHistoriesChecker,
  serviceInRegionHistoryHasServiceChecker
} from "../../checkers/serviceInRegionHistoryChecker";
import { IGetServiceRegionHistoryResponseLegacy } from "../../../daos/types/IGetServiceRegionHistoryResponse";

export const listServiceHasItems = new QaItemDefinition<IServiceMetaData[]>(
  "/api/services results should return list of services",
  getServiceListOperation,
  serviceListHasItemChecker
);

export const listRegionHasItems = new QaItemDefinition<IGetRegionsResponse>(
  "/api/regions should return list of regions",
  getRegionListOperation,
  regionListHasItemChecker
);

// region service plan
export const getServicePlanListReturnServiceData = new QaItemDefinition<IGetSingleServicePlanResponseLegacy>(
  "/api/services/.../plans should return with valid service meta data",
  getServicePlanListOperation,
  servicePlanListHasServiceChecker
);

export const getServicePlanListReturnPlanData = new QaItemDefinition<IGetSingleServicePlanResponseLegacy>(
  "/api/services/.../plans should return plans data",
  getServicePlanListOperation,
  servicePlanListHasPlansChecker
);
// endregion


// region service in region history
export const getServiceInRegionHistoryReturnServiceData = new QaItemDefinition<IGetServiceRegionHistoryResponseLegacy>(
  "/api/services/.../plans/.../history should return with valid service meta data",
  getServiceInRegionHistoryOperation,
  serviceInRegionHistoryHasServiceChecker
);

export const getServiceInRegionHistoryReturnHistoryData = new QaItemDefinition<IGetServiceRegionHistoryResponseLegacy>(
  "/api/services/.../plans/.../history should return history data",
  getServiceInRegionHistoryOperation,
  serviceInRegionHistoryHasHistoriesChecker
);
// endregion
