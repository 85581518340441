import * as React from "react";
import styled from "styled-components";
import { useCallback, useEffect, useRef, useState } from "react";
import { Button, Select } from "@amzn/awsui-components-react";
import Header from "@amzn/awsui-components-react-v3/polaris/header";
import { Container } from "./SingleServiceHeader.style";
import DataFetchingError from "../common/DataFetchingError";
import { UserAbilities } from "../../models/UserAbilities";
import { SingleServiceHeaderProp } from "./SingleServiceHeader.types";
import { IconTip } from "@amzn/api-parity-react-component/lib/components/IconTip/IconTip";
import { isProgrammaticallyCategorized } from "../../utils/serviceMetadataUtil";
import { ButtonBar } from "../common/ButtonBar/ButtonBar";

export const SingleServiceHeaderTestId = {
  viewCategoryStaticText: "SingleServiceHeader-viewCategoryStaticText",
  updateCategoryButton: "SingleServiceHeader-updateCategoryButton",
  updateCategoryDropSelect: "SingleServiceHeader-updateCategoryDropSelect",
  programmaticallyCategorizedTip: "SingleServiceHeader-programmaticallyCategorizedTip",
  viewRmsButton: "SingleServiceHeader-viewRms"
};

const ServiceInfoSection = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const ServiceCategory = styled.div`
  flex-grow: 1;
`;

const ActionButton = styled.div`
  flex-grow: 0;
  flex-shrink: 0;
`;

export const SingleServiceHeader: React.FunctionComponent<SingleServiceHeaderProp> = (props) => {

  const {
    headerText,
    href,
    service,
    selectedOption,
    serviceCategoriesWithCurrent,
    handleCategorySelectionChange,
    updateCategoryButtonDisabled,
    onSubmit
  } = props

  const userAbilities: UserAbilities = props.userAbilities;

  const [unknownError, setUnknownError] = useState(false);
  const [loading, setLoading] = useState<boolean>(false);

  const mounted = useRef(false);
  useEffect(() => {
    mounted.current = true; // Will set it to true on mount ...
    return () => { mounted.current = false; }; // ... and to false on unmount
  }, []);

  const handleUpdateServiceBusinessPlan = useCallback(async () => {
    if (!onSubmit) {
      return;
    }
    setLoading(true);
    if (mounted.current) {
      setLoading(false);
    }
    try {
      const businessPlan = selectedOption?.text ?? "";
      await onSubmit(service, businessPlan);
    } catch (error) {
      setLoading(false);
      setUnknownError(true);
    }
  }, [
    service,
    selectedOption,
    onSubmit
  ]);

  function updateServiceCategory() {
    return (
      <ButtonBar>
        <Select
          key={SingleServiceHeaderTestId.updateCategoryDropSelect}
          data-testid={SingleServiceHeaderTestId.updateCategoryDropSelect}
          options={serviceCategoriesWithCurrent}
          selectedOption={selectedOption}
          onChange={handleCategorySelectionChange}
          selectedLabel="Selected"
        />
        <Button
          key={SingleServiceHeaderTestId.updateCategoryButton}
          data-testid={SingleServiceHeaderTestId.updateCategoryButton}
          variant="primary"
          loading={loading}
          disabled={updateCategoryButtonDisabled}
          onClick={handleUpdateServiceBusinessPlan}>
            Update Category
        </Button>
        {
          isProgrammaticallyCategorized(service) &&
          <IconTip
            key={SingleServiceHeaderTestId.programmaticallyCategorizedTip}
            data-testid={SingleServiceHeaderTestId.programmaticallyCategorizedTip}
            icon={"status-info"}
            tooltip={"Programmatically Categorized"}
          />
        }
      </ButtonBar>
    )
  }

  const viewServiceCategory = (text) => (
    <span
      data-testid={SingleServiceHeaderTestId.viewCategoryStaticText}
    >
      {text}
    </span>
  )

  return (
    <Container>
      {unknownError && <DataFetchingError/>}
      {
        (!!headerText) && <Header
          variant="h1">
          {headerText}
        </Header>
      }
      <ServiceInfoSection>
        <ServiceCategory>
          {
            (userAbilities.canWriteCategories && !!selectedOption) ? updateServiceCategory() : viewServiceCategory(selectedOption?.text)
          }
        </ServiceCategory>
        <ActionButton>
          {(href) && <Button data-testid={SingleServiceHeaderTestId.viewRmsButton} href={href}>View in RMS</Button>}
        </ActionButton>
      </ServiceInfoSection>

    </Container>
  );
};

export default SingleServiceHeader;
