import createSagaMiddleware from "redux-saga";
import { applyMiddleware, compose, createStore } from "redux";
import { rootReducer, RootState } from "./reducers/reducers";
import { rootSagas } from "./sagas/sagas";

export function store(initialState?: RootState, disableSaga?: boolean) {
  const sagaMiddleware = createSagaMiddleware()
  // @ts-ignore
  const composeEnhancers = (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({ trace: true, traceLimit: 25 })) || compose;
  const store = createStore(
    rootReducer,
    initialState,
    composeEnhancers(applyMiddleware(sagaMiddleware))
  );

  if (!disableSaga) {
    sagaMiddleware.run(rootSagas);
  }

  return store;
}
