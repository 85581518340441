import { expansionPlanById } from "@amzn/api-parity-react-component/lib/models/vos/AwsServiceExpansionPlan";
import { AwsServiceVO } from "@amzn/api-parity-react-component";
import { getDateForFileName } from "../../utils/dateUtil";
import { ExportDataBook, ExportDataCell } from "@amzn/excelerator";
import { dataToDataBook, emptyExportValue } from "../ExportTableToCSVExcel/ExportTableToCSVExcel";

export const servicesListCsvHeaders = [
  "RIP Name",
  "Long Name",
  "Sortable Name",
  "Category",
  "Visibility"
];

export const servicesListCSVFilename = `ServicesList-${getDateForFileName()}.csv`

export function servicesListDataModel(services: AwsServiceVO[]): ExportDataCell[][] {
  return services.map((service) => {
    const row = [
      service.nameVariations?.ripId ?? emptyExportValue,
      service.nameVariations?.long ?? emptyExportValue,
      service.nameVariations?.sortable ?? emptyExportValue,
    ] 
    
    const category_name = expansionPlanById.get(service.expansionPlan);
    category_name ? row.push(category_name.recon) : row.push( emptyExportValue )
    row.push(service.visibility ??  emptyExportValue)

    return row;
  })
}


export const generateServicesListWorkbook = (services: AwsServiceVO[]): ExportDataBook => {
  let servicesListExcelData: ExportDataCell[][] = [servicesListCsvHeaders]

  servicesListExcelData = servicesListExcelData.concat(servicesListDataModel(services))

  return dataToDataBook(servicesListExcelData);
}
