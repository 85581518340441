import { getReconApiClient } from "./daoUtil";
import { IGetAppStateResponse } from "./types/IGetAppStateResponse";

export class AppStateDao {
  /**
   * Get Application state which consists of application global state and user's permissions
   */
  async getAppState(): Promise<IGetAppStateResponse> {
    const client = getReconApiClient();
    const res = await client.get<IGetAppStateResponse>(this.getAppStateUrl());
    return res.data;
  }

  getAppStateUrl(): string {
    return "/api/appstate";
  }
}
