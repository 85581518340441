import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse, Method } from "axios";
import { camelize } from "@ridi/object-case-converter";
import urljoin from "url-join";
import { getConfig, getImpersonation, getImpersonationApiUrl, isImpersonating } from "../utils/environmentUtil";
import { MidwayIdentityCredentialProvider } from "@amzn/midway-identity-credential-provider";
import { REGION } from "../configs/environmentConfig";
// eslint-disable-next-line you-dont-need-lodash-underscore/is-string
import { isString } from "lodash";

// @ts-ignore
import { Endpoint, HttpRequest, Signers } from "aws-sdk";
import { normalizeRequestMethod, RequestMethod } from "../utils/requestUtil";
import { CONTENT_TYPE_AMAZON_JSON } from "../constants";
import { snakeCasePatches } from "../utils/patchUtil";
import { Optional } from "../models/types/Optional";
import { MidwayAxiosRetry } from "./MidwayAxiosRetry";

let provider;
const API_GATEWAY_SERVICE = "execute-api";
const GET_CSRF_TOKEN_PATH = "/api/tokens";

const RMS = {
  ServiceName: "rms-frontend",
  CoralModelServiceNamespace: "com.amazonaws.regions.management.frontend.service",
  CoralModelServiceName: "RegionsManagementFrontendService",
}

/**
 * Interface for pagination responses
 */
export interface PaginationResponse<T> {
  items: T[],
  nextToken?: string,
  NextToken?: string, // for non-camelized responses (like dates)
}

/**
 * The API paths that don't need CSRF tokens
 * CSRF tokens are not required for GET calls, but required
 * for most of the mutational calls in POST, PATCH or PUT
 * @type {[RegExp]}
 */
export const nonCsrfApiPath = [
  /\/api\/tokens$/i,  // The get csrf token path itself
  /([?&])csrftoken=/i, // Any URL already includes CSRF token
];


export interface ISig4Credential {
  accessKeyId: string;
  secretAccessKey: string;
  sessionToken: string;
}

const midwayRetry: MidwayAxiosRetry = new MidwayAxiosRetry();

/**
 * Client would be used for Recon's data fetched from website's s3 bucket
 */
export function getMidwayApiClient(): AxiosInstance {
  const instance = axios.create({
    withCredentials: true,
  });
  instance.interceptors.response.use(
    (response) => response,
    midwayRetry.errorHandler.bind(midwayRetry));

  return instance;
}

export function getALMApiClient(): AxiosInstance {
  const client = getALMSig4Client(getConfig()?.almApiUrl as string);
  client.interceptors.response.use(camelizeResponseInterceptor);
  return client;
}

export function getALMSig4Client(baseUrl: string): AxiosInstance {
  const instance = axios.create();
  instance.interceptors.request.use(getALMSig4RequestInterceptor(baseUrl));
  return instance;
}

export async function getALMEndpoint(urlOrPath: string, baseUrl: string, method: Method): Promise<Endpoint> {
  let endpoint = new Endpoint(urlOrPath);
  const baseUrlAbsent = (endpoint.host === "");
  let url = (baseUrlAbsent) ? urljoin(baseUrl, urlOrPath) : urlOrPath;

  return new Endpoint(url);
}

export function getALMSig4RequestInterceptor(baseUrl: string): (request: AxiosRequestConfig) => Promise<AxiosRequestConfig> {
  return async (request: AxiosRequestConfig): Promise<AxiosRequestConfig> => {
    const method = normalizeRequestMethod(request.method);
    let endpoint;

    if (isImpersonating()) {
      // @ts-ignore
      endpoint = await getALMEndpoint(request.url, getImpersonationApiUrl(), method);
    } else {
      // @ts-ignore
      endpoint = await getALMEndpoint(request.url, baseUrl, method);
    }

    request.url = endpoint.href;
    request.method = method;
    request.data = snakeCasePatchBody(method, normalizeRequestBody(method, request.data));

    let headers = request.headers;

    if (isImpersonating()) {
      // skip header signing when impersonating because it is calling a special backend
      headers = {
        ...headers,
        impersonation: getImpersonation()
      }
    } else {
      const credentials = await getSig4Credentials();
      const signedRequest = signRequest(request, credentials);
      headers = signedRequest.headers;
      removeUnsafeHeaders(headers);
    }

    Object.assign(request, { headers });
    return request;
  }
}

export function getReconApiClient(): AxiosInstance {
  const client = getSig4Client(getConfig()?.apiUrl as string);
  client.interceptors.response.use(camelizeResponseInterceptor);
  return client;
}

export function getRmsApiClient(): AxiosInstance {
  const instance = axios.create();
  instance.interceptors.request.use(rmsRequestInterceptor);
  instance.interceptors.response.use(camelizeResponseInterceptor);
  return instance;
}

export async function getSig4Credentials(): Promise<ISig4Credential> {
  if (!provider) {
    provider = new MidwayIdentityCredentialProvider({
      IdentityPoolId: getConfig()?.cognitoIdentityPoolId as string,
    }, { region: REGION });
  }

  await provider.getPromise();
  return {
    accessKeyId: provider.accessKeyId,
    secretAccessKey: provider.secretAccessKey,
    sessionToken: provider.sessionToken,
  };
}

export function getSig4Client(baseUrl: string): AxiosInstance {
  const instance = axios.create();
  instance.interceptors.request.use(getSigv4RequestInterceptor(baseUrl));
  return instance;
}


export function normalizeRequestBody(method: string, requestBody: Optional<string | Object>): string | Object {
  if (method === RequestMethod.get) {
    return ""
  } else {
    return requestBody || {};
  }
}

export function snakeCasePatchBody(method, requestBody) {
  if (method === RequestMethod.patch) {
    return snakeCasePatches(requestBody);
  }
  return requestBody;
}

/**
 * Interceptor for SigV4 request
 */
export async function rmsRequestInterceptor(request: AxiosRequestConfig): Promise<AxiosRequestConfig> {
  const method = normalizeRequestMethod(request.method);
  // @ts-ignore
  const endpoint = await getEndpoint(request.url, getConfig()?.rmsApiUrl, method, getEmptyCsrfToken);
  request.url = endpoint.href;
  request.method = method;
  request.data = normalizeRequestBody(method, request.data);

  const operation = request.headers.operation;
  const rmsHeaders = {
    "X-Amz-Target": `${RMS.CoralModelServiceNamespace}.${RMS.CoralModelServiceName}.${operation}`,
  }

  const credentials = await getSig4Credentials();
  const signedRequest = signRequest(request, credentials, RMS.ServiceName, rmsHeaders);
  const headers = signedRequest.headers;
  removeUnsafeHeaders(headers);

  Object.assign(request, { headers });
  return request;
}


/**
 * Interceptor for SigV4 request
 */

export function getSigv4RequestInterceptor(baseUrl: string): (request: AxiosRequestConfig) => Promise<AxiosRequestConfig> {
  return async (request: AxiosRequestConfig): Promise<AxiosRequestConfig> => {
    const method = normalizeRequestMethod(request.method);
    let endpoint;

    if (isImpersonating()) {
      // @ts-ignore
      endpoint = await getEndpoint(request.url, getImpersonationApiUrl(), method);
    } else {
      // @ts-ignore
      endpoint = await getEndpoint(request.url, baseUrl, method);
    }

    request.url = endpoint.href;
    request.method = method;
    request.data = snakeCasePatchBody(method, normalizeRequestBody(method, request.data));

    let headers = request.headers;

    if (isImpersonating()) {
      // skip header signing when impersonating because it is calling a special backend
      headers = {
        ...headers,
        impersonation: getImpersonation()
      }
    } else {
      const credentials = await getSig4Credentials();
      const signedRequest = signRequest(request, credentials);
      headers = signedRequest.headers;
      removeUnsafeHeaders(headers);
    }

    Object.assign(request, { headers });
    return request;
  }
}


/**
 *
 * @param request
 * @param credentials
 * @param service
 * @param headers
 * @return {HttpRequest}
 */
export function signRequest(request, credentials: ISig4Credential, service=API_GATEWAY_SERVICE, headers = {}) {
  const endpoint = new Endpoint(request.url);
  const httpReq = new HttpRequest(endpoint, REGION);
  httpReq.method = request.method;
  httpReq.headers = {
    ...headers,
    "Accept": "application/json",
    "Content-Type": CONTENT_TYPE_AMAZON_JSON,
    "Host": endpoint.host,
  };
  // @ts-ignore
  httpReq.path = endpoint.path;
  httpReq.body = stringifyData(request.data);

  const signer = new Signers.V4(httpReq, service);
  signer.addAuthorization(credentials, new Date());

  return httpReq;
}

export function stringifyData(data) {
  if (isString(data)) {
    return data;
  }
  return JSON.stringify(data);
}


export function removeUnsafeHeaders(headers) {
  delete headers["Host"];
  delete headers["host"];
}

/**
 * Return an endpoint with the supplied parameters, if urlOrPath does not contain any host information,
 */
export async function getEndpoint( urlOrPath: string, baseUrl: string, method: Method, getCsrfTokenFn = getCsrfToken): Promise<Endpoint> {
  let endpoint = new Endpoint(urlOrPath);
  const baseUrlAbsent = (endpoint.host === "");
  let url = (baseUrlAbsent) ? urljoin(baseUrl, urlOrPath) : urlOrPath;

  if (needsCsrfToken(url, method)) {
    const token = await getCsrfTokenFn();
    if (token) {
      url = urljoin(url, `?csrftoken=${encodeURIComponent(token)}`);
    }
  }

  return new Endpoint(url);
}


/**
 * Whether the endpoint requires CSRF token
 */
export function needsCsrfToken(url: string, method: Method): boolean {
  if (method === RequestMethod.get) {
    return false;
  }

  return !nonCsrfApiPath.some((pathExp) => {
    return pathExp.test(url);
  });
}

/**
 * Get CSRF token
 */
export async function getCsrfToken(): Promise<string> {
  const client = getReconApiClient();
  const res = await client.post(GET_CSRF_TOKEN_PATH);
  return res.data.tokenId;
}

export function getEmptyCsrfToken() {
  return Promise.resolve();
}

export function camelizeResponseInterceptor(response: AxiosResponse): AxiosResponse {
  // TODO: Detect drift away error by API Gateway which carries 200 response code
  const rawData = response.data;
  let res: AxiosResponse = response;
  if (rawData && typeof (rawData) === "object") {
    const excludeFromCamelize = "api/eventdates"
    const shouldCamelize: boolean = !response.config?.url?.includes(excludeFromCamelize)
    const data = shouldCamelize ? camelize(rawData, { recursive: true }) : rawData;
    res = {
      ...response,
      data
    };
  }
  return res;
}

// https://i.amazon.com/RECON-4681
// Because some service names contain a '/', we normalize them by using a '+' so they do
// not cause issues when the name is used in a link.
export function normalizeServiceName(serviceName: Optional<string>): string {
  return serviceName?.split("/")?.join("+") || "";
}

/**
 * Convert a value that would be used as a path variable when constructing an API call
 * This means the characters such as "/" etc would need to be converted and encoded
 * @param value
 */
export function sanitizePathValue(value: string): string {
  return encodeURIComponent(normalizeServiceName(value));
}

export function isResponseHtml(value: string | object): boolean {
  if (typeof value !== "string") {
    return false;
  }

  return (value as string).toLowerCase().indexOf("<html") >= 0;
}
