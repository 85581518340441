import * as React from "react";
import { FunctionComponent } from "react";
import { useParams } from "react-router-dom";
import { SingleServiceHeaderContainer } from "../SingleServiceHeader/SingleServiceHeaderContainer";
import { NoAccessError, NoAccessErrorTestId } from "../../../components/common/NoAccessError";
import { UserAbilities } from "../../../models/UserAbilities";
import { SingleServiceContactsContainer } from "../../ServiceContacts/SingleServiceContactsContainer";
import { useUserPermission } from "../../../hooks/useUserPermission";
import { ISingleServiceContainerPathParam } from "../SingleServiceContainer.types";
import { fixFormatServiceId } from "../../../utils/urlUtil";

export const SingleServiceContactContainerTestId = {
  header:  "SingleServiceContactContainer-header",
  noAccess: NoAccessErrorTestId.flash,
}

export interface ISingleServiceContactContainerPathParam {
  serviceName: string;
}

export interface IAccessibleSingleServiceContactContainerProps {
  serviceId: string;
  showHeader: boolean;
  userAbilities: UserAbilities;
}

// This view is only for unified ux
export const SingleServiceContactContainer: FunctionComponent<{}> = () => {
  const { serviceName } = useParams<ISingleServiceContainerPathParam>();

  const userAbilities: UserAbilities = useUserPermission(serviceName);
  const canAccess = !!userAbilities.canRead;
  return canAccess ?
    <AccessibleSingleServiceContactContainer
      serviceId={serviceName}
      showHeader={true}
      userAbilities={userAbilities}
    />
    : <NoAccessError />;
}

export const AccessibleSingleServiceContactContainer: FunctionComponent<IAccessibleSingleServiceContactContainerProps> = (props): JSX.Element => {
  return <React.Fragment>
    {
      (props.showHeader) && <SingleServiceHeaderContainer data-testid={SingleServiceContactContainerTestId.header} serviceId={fixFormatServiceId(props.serviceId)} />
    }
    <SingleServiceContactsContainer
      key={props.serviceId}
      nameRip={props.serviceId}
      userAbilities={props.userAbilities}
    />
  </React.Fragment>
}
