import React from "react";
import InlineHeader from "./StyledComponents/InlineHeader";

interface SinglePageHeaderSettings {
  headerText: string;
  subText?: JSX.Element;
  manageButtonText?: string;
  href: string;
}

export const SinglePageHeader: React.FunctionComponent<SinglePageHeaderSettings> = (props) => {
  const { headerText, subText, manageButtonText, href } = props;

  const manageButton = (
    <div>
      <a className="awsui-button-variant-normal awsui-button" href={href}>
        {manageButtonText}
      </a>
    </div>
  );

  const preStyle: React.CSSProperties = {
    whiteSpace: "pre",
    marginLeft: "1rem",
  };

  return (
    <div className="awsui-util-mb-m awsui-util-mt-xs">
      <div className="awsui-util-action-stripe-large">
        <div className="awsui-util-action-stripe-title">
          <InlineHeader>
            <h1>{headerText}</h1>
            <h5 className="inline-header-subtext" style={preStyle}>
              {subText}
            </h5>
          </InlineHeader>
        </div>
        {manageButtonText ? (
          <div className="awsui-util-action-stripe-group">{manageButton}</div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};
