import * as React from "react";
import { FunctionComponent, useEffect } from "react";
import { IHasDataTestId } from "../../../../models/IHasDataTestId";
import { LaunchVO } from "../../../../models/vos/LaunchVO";
import { LaunchInformationBanner } from "../../common/LaunchInformationBanner/LaunchInformationBanner";
import { LaunchState } from "../../../ProductLaunches/LaunchWizard/LaunchWizard.types";
import { draftBannerBaseTestId, draftBannerMessage, draftBannerTitle } from "./LaunchDraftBanner.constants";
import { formulateEndOfDraftAlertString } from "./LaunchDraftBanner.helpers";

export interface LaunchDraftBannerProps extends IHasDataTestId {
  launch: LaunchVO
}

export const LaunchDraftBanner: FunctionComponent<LaunchDraftBannerProps> = (props) => {
  const [visible, setVisible] = React.useState(false);
  const [alertMessage, setAlertMessage] = React.useState("");

  useEffect(() => {
    if(props.launch?.state?.toLowerCase() === LaunchState.DRAFT.toLowerCase()) {
      const alertString = draftBannerMessage + formulateEndOfDraftAlertString(props.launch)
      setAlertMessage(alertString);
      setVisible(true)
    }
    else {
      setVisible(false)
    }
  }, [props.launch, props.launch?.state])

  return <LaunchInformationBanner visible={visible} header={draftBannerTitle} text={alertMessage} setVisible={setVisible} data-testid={draftBannerBaseTestId}/>
}
