import { ProductVO } from "../../models/vos/ProductVO";
import { ProductTableVM } from "./ProductsList.types";

export const ProductVOToProductTableVM = (items: ProductVO[]): ProductTableVM[] => {
  return items.map((item) => {
    const product = new ProductTableVM();

    product.sourceItem = item;

    product.id = item.id;
    product.name = item.name;
    product.vp = item.vp;
    product.gm = item.gm;
    product.audience = item.audience;
    product.description = item.description;
    product.state = item.state;
    product.category = item.category;
    product.codename = item.codename;
    product.ripId = item.ripId;
    product.cti = item.cti;
    product.blueprintUid = item.blueprintUid;
    product.parentProductId = item.parentProductId;
    product.productApprovalDate = item.productApprovalDate;
    product.productApprovedBy = item.productApprovedBy;
    product.type = item.type;

    // Boolean processing
    product.confidential = item.confidential ? "Yes" : "No";

    // Array processing
    product.primaryOwner = item.primaryOwner ? item.primaryOwner.alias : undefined;
    product.secondaryOwners = Array.isArray(item.secondaryOwners) && item.secondaryOwners.length > 0
      ? item.secondaryOwners?.slice(1)?.reduce((previous, current) => {
        current.alias && (previous = previous.concat(`,${current.alias}`));
        return previous;
      }, item.secondaryOwners[0].alias)
      : undefined;
    product.tags = Array.isArray(item.tags) && item.tags.length > 0
      ? item.tags.join(", ")
      : undefined;

    return product;
  });
}
