import { RegionVO } from "@amzn/api-parity-react-component/lib/models/vos/RegionVO";
import { GENERALLY_AVAILABLE } from "../constants";

export const resourceParityRegionPartitions: ReadonlySet<string> = new Set([
  "aws",
  "aws-cn",
  "aws-us-gov",
  "aws-iso",
  "aws-iso-b",
]);


/**
 * Not all the regions have resource parity data, this shortcut
 * function prevents those regions queried or listed to keep the UI clean
 * @param region
 */
export function isResourceParityRegion(region: RegionVO): boolean {
  return region.status === GENERALLY_AVAILABLE && resourceParityRegionPartitions.has(region.partition);
}
