import { IHasId } from "@amzn/api-parity-react-component/lib/models/IHasId";
import { AwsServiceVO, RegionVO, ServiceBuildPlanVO } from "@amzn/api-parity-react-component";
import { ExcludeFromPercentage } from "@amzn/api-parity-react-component/lib/models/vos";
import { hydrateModel } from "../../utils/modelUtil";
import { ParityStatusEnum } from "@amzn/api-parity-react-component/lib/models/ParityStatus";
import { ParityRollupStatistics } from "../ParityRollupStatistics";

export class ServiceInRegionRollupStatusVO implements IHasId {
  id: string = "";
  service: AwsServiceVO = new AwsServiceVO();
  region: RegionVO = new RegionVO();

  statistics: ParityRollupStatistics = new ParityRollupStatistics();
  exception?: ServiceBuildPlanVO;

  /**
   * Status of the raw service status in the region
   */
  status: ParityStatusEnum = ParityStatusEnum.NoData;

  /**
   * A calculation hint whether the parity should be excluded from % calculation
   * A feature parity should be excluded if
   * - There is an exception associated with the Parity such as not launching
   */
  excludeFromPercentage: ExcludeFromPercentage = ExcludeFromPercentage.Include;

  constructor(source?: Partial<ServiceInRegionRollupStatusVO>) {
    hydrateModel(source, this);
  }
}
