import { immerable } from "immer";
import { FilterFn } from "@amzn/api-parity-react-component/lib/utils/collectionUtil";
import { FilterableTreeItem, MatchedState } from "./FilterableTreeItem";
import { FeatureVO } from "./vos";
import { AwsServiceVO } from "@amzn/api-parity-react-component/lib/models/vos/AwsServiceVO";
import { hydrateModel } from "../utils/modelUtil";
import { Optional } from "./types/Optional";
import { ISortComparator } from "@amzn/api-parity-react-component/lib/models/SortBy";

export enum FeatureTreeItemType {
  Feature = "Feature",
  Service = "Service",
}

export class FeatureTreeItem extends FilterableTreeItem {
  [immerable]
  id: string = "";
  name: string = "";
  entity: FeatureVO | AwsServiceVO = new FeatureVO();

  // Because of limitation of RSuite grid, children cannot be a getter
  // This is to be investigated
  children?: FeatureTreeItem[];

  constructor(source?: Partial<FeatureTreeItem>) {
    super();
    hydrateModel(source, this);
  }

  addChild(item: FilterableTreeItem) {
    super.addChild(item);
    this.children = this.matchedChildItems as FeatureTreeItem[];
  }

  applyFilter(filter: FilterFn<FeatureTreeItem>): MatchedState {
    const result: MatchedState = super.applyFilter(filter as FilterFn<FilterableTreeItem>);
    this.children = this.matchedChildItems as FeatureTreeItem[];
    return result;
  }

  applySort(sort:Optional<readonly ISortComparator<FeatureTreeItem>[]>): void {
    super.applySort(sort as Optional<readonly ISortComparator<FilterableTreeItem>[]>);
    this.children = this.matchedChildItems as FeatureTreeItem[];
  }

  resetSort() {
    super.resetSort();
    this.children = this.matchedChildItems as FeatureTreeItem[];
  }

  resetChildFilter() {
    super.resetChildFilter();
    this.children = this.matchedChildItems as FeatureTreeItem[];
  }

  resetFilter() {
    super.resetFilter();
    this.children = this.matchedChildItems as FeatureTreeItem[];
  }

}

