import * as React from "react";
import { useCallback, useState } from "react";
import { getAppState } from "../../models/ApplicationState";
import { ProductDao } from "../../daos/ProductDao";
import useBreadcrumbs from "../../components/Breadcrumbs/useBreadcrumbs";
import { ProductListTestIdSuffixes, ProductsList } from "../../components/ProductsList/ProductsList";
import { NoAccessError } from "../../components/common/NoAccessError";
import { productDtosToVOs } from "../../parsers/productParser";
import {
  Alert,
  Button,
  CollectionPreferencesProps,
  Header,
  Link,
  PaginationProps,
  PropertyFilterProps,
  SpaceBetween,
  Spinner
} from "@amzn/awsui-components-react-v3";
import { REPORT_A_BUG_URL } from "../../constants";
import { NonCancelableCustomEvent } from "@amzn/awsui-components-react-v3/polaris/internal/events";
import { useLocation } from "react-router";
import { useHistory } from "react-router-dom";
import { DEFAULT_TABLE_PREFERENCES } from "../../components/ProductsList/ProductsListProperties";
import useTitleBanner from "../../components/unified/TitleBanner/useTitleBanner";
import { getDataTestIdProp } from "../../utils/testUtil";
import { ExportTableToCSVExcel } from "../../components/ExportTableToCSVExcel/ExportTableToCSVExcel";
import { generateProductsListWorkbook } from "../../components/ProductsList/ProductsListCsvExport";
import { ProductVO } from "../../models/vos/ProductVO";
import { useLoadInfinitePages } from "../../utils/swrUtil";
import { getSwrConfig } from "../../daos/swrUtil";

// TODO: - add tests
//       - update product querying

const getFilter = (search) => {
  try {
    return JSON.parse(atob(new URLSearchParams(search).get("filter")!))
  } catch {
    return undefined;
  }
}

const TableHeader = ({ count, generateDataBook, ...props }) => (
  <Header
    counter={
      "(" + count + ")"
    }
    actions={
      <SpaceBetween direction="horizontal" size="xs">
        <Button
          href="https://issues.amazon.com/issues/create?template=f29147d7-a56a-4b37-a8c7-79b65eaf9475"
          target="_blank"
          variant="primary"
          {...getDataTestIdProp(props, ProductListTestIdSuffixes.createButton)}
        >
          Create a new product
        </Button>
        <ExportTableToCSVExcel
          disabled={props.loading}
          generateDataBook={generateDataBook}
          fileNamePrefix="ProductsList"
        />
      </SpaceBetween>
    }
    {...getDataTestIdProp(props, ProductListTestIdSuffixes.tableHeader)}
  >
    {props.loading && <Spinner/>}
    All products
  </Header>
);

const ProductsListContainer = () => {
  const userAbilities = getAppState().userAbilities;

  const history = useHistory();
  const { search } = useLocation();

  const [page, setPage] = useState<number>(Number(new URLSearchParams(search).get("page")) || 1);
  const [filter, setFilter] = useState<any>(getFilter(search));

  const { data: products, error, loadingMore } = useLoadInfinitePages<ProductVO>(
    "/alm/products",
    async (request, token) => {
      const dao = new ProductDao();
      const dto = await dao.getProducts(200, token);
      return { items: productDtosToVOs(dto.items), nextToken: dto.nextToken };
    },
    { ...getSwrConfig() }
  );

  useBreadcrumbs(["Products"]);
  useTitleBanner({ visible: true, title: "Products" });

  const getExportDataBook = useCallback(() => {
    return generateProductsListWorkbook(products ?? []);
  }, [products]);

  const getPreferences = useCallback(() => {
    try {
      const storedPreferences = JSON.parse(localStorage.getItem("almProductsListPreferences") ?? "{}");

      if (storedPreferences.visibleContent.includes("name")) {
        return storedPreferences;
      }
    } catch (e) {
      localStorage.setItem("almProductsListPreferences", JSON.stringify(DEFAULT_TABLE_PREFERENCES));
      return DEFAULT_TABLE_PREFERENCES;
    }
  }, []);

  const paginationChangeHandler = useCallback((event: NonCancelableCustomEvent<PaginationProps.ChangeDetail>) => {
    const queryParams = new URLSearchParams(search);
    queryParams.set("page", event.detail.currentPageIndex.toString());
    history.push({
      search: queryParams.toString()
    });
    setPage(event.detail.currentPageIndex);
  }, [history, search]);

  const preferenceChangeHandler = useCallback((event: NonCancelableCustomEvent<CollectionPreferencesProps.Preferences>) => {
    localStorage.setItem("almProductsListPreferences", JSON.stringify(event.detail))
  }, []);

  const filterChangeHandler = useCallback((event: NonCancelableCustomEvent<PropertyFilterProps.Query>) => {
    const queryParams = new URLSearchParams(search);
    queryParams.set("filter", btoa(JSON.stringify(event.detail)));
    history.push({
      search: queryParams.toString()
    });
    setFilter(event.detail);
  }, [history, search]);

  if (true) {
    if (error) {
      return (
        <Alert
          type="error"
          header="Data Fetching Error"
        >
          {"There was an error fetching the product list, please try again or submit a "}
          <Link
            external
            externalIconAriaLabel="Opens in a new tab"
            href={REPORT_A_BUG_URL}
          >
            ticket
          </Link>.
        </Alert>
      );
    }
    return <ProductsList
      items={products ?? []}
      loading={loadingMore}
      onPaginationChange={paginationChangeHandler}
      onPreferenceChange={preferenceChangeHandler}
      onFilterChange={filterChangeHandler}
      filter={filter}
      page={page}
      header={
        <TableHeader
          count={products?.length ?? 0}
          generateDataBook={getExportDataBook}
          loading={loadingMore}
        />
      }
      preferences={getPreferences()}
    />
  }
  return <NoAccessError/>
}

export default ProductsListContainer;
