import { RegionVO } from "@amzn/api-parity-react-component/lib/models/vos/RegionVO";
import { FeatureTreeItem } from "../../../../../models/FeatureTreeItem";
import { emptyExportValue } from "../../../../ExportTableToCSVExcel/ExportTableToCSVExcel";
import { ExportDataBook, ExportDataCell, ExportDataSheet, RichDataCell } from "@amzn/excelerator";
import * as awsui from "@amzn/awsui-design-tokens";
import { getPolarisColor } from "@amzn/api-parity-react-component/lib/utils/colorUtil";
import { getDataCellRecord } from "@amzn/excelerator/lib/excel/excelUtil";
import { IFeatureParityOverviewGridViewModel } from "./FeatureParityOverviewGrid.types";
import { generateDataCells, IExportColumnDefinition } from "@amzn/api-parity-react-component/lib/utils/exportUtil";
import { Nullable } from "../../../../../models/types/Nullable";
import { MatchedState } from "../../../../../models/FilterableTreeItem";
import { getFeatureTreeItemService, getGm, getVp } from "../../../../../utils/featureTreeItemUtil";
import { Optional } from "../../../../../models/types/Optional";
import { AwsServiceVO, ServiceBuildPlanCollectionView, ServiceBuildPlanVO } from "@amzn/api-parity-react-component";
import { FeatureVO, ServiceInRegionRollupStatusVO } from "../../../../../models/vos";
import { toPercent } from "@amzn/api-parity-react-component/lib/utils/modelUtil";
import { zeroScoreValue } from "./FeatureParityOverviewGrid.util";
import {
  FeatureParityCollectionView,
  ServiceInRegionRollupStatusCollectionView
} from "../../../../../models/collections";
import { IFeatureParityDisplay } from "../../../../../models/featureParityDisplay";
import { getExceptionDisplay, getFeatureParityDisplay } from "../../featureParityDisplayUtil";

export function getColumnDefinitions(viewModel: IFeatureParityOverviewGridViewModel): IExportColumnDefinition<FeatureTreeItem>[] {
  const definitions: IExportColumnDefinition<FeatureTreeItem>[] = [
    {
      header: "Parent Service ShortName",
      cell: (row) => getCell(row, getParentEntity(row).id),
    },

    {
      header: "Child Service Long Name",
      cell: (row) => getCell(row, getChildServiceEntity(row)?.name ?? emptyExportValue),
    },

    {
      header: "Child Service ShortName",
      cell: (row) => getCell(row, getChildServiceEntity(row)?.id ?? emptyExportValue),
    },

    {
      header: "Feature Long Name",
      cell: (row) => getCell(row, getFeatureEntity(row)?.name ?? emptyExportValue),
    },

    {
      header: "Feature ShortName",
      cell: (row) => getCell(row, getFeatureEntity(row)?.ripId ?? emptyExportValue),
    },

    {
      header: "GM",
      cell: (row) => getCell(row, getGm(row)),
    },

    {
      header: "VP",
      cell: (row) => getCell(row, getVp(row)),
    },

    {
      header: "Parity %",
      // @ts-ignore
      cell: (row) => getCell(row, !zeroScoreValue.has( viewModel.parityScore.byEntity.get(row.entity)) ? toPercent(viewModel.parityScore.byEntity.get(row.entity) as number, 1)  : "-"),
    },
  ];

  viewModel.regions.items.forEach((region) => {
    definitions.push({
      header: region.airportCode,
      cell: (row) => getCell(row, getParity(row, region, viewModel.serviceParities, viewModel.featureParities, viewModel.featureParityRollupByService)),
    })
  });

  return definitions;
}

export function getCell(item: FeatureTreeItem, primitiveValue: Nullable<string | number>): ExportDataCell {
  if (item.children) {
    return new RichDataCell({
      backgroundColor: getPolarisColor(awsui.colorTextHomeHeaderSecondary)
    }).withData(getDataCellRecord(primitiveValue));
  }

  return primitiveValue;
}

export function getParity(item: FeatureTreeItem,
                          region: RegionVO,
                          serviceParities: ServiceBuildPlanCollectionView,
                          featureParities: FeatureParityCollectionView,
                          featureParityRollupByService: ServiceInRegionRollupStatusCollectionView
): string  {
  const service: Optional<AwsServiceVO> = getFeatureTreeItemService(item);

  if (service) {
    return getServiceParityDisplay(service, region, serviceParities, featureParityRollupByService).plain;
  }

  return getFeatureParityDisplay(item.entity as FeatureVO, region, featureParities).plain;

}

export function getServiceParityDisplay(
  service: AwsServiceVO,
  region: RegionVO,
  serviceParities: ServiceBuildPlanCollectionView,
  featureParityRollupByService: ServiceInRegionRollupStatusCollectionView,
): IFeatureParityDisplay {
  const plan: Optional<ServiceBuildPlanVO> = serviceParities.byServiceRegion(service, region);
  const rollup: ServiceInRegionRollupStatusVO = featureParityRollupByService.byServiceRegion(service, region) as ServiceInRegionRollupStatusVO;

  if (rollup.exception) {
    return getExceptionDisplay(rollup.exception);
  }

  return {
    plain: plan?.status ?? emptyExportValue,
    // @ts-ignore
    visual: null,
  }
}


export function viewModelToDataBook(
  viewModel: IFeatureParityOverviewGridViewModel
): ExportDataBook {
  const columnDefinitions = getColumnDefinitions(viewModel);
  const flattenedItems: FeatureTreeItem[] = getFlattenedFeatureTreeItems(viewModel.data);
  const cells = generateDataCells(columnDefinitions, flattenedItems);
  return new ExportDataBook({
    sheets: [
      new ExportDataSheet({
        rows: cells
      })
    ]
  });
}

export function getFlattenedFeatureTreeItems(items: FeatureTreeItem[]): FeatureTreeItem[] {
  const result: FeatureTreeItem[] = [];
  items
    .filter((item) => item.matchState !== MatchedState.NoMatch)
    .forEach((item) => {
      result.push(item);
      if (item.children) {
        result.push(...getFlattenedFeatureTreeItems(item.children));
      }
    });
  return result;
}


export function getParentEntity(item: FeatureTreeItem): AwsServiceVO {
  let node: FeatureTreeItem = item;
  while (node.depth > 0) {
    node = node.parent as FeatureTreeItem;
  }
  return node.entity as AwsServiceVO;
}

export function getChildServiceEntity(item: FeatureTreeItem): Optional<AwsServiceVO> {
  if (item.depth === 0) {
    return;
  }

  let node: FeatureTreeItem = item;
  while (node.depth > 1) {
    node = node.parent as FeatureTreeItem;
  }

  const service: Optional<AwsServiceVO> = getFeatureTreeItemService(node);
  if (service) {
    return service;
  }

  return getParentEntity(item);
}

export function getFeatureEntity(item: FeatureTreeItem): Optional<FeatureVO> {
  if (item.depth === 0) {
    return;
  }

  let node: FeatureTreeItem = item;
  while (node.depth > 1) {
    node = node.parent as FeatureTreeItem;
  }

  const service: Optional<AwsServiceVO> = getFeatureTreeItemService(node);
  if (!service) {
    return node.entity as FeatureVO;
  }
}
