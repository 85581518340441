export const FeatureParityOverviewGridTestId = {
  component: "FeatureParityOverviewGrid",
  legend: "FeatureParityOverviewGrid-legend",
}

export const FeatureParityOverviewGridTestIdSuffix = {
  /**
   * Used as a part to tag contents in grid
   */
  content: {
    name: "-name-",
    parityScore: "-parityScore-",
  }
}

