import * as React from "react";
import { useCallback, useEffect, useState } from "react";
import moment from "moment";
import { Icon, Tooltip } from "@amzn/awsui-components-react";
import * as _ from "lodash";
import RegionsTable from "../common/TableComponents/RegionsTable";
import EnterEstimatedDate from "../EnterEstimatedDate/EnterEstimatedDate";
import BusinessCaseOverride from "../BusinessCaseOverride/BusinessCaseOverride";
import {
  defaultTableConfig,
  genAriaLabel,
  remapColumnWidthsChange,
  sortConfidence,
  sortDeliveryDate,
  sortRegion,
  sortRegionLaunchDate,
  sortStatus,
} from "../../utils/tableUtils";
import { NON_GLOBALLY_EXPANDING } from "../../constants";
import { ProjectedDate } from "../common/ProjectedDate";
import {
  getRmsProjectedFinishDateFromServiceRegionItem,
  isServicePlanApplicable
} from "../../utils/rmsProjectedDateUtil";
import { RmsProjectedDateColumnHeader } from "../common/RmsProjectedDateColumnHeader";
import { attachRegionObject } from "../../utils/planUtil";
import { Region, RegionLaunch } from "./SingleService.common";
import { PlanConfidence } from "../PlanConfidence/PlanConfidence";
import { toShortDate } from "../../utils/dateUtil";
import * as awsui from "@amzn/awsui-design-tokens/polaris";
import { DEFAULT_STATUS } from "../../utils/singleRegionUtils";
import {
  addBlueprintColumnDefinition,
  addBlueprintSortableColumn,
  addBlueprintTableContentSelectorOption,
  isRmsManaged,
  sortRmsProjectedFinishedDate
} from "./SingleServiceNotCompletedTableUtil";
import { ISecurityTableColumnDefinition } from "../../models/polaris2/SecurityTableColumnDefinition";
import { IServicePlan } from "../../models/types/IServicePlan";
import {
  ISingleServiceNotCompletedTableColumnWidth,
  SingleServiceNotCompletedTableProps
} from "./SingleServiceNotCompletedTable.types";
import { canSeeModifyPlanAction } from "../../utils/securityUtil";
import { DialogFooterButtonBar } from "../common/Dialog/DialogFooterButtonBar";
import { LoadingIndicator } from "../LoadingIndicator/LoadingIndicator";
import { EnterEstimatedDateSubmitDelegate } from "../../models/delegates/EnterEstimatedDateSubmitDelegate";
import { IServiceInRegionHistory } from "../../daos/types/IServiceInRegionHistory";
import {
  getNonEmptyProperties,
  IReconcileCollectionContentResult,
  reconcileCollectionContent
} from "../../utils/collectionUtil";
import { UserAbilities } from "../../models/UserAbilities";
import { ServiceInRegionDisposition } from "../../daos/types/ServiceInRegionDisposition";
import { Optional } from "../../models/types/Optional";
import TablePropertyFiltering
  from "@amzn/awsui-components-react/polaris/table-property-filtering/table-property-filtering";
import { BusinessCaseOverrideSubmitDelegate } from "../../models/delegates/BusinessCaseOverrideSubmitDelegate";
import { IGenericMutationResponseDto } from "../../daos/types/IGenericMutationResponseDto";
import {
  generateSingleServiceNotCompletedNoDatesWorkbook,
  generateSingleServiceNotCompletedWorkbook,
} from "./SingleServiceNotCompletedTableCSVExport";
import { ExportTableToCSVExcel } from "../ExportTableToCSVExcel/ExportTableToCSVExcel";
import { ButtonBar } from "../common/ButtonBar/ButtonBar";

export const SingleServiceNotCompletedTableTestId = {
  table: "SingleServiceNotCompletedTable-table",
  enterEstimatedDate: "SingleServiceNotCompletedTable-enterEstimatedDate",
  businessCaseOverride: "SingleServiceNotCompletedTable-businessCaseOverride",
  exportTable: "SingleServiceNotCompletedTable-exportTable",
}

const fields = {
  0: "region",
  1: "status",
  2: "confidence",
  3: "date",
  4: "rmsProjectedDate",
};

function getSortableColumns(plan) {
  const columns = [
    { id: "region", field: "region", comparator: sortRegion },
    { id: "regionLaunch", field: "regionLaunchDate", comparator: sortRegionLaunchDate },
    { id: "status", field: "status", comparator: sortStatus },
    { id: "confidence", field: "confidence", comparator: sortConfidence },
    { id: "date", field: "date", comparator: sortDeliveryDate },
  ];

  if (isServicePlanApplicable(plan)) {
    // TODO: tangentl improve type definitions for comparators and various data types
    // @ts-ignore
    columns.push({ id: "rmsProjectedDate", field: "rmsProjectedDate", comparator: sortRmsProjectedFinishedDate });
  }

  addBlueprintSortableColumn(columns)

  return columns;
}

const getFilteringOptions = (items: IServicePlan[], userAbilities: UserAbilities): TablePropertyFiltering.Option[] => [
  {
    groupValuesLabel: "Airport Codes",
    propertyKey: "region",
    propertyLabel: "Airport Code",
    values: getNonEmptyProperties(items, (plan) => plan.region),
  },
  {
    groupValuesLabel: "Region Launch Dates",
    propertyKey: "regionLaunchDate",
    propertyLabel: "Region Launch Date",
    values: getNonEmptyProperties(items, (plan) => plan.regionLaunchDate),
    canReadDates: userAbilities.canReadDates,
  },
  {
    groupValuesLabel: "Statuses",
    propertyKey: "status",
    propertyLabel: "Status",
    values: getNonEmptyProperties(items, (plan) => plan.status),
  },
  {
    groupValuesLabel: "Confidence",
    propertyKey: "confidence",
    propertyLabel: "Confidence",
    values: getNonEmptyProperties(items, (plan) => plan.confidence),
    canReadDates: userAbilities.canReadDates,
  },
  {
    groupValuesLabel: "Planned Launch Date",
    propertyKey: "date",
    propertyLabel: "Planned Launch Date",
    values: getNonEmptyProperties(items, (plan) => plan.date),
    canReadDates: userAbilities.canReadDates,
  },
].filter(filterOption => filterOption.canReadDates !== false);

function getTableContentSelectorOptions(plan, userAbilities) {
  const unfilteredOptions = [
    { id: "region", label: "Region", visible: true, editable: false },
    { id: "regionLaunch", label: "Region Launch Date", visible: true, canReadDates: userAbilities.canReadDates },
    { id: "status", label: "Status", visible: true },
    { id: "confidence", label: "Confidence", visible: true, canReadDates: userAbilities.canReadDates },
    { id: "date", label: "Planned Launch Date", visible: true, editable: false, canReadDates: userAbilities.canReadDates },
  ];

  if (isServicePlanApplicable(plan)) {
    unfilteredOptions.push({ id: "rmsProjectedDate", label: "RMS Projected GA Date", visible: true, canReadDates: userAbilities.canReadDates });
  }

  addBlueprintTableContentSelectorOption(unfilteredOptions, userAbilities.canReadDates)
  const options = unfilteredOptions.filter(columnDefinition => columnDefinition.canReadDates !== false)

  return {
    title: "Select visible columns",
    options: [
      {
        label: "Columns",
        options,
      }
    ]
  };
}

function onBeforeUnstick(item) {
  item.preventDefault();
}

const getTableConfig = props => {
  const {
    plan,
    items,
    loading,
    columnDefinitions,
    header,
    filteringOptions,
    sortingColumn,
    sortingDescending,
    tableSelection,
    handleVisibleItemsChange,
    handleColumnWidthsChange,
    handleSortingChange,
    userAbilities
  } = props;

  return defaultTableConfig({
    features: "*",
    variant: "borderless",
    columnDefinitions,
    header,
    items,
    loading,
    onVisibleItemsChange: handleVisibleItemsChange,
    onColumnWidthsChange: handleColumnWidthsChange,
    onBeforeUnstick,
    onSortingChange: handleSortingChange,
    filteringOptions,
    sortableColumns: getSortableColumns(plan),
    tableSelection,
    contentSelectorOptions: getTableContentSelectorOptions(plan, userAbilities),
    sortingColumn,
    sortingDescending,
  });
};

export const SingleServiceNotCompletedTable: React.FunctionComponent<SingleServiceNotCompletedTableProps> = (props) => {
  const {
    regions,
    nameSortable,
    serviceName,
    items,
    loading = true,
    refreshItems,
    plan,
    userAbilities,
    onEnterEstimatedDateSubmit,
    onBusinessCaseOverrideSubmit,
  } = props;

  const [internalLoading, internalLoadingSet] = useState<boolean>(false);
  const [isLoading, isLoadingSet] = useState<boolean>(false);

  useEffect(() => {
    isLoadingSet(loading || internalLoading);
  }, [loading, internalLoading]);

  const [notCompletedSortingState, setNotCompletedSortingState] = useState({
    sortingColumn: "date",
    sortingDescending: false,
  });

  const handleNotCompletedSortingChange = useCallback(({ detail: { sortingColumn, sortingDescending } }) => {
    setNotCompletedSortingState({ sortingColumn, sortingDescending });
  }, []);

  const enterEstimatedDate_onSubmit = useCallback<EnterEstimatedDateSubmitDelegate>(async (plan, update): Promise<unknown> => {
    if (!onEnterEstimatedDateSubmit) {
      return;
    }
    internalLoadingSet(true)
    try {
      const result = await onEnterEstimatedDateSubmit(plan, update);
      internalLoadingSet(false);
      return result;
    } catch(e) {
      internalLoadingSet(false);
      return Promise.reject(e);
    }
  }, [
    onEnterEstimatedDateSubmit
  ]);

  const businessCaseOverride_onSubmit = useCallback<BusinessCaseOverrideSubmitDelegate>(async (plan, update): Promise<IGenericMutationResponseDto> => {
    if (!onBusinessCaseOverrideSubmit) {
      return { message: "cancel" };
    }
    internalLoadingSet(true)
    try {
      const result = await onBusinessCaseOverrideSubmit(plan, update);
      internalLoadingSet(false);
      return result;
    } catch(e) {
      internalLoadingSet(false);
      return Promise.reject(e);
    }
  }, [
    onBusinessCaseOverrideSubmit
  ]);

  const [selected, setSelected] = useState<IServicePlan[]>([]);
  const [columnWidths, setColumnWidths] = useState<ISingleServiceNotCompletedTableColumnWidth>({
    confidence: undefined,
    region: undefined,
    status: undefined,
    date: undefined,
    rmsProjectedDate: undefined,
  });
  const { belongsToInstance: selectedRegion } = selected[0] ?? "";

  const itemsWithRegion = attachRegionObject(items, regions);

  let actionButtons = (
    <DialogFooterButtonBar>
      <BusinessCaseOverride
        userAbilities={userAbilities}
        nameSortable={nameSortable}
        nameRip={serviceName}
        region={selectedRegion}
        selected={selected[0]}
        handleSubmitChange={refreshItems}
        onSubmit={businessCaseOverride_onSubmit}
      />
      <EnterEstimatedDate
        nameSortable={nameSortable}
        nameRip={serviceName}
        region={selectedRegion}
        // TODO: tangentl data types are inconsistent
        selected={selected[0] as IServiceInRegionHistory}
        handleSubmitChange={refreshItems}
        onSubmit={enterEstimatedDate_onSubmit}
        userAbilities={userAbilities}
      />
    </DialogFooterButtonBar>
  );

  const getExportDataBook = useCallback(() => {
    if (!userAbilities.canReadDates){
      return generateSingleServiceNotCompletedNoDatesWorkbook(itemsWithRegion)
    }
    else {
      return generateSingleServiceNotCompletedWorkbook(itemsWithRegion, isServicePlanApplicable(plan))
    }

  }, [itemsWithRegion, plan, userAbilities.canReadDates]);

  const header = RegionsTable.getTableHeader({
    primary: "Not Completed",
    secondary: isLoading && <LoadingIndicator />,
    actionButtons: <ButtonBar>
      {canSeeModifyPlanAction(userAbilities) && actionButtons}
      <ExportTableToCSVExcel
        generateDataBook={getExportDataBook}
        fileNamePrefix={"SingleServiceNotCompleted"}
        data-testid={SingleServiceNotCompletedTableTestId.exportTable}
      />
    </ButtonBar>
  });

  const handleColumnWidthsChange = ({ detail: { widths } }) => {
    // @ts-ignore
    const mappedWidths: ISingleServiceNotCompletedTableColumnWidth = remapColumnWidthsChange(fields, widths);
    setColumnWidths(mappedWidths);
  };

  const handleSelectionChange = useCallback(({ detail: { selectedItems } }) => {
    setSelected(selectedItems);
  }, []);

  useEffect(() => {
    // This handles the edge case that when an item is selected
    // upon reloading the changes, the selected state still holds
    // the older reference of the item
    const reconciled: IReconcileCollectionContentResult<IServicePlan> = reconcileCollectionContent<IServicePlan>(selected, itemsWithRegion, (sinr) => sinr.instance, _.isEqual );
    if (reconciled.hasChanges) {
      setSelected(reconciled.final);
    }
  }, [selected, itemsWithRegion]);

  const isRowDisabled = ({ region, regionLaunchDate, plan, disposition }) => {
    if (disposition === "ATO" || disposition === "Not Launching"){
      return !userAbilities.canWriteCategories ;
    } else {
      return isRmsManaged(region, regionLaunchDate, plan)
    }
  };

  const getToolTip = (disposition: Optional<ServiceInRegionDisposition>) => {
    switch (disposition) {
      case "ATO":
        return (
          <Tooltip text="Pending an authority to operate">
            <Icon size="normal" variant="normal" name="status-info" />
          </Tooltip>
        );
      case "Not Launching":
        return (
          <Tooltip text="This region has been approved for an override and is not launching">
            <Icon size="normal" variant="normal" name="status-info" />
          </Tooltip>
        );
      default:
        return undefined;
    }
  };

  const getRenderedDateText = (region, formattedDate, defaultTextForNoDate) => {
    if (isRowDisabled(region)) {
      return formattedDate ? `${formattedDate} (Managed by RMS)`: " (Managed by RMS)";
    } else if (formattedDate){
      return formattedDate;
    }

    return defaultTextForNoDate;
  };

  const getDateCell = (region: IServicePlan) => {
    const disposition: Optional<ServiceInRegionDisposition> = region?.disposition;
    const toolTip = getToolTip(disposition);

    let defaultTextForNoDate = "Need Date";
    if (plan === NON_GLOBALLY_EXPANDING){
      defaultTextForNoDate = "Not Launching";
    }

    if (disposition === "ATO" || disposition === "Not Launching") {
      return (
        <div className="awsui-util-t-l">
          {`${disposition} `}{toolTip}
        </div>
      );
    } else {
      const date: Optional<string>  = region?.date;
      const formattedDate = toShortDate(date);
      const renderedDateText = getRenderedDateText(region, formattedDate, defaultTextForNoDate);

      let style;
      const diff = moment().diff(date);
      if (diff > 0) {
        style = { color: awsui.colorTextStatusError };
      }

      return (
        <div className="awsui-util-t-l" style={style}>
          {renderedDateText}
        </div>
      );
    }
  };

  const tableSelection = {
    selectionType: "single",
    trackBy: "belongsToInstance",
    isItemDisabled: isRowDisabled,
    onSelectionChange: handleSelectionChange,
  };

  const unfilteredColumnDefinitions: ISecurityTableColumnDefinition<IServicePlan>[] = [
    {
      id: "region",
      header: "Region",
      cell: (item) => <Region region={item.regionObject} nameRip={serviceName} />,
      label: sortState => genAriaLabel(sortState, "Airport Code", "region"),
      width: columnWidths.region,
      minWidth: 142,
    },
    {
      id: "regionLaunch",
      header: "Region Launch Date",
      cell: (item) => <RegionLaunch region={item.regionObject} />,
      label: sortState => genAriaLabel(sortState, "Region Launch Date", "regionLaunch"),
      width: columnWidths.date,
      minWidth: 125,
      canReadDates: userAbilities.canReadDates
    },
    {
      id: "status",
      header: "RIP Build Status",
      cell: ({ status }) => (<div className="awsui-util-t-l">{status ?? DEFAULT_STATUS}</div>),
      label: sortState => genAriaLabel(sortState, "RIP Build Status", "status"),
      width: columnWidths.status,
      minWidth: 120
    },
    {
      id: "confidence",
      header: "Confidence",
      cell: (serviceInRegionPlan) => <PlanConfidence plan={serviceInRegionPlan} />,
      label: sortState => genAriaLabel(sortState, "Confidence", "confidence"),
      width: columnWidths.confidence,
      minWidth: 132,
      canReadDates: userAbilities.canReadDates
    },
    {
      id: "date",
      header: "Planned Launch Date",
      cell: getDateCell,
      label: sortState => genAriaLabel(sortState, "Planned Launch Date", "date"),
      width: columnWidths.date,
      minWidth: 125,
      canReadDates: userAbilities.canReadDates
    }
  ];

  if (isServicePlanApplicable(plan)) {
    unfilteredColumnDefinitions.push(
      {
        id: "rmsProjectedDate",
        header: <RmsProjectedDateColumnHeader />,
        cell: (row) => (
          <div className="awsui-util-t-l">
            {
              // @ts-ignore
              isRmsManaged(row.region, row.regionObject.date, plan) ? <ProjectedDate
                ripName={serviceName}
                regionName={row.region}
                projected={getRmsProjectedFinishDateFromServiceRegionItem(row)}
              /> : "-"
            }
          </div>
        ),
        label: sortState => genAriaLabel(sortState, "RMS Projected GA Date", "rmsProjectedDate"),
        width: columnWidths.rmsProjectedDate,
        minWidth: 125,
        canReadDates: userAbilities.canReadDates
      }
    );
  }

  addBlueprintColumnDefinition(unfilteredColumnDefinitions, userAbilities.canReadDates)

  const columnDefinitions = unfilteredColumnDefinitions.filter(columnDefinition => columnDefinition.canReadDates !== false)

  const filteringOptions = getFilteringOptions(itemsWithRegion, userAbilities);
  const { sortingDescending, sortingColumn } = notCompletedSortingState;
  const tableConfig = getTableConfig({
    plan,
    items: itemsWithRegion,
    loading,
    columnDefinitions,
    header,
    filteringOptions,
    tableSelection,
    handleColumnWidthsChange,
    handleSortingChange: handleNotCompletedSortingChange,
    sortingDescending,
    sortingColumn,
    userAbilities
  });

  return (
    <RegionsTable
      testId={SingleServiceNotCompletedTableTestId.table}
      {...tableConfig}
      TablePagination={{ pageSize: 5 }}
    />
  );
};

export default SingleServiceNotCompletedTable;
