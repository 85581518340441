import * as React from "react";
import { FunctionComponent, useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import { Tabs } from "@amzn/awsui-components-react";
import { IResourceParityViewModel, ResourceParityProps } from "./ResourceParity.types";
import { ResourceParitySettingVO } from "@amzn/api-parity-react-component/lib/models/vos/ResourceParitySettingVO";
import { StringParam, useQueryParam } from "use-query-params";
import { Nullable } from "../../../models/types/Nullable";
import { ResourceParity as ResourceParityComponent } from "@amzn/api-parity-react-component/lib/components/parity-grid/ResourceParity/ResourceParity";
import { FlushedContentTabs } from "../../common/StyledComponents/TabsNoPadding";
import { viewModelSelector } from "./ResourceParity.view.model.composer";

export const ResourceParityTestId = {
  component: "ResourceParity"
}

const Container = styled("div")`
  position: relative;
`;

const tabContentMaxHeight = "calc( 100vh - 300px )";

export const ResourceParity: FunctionComponent<ResourceParityProps> = (props) => {
  const [viewModel, setViewModel] = useState<IResourceParityViewModel>(viewModelSelector(props));

  const [serviceId, setServiceId] = useQueryParam<Nullable<string>>("service", StringParam);
  const initialSetting: ResourceParitySettingVO = new ResourceParitySettingVO();
  initialSetting.selection.serviceId = serviceId ?? undefined;
  const [setting, setSetting] = useState(initialSetting);

  useEffect(() => {
    setServiceId(setting.selection.serviceId)
  }, [
    setting,
    setServiceId,
  ]);

  const grid_onSettingChange = useCallback((newSetting) => {
    setSetting(newSetting);
  }, [setSetting])

  useEffect(() => {
    setViewModel(viewModelSelector(props))
  }, [
    props,
  ]);

  const parityGrid = <ResourceParityComponent
    constraintHeight={tabContentMaxHeight}
    services={viewModel.services}
    regions={viewModel.regions}
    resources={viewModel.resources}
    resourceParities={viewModel.resourceParities}
    propertyTypes={viewModel.propertyTypes}
    propertyParities={viewModel.propertyParities}
    buildPlans={viewModel.nonGaBuildPlans}
    setting={setting}
    onSettingChange={grid_onSettingChange}
  />;

  const tabs: Tabs.Tab[] = [
    {
      id: "parityGrid",
      label: "Resource Parity Grid",
      content: parityGrid,
    },
  ];

  return (
    <Container data-testid={ResourceParityTestId.component}>
      <FlushedContentTabs tabs={tabs} activeTabId={"parityGrid"} variant={"container"} />
    </Container>

  );
};
