import { Displayable } from "@amzn/api-parity-react-component/lib/models/Displayable";
import {
  Checkbox,
  DatePicker,
  Input,
  Select,
  Textarea,
  TokenGroup,
  RadioGroup,
  Autosuggest,
  TagEditor
} from "@amzn/awsui-components-react-v3";
import * as React from "react";
import { FormFieldTypes, IIndividualFormFieldWithControllerInput } from "./IndividualFormFieldWithController.types";
import { IndividualFormFieldWithControllerTestIdSuffix } from "./IndivudalFormFieldWithController";
import { composeDataTestId, getDataTestIdProp } from "../../utils/testUtil";
import {
  isDateWithinWeeksPrior,
  VALID_DATE_NOT_PAST_NOR_WEEKEND,
  VALID_DATE_NOT_PAST_TWO_WEEKS_NOR_WEEKEND,
} from "./FormConstants";
import { HelpPanelButton } from "../HelpPanel/HelpPanelButton/HelpPanelButton";

interface IFormFieldProps {
  props: IIndividualFormFieldWithControllerInput,
  value: any,
  onChange: any,
  onBlur?: any,
}

interface IFormTextFieldProps extends IFormFieldProps {
  invalid: any,
}

export const convertBooleanToRadioYesNo = (option?: boolean): string | undefined => {
  if (option || option === false) {
    return option === true ? "Yes" : "No";
  }
  return undefined;
}

export const convertYesNoRadioToBoolean = (option?: string): boolean | undefined => {
  if (option) {
    if (option === "Yes") return true
    else if (option === "No") return false
    else return undefined;
  }
  return undefined;
}

export const displayableInputEntryField = (args: IFormTextFieldProps): Displayable => {
  return (
    <Input
      {...getDataTestIdProp(args.props, composeDataTestId(args.props.formField.id, IndividualFormFieldWithControllerTestIdSuffix.input))}
      onChange={(e) => args.onChange(e.detail.value)}
      onBlur={args.onBlur}
      value={args.value}
      key={args.props.formField.id + "Input"}
      invalid={args.invalid}
      placeholder={args.props.formField.placeholder}
    />
  )
}

export const displayableDateEntryField = (args: IFormFieldProps): Displayable => {
  return (
    <DatePicker
      {...getDataTestIdProp(args.props, composeDataTestId(args.props.formField.id, IndividualFormFieldWithControllerTestIdSuffix.date))}
      onChange={(e) => args.onChange(e.detail.value)}
      onBlur={args.onBlur}
      value={args.value}
      isDateEnabled={
        date =>
          (!(args.props.formField.validation === VALID_DATE_NOT_PAST_NOR_WEEKEND) || isDateWithinWeeksPrior(date, 0)) &&
          (!(args.props.formField.validation === VALID_DATE_NOT_PAST_TWO_WEEKS_NOR_WEEKEND) || isDateWithinWeeksPrior(date, 2)) &&
          (date.getDay() !== 0 && date.getDay() !== 6)
      }
      key={args.props.formField.id + "Date"}
      placeholder="YYYY/MM/DD"
      nextMonthAriaLabel="Next month"
      previousMonthAriaLabel="Previous month"
      todayAriaLabel="Today"
    />
  )
}

export const displayableSelectEntryField = (args: IFormFieldProps): Displayable => {
  return (
    <Select
      {...getDataTestIdProp(args.props, composeDataTestId(args.props.formField.id, IndividualFormFieldWithControllerTestIdSuffix.select))}
      onChange={(e) => args.onChange(e.detail.selectedOption)}
      key={args.props.formField.id + "Select"}
      onBlur={args.onBlur}
      selectedOption={args.value}
      options={args.props.formField.options}
    />
  )
}

export const displayableAutoSuggestField = (args: IFormFieldProps): Displayable => {
  return (
    <Autosuggest
      {...getDataTestIdProp(args.props, composeDataTestId(args.props.formField.id, IndividualFormFieldWithControllerTestIdSuffix.select))}
      onChange={(e) => args.onChange(e.detail.value)}
      value={args.value ?? ""}
      virtualScroll={args.props.formField.options ? args.props.formField.options.length > 500 : false}
      options={args.props.formField.options}
      enteredTextLabel={value => `${value}`}
    />
  )
}

export const displayableTextAreaEntryField = (args: IFormTextFieldProps): Displayable => {
  return (
    <Textarea
      {...getDataTestIdProp(args.props, composeDataTestId(args.props.formField.id, IndividualFormFieldWithControllerTestIdSuffix.textArea))}
      onChange={(e) => args.onChange(e.detail.value)}
      key={args.props.formField.id + "TextArea"}
      onBlur={args.onBlur}
      value={args.value}
      invalid={args.invalid}
    />
  )
}

export const displayableTokenGroup = (args: IFormTextFieldProps): Displayable => {
  return (
    <TokenGroup
      {...getDataTestIdProp(args.props, composeDataTestId(args.props.formField.id, IndividualFormFieldWithControllerTestIdSuffix.tokenGroup))}
      onDismiss={(e) => args.onChange([
        ...args.value.slice(0, e.detail.itemIndex),
        ...args.value.slice(e.detail.itemIndex + 1)
      ])}
      key={args.props.formField.id + "TokenGroup"}
      items={args.value.map(item => ({ label: item }))}
    />
  )
}

export const displayableRadioEntryField = (args: IFormTextFieldProps): Displayable => {
  return (
    <RadioGroup
      {...getDataTestIdProp(args.props, composeDataTestId(args.props.formField.id, IndividualFormFieldWithControllerTestIdSuffix.radio))}
      onChange={(e) => args.onChange(e.detail.value)}
      key={args.props.formField.id + "RadioEntry"}
      value={args.value}
      items={[
        { value: "Yes", label: "Yes" },
        { value: "No", label: "No" },
      ]}
    />
  )
}

export const displayableCheckboxField = (args: IFormTextFieldProps): Displayable => {
  return (
    <Checkbox
      {...getDataTestIdProp(args.props, composeDataTestId(args.props.formField.id, IndividualFormFieldWithControllerTestIdSuffix.checkbox))}
      onChange={(e) => args.onChange(e.detail.checked)}
      key={args.props.formField.id + "Checkbox"}
      checked={args.value}
    >
      {args.props.formField.placeholder}
      {
        args.props.formField.helpId
        && <HelpPanelButton helpId={args.props.formField.helpId}/>
      }
    </Checkbox>
  )
}

export const displayableKeyValueField = (args: IFormTextFieldProps): Displayable => {
  return (
    <TagEditor
      {...getDataTestIdProp(args.props, composeDataTestId(args.props.formField.id, IndividualFormFieldWithControllerTestIdSuffix.checkbox))}
      i18nStrings={{
        keyPlaceholder: "Enter contact type",
        valuePlaceholder: "Enter contact alias",
        addButton: "+",
        removeButton: "Remove",
        awsPrefixError: "error",
        duplicateKeyError: "duplicated contact type",
        emptyKeyError: "must specify contact type",
        emptyTags: "",
        enteredKeyLabel: (text) => text,
        enteredValueLabel: (text) => text,
        invalidKeyError: "invalid contact type",
        invalidValueError: "invalid alias",
        keyHeader: "contact type",
        keySuggestion: "",
        keysSuggestionError: "",
        keysSuggestionLoading: "",
        loading: "",
        maxKeyCharLengthError: "max length for contact type exceeded",
        maxValueCharLengthError: "max length for contact type exceeded",
        optional: "",
        tagLimit: (availableTags: number) => "",
        tagLimitExceeded: (tagLimit: number) => "contact limit exceeded",
        tagLimitReached: (tagLimit: number) => "contact limit reached",
        tooManyKeysSuggestion: "too many contacts",
        tooManyValuesSuggestion: "too many aliases",
        undoButton: "undo",
        undoPrompt: "undo",
        valueHeader: "alias",
        valueSuggestion: "",
        valuesSuggestionError: "invalid alias",
        valuesSuggestionLoading: "loading alias",
      }}
      onChange={(e) => args.onChange(e.detail.tags)}
      key={args.props.formField.id + "KeyValues"}
      tagLimit={5}
      tags={args.value}
    />
  )
}

export const formFieldTypeMapper = {
  [FormFieldTypes.INPUT_ENTRY]: displayableInputEntryField,
  [FormFieldTypes.DATE_ENTRY]: displayableDateEntryField,
  [FormFieldTypes.TEXT_ENTRY]: displayableTextAreaEntryField,
  [FormFieldTypes.SELECT_ENTRY]: displayableSelectEntryField,
  [FormFieldTypes.TOKEN_ENTRY]: displayableTokenGroup,
  [FormFieldTypes.CHECKBOX_ENTRY]: displayableCheckboxField,
  [FormFieldTypes.RADIO_ENTRY]: displayableRadioEntryField,
  [FormFieldTypes.AUTOSUGGEST_ENTRY]: displayableAutoSuggestField,
  [FormFieldTypes.KEY_VALUE_ENTRY]: displayableKeyValueField,
}
