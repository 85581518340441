import * as React from "react";
import { FunctionComponent, useCallback } from "react";
import { SpaceBetween, ColumnLayout } from "@amzn/awsui-components-react-v3";
import { Optional } from "@amzn/react-bot";
import { formFieldSpacing } from "../../EditLaunchForm.style";
import { Displayable } from "@amzn/api-parity-react-component/lib/models/Displayable";
import { IFormFieldData } from "../../../../common/FormTypes";
import { getDataTestIdProp } from "../../../../../utils/testUtil";
import { editLaunchFormFields } from "../../EditLaunchFormFields";
import { IEditLaunchFormFieldsProps, IKeyValueFormInput, ISelectableFormInput, } from "../../EditLaunchForm.types";
import { IndividualFormFieldWithController } from "../../../../IndividualFormFieldWithController/IndivudalFormFieldWithController";
import { FormFieldTypes } from "../../../../IndividualFormFieldWithController/IndividualFormFieldWithController.types";

export const EditLaunchContactsFormFieldsTestIdSuffixes = {
  productManager: "-productManager",
  marketingLead: "-marketingLead",
  engineeringLead: "-engineeringLead",
  otherContacts: "-otherContacts",
};

export const EditLaunchContactsFormFields: FunctionComponent<IEditLaunchFormFieldsProps> = (props) => {

  const { control, errors } = props;

  // instantiates field in form and hooks it up to parent react-hook-form via control + errors
  const getFormField = useCallback((formField: IFormFieldData, fieldType: FormFieldTypes, defaultValue: Optional<string> | IKeyValueFormInput[] | string[] | ISelectableFormInput | boolean, info?: Displayable) => {
    return <IndividualFormFieldWithController control={control}
                                              errors={errors}
                                              formField={formField}
                                              defaultValue={defaultValue}
                                              popoverInfo={info}
                                              fieldType={fieldType}
                                              {...getDataTestIdProp(props, EditLaunchContactsFormFieldsTestIdSuffixes[formField.id])}
    />;
  }, [control, errors, props]);

  return (
    <SpaceBetween
      {...getDataTestIdProp(props)}
      direction="vertical"
      size={formFieldSpacing.section}>
      <SpaceBetween direction={"vertical"} size={formFieldSpacing.vertical}>
        <ColumnLayout columns={3}>
          {getFormField(editLaunchFormFields.productManager, FormFieldTypes.INPUT_ENTRY, control.getValues().productManager)}
          {getFormField(editLaunchFormFields.marketingLead, FormFieldTypes.INPUT_ENTRY, control.getValues().marketingLead)}
          {getFormField(editLaunchFormFields.engineeringLead, FormFieldTypes.INPUT_ENTRY, control.getValues().engineeringLead)}
        </ColumnLayout>
        {getFormField(editLaunchFormFields.otherContacts, FormFieldTypes.KEY_VALUE_ENTRY, control.getValues().otherContacts)}
      </SpaceBetween>
    </SpaceBetween>
  );
}
