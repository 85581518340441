import { ComponentFeatureVO } from "../models/vos/ComponentFeatureVO";
import { AwsServiceVO } from "@amzn/api-parity-react-component";
import { difference } from "lodash";
import { convertServicesToComponentFeatures } from "../parsers/componentFeatureParser";

const getSelectedDifference = (subtractFromComponents: ComponentFeatureVO[],
                               subtractFromServices: AwsServiceVO[],
                               componentsToSubtract: ComponentFeatureVO[],
                               servicesToSubtract: AwsServiceVO[],
                               serviceName: string) => {
  return difference(subtractFromComponents, componentsToSubtract).concat(
    convertServicesToComponentFeatures(difference(subtractFromServices, servicesToSubtract), serviceName)
  )
}

export const componentsToDelete = (currentlySelectedComponents: ComponentFeatureVO[],
                                   currentlySelectedServices: AwsServiceVO[],
                                   initiallySelectedComponents: ComponentFeatureVO[],
                                   initiallySelectedServices: AwsServiceVO[],
                                   serviceName: string): ComponentFeatureVO[] => {
  return getSelectedDifference(initiallySelectedComponents, initiallySelectedServices, currentlySelectedComponents, currentlySelectedServices, serviceName);
}

export const componentsToAdd = (currentlySelectedComponents: ComponentFeatureVO[],
                                currentlySelectedServices: AwsServiceVO[],
                                initiallySelectedComponents: ComponentFeatureVO[],
                                initiallySelectedServices: AwsServiceVO[],
                                serviceName: string): ComponentFeatureVO[] => {
  return getSelectedDifference(currentlySelectedComponents, currentlySelectedServices, initiallySelectedComponents, initiallySelectedServices, serviceName);
}
