import * as React from "react";
import { FunctionComponent, useEffect, useState } from "react";
import { useJsonLoader } from "../../../utils/containerUtil";
import { IReconServiceParityItemDto, IRegionMetadataDto } from "@amzn/api-parity-react-component/lib/models/dtos/recon";
import { IContextualSwrResponse } from "../../../models/types/IContextualSwrResponse";
import { Optional } from "../../../models/types/Optional";
import memoize from "micro-memoize";
import { DetailLoadingVO } from "../../../models/vos/DetailLoadingVO";
import { getSwrStatus } from "../../../utils/swrUtil";
import { DetailLoader } from "../../DetailLoader/DetailLoader";
import { AwsServiceVO } from "@amzn/api-parity-react-component";
import { loadStateToLoadingStatus } from "../../../utils/modelUtil";
import { ResourceParityLoaderProps } from "./ResourceParityLoader.types";
import { IResourceSpecificationByRegionDto } from "@amzn/api-parity-react-component/lib/models/dtos/cloudformation";
import { IResourceParityBootstrapInput } from "@amzn/api-parity-react-component/lib/models/ResourceParityBootstrapInput";
import { isFullyLoaded } from "../../../utils/loadingStatusUtil";


function combineDataImpl(
  ripIdMap: Optional<string>,
  reconRegions: Optional<IRegionMetadataDto[]>,
  reconServices: Optional<AwsServiceVO[]>,
  resourceByRegion: Optional<IResourceSpecificationByRegionDto[]>,
  serviceParities: Optional<IReconServiceParityItemDto[]>,
) {
  const data: Partial<IResourceParityBootstrapInput> = {
    ripIdMap,
    reconRegions,
    reconServices,
    resourceByRegion,
    serviceParities,
  }
  return data;
}

const combineData = memoize(combineDataImpl);

export const ResourceParityLoader: FunctionComponent<ResourceParityLoaderProps> = (props) => {
  const {
    serviceList,
    serviceListLoadState,
    onDataLoaded,
  } = props;

  // @ts-ignore type mismatch has to do with contact
  const reconParitySwr = useJsonLoader<IReconServiceParityItemDto[]>(() => props.getServiceParities(), "RECON Parity");
  const reconRegionListSwr = useJsonLoader<IRegionMetadataDto[]>(() => props.getRegions(), "RECON Regions");
  const resourceByRegionSwr = useJsonLoader<IResourceSpecificationByRegionDto[]>(() => props.getResourceParities(), "CloudFormation Resource Specifications");
  const ripIdMapApiSwr = useJsonLoader<string>(() => props.getRipIdMap(), "CloudFormation Service Map with RIP");

  const allLoaders: IContextualSwrResponse<any>[] = [
    reconParitySwr,
    reconRegionListSwr,
    resourceByRegionSwr,
    ripIdMapApiSwr,
  ];

  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const [data, setData] = useState<Optional<Partial<IResourceParityBootstrapInput>>>();

  useEffect(() => {
    const allDataLoaded: boolean = isFullyLoaded([
      reconParitySwr,
      reconRegionListSwr,
      resourceByRegionSwr,
      ripIdMapApiSwr,
    ], [serviceListLoadState]);

    setIsLoaded(allDataLoaded);

    if (allDataLoaded) {
      setData(combineData(
        ripIdMapApiSwr.response.data,
        reconRegionListSwr.response.data,
        serviceList,
        resourceByRegionSwr.response.data,
        reconParitySwr.response.data,
      ));
    } else {
      setData(undefined);
    }
  }, [
    reconParitySwr,
    reconRegionListSwr,
    resourceByRegionSwr,
    ripIdMapApiSwr,
    serviceListLoadState,
    serviceList,
  ]);

  useEffect(() => {
    if (data && onDataLoaded) {
      onDataLoaded(data as IResourceParityBootstrapInput);
    }
  }, [data, onDataLoaded]);

  let content: React.ReactElement;
  if (!isLoaded) {
    const loadingStatus: DetailLoadingVO[] = allLoaders.map((loader) => {
      return {
        label: loader.context,
        status: getSwrStatus(loader.response),
        error: loader.response.error?.message,
      };
    });

    loadingStatus.push({
      label: "All Services",
      status: loadStateToLoadingStatus(serviceListLoadState),
    });

    content = <DetailLoader items={loadingStatus} />;
  } else {
    content = <React.Fragment />
  }

  return content;
};
