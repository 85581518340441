import axios, { AxiosError, AxiosRequestConfig } from "axios";
import { TokenRetriever } from "@amzn/midway-identity-credential-provider/dist/token-retriever";
import { Optional } from "../models/types/Optional";

export const MIDWAY_TOKEN_EXPIRE_STATUS_CODE = "307";

function defaultSsoDelegate(): Promise<any> {
  // @ts-ignore
  return TokenRetriever.getTokenOrRedirect("aws")
}

/**
 * A class which provides an axios client which would retry request in the event calls through Midway failed due
 * to token expiration
 */
export class MidwayAxiosRetry {
  private readonly _retryingConfigs: Set<AxiosRequestConfig>;
  private _midwayDeduper: Optional<Promise<any>>;
  private readonly _ssoDelegate: () => Promise<any>;
  enableLogging = true;

  constructor(ssoDelegate?: () => Promise<any>) {
    this._retryingConfigs = new Set<AxiosRequestConfig>();
    // @ts-ignore
    this._ssoDelegate = ssoDelegate ?? defaultSsoDelegate;
  }

  errorHandler(err: AxiosError): Promise<any> {
    if (!this.shouldRetry(err)) {
      this.log("Give up retrying", err);
      this._retryingConfigs.delete(err.config);
      return Promise.reject(err);
    }

    this._retryingConfigs.add(err.config);
    if (!this._midwayDeduper) {
      this._midwayDeduper = this._ssoDelegate();
    }

    return this._midwayDeduper.then(() => {
      this.log("Retrying", err);
      this._retryingConfigs.delete(err.config);
      this._midwayDeduper = undefined;
      return axios.request(err.config);
    }).catch((midwayErr: any) => {
      this.log("Retry failed", err);
      this._retryingConfigs.delete(err.config);
      this._midwayDeduper = undefined;
      return Promise.reject(midwayErr);
    });
  }

  shouldRetry(err: AxiosError): boolean {
    if (this.hasErrorBeenRetried(err)) {
      this.log("Has been retried", err);
      return false;
    }

    if (this.isMidwayExpirationError(err)) {
      this.log("In Midway error", err);
      return true;
    }

    this.log(`isRequestingStaticFile ${this.isRequestingStaticFile( err.config.url ?? "" )}`, err);
    // Sometimes the error does not show up as 307, but instead error has no error code
    // with Network Error as message, and isAxiosError as true
    // instead of checking these peculiar conditions which are subject to change without notice
    // The code just check and see if the request is asking for static json file or txt file
    // return true if it is requesting a static file
    return !!err.config.url && this.isRequestingStaticFile( err.config.url );
  }

  log(message: string, relatedError: AxiosError): void {
    if (this.enableLogging) {
      console.log({
        message,
        url: relatedError.config.url,
        error: relatedError,
      });
    }
  }

  isRequestingStaticFile(url: string): boolean {
    const normalizedUrl = url.toLowerCase();
    return normalizedUrl.endsWith(".json") || normalizedUrl.endsWith(".txt");
  }

  hasErrorBeenRetried(err: AxiosError): boolean {
    return this.pendingRetries.has(err.config);
  }

  isMidwayExpirationError(err: AxiosError): boolean {
    return err.code?.toString() === MIDWAY_TOKEN_EXPIRE_STATUS_CODE;
  }

  /**
   * Expose the list of configs to be retried for unit testing purpose only
   */
  get pendingRetries(): ReadonlySet<AxiosRequestConfig> {
    return this._retryingConfigs;
  }

  get hasPendingSsoCall(): boolean {
    return !!this._midwayDeduper;
  }

}
