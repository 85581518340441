import {
  AwsServiceSdkApiListDto,
  IAwsServiceIdMapDto,
  IAwsServiceSdkApi
} from "@amzn/api-parity-react-component/lib/models/dtos/type";
import { AxiosInstance } from "axios";
import { getMidwayApiClient } from "./daoUtil";
import { IServiceNoDataReasonDto } from "@amzn/api-parity-react-component/lib/models/dtos/IServiceNoDataReason";
import { IAutoCreateServiceDto } from "@amzn/api-parity-react-component/lib/models/dtos/IAutoCreateServiceDto";

export class ApiParityDao {
  async getServiceIdMap(): Promise<IAwsServiceIdMapDto[]> {
    const client: AxiosInstance = getMidwayApiClient();
    const res = await client.get<IAwsServiceIdMapDto[]>("/static-reference-data/maps/serviceIdMap.json");
    return res.data;
  }

  async getServiceWithNoDataReason(): Promise<IServiceNoDataReasonDto[]> {
    const client: AxiosInstance = getMidwayApiClient();
    const res = await client.get<IServiceNoDataReasonDto[]>("/static-reference-data/maps/serviceNoData.json");
    return res.data;
  }

  async getAutoCreateService(): Promise<IAutoCreateServiceDto> {
    const client: AxiosInstance = getMidwayApiClient();
    const res = await client.get<IAutoCreateServiceDto>("/static-reference-data/maps/autoAddService.json");
    return res.data;
  }

  async getParityOverride(): Promise<string> {
    const client: AxiosInstance = getMidwayApiClient();
    const res = await client.get<string>("/static-reference-data/maps/apiParityOverride.csv");
    return res.data;
  }

  async getSdkApi(): Promise<IAwsServiceSdkApi[]> {
    const client: AxiosInstance = getMidwayApiClient();
    const res = await client.get<AwsServiceSdkApiListDto>("/static-reference-data/awssdk/apis.json");
    let apis: IAwsServiceSdkApi[] = [];
    res.data.forEach((rawApi) => {
      apis = apis.concat(rawApi.versions.map(av => av.api))
    });
    return apis;
  }
}
