import { Control, DeepMap, FieldError } from "react-hook-form";
import { AwsServiceVO } from "@amzn/api-parity-react-component";
import { IHasDataTestId } from "../../../models/IHasDataTestId";
import { RegionMetadataVO } from "../../../models/vos/RegionMetadataVO";
import { ILaunchDto } from "../../../daos/types/ILaunchDto";
import { ProductVO } from "../../../models/vos/ProductVO";
import { EventDateVO } from "../../../models/vos/EventDateVO";

export interface ILaunchWizardInput extends IHasDataTestId {
  product: ProductVO;
  services: AwsServiceVO[]
  regions: RegionMetadataVO[];
  onFormSubmit: (launch: Partial<ILaunchDto>) => Promise<ILaunchDto>;
  isLoading: boolean;
  blockedDates: EventDateVO[];
}

export interface ILaunchWizardEvent {
}

export type LaunchWizardProps = ILaunchWizardInput & ILaunchWizardEvent;

export interface ILaunchWizardDetailsInput extends IHasDataTestId {
  serviceName?: string;
  control: Control;
}

export interface ILaunchWizardRegionsInput extends IHasDataTestId {
  control: Control;
  regions: RegionMetadataVO[];
  selectedRegions?: string[];
}

export interface ILaunchWizardReviewInput extends IHasDataTestId {
  serviceName?: string;
  control: Control;
  regions: RegionMetadataVO[];
  setPage: any;
}

export interface ISelectOptions {
  label: string
  value: string
}

export interface ILaunchDetailFormValues {
  serviceName?: string,
  launchIteration?: ISelectOptions;
  launchType?: ISelectOptions;
  launchName?: string
  launchDescription?: string
  codeName?: string
  ripName?: string
  expectedLaunchDate?: string
  launchScope?: ISelectOptions
  tier?: ISelectOptions
  disclosureLevel?: ISelectOptions
  confidential?: ISelectOptions
  goalType?: ISelectOptions
  goalLink?: string
  audience?: ISelectOptions
  productManager?: string
  productMarketingManager?: string
  engineeringLead?: string
  prfaqBoolean?: ISelectOptions
  pricingBoolean?: ISelectOptions
  regulatoryBoolean?: ISelectOptions
  namingBoolean?: ISelectOptions
  services?: AwsServiceVO[]
}

export interface ILaunchFormValuesController extends IHasDataTestId {
  control: Control<ILaunchDetailFormValues>;
  errors: DeepMap<ILaunchDetailFormValues, FieldError>;
  blockedDates: EventDateVO[];
}

export type ILaunchWizardDetailsFormProps = ILaunchFormValuesController & ILaunchDetailFormValues

export enum LaunchScope {
  BETA = "Beta",
  PREVIEW = "Preview",
  PRE_ANNOUNCE = "Pre-announce",
  GA = "Generally Available",
}

export enum LaunchType {
  BUG_FIX = "Bug Fix",
  FEATURE = "Feature",
  INSTANCE = "Instance",
  PRFAQ = "PRFAQ",
  OPEN_SOURCE = "Open Source",
  REGION_OR_LOCAL_ZONE_OR_AVAILABILITY_ZONE = "Region/Local Zone/Availability Zone",
  SERVICE = "Service",
  STORAGE_CLASS = "Storage Class",
  VOLUME_TYPE = "Volume Type",
  OTHER = "Other",
}

export enum LaunchIteration {
  NEW = "New",
  REGION_EXPANSION = "Region Expansion"
}

export enum LaunchTier {
  TIER_1 = "Tier-1",
  TIER_2 = "Tier-2",
  TIER_3 = "Tier-3",
}

export enum LaunchDisclosure {
  NDA_ONLY_CUSTOMER = "NDA",
  PUBLIC_ROADMAP = "Public",
  CONFIDENTIAL = "Secret",
}

export enum LaunchGoalType {
  GM = "GM",
  VP = "VP",
  CHOP = "CHOP",
  S_TEAM = "S-Team",
  TEAM = "Team",
  OTHER = "Other",
}

export enum LaunchStatus {
  GREEN = "Green",
  YELLOW = "Yellow",
  RED = "Red",
}

export enum LaunchState {
  DRAFT = "Draft",
  IN_PROGRESS = "In Progress",
  SHIPPED = "Shipped",
  CANCELLED = "Cancelled",
  ON_HOLD = "On Hold",
  ARCHIVED = "Archived",
}

export enum LaunchAudience {
  INTERNAL = "Internal",
  EXTERNAL = "External",
}

export enum ReasonForChange {
  MISSING_SERVICE_DEPENDENCY = "Missing Service Dependency",
  MISSING_FEATURE_DEPENDENCY = "Missing Feature Dependency",
  LACK_OF_HARDARE_CAPACITY = "Lack of Hardware Capacity",
  LACK_OF_TEAM_RESOURCES = "Lack of Team Resources",
  BUILD_DELAYS = "Build Delays",
  REPRIORITIZED = "Reprioritized",
  LEADERSHIP_APPROVAL_DELAYS = "Leadership Approval Delays",
  ALIGNING_WITH_ANOTHER_LAUNCH_OR_EVENT = "Aligning with Another Launch/Event",
  OTHER = "OTHER",
}
