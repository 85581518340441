import React, { FunctionComponent } from "react";
import { CurrentUserProps } from "./CurrentUser.types";
import { getAppState } from "../../models/ApplicationState";

export const CurrentUser: FunctionComponent<CurrentUserProps> = (props) => {
  return (
    <React.Fragment>
      {getAppState().userAbilities.getAbilityDescription()}
    </React.Fragment>
  );
};
