export interface IRemoteOperationResult<T> {
  success: boolean;
  result?: T;
  error?: {
    httpStatusCode: number;
  }
}

export interface SuccessRemoteOperationResult<T> extends IRemoteOperationResult<T> {
  success: true;
  result: T;
  error: undefined;
}

export interface FailRemoteOperationResult<T> extends IRemoteOperationResult<T> {
  success: false;
  result: undefined;
  error: {
    httpStatusCode: number;
  };
}

export type RemoteOperationResult<T> = SuccessRemoteOperationResult<T> | FailRemoteOperationResult<T>


/**
 * Shorthand type
 */
export type AsyncResult<T> = Promise<RemoteOperationResult<T>>;

/**
 * Remote operation is simply a function which returns a promise of data of the expected type
 */
export type RemoteOperation<T> = () => AsyncResult<T>;

let operationId: number = 1;
export function getNextOperationId(): string {
  operationId++;
  return operationId.toString();
}


/**
 * QARemoteOperation is a simple model wrapper of RemoteOperation added with unique id
 * During test run, the remote operation result would be cached by the id
 * This way when multiple tests happen to invoke the same operation (identified by the id)
 * only one call is made, thus it saves time
 *
 * The design allows to remote operation to have different id's too, this gives flexibility
 * in case where are other context such as mutations where a different dataset should be inspected
 */
export class QaRemoteOperation<T> {
  public id: string = getNextOperationId();
  constructor(public operation: RemoteOperation<T>, id?: string) {
    if (id) {
      this.id = id;
    }
  }
}
