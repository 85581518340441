import { EventType, IEvent, IEventDateDto } from "../daos/types/IEventDateDto";
import { EventDateVO } from "../models/vos/EventDateVO";

export const defaultDate = "2099-01-01"
export const defaultEventType: EventType = "UNKNOWN"
export const defaultEventName = "Please check the AWS Change Control Policy Wiki"

export function eventDateDtosToVos(dto: IEventDateDto): EventDateVO[] {
  return Object.keys(dto).map((dateString) => {
    return {
      date: sanitizeDate(dateString),
      events: sanitizeEvents(dto[dateString]),
    }
  })
}

function sanitizeDate(date: string): string {
  const sanitizedDate = new Date(date);
  return isNaN(sanitizedDate.getDate()) ? defaultDate : sanitizedDate.toISOString().split("T")[0];
}

function sanitizeEvents(listOfEvents?: IEvent[]): IEvent[] {
  const defaultEvent: IEvent = {
    type: defaultEventType,
    name: defaultEventName,
  }

  if (listOfEvents && listOfEvents.length > 0) {
    return listOfEvents.map((dirtyEvent) => {
      return {
        type: dirtyEvent.type ?? defaultEventType,
        name: dirtyEvent.name ?? defaultEventName,
        details: dirtyEvent.details
      }
    })
  }

  return [defaultEvent];
}
