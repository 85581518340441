// A set of style aid for Polaris implementation

import styled from "styled-components";
import * as awsui from "@amzn/api-parity-react-component/lib/components/design-tokens"
import { Header } from "@amzn/awsui-components-react-v3";

/**
 * Polaris tab container imposes white space around the content, and provides
 * no variant to remove the whitespace.
 *
 * This style is to kill the whitespace until Polaris provides a way to do so
 *
 * @see https://polaris.a2z.com/components/awsui-tabs/?example=with-container
 */
export const FlushedTabContent = styled("div")`
  margin: -${awsui.Space.medium} -${awsui.Space.large} -${awsui.Space.large};
`;

export const CompactHeader = styled(Header)`
  padding: 0;
  margin-top: -1rem;
  margin-bottom: -1rem;
`;
