import * as React from "react";
import styled from "styled-components";
import { macScrollOverride } from "../../styles/macScrollOverride";
import { ParityTableStyleWrapperProps } from "./ParityTableStyleWrapper.types";

export const ParityTableStyleWrapperTestId = {
  component: "ParityTableStyleWrapper",
};

export interface IFreezeColumnResponsiveTableProp {
  // The selection column's width with unit
  selectionWidth: string;

  // non table content height with unit, this will maximize the table's height
  overheadHeight: string;

  // minimum height
  minHeight: string;
}

export const Container = styled.div<IFreezeColumnResponsiveTableProp>`
  .awsui-table-container {
    position: relative;
    z-index: 1;
    overflow: auto;
    height: max(${ (prop) => prop.minHeight }, calc(100vh - ${(prop) => prop.overheadHeight}));
    
    ${macScrollOverride};
    
    table[role="table"] {
      thead {
        th, td {
          position: sticky;
          top: 0;
        }
        
        .awsui-table-selection-area {
          position: sticky;
          left: 0;
          z-index: 5;
        }
        
        th {
          &:nth-child(2) {
            position: sticky;
            left: ${ (prop) => prop.selectionWidth };  // width of awsui-table-selection-area
            z-index: 5;
          }
        }
      }
      
      tbody {
        .awsui-table-row {
          .awsui-table-selection-area {
            position: sticky;
            left: 0;
            z-index: 2;
            background-color: #fff;
          }
          td {
            &:nth-child(2) {
              position: sticky;
              left: ${ (prop) => prop.selectionWidth };  // width of awsui-table-selection-area
              z-index: 2;
              background-color: #fff;
              border-right: 1px solid #eeeeee;
            }
          }
          
          // When table row is selected, use the blue background
          &.awsui-table-row-selected {
            .awsui-table-selection-area {
              background-color: var(--awsui-color-background-item-selected);
            }
            
            td {
              &:nth-child(2) {
                background-color: var(--awsui-color-background-item-selected);
              }
            }
          }
        }
      }
    }
  }
`

/**
 * Wrapper which provides style override to ParityListTable so it has the fixed column etc.
 * This component exists to prevent ParityListTable to unnecessary self redraw because it
 * triggers every cell in the table re-evaluated.  It is a performance bug that can be overcome
 * if we adopt view model approach that cell values would be bound to a pre-calculated values
 */
export const ParityTableStyleWrapper: React.FunctionComponent<ParityTableStyleWrapperProps> = (props) => {
  // spaceOverhead is all the space used by content before and after the parity table, such as nav header, banner, breadcrumb
  // currently the value is hardcoded.  It is not good, if the banner content has changed, it should impact the size overhead
  // TODO: Calculate space overhead dynamically or use better CSS layout so we don't need ot hardcode the values (RECON-4952)
  const topNavbarHeight = 50;
  const infoBannerHeight = 20; // The blue banner, this height may vary by screensize and text volume
  const headerZoneHeight = 85; // Breadcrumb area
  const tableHeaderHeight = 85; // Table filter + title, pagination
  const spaceReservedForFilter = 15; // In case user add filter to the table, the filter would take up some
  const totalTopSpace = topNavbarHeight + infoBannerHeight + headerZoneHeight + tableHeaderHeight + spaceReservedForFilter;

  const bottomSpace = 25;

  const overhead = props.spaceOverhead ?? { top: totalTopSpace, bottom: bottomSpace };
  const overheadSize: string = `${overhead.top + overhead.bottom}px`;
  return (
    <Container
      data-testid={ParityTableStyleWrapperTestId.component}
      selectionWidth={"54px"}
      overheadHeight={overheadSize}
      minHeight={"340px"}>
        {props.children}
    </Container>
  )
}
