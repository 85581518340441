import { IPivotTableData } from "./IPivotTableData";
import { IRegionMetadata } from "../../daos/types/IRegionMetaData";
import { IServiceMetaData } from "../../daos/types/IServiceMetaData";
import { IServiceContactsDto } from "../../daos/types/IServiceContactsDto";
import { IPivotPlansData } from "./IPivotPlansData"

export const transformPivot = ( regionsData: IRegionMetadata[] = [],
                                servicesData: IServiceMetaData[] = [],
                                plansData:IPivotPlansData[] = [],
                                contactsData: IServiceContactsDto[] = [],
                                historyData:IPivotPlansData[] = [] ): IPivotTableData [] => {
  const result: IPivotTableData [] = [];

  plansData.forEach(servicePlanObject => {
    const instance = servicePlanObject.instance.split(":");
    const service = instance[0];
    const recordRegion = instance[instance.length - 1];
    const recordDate = servicePlanObject.date.split("T")[0];

    const foundRegion = regionsData.find(item => item.airportCode === recordRegion);
    const recordRegionStatus = foundRegion?.status;
    const recordPartition = foundRegion?.partition;

    const foundPlan = servicesData.find(item => item.instance === service);
    const recordService = foundPlan?.nameLong;
    const recordPlan = foundPlan?.plan;
    const recordVisibility = foundPlan?.visibility;

    const foundContacts = contactsData.find(item => item.instance.split(":")[0] === service);
    const recordGM = foundContacts?.gm;
    const recordVP = foundContacts?.vp;

    const record: IPivotTableData = {
      service: recordService,
      category: recordPlan,
      region: recordRegion,
      regionStatus: recordRegionStatus,
      serviceStatus: servicePlanObject.status,
      visibility: recordVisibility,
      partition: recordPartition,
      serviceLaunchDate: recordDate,
      gm: recordGM,
      vp: recordVP,
      slips: -1,
      history: [],
    };

    if (recordService) {
      result.push(record);
    }

  });

  historyData.forEach(history => {
    const historyInstance = history.instance.split(":")
    const historyService = historyInstance[0]
    const historyRegion = historyInstance[historyInstance.length - 1]
    const foundRecord = result.find(item => item.service === historyService && item.region === historyRegion);
    if(foundRecord){
      foundRecord.history.push(history.date.split("T")[0] + ", ")
      if(foundRecord.slips !== undefined){
        foundRecord.slips += 1
      }
    }
  });

  result.forEach(record => {
    if (record.slips === -1) {
      record.slips = undefined
    }
  });

  return result;
}
