import { ParityRollupStatistics } from "../models/ParityRollupStatistics";

/**
 * Combine statistics in "from" into "to", this means "to" would be mutated
 * @param from
 * @param to
 */
export function accumulateStatistics(from: ParityRollupStatistics, to: ParityRollupStatistics): void {
  to.ga += from.ga;
  to.notLaunching += from.notLaunching;
  to.ato += from.ato;
  to.expectedTotal += from.expectedTotal;
  to.rawTotal += from.rawTotal;
  to.plannedLaunch += from.plannedLaunch;
  to.notAvailable += from.notAvailable;
}
