import React, { useState } from "react";
import ParityListTable from "../../components/Parity/ParityListTable";
import { transformParityData, serviceInRegionInParity } from "../../utils/parityUtils";
import useBreadcrumbs from "../../components/Breadcrumbs/useBreadcrumbs";
import useSWR from "swr";
import { getSwrConfig } from "../../daos/swrUtil";
import { ParityDao } from "../../daos/ParityDao";
import { RegionDao } from "../../daos/RegionDao";
import { getAppState } from "../../models/ApplicationState";
import { Flash } from "@amzn/awsui-components-react";
import { NoAccessError } from "../../components/common/NoAccessError"
import { ServiceByRegionReportDao } from "../../daos/ServiceByRegionReportDao";

const serviceByReportDao = new ServiceByRegionReportDao();
const downloadServiceByReportDelegate = serviceByReportDao.getServiceByRegionReport.bind(serviceByReportDao);

export function filterFullParity(items, filteredRegions) {
  return items.filter(item => {
    let notFullParity = false;
    filteredRegions.forEach(region => {
      const lowerAirportCode = region.airportCode.toLowerCase();
      const confidence = item[lowerAirportCode]?.confidence;
      const status = item[lowerAirportCode]?.status;
      const exceptionStatus = item[lowerAirportCode]?.exceptionStatus;
      if (!serviceInRegionInParity(confidence, status, exceptionStatus)) {
        notFullParity = true;
      }
    })
    return notFullParity;
  })
}

export const ParityContainer = () => {
  return getAppState().userAbilities.canRead ? <AccessibleParityContainer/> : <NoAccessError/>;
}

const AccessibleParityContainer = () => {
  useBreadcrumbs([ "Parity" ]);

  const [hideFullParityToggle, hideFullParityToggleSet] = useState(true)

  const { data: parityData = [], isValidating: parityLoading = true, error: parityError } = useSWR("parity", () => {
    const dao = new ParityDao();
    // return dao.getParityCache(); TODO - addilks uncomment this once we have the ability to filter dates from encoded data (Sev2 - RECON-10705)
    return dao.getParity();
  }, getSwrConfig());

  const { data: regions = [], isValidating: regionsLoading = true, error: regionsError } = useSWR("regions", () => {
    const dao = new RegionDao();
    return dao.getRegions();
  }, getSwrConfig());

  const hasError = parityError || regionsError

  if (hasError) {
    return (
      <Flash
        type="error"
        header="Data Fetching Error"
        content="We are having an issue getting this data right now,
            try refreshing or please reach out to us if you continue to have issues"
      />
    );
  }

  const items = transformParityData(parityData);
  const filteredRegions = regions.filter(region => region?.status !== "BUILD" 
    && region?.status !== "PLANNED");
  const itemsWithoutFullParityServices = filterFullParity(items, filteredRegions);

  function tableOnHideFullParityToggle(event) {
    const toggled = event.detail.checked;
    hideFullParityToggleSet(toggled)
  }

  return (
    <>
      <ParityListTable
        onExport={downloadServiceByReportDelegate}
        canExport={getAppState().userAbilities.canReadDates}
        items={hideFullParityToggle ? itemsWithoutFullParityServices : items}
        regions={filteredRegions}
        loading={parityLoading || regionsLoading}
        onHideFullParityToggle={tableOnHideFullParityToggle}
        hideFullParityToggle={hideFullParityToggle}
      />
    </>
  );

};

export default ParityContainer;
