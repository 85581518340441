import { genAriaLabel, sortDates } from "../../utils/tableUtils";
import * as React from "react";
import { IServiceRegionPlanDto } from "../../daos/types/IServiceRegionPlanDto";
import { Optional } from "../../models/types/Optional";
import { AttentionTip } from "@amzn/api-parity-react-component/lib/components/status-grid/attention-tip/AttentionTip";
import { Icon } from "@amzn/awsui-components-react-v3";
import styled from "styled-components";
import { getRmsProjectedFinishDateFromServiceRegionItem } from "../../utils/rmsProjectedDateUtil";
import { COMPARABLE_DATE_IGNORE_TIME_FORMAT } from "../../utils/dateUtil";
import dayjs from "dayjs";
import dayjsBusinessDays from "dayjs-business-days";
import {
  globallyExpandingLaunchBlocking,
  globallyExpandingMandatory
} from "@amzn/api-parity-react-component/lib/models/vos/AwsServiceExpansionPlan";

dayjs.extend(dayjsBusinessDays);

export const BLUEPRINT_COLUMN_HEADER_LABEL: string = "Blueprint Projected GA Date"
export const BLUEPRINT_COLUMN_ID = "blueprintDate"
export const RMS_MANAGED_GLOBALLY_EXPANDING_MANDATORY_THRESHOLD = 90;

export const IconHolder = styled("span")`
  margin-left: 4px;
  display: inline-block;
`

const DateHolder = styled("span")`
  width: 80px;
  display: inline-block;
`

export const differentBlueprintDateTip = (
  <AttentionTip
    tooltip={"Different from Recon's date"}
    icon={<Icon variant={"subtle"} name={"status-warning"}/>}
  />
);

export const getBlueprintDate = (plan: IServiceRegionPlanDto): Optional<string> => plan.blueprintEstLaunchDate;

export const hasDifferentBlueprintDate = (plan: IServiceRegionPlanDto) => getBlueprintDate(plan) && getBlueprintDate(plan) !== plan.date;

export function sortBlueprintDate(plan: IServiceRegionPlanDto, secondPlan: IServiceRegionPlanDto): number {
  const dateOne = getBlueprintDate(plan);
  const dateTwo = getBlueprintDate(secondPlan);
  // @ts-ignore
  return sortDates(dateOne, dateTwo);
}

export function sortRmsProjectedFinishedDate(plan: IServiceRegionPlanDto, secondPlan: IServiceRegionPlanDto) {
  const dateOne = getRmsProjectedFinishDateFromServiceRegionItem(plan);
  const dateTwo = getRmsProjectedFinishDateFromServiceRegionItem(secondPlan);
  // @ts-ignore
  return sortDates(dateOne, dateTwo);
}

export const addBlueprintSortableColumn = (sortableColumns: Array<any>) => {
  sortableColumns.push({ id: BLUEPRINT_COLUMN_ID, field: BLUEPRINT_COLUMN_ID, comparator: sortBlueprintDate });
}

export const addBlueprintTableContentSelectorOption = (tableContentSelectorOptions: Array<any>, canReadDates: boolean) => {
  tableContentSelectorOptions.push({ id: BLUEPRINT_COLUMN_ID, label: BLUEPRINT_COLUMN_HEADER_LABEL, visible: true, canReadDates });
}

export const addBlueprintColumnDefinition = (columnDefinitions: Array<any>, canReadDates: boolean) => {
  columnDefinitions.push(
    {
      id: BLUEPRINT_COLUMN_ID,
      header: BLUEPRINT_COLUMN_HEADER_LABEL,
      cell: (plan: IServiceRegionPlanDto) => (
        <>
          <DateHolder>{getBlueprintDate(plan) ?? "No Date"}</DateHolder>
          {hasDifferentBlueprintDate(plan) && <IconHolder>{differentBlueprintDateTip}</IconHolder>}
        </>
      ),
      label: sortState => genAriaLabel(sortState, BLUEPRINT_COLUMN_HEADER_LABEL, BLUEPRINT_COLUMN_ID),
      canReadDates
    }
  );
}

export const isRmsManaged = (region: string, regionLaunchDate: string, serviceCategory: Optional<string>): boolean => {
  const regionLaunchDateDayJs =  dayjs(regionLaunchDate)
  const currentDate = dayjs().format(COMPARABLE_DATE_IGNORE_TIME_FORMAT);

  if (serviceCategory === globallyExpandingLaunchBlocking.recon) {
    return currentDate <= regionLaunchDateDayJs.format(COMPARABLE_DATE_IGNORE_TIME_FORMAT);
  } else if (serviceCategory === globallyExpandingMandatory.recon) {
    // @ts-ignore - TS sometimes has issues with dayjs
    const rmsManagedMandatoryThreshold = regionLaunchDateDayJs.businessDaysAdd(RMS_MANAGED_GLOBALLY_EXPANDING_MANDATORY_THRESHOLD).format(COMPARABLE_DATE_IGNORE_TIME_FORMAT);
    return currentDate <= rmsManagedMandatoryThreshold;
  } else {
    return false;
  }
}
