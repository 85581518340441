import * as React from "react";
import { useEffect, useState } from "react";
import {
  Alert,
  Icon,
  Form,
  FormSection,
  FormField,
  ColumnLayout
} from "@amzn/awsui-components-react";

import { Button, Link, SpaceBetween } from "@amzn/awsui-components-react-v3";
import ReportSelect from "../common/StyledComponents/ReportSelect";
import { RECON_URL_CREATE_ISSUE } from "../../constants";
import useSWR from "swr";
import { getSwrConfig } from "../../daos/swrUtil";
import {
  GEParityTables, headerText, subText, formFieldLabel,
  ServiceAvailabilityReportTestId, reportSelectOptions, errorHeaderText
} from "./ServiceAvailabilityReportHelpers";

const ServiceAvailabilityReport = (props) => {
  const [selectedOption, setSelectedOption ] = useState(GEParityTables);
  const [templateType, setTemplateType ] = useState(GEParityTables["templateType"]);
  const [shouldFetch, setShouldFetch ] = useState(0);
  const [fetchError, setFetchError] = useState(false);
  const [selectValidation, setSelectValidation] = useState("");

  const { isValidating: loading = true, error } = useSWR( shouldFetch ? shouldFetch.toString() : null, async () => {
    return props.onReport(templateType, selectedOption)
  }, getSwrConfig());

  useEffect(() => {
    if (error) {
      setFetchError(error);
    }
  }, [error]);

  const handleSelectionChange = ({ detail: { selectedOption } }) => {
    setShouldFetch(0);
    const invalidMessage = "A report must be selected.";
    if (selectedOption) {
      setSelectedOption(selectedOption);
      setTemplateType(selectedOption["templateType"]);
    } else {
      setSelectValidation(invalidMessage);
    }
  };

  const handleClick = async () => {
    setFetchError(false);
    setShouldFetch(Date.now());
  };

  const errorContentText = (
    <>
      Please{" "}
      <Link
        href={RECON_URL_CREATE_ISSUE}
        external={true}
        color={"inverted"}
      >
        let us know <Icon variant="link" name="external" />
      </Link>{" "}
      if this problem persists.
    </>
  );

  return (
    <>
      { fetchError &&
        <Alert
          type="error"
          header={errorHeaderText}
          content={errorContentText}
          data-testid={ServiceAvailabilityReportTestId.error}
        />
      }
      <Form>
        <FormSection header={headerText} description={subText}>
          <ColumnLayout>
            <SpaceBetween size={"xl"} direction={"vertical"}>
              <FormField
                label={formFieldLabel}
                errorText={selectValidation}
                data-testid={ServiceAvailabilityReportTestId.formField}
              >
                <ReportSelect
                  selectedLabel="Selected"
                  options={reportSelectOptions}
                  selectedOption={selectedOption}
                  onChange={handleSelectionChange}
                  data-testid={ServiceAvailabilityReportTestId.typeSelect}
                />
              </FormField>
              <Button
                //@ts-ignore
                label="Generate and Download Report"
                icon="download"
                iconAlign="right"
                variant="primary"
                data-testid={ServiceAvailabilityReportTestId.button}
                loading={loading}
                onClick={handleClick}
              >
                Generate and Download Report
              </Button>
            </SpaceBetween>
          </ColumnLayout>
        </FormSection>
      </Form>
    </>
  );
};

export default ServiceAvailabilityReport;
