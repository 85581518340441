import React, { FunctionComponent, useCallback, useEffect, useMemo, useState } from "react";
import { Tabs, TabsProps } from "@amzn/awsui-components-react-v3";
import { ProductDetailsProps } from "./ProductDetails.types";
import { ProductProperties } from "./ProductProperties/ProductProperties";
import { ProductChildProducts } from "./Tabs/ProductChildProducts/ProductChildProducts";
import { NonCancelableCustomEvent } from "@amzn/awsui-components-react-v3/polaris/internal/events";
import { getDataTestIdProp } from "../../utils/testUtil";

export const ProductDetailsTestIdSuffixes = {
  tabs: "-tabs"
}

export const ProductDetails: FunctionComponent<ProductDetailsProps> = (props) => {

  const {
    product,
    disabled,
    childProducts,
    loading,
    onTabChange,
    childProductProps
  } = props;

  const tabs = useMemo(() => [
    /*{
      label: "RIP Services & Components",
      id: "services",
      content: <ProductServices
        services={services}
        disabled={disabled}
        loading={loading}
      />
    },
    {
      label: "RIP Features",
      id: "features",
      content: <ProductFeatures
        features={features}
        disabled={disabled}
        loading={loading}
      />,
    },*/
    {
      label: "Child products",
      id: "children",
      content: <ProductChildProducts
        childProducts={childProducts}
        disabled={disabled}
        loading={loading}
        {...childProductProps}
      />,
    }
  ], [childProductProps, childProducts, disabled, loading]);

  const [selectedTab, setSelectedTab] = useState(
    tabs.find(tab => tab.id === props.selectedTab) ? props.selectedTab : tabs[0].id
  )

  useEffect(() => {
    setSelectedTab(tabs.find(tab => tab.id === props.selectedTab) ? props.selectedTab : tabs[0].id);
  }, [props.selectedTab, tabs]);

  const tabs_onChange = useCallback((evt: NonCancelableCustomEvent<TabsProps.ChangeDetail>) => {
    const tab = evt.detail.activeTabId;
    setSelectedTab(tab);
    onTabChange?.(evt);
  }, [onTabChange]);

  return (
    <>
      <ProductProperties
        product={product}
        disabled={disabled}
        loading={loading}
      />
      <Tabs
        tabs={tabs}
        activeTabId={selectedTab}
        onChange={tabs_onChange}
        {...getDataTestIdProp(props, ProductDetailsTestIdSuffixes.tabs)}
      />
    </>
  );
};
