import React from "react";
import { useLocation, useParams } from "react-router-dom";
import SingleRegionTabs from "../../components/SingleRegion/SingleRegionTabs";
import { SingleRegionDetails } from "../../components/SingleRegion/SingleRegionDetails"
import { SinglePageHeader } from "../../components/common/SinglePageHeader";
import { RMS_URL_DIMENSIONS } from "../../constants";
import useBreadcrumbs from "../../components/Breadcrumbs/useBreadcrumbs";
import useSWR from "swr";
import { getSwrConfig } from "../../daos/swrUtil";
import { RegionDao } from "../../daos/RegionDao";
import { NoAccessError } from "../../components/common/NoAccessError";
import { getAppState } from "../../models/ApplicationState";
import { Loading } from "../../components/common/Loading";
import { Flash } from "@amzn/awsui-components-react";

const SingleRegionContainer = () => {
  const { regionName } = useParams();
  const userAbilities = getAppState().userAbilities;
  const location = useLocation();
  const { pathname } = location;
  const { data: region = {}, isValidating: loading, error } = useSWR("region", () => {
    const dao = new RegionDao();
    return dao.getSingleRegionPlans(regionName);
  }, getSwrConfig());

  const { regionMetadata, serviceInstances: services = [] } = region ?? {};
  useBreadcrumbs([ "Regions=/regions", regionName ]);

  const { partition, description, nameLong, status, date } = regionMetadata ?? {
    partition: "",
    description: "",
    nameLong: "",
    status: "",
    date: ""
  };

  if (loading) {
    return <Loading/>;
  }
  if (userAbilities.canRead) {
    if (error) {
      return (
        <Flash
          type="error"
          header="Data Fetching Error"
          content="We are having an issue getting this data right now,
          try refreshing or please reach out to us if you continue to have issues"
        />
      );
    }
    const details = { partition, description, nameLong, status, date };
    return (
      <>
        <SinglePageHeader
          headerText={`${regionName} Region`}
          manageButtonText="View in RMS"
          href={`${RMS_URL_DIMENSIONS}${pathname}`}
        />
        <SingleRegionDetails
          details={details}
        />
        <SingleRegionTabs
          regionName={regionName}
          loading={loading}
          items={services}
          userAbilities={userAbilities}
        />
      </>
    );
  }
  return <NoAccessError/>
};

export default SingleRegionContainer;
