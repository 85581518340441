import { ENSURE_SERVICE_LIST, LOAD_SERVICE_LIST, LOAD_SERVICE_LIST_SUCCESS, ServiceActionTypes } from "./serviceAction";
import { AwsServiceVO } from "@amzn/api-parity-react-component";

/**
 * Return an action ensuring a service list is loaded or being loaded
 * this can prevent service list from unnecessarily reloaded
 */
export function ensureServiceList(): ServiceActionTypes {
  return {
    type: ENSURE_SERVICE_LIST
  };
}

export function loadServiceList(): ServiceActionTypes {
  return {
    type: LOAD_SERVICE_LIST
  };
}

export function loadServiceListSuccess(services: AwsServiceVO[]): ServiceActionTypes {
  return {
    type: LOAD_SERVICE_LIST_SUCCESS,
    payload: {
      services,
    }
  };
}
