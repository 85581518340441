/**
 * Comparing two collections and ensure their contents are identical
 * The function assumes both array's contents are unique
 * @param a
 * @param b
 */
import { Nullable } from "../models/types/Nullable";
import { Optional } from "../models/types/Optional";

export function areCollectionContentIdentical<T>(a: T[], b: T[]): boolean {
  if (a.length !== b.length) {
    return false;
  }
  const setA: Set<T> = new Set(a);
  return b.every((x) => setA.has(x));
}



export function partition<T>(items: T[], predicate: (item: T) => boolean): [T[], T[]] {
  const trueItems: T[] = [];
  const falseItems: T[] = [];
  items.forEach((item: T) => {
    if (predicate(item)) {
      trueItems.push(item);
    } else {
      falseItems.push(item);
    }
  });

  return [trueItems, falseItems];
}

export function getNonEmptyProperties<T, PT = string>(list: T[], getProperty: (item: T) => Nullable<PT>): PT[] {
  const result: PT[] = [];
  list.forEach((item) => {
    const property: Nullable<PT> = getProperty(item);
    if (property) {
      result.push(property);
    }
  });
  return result;
}


export interface IReconcileCollectionContentResult<T> {
  hasChanges: boolean;
  final: T[];
  removed: T[];
}

/**
 * Go through all the contents in the target collection
 * And ensure all of contents in target collection are the same from sourceContent collect
 * If not, attempt to pick from sourceContent by matching the item with key
 *
 * @param target
 * @param sourceContent
 * @param keyLookup
 */
export function reconcileCollectionContent<T>(target: T[], sourceContent: T[], keyLookup: (item: T) => string, compareFn: (a: T, b: T) => boolean = (a, b) => a === b): IReconcileCollectionContentResult<T> {
  let hasChanges = false;
  let final: T[] = [];
  const removed: T[] = [];

  for (let item of target) {
    if (sourceContent.findIndex((srcItem) => compareFn(srcItem, item)) >= 0) {
      final.push(item);
    } else {
      // cannot find the item from sourceContent
      hasChanges = true;
      const key: string = keyLookup(item);
      const sourceItem: Optional<T> = sourceContent.find((srcItem) => keyLookup(srcItem) === key);
      if (typeof(sourceItem) !== "undefined") {
        final.push(sourceItem);
      } else {
        removed.push(item);
      }
    }
  }

  if (!hasChanges) {
    final = target;
  }

  return {
    hasChanges,
    final,
    removed,
  };
}


export function getUnique<T>(values: T[]): T[] {
  const result: T[] = Array.from<T>(new Set(values));

  if (result.length === values.length) {
    // result and values are identical so there is no reason
    // to send a new copy of the array but to return the original
    return values;
  }

  return result;
}


/**
 * Go through the list, if any item in the list match with the supplied item
 * and the newItem is different, a new list with the updated item would be returned
 * if there is no match, a new list with the item appended would be returned
 *
 * Otherwise return the original list
 * @param newItem
 * @param list
 * @param matchFn
 */
export function updateItemInList<T>(newItem: T, list: T[], matchFn: (a: T, b: T) => boolean): T[] {
  const matchItemIndex: number = list.findIndex((item) => matchFn(newItem, item));
  const noUpdateNeeded: boolean = (matchItemIndex >= 0 && newItem === list[matchItemIndex]);
  if (noUpdateNeeded) {
    return list;
  }

  const newList: T[] = list.concat();
  if (matchItemIndex >= 0) {
    newList[matchItemIndex] = newItem;
  } else {
    newList.push(newItem);
  }

  return newList;
}
