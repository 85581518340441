import { CtiVO } from "../models/vos/CtiVO";
import { IServiceContactsDto, IOrderLxsDto } from "../daos/types/IServiceContactsDto"
import { ContactsType, ServiceContactsVO, LxsVO } from "../models/vos/ServiceContactsVO";
import { getRipName } from "../utils/serviceMetadataUtil";
import { sort } from "@amzn/api-parity-react-component/lib/utils/sortUtil";
import { ISortComparator, SortByDirection } from "@amzn/api-parity-react-component/lib/models/SortBy"
import { AwsServiceVO } from "@amzn/api-parity-react-component";
import { getPartitionFromInstance } from "../utils/serviceContactUtil";
import { fromInstanceText } from "./instanceParser";

// how many levels at the end of the chain to remove (this removes the CEO, CEO's report and CEO's report's report)
const LAST_LEADERS_TO_REMOVE = 3

export function orderLxsToOrderedArray(lxs: IOrderLxsDto[]): LxsVO[] {
   const byOrder: ISortComparator<IOrderLxsDto> = {
     direction: SortByDirection.Ascending,
     valueExtractFn: (x) => x.order
   };
   const orderedDtos: IOrderLxsDto[] = sort( lxs, [byOrder] );
   return orderedDtos.map((dto) => {
      let vo: LxsVO = new LxsVO();
      vo.alias = dto.alias
      vo.level = dto.level
      return vo;
   });
 }

export function parseServiceContactsDto (dto: IServiceContactsDto): ServiceContactsVO {
  const vo: ServiceContactsVO = new ServiceContactsVO();

  vo.artifact = dto.artifact;
  vo.instance = fromInstanceText(dto.instance).names[0];
  vo.uid = fromInstanceText(dto.instance).names[0] + ":" + getPartitionFromInstance(dto.instance);
  vo.partition = getPartitionFromInstance(dto.instance);
  vo.contactsType = dto.contactsType;
  vo.primaryBuildPoc = dto.primaryBuildPocMap?.alias;
  vo.rbtServiceManager = dto.rbtServiceManagerMap?.alias;
  vo.secondaryBuildPoc = dto.secondaryBuildPocMap?.alias;
  vo.buildTask = dto.buildTask;
  vo.simGuid = dto.simGuid;
  vo.notifications = dto.notifications;
  vo.azServiceOwner = dto.azServiceOwnerMap?.alias;
  vo.localZoneServiceOwner = dto.localZoneServiceOwnerMap?.alias;
  vo.vpceBuildOwner = dto.vpceBuildOwnerMap?.alias;
  vo.gm = dto.gm;
  vo.vp = dto.vp;
  vo.leadershipChain = dto.leadershipChain ? orderLxsToOrderedArray(dto.leadershipChain) : undefined;

  vo.leadershipChain = vo.leadershipChain?.slice(0, -LAST_LEADERS_TO_REMOVE)

  vo.serviceName = dto.contactsType === ContactsType.Rip ? getRipName(dto.instance) : dto.buildTask as string;
  vo.ripContacts = dto.ripContacts ?? [];

  if (dto.cti) {
    vo.cti = new CtiVO();
    vo.cti.category = dto.cti.c
    vo.cti.type = dto.cti.t
    vo.cti.item = dto.cti.i
  }
  else {
    vo.cti = undefined
  }

  vo.dto = dto;
  vo.wikiUrl = dto.wikiUrl;
  vo.oncallTeam = dto.oncallTeam;
  vo.inRmsGraph = dto.inRmsGraph;
  vo.audit = dto.audit;
  vo.opsDashboard = dto.opsDashboard;

  return vo;
}

export function parseAllContactsDto (dtos: IServiceContactsDto[]): ServiceContactsVO[] {
  return dtos.map(dto => parseServiceContactsDto(dto));
}

export function addNameVariationsToContacts(vos: ServiceContactsVO[], dtos: AwsServiceVO[]): void {
  vos.forEach((vo) => {
    if (vo.contactsType === ContactsType.Rip) {
      const service = dtos.find((dto) => dto.id === vo.serviceName);
      if (service) {
        vo.nameLong = service.nameVariations?.long ?? undefined;
        vo.nameSortable = service.nameVariations?.sortable ?? undefined;
      }
    }
  })
}
