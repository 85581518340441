import { IServicePlan } from "../../models/types/IServicePlan";
import { ExportDataBook, ExportDataCell } from "@amzn/excelerator";
import { dataToDataBook } from "../ExportTableToCSVExcel/ExportTableToCSVExcel";
import {
  getCategoryFromPlanForDisplay,
  getConfidenceFromServicePlan,
  getPlannedDateFromServicePlan,
  getStatusFromServicePlan
} from "../../utils/planUtil";
import { getRMSDateForExport } from "../SingleService/SingleServiceNotCompletedTableCSVExport";
import { getRmsProjectedFinishDateFromRegionDetail, isRegionApplicable } from "../../utils/rmsProjectedDateUtil";
import { getServiceDisplayName } from "../../utils/serviceMetadataUtil";

export let singleRegionNotCompletedNoDateHeaders: string[] = [
  "Service Long Name",
  "Category",
  "RIP Build Status",
];

export function getHeadersWithDates(showRMSDate: boolean): string[] {
  if (showRMSDate) {
    return [...singleRegionNotCompletedNoDateHeaders, "Confidence", "Planned Launch Date", "RMS Projected GA Date"]
  }
  else{
    return [...singleRegionNotCompletedNoDateHeaders, "Confidence", "Planned Launch Date"]
  }
}

export function addDateColumnsIfApplicable(canReadDates: boolean, row: ExportDataCell[], showRMSDate: boolean, singleServicePlan: IServicePlan, regionName: string): ExportDataCell[] {
  if (!canReadDates) {
    return row
  }

  row = [...row, getConfidenceFromServicePlan(singleServicePlan), getPlannedDateFromServicePlan(singleServicePlan)]

  if (showRMSDate) {
    //isRMSManaged is always true for now because we don't filter for 90days on the Single Region Not Completed Table yet
    row.push(getRMSDateForExport(true, getRmsProjectedFinishDateFromRegionDetail(singleServicePlan, regionName)));
  }

  return row

}

export const generateSingleRegionNotCompletedWorkbook = (plans: IServicePlan[], canReadDates: boolean, regionName: string): ExportDataBook => {
  let singleServiceNotCompletedExcelData: ExportDataCell[][] = [canReadDates ? getHeadersWithDates(isRegionApplicable(regionName)): singleRegionNotCompletedNoDateHeaders]

  plans.forEach((plan: IServicePlan) => {
    let row: string[] = [
      getServiceDisplayName(plan),
      getCategoryFromPlanForDisplay(plan),
      getStatusFromServicePlan(plan),
    ]

    singleServiceNotCompletedExcelData.push(addDateColumnsIfApplicable(canReadDates, row, isRegionApplicable(regionName), plan, regionName));
  })

  return dataToDataBook(singleServiceNotCompletedExcelData);
}
