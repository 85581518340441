import {
  AllContactsPage,
  AutomatedInsightsDashboardPage,
  ContactsPage,
  LaunchDatePage,
  RegionResource,
  ServiceResource,
  ServiceTeamPage,
  ParityPage,
  ApiParityPage,
  FeatureParityPage,
  ServiceParityPage,
  ServiceLandingResource,
  ServiceLandingPage,
  LandingPage,
  ServiceTeamDashboardPage,
  LeadershipDashboardPage,
  ServiceParityReportPage,
  LaunchManagerDashboardPage,
  LandingPageResource,
  ProductPropertiesPage,
  ProductLaunchesPage,
  ProductCreateLaunchPage,
  ProductLandingPage,
  ProductResource,
  ProductLandingResource,
  RegionBuildHealthReportDashboardPage,
  LaunchResource,
  LaunchPropertiesPage,
  LaunchEditPage,
  LaunchEditRegionsPage,
  LaunchEditDocsReviewsPage,
  DependenciesAndSuccessorsDashboardPage,
  DependenciesAndSuccessorsPage
} from "@amzn/region-services-react-library-v4";
import * as React from "react";
import { ApiParityBanner } from "./components/common/ApiParityBanner";
import { PivotTableLoader } from "./components/PivotTable/PivotTableLoader";
import { diagnosticRoutes } from "./routes/diagnosticRoute";
import { IRouteDefinition } from "./routes/IRouteDefinition";
import { ApiParityContainer } from "./views/ApiParityContainer/ApiParityContainer";
import {
  LaunchManagerBetaDashboard,
  LaunchManagerDashboard,
  LeadershipBetaDashboard,
  LeadershipDashboard,
  ServiceManagerBetaDashboard,
  ServiceManagerDashboard,
  ServiceTeamBetaDashboard,
  ServiceTeamDashboard,
  MailerStatsBetaDashboard,
  MailerStatsDashboard,
  RegionReadinessScoreDashboard,
  AutomationDashboard,
  RegionBuildHealthDashboard,
  DataQualityDashboard,
  UnblockedItemsDashboard,
  DependenciesAndSuccessorsDashboard,
} from "./views/Dashboards/dashboards";
import Home from "./views/Home/Home";
import PageNotFoundContainer from "./views/PageNotFound/PageNotFoundContainer";
import ParityContainer from "./views/Parity/ParityContainer";
import RegionsListContainer from "./views/RegionsList/RegionsListContainer";
import { ReportsContainer } from "./views/Reports/ReportsContainer";
import { ServiceContactsTableContainer } from "./views/ServiceContacts/ServiceContactsTableContainer";
import ServiceInRegionContainer from "./views/ServiceInRegion/ServiceInRegionContainer";
import ServicesListContainer from "./views/ServicesList/ServicesListContainer";
import SingleRegionContainer from "./views/SingleRegion/SingleRegionContainer";
import { ResourceParityContainer } from "./views/ResourceParityContainer/ResourceParityContainer";
import { Redirect } from "react-router";
import { SingleServiceParityContainer } from "./views/SingleService/SingleServiceParity/SingleServiceParityContainer";
import { SingleServiceTeamContainer } from "./views/SingleService/SingleServiceTeam/SingleServiceTeamContainer";
import { SingleServiceContactContainer } from "./views/SingleService/SingleServiceContact/SingleServiceContactContainer";
import { SingleServiceExpansionContainer } from "./views/SingleService/SingleServiceExpansion/SingleServiceExpansionContainer";
import { SingleServiceContainer } from "./views/SingleService/SingleServiceContainer";
import { FeatureParityContainer } from "./views/FeatureParityContainer/FeatureParityContainer";
import ProductsListContainer from "./views/ProductsListContainer/ProductsListContainer";
import { ProductDetailsContainer } from "./views/ProductDetailsContainer/ProductDetailsContainer";
import { ProductLaunchesContainer } from "./views/ProductLaunchesContainer/ProductLaunchesContainer";
import { LaunchDetailsContainer } from "./views/LaunchDetailsContainer/LaunchDetailsContainer";
import { SingleServiceDependenciesAndSuccessorsContainer } from "./views/SingleService/SingleServiceDependenciesAndSuccessors/SingleServiceDependenciesAndSuccessorsContainer";
import { LaunchWizardContainer } from "./views/LaunchWizardContainer/LaunchWizardContainer";
import { EditLaunchContainer } from "./views/EditLaunch/EditLaunchContainer";
import { EditLaunchRegionsContainer } from "./views/EditLaunchRegions/EditLaunchRegionsContainer";
import { EditLaunchDocReviewsContainer } from "./views/EditLaunchDocReviews/EditLaunchDocReviewsContainer";

const NoNotification: React.FunctionComponent = () => {
  return <React.Fragment />;
};

export const routes: IRouteDefinition[] = [
  {
    key: "home",
    path: "/",
    resourceConstructor: ServiceLandingResource,
    unifiedPage: LandingPage,
    exact: true,
    secure: false,
    component: Home,
  },
  {
    key: "serviceInRegionFromRegion",
    path: "/regions/:regionName/:serviceName",
    resourceConstructor: RegionResource,
    exact: true,
    secure: true,
    component: ServiceInRegionContainer,
  },
  {
    // This route needs to be put before service in region route
    // this is because it has the same route pattern, if it is put after
    // user would be would directed to service in region instead of the service team
    key: "singleServiceServiceTeam",
    path: "/services/:serviceName/serviceteam",
    resourceConstructor: ServiceResource,
    unifiedPage: ServiceTeamPage,
    exact: true,
    secure: true,
    component: SingleServiceTeamContainer,
    notification: NoNotification,
  },
  {
    // This route needs to be put before service in region route
    // this is because it has the same route pattern, if it is put after
    // user would be would directed to service in region instead of the service team
    key: "singleServiceDependenciesAndSuccessors",
    path: "/services/:serviceName/dependenciesandsuccessors",
    resourceConstructor: ServiceResource,
    unifiedPage: DependenciesAndSuccessorsPage,
    exact: true,
    secure: true,
    component: SingleServiceDependenciesAndSuccessorsContainer,
    notification: NoNotification,
  },
  {
    key: "singleServiceParity",
    path: "/services/:serviceName/parity",
    resourceConstructor: ServiceResource,
    unifiedPage: ParityPage,
    exact: true,
    secure: true,
    component: SingleServiceParityContainer,
  },
  {
    key: "singleServiceContact",
    path: "/services/:serviceName/contact",
    resourceConstructor: ServiceResource,
    unifiedPage: ContactsPage,
    exact: true,
    secure: true,
    component: SingleServiceContactContainer,
  },
  {
    key: "singleServiceExpansionTracking",
    path: "/services/:serviceName/launchdates",
    resourceConstructor: ServiceResource,
    unifiedPage: LaunchDatePage,
    exact: true,
    secure: true,
    component: SingleServiceExpansionContainer,
  },
  {
    key: "serviceInRegionFromService",
    path: "/services/:serviceName/:regionName",
    resourceConstructor: ServiceResource,
    exact: true,
    secure: true,
    component: ServiceInRegionContainer,
  },
  {
    key: "singleService",
    path: "/services/:serviceName",
    resourceConstructor: ServiceResource,
    exact: true,
    secure: true,
    component: SingleServiceContainer,
  },
  {
    key: "services",
    path: "/services",
    resourceConstructor: ServiceLandingResource,
    unifiedPage: ServiceLandingPage,
    exact: true,
    secure: true,
    component: ServicesListContainer,
  },
  {
    key: "singleRegion",
    path: "/regions/:regionName",
    resourceConstructor: RegionResource,
    exact: true,
    secure: true,
    component: SingleRegionContainer,
  },
  {
    key: "reports",
    path: "/reports",
    resourceConstructor: ServiceLandingResource,
    unifiedPage: ServiceParityReportPage,
    exact: true,
    secure: true,
    component: ReportsContainer,
  },
  {
    key: "regions",
    path: "/regions",
    resourceConstructor: RegionResource,
    exact: true,
    secure: true,
    component: RegionsListContainer,
  },
  {
    key: "parity",
    path: "/parity",
    resourceConstructor: ServiceLandingResource,
    unifiedPage: ServiceParityPage,
    exact: true,
    secure: true,
    component: ParityContainer,
  },
  {
    key: "apiparity",
    path: "/parity/api",
    resourceConstructor: ServiceLandingResource,
    unifiedPage: ApiParityPage,
    exact: true,
    secure: true,
    component: ApiParityContainer,
    notification: ApiParityBanner,
  },
  {
    key: "resourceparity",
    path: "/parity/resource",
    resourceConstructor: ServiceLandingResource,
    exact: true,
    secure: true,
    component: ResourceParityContainer,
  },
  {
    key: "contacts",
    path: "/contacts",
    resourceConstructor: ServiceLandingResource,
    unifiedPage: AllContactsPage,
    exact: true,
    secure: true,
    component: ServiceContactsTableContainer,
  },
  {
    key: "servicemanagerdashboard",
    path: "/dashboards/servicemanager",
    resourceConstructor: ServiceResource,
    exact: true,
    secure: true,
    component: ServiceManagerDashboard,
    notification: NoNotification,
  },
  {
    key: "launchmanagerdashboard",
    path: "/dashboards/launchmanager",
    resourceConstructor: LandingPageResource,
    unifiedPage: LaunchManagerDashboardPage,
    exact: true,
    secure: true,
    component: LaunchManagerDashboard,
    notification: NoNotification,
  },
  {
    key: "serviceteamdashboard",
    path: "/dashboards/serviceteam",
    resourceConstructor: LandingPageResource,
    unifiedPage: ServiceTeamDashboardPage,
    exact: true,
    secure: true,
    component: ServiceTeamDashboard,
    notification: NoNotification,
  },
  {
    key: "leadershipdashboard",
    path: "/dashboards/leadership",
    resourceConstructor: LandingPageResource,
    unifiedPage: LeadershipDashboardPage,
    exact: true,
    secure: true,
    component: LeadershipDashboard,
    notification: NoNotification,
  },
  {
    key: "launchmanagerbeta",
    path: "/dashboards/launchmanagerbeta",
    resourceConstructor: ServiceResource,
    exact: true,
    secure: true,
    component: LaunchManagerBetaDashboard,
    notification: NoNotification,
  },
  {
    key: "servicemanagerbeta",
    path: "/dashboards/servicemanagerbeta",
    resourceConstructor: ServiceResource,
    exact: true,
    secure: true,
    component: ServiceManagerBetaDashboard,
    notification: NoNotification,
  },
  {
    key: "serviceteambeta",
    path: "/dashboards/serviceteambeta",
    resourceConstructor: ServiceResource,
    exact: true,
    secure: true,
    component: ServiceTeamBetaDashboard,
    notification: NoNotification,
  },
  {
    key: "leadershipbeta",
    path: "/dashboards/leadershipbeta",
    resourceConstructor: ServiceResource,
    exact: true,
    secure: true,
    component: LeadershipBetaDashboard,
    notification: NoNotification,
  },
  {
    key: "mailerstatsbeta",
    path: "/dashboards/mailerstatsbeta",
    resourceConstructor: ServiceResource,
    exact: true,
    secure: true,
    component: MailerStatsBetaDashboard,
    notification: NoNotification,
  },
  {
    key: "mailerstats",
    path: "/dashboards/mailerstats",
    resourceConstructor: ServiceResource,
    exact: true,
    secure: true,
    component: MailerStatsDashboard,
    notification: NoNotification,
  },
  {
    key: "regionreadinessscore",
    path: "/dashboards/regionreadinessscore",
    resourceConstructor: ServiceResource,
    exact: true,
    secure: true,
    component: RegionReadinessScoreDashboard,
    notification: NoNotification,
  },
  {
    key: "automationdashboard",
    path: "/dashboards/automationdashboard",
    resourceConstructor: LandingPageResource,
    unifiedPage: AutomatedInsightsDashboardPage,
    exact: true,
    secure: true,
    component: AutomationDashboard,
    notification: NoNotification,
  },
  {
    key: "regionbuildhealthdashboard",
    path: "/dashboards/regionbuildhealthdashboard",
    resourceConstructor: LandingPageResource,
    unifiedPage: RegionBuildHealthReportDashboardPage,
    exact: true,
    secure: true,
    component: RegionBuildHealthDashboard,
    notification: NoNotification,
  },
  {
    key: "dataqualitydashboard",
    path: "/dashboards/dataqualitydashboard",
    resourceConstructor: ServiceResource,
    exact: true,
    secure: true,
    component: DataQualityDashboard,
    notification: NoNotification,
  },
  {
    key: "dependenciesandsuccessorsdashboard",
    path: "/dashboards/dependenciesandsuccessors",
    resourceConstructor: LandingPageResource,
    unifiedPage: DependenciesAndSuccessorsDashboardPage,
    exact: true,
    secure: true,
    component: DependenciesAndSuccessorsDashboard,
    notification: NoNotification,
  },
  {
    key: "unblockeditemsdashboard",
    path: "/dashboards/unblockeditemsdashboard",
    resourceConstructor: ServiceResource,
    exact: true,
    secure: true,
    component: UnblockedItemsDashboard,
    notification: NoNotification,
  },
  {
    key: "featureParity",
    path: "/parity/features",
    resourceConstructor: ServiceLandingResource,
    unifiedPage: FeatureParityPage,
    exact: true,
    secure: true,
    component: FeatureParityContainer,
    notification: NoNotification,
  },
  {
    key: "pivot",
    path: "/pivot",
    resourceConstructor: ServiceLandingResource,
    exact: true,
    secure: true,
    component: PivotTableLoader,
    notification: NoNotification,
  },
  {
    key: "almProducts",
    path: "/alm/products",
    resourceConstructor: ProductLandingResource,
    unifiedPage: ProductLandingPage,
    unifiedHeader: true,
    exact: true,
    secure: true,
    component: ProductsListContainer,
    notification: NoNotification,
  },
  {
    key: "almProduct",
    path: "/alm/products/:productId",
    resourceConstructor: ProductResource,
    unifiedPage: ProductPropertiesPage,
    unifiedHeader: true,
    exact:true,
    secure: true,
    component: ProductDetailsContainer,
    notification: NoNotification
  },
  {
    key: "almLaunches",
    path: "/alm/products/:productId/launches",
    resourceConstructor: ProductResource,
    unifiedPage: ProductLaunchesPage,
    unifiedHeader: true,
    exact: true,
    secure: true,
    component: ProductLaunchesContainer,
    notification: NoNotification
  },
  {
    key: "almLaunchesCreate",
    path: "/alm/products/:productId/launches/create",
    resourceConstructor: ProductResource,
    unifiedPage: ProductCreateLaunchPage,
    exact: true,
    secure: true,
    component: LaunchWizardContainer,
    notification: NoNotification
  },
  {
    key: "almLaunch",
    path: "/alm/launches/:launchId",
    resourceConstructor: LaunchResource,
    unifiedPage: LaunchPropertiesPage,
    unifiedHeader: true,
    exact: true,
    secure: true,
    component: LaunchDetailsContainer,
    notification: NoNotification
  },
  {
    key: "almLaunchEdit",
    path: "/alm/launches/:launchId/edit",
    resourceConstructor: LaunchResource,
    unifiedPage: LaunchEditPage,
    unifiedHeader: true,
    exact: true,
    secure: true,
    component: EditLaunchContainer,
    notification: NoNotification
  },
  {
    key: "almLaunchRegionEdit",
    path: "/alm/launches/:launchId/edit/regions",
    resourceConstructor: LaunchResource,
    unifiedPage: LaunchEditRegionsPage,
    unifiedHeader: true,
    exact: true,
    secure: true,
    component: EditLaunchRegionsContainer,
    notification: NoNotification
  },
  {
    key: "almLaunchDocEdit",
    path: "/alm/launches/:launchId/edit/docs_reviews",
    resourceConstructor: LaunchResource,
    unifiedPage: LaunchEditDocsReviewsPage,
    unifiedHeader: true,
    exact: true,
    secure: true,
    component: EditLaunchDocReviewsContainer,
    notification: NoNotification
  },
  {
    key: "pageNotFound",
    secure: false,
    resourceConstructor: ServiceLandingResource,
    component: PageNotFoundContainer,
  },
  ...diagnosticRoutes,
];

export const redirects = [
  <Redirect
    key="redirectServiceManager"
    from={"/dashboards/servicemanager"}
    to={"/dashboards/serviceteam"} />,
  <Redirect
    key="redirectServiceManagerBeta"
    from={"/dashboards/servicemanagerbeta"}
    to={"/dashboards/serviceteambeta"} />
];

export default routes;
