import { getReconApiClient, normalizeServiceName } from "./daoUtil";
import { IServiceContactsDto } from "./types/IServiceContactsDto";
import { ContactsType, ServiceContactsVO } from "../models/vos/ServiceContactsVO";
import { IAddOrEditServiceContactResponse } from "./types/IAddOrEditServiceContactResponse";
import { AxiosError, AxiosInstance } from "axios";
import {
  IBulkEditServiceContactApiResponse,
  IBulkEditServiceContactResponse,
  OverrideInput
} from "../components/ServiceContacts/EnterServiceContact/BulkEditServiceContactWizard/BulkEditServiceContactWizard.types";
import { editableFields } from "../components/ServiceContacts/EnterServiceContact/BulkEditServiceContactWizard/BulkEditServiceContactWizardConstants";
import { IPatchModel } from "./types/IPatchModel";
import { IDeleteServiceContactResponse } from "./types/IDeleteServiceContactsResponse";
import { nonCTIFormFields } from "../components/ServiceContacts/EnterServiceContact/AddOrEditServiceContactModal/AddOrEditServiceContactModalFormFields";
import { IAddOrEditFormInput } from "../components/ServiceContacts/EnterServiceContact/AddOrEditServiceContactModal/AddOrEditServiceContactModal.types";
import { Optional } from "../models/types/Optional";
import { IServiceLeaderUpdate } from "../models/delegates/UpdateServiceLeaderDelegate";
import { getPatches, ModifierFunction } from "../utils/patchUtil";
import { IGenericMutationResponseDto } from "./types/IGenericMutationResponseDto";

import { IRegionMetadata } from "./types/IRegionMetaData";
import { FixedApiErrorMessage } from "./errors";
import { RegionPartitionId } from "../models/RegionPartition";
import { doesServiceContactInstanceBelongToInstance } from "../utils/serviceContactUtil";
import { isBetaOrDevEnv } from "../utils/environmentUtil";

const SCPatchPaths= {
  "contactsType": "/contacts_type",
  "buildTask": "/build_task",
  "cti": "/cti",
  "partition": "/partition",
  "primaryBuildPoc": "/primary_build_poc_map",
  "secondaryBuildPoc": "/secondary_build_poc_map",
  "rbtServiceManager": "/rbt_service_manager_map",
  "notifications": "/notifications",
  "simGuid": "/sim_guid",
  "azServiceOwner": "/az_service_owner_map",
  "localZoneServiceOwner": "/local_zone_service_owner_map",
  "vpceBuildOwner": "/vpce_build_owner_map",
  "wikiUrl": "/wiki_url",
  "oncallTeam": "/oncall_team",
  "inRmsGraph": "/in_rms_graph",
  "ripContacts": "/rip_contacts",
  "opsDashboard": "/ops_dashboard",
}

const SCPatchMaps = ["/primary_build_poc_map", "/secondary_build_poc_map", "/rbt_service_manager_map", "/az_service_owner_map", "/local_zone_service_owner_map", "/vpce_build_owner_map"]

export const buildTaskNoSpaces = (buildTask: string): string => {
  return buildTask.split(" ").join("");
}

export class ServiceContactsDao {
  /**
   * Get a single service contacts record if it exists
   * @deprecated Please use getServiceContactForPartition instead
   * @param {string} serviceName
   */
  async getServiceContacts(serviceName: string): Promise<Optional<IServiceContactsDto>> {
    return this.getForPartition(serviceName);
  }

  async getForPartition(serviceId: string, partition: string = RegionPartitionId.public): Promise<Optional<IServiceContactsDto>> {
    const contacts: Optional<readonly IServiceContactsDto[]> = await this.getForAllPartitions(serviceId, partition === RegionPartitionId.public);
    if (!contacts) {
      return;
    }

    return contacts.find((dto) => doesServiceContactInstanceBelongToInstance(dto.instance, partition));
  }

  /**
   * Get a service' contacts in all available partitions
   * @param serviceId
   * @param limitToPublicPartition This is a temporary parameter just to ensure the code works without the new Service Contact APIs which supports other partitions
   */
  async getForAllPartitions(serviceId: string, limitToPublicPartition: boolean = false): Promise<Optional<readonly IServiceContactsDto[]>> {
    try {
      const normalizedName = buildTaskNoSpaces(normalizeServiceName(serviceId));
      const client = getReconApiClient();
      const res = await client.get<IServiceContactsDto[]>(this.getServiceContactsUrl(normalizedName, !limitToPublicPartition));
      let promises = res.data.map(async (result) => {
        try {
          const resAudit = await client.get(this.getServiceContactsAuditUrl(result["instance"]))
          if (resAudit) {
            result["audit"] = resAudit.data
          }
        }
        catch (err) {
          console.log("Could not fetch audit data")
          console.log(err)
          result["audit"] = undefined
        }
      })
      let results = await Promise.all(promises)
      return res.data;
    } catch (exception) {
      const exceptionType = (exception as AxiosError).response?.data?.message
      if (exceptionType !== FixedApiErrorMessage.objectNotFound) {
        throw exception
      }
    }
  }

  getServiceContactsAuditUrl(serviceName: string): string {
    return `/api/contacts/${normalizeServiceName(serviceName)}/audit`;
  }

  /**
   *
   * @param {string} serviceName
   * @param includeAllPartitions
   * @return {string}
   */
  getServiceContactsUrl(serviceName: string, includeAllPartitions: boolean = false): string {
    let normalizeName = normalizeServiceName(serviceName);

    if (includeAllPartitions) {
      normalizeName += ":all";
    }
    return `/api/contacts/${encodeURIComponent(normalizeName)}`;
  }

  /**
   * Get all contacts
   */
  async getAllContacts(in_rms_graph: boolean): Promise<IServiceContactsDto[]> {
    const client = getReconApiClient();
    const res = await client.get(this.getAllContactsUrl(in_rms_graph));
    let allContacts = res.data;

    return allContacts;
  }

  /**
   *
   * @return {string}
   */
  getAllContactsUrl(in_rms_graph: boolean): string {
    return "/api/contacts" + (in_rms_graph ? "?in_rms_graph=true" : "");
  }

  async bulkEditServiceContacts(data: (field: string) => OverrideInput, services: string[]): Promise<IBulkEditServiceContactResponse[]> {
    const patch: IPatchModel[] = this.getBulkServiceContactsPatch(data);

    const allServiceContactPromises: Promise<IBulkEditServiceContactResponse>[] = services.map(
      (service) => this.patchSingleServiceContacts(service.split(":")[0], patch, service.split(":")[1])
    );

    return Promise.all(allServiceContactPromises)
  }

  patchSingleServiceContacts(service: string, patch: IPatchModel[], partition?: string | undefined): Promise<IBulkEditServiceContactResponse> {
    const client: AxiosInstance = getReconApiClient();

    return new Promise<IBulkEditServiceContactResponse>((resolve, reject) => {
      client.patch<IBulkEditServiceContactApiResponse>(this.patchServiceContactsURL(service, partition), patch).then((resp) => {
        resolve({ message: resp.data.message, serviceName: service });
      }).catch((e: AxiosError) => {
        if (e.response?.status === 404) {
          resolve({ message: e.response.data.message, serviceName: service });
        } else {
          reject(e);
        }
      })
    });
  }

  getBulkServiceContactsPatch(data: (field: string) => OverrideInput): IPatchModel[] {
    let patch: IPatchModel[] = [];

    editableFields.forEach((field) => {
      const attribute = data(field.attribute)
      attribute.override && this.addAttrToPatch(attribute.value, SCPatchPaths[field.attribute], patch)
    })

    return patch
  }

  /**
   * Add or Edit Service Contact with Patch
   * @return {Promise<string>}
   * @param data
   * @param serviceName
   * @param prevContactInfo
   */
  async addOrEditServiceContact(data: IAddOrEditFormInput, prevContactInfo: Optional<ServiceContactsVO> = undefined): Promise<IAddOrEditServiceContactResponse> {
    const patch: IPatchModel[] = this.getServiceContactsPatch(data, prevContactInfo);
    const client: AxiosInstance = getReconApiClient();
    const responseMessage: IAddOrEditServiceContactResponse = await (await client.patch(this.patchServiceContactsURL(data.serviceName, (data.partition ? data.partition : prevContactInfo?.partition)), patch)).data;
    return responseMessage
  }

  getRipContacts(value: string): string [] {
    if (!value) {
      return [];
    }

    const ripContactsList =  value.split(",")
    const noWhiteSpace = ripContactsList.map(alias => alias.trim())
    const noEmptyValues = noWhiteSpace.filter(alias => alias)
    const uniqueValuesOnly = noEmptyValues.filter((value, index, self) => self.indexOf(value) === index)

    return uniqueValuesOnly
  }

  getServiceContactsPatch(data: IAddOrEditFormInput, prevContactInfo: Optional<ServiceContactsVO>): IPatchModel[] {
    let patch: IPatchModel[] = [];

    for (let formField in nonCTIFormFields) {
      let formFieldName = nonCTIFormFields[formField].id;
      let submittedFieldValue: string | string [];

      if (formFieldName === "ripContacts") {
        const ripContactModifiedAndNotEmpty: boolean = typeof(data[formFieldName]) === "string" && data[formFieldName];
        const noRipContactExist: boolean = data[formFieldName] && data[formFieldName].length === 0;
        submittedFieldValue = ripContactModifiedAndNotEmpty ?  this.getRipContacts(data[formFieldName]) : data[formFieldName];

        if (noRipContactExist) {
          submittedFieldValue = "";
        }
      }
      else {
        submittedFieldValue =  data[formFieldName].trim() || "";
      }

      if (!prevContactInfo || prevContactInfo[formFieldName] !== submittedFieldValue){
        this.addAttrToPatch(submittedFieldValue, SCPatchPaths[formFieldName], patch);
      }
    }

    this.addContactsTypeCTIAndBuildTaskToPatch(prevContactInfo, patch, data);
    return patch
  }

  addContactsTypeCTIAndBuildTaskToPatch(prevContactInfo: ServiceContactsVO | undefined, patch: IPatchModel[], data: IAddOrEditFormInput) {
    if (prevContactInfo) {
      if (prevContactInfo.contactsType === ContactsType.NonRip) {
        this.addCTIToPatch(data, prevContactInfo, patch);
      }
    } else {
      this.addAttrToPatch(data.contactsType, SCPatchPaths.contactsType, patch)
      if (data.buildTask) {
        this.addAttrToPatch(data.buildTask, SCPatchPaths.buildTask, patch)
        this.addCTIToPatch(data, prevContactInfo, patch);
      }
    }
  }

  addCTIToPatch(data: IAddOrEditFormInput, prevContactInfo: ServiceContactsVO | undefined, patch: IPatchModel[]) {
    if (data["category"] !== "") {
      let cti: { C: any; T: any; I: any; } = {
        "C": data["category"],
        "T": data["type"],
        "I": data["item"],
      };
      if (prevContactInfo && prevContactInfo.cti) {
        if (cti.C !== prevContactInfo.cti.category || cti.T !== prevContactInfo.cti.type || cti.I !== prevContactInfo.cti.item) {
          this.addAttrToPatch(cti, SCPatchPaths.cti, patch)
        }
      } else {
        this.addAttrToPatch(cti, SCPatchPaths.cti, patch)
      }
    }
  }

  addAttrToPatch(SCAttr: any, SCPath: string, patch: IPatchModel[]): void {
    if (SCAttr !== "") {
      patch.push(this.getSingleAddAttrPatchInfo(SCPath, SCAttr));
    }
    else {
      patch.push(this.getSingleRemoveAttrPatchInfo(SCPath, SCAttr))
    }
  }

  getSingleAddAttrPatchInfo(path: string, value: string): IPatchModel {
    var patchValue;

    if (SCPatchMaps.includes(path)) {
      patchValue = { "alias": value }
    }
    else {
      patchValue = value
    }

    return { "op": "add", "path": path, "value": patchValue }
  }

  getSingleRemoveAttrPatchInfo(path: string, value: string): IPatchModel {
    return { "op": "remove", "path": path }
  }

  patchServiceContactsURL(serviceName: string, partition?: string | undefined): string {
    return "/api/contacts/" + encodeURIComponent(buildTaskNoSpaces(normalizeServiceName(serviceName + (partition ? `:${partition}` : ""))));
  }

  deleteServiceContactsURL(serviceName: string, partition: string | undefined): string {
    return "/api/contacts/" + encodeURIComponent(buildTaskNoSpaces(normalizeServiceName(serviceName + (partition ? `:${partition}` : ""))));
  }

  async deleteServiceContact(serviceContact: ServiceContactsVO): Promise<IDeleteServiceContactResponse> {
    const client: AxiosInstance = getReconApiClient();
    const responseMessage: IDeleteServiceContactResponse = await (await client.delete(this.deleteServiceContactsURL(serviceContact.serviceName, serviceContact.partition))).data;
    return responseMessage
  }

  /**
   * Update GM/VP of the specified service contact
   * @param dto The contact's DTO
   * @param update The updated leader information
   */
  async updateLeader(dto: IServiceContactsDto, update: IServiceLeaderUpdate): Promise<IGenericMutationResponseDto> {
    const patch: IPatchModel[] = getPatches(dto, this.getUpdateLeaderPatchFn(update));
    const client: AxiosInstance = getReconApiClient();
    const res = await client.patch<IGenericMutationResponseDto>(
      this.patchServiceContactsURL(dto.instance),
      patch
    );

    return res.data;
  }

  getUpdateLeaderPatchFn(update: IServiceLeaderUpdate): ModifierFunction<IServiceContactsDto> {
    return (contact: IServiceContactsDto): void => {
      if (update.gm?.trim()) {
        contact.gm = update.gm.trim();
      } else {
        delete(contact.gm);
      }

      if (update.vp?.trim()) {
        contact.vp = update.vp.trim();
      } else {
        delete(contact.vp);
      }
    }
  }

  async updateRipContacts(dto: IServiceContactsDto, update: string[]): Promise<IGenericMutationResponseDto> {
    const patch: IPatchModel[] = [];
    if (!update || update.length === 0)
      patch.push({ op: "remove", path: "/rip_contacts" });
    else
      patch.push({ op: "replace", path: "/rip_contacts", value: update });

    const client: AxiosInstance = getReconApiClient();
    const res = await client.patch<IGenericMutationResponseDto>(
      this.patchServiceContactsURL(dto.instance),
      patch
    );

    return res.data;
  }

  // Create a map of partition to array of regions in that partition.
  createPartitionToRegionsMap(regions: IRegionMetadata[]) {
    const partitionToRegionsMap = {};
    var region;
    for (region in regions) {
      if (!partitionToRegionsMap[regions[region].partition]) {
        partitionToRegionsMap[regions[region].partition] = [];
      }
      partitionToRegionsMap[regions[region].partition].push(regions[region].airportCode);
    }
    return partitionToRegionsMap;
  }
}
