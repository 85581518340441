import { FunctionComponent } from "react";
import * as React from "react";
import { ValueWithLabelProps } from "./ValueWithLabel.types";
import { Box } from "@amzn/awsui-components-react-v3";
import { getDataTestIdProp } from "../../../../../../utils/testUtil";

export const ValueWithLabelTestId = {
  label: "ValueWithLabel-label",
  value: "ValueWithLabel-value",
}

export const ValueWithLabel: FunctionComponent<ValueWithLabelProps> = (props) => {
  return (
    <div {...getDataTestIdProp(props)}>
      <Box margin={{ bottom: "xxxs" }} color="text-label" data-testid={ValueWithLabelTestId.label}>
        {props.label}
      </Box>
      <div data-testid={ValueWithLabelTestId.value}>{props.children}</div>
    </div>
  );
};
