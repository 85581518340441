import { UserAbilities } from "../models/UserAbilities";
import useSWR from "swr";
import { IRipBindleUserResponse } from "../daos/types/IRipBindleUserResponse";
import { RipBindleUserDao } from "../daos/RipBindleUserDao";
import { getSwrConfig } from "../daos/swrUtil";
import { mergeUserAbilitiesWithBindle } from "../views/SingleService/SingleServiceContainerUtil";
import { getAppState } from "../models/ApplicationState";

export type GetBindleDelegate = (serviceId: string) => Promise<IRipBindleUserResponse>;

const defaultRipBindleResponse: IRipBindleUserResponse = { authorized: false };
const dao: RipBindleUserDao = new RipBindleUserDao();
const defaultGetBindleFn: GetBindleDelegate = dao.getRipBindleUser.bind(dao);

export function useUserPermission(serviceId?: string, getBindle: GetBindleDelegate = defaultGetBindleFn): UserAbilities {
  const ripBindleSwr = useSWR<IRipBindleUserResponse>( `${serviceId}Bindles`, () => {
    if (serviceId) {
      return getBindle(serviceId);
    }
    return Promise.resolve(defaultRipBindleResponse);
  }, getSwrConfig());

  const basePermission: UserAbilities = getAppState().userAbilities;
  if (!serviceId) {
    return basePermission;
  }
  const ripBindleResponse = ripBindleSwr.data ?? defaultRipBindleResponse;
  return mergeUserAbilitiesWithBindle(basePermission, ripBindleResponse);
}
