import { ExtractKey } from "@amzn/api-parity-react-component/lib/utils/lookupUtil";

export const RECON_COMPONENT_SEPARATOR = "+";
export const componentIdExtractor: ExtractKey<ComponentFeatureVO> = componentFeatureVO => componentFeatureVO.id;
export const componentFeatureSortFunction = (a, b) => a.id.localeCompare(b.id);

export class ComponentFeatureVO {
  id: string;
  longName: string;
  serviceName: string;
  serviceComponentName: string;

  /**
   *
   * @param id In the form: service-name+component-name
   * @param longName The display name of the component feature
   * @param serviceName The parent service name
   * @param serviceComponentName The parent and component name in the form: service-name+component-name
   */
  constructor(id: string, longName: string, serviceName: string, serviceComponentName: string) {
    this.id = id;
    this.longName = longName;
    this.serviceName = serviceName;
    this.serviceComponentName = serviceComponentName;
  }
}
