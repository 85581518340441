import { InstanceName } from "../models/InstanceName";
import { Nullable } from "../models/types/Nullable";

/**
 * Convert instance text into an InstanceName model
 * @param instanceText
 */
export function fromInstanceText( instanceText: string ): InstanceName {
  const names: string[] = [];
  let isVersioned: boolean = false;
  let version: number = 0;

  const fragments: string[] = instanceText.split(":");
  fragments.forEach((fragment) => {
    const versionSuspect: GetInstanceVersionResult = getInstanceVersion(fragment);
    if (versionSuspect.isVersioned) {
      isVersioned = true;
      version = versionSuspect.version;
    } else {
      names.push(fragment);
    }
  });

  return new InstanceName({
    isVersioned,
    version,
    names,
  });
}


export type GetInstanceVersionResult = {
  isVersioned: true;
  version: number;
} | { isVersioned: false; };


const versionMatcher: RegExp = /^v(\d+)$/i;

/**
 * Parse a string which can be a version number
 * @param fragment
 */
export function getInstanceVersion( fragment: string ): GetInstanceVersionResult {
  const match: Nullable<RegExpMatchArray> = fragment.match(versionMatcher);
  if (!match) {
    return {
      isVersioned: false
    };
  }

  const versionString: string = match[1];
  return {
    isVersioned: true,
    version: parseInt(versionString, 10)
  };
}
