import { AwsServiceVO, CollectionViewBase, RegionVO } from "@amzn/api-parity-react-component";
import { FeatureParityVO } from "../vos/FeatureParityVO";
import { FeatureParityCollection } from "./FeatureParityCollection";
import { FeatureVO } from "../vos/FeatureVO";
import { Optional } from "../types/Optional";

export class FeatureParityCollectionView extends CollectionViewBase<FeatureParityVO, FeatureParityCollection | FeatureParityCollectionView> {
  byFeature(feature: FeatureVO): readonly FeatureParityVO[] {
    return this.applyFilterAndSort(this.source.byFeature(feature));
  }

  byService(service: AwsServiceVO): readonly FeatureParityVO[] {
    return this.applyFilterAndSort(this.source.byService(service))
  }

  byRegion(region: RegionVO): readonly FeatureParityVO[] {
    return this.applyFilterAndSort(this.source.byRegion(region))
  }

  byServiceRegion(service: AwsServiceVO, region: RegionVO): readonly FeatureParityVO[] {
    return this.applyFilterAndSort(this.source.byServiceRegion(service, region));
  }

  byFeatureRegion(feature: FeatureVO, region: RegionVO): Optional<FeatureParityVO> {
    return this.returnItemIfPassesFilter(
      this.source.byFeatureRegion(feature, region)
    );
  }
}
