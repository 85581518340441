import { ConfigInterface } from "swr";

/**
 * @param refreshInterval API call refresh frequency in milliseconds
 * @see https://github.com/vercel/swr#options for more information on the configuration
 */
export const getSwrConfig = (refreshInterval: number = 0): ConfigInterface => {
  return {
    refreshInterval,
    refreshWhenHidden: true, // Considered hidden if the tab is not selected
    revalidateOnFocus: false,
    shouldRetryOnError: false,
  };
}
