import { SideNavigationProps } from "@amzn/awsui-components-react-v3";
import { FAQ_URL, ROADMAP_URL } from "../../../constants";

export const documentSection: SideNavigationProps.Section = {
  type: "section",
  text: "Documentation",
  defaultExpanded: false,
  items: [
    {
      type: "link",
      text: "FAQ",
      href: FAQ_URL,
      external: true,
    },
    {
      type: "link",
      text: "Roadmap",
      href: ROADMAP_URL,
      external: true,
    }
  ]
};
