import * as React from "react";
import { FunctionComponent } from "react";
import { FeatureParityLoaderContainerProps } from "./FeatureParityLoaderContainer.types";
import { ParityDao } from "../../daos/ParityDao";
import { RegionDao } from "../../daos/RegionDao";
import { FeaturesDao } from "../../daos/FeaturesDao";
import { LoadStateEnum } from "../../models/LoadStateEnum";
import { useSelector } from "react-redux";
import { serviceListSelector, serviceLoadStateSelector } from "../../redux/selectors/serviceSelector";
import { AwsServiceVO } from "@amzn/api-parity-react-component";
import { FeatureParityLoader } from "../../components/Parity/FeatureParity/FeatureParityLoader/FeatureParityLoader";

const parityDao: ParityDao = new ParityDao();
const regionDao: RegionDao = new RegionDao();
const featureDao: FeaturesDao = new FeaturesDao();

export const FeatureParityLoaderContainer: FunctionComponent<FeatureParityLoaderContainerProps> = (props) => {
  const serviceListLoadState: LoadStateEnum = useSelector(serviceLoadStateSelector);
  const serviceList: AwsServiceVO[] = useSelector(serviceListSelector);

  return <FeatureParityLoader
    getFeatures={featureDao.getFeatureParityRawData.bind(featureDao)}
    getFeatureParityOverrides={featureDao.getFeatureParityOverride.bind(featureDao)}
    getServiceParities={parityDao.getParity.bind(parityDao)}
    getRegions={regionDao.getRegions.bind(regionDao)}
    serviceList={serviceList}
    serviceListLoadState={serviceListLoadState}
    onDataLoaded={props.onDataLoaded}
  />
};
