import * as React from "react";
import { useCallback, useEffect, useState } from "react";
import { Alert, Button, Container, Header, SpaceBetween } from "@amzn/awsui-components-react-v3";
import useSWR from "swr";
import { getSwrConfig } from "../../daos/swrUtil";
import { BlueprintConflictsReportDao } from "../../daos/BlueprintConflictsReportDao";
import { Link } from "@amzn/awsui-components-react-v3"
import { getDateForFileName } from "../../utils/dateUtil";

export const BluePrintConflictsReportTestId = {
  button: "BlueprintConflictsReport-button",
};

const BlueprintConflictsReport = () => {
  const [fetchError, setFetchError] = useState(false);
  const [shouldFetch, setShouldFetch] = useState(0);

  const { data, isValidating: loading = true, error } = useSWR(
    shouldFetch ? `blueprintconflicts${shouldFetch}` : null, async () => {
      // TODO: Externalize DAO
      const dao = new BlueprintConflictsReportDao();
      const filename = `${getDateForFileName()}_BlueprintConflictsReport.xlsx`;
      return await dao.getBlueprintConflictsReport(filename);
    }, getSwrConfig());

  useEffect(() => {
    if (data) {
      setShouldFetch(0);
    } else if (error) {
      setFetchError(error);
    }
  }, [data, error]);

  const headerText = "Blueprint Conflicts Report";
  const wikiLink = <Link external
                         href="https://w.amazon.com/bin/view/AWSRegionBuildEngineering/Recon/BlueprintConflictsReport/"
                         fontSize="body-s">
    Blueprint Conflicts Report Wiki
  </Link>;
  const description = <>
    This is the .xlsx report which details conflicts with Blueprint data. More information can be found
    here: {wikiLink}
  </>;
  const errorText = `There was an issue generating the Blueprint conflicts report: ${fetchError}`;

  const handleClick = useCallback(async () => {
    setShouldFetch(Date.now());
  }, []);

  return (
    <Container header={<Header description={description}>{headerText}</Header>}>
      <SpaceBetween size={"xl"} direction={"vertical"}>
        {fetchError && <Alert type="error">{errorText}</Alert>}

        <Button
          data-testid={BluePrintConflictsReportTestId.button}
          loading={loading}
          iconName="download"
          iconAlign="right"
          variant="primary"
          onClick={handleClick}>Generate Blueprint Conflicts Report</Button>
      </SpaceBetween>
    </Container>
  );
};

export default BlueprintConflictsReport;
