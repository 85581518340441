import { Optional } from "../../../../models/types/Optional";
import { ContactsType, ServiceContactsVO } from "../../../../models/vos/ServiceContactsVO";
import { CTIContactFormFields } from "./AddOrEditServiceContactModalFormFields";
import { IAddOrEditFormInput } from "./AddOrEditServiceContactModal.types";
import { EnterServiceContactsFormType } from "./AddOrEditServiceContactModalConstants";
import { ServiceContactUpdateResult } from "../../../../orchestrators/ServiceContactOrchestrator";
import { MutationResultType } from "../../../../models/remoteMutation";
import { IconProps } from "@amzn/awsui-components-react-v3";
import { IFormFieldData } from "../../../common/FormTypes";

export interface IEditContactAppearance {
  openButtonLabel: string;
  openButtonIcon?: IconProps.Name;
}

export interface IEditContactAppearanceByAction {
  add: IEditContactAppearance;
  edit: IEditContactAppearance;
  addNewPartition: IEditContactAppearance;
}

export const editContactAppearance: IEditContactAppearanceByAction = {
  add: {
    openButtonLabel: "",
    openButtonIcon: "add-plus",
  },
  edit: {
    openButtonLabel: "Edit",
    openButtonIcon: undefined,
  },
  addNewPartition: {
    openButtonLabel: "Add Partitional Contacts",
    openButtonIcon: undefined,
  }
}

export const getDisabled = (isAdding: boolean, contact: Optional<ServiceContactsVO>): boolean => {
  return !isAdding && !contact;
}

/**
 * Get all the appearance attributes of the edit contact UI by action
 * @param isAdding
 */
export function getAppearance(isAdding: boolean, isAddingNewPartition: boolean): IEditContactAppearance {
  if (isAddingNewPartition) {
    return editContactAppearance.addNewPartition;
  }
  else {
    return (isAdding) ? editContactAppearance.add : editContactAppearance.edit;
  }
}


// TODO: Use appearance instead of individual functions, this helps reduce unit test volume

export function submitButtonLabel(isAdding: boolean): string {
  return (isAdding) ? "Add" : "Update";
}

export function getModalTitle(isAdding: boolean, contact: Optional<ServiceContactsVO>): string {
  if (isAdding) {
    return "Add New Service Contact";
  }
  return `Editing Service Contact for ${contact?.serviceName}`;
}

export function getDefaultValue(serviceContact: Optional<ServiceContactsVO>, formField: IFormFieldData): string {
  if (formField.id in CTIContactFormFields) {
    return (serviceContact?.cti?.[formField.id] || "")
  }
  else {
    return (serviceContact?.[formField.id] || "")
  }
}

export const getContactNameFromForm = (data: IAddOrEditFormInput, prevContact: Optional<ServiceContactsVO>): string => {
  if (prevContact) {
    return (prevContact.contactsType === ContactsType.Rip) ? (prevContact.serviceName || "") : (prevContact.buildTask || "");
  }

  return (data.contactsType === ContactsType.Rip) ? (data.ripShortname || "") : (data.buildTask || "");
}

//TODO: Update this in the future to query the new service contact and pass that back
export function processAddOrEditServiceContactsResponse(response: ServiceContactUpdateResult, isAdding: boolean, serviceName: string,
                                                        onSuccessfulSubmit: Optional<(successfulServices: ServiceContactsVO[],
                                                                             failedServices: ServiceContactsVO[],
                                                                             isAddEditOrDelete: EnterServiceContactsFormType) => void>,
                                                        ): void {
  const newServiceContact = new ServiceContactsVO()
  newServiceContact.serviceName = serviceName

  const changeType = (isAdding) ? EnterServiceContactsFormType.Add : EnterServiceContactsFormType.Edit;

  if (response.type === MutationResultType.Success) {
    onSuccessfulSubmit?.([newServiceContact], [], changeType);
  } else {
    onSuccessfulSubmit?.([], [newServiceContact], changeType);
  }
}

