/*
 * transformParityData() -- return data suitable for displaying on the Parity UI screen.
 * This is a helper function that includes all logic to transform data from the parity
 * API ajax call into what displays on the UI.
 *
 * A key feature is that the return array objects always have the keys shown below,
 * regardless of what is present or absent in the input objects.
 *
 * Portion of the data from /ListParity API:
 * -----------------------------------------
 *     {
 *        "service_metadata": {            *** See transformParityServiceMetaData() below
 *          "name_rip": "snowballedge",
 *          "name_long": "AWS Snowball Edge",
 *          "name_sortable": "Snowball Edge",
 *          "contacts": {
 *             "gm": "sdroy",
 *             "vp": "wjv",
 *             "notifications": "snowball-pm"
 *          },
 *          "category": "Mandatory"
 *        },
 *        "service_instances": {     *** See transformParityServiceInstances() below
 *           "SFO": { "status": "GA",    "confidence": "Complete", "date": "2016-11-30" },
 *           "MXP": { "status": "BUILD", "confidence": "Green",    "date": "2020-05-27" },
 *           ...
 *
 * Expected return array has elements of the form:
 * -----------------------------------------------
 *     {
 *        "nameRip": "dynamodb",       -------+
 *        "nameLong": "DynamoDB",             |
 *        "nameSortable": "DynamoDB",         | Built from "serviceMetaData"
 *        "sTeamDirect": "dulabani",          | element in input objects
 *        "gm": "clazier",                    |
 *        "category": "Launch Blocking",      |
 *        "plan": "myplan",              -----+
 *
 *        "apa": {                       -----+
 *            "status": "PLANNED",            |
 *            "date": "2020-06-30T20...       |
 *            "exceptionStatus": undefined,   | Built from serviceInstances array
 *            "confidence": "Green"           | in input objects
 *        },                                  |
 *        "arn": { ... },                     |
 *        "bah": { ... },                     |
 *        "bjs": { ... },              -------+
 *        ...
 */


/**
 * @param { import("../daos/types/IGetParityResponse").IParityItem } serviceMetadata
 * @return {{nameLong, gm: undefined, nameRip *, nameSortable, category, sTeamDirect: undefined, plan}}
 */
export const transformParityServiceMetaData = serviceMetadata => {
  const { contacts, nameSortable, nameRip, nameLong, category, plan } = serviceMetadata ?? {
    contacts: { gm: undefined, vp: undefined },
    nameSortable: undefined,
    nameRip: undefined,
    nameLong: undefined,
    category: undefined,
    plan: undefined
  };
  const { gm, vp } = contacts ?? { gm: undefined, vp: undefined };

  return {
    nameRip,
    nameLong,
    nameSortable,
    sTeamDirect: vp,
    gm,
    category,
    plan
  };
}


/*
 * Given a SINGLE OBJECT representing ONE REGION from the "servicesInstances"
 * array, return an object for the parity report.  This is a simple transform
 * that pulls out four attributes and keeps their keys in the return object.
 * This does two things:
 *    1. Always returns an object with keys, even if not present in argument
 *    1. Doesn't return any other keys from the input argument
 */
export const transformParityServiceInstanceObject = serviceInstanceObject => {
  const {
    status = undefined,
    date = undefined,
    exceptionStatus = undefined,
    confidence = undefined
  } = serviceInstanceObject ?? {};
  return { status, date, exceptionStatus, confidence };
}


/*
 * Function used to do all transformations on data from /ListParity.  This
 * the expected function other parts of the application should import and
 * call.  This calls the helper methods above to do all the real work.
 */
export const transformParityData = parityData => {
  const items = parityData ?? [];
  return items.map((item) => {
    const { serviceMetadata } = item ?? {};
    const ret = transformParityServiceMetaData(serviceMetadata);

    const { serviceInstances } = item ?? {};
    for(const airportCode in serviceInstances)
      ret[airportCode] = transformParityServiceInstanceObject(serviceInstances[airportCode]);

    return ret;
  });
};

export const serviceInRegionInParity = (confidence, status, exceptionStatus = "") => {
  return (confidence === "Complete" || status === "GA" || exceptionStatus === "ATO" || exceptionStatus === "NL");
};

