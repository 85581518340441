import { SideNavigationProps } from "@amzn/awsui-components-react-v3";
import { ENABLE_RESOURCE_PARITY } from "../../../constants";

const conditionalLaunched: SideNavigationProps.Item[] = ENABLE_RESOURCE_PARITY ? [
  {
    type: "link",
    text: "Resource Parity",
    href: "/parity/resource",
  }
] : [];

export const paritySection: SideNavigationProps.Section = {
  type: "section",
  text: "Parity",
  items: [
    {
      type: "link",
      text: "Service Launch Parity",
      href: "/parity",
    },
    {
      type: "link",
      text: "Feature Parity",
      href: "/parity/features",
    },
    {
      type: "link",
      text: "API Parity",
      href: "/parity/api",
    },
    ...conditionalLaunched,
  ]
};
