import * as React from "react"
import { ContactsType, LxsVO, ServiceContactsVO } from "../../../../models/vos/ServiceContactsVO";
import { FilteringOperation } from "@amzn/awsui-components-react/polaris/internal/components/table/src/internal";
import {
  TablePropertyFilteringProps,
  TableProps
} from "@amzn/awsui-components-react/polaris/internal/components/table/src";
import memoize from "micro-memoize";
import { SingleServiceLink } from "../../../common/Links";
import { serviceContactWarning } from "../../ServiceContactsUtils/serviceContactsWarnings";
import styled from "styled-components";
import { filterPropertyKey } from "../serviceContactTable.constants";
import { Nullable } from "../../../../models/types/Nullable";
import { ExportDataBook, ExportDataCell } from "@amzn/excelerator";
import { serviceContactsDataModel } from "../../ServiceContactsUtils/ServiceContactsCsvExport";
import { dataToDataBook } from "../../../ExportTableToCSVExcel/ExportTableToCSVExcel";

export type MatchStrategy<T> = (token: TablePropertyFilteringProps.FilteringToken, item: T) => boolean;

const leadershipChainKeys: Set<string> = new Set<string>([
  filterPropertyKey.l7s,
  filterPropertyKey.l8s,
  filterPropertyKey.l10s,
]);

export const missingInfoValue = "Yes";

const ServiceNameLayout = styled("div")`
  display: flex;
  flex-direction: row;
`
const ServiceNameContainer = styled("div")`
  flex-grow: 1;
  overflow-wrap: anywhere;
`;
const IconContainer = styled("div")`
  flex-grow: 0;
`;

// This is a direct clone of getOperationFn from Polaris
// if the function were exported in polaris, this would have been unnecessary
// https://code.amazon.com/packages/AWS-UI-Components/blobs/heads/mainline-2.1/--/components/table/src/property-filtering/utils.ts
export const getOperationFn: Function = (operation: FilteringOperation) => {
  switch (operation) {
    case FilteringOperation.OR:
      return (value1: boolean, value2: boolean) => value1 || value2;
    case FilteringOperation.AND:
    default:
      // This default has been intentionally added as defensive programming to catch
      // unsupported operations passed in my customers of the API
      return (value1: boolean, value2: boolean) => value1 && value2;
  }
};

// This is a direct clone of getMatchFn from Polaris
// if the function were exported in polaris, this would have been unnecessary
// https://code.amazon.com/packages/AWS-UI-Components/blobs/heads/mainline-2.1/--/components/table/src/property-filtering/utils.ts
export const getMatchFn: Function = (negated: boolean, matchStrategy: Function) => {
  return negated ? (a, b) => !matchStrategy(a, b) : matchStrategy;
};

// This is a direct clone of fullMatchStategy from Polaris (including typos, :) )
// if the function were exported in polaris, this would have been unnecessary
// https://code.amazon.com/packages/AWS-UI-Components/blobs/heads/mainline-2.1/--/components/table/src/property-filtering/utils.ts
export const fullMatchStategy = (token: TablePropertyFilteringProps.FilteringToken, item: TableProps.Item) => {
  // The double equal is intentional, so 1 can equal to "1"
  // eslint-disable-next-line eqeqeq
  return token.value == item[token.propertyKey];
};


// This is a direct clone of partialMatchStategy from Polaris (including typos, :) )
// if the function were exported in polaris, this would have been unnecessary
// https://code.amazon.com/packages/AWS-UI-Components/blobs/heads/mainline-2.1/--/components/table/src/property-filtering/utils.ts
export const partialMatchStategy = (token: TablePropertyFilteringProps.FilteringToken, item: TableProps.Item) => {
  const searchText = token.value.toLowerCase();
  const searchableProps = token.propertyKey ? [token.propertyKey] : Object.keys(item);

  return searchableProps.some(prop => {
    return typeof item[prop] === "string" && item[prop].toLowerCase().indexOf(searchText) !== -1;
  });
};

export function getContactAliasesImpl(contact: ServiceContactsVO): Set<string> {
  return new Set<string>(contact.leadershipChain?.map((lx: LxsVO) => lx.alias.toLowerCase()) ?? []);
}

export const getContactAliases = memoize(getContactAliasesImpl, {
  maxSize: 50000,
});

export function fullLeadershipChainMatchStrategy(token: TablePropertyFilteringProps.FilteringToken, item: ServiceContactsVO): boolean {
  const aliases = getContactAliases(item);
  return aliases.has(token.value);
}

export function partialLeadershipChainMatchStrategy(token: TablePropertyFilteringProps.FilteringToken, item: ServiceContactsVO): boolean {
  const searchText = token.value.toLowerCase();
  const aliases = getContactAliases(item);
  // @ts-ignore
  for (const alias of aliases.values()) {
    if (alias.includes(searchText)) {
      return true;
    }
  }
  return false;
}

export function getRipContactAliasesImpl(contact: ServiceContactsVO): Set<string> {
  return new Set<string>(contact.ripContacts?.map((contact: string) => contact.toLowerCase()) ?? []);
}

export const getRipContactAliases = memoize(getRipContactAliasesImpl, {
  maxSize: 50000,
});

export function fullRipContactsMatchStrategy(token: TablePropertyFilteringProps.FilteringToken, item: ServiceContactsVO): boolean {
  const aliases = getRipContactAliases(item);
  return aliases.has(token.value.toLowerCase());
}

export function partialRipContactsMatchStrategy(token: TablePropertyFilteringProps.FilteringToken, item: ServiceContactsVO): boolean {
  const searchText = token.value.toLowerCase();
  const aliases = getRipContactAliases(item);
  // @ts-ignore
  for (const alias of aliases.values())
    if (alias.includes(searchText))
      return true;
  return false;
}

export function fullMissingInfoMatchStrategy(token: TablePropertyFilteringProps.FilteringToken, item: ServiceContactsVO): boolean {
  const searchText = token.value.toLowerCase();
  const hasLeadershipChain = !!item.leadershipChain;
  return searchText === missingInfoValue ? hasLeadershipChain : !hasLeadershipChain;
}

export function anyStrategy(...strategies: MatchStrategy<ServiceContactsVO>[]): MatchStrategy<ServiceContactsVO> {
  return (token: TablePropertyFilteringProps.FilteringToken, item: ServiceContactsVO): boolean => {
    return strategies.some(strategy => !!strategy(token, item));
  }
}

export function getMatchStrategy(token: TablePropertyFilteringProps.FilteringToken): MatchStrategy<ServiceContactsVO> {
  if (leadershipChainKeys.has(token.propertyKey)) {
     return token.isFreeText ? partialLeadershipChainMatchStrategy : fullLeadershipChainMatchStrategy;
  }
  if (token.propertyKey === "missingInfo") {
    return fullMissingInfoMatchStrategy;
  }

  if (token.isFreeText) {
    return anyStrategy(partialMatchStategy, partialLeadershipChainMatchStrategy, partialRipContactsMatchStrategy);
  } else {
    return anyStrategy(fullMatchStategy, fullLeadershipChainMatchStrategy, fullRipContactsMatchStrategy);
  }
}

// https://code.amazon.com/packages/AWS-UI-Components/blobs/heads/mainline-2.1/--/components/table/src/property-filtering/utils.ts
export function serviceContactCustomFilter(
  item: ServiceContactsVO,
  tokens: TablePropertyFilteringProps.FilteringToken[],
  operation: string
): boolean {
  return (
    !tokens.length ||
    tokens.reduce((include: boolean, token: TablePropertyFilteringProps.FilteringToken) => {
      const opFn = getOperationFn(operation);
      let strategy = getMatchStrategy(token);
      const eqFn = getMatchFn(token.negated, strategy);

      return opFn(include, eqFn(token, item));
    }, operation === FilteringOperation.AND)
  );
}

export function getLeadershipByLevel(
  contacts: ServiceContactsVO[],
  level: number
): string[] {
  let result: string[] = [];
  contacts.forEach((contact) => {
    if (contact.leadershipChain) {
      result = result.concat(
        contact.leadershipChain.filter((lx) => lx.level === level).map((lx) => lx.alias)
      );
    }
  });
  return result;
}

export function getItemsWithMissingInfo(
  contacts: ServiceContactsVO[],
): string[] {
  let result: string[] = [];
  contacts.forEach((contact) => {
    if (!contact.leadershipChain) {
      result = result.concat(missingInfoValue);
    }
  });
  return result;
}

export function getServiceNameCell(item: ServiceContactsVO, redirectToContact?: boolean): React.ReactFragment {
  return (
    <ServiceNameLayout>
      <ServiceNameContainer>
        {item.contactsType === ContactsType.Rip ?
          <SingleServiceLink nameRip={item.serviceName} nameSortable={item.nameSortable} nameLong={item.nameLong} redirectToContacts={redirectToContact}/> : item.serviceName}
      </ServiceNameContainer>
      <IconContainer>
        {serviceContactWarning(item)}
      </IconContainer>
    </ServiceNameLayout>
  )
}

const keyValueEntryDivider = ": ";
export function extractFilteringText(text: Nullable<string>): string {
  if (!text) {
    return "";
  }

  const propValueIndex: number = text.indexOf(keyValueEntryDivider);
  if (propValueIndex < 0) {
    return text;
  }

  return text.substr(propValueIndex + keyValueEntryDivider.length);
}

export function generateServiceContactsWorkbook(items: ServiceContactsVO[], headers: string[]): ExportDataBook {
  const exportData: ExportDataCell[][] = [];
  exportData.push(headers);
  serviceContactsDataModel(items).forEach((item) => exportData.push(item))
  return dataToDataBook(exportData);
}
