import * as React from "react";
import { Route } from "react-router-dom";
import { Loading } from "../common/Loading";

export const SecureRoute = ({ hasApplicationStateLoaded, component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) =>
        (hasApplicationStateLoaded) ? <Component {...props} /> : <Loading />
      }
      />
  )
}
