import { QaRemoteOperation } from "./QaRemoteOperation";
import { QaChecker } from "./QaChecker";

/**
 * QaItemDefinition is a model that describe what each QA test should be
 * think of it is like an "it()" or "test()" in unit tests
 *
 * Each item consists of a human readable text describing what the test does
 * the remoteOperation that should be executed
 * a checker which checks the result of the remoteOperation
 *
 * The optional testId (not used right now) can be used to mark the test result
 * so End-to-End Cypress tests can inspect the result at greater granularity if needed.
 */
export class QaItemDefinition<T> {
  constructor(
    public description: string,
    public remoteOperation: QaRemoteOperation<T>,
    public checker: QaChecker<T>,
    public testId: string = "",
  ) {
  }
}
