import * as React from "react";
import { FunctionComponent, useCallback } from "react";
import { LaunchDocReviewsProps } from "./LaunchDocReviews.types";
import { getDataTestIdProp } from "../../../../utils/testUtil";
import {
  Box, Button, Cards,
  Container,
  ExpandableSection,
  Header, Link,
  SpaceBetween,
} from "@amzn/awsui-components-react-v3";
import styled from "styled-components";
import { displayOr } from "../../../../utils/displayUtil";
import { formatLaunchDate } from "../../launchDetailsUtils";

export const LaunchDocReviewsTestIdSuffixes = {
  cards: "-cards",
  button: "-button"
}

const ReviewExpandableSection = styled(ExpandableSection)`
  div[role=button] > div:first-child {
    margin-top: 11px;
  }
`;

const LaunchDocCardHeader = ({ docReview }) => (
  <Header
    actions={<Button
      variant="primary"
      href={docReview.docLink}
      iconAlign="right"
      iconName="external"
      target="_blank"
    >
      Open Document
    </Button>}
  >
    Type: {docReview.docReviewType}
  </Header>
);

const LaunchDocCardReview = ({ review }) => (
  <ReviewExpandableSection
    defaultExpanded
    header={
      <Header variant="h3">
        <SpaceBetween size="l" direction="horizontal">
          <Box variant="p">Review Level: {displayOr(review.level)}</Box>
          <Box variant="p">Meeting: {displayOr(
            review.date,
            () => formatLaunchDate(review.date)
          )}</Box>
          <Box variant="p">Status: {displayOr(review.status)}</Box>
        </SpaceBetween>
      </Header>
    }
  >
    <Box padding={{ left: "xxl" }} variant="p">Notes: {displayOr(review.notes)}</Box>
  </ReviewExpandableSection>
);


export const LaunchDocReviews: FunctionComponent<LaunchDocReviewsProps> = (props) => {
  const { onEdit, docReviews, loading, canEdit } = props;

  const edit_onClick = useCallback(() =>
      onEdit?.(),
    [onEdit]
  );

  return (
    <Container
      header={<Header
        variant="h2"
        actions={
          <SpaceBetween size="s" direction="horizontal">
            <Button
              onClick={edit_onClick}
              disabled={!canEdit}
              {...getDataTestIdProp(props, LaunchDocReviewsTestIdSuffixes.button)}
            >
              Edit
            </Button>
          </SpaceBetween>
        }
      >
        Documents & Reviews
      </Header>}
    >
      <Cards
        items={docReviews}
        loading={loading}
        variant="container"
        trackBy="docReviewType" // this should be uniqish, change later
        cardDefinition={{
          header: docReview => <LaunchDocCardHeader docReview={docReview} {...props} />,
          sections: [
            {
              id: "reviews",
              content: item => item.reviews
                ? item.reviews?.map((review, index) =>
                  <LaunchDocCardReview review={review} key={index}/>)
                : <></>
            }
          ]
        }}
        cardsPerRow={[{
          cards: 1
        }]}
        empty={
          <Box textAlign="center" color="inherit">
            <b>No documents</b>
            <Box
              padding={{ bottom: "s" }}
              variant="p"
              color="inherit"
            >
              No documents or reviews to display.
            </Box>
          </Box>
        }
        {...getDataTestIdProp(props, LaunchDocReviewsTestIdSuffixes.cards)}
      />
    </Container>
  );
};
