import * as React from "react";
import { FunctionComponent, useCallback, useEffect, useState } from "react";
import useSWR from "swr";
import { useParams } from "react-router-dom";
import { NoAccessError, NoAccessErrorTestId } from "../../../components/common/NoAccessError";
import { IRipBindleUserResponse } from "../../../daos/types/IRipBindleUserResponse";
import { getSwrConfig } from "../../../daos/swrUtil";
import { UserAbilities } from "../../../models/UserAbilities";
import { PlanDao } from "../../../daos/PlanDao";
import { ServiceDao } from "../../../daos/ServiceDao";
import { IServiceCategoryOption, serviceCategories } from "../../../models/ServiceCategory";
import { IServicePlan, ServicePlanAndStubbed } from "../../../models/types/IServicePlan";
import { GENERALLY_AVAILABLE } from "../../../constants";
import { IRegionMetadata } from "../../../daos/types/IRegionMetaData";
import { IServiceRegionPlanDto } from "../../../daos/types/IServiceRegionPlanDto";
import { IGetSingleServicePlanResponseLegacy } from "../../../daos/types/IGetSingleServicePlanResponse";
import { Optional } from "../../../models/types/Optional";
import { RegionDao } from "../../../daos/RegionDao";
import { IServiceMetaData } from "../../../daos/types/IServiceMetaData";
import { getServiceDisplayName } from "../../../utils/serviceMetadataUtil";
import { equalizeWithRegions, getServicePlansInLaunchedRegionsOrServiceIsNge } from "../../../utils/planUtil";
import { Flash } from "@amzn/awsui-components-react";
import SingleServiceHeader from "../../../components/SingleService/SingleServiceHeader";
import { getRmsServiceDetailUrl } from "../../../utils/unifiedUxUtil";
import useBreadcrumbs from "../../../components/Breadcrumbs/useBreadcrumbs";
import { partition } from "../../../utils/collectionUtil";
import SingleServiceNotCompletedTable from "../../../components/SingleService/SingleServiceNotCompletedTable";
import SingleServiceCompletedTable from "../../../components/SingleService/SingleServiceCompletedTable";
import { Container, SpaceBetween } from "@amzn/awsui-components-react-v3";
import { SingleServiceHeaderContainer } from "../SingleServiceHeader/SingleServiceHeaderContainer";
import { useUserPermission } from "../../../hooks/useUserPermission";
import { ISingleServiceContainerPathParam } from "../SingleServiceContainer.types";
import { fixFormatServiceId } from "../../../utils/urlUtil";

const defaultRegionList: IRegionMetadata[] = [];
const defaultServiceInstances: IServiceRegionPlanDto[] = [];
export const defaultRipBindleResponse: IRipBindleUserResponse = { authorized: false };

export const SingleServiceExpansionContainerTestId = {
  header:  "SingleServiceExpansionContainer-header",
  noAccess: NoAccessErrorTestId.flash,
}

const planDao = new PlanDao();
const serviceDao = new ServiceDao();

export const getCurrentServiceCategory = (plan: string, options: IServiceCategoryOption[]) => {
  const currentPlan = plan ? plan : "None";
  return options.find(({ text }) => text === currentPlan);
};

export const markServiceCategoryAsCurrent = (plan): IServiceCategoryOption[] => {
  return serviceCategories.map(serviceCategory => {
    const serviceCategoryText = serviceCategory?.text;
    if ((serviceCategoryText === plan) || (plan === undefined && serviceCategoryText === "None")) {
      return {
        "text": serviceCategoryText,
        "label": `${serviceCategoryText} (Current)`, // using text attr to remove the '(Removes Category)' from the None Selection
        "id": serviceCategory?.id
      }
    } else {
      return serviceCategory;
    }
  });
};


export interface ISingleServiceExpansionContainerPathParam {
  serviceName: string;
}

export interface IAccessibleSingleServiceExpansionContainerProps {
  serviceId: string;
  showHeader: boolean;
  userAbilities: UserAbilities;
}

// This view is only for unified ux
export const SingleServiceExpansionContainer: FunctionComponent<{}> = () => {
  const { serviceName } = useParams<ISingleServiceContainerPathParam>();

  const userAbilities: UserAbilities = useUserPermission(serviceName);

  const canAccess = !!userAbilities.canRead;
  return canAccess ?
    <AccessibleSingleServiceExpansionContainer
      serviceId={serviceName}
      showHeader={true}
      userAbilities={userAbilities}
    />
    : <NoAccessError />;
}

export const AccessibleSingleServiceExpansionContainer: FunctionComponent<IAccessibleSingleServiceExpansionContainerProps> = (props): JSX.Element => {
  const {
    serviceId,
    userAbilities,
  } = props;

  const planSwr = useSWR<IGetSingleServicePlanResponseLegacy>(serviceId + "plans", () => {
    const planDao = new PlanDao();
    return planDao.getSingleServicePlans(serviceId);
  }, getSwrConfig());

  const service: Optional<IGetSingleServicePlanResponseLegacy> = planSwr.data;
  const refreshService = planSwr.revalidate;

  const regionListSwr = useSWR<IRegionMetadata[]>(serviceId + "regions", () => {
    const regionDao = new RegionDao();
    return regionDao.getRegions();
  }, getSwrConfig());

  const loading = planSwr.isValidating || regionListSwr.isValidating;
  const regions = regionListSwr.data ?? defaultRegionList;

  const [selectedOption, setSelectedOption] = useState<Optional<IServiceCategoryOption>>(undefined);
  const [serviceCategory, setServiceCategory] = useState<Optional<IServiceCategoryOption>>(undefined);
  const [updateCategoryButtonDisabled, setUpdateCategoryButtonDisabled] = useState(true);
  const [combinedInstances, setCombinedInstances] = useState<ServicePlanAndStubbed[]>([]);
  const [serviceCategoriesWithCurrent, setServiceCategoriesWithCurrent] = useState<IServiceCategoryOption[]>([]);

  const onRefreshItems = useCallback(async () => {
    await refreshService();
  }, [refreshService]);

  const serviceMetadata: Optional<IServiceMetaData> = service?.serviceMetadata;
  const serviceInstances: IServiceRegionPlanDto[] = service?.serviceInstances ?? defaultServiceInstances;
  const { nameSortable, plan } = serviceMetadata ?? {
    nameSortable: "",
    nameLong: "",
    plan: "",
  };

  const displayName = (serviceMetadata) ? getServiceDisplayName(serviceMetadata) : "";

  const setBreadcrumbs = useBreadcrumbs();
  useEffect(() => {
    setBreadcrumbs([ "Services=/services", displayName || "" ]);
  }, [displayName, setBreadcrumbs]);


  useEffect(() => {
    const combined: ServicePlanAndStubbed[] = equalizeWithRegions(serviceInstances, regions);
    setCombinedInstances(combined)
  }, [serviceInstances, regions])

  const setCurrentServiceCategory = useCallback((options: IServiceCategoryOption[], plan: string) => {
    const currentServiceCategory = getCurrentServiceCategory(plan, options);
    setSelectedOption(currentServiceCategory);
    setServiceCategory(currentServiceCategory);
  }, [
    setSelectedOption,
    setServiceCategory,
  ]);

  const handleCategorySelectionChange = useCallback(({ detail: { selectedOption: eventSelectedOption } }) => {
    setSelectedOption(eventSelectedOption);
    setUpdateCategoryButtonDisabled( serviceCategory?.id === eventSelectedOption?.id );
  }, [
    serviceCategory,
    setSelectedOption,
    setUpdateCategoryButtonDisabled,
  ]);

  useEffect(() => {
    const options: IServiceCategoryOption[] = markServiceCategoryAsCurrent(plan);
    setServiceCategoriesWithCurrent(options);
    // @ts-ignore
    setCurrentServiceCategory(options, plan);
  }, [
    plan,
    setServiceCategoriesWithCurrent,
    setCurrentServiceCategory,
  ]);

  const header_onSubmit = useCallback(async (service: IServiceMetaData, plan: string) => {
    try {
      const result = await serviceDao.patchServicePlan(service, plan);
      setUpdateCategoryButtonDisabled(true); // TODO: This should be moved into SingleServiceHeader
      await refreshService();
      return result;
    } catch (err) {
      return Promise.reject(err);
    }
  }, [
    refreshService,
  ]);

  const [complete, completeSet] = useState<ServicePlanAndStubbed[]>([]);
  const [notCompleted, notCompletedSet] = useState<ServicePlanAndStubbed[]>([]);

  useEffect(() => {
    const partitioned: [ServicePlanAndStubbed[], ServicePlanAndStubbed[]] = partition(
      combinedInstances,
      (servicePlan: ServicePlanAndStubbed) => {
        return (servicePlan as IServiceRegionPlanDto).status === GENERALLY_AVAILABLE;
      }
    );

    completeSet(partitioned[0]);
    notCompletedSet(partitioned[1]);
  }, [combinedInstances]);

  const notCompletedInGaRegions = getServicePlansInLaunchedRegionsOrServiceIsNge(notCompleted as IServiceRegionPlanDto[]);

  if (planSwr.error || regionListSwr.error) {
    return (
      <Flash
        type="error"
        header="Data Fetching Error"
        content="We are having an issue getting this data,
          please try checking the spelling of the ripname in the url
          or first onboarding to RIP"
      />
    );
  }

  const pathname = `/services/${encodeURI(serviceId)}`;

  return (
    <React.Fragment>
      {
        (props.showHeader) && <SingleServiceHeaderContainer data-testid={SingleServiceExpansionContainerTestId.header} serviceId={fixFormatServiceId(props.serviceId)} />
      }

      <SingleServiceHeader
        userAbilities={userAbilities}
        headerText={""}
        href={getRmsServiceDetailUrl(pathname)}
        // @ts-ignore
        service={serviceMetadata}
        selectedOption={selectedOption}
        serviceCategoriesWithCurrent={serviceCategoriesWithCurrent}
        handleCategorySelectionChange={handleCategorySelectionChange}
        updateCategoryButtonDisabled={updateCategoryButtonDisabled}
        onSubmit={header_onSubmit}
      />

      <SpaceBetween size={"l"} direction={"vertical"}>
        <Container disableContentPaddings={true}>
          <SingleServiceNotCompletedTable
            regions={regions}
            items={notCompletedInGaRegions as IServicePlan[]}
            plan={plan as string}
            userAbilities={userAbilities}
            nameSortable={nameSortable as string}
            serviceName={serviceId}
            refreshItems={onRefreshItems}
            loading={loading}
            onEnterEstimatedDateSubmit={planDao.updateEstimatedDate.bind(planDao)}
            onBusinessCaseOverrideSubmit={planDao.updateBusinessCaseOverride.bind(planDao)}
          />
        </Container>


        <Container disableContentPaddings={true}>
          <SingleServiceCompletedTable
            regions={regions}
            items={complete as IServicePlan[]}
            serviceName={serviceId}
            loading={loading}
          />
        </Container>
      </SpaceBetween>
    </React.Fragment>
  );

}
