import * as React from "react";
import { FunctionComponent, useCallback, useState } from "react";
import { Grid, Button, Input } from "@amzn/awsui-components-react-v3";
import { IEditLaunchFormFieldsProps, } from "../../../EditLaunchForm.types";
import { setTagAndValidate } from "./EditLaunchTags.helpers";
import { getDataTestIdProp } from "../../../../../../utils/testUtil";

export const EditLaunchTagsTestIdSuffixes = {
  input: "-date",
  button: "-button",
};

export const EditLaunchTags: FunctionComponent<IEditLaunchFormFieldsProps> = (props) => {
  const [newTag, setNewTag] = useState("");
  const [invalid, setInvalid] = useState(false);

  const { control } = props;

  const addTag = useCallback(async (event) => {
    event.preventDefault();
    if(!invalid && newTag.length > 0){
      control.setValue("tags", [...(control.getValues("tags") ?? []), newTag]);
      setNewTag("");
    }
  }, [control, invalid, newTag]);

  return (
    <>
      <Grid gridDefinition={[{ colspan: 3 }, { colspan: 6 }]}>
        <Input
          onChange={({ detail }) => setTagAndValidate(detail, setNewTag, setInvalid)}
          value={newTag}
          invalid={invalid}
          {...getDataTestIdProp(props, EditLaunchTagsTestIdSuffixes.input)}
        />
        <Button onClick={addTag} variant="primary" {...getDataTestIdProp(props, EditLaunchTagsTestIdSuffixes.button)}>Add Tag</Button>
      </Grid>
    </>
  );
};
