import styled from "styled-components";

const HorizontalBar = styled.hr`
  border: 1px solid rgb(234, 237, 237);
  margin-block-start: 0.5em;
  margin-block-end: 1.25em;
  margin-inline-start: -2rem;
  margin-inline-end: -2rem;
`;

export default HorizontalBar;
