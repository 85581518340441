import {
  REQUIRED_AND_VALID_CHAR_REGEX,
  VALID_ALIAS_CHAR_REGEX_AND_MAXLENGTH, VALID_RIP_CONTACTS_ENTRY
} from "./AddOrEditServiceContactModalConstants";
import {
  notificationsValidation,
  partitionValidation,
  simGuidValidation
} from "../BulkEditServiceContactWizard/BulkEditServiceContactWizardConstants";
import {
  IAddOrEditFormFieldsValidationListCTI,
  IAddOrEditFormFieldsValidationListNonCTI,
  IAddOrEditFormFieldsValidationListPartitions,
  IAddOrEditFormFieldsValidationListServiceNames,
} from "./AddOrEditServiceContactModal.types";

export const serviceNameFormFields: IAddOrEditFormFieldsValidationListServiceNames = {
  buildTask: {
    placeholder: "Build Task",
    id: "buildTask",
    validation: REQUIRED_AND_VALID_CHAR_REGEX,
  },
  ripShortname: {
    placeholder: "Rip Shortname",
    id: "ripShortname",
    validation: REQUIRED_AND_VALID_CHAR_REGEX,
  },
}

export const partitionFormFields: IAddOrEditFormFieldsValidationListPartitions = {
  partition: {
    placeholder: "Partition",
    id: "partition",
    validation: {
      pattern: {
        value: partitionValidation.pattern,
        message: "Invalid character. Entry must contain only lowercase letters and hyphens.",
      }
    }
  },
}

export const nonCTIFormFields: IAddOrEditFormFieldsValidationListNonCTI = {
  azServiceOwner: {
    placeholder: "AZ Service Owner",
    id: "azServiceOwner",
    validation: VALID_ALIAS_CHAR_REGEX_AND_MAXLENGTH
  },
  localZoneServiceOwner: {
    placeholder: "Local Zone Service Owner",
    id: "localZoneServiceOwner",
    validation: VALID_ALIAS_CHAR_REGEX_AND_MAXLENGTH
  },
  notifications: {
    placeholder: "Notification Alias",
    id: "notifications",
    validation: {
      pattern: {
        value: notificationsValidation.pattern,
        message: "Invalid character. Entry must contain only lowercase letters and hyphens.",
      }
    }
  },
  primaryBuildPoc: {
    placeholder: "Primary Build Poc",
    id: "primaryBuildPoc",
    validation: VALID_ALIAS_CHAR_REGEX_AND_MAXLENGTH
  },
  rbtServiceManager: {
    placeholder: "Build TPM",
    id: "rbtServiceManager",
    validation: VALID_ALIAS_CHAR_REGEX_AND_MAXLENGTH
  },
  secondaryBuildPoc: {
    placeholder: "Secondary Build POC",
    id: "secondaryBuildPoc",
    validation: VALID_ALIAS_CHAR_REGEX_AND_MAXLENGTH
  },
  simGuid: {
    placeholder: "SIM GUID",
    id: "simGuid",
    validation: {
      pattern: {
        value: simGuidValidation.pattern,
        message: "Invalid character. Entry must contain only lowercase letters, numbers, and hyphens.",
      }
    }
  },
  vpceBuildOwner: {
    placeholder: "VPCE Build Owner",
    id: "vpceBuildOwner",
    validation: VALID_ALIAS_CHAR_REGEX_AND_MAXLENGTH
  },
  wikiUrl: {
    placeholder: "Wiki URL",
    id: "wikiUrl",
    validation: {
      pattern: {
        value: /^https:\/\/w.amazon.com\/bin\/view\/[^\s]+$/,
        message: "Invalid Wiki link. Wiki link must start with https://w.amazon.com/bin/view/ and must not contain any whitespace",
      }
    }
  },
  oncallTeam: {
    placeholder: "Oncall Team",
    id: "oncallTeam",
    validation: {
      pattern: {
        value: /^[a-z0-9\-_]+$/,
        message: "Invalid oncall team. Entry must contain only lowercase letters, numbers, dash, and underscores.",
      }
    }
  },
  ripContacts: {
    placeholder: "Rip Contacts",
    id: "ripContacts",
    validation: {
      pattern: {
        value: VALID_RIP_CONTACTS_ENTRY,
        message: "Invalid Rip contact. Entry must be comma seperated and only contain letters, numbers, hyphen, underscore and plus.",
      }
    }
  },
  opsDashboard: {
    placeholder: "Operational Dashboard",
    id: "opsDashboard",
    validation: {
      pattern: {
        value: /^https?:\/\/[^\s]+$/,
        message: "The dashboard link must either begin with http:// or https:// and must not contain any whitespace",
      }
    }
  },
}

export const CTIContactFormFields: IAddOrEditFormFieldsValidationListCTI = {
  category: {
    placeholder: "Category",
    id: "category",
    validation: REQUIRED_AND_VALID_CHAR_REGEX
  },
  type: {
    placeholder: "Type",
    id: "type",
    validation: REQUIRED_AND_VALID_CHAR_REGEX
  },
  item: {
    placeholder: "Item",
    id: "item",
    validation: REQUIRED_AND_VALID_CHAR_REGEX
  },
}
