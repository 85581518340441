import React, { FunctionComponent } from "react";
import Button from "@amzn/awsui-components-react-v3/polaris/button";
import { CtiTicketButtonProps } from "./CtiTicketButton.types";
import { CtiVO } from "../../models/vos/CtiVO";
import { getDataTestIdProp } from "../../utils/testUtil";
import { Optional } from "../../models/types/Optional";
import { stringify } from "query-string"


export const CtiTicketButtonTestIdSuffix = {
  newTicketButton : "-newTicketButton"
}

export function generateCtiLink(cti: Optional<CtiVO>): string {

  if (cti !== undefined) {
    return "https://t.corp.amazon.com/create/options?" + stringify( cti, { sort: false })
  }

  return ""
}

export const CtiTicketButton: FunctionComponent<CtiTicketButtonProps> = (props) => {
  return (
    <Button
      {...getDataTestIdProp(props, CtiTicketButtonTestIdSuffix.newTicketButton)}
      disabled={!props.cti || props.cti === undefined}
      href={generateCtiLink(props.cti)}
      iconAlign="right"
      iconName="external"
      target="_blank"
      variant="primary"
    >
      New Ticket
    </Button>
  );
};
