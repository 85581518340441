import { QaItemDefinition } from "../../models/QaItemDefinition";
import { updateServicePlanOperation } from "../../remoteOperations/serviceOperation";
import { noAccessChecker } from "../../checkers/noAccessChecker";
import {
  removeEstimatedDateOperation,
  updateBusinessCaseOverrideInGAOperation,
  updateEstimatedDateOperation
} from "../../remoteOperations/planOperation";

export const cannotUpdateServicePlan = new QaItemDefinition<any>(
  "PATCH /api/services update service category/plan should be rejected",
  updateServicePlanOperation,
  noAccessChecker
);

export const cannotUpdateBusinessCaseOverride = new QaItemDefinition<any>(
  "PATCH /api/services/.../plans/... update business case override should be rejected",
  updateBusinessCaseOverrideInGAOperation,
  noAccessChecker
);

export const cannotUpdateEstimatedDate = new QaItemDefinition<any>(
  "PATCH /api/services/.../plans/... update estimated date should be rejected",
  updateEstimatedDateOperation,
  noAccessChecker
);

export const cannotRemoveEstimatedDate = new QaItemDefinition<any>(
  "PATCH /api/services/.../plans/... remove estimated date should be rejected",
  removeEstimatedDateOperation,
  noAccessChecker
);
