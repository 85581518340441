import { getMidwayApiClient, getReconApiClient } from "./daoUtil";
import {
  GetTaggedFeaturePlansResponseObject,
  IFeatureParityRaw, ITaggedFeaturePlan,
  ReconFeatureComposed,
  ReconFeatureRegionInstance,
  RipFeatureMetadata,
  RipGQLDataResponse
} from "./types/FeaturesDaoTypes";
import { PlanDao } from "./PlanDao";
import { IGetServiceComponentsResponse, IServiceComponentDto } from "./types/IGetServiceComponentsResponse";
import { AxiosInstance } from "axios";

export const parseAllFeatureParityTableData = (data: RipGQLDataResponse): ReconFeatureComposed[] => {
  const combinedData: ReconFeatureComposed[] = [];
  const featureMetadatas = data.metadata;
  const featureInstances = data.instances;
  featureMetadatas.forEach(feature => {
    if (!isIntegFeature(feature)) {
      const featureId = feature.ripId;
      const parentId = feature.belongsToService;
      const foundInstances = featureInstances.filter(item => item.ripId === featureId && item.parentId === parentId);

      const convertedInstances = foundInstances.map(item => {
        const converted: ReconFeatureRegionInstance = {
          "region": item.belongsToDimensionName,
          "status": item.status
        };

        if (item?.estimatedLaunchDate) {
          converted.estLaunchDate = item.estimatedLaunchDate; // "2021-04-22T00:00:00.000Z"
        }

        return converted;
      });

      combinedData.push({
        "id": featureId,
        "longName": feature.longName,
        "parentId": feature.belongsToService,
        "visibility": feature.visibility,
        "type": "Feature",
        "regions": [
          ...convertedInstances
        ]
      });
    }
  });

  return combinedData;
};

export const isIntegFeature = (feature : RipFeatureMetadata): boolean => {
  return feature?.longName?.includes("Integ Test ")
}

export const extractRelevantReconData = (allTaggedFeaturePlans: GetTaggedFeaturePlansResponseObject, taggedFeatureToParentMap) => {
  const extractedData: ReconFeatureComposed[] = [];
  allTaggedFeaturePlans.forEach(serviceAsFeature => {
    const id = serviceAsFeature.service.instance.split(":")[0].replace("/", "+");
    const longName = serviceAsFeature.service.nameLong ?? id;
    const visibility = serviceAsFeature.service.visibility;
    const convertedRegions: any = [];
    serviceAsFeature.plans.forEach((item) => {
      convertedRegions.push({
        "region": item.belongsToInstance,
        "status": item.status
      });
    });

    const parentId = taggedFeatureToParentMap[id];

    extractedData.push({
      id,
      longName,
      parentId,
      visibility,
      type: "Service",
      regions: convertedRegions
    })
  });

  return extractedData;
}

export const combineRIPFeaturesAndReconFeatures = (allRipFeatureDataCombined: ReconFeatureComposed[], extractedReconFeatureData: ReconFeatureComposed[]): ReconFeatureComposed[] => {
  return allRipFeatureDataCombined.concat(extractedReconFeatureData);;
}

export const convertTaggedFeaturesToParentMap = (allTaggedFeatures) => {
  const taggedFeatureToParentMap: Map<string, string> = new Map();
  allTaggedFeatures.forEach(taggedFeature => {
    let child;
    if (taggedFeature.ripTopLevelServiceName !== undefined) {
      child = taggedFeature.ripTopLevelServiceName;
    } else {
      child = taggedFeature.ripComponentServiceName;
    }

    const localParent = taggedFeature.ripServiceName;
    taggedFeatureToParentMap[child] = localParent;
  });

  return taggedFeatureToParentMap;
}


export class FeaturesDao {
  async getAllTaggedFeatureplans(): Promise<any> {
    const client = getReconApiClient();
    const res = await client.get<IGetServiceComponentsResponse>("/api/taggedfeatures/plans");
    return res.data;
  }

  async getAllFeatureParityTableData(): Promise<ReconFeatureComposed[]> {
    // Part 1 get all rip Feature Data (219 features returned)
    const client = getReconApiClient();
    const res = await client.get(this.getFeaturesUrl());
    const allRIPFeatureData = parseAllFeatureParityTableData(res.data);

    // Part 2 - get all parents to children map
    const planDao = new PlanDao();
    const allTaggedFeatures = await planDao.getAllTaggedFeatureComponents();
    const taggedFeatureToParentMap = convertTaggedFeaturesToParentMap(allTaggedFeatures);


    // Part 3 - get all toplvl+components tagged as features from Recon (seeing 246 features returned)
    const allTaggedFeaturePlans : GetTaggedFeaturePlansResponseObject = await this.getAllTaggedFeatureplans();
    const allReconFeatureData = extractRelevantReconData(allTaggedFeaturePlans, taggedFeatureToParentMap);


    // Part 4 - combine recon and rip data (219 + 246)
    const ripAndReconFeatureData = combineRIPFeaturesAndReconFeatures(allRIPFeatureData, allReconFeatureData);
    return ripAndReconFeatureData;
  }

  async getFeatureParityRawData(): Promise<IFeatureParityRaw> {
    const client = getReconApiClient();
    const featureListPromise: Promise<{ data: RipGQLDataResponse }> = client.get(this.getFeaturesUrl());

    const planDao = new PlanDao();
    const taggedFeaturesPromise: Promise<IServiceComponentDto[]> = planDao.getAllTaggedFeatureComponents();

    const taggedFeaturePlansPromise: Promise<ITaggedFeaturePlan[]> = this.getAllTaggedFeatureplans();

    // Use Promise.all so all the calls are made in parallel
    return await Promise.all([
      featureListPromise,
      taggedFeaturesPromise,
      taggedFeaturePlansPromise,
    ]).then(([
      features,
      taggedFeatures,
      taggedFeaturePlans
    ]) => {
      return {
        features: features.data,
        taggedFeatures,
        taggedFeaturePlans,
      };
    });
  }

  async getFeatureParityOverride(): Promise<string> {
    const client: AxiosInstance = getMidwayApiClient();
    const res = await client.get<string>("/static-reference-data/maps/featureParityOverride.csv");
    return res.data;
  }


  getFeaturesUrl(): string {
    return "/api/features";
  }
}

