import { getReconApiClient } from "./daoUtil";
import { IGetServiceByRegionReportResponse } from "./types/IGetServiceByRegionReportResponse";
import { IServiceInRegionReportOption } from "../models/ServiceInRegionReportOptions";
import { b64toBlob } from "../utils/reportsUtils";
import { MIME_TYPE_XLSX } from "../constants";
import { saveAs } from "file-saver";

export class ServiceByRegionReportDao {
  /**
   * Get report specified by template type
   */
  async getServiceByRegionReport(reportType: IServiceInRegionReportOption, filename: string): Promise<IGetServiceByRegionReportResponse> {
    const client = getReconApiClient();
    const res = await client.get<IGetServiceByRegionReportResponse>(this.getServiceByRegionReportUrl(reportType));
    const data: string = res.data;
    const blob = b64toBlob(data, MIME_TYPE_XLSX);
    saveAs(blob, filename);
    return data;
  }

  getServiceByRegionReportUrl(reportType: IServiceInRegionReportOption): string {
    return `/api/reports/servicesbyregion?type=${encodeURIComponent(reportType.value)}`
  }
}
