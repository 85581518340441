import { css } from "styled-components";

/**
 * Mac OSX hides scrollbars when user is using trackpad, which can be counter-intuitive because users would not be able
 * to tell if the content is scrollable or not.  This CSS overrides the behavior by recreating the scrollbar.
 * Credit:
 * https://stackoverflow.com/questions/7855590/preventing-scroll-bars-from-being-hidden-for-macos-trackpad-users-in-webkit-blin
 */
export const macScrollOverride = css`
  &::-webkit-scrollbar {
    -webkit-appearance: none;
    &:vertical {
      width: 11px;
    }
    &:horizontal {
      height: 11px;
    }
  }
  
  &::-webkit-scrollbar-thumb {
    border-radius: 8px;
    border: 2px solid white; /* should match background, can't be transparent */
    background-color: rgba(0, 0, 0, .5);
  }
`;
