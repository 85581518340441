import * as React from "react";
import { ResourceParityLoaderContainerProps } from "./ResourceParityLoaderContainer.types";
import { ParityDao } from "../../daos/ParityDao";
import { RegionDao } from "../../daos/RegionDao";
import { ResourceParityDao } from "../../daos/ResourceParityDao";
import { LoadStateEnum } from "../../models/LoadStateEnum";
import { useSelector } from "react-redux";
import { serviceListSelector, serviceLoadStateSelector } from "../../redux/selectors/serviceSelector";
import { AwsServiceVO } from "@amzn/api-parity-react-component";
import { ResourceParityLoader } from "../../components/Parity/ResourceParityLoader/ResourceParityLoader";

const parityDao: ParityDao = new ParityDao();
const regionDao: RegionDao = new RegionDao();
const resourceParityDao: ResourceParityDao = new ResourceParityDao();

export const ResourceParityLoaderContainer: React.FunctionComponent<ResourceParityLoaderContainerProps> = (props) => {

  const serviceListLoadState: LoadStateEnum = useSelector(serviceLoadStateSelector);
  const serviceList: AwsServiceVO[] = useSelector(serviceListSelector);

  return <ResourceParityLoader
    getServiceParities={parityDao.getParity.bind(parityDao)}
    getRegions={regionDao.getRegions.bind(regionDao)}
    getResourceParities={resourceParityDao.getParities.bind(resourceParityDao)}
    getRipIdMap={resourceParityDao.getCloudFormationIdMap.bind(resourceParityDao)}
    serviceList={serviceList}
    serviceListLoadState={serviceListLoadState}
    onDataLoaded={props.onDataLoaded}
  />
}
