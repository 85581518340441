import { LabelWithValue } from "./FeatureInRegionCommentModal.types";
import { FeatureInRegionCommentVO, Reason } from "../../models/vos/FeatureInRegionCommentVO";

export const MAX_BULK_SELECT_FEATURES = 5
export const VALIDATE_SELECTED_FEATURES = (features: LabelWithValue[]): boolean => !!features.length && features.length <= MAX_BULK_SELECT_FEATURES

export const getCommentsToSubmit = (features: LabelWithValue[],
                                    regions: string[],
                                    service: string,
                                    sim: string,
                                    note: string,
                                    reason: Reason,
                                    whatsNewLink: string): FeatureInRegionCommentVO[] => {

  const commentsToSubmit: FeatureInRegionCommentVO[] = [];

  features.forEach(feature => {
    regions.forEach(region => {
      commentsToSubmit.push(
        new FeatureInRegionCommentVO({
          feature: feature.value,
          region,
          service,
          sim,
          note,
          reason,
          whatsNewLink,
        })
      )
    })
  })

  return commentsToSubmit;

}
