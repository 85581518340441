import * as React from "react";
import { FunctionComponent, useCallback, useEffect, useState } from "react";
import { ColumnLayout, SpaceBetween } from "@amzn/awsui-components-react-v3";
import { Optional } from "@amzn/react-bot";
import { Displayable } from "@amzn/api-parity-react-component/lib/models/Displayable";
import { IFormFieldData } from "../../../../common/FormTypes";
import { getDataTestIdProp } from "../../../../../utils/testUtil";
import { editLaunchFormFields } from "../../EditLaunchFormFields";
import { ISelectableFormInput, } from "../../EditLaunchForm.types";
import {
  IndividualFormFieldWithController
} from "../../../../IndividualFormFieldWithController/IndivudalFormFieldWithController";
import { FormFieldTypes } from "../../../../IndividualFormFieldWithController/IndividualFormFieldWithController.types";
import { WarningText } from "../../../../ProductLaunches/LaunchWizard/LaunchWizard";
import { EditLaunchTags } from "./EditLaunchTags/EditLaunchTags";
import { IEditLaunchPropertiesFormFieldsProps } from "./EditLaunchPropertiesForm.types";
import { isOnBlockedDay } from "./EditLaunchPropertiesForm.helpers";

export const EditLaunchPropertiesFormFieldsTestIdSuffixes = {
  date: "-date",
  description: "-description",
  tags: "-tags",
  state: "-launchState",
  status: "-status",
  disclosureLevel: "-disclosureLevel",
  trackerLink: "-trackerLink",
  dateWarning: "-dateWarning",
};

export const EditLaunchPropertiesFormFields: FunctionComponent<IEditLaunchPropertiesFormFieldsProps> = (props) => {

  const { control, errors, dateWatch } = props;
  const [dateWarning, setDateWarning] = useState<false | string>(isOnBlockedDay(props.blockedDates, props.control.getValues().date))

  useEffect(() => {
    setDateWarning(isOnBlockedDay(props.blockedDates, dateWatch))
  }, [props.blockedDates, dateWatch])

  // instantiates field in form and hooks it up to parent react-hook-form via control + errors
  const getFormField = useCallback((formField: IFormFieldData, fieldType: FormFieldTypes, defaultValue: Optional<string> | string[] | ISelectableFormInput, info?: Displayable) => {
    return <IndividualFormFieldWithController control={control}
                                              errors={errors}
                                              formField={formField}
                                              defaultValue={defaultValue}
                                              popoverInfo={info}
                                              fieldType={fieldType}
                                              {...getDataTestIdProp(props, EditLaunchPropertiesFormFieldsTestIdSuffixes[formField.id])}
    />;
  }, [control, errors, props]);

  return (
    <>
      <ColumnLayout columns={3}>
        <SpaceBetween direction="vertical" size="xs">
          {getFormField(editLaunchFormFields.date, FormFieldTypes.DATE_ENTRY, control.getValues().date)}
          {dateWarning !== false &&
            <WarningText {...getDataTestIdProp(props, EditLaunchPropertiesFormFieldsTestIdSuffixes.dateWarning)}>{dateWarning}</WarningText>}
        </SpaceBetween>
        {getFormField(editLaunchFormFields.state, FormFieldTypes.SELECT_ENTRY, control.getValues().state)}
        {getFormField(editLaunchFormFields.status, FormFieldTypes.SELECT_ENTRY, control.getValues().status)}
        {getFormField(editLaunchFormFields.description, FormFieldTypes.TEXT_ENTRY, control.getValues().description)}
        {getFormField(editLaunchFormFields.trackerLink, FormFieldTypes.INPUT_ENTRY, control.getValues().trackerLink)}
        {getFormField(editLaunchFormFields.disclosureLevel, FormFieldTypes.SELECT_ENTRY, control.getValues().disclosureLevel)}
        {getFormField(editLaunchFormFields.tags, FormFieldTypes.TOKEN_ENTRY, control.getValues().tags)}
      </ColumnLayout>
      <EditLaunchTags control={control} errors={errors}/>
    </>
  );
};
