import React, { FunctionComponent, useEffect, useState } from "react";
import {
  Box,
  Button,
  Container,
  Grid,
  Header,
  Modal,
  Pagination,
  PropertyFilter,
  PropertyFilterProps,
  SpaceBetween,
  Table,
  TableProps
} from "@amzn/awsui-components-react-v3";
import styled from "styled-components";
import { useCollection } from "@amzn/awsui-collection-hooks";
import { LaunchHistoryProps } from "./LaunchHistory.types";
import { getDataTestIdProp } from "../../../../utils/testUtil";
import { ChangeVO, ReducedChangeVO } from "../../../../models/vos/ChangeVO";
import { displayOr } from "../../../../utils/displayUtil";
import { formatLaunchDate } from "../../launchDetailsUtils";
import { ValueWithLabel } from "../../../common/ValueWithLabel/ValueWithLabel";
import User from "../../../common/User";
import { DEFAULT_I18N_STRINGS } from "../../../ProductsList/ProductsListProperties";
import { filterChanges, generateFilterOptions, sortChanges } from "./LaunchHistoryUtil";

export const LaunchHistoryTestIdSuffixes = {
  table: "-table",
  modal: "-modal",
  testing: "-testing",
}

const TableHeader = ({ count }) => (
  <Header
    variant="h2"
    counter={
      "(" + count + ")"
    }
  >
    History
  </Header>
);

const HiddenOverflowTable = styled(Table)<TableProps>`
  table {
    overflow: hidden;
  }
`;

const LaunchSubChangesTable = ({ changes }) => {
  const { items, collectionProps, paginationProps } = useCollection(
    changes,
    {
      pagination: {
        pageSize: 5,
        defaultPage: 0
      }
    }
  )

  return (
    <HiddenOverflowTable
      items={items}
      sortingDisabled
      wrapLines
      pagination={
        <Pagination
          {...paginationProps}
        />
      }
      empty={
        <Box textAlign="center" color="inherit">
          <b>No resources</b>
          <Box
            padding={{ bottom: "s" }}
            variant="p"
            color="inherit"
          >
            No resources to display.
          </Box>
        </Box>
      }
      columnDefinitions={[
        {
          id: "target",
          header: "Field name",
          cell: (item: ChangeVO) => item.target
        },
        {
          id: "previous",
          header: "Previous value",
          cell: (item: ChangeVO) => typeof item.oldValue === "object"
            ? JSON.stringify(item.oldValue)
            : displayOr(item.oldValue)
        },
        {
          id: "new",
          header: "New value",
          cell: (item: ChangeVO) => typeof item.newValue === "object"
            ? JSON.stringify(item.newValue)
            : displayOr(item.newValue)
        }
      ]}
      {...collectionProps}
    />
  );
}

const LaunchHistoryModal = ({ visible, onDismiss, details, ...props }) => (
  <Modal
    visible={visible}
    onDismiss={onDismiss}
    closeAriaLabel="Close modal"
    size="max"
    header="Details"
    {...getDataTestIdProp(props)}
  >
    <>
      <Container>
        <Grid
          gridDefinition={[
            { colspan: 4 },
            { colspan: 8 },
            { colspan: 12 },
            { colspan: 12 },
          ]}
        >
          <SpaceBetween size="m">
            <ValueWithLabel label="Modified by">
              {displayOr(
                details.whoChanged,
                () =>
                  <User alias={details.whoChanged!}/>
              )}
            </ValueWithLabel>
          </SpaceBetween>
          <SpaceBetween size="m">
            <ValueWithLabel label="Reason for change">
              {displayOr(details.reason)}
            </ValueWithLabel>
            <ValueWithLabel label="Notes">
              {displayOr(details.description)}
            </ValueWithLabel>
          </SpaceBetween>
        </Grid></Container>
      <LaunchSubChangesTable changes={details.changes}/>
    </>
  </Modal>
);

const filteringProperties: PropertyFilterProps.FilteringProperty[] = [
  {
    key: "target",
    operators: ["=", "!="],
    propertyLabel: "Field",
    groupValuesLabel: "Fields"
  },
  {
    key: "whoChanged",
    operators: ["=", "!="],
    propertyLabel: "Modified by",
    groupValuesLabel: "Modified by"
  }
];

export const LaunchHistory: FunctionComponent<LaunchHistoryProps> = (props) => {
  const [showModal, setShowModal] = useState(false);
  const [changeDetails, setChangeDetails] = useState<ReducedChangeVO>(new ReducedChangeVO());
  const [changes, setChanges] = useState<ReducedChangeVO[]>([]);
  const [filteringOptions, setFilteringOptions] = useState<PropertyFilterProps.FilteringOption[]>([]);
  const [sortDescending, setSortDescending] = useState<boolean>(false);

  const { changes:data, loading } = props;

  const columnDefinitions = [
    {
      id: "target",
      header: "Fields changed",
      cell: item => item.changes.map(elm => elm.target).join("; ")
    },
    {
      id: "date",
      header: "Date modified",
      cell: item => formatLaunchDate(item.timeChanged),
      sortingField: "timeChanged",
      sortingComparator: (left: ReducedChangeVO, right: ReducedChangeVO) => {
        if (new Date(left.timeChanged) > new Date(right.timeChanged))
          return -1;

        if (new Date(left.timeChanged) < new Date(right.timeChanged))
          return 1;

        return 0;
      }
    },
    {
      id: "modifier",
      header: "Modified by",
      cell: item => displayOr(
        item.whoChanged,
        () => <User alias={item.whoChanged!}/>
      ),
      sortingField: "whoChanged"
    },
    {
      id: "details",
      header: "Details",
      cell: item => {
        return (
          <Button
            onClick={() => {
              setShowModal(true);
              setChangeDetails(item);
            }}
          >
            Info
          </Button>
        )
      },
      minWidth: 120,
    }
  ];

  const [sortingColumn, setSortingColumn] = useState<TableProps.SortingColumn<ReducedChangeVO>>(columnDefinitions[1]);

  const [query, setQuery] = useState<PropertyFilterProps.Query>({
    tokens: [],
    operation: "and"
  });


  useEffect(() => {
    let parsedChanges = filterChanges(data, query, filteringProperties);
    parsedChanges = sortChanges(parsedChanges, sortingColumn, sortDescending);

    setChanges(parsedChanges);
  }, [data, query, sortDescending, sortingColumn]);

  useEffect(() => {
    const newFilteringOptions = generateFilterOptions(data);
    setFilteringOptions(newFilteringOptions);
  }, [data]);

  return (
    <>
      <LaunchHistoryModal
        visible={showModal}
        onDismiss={() => setShowModal(false)}
        details={changeDetails}
        {...getDataTestIdProp(props, LaunchHistoryTestIdSuffixes.modal)}
      />
      <Table
        items={changes}
        loading={loading}
        header={<TableHeader count={data.length ?? 0} />}
        filter={<PropertyFilter
          i18nStrings={DEFAULT_I18N_STRINGS}
          query={query}
          onChange={({ detail }) => setQuery(detail)}
          filteringProperties={filteringProperties}
          filteringOptions={filteringOptions}
          countText={`${changes.length} matches`}
        />}
        sortingColumn={sortingColumn}
        columnDefinitions={columnDefinitions}
        sortingDescending={sortDescending}
        onSortingChange={({ detail }) => {
          setSortDescending(detail.isDescending as boolean);
          setSortingColumn(detail.sortingColumn);
        }}
        empty={
          <Box textAlign="center" color="inherit">
            <b>No changes</b>
            <Box
              padding={{ bottom: "s" }}
              variant="p"
              color="inherit"
            >
              No changes to display.
            </Box>
          </Box>
        }
        {...getDataTestIdProp(props, LaunchHistoryTestIdSuffixes.table)}
      />
    </>
  );
};
