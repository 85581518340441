import { useState } from "react";
import * as React from "react";
import PivotTableUI from "react-pivottable/PivotTableUI";
import "react-pivottable/pivottable.css";
import { useQueryParams, StringParam } from "use-query-params";

export const PivotTableContainer = (props) => {
  const [query, setQuery] = useQueryParams({
    "rows": StringParam,
    "cols": StringParam,
    "aggregator":StringParam,
    "val": StringParam
  });

  const [extraProps, setExtraProps] = useState(props.data?{
    rows: query.rows?.split(",")[0] === ""? []:query.rows?.split(","),
    cols: query.cols?.split(",")[0] === ""? []:query.cols?.split(","),
    aggregatorName: query.aggregator?.split("+").join(" "),
    vals: [query.val]
  }: {});

  const setURL = () => {
    setQuery({
      rows: PivotTableUI.rows?PivotTableUI.rows:null,
      cols: PivotTableUI.cols?PivotTableUI.cols:null,
      aggregator: PivotTableUI.aggregatorName?PivotTableUI.aggregatorName:null,
      val: PivotTableUI.vals?PivotTableUI.vals:null
    })
  }

  return (
    <div>
      <button onClick={setURL}>Click to Generate URL</button>
      <div>
        <p>Examples:</p>
        <ul>
          <li><a href={"/pivot?aggregator=First&cols=regionStatus%2Cregion&rows=service&val=serviceLaunchDate"}>Service x Region and Region Status</a></li>
        </ul>
      </div>
      <PivotTableUI
        data={props.data}
        onChange={ (event) => {
          PivotTableUI.rows = event.rows;
          PivotTableUI.cols = event.cols;
          PivotTableUI.aggregatorName = event.aggregatorName;
          PivotTableUI.vals = event.vals;

          setExtraProps(event);
        }}
        {...extraProps}
      />
    </div>
  )
}

export default PivotTableContainer;
