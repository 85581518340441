export const charCount = (str: string, char: string) => {
  if (char.length !== 1) {
    throw TypeError(`char should only have one letter, received ${char}`);
  }

  let count = 0;

  for (let i = 0; i < str.length; i++) {
    if (char === str[i]) {
      count += 1;
    }
  }

  return count;
}
export const includesIgnoreCase = (item1: string, search: string): boolean => {
  return item1.toLowerCase().includes(search);
};

export type MatchNodeFn = (...values: string[]) => boolean;

/**
 * Return a function that always return true regardless of any string array passed to it
 * @param values
 */
export const alwaysMatch: MatchNodeFn = (...values: string[]) => true;

/**
 * Return a function which takes an array of string, and if any of string contains any of the term
 * the supplied terms, it would return true
 * @param terms
 */
export const matchAnyTerm = (terms: string[]): MatchNodeFn => {
  return (...values: string[]) => {
    return terms.some((term: string) => {
      if (term) {
        return values.some((val) => includesIgnoreCase(val, term));
      }
      return false;
    });
  };
};
