import * as React from "react";
import { Modal } from "@amzn/awsui-components-react";
import { Alert } from "@amzn/awsui-components-react-v3";

export function PermissionError({ header, body, visible, onDismiss, testIds }) {
	return (
		<Modal
			visible={visible}
			header={header} 
			onDismiss={onDismiss}
			data-testid={testIds.modal}
		>
			<Alert data-testid={testIds.alert} type="error">
				{body}
			</Alert>
		</Modal>
	)
}
