import * as React from "react";
import {
  EMAIL_US_URL,
  REQUEST_A_FEATURE_URL,
  REPORT_A_BUG_URL,
  MAILING_LIST_URL,
  FAQ_URL,
  ROADMAP_URL,
} from "../../constants";
import { ExplainAbilities } from "./ExplainAbilities";
import { ColumnLayout, Container, Grid, Icon, Link, SpaceBetween } from "@amzn/awsui-components-react-v3";
import styled from "styled-components";

const Content = styled.div`
  margin: 3rem 8rem;
`

const gridDefinition = [
  { colspan: { default: 12, xxs: 6 } },
  { colspan: { default: 12, xxs: 6 } },
];

const HelpColumnContainer = ({ title, children }) => (
  <Container
    header={<h2>{title}</h2>}>
    {children}
  </Container>
);

const HelpLink = ({ icon, text, url }) => (
  <SpaceBetween direction={"horizontal"} size={"xs"}>
    <Icon
      size="normal"
      variant="subtle"
      name={icon}
      alt={text}
    />
    <Link href={url} external={true}>{text}</Link>
  </SpaceBetween>
);

function HomeContent() {
  return (
    <Content>
      <SpaceBetween size={"xl"} direction={"vertical"}>
        <Grid gridDefinition={gridDefinition}>
          <HelpColumnContainer title="Contact Us">
            <ColumnLayout borders="horizontal">
              <HelpLink icon="contact"  text="Email Us"          url={EMAIL_US_URL} />
              <HelpLink icon="edit"     text="Request a Feature" url={REQUEST_A_FEATURE_URL} />
              <HelpLink icon="bug"      text="Report a Bug"      url={REPORT_A_BUG_URL} />
              <HelpLink icon="envelope" text="Mailing List"      url={MAILING_LIST_URL} />
            </ColumnLayout>
          </HelpColumnContainer>

          <HelpColumnContainer title="Documentation">
            <ColumnLayout borders="horizontal">
              <HelpLink icon="status-info" text="FAQ"      url={FAQ_URL} />
              <HelpLink icon="calendar"    text="Roadmap"  url={ROADMAP_URL} />
            </ColumnLayout>
          </HelpColumnContainer>
        </Grid>

        <Grid gridDefinition={gridDefinition}>
          <HelpColumnContainer title="Your Permissions">
            <ExplainAbilities />
          </HelpColumnContainer>
        </Grid>
      </SpaceBetween>
    </Content>
  );
}

export default HomeContent;
