import React from "react";
import { FormSection, ColumnLayout } from "@amzn/awsui-components-react";
import { toShortDate } from "../../utils/dateUtil";
import { Spinner } from "@amzn/awsui-components-react";

export const SingleRegionDetailsTestId = {
    date: "SingleRegionDetails-date"
}

export function SingleRegionDetails({ details }) {
    return (
        <FormSection header="Region Details" expandable={true} expanded={true}>
        {details.loading ? <Spinner></Spinner> : 
			<ColumnLayout columns={3} variant="text-grid">
				<div data-awsui-column-layout-root="true">
					<div>
						<div className="awsui-util-spacing-v-s">
							<div>
								<div className="awsui-util-label">External Name</div>
								<div>{details.nameLong}</div>
							</div>
							<div>
								<div className="awsui-util-label">Location</div>
								<div>{details.description}</div>
							</div>
						</div>
					</div>
					<div>
						<div className="awsui-util-spacing-v-s">
							<div>
								<div className="awsui-util-label">Partition</div>
								<div>{details.partition}</div>
							</div>
							<div>
								<div className="awsui-util-label">RIP Build Status</div>
								<div>{details.status}</div>
							</div>
						</div>
					</div>
					<div>
						<div className="awsui-util-spacing-v-s">
							<div>
								<div className="awsui-util-label">Launch Date</div>
								<div data-testid={SingleRegionDetailsTestId.date}>{toShortDate(details.date)}</div>
							</div>
						</div>
					</div>
				</div>
			</ColumnLayout>
        }  
        </FormSection>
    );
}


