import * as React from "react";
import { FunctionComponent } from "react";
import { DetailLoaderItemProps, DetailLoaderProps } from "./DetailLoader.types";
import { Container, ErrorDetail, LineItemText } from "./DetailLoader.styles";
import { getLoadingStatusIcon } from "../../utils/loadingStatusUtil";

export const DetailLoaderTestId = {
  errorText: "DetailLoader-errorText",
}

export const DetailLoaderItem: FunctionComponent<DetailLoaderItemProps> = (props) => {
  const item = props.item;
  return (
    <React.Fragment>
      <LineItemText status={item.status}>{ getLoadingStatusIcon(item.status) }</LineItemText>
      <LineItemText status={item.status}>
        {item.label}
        { (!!item.error) && <ErrorDetail data-testid={DetailLoaderTestId.errorText}>{item.error}</ErrorDetail>}
      </LineItemText>
    </React.Fragment>
  )
}

export const DetailLoader: FunctionComponent<DetailLoaderProps> = (props) => {
  return (
    <Container>
      {
        props.items.map((item, index) => <DetailLoaderItem key={index} item={item} />
        )
      }
    </Container>
  );
};
