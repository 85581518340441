import { QaRemoteOperation } from "../models/QaRemoteOperation";
import { remoteOperationRunner } from "./remoteOperationRunner";
import { ServiceDao } from "../../daos/ServiceDao";
import { IServiceMetaData } from "../../daos/types/IServiceMetaData";
import { CATEGORY_LIST } from "../../constants";
import { PlanDao } from "../../daos/PlanDao";
import { serviceRipId, serviceRipIdCategoryChange } from "./testData";
import { IGetSingleServicePlanResponseLegacy } from "../../daos/types/IGetSingleServicePlanResponse";
import { IGenericMutationResponseDto } from "../../daos/types/IGenericMutationResponseDto";
import { Optional } from "../../models/types/Optional";

export const getServiceListOperation: QaRemoteOperation<IServiceMetaData[]> = new QaRemoteOperation<IServiceMetaData[]>(
  () => remoteOperationRunner(async () => {
    const dao: ServiceDao = new ServiceDao();
    return await dao.getServices(false);
  })
);

export const getSingleServiceOperation: QaRemoteOperation<IGetSingleServicePlanResponseLegacy> = new QaRemoteOperation<IGetSingleServicePlanResponseLegacy>(
  () => remoteOperationRunner(async () => {
    const planDao: PlanDao = new PlanDao();
    return await planDao.getSingleServicePlans(serviceRipId);
  })
);

export const updateServicePlanOperation: QaRemoteOperation<IGenericMutationResponseDto> = new QaRemoteOperation<IGenericMutationResponseDto>(
  () => remoteOperationRunner(async () => {
    const dao: ServiceDao = new ServiceDao();
    const planDao: PlanDao = new PlanDao();
    const serviceWithPlans: IGetSingleServicePlanResponseLegacy = await planDao.getSingleServicePlans(serviceRipIdCategoryChange);
    const currentPlan: Optional<string> = serviceWithPlans.serviceMetadata.plan;
    const newPlan: Optional<string> = getDifferentPlan(currentPlan);

    // TODO: Date returned from the backend is { message: success }
    // Better test should be going to retrieve service again, but there is
    // extra work around guarding eventual consistency, punt it till the first
    // round of DAO tests are done.
    return await dao.patchServicePlan(serviceWithPlans.serviceMetadata, newPlan);
  })
);

export function getDifferentPlan(currentPlan: Optional<string>): string {
  for (let plan of CATEGORY_LIST) {
    if (plan !== currentPlan) {
      return plan;
    }
  }
  // This should never happen (how can you not find a value in an array that's different from the supplied value?)
  // But in case it happens, this error should help.
  throw new Error("getDifferentPlan errors, this is caused by CATEGORY LIST has fewer than 2 different items");
}
