import {
  AwsServiceCollection,
  AwsServiceCollectionView,
  AwsServiceVO,
  ExcludeFromPercentage,
  RegionCollectionView,
  RegionVO,
  ServiceBuildPlanCollection,
  ServiceBuildPlanCollectionView,
  ServiceBuildPlanStatusEnum,
  ServiceBuildPlanVO,
} from "@amzn/api-parity-react-component";
import {
  FeatureCollection,
  FeatureCollectionView,
  FeatureParityCollection,
  FeatureParityCollectionView,
  ServiceInRegionRollupStatusCollection
} from "../models/collections";
import { RegionCollection } from "@amzn/api-parity-react-component/lib/models/collections/RegionCollection";
import { FeatureTreeItem } from "../models/FeatureTreeItem";
import { featureDataToTreeItems, getFeatureParityRollupScore, getServiceParityRollupScore } from "./featureUtil";
import { FeatureParityVO, FeatureVO, ServiceInRegionRollupStatusVO } from "../models/vos";
import { ParityStatusEnum } from "@amzn/api-parity-react-component/lib/models/ParityStatus";
import { Optional } from "../models/types/Optional";
import { computeServiceParityId } from "@amzn/api-parity-react-component/lib/utils/modelUtil";
import {
  hasException,
  isApiParityExemptFromBuildPlan,
  isInParity, isInProgressWithDate
} from "@amzn/api-parity-react-component/lib/utils/serviceBuildPlanUtil";
import { ParityRollupStatistics } from "../models/ParityRollupStatistics";
import { accumulateStatistics } from "./parityRollupStatisticsUtil";
import { getFeatureTreeItemService } from "./featureTreeItemUtil";

/**
 * Lookup data used for
 */
export interface IFeatureRollupLookup {
  services: AwsServiceCollection | AwsServiceCollectionView;
  regions: RegionCollection | RegionCollectionView;
  features: FeatureCollection | FeatureCollectionView;
  serviceParities: ServiceBuildPlanCollection | ServiceBuildPlanCollectionView;
  featureParities: FeatureParityCollection | FeatureParityCollectionView;
}

export class FeatureRollupResult {
  rollups: ServiceInRegionRollupStatusCollection = new ServiceInRegionRollupStatusCollection();
  /**
   * Features which have no availability across all regions
   */
  zeroAvailabilityFeatures: Set<FeatureVO> = new Set<FeatureVO>();

  /**
   * Services which have no availability across all regions
   */
  zeroAvailabilityServices: Set<AwsServiceVO> = new Set<AwsServiceVO>();
}

export const unavailableServiceStatus: ReadonlySet<ServiceBuildPlanStatusEnum> = new Set<ServiceBuildPlanStatusEnum>([
  ServiceBuildPlanStatusEnum.Build,
  ServiceBuildPlanStatusEnum.IA,
  ServiceBuildPlanStatusEnum.Bvpc,
  ServiceBuildPlanStatusEnum.NotPlanned,
]);

/**
 * FeatureRollup class would perform the following
 * 1. For any service, rollup all of its features' statuses in each region (bubble up)
 * 2. Locate all the services and features which are absent across all regions
 *
 * FeatureRollup prepares data for FeatureParityScoreCalculator
 * FeatureRollup should be called after applyServiceParities which is a propagate-down procoess
 */
export class FeatureRollup {
  private _result: FeatureRollupResult = new FeatureRollupResult();
  private readonly lookup: IFeatureRollupLookup

  constructor(lookup: IFeatureRollupLookup) {
    this.lookup = lookup;
    this.reset();
  }

  reset(): void {
    this._result = new FeatureRollupResult();
  }

  locateZeroAvailability(): void {
    this.lookup.features.items.forEach((feature) => {
      if (this.doesFeatureHaveZeroAvailability(feature)) {
        this.result.zeroAvailabilityFeatures.add(feature);
      }
    });

    this.lookup.services.items.forEach((service) => {
      if (this.doesServiceHaveZeroAvailability(service)) {
        this.result.zeroAvailabilityServices.add(service);
      }
    })
  }

  doesFeatureHaveZeroAvailability(feature: FeatureVO): boolean {
    const parities: readonly FeatureParityVO[] = this.lookup.featureParities.byFeature(feature);
    if (parities.length === 0) {
      return true;
    }
    return parities.every((parity) => parity.parity === ParityStatusEnum.NoData);
  }

  doesServiceHaveZeroAvailability(service: AwsServiceVO): boolean {
    const parities: readonly ServiceBuildPlanVO[] = this.lookup.serviceParities.byService(service);
    if (parities.length === 0) {
      return true;
    }
    return parities.every((parity) => !isInParity(parity) && !hasException(parity) && !isInProgressWithDate(parity));
  }

  computeFeatureParityPercentageExclusion(): void {
    this.lookup.featureParities.items.forEach((parity) => {
      const feature: FeatureVO = parity.feature;
      if (this.result.zeroAvailabilityFeatures.has(feature)) {
        parity.excludeFromPercentage = ExcludeFromPercentage.Exclude;
      } else if (
        parity.statusInferredFrom
        && parity.statusInferredFrom instanceof ServiceBuildPlanVO
        && isApiParityExemptFromBuildPlan(parity.statusInferredFrom)
      ) {
        parity.excludeFromPercentage = ExcludeFromPercentage.Exclude;
      }
    });
  }

  rollup(rootServices: AwsServiceCollectionView): void {
    this.reset();
    this.locateZeroAvailability();
    this.computeFeatureParityPercentageExclusion();

    const featureTreeRootItems: FeatureTreeItem[] = featureDataToTreeItems(
      rootServices, {
        services: this.lookup.services,
        regions: this.lookup.regions,
        features: this.lookup.features,
        parities: this.lookup.featureParities,
      }
    );

    featureTreeRootItems.forEach((rootItem) => this.rollupItemChildren(rootItem))
  }

  rollupItemChildren(item: FeatureTreeItem): void {
    this.lookup.regions.items.forEach((region) => this.rollupItemChildrenInRegion(item, region))
  }

  rollupItemChildrenInRegion(item: FeatureTreeItem, region: RegionVO): Optional<ServiceInRegionRollupStatusVO> {
    const service = getFeatureTreeItemService(item);
    if (!service) {
      return;
    }

    // already rollup
    if (this.result.rollups.byServiceRegion(service, region)) {
      return this.result.rollups.byServiceRegion(service, region);
    }

    const rollup: ServiceInRegionRollupStatusVO = this.getRollupVo(service, region);
    this.result.rollups.add(rollup);

    if (!item.childItems || item.childItems.length === 0) {
      const service = getFeatureTreeItemService(item);
      if (service && !this.result.zeroAvailabilityServices.has(service)) {
        const serviceParity = this.lookup.serviceParities.byServiceRegion(service, region);
        const parityScore = getServiceParityRollupScore(serviceParity);
        accumulateStatistics(parityScore, rollup.statistics)
      }
      return rollup
    }

    item.childItems.forEach((childItem) => {
      const child: FeatureTreeItem = childItem as FeatureTreeItem;
      const isItemRelatedToService = !!getFeatureTreeItemService(child);
      if (isItemRelatedToService) {
        const statsForService = this.rollupItemChildrenInRegion(child, region)?.statistics;
        if (statsForService) {
          accumulateStatistics(statsForService, rollup.statistics);
        }
      } else {
        const feature: FeatureVO = child.entity as FeatureVO;
        const statsForFeature = this.getFeatureParityRollupStatistics(feature, region);
        accumulateStatistics(statsForFeature, rollup.statistics);
      }
    });

    return rollup;
  }

  getFeatureParityRollupStatistics(feature: FeatureVO, region: RegionVO): ParityRollupStatistics {
    const stats: ParityRollupStatistics = new ParityRollupStatistics({
      rawTotal: 1,
    });

    if (this.result.zeroAvailabilityFeatures.has(feature)) {
      return stats;
    }

    const featureParity: Optional<FeatureParityVO> = this.lookup.featureParities.byFeatureRegion(feature, region);

    return getFeatureParityRollupScore(featureParity);
  }

  getRollupVo(service: AwsServiceVO, region: RegionVO): ServiceInRegionRollupStatusVO {
    const id: string = computeServiceParityId(service, region);
    const serviceParity: Optional<ServiceBuildPlanVO> = this.lookup.serviceParities.byServiceRegion(service, region);
    const rollup: ServiceInRegionRollupStatusVO = new ServiceInRegionRollupStatusVO({
      id,
      service,
      region,
      exception: serviceParity,
    });

    if (serviceParity) {
      if (isApiParityExemptFromBuildPlan(serviceParity)) {
        rollup.excludeFromPercentage = ExcludeFromPercentage.Exclude;
      } else {
        rollup.exception = undefined;
        if (isInParity(serviceParity)) {
          rollup.status = ParityStatusEnum.Available;
        }
      }
    }
    return rollup;
  }

  get result(): FeatureRollupResult {
    return this._result;
  }

  // get featureParities() is only being used for testing purposes
  get featureParities(): FeatureParityCollection | FeatureParityCollectionView {
    return this.lookup.featureParities
  }
}
