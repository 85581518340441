import { AxiosError, AxiosInstance } from "axios";
import { parseRegionDtoList } from "@amzn/api-parity-react-component/lib/parsers/reconDataParser";
import { RegionVO } from "@amzn/api-parity-react-component/lib/models/vos/RegionVO";
import { getMidwayApiClient, getReconApiClient, isResponseHtml } from "./daoUtil";
import { cosApiListEventStatusForOneRegionDtoSchema, ICosApiListEventStatusForOneRegionDto } from "./types/ICosApiDto";
import cosDataValidate from "./types/CosSchemaDataValidate";

import { IRegionMetadata } from "./types/IRegionMetaData";
import { Optional } from "../models/types/Optional";
import { RegionDao } from "./RegionDao";
import Ajv, { ValidateFunction } from "ajv";
import { GENERALLY_AVAILABLE } from "../constants";
import { ICecmRegionEventWithErrorDto } from "./types/ICecmRegionEventWithErrorDto";

export type RegionFetcher = () => Promise<IRegionMetadata[]>;

/**
 * A data structure representing how each regions' CECM data should be fetched
 */
export interface IRegionParityFetchByMechanism {
  fromCache: RegionVO[];
  fromCosApi: RegionVO[];
}

export enum RegionParityFetchMechanism {
  FromCache = "fromCache",
  FromCosApi = "fromCosApi",
  DoNotFetch = "doNotFetch",
}

export const CecmErrorCode = {
  invalidCosSchema: "invalidCosSchema"
}

type SchemaValidationErrorDetail = any; // TODO: add type information

export class InvalidCosSchemaError extends Error {
  public readonly code = CecmErrorCode.invalidCosSchema;
  private static defaultMessage = "Invalid COS API schema received"
  constructor(relatedEntity?: string, public readonly errors?: readonly SchemaValidationErrorDetail[]) {
    super();
    this.message = (relatedEntity) ? `${InvalidCosSchemaError.defaultMessage} for ${relatedEntity}`
      : InvalidCosSchemaError.defaultMessage;
  }
}


export const fetchFromApiPartitions: ReadonlySet<string> = new Set([
  "aws",
]);

/**
 * DAO classes to retrieve CloudTrail Event Coverage Monitor data
 */
export class CecmDao {


  getDefaultRegionListFetcher(): RegionFetcher {
    const regionDao: RegionDao = new RegionDao();
    return regionDao.getRegions.bind(regionDao);
  }

  async getParities(regionFetcher?: RegionFetcher): Promise<ICecmRegionEventWithErrorDto[]> {
    const getRegions: RegionFetcher = regionFetcher ?? this.getDefaultRegionListFetcher();
    const regionDtos: IRegionMetadata[] = await getRegions();

    const regions: RegionVO[] = parseRegionDtoList(regionDtos);
    return this.getParitiesByRegions(regions);
  }

  async getParitiesByRegions(regions: RegionVO[]): Promise<ICecmRegionEventWithErrorDto[]> {
    // TODO: tangentl Add error handling
    const regionsByFetchMechanism: IRegionParityFetchByMechanism = this.splitRegionsByFetchMechanism(regions);

    const fetchPromises: Promise<ICecmRegionEventWithErrorDto[]>[] = [
      this.getParityFromCache(regionsByFetchMechanism.fromCache),
      this.getParityFromApi(regionsByFetchMechanism.fromCosApi),
    ];

    return Promise.all(fetchPromises).then(
      ( parityByRegionChunks: ICecmRegionEventWithErrorDto[][]) =>
        parityByRegionChunks.reduce((all, chunk) => all.concat(chunk), [])
    );
  }

  splitRegionsByFetchMechanism(regions: RegionVO[]): IRegionParityFetchByMechanism {
    const result: IRegionParityFetchByMechanism = {
      fromCache: [],
      fromCosApi: []
    };

    regions.forEach((region) => {
      const mechanism: RegionParityFetchMechanism = this.getRegionFetchMechanism(region);

      switch(mechanism) {
        case RegionParityFetchMechanism.FromCache:
          result.fromCache.push(region);
          break;
        case RegionParityFetchMechanism.FromCosApi:
          result.fromCosApi.push(region);
          break;
        default:
          break;
      }
    });
    return result;
  }

  getRegionFetchMechanism(region: RegionVO): RegionParityFetchMechanism {
    if (region.status !== GENERALLY_AVAILABLE) {
      return RegionParityFetchMechanism.DoNotFetch;
    }

    if (fetchFromApiPartitions.has(region.partition)) {
      return RegionParityFetchMechanism.FromCosApi;
    }

    return RegionParityFetchMechanism.FromCache;
  }


  async getParityFromCache(regions: RegionVO[]): Promise<ICecmRegionEventWithErrorDto[]> {
    const allRegionPromises: Promise<ICecmRegionEventWithErrorDto>[] = regions.map(
      (region) => this.getSingleRegionParityFromCache(region)
    );

    return Promise.all(allRegionPromises);
  }

  getSingleRegionParityFromCache(region: RegionVO): Promise<ICecmRegionEventWithErrorDto> {
    const client: AxiosInstance = getMidwayApiClient();

    return new Promise<ICecmRegionEventWithErrorDto>((resolve, reject) => {
      let emptyEvents: ICecmRegionEventWithErrorDto = {
        region: region.airportCode,
        events: [],
      }
      client.get<ICecmRegionEventWithErrorDto>(this.getParityCacheUrl(region)).then((resp) => {
        // Backend may return 404 in the form of a success http call with response body as html
        resolve(
          isResponseHtml(resp.data) ? emptyEvents : resp.data
        );
      }).catch((e: AxiosError) => {
        // Given the regions are from the database, it is possible that the cache does not have the
        // requested region, when it happens, just return empty events.  Browser console log
        // will still show the 404 error
        if (e.response?.status === 404) {
          resolve(emptyEvents);
        } else {
          reject(e);
        }
      })
    });
  }

  getParityCacheUrl(region: RegionVO): string {
    return `/static-reference-data/cecm/CECM_${region.airportCode}_prod.json`;
  }

  async getParityFromApi(regions: RegionVO[]): Promise<ICecmRegionEventWithErrorDto[]> {
    const allRegionPromises: Promise<ICecmRegionEventWithErrorDto>[] = regions.map(
      (region) => this.getServiceEventsForOneRegion(undefined, region)
    );

    return Promise.all(allRegionPromises);
  }

  async getServiceEventsForOneRegion(eventSourceId: Optional<string>, region: RegionVO): Promise<ICecmRegionEventWithErrorDto> {
    const client = getReconApiClient();
    const url = this.getServiceEventsForOneRegionUrl(region);
    const result: ICosApiListEventStatusForOneRegionDto = (await client.get<ICosApiListEventStatusForOneRegionDto>(url)).data;
    if (!cosDataValidate(result)) {
      // Get all errors instead of just the first error
      const ajv: Ajv = new Ajv({ allErrors: true });
      ajv.validate(cosApiListEventStatusForOneRegionDtoSchema, result);
      // const err: InvalidCosSchemaError = new InvalidCosSchemaError(region.airportCode, ajv.errors as ErrorObject[]);
      console.error(`Schematic error with COS API data for region ${region.airportCode} (${region.name})`, {
        airportCode: region.airportCode,
        regionName: region.name,
        errors: ajv.errors,
        data: result,
      });

      // Short-term fix for Sev2 https://sim.amazon.com/issues/RECON-10335
      // @ts-ignore (errors are not defined in the interface)
      return {
        region: region.airportCode,
        events: [],
      }
    }

    return {
      region: region.airportCode,
      events: result.usages,
      errors: result.errors,
    };
  }

  getServiceEventsForOneRegionUrl(region: RegionVO): string {
    return `/api/regions/${region.airportCode}/apiusages`;
  }


  async getExemptedApis(): Promise<string> {
    const client: AxiosInstance = getMidwayApiClient();
    const res = await client.get("/static-reference-data/maps/exemptedApi.txt");
    return res.data;
  }
}
