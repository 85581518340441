import React, { useCallback, useState } from "react";
import RegionsTable from "../common/TableComponents/RegionsTable";
import { ServiceInRegionLinkViaRegion } from "../common/Links";
import {
  sortRIP,
  sortDeliveryDateDeep,
  sortStatusDeep,
  sortConfidenceDeep,
  defaultTableConfig,
  genAriaLabel,
  remapColumnWidthsChange,
  sortCategory,
  sortSortableName,
} from "../../utils/tableUtils";
import { getStatusCell, getDateCell } from "../../utils/singleRegionUtils";
import { CATEGORY_FIELD } from "../../constants";
import { getNonEmptyProperties } from "../../utils/collectionUtil";
import { ExportTableToCSVExcel } from "../ExportTableToCSVExcel/ExportTableToCSVExcel";
import { generateSingleRegionCompletedWorkbook } from "./SingleRegionCompletedCSVExport";
import { getServiceDisplayName } from "../../utils/serviceMetadataUtil";

export const SingleRegionCompletedTestId = {
  table: "SingleRegionCompletedListTable-table",
  exportTable: "SingleRegionCompletedListTable-exportTable",
}

const fields = {
  0: "nameSortable",
  1: "nameRip",
  2: CATEGORY_FIELD,
  3: "status",
  4: "confidence",
  5: "date",
};

const getColumnDefinitions = (regionName, widths) => [
  {
    id: fields[0],
    header: "Service Name",
    cell: (item) => (
      <ServiceInRegionLinkViaRegion regionName={regionName} serviceName={item.nameRip}>
        {getServiceDisplayName(item, "-")}
      </ServiceInRegionLinkViaRegion>
    ),
    label: sortState =>
      genAriaLabel(sortState, "Service Name", fields[0]),
    width: widths[fields[0]],
    minWidth: 142,
  },
  {
    id: fields[1],
    header: "RIP Name",
    cell: ({ nameRip }) => (
      <ServiceInRegionLinkViaRegion regionName={regionName} serviceName={nameRip}>
        {nameRip ?? "-"}
      </ServiceInRegionLinkViaRegion>
    ),
    label: sortState => genAriaLabel(sortState, "RIP Name", "nameRip"),
    width: widths.nameRip,
    minWidth: 142,
  },
  {
    id: fields[2],
    header: "Category",
    cell: ({ category, plan }) => (
      <div className="awsui-util-t-l">{plan ?? "-"}</div>
    ),
    label: sortState => genAriaLabel(sortState, "Category", CATEGORY_FIELD),
    width: widths[CATEGORY_FIELD],
    minWidth: 120,
  },
  {
    id: fields[3],
    header: "RIP Build Status",
    cell: getStatusCell,
    label: sortState => genAriaLabel(sortState, "RIP Build Status", "status"),
    width: widths.status,
    minWidth: 120,
  },
  {
    id: fields[5],
    header: "Date Delivered",
    cell: getDateCell,
    label: sortState => genAriaLabel(sortState, "Date Delivered", "date"),
    width: widths.date,
    minWidth: 120,
  },
];

const SORTABLE_COLUMNS = [
  { id: fields[0], field: fields[0], comparator: sortSortableName },
  { id: "nameRip", field: fields[1], comparator: sortRIP },
  { id: CATEGORY_FIELD, field: fields[2], comparator: sortCategory },
  { id: "status", field: fields[3], comparator: sortStatusDeep },
  { id: "confidence", field: fields[4], comparator: sortConfidenceDeep },
  { id: "date", field: fields[5], comparator: sortDeliveryDateDeep },
];

const getFilteringOptions = items => [
  {
    groupValuesLabel: "Service Names",
    propertyKey: fields[0],
    propertyLabel: "Service Name",
    values: getNonEmptyProperties(items, (item) => getServiceDisplayName(item)),
  },
  {
    groupValuesLabel: "RIP Names",
    propertyKey: fields[1],
    propertyLabel: "RIP Name",
    values: getNonEmptyProperties(items, (item) => item.nameRip),
  },
  {
    groupValuesLabel: "Categories",
    propertyKey: fields[2],
    propertyLabel: "Category",
    values: getNonEmptyProperties(items, (item) => item[CATEGORY_FIELD]),
  },
  {
    groupValuesLabel: "Statuses",
    propertyKey: fields[3],
    propertyLabel: "Status",
    values: getNonEmptyProperties(items, (item) => item.status),
  },
  {
    groupValuesLabel: "Confidence",
    propertyKey: fields[4],
    propertyLabel: "Confidence",
    values: getNonEmptyProperties(items, (item) => item.confidence),
  },
  {
    groupValuesLabel: "Dates Delivered",
    propertyKey: fields[5],
    propertyLabel: "Date Delivered",
    values: getNonEmptyProperties(items, (item) => item.date),
  },
];

const TABLE_CONTENT_SELECTOR_OPTIONS = {
  title: "Select visible columns",
  options: [
    {
      label: "Columns",
      options: [
        { id: fields[0], label: "Service Long Name", visible: true },
        { id: "nameRip", label: "RIP Name", visible: false },
        { id: CATEGORY_FIELD, label: "Category", visible: true },
        { id: "status", label: "Status", visible: true },
        { id: "confidence", label: "Confidence", visible: true },
        { id: "date", label: "Date Delivered", visible: true },
      ],
    }
  ]
};

const getTableConfig = args => {
  const {
    items,
    loading,
    columnDefinitions,
    header,
    filteringOptions,
    sortingColumn,
    sortingDescending,
    handleColumnWidthsChange,
    handleSortingChange,
  } = args;
  return defaultTableConfig({
    features: [
      "TablePropertyFiltering",
      "TablePagination",
      "TableSorting",
      "TablePreferences",
      "TablePageSizeSelector",
      "TableWrapLines",
      "TableContentSelector",
    ],
    variant: "borderless",
    columnDefinitions,
    header,
    items,
    loading,
    onColumnWidthsChange: handleColumnWidthsChange,
    onSortingChange: handleSortingChange,
    filteringOptions,
    sortableColumns: SORTABLE_COLUMNS,
    contentSelectorOptions: TABLE_CONTENT_SELECTOR_OPTIONS,
    sortingColumn,
    sortingDescending,
  });
};

const SingleRegionCompletedTable = ({
  regionName,
  items,
  loading = true,
  sortingState,
  handleSortingChange,
}) => {

  const [columnWidths, setColumnWidths] = useState({
    [fields[0]]: undefined,
    nameRip: undefined,
    category: undefined,
    plan: undefined,
    status: undefined,
    confidence: undefined,
    date: undefined,
  });

  const getExportDataBook = useCallback(() => {
    return generateSingleRegionCompletedWorkbook(items)
  }, [items]);

  const header = RegionsTable.getTableHeader({
    primary: "Completed",
    secondary: undefined,
    actionButtons: <ExportTableToCSVExcel
      generateDataBook={getExportDataBook}
      fileNamePrefix={`SingleRegionCompleted`}
      // When this file is converted to tsx then we should add a dom test that this component exists
      data-testId={SingleRegionCompletedTestId.exportTable}
    /> });

  const handleColumnWidthsChange = ({ detail: { widths } }) => {
    const mappedWidths = remapColumnWidthsChange(fields, widths);
    setColumnWidths(mappedWidths);
  };

  const columnDefinitions = getColumnDefinitions(regionName, columnWidths);
  const filteringOptions = getFilteringOptions(items);
  const tableConfig = getTableConfig({
    items,
    loading,
    columnDefinitions,
    header,
    filteringOptions,
    handleColumnWidthsChange,
    handleSortingChange,
    ...sortingState,
  });

  return <RegionsTable testId={SingleRegionCompletedTestId.table} {...tableConfig} />;
};

export default SingleRegionCompletedTable;
