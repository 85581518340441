import React, { useCallback, useState } from "react";
import { FixedContainer } from "../common/FixedContainer";
import { Loading } from "../common/Loading";
import { QuickSightDao } from "../../daos/QuickSightDao";
import useSWR from "swr";
import { QuickSightDashboardProps } from "./QuickSightDashboard.types";
import { Button, SpaceBetween, StatusIndicator } from "@amzn/awsui-components-react-v3";
import { Optional } from "../../models/types/Optional";
import {
  addParamsToUrlHash,
  getQuickSightSwrConfig,
  QuickSightDashboardErrors,
  validUrlRedirect
} from "./quickSightDashboardUtil";
import {
  ContentFrame,
  ContentLayout,
  DashboardControlBar,
  HelpSlot,
  ControlSlot,
  legacyDashboardHeight
} from "./QuickSightDashboard.style";

export const QuickSightDashboardTestId = {
  embeddedRoot: "QuickSightDashboard-embeddedRoot",
  error: "QuickSightDashboard-error",
  openTabError: "QuickSightDashboard-openTabError",
};


const QuickSightError = () => {
  return (
    <StatusIndicator
      data-testid={QuickSightDashboardTestId.error}
      type="error">Couldn't load dashboard</StatusIndicator>
  );
};


export const QuickSightDashboard: React.FunctionComponent<QuickSightDashboardProps> = (props) => {
  const [isGeneratingUrl, isGeneratingUrlSet] = useState<boolean>(false);
  const [openTabError, openTabErrorSet] = useState<Optional<string>>(undefined);

  const { dashboardId, params=null } = props;
  const width = "100%";
  const height = props.dashboardHeight ?? legacyDashboardHeight;

  const { data: url } = useSWR(
    dashboardId,
    () => new QuickSightDao().getQuickSightDashboardUrl(dashboardId),
    getQuickSightSwrConfig());

  let content: JSX.Element = <QuickSightError />;

  const openInNewTab_onClick = useCallback(async() => {
    isGeneratingUrlSet(true);
    try {
      const baseUrl: URL = await new QuickSightDao().getQuickSightDashboardUrl(props.dashboardId);
      isGeneratingUrlSet(false);
      if (!validUrlRedirect(baseUrl)) {
        openTabErrorSet(QuickSightDashboardErrors.generic);
        return;
      }
      const contentUrl: string = addParamsToUrlHash(baseUrl, props.params);
      const win = window.open(contentUrl, "_blank", "noopener, noreferrer");
      if (win) {
        win?.focus();
        openTabErrorSet(undefined);
      } else {
        openTabErrorSet(QuickSightDashboardErrors.openTabBlocked);
      }
    } catch {
      openTabErrorSet(QuickSightDashboardErrors.generic);
    }
  }, [props.dashboardId, props.params]);

  if (!url) {
    content = <FixedContainer
      width={width}
      height={height}
      centered={true}
    >
      <Loading />
    </FixedContainer>
  } else if (validUrlRedirect(url)) {
    const contentUrl: string = addParamsToUrlHash(url, params);

    content = (
      <ContentLayout>
        <DashboardControlBar>
          <HelpSlot> 
            <SpaceBetween size = "xl" direction="horizontal">
              { props.links }
            </SpaceBetween>
          </HelpSlot>
          <ControlSlot>
            <SpaceBetween size="xl" direction="horizontal">
              {
                (openTabError) && 
                <StatusIndicator
                  data-testid={QuickSightDashboardTestId.openTabError}
                  type={"error"}>
                </StatusIndicator>
              }
              <Button
                loading={isGeneratingUrl}
                variant={"link"}
                iconAlign={"right"}
                iconName={"external"}
                onClick={openInNewTab_onClick}>
                  Open dashboard in new tab
                </Button>
            </SpaceBetween>
          </ControlSlot>
        </DashboardControlBar>
        <ContentFrame
          title="QuickSight Dashboard"
          sandbox="allow-forms allow-same-origin allow-scripts allow-popups allow-popups-to-escape-sandbox allow-downloads"
          src={contentUrl}
          frameBorder={0}
          width={width}
          height={height}
          data-testid={QuickSightDashboardTestId.embeddedRoot}
        />
      </ContentLayout>
    );
  }

  return <FixedContainer width={width} height={height}>{content}</FixedContainer>
};
