import * as React from "react";
import { Flashbar } from "@amzn/awsui-components-react-v3";
import { BannerProps } from "./Banner.types";
import { FlashbarProps } from "@amzn/awsui-components-react-v3/polaris/flashbar";

export const BannerTestId = {
  blueprint: "Banner-blueprint"
}

export const getFlashBarItems = (needsBlueprintStaleBanner: boolean): FlashbarProps.MessageDefinition[] => {
  const itemList: FlashbarProps.MessageDefinition[] = []

  if (needsBlueprintStaleBanner) {
    const blueprintStaleBannerContent = (<div data-testid={BannerTestId.blueprint}>
      Blueprint data is over an hour stale in Recon. Recent changes in Blueprint, if they exist, are not reflected in
      Recon.
    </div>);


    itemList.push({ "type": "error", "content": blueprintStaleBannerContent })
  }

  return itemList
}

export const Banner: React.FunctionComponent<BannerProps> = (props) => {
  return <Flashbar items={getFlashBarItems(props.needsBlueprintStaleBanner)}/>;
};
