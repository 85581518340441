import { IHasDataTestId } from "../models/IHasDataTestId";

export const noProp = Object.freeze({});

/**
 * Returns a simple object that contains only data-testid and suffix
 * @param props
 * @param suffix
 */
export function getDataTestIdProp(props: IHasDataTestId, suffix: string = ""): IHasDataTestId {
  if (!props["data-testid"]) {
    return noProp;
  }
  return {
    "data-testid": composeDataTestId(props["data-testid"], suffix)
  };
}

export function composeDataTestId(...parts: string[]): string {
  return parts.join("");
}
