import * as React from "react";
import { FunctionComponent } from "react";
import { Container, Header } from "@amzn/awsui-components-react-v3";
import { EditLaunchContactsFormFields } from "./EditLaunchContactsForm/EditLaunchContactsForm";
import { IHasDataTestId } from "../../../../models/IHasDataTestId";
import { getDataTestIdProp } from "../../../../utils/testUtil";
import { IEditLaunchFormFieldsInput } from "../EditLaunchForm.types";

export const EditLaunchContactsTestIdSuffix = {
  header: "-header",
  form: "-form",
}

export interface IEditLaunchContactsContainerProps extends IHasDataTestId {
  control: any,
  errors: any,
}


export const EditLaunchContacts: FunctionComponent<IEditLaunchFormFieldsInput> = (props) =>{

  return (
    <>
      <Container header={
        <Header variant="h2" {...getDataTestIdProp(props, EditLaunchContactsTestIdSuffix.header)}>
          Edit Launch Contacts
        </Header>
      }
      >
        <form {...getDataTestIdProp(props, EditLaunchContactsTestIdSuffix.form)}>
          <EditLaunchContactsFormFields control={props.control} errors={props.errors} />
        </form>
      </Container>
    </>
  )
}
