import * as React from "react";
import { FunctionComponent, useCallback } from "react";
import { SpaceBetween, ColumnLayout } from "@amzn/awsui-components-react-v3";
import { Optional } from "@amzn/react-bot";
import { formFieldSpacing } from "../../EditLaunchForm.style";
import { Displayable } from "@amzn/api-parity-react-component/lib/models/Displayable";
import { IFormFieldData } from "../../../../common/FormTypes";
import { getDataTestIdProp } from "../../../../../utils/testUtil";
import { editLaunchFormFields } from "../../EditLaunchFormFields";
import { IEditLaunchFormFieldsProps, ISelectableFormInput, } from "../../EditLaunchForm.types";
import { IndividualFormFieldWithController } from "../../../../IndividualFormFieldWithController/IndivudalFormFieldWithController";
import { FormFieldTypes } from "../../../../IndividualFormFieldWithController/IndividualFormFieldWithController.types";
import { displayOr } from "../../../../../utils/displayUtil";
import { ValueWithLabel } from "../../../../common/ValueWithLabel/ValueWithLabel";

export const EditLaunchGeneralFormFieldsTestIdSuffixes = {
  codename: "-codename",
  name: "-name",
  productName: "-product",
  iteration: "-iteration",
  type: "-type",
  scope: "-scope",
  ripId: "-ripId",
  tier: "-tier",
  audience: "-audience",
  confidential: "-confidential",
  goalLink: "-goalLink",
  goal: "-goalType",

  contact: "-contact",

  pricingChangeInvolved: "-pricingChangeInvolved",
  namingInvolved: "-namingInvolved",
  prfaqRequired: "-prfaq",
  regulated: "-regulated",
};

export const EditLaunchGeneralFormFields: FunctionComponent<IEditLaunchFormFieldsProps> = (props) => {

  const { control, errors } = props;

  // instantiates field in form and hooks it up to parent react-hook-form via control + errors
  const getFormField = useCallback((formField: IFormFieldData, fieldType: FormFieldTypes, defaultValue: Optional<string> | string[] | ISelectableFormInput | boolean, info?: Displayable) => {
    return <IndividualFormFieldWithController control={control}
                                              errors={errors}
                                              formField={formField}
                                              defaultValue={defaultValue}
                                              popoverInfo={info}
                                              fieldType={fieldType}
                                              {...getDataTestIdProp(props, EditLaunchGeneralFormFieldsTestIdSuffixes[formField.id])}
    />;
  }, [control, errors, props]);

  editLaunchFormFields.ripId.options = props.services?.map((service) => ({ value: service.id }))
  return (
    <>
      <SpaceBetween
        {...getDataTestIdProp(props)}
        direction="vertical"
        size={formFieldSpacing.section}>
          <SpaceBetween direction={"vertical"} size={formFieldSpacing.vertical}>
            <ColumnLayout columns={3}>
              {getFormField(editLaunchFormFields.codename, FormFieldTypes.INPUT_ENTRY, control.getValues().codename)}
              {getFormField(editLaunchFormFields.name, FormFieldTypes.INPUT_ENTRY, control.getValues().name)}
              <ValueWithLabel label={"Product name"} {...getDataTestIdProp(props, EditLaunchGeneralFormFieldsTestIdSuffixes.productName)}  >
                {displayOr(control.getValues().productId)}
              </ValueWithLabel>
              {getFormField(editLaunchFormFields.iteration, FormFieldTypes.SELECT_ENTRY, control.getValues().iteration)}
              {getFormField(editLaunchFormFields.type, FormFieldTypes.SELECT_ENTRY, control.getValues().type)}
              {getFormField(editLaunchFormFields.scope, FormFieldTypes.SELECT_ENTRY, control.getValues().scope)}
              {getFormField(editLaunchFormFields.ripId, FormFieldTypes.AUTOSUGGEST_ENTRY, control.getValues().ripId)}
              {getFormField(editLaunchFormFields.tier, FormFieldTypes.SELECT_ENTRY, control.getValues().tier)}
              {getFormField(editLaunchFormFields.audience, FormFieldTypes.SELECT_ENTRY, control.getValues().audience)}
              {getFormField(editLaunchFormFields.confidential, FormFieldTypes.RADIO_ENTRY, control.getValues().confidential)}
              {getFormField(editLaunchFormFields.goal, FormFieldTypes.AUTOSUGGEST_ENTRY, control.getValues().goal)}
              {getFormField(editLaunchFormFields.goalLink, FormFieldTypes.INPUT_ENTRY, control.getValues().goalLink)}
              </ColumnLayout>
          </SpaceBetween>
      </SpaceBetween>
    </>
  );
};
