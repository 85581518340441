import * as React from "react";
import moment from "moment";
import styled from "styled-components";
import {
  SingleServiceLink,
  ServiceInRegionLinkViaParity
} from "../common/Links";
import { Icon } from "@amzn/awsui-components-react/polaris";
import User from "../common/User";
import {
  CATEGORY_FIELD,
  NON_GLOBALLY_EXPANDING
} from "../../constants";
import { serviceInRegionInParity } from "../../utils/parityUtils";
import { getAppState } from "../../models/ApplicationState";
import { DateLike } from "../../models/DateLike";
import { Optional } from "../../models/types/Optional";
import * as awsui from "@amzn/awsui-design-tokens/polaris";
import { displayOr } from "../../utils/displayUtil";
import { IParityServiceStatus } from "../../daos/types/IGetParityResponse";

const BlackText = styled.div`
  color: initial;
  text-decoration: none;
`;

const NotPlanned = styled.div`
  color: ${awsui.colorTextStatusError};
  font-weight: bold;
`;

const PastDue = styled.div`
  color: initial;
  background-color: ${awsui.colorChartsRed300};
  font-weight: bold;
`;

const WithinFourteen = styled.div`
  color: initial;
  background-color: ${awsui.colorChartsOrange300};
  font-weight: bold;
`;

const getStatusValue = (status: string, date: Optional<DateLike>, exception: Optional<string>, confidence: Optional<string>, plan: Optional<string>): JSX.Element => {
  if (serviceInRegionInParity(confidence, status)) {
    return (<Icon variant="success" name="status-positive" />);
  } else if (exception && exception !== "None") {
    switch (exception) {
      case "NL":
        return <BlackText>{"NL"}</BlackText>;
      case "ATO":
        return <BlackText>{"ATO"}</BlackText>;
      default:
        return <BlackText>{"-"}</BlackText>;
    }
  } else if (date === "") { // This means the date has been removed by the backend
    return <BlackText>{"-"}</BlackText>;
  } else if (date !== undefined) {
    const currentDate = moment();
    const formatedDate = moment(date).format("MM/YY")
    if (moment(date).isBefore(currentDate)) {
      return <PastDue>{ formatedDate }</PastDue>
    } else if (moment(date).diff(currentDate, "days") <= 14) {
      return <WithinFourteen>{ formatedDate }</WithinFourteen>
    } else {
      return <BlackText>{formatedDate}</BlackText>;
    }
  } else if ( plan === NON_GLOBALLY_EXPANDING ) {
    return <BlackText>NL</BlackText>;
  } else if ( !getAppState().userAbilities.canReadDates ) { // We should not tell users who can't see dates about needing dates
    return <BlackText>{"-"}</BlackText>
  } else {
    return <NotPlanned>ND</NotPlanned>;
  }
}

const BASE_LEFT_COLUMN_DEFS = [
  {
    id: "nameSortable",
    header: "Service Name",
    cell: ({ nameRip, nameLong, nameSortable }) => (
      <SingleServiceLink
        nameSortable={nameSortable}
        nameLong={nameLong}
        nameRip={nameRip}
      />
    ),
    width: 300,
  },
  {
    id: "gm",
    header: "General Manager",
    cell: ({ gm }) => displayOr(gm, () => <User alias={gm} />),
    width: 180,
  },
  {
    id: "sTeamDirect",
    header: "S-Team Direct",
    cell: ({ sTeamDirect }) => displayOr(sTeamDirect, () => <User alias={sTeamDirect} />),
    width: 180,
  },
  {
    id: CATEGORY_FIELD,
    header: "Service Category",
    cell: ({ category, plan }) => (plan ?? "-"),
    width: 220,
  }
];


// Function that *generates* functions usable below.  The return value of this is a function.
const createColumnFormatterFcn = (region: IParityServiceStatus) => {
  return (props) => {
    const key = region.nameSortable?.toLowerCase();
    const status = props[key]?.status;
    const date = props[key]?.date;
    const confidence = props[key]?.confidence;
    const exception = props[key]?.exceptionStatus;
    const plan = props?.plan;
    const value: Optional<JSX.Element> = getStatusValue(status, date, exception, confidence, plan);
    const nameRip = props["nameRip"];
    return (
      <ServiceInRegionLinkViaParity regionName={key} serviceName={nameRip} >
        <div className="awsui-util-t-c">{displayOr(value)}</div>
      </ServiceInRegionLinkViaParity>
    )
  };
};


const getColumnDefinitions = (regions) => {
  const regionDefs = regions.map(region => {
    return {
      id: region?.nameSortable,
      header: <div className="awsui-util-t-c">{ region?.nameSortable }</div>,
      cell: createColumnFormatterFcn(region), // curry down to actual formatter for this region
      minWidth: 90
    }
  });

  return [
    ...BASE_LEFT_COLUMN_DEFS,
    ...regionDefs
  ];

};

export { getColumnDefinitions, createColumnFormatterFcn };
