import * as React from "react";
import { LaunchVO } from "../models/vos/LaunchVO";

export interface ILaunchContext {
  launch: LaunchVO;
  setLaunch: React.Dispatch<React.SetStateAction<LaunchVO>>;
}

// provide a React context provider to serve
//  a product and product state dispatch function
const LaunchContext = React.createContext<ILaunchContext>(
  { launch: new LaunchVO(), setLaunch: () => {} }
);

export function useLaunchContext() {
  return React.useContext<ILaunchContext>(LaunchContext);
}

export default LaunchContext;
