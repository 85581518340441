import * as React from "react";
import { FunctionComponent } from "react";
import { Container, Header } from "@amzn/awsui-components-react-v3";
import { EditLaunchGeneralFormFields } from "./EditLaunchGeneralForm/EditLaunchGeneralForm";
import { IHasDataTestId } from "../../../../models/IHasDataTestId";
import { getDataTestIdProp } from "../../../../utils/testUtil";
import { IEditLaunchFormFieldsInput } from "../EditLaunchForm.types";

export const EditLaunchGeneralTestIdSuffix = {
  header: "-header",
  form: "-form",
}

export interface IEditLaunchGeneralContainerProps extends IHasDataTestId {
  control: any,
  errors: any,
}


export const EditLaunchGeneral: FunctionComponent<IEditLaunchFormFieldsInput> = (props) =>{

  return (
    <>
      <Container header={
        <Header variant="h2" {...getDataTestIdProp(props, EditLaunchGeneralTestIdSuffix.header)}>
          Edit Launch General
        </Header>
      }
      >
        <form {...getDataTestIdProp(props, EditLaunchGeneralTestIdSuffix.form)}>
          <EditLaunchGeneralFormFields control={props.control} errors={props.errors} services={props.services}/>
        </form>
      </Container>
    </>
  )
}
