import * as React from "react";
import { FunctionComponent } from "react";
import { ProductPropertiesProps } from "./ProductProperties.types";
import { Box, Button, ColumnLayout, Header, Link, SpaceBetween } from "@amzn/awsui-components-react-v3";
import { getDataTestIdProp } from "../../../utils/testUtil";
import { displayOr } from "../../../utils/displayUtil";
import { ValueWithLabel } from "../../common/ValueWithLabel/ValueWithLabel";
import User from "../../common/User";
import { ProductContactType } from "../../../daos/types/IProductDto";
import { LoadingContainer } from "../../common/LoadingContainer/LoadingContainer";
import { useHistory } from "react-router-dom";

export const ProductPropertiesTestIdSuffixes = {
  name: "-name",
  confidential: "-confidential",
  ripId: "-ripId",
  category: "-category",
  audience: "-audience",
  tags: "-tags",
  productCti: "-productCti",
  antPermissionLink: "-antPermissionLink",
  ctiLink: "-ctiLink",
  editButton: "-editButton",
  ripNameLink: "-ripNameLink",
}

const ProductTagLink = ({ tagValue }) => {
  const history = useHistory();
  return (
    <Link
      onFollow={(event) => {
        event.preventDefault();
        const searchValue = {
          operation: "and",
          tokens: [
            {
              operator: ":",
              propertyKey: "tags",
              value: tagValue
            }
          ]
        }
        history.push({
          pathname: `/alm/products`,
          search: `filter=${btoa(JSON.stringify(searchValue))}`,
        });
      }}
    >
      {tagValue}
    </Link>
  );
}

export function editButton(isDisabled?: boolean, dataTestId?: string) {
  const editSimUrl: string = "https://issues.amazon.com/issues/create?template=36b19f14-4d6b-4aa4-9aba-4e4cbf34bc72"
  return (
    <Button
      data-testid={dataTestId}
      disabled={isDisabled}
      iconAlign="right"
      iconName="external"
      target="_blank"
      href={editSimUrl}
    >
      Edit
    </Button>
  )
}


export const ProductProperties: FunctionComponent<ProductPropertiesProps> = (props) => {
  const { product, disabled, loading } = props;
 
  function getRipNameLink(ripId: string) {
    return (
      <Link
        {...getDataTestIdProp(props, ProductPropertiesTestIdSuffixes.ripNameLink)}
        external
        href={`https://regions.aws.dev/services/statuses/${encodeURI(ripId)}`}
      >
        {ripId}
      </Link>
    )
  }

  function getAntPermissionGroupLink(group: string) {
    return (
      <Link
        {...getDataTestIdProp(props, ProductPropertiesTestIdSuffixes.antPermissionLink)}
        external
        href={`https://permissions.amazon.com/group.mhtml?group=${encodeURI(group)}&group_type=ant`}
      >
        {group}
      </Link>
    )
  }

  function getCtiLink(ctiString: string) {
    const cti = ctiString.split("/");
    const sanitizedCtiValues = cti.map((ctiValue) => encodeURIComponent(ctiValue))
    if (sanitizedCtiValues.length === 3) {
      return (
        <Link
          {...getDataTestIdProp(props, ProductPropertiesTestIdSuffixes.ctiLink)}
          external
          href={`https://t.corp.amazon.com/create/options?category=${sanitizedCtiValues[0]}&type=${sanitizedCtiValues[1]}&item=${sanitizedCtiValues[2]}`}
        >
          {ctiString}
        </Link>
      )
    } else {
      return ctiString;
    }
  }

  return (
    <SpaceBetween size="l" direction="vertical">
      <LoadingContainer loading={loading} header={<Header variant="h2" actions={editButton(disabled)}>Properties</Header>}>
        <ColumnLayout columns={3} variant="text-grid">
          <SpaceBetween size="l">
            <ValueWithLabel
              label="Codename"
              helpId="PRODUCT_CODENAME"
            >
              {displayOr(product.codename)}
            </ValueWithLabel>
            <ValueWithLabel
              label="RIP name"
              helpId="PRODUCT_RIP"
              {...getDataTestIdProp(props, ProductPropertiesTestIdSuffixes.ripId)}
            >
              {displayOr(product.ripId, () => getRipNameLink(product.ripId ?? ""))}
            </ValueWithLabel>
            <ValueWithLabel
              label="Description"
              helpId="PRODUCT_DESCRIPTION"
            >
              {displayOr(product.description)}
            </ValueWithLabel>
          </SpaceBetween>
          <SpaceBetween size="l">
            <ValueWithLabel
              label="Product final name"
              helpId="PRODUCT_FINAL"
              {...getDataTestIdProp(props, ProductPropertiesTestIdSuffixes.name)}
            >
              {displayOr(product.name)}
            </ValueWithLabel>
            <ValueWithLabel
              label="Product type"
              helpId="PRODUCT_TYPE"
            >
              {displayOr(product.type)}
            </ValueWithLabel>
            <ValueWithLabel
              label="Product Category"
              helpId="PRODUCT_CATEGORY"
              {...getDataTestIdProp(props, ProductPropertiesTestIdSuffixes.category)}
            >
              {displayOr(product.category)}
            </ValueWithLabel>

          </SpaceBetween>
          <SpaceBetween size="l">
            <ValueWithLabel
              label="Audience"
              helpId="PRODUCT_AUDIENCE"
              {...getDataTestIdProp(props, ProductPropertiesTestIdSuffixes.audience)}
            >
              {displayOr(product.audience)}
            </ValueWithLabel>
            <ValueWithLabel
              label="Tags"
              {...getDataTestIdProp(props, ProductPropertiesTestIdSuffixes.tags)}
            >
              {displayOr(
                product.tags?.length,
                () => <>
                  {product.tags?.map((tag, index) =>
                    <React.Fragment key={index}>
                      <ProductTagLink
                        tagValue={tag}
                      />
                      {index + 1 !== product.tags?.length && ", "}
                    </React.Fragment>
                  )}
                </>
              )}
            </ValueWithLabel>
          </SpaceBetween>
        </ColumnLayout>
      </LoadingContainer>
      <LoadingContainer loading={loading} header={<Header variant="h2" actions={editButton(disabled)}>Contacts</Header>}>
        <ColumnLayout columns={2}>
          <ValueWithLabel label="GM">
            {displayOr(product.gm, () => <User alias={product.gm!}/>)}
          </ValueWithLabel>
          <ValueWithLabel label="VP">
            {displayOr(product.vp, () => <User alias={product.vp!}/>)}
          </ValueWithLabel>
        </ColumnLayout>
        <ColumnLayout columns={2}>
          <Box>
            <Box color="text-label">Product owner</Box>
            {displayOr(product.primaryOwner, () =>
              <ValueWithLabel label={ProductContactType[product.primaryOwner?.contactType ?? "Other"]}>
                {displayOr(product.primaryOwner?.alias, () => <User alias={product.primaryOwner?.alias!}/>)}
              </ValueWithLabel>
            )}
          </Box>
          <Box>
            <Box color="text-label">Secondary owner(s)</Box>
            {displayOr(product.secondaryOwners, () =>
              <SpaceBetween size="l">
                {product.secondaryOwners!.map((secondaryOwner, index) => {
                  return <ValueWithLabel key={index} label={ProductContactType[secondaryOwner.contactType ?? "Other"]}>
                    {displayOr(secondaryOwner.alias, () => <User alias={secondaryOwner.alias}/>)}
                  </ValueWithLabel>
                })}
              </SpaceBetween>
            )}
          </Box>
        </ColumnLayout>
        <ColumnLayout columns={1}>
          <ValueWithLabel
            label="Product CTI"
            helpId="PRODUCT_CTI"
            {...getDataTestIdProp(props, ProductPropertiesTestIdSuffixes.productCti)}
          >
            {displayOr(product.cti, () => getCtiLink(product.cti ?? ""))}
          </ValueWithLabel>
        </ColumnLayout>
      </LoadingContainer>
      <LoadingContainer loading={loading} header={<Header variant="h2" actions={editButton(disabled)}>Permissions</Header>}>
        <ColumnLayout columns={2}>
          <ValueWithLabel
            label="Read/Write"
            helpId="PRODUCT_WRITE"
          >
            {displayOr(product.writeGroups, () => getAntPermissionGroupLink(product.writeGroups![0]))}
          </ValueWithLabel>
          <ValueWithLabel
            label="Read"
            helpId="PRODUCT_READ"
          >
            {displayOr(product.readGroups, () => getAntPermissionGroupLink(product.readGroups![0]))}
          </ValueWithLabel>
        </ColumnLayout>
      </LoadingContainer>
      <LoadingContainer loading={loading} header={<Header variant="h2">ID info</Header>}>
        <ValueWithLabel label="ALM product ID">
          {displayOr(product.id)}
        </ValueWithLabel>
      </LoadingContainer>
    </SpaceBetween>
  );
};
