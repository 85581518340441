import { SortType } from "rsuite-table";
import { SortByDirection } from "@amzn/api-parity-react-component/lib/models/SortBy";
import { Optional } from "../models/types/Optional";

export function toSortByDirection(sortType?: SortType): Optional<SortByDirection> {
  if (sortType === "asc") {
    return SortByDirection.Ascending;
  }

  if (sortType === "desc") {
    return SortByDirection.Descending;
  }

  return;
}


export function toSortType(direction?: SortByDirection): Optional<SortType> {
  if (direction === SortByDirection.Ascending) {
    return "asc";
  }

  if (direction === SortByDirection.Descending) {
    return "desc";
  }

  return;
}
