import { QaRemoteOperation } from "../models/QaRemoteOperation";
import { remoteOperationRunner } from "./remoteOperationRunner";
import { PlanDao } from "../../daos/PlanDao";
import { IGenericMutationResponseDto } from "../../daos/types/IGenericMutationResponseDto";
import {
  bcoChangeRegionId,
  gaRegionId,
  generateFakeNote,
  generateFakeSim,
  generateFutureDate,
  removeDateRegionId,
  serviceRipId
} from "./testData";
import { IServicePlanBusinessCaseOverride } from "../../models/types/IServicePlanBusinessCaseOverride";
import { IServiceRegionPlanDto } from "../../daos/types/IServiceRegionPlanDto";
import { IServiceInRegionHistory } from "../../daos/types/IServiceInRegionHistory";
import { IGetSingleServicePlanResponseLegacy } from "../../daos/types/IGetSingleServicePlanResponse";
import { IUpdateServicePlanEstimatedDate } from "../../models/types/IUpdateServicePlanEstimatedDate";
import { toShortDate } from "../../utils/dateUtil";


async function applyOverride(serviceId: string, regionId: string, override: IServicePlanBusinessCaseOverride): Promise<IGenericMutationResponseDto> {
  const planDao: PlanDao = new PlanDao();
  const serviceWithPlans: IGetSingleServicePlanResponseLegacy = await planDao.getSingleServicePlans(serviceId);
  const plan: IServiceRegionPlanDto = serviceWithPlans.serviceInstances.find((p) => p.belongsToInstance === regionId) as IServiceRegionPlanDto;
  plan.region = regionId; // region field is not always populated in DTO
  return planDao.updateBusinessCaseOverride(plan as IServiceInRegionHistory, override);
}


/**
 * Remove existing overrides
 * @param serviceId
 * @param regionId
 */
async function ensureNoException(serviceId: string, regionId: string): Promise<IGenericMutationResponseDto> {
  const override: IServicePlanBusinessCaseOverride = {
    disposition: "None",
    sim: generateFakeSim(),
    note: generateFakeNote(),
  }

  return await applyOverride(serviceId, regionId, override);
}

export const updateBusinessCaseOverrideInGAOperation: QaRemoteOperation<IGenericMutationResponseDto> = new QaRemoteOperation<IGenericMutationResponseDto>(
  () => remoteOperationRunner(async () => {
    const override: IServicePlanBusinessCaseOverride = {
      disposition: "ATO",
      sim: generateFakeSim(),
      note: generateFakeNote()
    }
    return await applyOverride(serviceRipId, bcoChangeRegionId, override);
  })
);

export const removeBusinessCaseOverrideInGAOperation: QaRemoteOperation<IGenericMutationResponseDto> = new QaRemoteOperation<IGenericMutationResponseDto>(
  () => remoteOperationRunner(async () => {
    return await ensureNoException(serviceRipId, bcoChangeRegionId);
  })
);

export const updateEstimatedDateOperation: QaRemoteOperation<IGenericMutationResponseDto> = new QaRemoteOperation<IGenericMutationResponseDto>(
  () => remoteOperationRunner(async () => {
    try {
      await ensureNoException(serviceRipId, gaRegionId);
    } catch {
      // do nothing
      // We don't want removing exception becomes a side effect because the real test
      // is about update estimated date
    }

    const planDao: PlanDao = new PlanDao();
    const serviceWithPlans: IGetSingleServicePlanResponseLegacy = await planDao.getSingleServicePlans(serviceRipId);

    const plan: IServiceInRegionHistory = serviceWithPlans.serviceInstances.find((p) => p.belongsToInstance === gaRegionId) as IServiceInRegionHistory;
    plan.region = gaRegionId; // region field is not always populated in DTO

    const update: IUpdateServicePlanEstimatedDate = {
      confidence: "Yellow",
      date: toShortDate(generateFutureDate()),
      note: generateFakeNote(),
      removeConfidenceAndDate: false,
    };

    return await planDao.updateEstimatedDate(plan, update);
  })
);


export const removeEstimatedDateOperation: QaRemoteOperation<IGenericMutationResponseDto> = new QaRemoteOperation<IGenericMutationResponseDto>(
  () => remoteOperationRunner(async () => {
    try {
      await ensureNoException(serviceRipId, removeDateRegionId);
    } catch {
      // do nothing
      // We don't want removing exception becomes a side effect because the real test
      // is about remove estimated date
    }

    const planDao: PlanDao = new PlanDao();
    let serviceWithPlans: IGetSingleServicePlanResponseLegacy = await planDao.getSingleServicePlans(serviceRipId);
    let plan: IServiceInRegionHistory = serviceWithPlans.serviceInstances.find((p) => p.belongsToInstance === removeDateRegionId) as IServiceInRegionHistory;
    plan.region = removeDateRegionId; // region field is not always populated in DTO

    // First we need to ensure the plan has stuff to remove
    try {
      const update: IUpdateServicePlanEstimatedDate = {
        confidence: "Yellow",
        date: toShortDate(generateFutureDate()),
        note: generateFakeNote(),
        removeConfidenceAndDate: false,
      }
      await planDao.updateEstimatedDate(plan, update);

      // Get a fresh copy of the plan, or otherwise we could be looking at pass referenced
      serviceWithPlans = await planDao.getSingleServicePlans(serviceRipId);
      plan = serviceWithPlans.serviceInstances.find((p) => p.belongsToInstance === removeDateRegionId) as IServiceInRegionHistory;
      plan.region = removeDateRegionId; // region field is not always populated in DTO
    } catch {
      // We don't want removing exception becomes a side effect because the real test
      // is about remove estimated date
    }

    const remove: IUpdateServicePlanEstimatedDate = {
      note: generateFakeNote(),
      removeConfidenceAndDate: true,
    };

    return await planDao.updateEstimatedDate(plan, remove);
  })
);
