import { FeatureParityVO } from "./FeatureParityVO";
import { hydrateModel } from "../../utils/modelUtil";
import { ServiceBuildPlanVO } from "@amzn/api-parity-react-component/lib/models/vos/ServiceBuildPlanVO";
import { ParityStatusEnum } from "@amzn/api-parity-react-component/lib/models/ParityStatus";

export interface IFeatureParityOverrideTrail {
  date: Date;
  reason?: string;
  comment?: string;
  ticketUrl?: string;
  overrideBy: string;
}

export interface IFeatureParityOverrideValue {
  parity: ParityStatusEnum;
  overrideTrail?: IFeatureParityOverrideTrail;
  overrideBuildPlan?: ServiceBuildPlanVO;
}

export class FeatureParityOverrideVO extends FeatureParityVO implements IFeatureParityOverrideValue {
  readonly isOverride: boolean = true;

  /**
   * Original Feature Parity value
   * When it is undefined, that means there is no data for the original Feature parity
   */
  original?: FeatureParityVO = new FeatureParityVO();
  overrideTrail?: IFeatureParityOverrideTrail;
  overrideBuildPlan?: ServiceBuildPlanVO;

  constructor(source?: Partial<FeatureParityOverrideVO>) {
    super(source);
    hydrateModel(source, this);
  }

  static fromFeatureParity(FeatureParity: FeatureParityVO, overrideValue: IFeatureParityOverrideValue): FeatureParityOverrideVO {
    const override: FeatureParityOverrideVO = new FeatureParityOverrideVO(FeatureParity);
    override.original = FeatureParity;
    override.parity = overrideValue.parity;
    override.overrideTrail = overrideValue.overrideTrail;
    override.overrideBuildPlan = overrideValue.overrideBuildPlan;
    return override;
  }
}
