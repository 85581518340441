import { estimatedDate } from "../../../models/ServiceInRegionReportOptions";
import * as React from "react";
import { useCallback, useEffect, useState } from "react";
import useSWR, { responseInterface } from "swr";
import { getDateForFileName } from "../../../utils/dateUtil";
import { getSwrConfig } from "../../../daos/swrUtil";
import { ParityTableCSVExportProps } from "./ParityTableCSVExport.types";
import { ActionMenu } from "@amzn/api-parity-react-component/lib/components/ActionMenu/ActionMenu";
import { ExportFormat } from "@amzn/excelerator";
import { IActionMenuItem } from "@amzn/api-parity-react-component/lib/components/ActionMenu/ActionMenu.types";
import { getExportFileFormat } from "../../ExportTableToCSVExcel/ExportTableToCSVExcel";
import { ButtonBar } from "../../common/ButtonBar/ButtonBar";
import { ButtonDropdownProps, StatusIndicator } from "@amzn/awsui-components-react-v3";

export const ParityTableCSVExportTestId = {
  buttonDropdown: "ParityTableCSVExport-buttonDropdown",
};

export const actionMenu: ButtonDropdownProps.Item[] = [
  { id: ExportFormat.Xlsx, text: "Export to Excel" },
];

export const ParityTableCSVExport: React.FunctionComponent<ParityTableCSVExportProps> = (props) => {
  const [shouldFetch, setShouldFetch ] = useState<number>(0);
  const [fileExtension, setFileExtension] = useState<ExportFormat>(ExportFormat.Xlsx)

  const { data, isValidating: loading = true, error }: responseInterface<any, any> = useSWR( shouldFetch ? shouldFetch.toString() : null, async () => {
    const filename = `${getDateForFileName()}_ServiceByRegion (${estimatedDate.filenamePart}).${fileExtension}`;
    return await props.onExport(estimatedDate, filename);
  }, getSwrConfig());

  useEffect(() => {
    if (data) {
      setShouldFetch(0);
    }
  }, [data, error]);

  const handleClick: (fileExtensionType: IActionMenuItem) => void = useCallback(async (fileExtensionType: IActionMenuItem) => {
    setShouldFetch(Date.now());
    setFileExtension(getExportFileFormat(fileExtensionType));
  }, []);

  return (
    <ButtonBar>
      <div data-testid={ParityTableCSVExportTestId.buttonDropdown}>
        <ActionMenu
          items={actionMenu}
          icon={"download"}
          onChange={handleClick}
        />
      </div>
      {loading && <StatusIndicator type={"loading"}/>}
    </ButtonBar>
  );
}
