import * as React from "react";
import * as ReactDOM from "react-dom";
// isomorphic-unfetch is needed or the isitdown component would have issue fetching status data
import "isomorphic-unfetch";
import App from "./App";
import { setFavicon, setupImpersonation } from "./utils/environmentUtil";
import { config } from "aws-sdk";
import { enablePatches, enableMapSet } from "immer";
import { REGION } from "./configs/environmentConfig";
import { setFeature } from "@amzn/api-parity-react-component/lib/feature/featureToggle";
import { Provider } from "react-redux";
import { store } from "./redux/store";
import "rsuite/dist/rsuite.min.css";
import { ENABLE_API_PARITY_JSON_EXPORT } from "./constants";

enablePatches();
enableMapSet();
setFavicon();
setupImpersonation();

setFeature({
  commenting: false,
  export: ENABLE_API_PARITY_JSON_EXPORT,
});

config.region = REGION;

const appReduxStore = store();
const root = document.createElement("div");
document.body.appendChild(root);
ReactDOM.render(
  <Provider store={appReduxStore}>
    <App/>
  </Provider>, root);
