import React, { CSSProperties } from "react";
import moment from "moment";
import { IFeatureServiceItem } from "../../daos/types/IGetFeatureDto"
import { formatEstimatedLaunchDate } from "../../utils/dateUtil"
import { Icon } from "@amzn/awsui-components-react/polaris";
import { GENERALLY_AVAILABLE } from "../../constants";

const featureCellStatusMap = {
  "NOT_PLANNED": {
    style: "ERROR",
    shortHandStatus: "NP"
  },
  "PLANNED": {
    shortHandStatus: "Pl"
  },
  "BUILD": {
    shortHandStatus: "Bu"
  },
  "IA": {
    shortHandStatus: "IA"
  },
  "DATE_EVIL": {
    style: "ERROR"
  },
  "GA": {
    style: "SUCCESS",
    shortHandStatus: "✔"
  },
  "DEFAULT": {
    style: "ERROR",
    shortHandStatus: "NP"
  }
}

export interface IParityCellViewModel {
  style?: CSSProperties;
  renderedText: string;
}

export function getParityCellStyleAndText(item : IFeatureServiceItem, region : string) : IParityCellViewModel {
  let status = item[region]?.status;
  const estimatedLaunchDate = item[region]?.estimatedLaunchDate;

  // If there is no status and no launch date - we need the service team to add data - No Plan
  if (!status && !estimatedLaunchDate) {
    status = "DEFAULT"
  }

  let style = featureCellStatusMap[status]?.style
  let renderedText = featureCellStatusMap[status]?.shortHandStatus

  // If they havent launched yet and have a date - always display that first
  if (estimatedLaunchDate && status !== GENERALLY_AVAILABLE) {
    const diff = moment().diff(estimatedLaunchDate);
    const date_is_in_past = diff > 0

    if (date_is_in_past) {
      status = "DATE_EVIL"
      style = featureCellStatusMap[status]?.style
    }

    renderedText = formatEstimatedLaunchDate(estimatedLaunchDate);
  }

  if (renderedText === "✔") {
    renderedText = (<Icon variant="success" name="status-positive" />);
  }

  return {
    style: style,
    renderedText: renderedText
  }
}
