import React from "react";
import { IErrorLineItem } from "../../models/remoteMutation";
import { ServiceContactUpdateErrorCode } from "../../orchestrators/ServiceContactOrchestrator";
import { contactsWriteAccessSimTemplateLink } from "../common/SimLinks/ContactsWriteAccessSimTemplateLink";
import { Displayable } from "@amzn/api-parity-react-component/lib/models/Displayable";

const INVERT_LINK_COLOR = true;

export const invalidRipNameError = "The RIP Shortname provided is invalid, please ensure it is spelled correctly or that it is registered with RIP.";
export const updateCannotBeValidatedError = "Cannot validate if update has completed successfully, please refresh and verify if the values are correct.";
export const accessDenied = <>Need bindle permission or Recon Service Contacts Write Access to add or update service contacts,
                              please use {contactsWriteAccessSimTemplateLink(INVERT_LINK_COLOR)} to request access to Recon Service Contacts Write permission.</>
export const unknownError = "An unknown error has occurred.";

export function getUpdateError(contactName: string, errors: IErrorLineItem<ServiceContactUpdateErrorCode>[]): Displayable {
  // for now we only deal with single error
  const code: ServiceContactUpdateErrorCode = errors[0].code;
  switch (code) {
    case ServiceContactUpdateErrorCode.DuplicateRip:
    case ServiceContactUpdateErrorCode.DuplicateBuildTask: {
      return `The contact, ${contactName}, already exists in that partition. Please edit the existing contact instead.`;
    }
    case ServiceContactUpdateErrorCode.InvalidRipName: {
      return invalidRipNameError;
    }
    case ServiceContactUpdateErrorCode.UpdateCannotBeValidated: {
      return updateCannotBeValidatedError;
    }
    case ServiceContactUpdateErrorCode.AccessDenied: {
      return accessDenied;
    }
    case ServiceContactUpdateErrorCode.Unknown:
    default: {
      return unknownError;
    }
  }
}
