import React, { FunctionComponent, useCallback, useEffect, useState } from "react";
import {
  Box,
  Button,
  CollectionPreferences,
  CollectionPreferencesProps,
  Header,
  NonCancelableCustomEvent,
  Pagination,
  PaginationProps,
  PropertyFilter,
  PropertyFilterProps,
  SpaceBetween,
  Spinner,
  Table
} from "@amzn/awsui-components-react-v3";
import { useCollection } from "@amzn/awsui-collection-hooks";
import {
  COLUMN_DEFINITIONS,
  CUSTOM_FILTERING_GROUPS,
  DEFAULT_COLLECTION_PREFERENCES,
  DEFAULT_I18N_STRINGS,
  DEFAULT_TABLE_PREFERENCES,
  FILTERING_PROPERTIES
} from "./ProductLaunchesProperties";
import { LaunchTableVM, ProductLaunchesProps } from "./ProductLaunches.types";
import { getDataTestIdProp } from "../../utils/testUtil";
import { ReactRouterButton } from "../common/RouterComponents/ReactRouterButton";
import { generateProductLaunchesWorkbook } from "./ProductLaunchesCsvExport";
import { ExportTableToCSVExcel } from "../ExportTableToCSVExcel/ExportTableToCSVExcel";
import { LaunchState } from "./LaunchWizard/LaunchWizard.types";
import { LaunchVOToLaunchTableVM } from "./ProductLaunchesUtil";

export const ProductLaunchesTestIdSuffixes = {
  table: "-table",
  emptyTableDisplay: "-emptyTableDisplay",
  tableHeader: "-tableHeader",
  propertyFilter: "-propertyFilter",
  pagination: "-pagination",
  collectionPreferences: "-collectionPreferences",
  actionDropdown: "-dropdown",
  createButton: "-createButton",
  buttonDraft: "-buttonDraft",
  buttonShipped: "-buttonShipped",
  buttonInProgress: "-buttonInProgress",
  buttonOhHold: "-buttonOnHold",
  buttonCancelled: "-buttonCancelled",
}

const EmptyTableDisplay = (props) => (
  <Box
    textAlign="center"
    color="inherit"
    {...getDataTestIdProp(props, ProductLaunchesTestIdSuffixes.emptyTableDisplay)}
  >
    <b>No launches</b>
    <Box
      padding={{ bottom: "s" }}
      variant="p"
      color="inherit"
    >
      No launches found for product{props.items?.length > 0 && " matching that filter"}.
    </Box>
  </Box>
);

const EmptyState = ({ title, subtitle, action }) => (
  <Box textAlign="center" color="inherit">
    <Box variant="strong" textAlign="center" color="inherit">
      {title}
    </Box>
    <Box variant="p" padding={{ bottom: "s" }} color="inherit">
      {subtitle}
    </Box>
    {action}
  </Box>
);

const TableHeader = ({ count, generateDataBook, ...props }) => (
  <Header
    counter={
      "(" + count + ")"
    }
    actions={
      <SpaceBetween direction="horizontal" size="xs">
        {/*<ButtonDropdown disabling for now
          items={[
            { text: "Action 1", id: "1", disabled: false },
            { text: "Action 2", id: "2", disabled: false },
            { text: "Action 3", id: "3", disabled: false }
          ]}
          expandToViewport={true}
          {...getDataTestIdProp(props, ProductLaunchesTestIdSuffixes.actionDropdown)}
        >
          Actions
        </ButtonDropdown>*/}
        <ReactRouterButton
          variant="primary"
          to="launches/create"
          {...getDataTestIdProp(props, ProductLaunchesTestIdSuffixes.createButton)}
        >
          Create a new launch
        </ReactRouterButton>
        <ExportTableToCSVExcel
          disabled={props.loading}
          generateDataBook={generateDataBook}
          fileNamePrefix="ProductLaunches"
        />
      </SpaceBetween>
    }
    {...getDataTestIdProp(props, ProductLaunchesTestIdSuffixes.tableHeader)}
  >
    {props.loading && <Spinner/>}
    Launches
  </Header>
)


const FilterButton = ({ label, value, onClick, selected, ...props }) => {
  return (
    <Box
      padding={{ right: "m", bottom: "m" }}
      display="inline-block"
    >
      <Button
        variant={selected ? "normal" : "link"}
        onClick={onClick}
        {...getDataTestIdProp(props)}
      >
        <Box
          margin={{ bottom: "xxxs" }}
        >
          {label}
        </Box>
        <div
        >
          {value}
        </div>
      </Button>
    </Box>
  );
}

export const ProductLaunches: FunctionComponent<ProductLaunchesProps> = (props) => {
  const {
    loading = true,
    page = 1,
    filter = {
      operation: "and",
      tokens: [{ propertyKey: "state", operator: "=", value: LaunchState.IN_PROGRESS }]
    },
    preferences: propPreferences = DEFAULT_TABLE_PREFERENCES,
    onPreferenceChange,
    onPaginationChange,
    onFilterChange,
  } = props;
  const [launchVMs, setLaunchVMs] = useState<LaunchTableVM[]>(LaunchVOToLaunchTableVM(props.items));
  const [preferences, setPreferences] = useState<any>(propPreferences);
  const [launchCount, setLaunchCount] = useState<any>({
    draft: 0,
    shipped: 0,
    inProgress: 0
  });
  const [selectedStateButton, setSelectedStateButton] = useState<LaunchState | undefined>(LaunchState.IN_PROGRESS);
  // const [selectedItems, setSelectedItems] = useState<LaunchVO[]>([]);

  const { items, actions, filteredItemsCount, collectionProps, propertyFilterProps, paginationProps } = useCollection(
    launchVMs,
    {
      propertyFiltering: {
        filteringProperties: FILTERING_PROPERTIES,
        defaultQuery: filter,
        empty: (
          <EmptyState
            title="No launches"
            subtitle="No launches to display."
            action={<></>}
          />
        ),
        noMatch: (
          <EmptyState
            title="No matches"
            subtitle="No matching items found."
            action={<Button onClick={() => actions.setFiltering("")}>Clear filter</Button>}
          />
        )
      },
      pagination: {
        pageSize: preferences.pageSize,
        defaultPage: page,
      },
      sorting: {
        defaultState: {
          sortingColumn: COLUMN_DEFINITIONS[0], // find a better way to define this
          isDescending: false
        }
      },
      selection: {},
    }
  );

  // convert VO's to VM's
  useEffect(() => {
    setLaunchVMs(LaunchVOToLaunchTableVM(props.items));
  }, [props.items]);

  // on query changes, attempt to find matching launch state filter button
  useEffect(() => {
    const foundQuery = propertyFilterProps.query.tokens
      .find((token) => token.propertyKey === "state")

    if (foundQuery && foundQuery.operator === "=") {
      setSelectedStateButton(foundQuery.value as LaunchState);
    } else {
      setSelectedStateButton(undefined);
    }
  }, [propertyFilterProps.query]);

  // count launch states and store them to be displayed
  useEffect(() => {
    const defaultCount = { draft: 0, shipped: 0, inProgress: 0, onHold: 0, cancelled: 0 };
    setLaunchCount(props.items.reduce<any>((counts, launch) => {
      switch (launch.state) {
        case "DRAFT":
          counts.draft += 1;
          break;
        case "SHIPPED":
          counts.shipped += 1;
          break;
        case "IN_PROGRESS":
          counts.inProgress += 1;
          break;
        case "ON_HOLD":
          counts.onHold += 1;
          break;
        case "CANCELLED":
          counts.cancelled += 1;
          break;
      }
      return counts;
    }, defaultCount));
  }, [props.items]);

  const getExportDataBook = useCallback(() => {
    return generateProductLaunchesWorkbook(props.items);
  }, [props.items]);

  const preferenceOnChange = useCallback((event: NonCancelableCustomEvent<CollectionPreferencesProps.Preferences>) => {
    setPreferences(event.detail);
    onPreferenceChange?.(event);
  }, [onPreferenceChange]);

  const paginationOnChange = useCallback((event: NonCancelableCustomEvent<PaginationProps.ChangeDetail>) => {
    onPaginationChange?.(event);
    paginationProps.onChange(event)
  }, [onPaginationChange, paginationProps]);

  const filterOnChange = useCallback((event: NonCancelableCustomEvent<PropertyFilterProps.Query>) => {
    onFilterChange?.(event);
    propertyFilterProps.onChange(event);
  }, [onFilterChange, propertyFilterProps])

  const defaultFilterHandler = useCallback((filterValue) => {
    const newQuery: PropertyFilterProps.Query = {
      operation: propertyFilterProps.query.operation,
      tokens: [
        { propertyKey: "state", operator: "=", value: LaunchState[filterValue] },
        ...propertyFilterProps.query.tokens.filter((token) => token.propertyKey !== "state")
      ]
    }

    filterOnChange(
      {
        detail: newQuery
      } as any
    );
  }, [filterOnChange, propertyFilterProps.query.operation, propertyFilterProps.query.tokens])

  return (
    <>
      <Box variant="h1">
        Launches
      </Box>
      <>
        <FilterButton
          label="In-progress launches"
          value={launchCount.inProgress}
          onClick={() => defaultFilterHandler("IN_PROGRESS")}
          selected={selectedStateButton === LaunchState.IN_PROGRESS}
          {...getDataTestIdProp(props, ProductLaunchesTestIdSuffixes.buttonInProgress)}
        />
        <FilterButton
          label="Draft launches"
          value={launchCount.draft}
          onClick={() => defaultFilterHandler("DRAFT")}
          selected={selectedStateButton === LaunchState.DRAFT}
          {...getDataTestIdProp(props, ProductLaunchesTestIdSuffixes.buttonDraft)}
        />
        <FilterButton
          label="On-hold launches"
          value={launchCount.onHold}
          onClick={() => defaultFilterHandler("ON_HOLD")}
          selected={selectedStateButton === LaunchState.ON_HOLD}
          {...getDataTestIdProp(props, ProductLaunchesTestIdSuffixes.buttonOhHold)}
        />
        <FilterButton
          label="Shipped launches"
          value={launchCount.shipped}
          onClick={() => defaultFilterHandler("SHIPPED")}
          selected={selectedStateButton === LaunchState.SHIPPED}
          {...getDataTestIdProp(props, ProductLaunchesTestIdSuffixes.buttonShipped)}
        />
        <FilterButton
          label="Cancelled launches"
          value={launchCount.cancelled}
          onClick={() => defaultFilterHandler("CANCELLED")}
          selected={selectedStateButton === LaunchState.CANCELLED}
          {...getDataTestIdProp(props, ProductLaunchesTestIdSuffixes.buttonCancelled)}
        />
      </>
      <Table
        {...collectionProps}
        trackBy="id"
        columnDefinitions={COLUMN_DEFINITIONS}
        items={items}
        loading={loading && (items?.length === 0 || !items)}
        loadingText="Loading launches"
        visibleColumns={preferences.visibleContent}
        // selectionType="multi"
        // selectedItems={selectedItems}
        // onSelectionChange={({ detail }) => setSelectedItems(detail.selectedItems)}
        empty={<EmptyTableDisplay {...props} />}
        filter={
          <PropertyFilter
            i18nStrings={DEFAULT_I18N_STRINGS}
            virtualScroll // improve performance significantly
            countText={`${filteredItemsCount} matches`}
            {...propertyFilterProps}
            customGroupsText={CUSTOM_FILTERING_GROUPS}
            onChange={filterOnChange}
            {...getDataTestIdProp(props, ProductLaunchesTestIdSuffixes.propertyFilter)}
          />
        }
        header={
          <TableHeader
            generateDataBook={getExportDataBook}
            count={props.items.length ?? 0}
            {...props}
          />
        }
        pagination={
          <Pagination
            {...paginationProps}
            ariaLabels={{
              nextPageLabel: "Next page",
              previousPageLabel: "Previous page",
              pageLabel: pageNumber =>
                `Page ${pageNumber} of all pages`
            }}
            onChange={paginationOnChange}
            {...getDataTestIdProp(props, ProductLaunchesTestIdSuffixes.pagination)}
          />
        }
        preferences={
          <CollectionPreferences
            {...DEFAULT_COLLECTION_PREFERENCES}
            preferences={preferences}
            onConfirm={preferenceOnChange}
            {...getDataTestIdProp(props, ProductLaunchesTestIdSuffixes.collectionPreferences)}
          />
        }
        {...getDataTestIdProp(props, ProductLaunchesTestIdSuffixes.table)}
      />
    </>
  );
};
