import React, { FunctionComponent, useCallback } from "react";
import useSWR, { responseInterface } from "swr";
import { getSwrConfig } from "../../daos/swrUtil";
import { ServiceContactsDao } from "../../daos/ServiceContactsDao";
import { addNameVariationsToContacts, parseAllContactsDto } from "../../parsers/serviceContactsParser";
import { ServiceContactsTable } from "../../components/ServiceContacts/ServiceContactsListTable/ServiceContactsTable";
import { Flashbar, FlashbarProps } from "@amzn/awsui-components-react-v3";
import { getAppState } from "../../models/ApplicationState";
import useBreadcrumbs from "../../components/Breadcrumbs/useBreadcrumbs";
import { NoAccessError } from "../../components/common/NoAccessError";
import { EnterServiceContactsFormType } from "../../components/ServiceContacts/EnterServiceContact/AddOrEditServiceContactModal/AddOrEditServiceContactModalConstants";
import { LoadStateEnum } from "../../models/LoadStateEnum";
import { useSelector } from "react-redux";
import { serviceListSelector, serviceLoadStateSelector } from "../../redux/selectors/serviceSelector";
import { addOrUpdateServiceContact } from "./orchestratorContainerUtils";
import { RegionDao } from "../../daos/RegionDao";
import { IRegionMetadata } from "../../daos/types/IRegionMetaData";

const dao: ServiceContactsDao = new ServiceContactsDao();

export const ServiceContactsTableContainer: FunctionComponent<{}> = () => {
  return getAppState().userAbilities.canRead ? <AccessibleServiceContactsTableContainer/> : <NoAccessError/>;
}

export const AccessibleServiceContactsTableContainer: FunctionComponent<{}> = () => {
  useBreadcrumbs([ "Contacts" ]);

  const [flashMessage, setFlashMessage] = React.useState<any>([]);
  const [onlyInRmsGraph, setOnlyInRmsGraph] = React.useState<boolean>(true);

  const serviceContactsSwr = useSWR(() => `contacts${onlyInRmsGraph}`, async () => {
    const dao = new ServiceContactsDao();
    const dto = await dao.getAllContacts(onlyInRmsGraph);
    const vo = parseAllContactsDto(dto);
    return vo;
  }, getSwrConfig());

  const table_onRefreshItems = useCallback(() => {
    (serviceContactsSwr as responseInterface<any, any>).revalidate?.();
  }, [serviceContactsSwr]);

  const allServicesLoadingState: LoadStateEnum = useSelector(serviceLoadStateSelector);
  const allServices = useSelector(serviceListSelector);

  addNameVariationsToContacts(serviceContactsSwr.data ?? [], allServices)

  const modal_displaySuccessfulSubmitMessage = useCallback( (successfulServices: string[], failedServices: string[], isAddEditOrDelete: EnterServiceContactsFormType): void => {
    let flashMessages: FlashbarProps.MessageDefinition[] = [];

    successfulServices.length > 0 && flashMessages.push({
      type: "success",
      content: `Successfully ${isAddEditOrDelete === EnterServiceContactsFormType.Add? "added" : isAddEditOrDelete === EnterServiceContactsFormType.Delete? "deleted" :"updated"} ${successfulServices.join(", ")}.`,
      dismissible: true,
      onDismiss: () => setFlashMessage([])
    })

    failedServices.length > 0 && flashMessages.push({
      type: "error",
      content: `Failed to ${isAddEditOrDelete === EnterServiceContactsFormType.Add? "add" :isAddEditOrDelete === EnterServiceContactsFormType.Delete? "delete" : "update"} ${failedServices.join(", ")}.`,
      dismissible: true,
      onDismiss: () => setFlashMessage([])
    })

    setFlashMessage(flashMessages)
    table_onRefreshItems()
  }, [table_onRefreshItems]);

  // Wrapper function so the .types.ts file can declare a simple signature for onChangeRmsGraphToggle to be  "boolean => void" instead of something
  // much more restrictive and confusing like the signature of useState().
  const table_onChangeRmsGraphToggle = (toggleValue: boolean) => {
    setOnlyInRmsGraph(toggleValue);
  }

  
  const serviceContactsPartitionRegionsSWR = useSWR("regions-partitions", async () => {
    const regionDao: RegionDao = new RegionDao();
    const regions: IRegionMetadata[] = await regionDao.getRegions();
    const partitionToRegionsMap = {};
    var region;
    for (region in regions) {
      if (!partitionToRegionsMap[regions[region].partition]) {
        partitionToRegionsMap[regions[region].partition] = [];
      }
      partitionToRegionsMap[regions[region].partition].push(regions[region].airportCode);
    }
    var partition;
    for (partition in partitionToRegionsMap) {
      partitionToRegionsMap[partition] = partitionToRegionsMap[partition].join(", ")
    }
    return partitionToRegionsMap;
  }, getSwrConfig())

  return <React.Fragment>
    <Flashbar items={flashMessage}/>
    <ServiceContactsTable
      items={serviceContactsSwr.data || []}
      regionsByPartition={serviceContactsPartitionRegionsSWR.data || {}}
      userAbilities={getAppState().userAbilities}
      loading={serviceContactsSwr.isValidating || serviceContactsPartitionRegionsSWR.isValidating || allServicesLoadingState === LoadStateEnum.Loading}
      onRefreshItems={table_onRefreshItems}
      onSuccessfulSubmitDisplaySuccess={modal_displaySuccessfulSubmitMessage}
      onDeleteSubmit={dao.deleteServiceContact.bind(dao)}
      onAddOrEditServiceContactSubmit={addOrUpdateServiceContact}
      onBulkEditServiceContactSubmit={dao.bulkEditServiceContacts.bind(dao)}
      rmsGraphToggle={onlyInRmsGraph}
      onChangeRmsGraphToggle={table_onChangeRmsGraphToggle}
    />
  </React.Fragment>

};
