import { Nullable } from "../models/types/Nullable";
import { LoadStateEnum } from "../models/LoadStateEnum";
import { LoadingStatus } from "../models/vos/DetailLoadingVO";
import { FeatureVO } from "../models/vos/FeatureVO";
import { RegionVO } from "@amzn/api-parity-react-component/lib/models/vos/RegionVO";
import { getCompositeId } from "@amzn/api-parity-react-component/lib/utils/modelUtil";

export function hydrateModel<T>(from: Nullable<Partial<T>>, to: T): void {
  if (!from) {
    return;
  }

  Object.assign<T, Partial<T>>(to, from);
}

export function loadStateToLoadingStatus(state: LoadStateEnum): LoadingStatus {
  switch (state) {
    case LoadStateEnum.Loaded:
      return LoadingStatus.Success;
    case LoadStateEnum.Loading:
      return LoadingStatus.Loading;
    case LoadStateEnum.Error:
      return LoadingStatus.Error;
    default:
      return LoadingStatus.NotStarted;
  }
}

/**
 * Loosely compare two string by removing leading/trailing empty space, null and undefined are treated as empty string
 */
export function compareStringIgnorePaddingSpace(s1: Nullable<string>, s2: Nullable<string>): boolean {
  const adjustedString1: string = (s1 ?? "").trim();
  const adjustedString2: string = (s2 ?? "").trim();
  return adjustedString1 === adjustedString2;
}


export function computeFeatureParityId(feature: FeatureVO, region: RegionVO): string {
  return getCompositeId(feature.id, region.id);
}
