import * as React from "react";
import { Alert, Link } from "@amzn/awsui-components-react-v3";

/**
 * Please see https://issues.amazon.com/recon-5067 and https://issues.amazon.com/recon-5059 for the reason
 * behind this component, delete this component once Safari is confirmed working
 */
export const SafariAccess: React.FunctionComponent<{}> = () => {
  return <Alert data-testid="SafariAccess" type="warning">
    You are using Safari browser, you may experience issue when using RECON, please try one of the following solutions:
    <ul>
      <li>Browse our application in <Link href="https://support.apple.com/guide/safari/browse-in-private-ibrw1069" external={true}>Private Mode</Link></li>
      <li>Or turn off <Link href="https://support.apple.com/guide/safari/privacy-sfri35610" external={true}>Prevent cross-site checking in Safari</Link></li>
      <li>Or use our application in <Link href="https://google.com/chrome" external={true}>Chrome</Link> or <Link href="https://getfirefox.com/" external={true}>Firefox</Link></li>
    </ul>
  </Alert>
};

export const SafariAccessTestId = {
  component: "SafariAccess"
};
