import { FeatureInRegionCommentVO } from "../models/vos/FeatureInRegionCommentVO";
import { Optional } from "../models/types/Optional";
import { FeatureParityVO, FeatureVO } from "../models/vos";
import {
  FeatureCollection,
  FeatureCollectionView,
  FeatureParityCollection,
  FeatureParityCollectionView,
  FeatureParityOverrideCollection,
  FeatureParityOverrideCollectionView
} from "../models/collections";
import {
  AwsServiceCollection,
  AwsServiceCollectionView,
  AwsServiceExpansionPlanEnum,
  AwsServiceVO,
  RegionCollectionView,
  ServiceBuildPlanCollection,
  ServiceBuildPlanCollectionView,
  ServiceBuildPlanVO
} from "@amzn/api-parity-react-component";
import {
  isApiParityExemptFromBuildPlan,
  isAto,
  isInParity,
  isInProgressWithDate,
  isNotLaunching
} from "@amzn/api-parity-react-component/lib/utils/serviceBuildPlanUtil";
import { computeFeatureParityId } from "./modelUtil";
import { ParityStatusEnum } from "@amzn/api-parity-react-component/lib/models/ParityStatus";
import {
  FeatureInRegionStatusEnum,
  serviceParityStatusToFeatureInRegionStatusEnumMap
} from "../models/FeatureInRegionStatus";
import { FeatureTreeItem } from "../models/FeatureTreeItem";
import { RegionCollection } from "@amzn/api-parity-react-component/lib/models/collections/RegionCollection";
import { ParityRollupStatistics } from "../models/ParityRollupStatistics";

/**
 * Maximum tree item depth
 * This is to avoid any circular dependencies between services which could create an
 * infinite-depth feature tree
 */
export const MAX_TREE_DEPTH = 5;

export const createFeatureRegionKey = (feature: string, region: string): string => feature + region

export const createFeatureIdAndRegionToCommentMap = (comments: FeatureInRegionCommentVO[]): Map<string, FeatureInRegionCommentVO> => {
  return new Map<string, FeatureInRegionCommentVO>(
    comments.map(comment => [createFeatureRegionKey(comment.feature, comment.region), comment])
  );
}

export const getCommentFromMap = (feature: string,
                                  region: string,
                                  featureIdAndRegionToComment: Map<string, FeatureInRegionCommentVO>): Optional<FeatureInRegionCommentVO> => {
  return featureIdAndRegionToComment.get(createFeatureRegionKey(feature, region))
}


const delimiter = ":";

export function computeUniqueFeatureId(feature: FeatureVO): string;
export function computeUniqueFeatureId(serviceId: string, featureId: string): string;
/**
 * Computes unique id for feature from it and parent service. Above signatures show argument options
 * @param featureOrServiceId: feature object or (if featureId supplied) the feature name as string
 * @param featureId?: the id of the parent service
 */
export function computeUniqueFeatureId(featureOrServiceId: FeatureVO | string, featureId?: string): string {
  const servicePart: string = (typeof(featureOrServiceId) === "string") ? featureOrServiceId : featureOrServiceId.service.id;
  const featurePart: string = (typeof(featureOrServiceId) === "string") ? featureId as string : featureOrServiceId.id;

  // If the feature id already has service||apiName, we can just ignore it
  const alreadyUnique: boolean = featurePart.includes(delimiter);
  if (alreadyUnique) {
    return featurePart;
  }
  return `${servicePart}${delimiter}${featurePart.toLowerCase()}`;
}


/**
 * Combine feature parity and overrides into a new Feature Parity collection
 * @param featureParities
 * @param overrides
 */
export function combineFeatureParityOverrides(
  featureParities: FeatureParityCollection | FeatureParityCollectionView,
  overrides: FeatureParityOverrideCollection | FeatureParityOverrideCollectionView,
): FeatureParityCollection {
  const computed: FeatureParityCollection = new FeatureParityCollection();
  computed.pauseChangeEvent();

  // go through each parities in the original list
  // and override the parity if there is a record in the overrides
  computed.addRange(featureParities.items.map((currentParity) => {
    return overrides.byFeatureRegion(currentParity.feature, currentParity.region) ?? currentParity;
  }));

  overrides.items.forEach((override) => {
    if (!computed.byFeatureRegion(override.feature, override.region)) {
      computed.add(override);
    }
  });
  computed.resumeChangeEvent();
  return computed;
}



/**
 * Apply Service parities to feature parities, when applying
 * if the Service parity is determined to have exception, ATO/NL, or have date
 * for every feature under the service, if there is no parity record
 * a feature parity with the exception would be created
 *
 * The operation can also be seen as "propagat-down" process, where service parity status
 * is bubbled down to child features' parity statuses
 *
 * @param serviceParities
 * @param featureParities
 * @param services
 * @param features
 */
export function applyServiceParities(
  serviceParities: ServiceBuildPlanCollection | ServiceBuildPlanCollectionView,
  featureParities: FeatureParityCollection,
  services: AwsServiceCollection | AwsServiceCollectionView,
  features: FeatureCollection | FeatureCollectionView,
): void {
  for (const service of services.items) {
    const paritiesOfService: readonly ServiceBuildPlanVO[] = serviceParities.byService(service);
    if (paritiesOfService.length === 0) {
      continue;
    }

    const featuresOfService: readonly FeatureVO[] = features.byService(service);
    if (featuresOfService.length === 0) {
      continue;
    }

    paritiesOfService.forEach((serviceParity) =>
      featureParities.addRange(applyServiceParityToFeatureParities(serviceParity, featuresOfService, featureParities))
    );
  }
}

/**
 *
 * Takes a list of features and returns a FeatureParity object for each one that has the same parent service as serviceParity
 * .
 * If the feature parity already exists in the featureParities collection, that object is used, and a planned date is added
 * from the plannedDate of the serviceParity
 *
 * If the feature parity doesn't exist in the featureParities collection, it is created from the attributes of serviceParity
 *
 * The objects are tagged as having their statusInferredFrom the serviceParity object
 *
 * @param serviceParity - the service that *should* be associated with each entry in features. If not, the feature is skipped
 * @param features - the list of features to create parities for
 * @param featureParities - the already-known list of parities that this function will use for reference and fill the gaps of
 */
export function applyServiceParityToFeatureParities(
  serviceParity: ServiceBuildPlanVO,
  features: readonly FeatureVO[],
  featureParities: FeatureParityCollection | FeatureParityCollectionView,
): FeatureParityVO[] {
  // Feature parity and api parity share the same logic
  if (!isApiParityExemptFromBuildPlan(serviceParity)) {
    return [];
  }

  const result: FeatureParityVO[] = [];

  const service = serviceParity.service;
  const region = serviceParity.region;
  const status = serviceParityToFeatureParityStatus(serviceParity);
  for (const feature of features) {
    // The features supplied should belong to the service, skip those which are not
    if (feature.service !== service) {
      continue;
    }

    if (!featureParities.byFeatureRegion(feature, region)) {
      const featureParity = new FeatureParityVO({
        id: computeFeatureParityId(feature, region),
        feature,
        region,
        parity: ParityStatusEnum.NoData,
        plannedDate: serviceParity.date,
        status,
        statusInferredFrom: serviceParity,
      });
      result.push(featureParity);
    } else {
      const parity: FeatureParityVO = featureParities.byFeatureRegion(feature, region) as FeatureParityVO;
      if (parity.status !== FeatureInRegionStatusEnum.GA && !parity.plannedDate) {
        parity.plannedDate = parity.plannedDate ?? serviceParity.date;
        parity.statusInferredFrom = serviceParity;
        parity.status = status;
      }
    }
  }

  return result;
}

export function serviceParityToFeatureParityStatus(serviceParity: ServiceBuildPlanVO): FeatureInRegionStatusEnum {
  if (isAto(serviceParity)) {
    return FeatureInRegionStatusEnum.Ato;
  }

  if (isNotLaunching(serviceParity)) {
    return FeatureInRegionStatusEnum.NotLaunching;
  }

  if (isInProgressWithDate(serviceParity)) {
    return FeatureInRegionStatusEnum.Planned;
  }

  return serviceParityStatusToFeatureInRegionStatusEnumMap.get(serviceParity.status) ?? FeatureInRegionStatusEnum.IA;
}

export interface IFeatureParityLookup {
  services: AwsServiceCollection | AwsServiceCollectionView;
  regions: RegionCollection | RegionCollectionView;
  features: FeatureCollection | FeatureCollectionView;
  parities: FeatureParityCollection | FeatureParityCollectionView;
}

export function featureDataToTreeItems(
  rootServices: AwsServiceCollection | AwsServiceCollectionView,
  lookup: IFeatureParityLookup
): FeatureTreeItem[] {
  return rootServices.items.map((service) => serviceToTreeItem(service, lookup));
}

export const serviceTreeItemMap: Map<AwsServiceVO, FeatureTreeItem> = new Map<AwsServiceVO, FeatureTreeItem>();

export function serviceToTreeItem(
  service: AwsServiceVO,
  lookup: IFeatureParityLookup,
): FeatureTreeItem {
  if (serviceTreeItemMap.has(service)) {
    return serviceTreeItemMap.get(service) as FeatureTreeItem;
  }

  const item: FeatureTreeItem = new FeatureTreeItem({
    id: service.id,
    name: service.name,
    entity: service,
  });

  const childFeatures: readonly FeatureVO[] = lookup.features.byService(service);
  if (childFeatures.length && !hasPossibleCircularDependencies(item)) {
    populateItemChildren(item, childFeatures, lookup);
  }

  serviceTreeItemMap.set(service, item);
  return item;
}

export const featureTreeItemMap: Map<FeatureVO, FeatureTreeItem> = new Map<FeatureVO, FeatureTreeItem>();

export function featureToTreeItem(
  feature: FeatureVO,
  lookup: IFeatureParityLookup,
): FeatureTreeItem {
  if (featureTreeItemMap.has(feature)) {
    return featureTreeItemMap.get(feature) as FeatureTreeItem;
  }

  const item: FeatureTreeItem = new FeatureTreeItem({
    id: feature.id,
    name: feature.name,
    entity: feature,
  });

  if (feature.markedAsFrom) {
    const childFeatures: readonly FeatureVO[] = lookup.features.byService(feature.markedAsFrom);
    if (childFeatures.length && !hasPossibleCircularDependencies(item)) {
      populateItemChildren(item, childFeatures, lookup);
    }
  }

  featureTreeItemMap.set(feature, item);
  return item;
}

export function populateItemChildren(
  item: FeatureTreeItem,
  childFeatures: readonly FeatureVO[],
  lookup: IFeatureParityLookup,
): void {
  for (const feature of childFeatures) {
    item.addChild(featureToTreeItem(feature, lookup));
  }
}

/**
 * Check if there is a risk of circular dependencies between service and feature
 * This is because a feature could be a service tagged as feature, thus there is a
 * risk of circular dependencies.  This should have been guarded upstream but unfortunately
 * the upstream (where tagged as feature) does not have such logic in-place.
 *
 * For now, the existence of this function is to catch this issue by a naive check of
 * tree depth, and see if it has reached maximum depth
 * @param item
 */
export function hasPossibleCircularDependencies(item: FeatureTreeItem): boolean {
  if (item.depth >= MAX_TREE_DEPTH) {
    // TODO: Make the warning message more helpful by printing the dependency tree
    // NOTE: If a circular dep is even possible, based on testing I'm unsure it wouldn't just throw an error
    console.warn(`${item.name} may have risk of circular dependencies`);
    return true;
  }

  return false;
}

/**
 * Return the root services shown in feature parity dashboard
 * @param services
 */
export function getInitialServices(services: AwsServiceCollectionView): AwsServiceCollectionView {
  return new AwsServiceCollectionView(
    services,
    {
      filter: (service) => !!service.expansionPlan
        && service.expansionPlan !== AwsServiceExpansionPlanEnum.None
        && service.expansionPlan !== AwsServiceExpansionPlanEnum.Unknown
        && service.visibility === "EXTERNAL"
    }
  );
}

export function isService(featureOrService: FeatureVO | AwsServiceVO): boolean {
  return !(featureOrService instanceof FeatureVO);
}

export function getServiceParityRollupScore(serviceParity: Optional<ServiceBuildPlanVO>): ParityRollupStatistics {
  const stats: ParityRollupStatistics = new ParityRollupStatistics({
    rawTotal: 1,
  });

  if (!serviceParity) {
    // there is no feature parity, that means the feature is not available in the region
    stats.expectedTotal = 1;
    stats.notAvailable = 1;
    return stats;
  }

  if (isAto(serviceParity)) {
    stats.ato = 1;
  } else if (isNotLaunching(serviceParity)) {
    stats.notLaunching = 1;
  } else if (isInProgressWithDate(serviceParity)) {
    stats.plannedLaunch = 1;
  } else if (isInParity(serviceParity)) {
    stats.expectedTotal = 1;
    stats.ga = 1;
  } else {
    stats.expectedTotal = 1;
    stats.notAvailable = 1;
  }

  return stats;
}


export function getFeatureParityRollupScore(featureParity: Optional<FeatureParityVO>): ParityRollupStatistics {
  const stats: ParityRollupStatistics = new ParityRollupStatistics({
    rawTotal: 1,
  });

  if (!featureParity) {
    // there is no feature parity, that means the feature is not available in the region
    stats.expectedTotal = 1;
    stats.notAvailable = 1;
    return stats;
  }

  switch (featureParity.status) {
    case FeatureInRegionStatusEnum.NotLaunching:
      stats.notLaunching = 1;
      break;
    case FeatureInRegionStatusEnum.Ato:
      stats.ato = 1;
      break;
    case FeatureInRegionStatusEnum.GA:
      stats.expectedTotal = 1;
      stats.ga = 1;
      break;
    case FeatureInRegionStatusEnum.Planned:
      if (!featureParity.plannedDate) {
        stats.expectedTotal = 1;
      }
      stats.plannedLaunch = 1;
      break;
    default:
      stats.expectedTotal = 1;
      stats.notAvailable = 1;
      break;
  }

  return stats;
}
