import { MultiSelectItemId } from "../IMultiSelectComponent";
import { SelectProps } from "@amzn/awsui-components-react-v3";
import { getIdFromItem, getLabelFromItem } from "../mutlSelectUtil";
import { mapBy } from "@amzn/api-parity-react-component/lib/utils/lookupUtil";


export function itemToOption<T>(
  item: T,
  idFunction?: (x: T) => MultiSelectItemId,
  labelFunction?: (x: T) => string | JSX.Element,
): SelectProps.Option {
  return {
    value: getIdFromItem(item, idFunction),
    label: getLabelFromItem(item, labelFunction) as string,
  };
}


export function selectedToOptions<T>(
  selected: MultiSelectItemId[] | T[],
  items: T[],
  idFunction?: (item: T) => MultiSelectItemId,
  labelFunction?: (item: T) => string | JSX.Element,
): SelectProps.Option[] {
  if (selected.length < 1) {
    return [];
  }

  const itemsById: Map<MultiSelectItemId, T> = mapBy(items, (x) => getIdFromItem(x, idFunction));
  const selectedOptions: SelectProps.Option[] = [];
  selected.forEach((s: MultiSelectItemId | T) => {
    const isId: boolean = typeof (s) === "string";
    const itemId: string = (isId) ? s as MultiSelectItemId : getIdFromItem(s as T, idFunction);
    if (itemsById.has(itemId)) {
      selectedOptions.push(
        itemToOption(
          itemsById.get(itemId) as T,
          idFunction,
          labelFunction,
        )
      );
    }
  });


  return selectedOptions;
}


export function optionsToItems<T>(
  options: SelectProps.Option[],
  items: T[],
  idFunction?: (item: T) => MultiSelectItemId
): T[] {
  if (options.length < 1) {
    return [];
  }

  const itemsById: Map<MultiSelectItemId, T> = mapBy(items, (x) => getIdFromItem(x, idFunction));
  const selected: T[] = [];
  options.forEach((o: SelectProps.Option) => {
    const itemId: MultiSelectItemId = o.value as MultiSelectItemId;
    if (itemsById.has(itemId)) {
      selected.push(itemsById.get(itemId) as T);
    }
  });

  return selected;
}


const virtualScrollThreshold = 100;
// This function is a work-around of a Polaris bug https://i.amazon.com/issues/AWSUI-12947
// The bug impacts multiselect with small number of options.
export function shouldUseVirtualScrolling<T>(items: T[]): boolean {
  return items.length >= virtualScrollThreshold;
}
