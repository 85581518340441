import { ServiceBuildPlanStatusEnum } from "@amzn/api-parity-react-component/lib/models/vos/ServiceBuildPlanVO";

export enum FeatureInRegionStatusEnum {
  GA = "GA",
  IA = "IA",
  Build = "Build",
  Planned = "Planned",
  NotPlanned = "NotPlanned",
  Bvpc = "BVPC",
  NotLaunching = "NotLaunching",
  Ato = "ATO",
}

/**
 * Mapping how status is encoded from RIP to feature in region status enum
 */
export const ripStatusToFeatureInRegionStatusEnumMap: ReadonlyMap<string, FeatureInRegionStatusEnum> = new Map([
  ["GA", FeatureInRegionStatusEnum.GA],
  ["IA", FeatureInRegionStatusEnum.IA],
  ["BUILD", FeatureInRegionStatusEnum.Build],
  ["PLANNED", FeatureInRegionStatusEnum.Planned],
  ["NOT_PLANNED", FeatureInRegionStatusEnum.NotPlanned],
  ["ATO", FeatureInRegionStatusEnum.Ato],
  ["NL", FeatureInRegionStatusEnum.NotLaunching],
  ["BVPC", FeatureInRegionStatusEnum.Bvpc],
]);


export const serviceParityStatusToFeatureInRegionStatusEnumMap: ReadonlyMap<ServiceBuildPlanStatusEnum, FeatureInRegionStatusEnum> = new Map([
  [ServiceBuildPlanStatusEnum.GA, FeatureInRegionStatusEnum.GA],
  [ServiceBuildPlanStatusEnum.IA, FeatureInRegionStatusEnum.IA],
  [ServiceBuildPlanStatusEnum.Build, FeatureInRegionStatusEnum.Build],
  [ServiceBuildPlanStatusEnum.Planned, FeatureInRegionStatusEnum.Planned],
  [ServiceBuildPlanStatusEnum.NotPlanned, FeatureInRegionStatusEnum.NotPlanned],
]);
