import { Nullable } from "../models/types/Nullable";

/**
 * When the input value is not empty, the value would be displayed or withValueDisplay
 * otherwise, the emptyValue is used
 * @param value
 * @param withValueDisplay
 * @param emptyValue
 */
export function displayOr<T>(value: Nullable<T>, withValueDisplay?: () => string | JSX.Element, emptyValue: string = "-" ): T | string | JSX.Element {
  if (value) {
    return withValueDisplay?.() ?? value;
  }

  return emptyValue;
}
