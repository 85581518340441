import { IServiceContactChanges } from "./BulkEditChangesTable";
import { ServiceContactsVO } from "../../../../models/vos/ServiceContactsVO";
import { FormValues, IBulkEditFormField } from "./BulkEditServiceContactWizard.types";
import { UnpackNestedValue } from "react-hook-form";

export const contactChanges = (existingValues: ServiceContactsVO[], editableFields: IBulkEditFormField[], watchChanges: UnpackNestedValue<FormValues>) => {
  const changes: IServiceContactChanges[] = [];
  existingValues.forEach((item) => {
    editableFields.forEach((key) => {
      if (watchChanges[key.attribute].override) {
        const change: IServiceContactChanges = {
          serviceName: item.serviceName,
          attribute: key.label,
          previousValue: item[key.attribute] ?? "No Previous Value",
          newValue: watchChanges[key.attribute].value
        }
        changes.push(change)
      }
    })
  })
  return changes;
}

export const noChangesRequested = (watchChanges: any, editableFields: IBulkEditFormField[]) => {
  var noChangesRequested = true;

  editableFields.forEach((field) => {
    if (watchChanges[field.attribute].override) {
      noChangesRequested = false;
    }
  })
  return noChangesRequested;
}