import { QaChecker } from "../models/QaChecker";
import { successCallChecker } from "./checkerHelper";
import { getErrorResult, successResult } from "../models/QaItemResult";
import {
  doesServicePlanHaveNonGAConfidence,
  doesServicePlanHaveNonGADate,
  doesServicePlanHaveNote
} from "./servicePlanListChecker";
import { IGetServiceRegionHistoryResponseLegacy } from "../../daos/types/IGetServiceRegionHistoryResponse";

export const serviceInRegionHistoryHasServiceChecker: QaChecker<IGetServiceRegionHistoryResponseLegacy> = (val) => {
  return successCallChecker(
    val,
    ( val: IGetServiceRegionHistoryResponseLegacy ) => {
      return (val.serviceMetadata) ? successResult : getErrorResult("No service are returned")
    },
    "Error calling GET /api/services/.../plans/.../history"
  );
}

export const serviceInRegionHistoryHasHistoriesChecker: QaChecker<IGetServiceRegionHistoryResponseLegacy> = (val) => {
  return successCallChecker(
    val,
    ( val: IGetServiceRegionHistoryResponseLegacy ) => {
      return (val.servicePlanHistory?.length > 0) ? successResult : getErrorResult("No plans are included")
    },
    "Error calling GET /api/services/.../plans/.../history"
  );
}

export const serviceInRegionHistoryHasNoNoteChecker: QaChecker<IGetServiceRegionHistoryResponseLegacy> = (val) => {
  return successCallChecker(
    val,
    ( val: IGetServiceRegionHistoryResponseLegacy ) => {
      const hasNoNote: boolean = !val.servicePlanHistory.some(doesServicePlanHaveNote);
      return (hasNoNote) ? successResult : getErrorResult("Note is detected in return data, it should not happen.")
    },
    "Error calling GET /api/services/.../plans/.../history"
  );
}

export const serviceInRegionHistoryHasNoNonGAConfidenceChecker: QaChecker<IGetServiceRegionHistoryResponseLegacy> = (val) => {
  return successCallChecker(
    val,
    ( val: IGetServiceRegionHistoryResponseLegacy ) => {
      const hasNoNote: boolean = !val.servicePlanHistory.some(doesServicePlanHaveNonGAConfidence);
      return (hasNoNote) ? successResult : getErrorResult("Confidence is seen in non GA plan, it should not happen.")
    },
    "Error calling GET /api/services/.../plans/.../history"
  );
}

export const serviceInRegionHistoryHasNoNonGADateChecker: QaChecker<IGetServiceRegionHistoryResponseLegacy> = (val) => {
  return successCallChecker(
    val,
    ( val: IGetServiceRegionHistoryResponseLegacy ) => {
      const hasNoNote: boolean = !val.servicePlanHistory.some(doesServicePlanHaveNonGADate);
      return (hasNoNote) ? successResult : getErrorResult("Date is seen in non GA plan, it should not happen.")
    },
    "Error calling GET /api/services/.../plans/.../history"
  );
}
