import React, { FunctionComponent, useCallback } from "react";
import { Button } from "@amzn/awsui-components-react-v3";
import { ReactRouterButtonProps } from "./ReactRouterButton.types";
import { useHistory } from "react-router-dom";

/**
 * ReactRouterButton
 * @desc Wraps PolarisV3 Button with react-router navigation
 * @param props: ReactRouterButtonProps
 */
export const ReactRouterButton: FunctionComponent<ReactRouterButtonProps> = (props) => {
  const { to, onClick } = props;
  const history = useHistory();

  const buttonHandler = useCallback((event) => {
    history.push(`${to}`);
    onClick?.(event);
  }, [history, to, onClick]);

  return (
    <Button
      {...props}
      onClick={buttonHandler}
    >
      {props.children}
    </Button>
  )
};
