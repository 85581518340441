import { IProductDto } from "../daos/types/IProductDto";
import { ProductVO } from "../models/vos/ProductVO";

export function productDtoToVO(dto: IProductDto): ProductVO {

  return new ProductVO({
    id: dto.id,
    parentProductId: dto.parentProductId,
    name: dto.productName,
    ripId: dto.ripId,
    blueprintUid: dto.blueprintUid,
    codename: dto.codename,
    audience: dto.audience,
    confidential: dto.confidential,
    category: dto.awsCategory,
    type: dto.productType,
    vp: dto.vp,
    cti: dto.productCti,
    tags: dto.tags,
    children: dto.childProductIds,
    gm: dto.gm,
    description: dto.description,
    state: dto.activeState,
    primaryOwner: dto.primaryOwner,
    secondaryOwners: dto.secondaryOwners,
    readGroups: dto.readGroups,
    writeGroups: dto.writeGroups,
    rowId: dto.rowId,
    productApprovalDate: dto.productApprovalDate,
    productApprovedBy: dto.productApprovedBy,
  });
}

export function productDtosToVOs(dtos: IProductDto[]): ProductVO[] {
  return dtos.map((dto) => productDtoToVO(dto));
}
