import * as React from "react";
import { FunctionComponent, useCallback, useState } from "react";
import useBreadcrumbs from "../../components/Breadcrumbs/useBreadcrumbs";
import { NoAccessError } from "../../components/common/NoAccessError";
import { getAppState } from "../../models/ApplicationState";
import { Optional } from "../../models/types/Optional";
import { ResourceParityContainerProps } from "./ResourceParityContainer.types";
import { IResourceParityBootstrapInput } from "@amzn/api-parity-react-component/lib/models/ResourceParityBootstrapInput";
import { ResourceParity } from "../../components/Parity/ResourceParity/ResourceParity";
import { ResourceParityLoaderContainer } from "../ResourceParityLoaderContainer/ResourceParityLoaderContainer";

export const ResourceParityContainer: FunctionComponent<ResourceParityContainerProps> = (props) => {
  return getAppState().userAbilities.canReadDates ? AccessibleResourceParityContainer(props) : <NoAccessError/>
};

const AccessibleResourceParityContainer: FunctionComponent<ResourceParityContainerProps> = (props) => {
  useBreadcrumbs([ "Parity=/parity", "Resource Parity" ]);
  const [data, setData] = useState<Optional<Partial<IResourceParityBootstrapInput>>>();

  const loader_onDataLoaded = useCallback((d: Partial<IResourceParityBootstrapInput>) => {
    setData(d);
  }, [])

  return <React.Fragment>
    <ResourceParityLoaderContainer onDataLoaded={loader_onDataLoaded} />
    {
      data && <ResourceParity data={data as IResourceParityBootstrapInput} />
    }
  </React.Fragment>
};
