import { QaItemDefinition } from "../models/QaItemDefinition";
import * as read from "./abilityItems/abilityRead";
import * as readDate from "./abilityItems/abilityReadDate";
import * as write from "./abilityItems/abilityWrite";
import * as writeCategory from "./abilityItems/abilityWriteCategory";

export const adminQaItems: QaItemDefinition<any>[] = [
  read.listRegionHasItems,
  readDate.listRegionHasNoGaRegionAndDates,
  read.listServiceHasItems,

  // business case override
  writeCategory.updateBusinessCaseOverrideInGA,
  writeCategory.removeBusinessCaseOverrideInGA,

  // estimated date
  write.updateEstimatedDate,
  writeCategory.removeEstimatedDate,

  // service plan
  writeCategory.updateServicePlan,
];
