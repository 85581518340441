
import * as React from "react";
import { FunctionComponent } from "react";
import { Container, Header } from "@amzn/awsui-components-react-v3";
import { getDataTestIdProp } from "../../../../utils/testUtil";
import { EditLaunchReasonFormFields } from "./EditLaunchReasonFormFields";
import { IEditLaunchFormFieldsInput, IEditLaunchFormInput, ISelectableFormInput } from "../EditLaunchForm.types";
import { FormState } from "react-hook-form";
import { Optional } from "@amzn/react-bot";

export const EditLaunchReasonTestIdSuffix = {
  header: "-header",
  form: "-form",
}

type EditLaunchFieldWatchers = {
  stateWatch: Optional<ISelectableFormInput>,
  statusWatch: Optional<ISelectableFormInput>,
  dateWatch: Optional<string>,
  nameWatch: Optional<string>,
  tierWatch: Optional<ISelectableFormInput>,
  reasonForChangeWatch: Optional<ISelectableFormInput>,
}

export interface IEditLaunchReasonInput extends IEditLaunchFormFieldsInput {
  formState: FormState<IEditLaunchFormInput>;
  getValues: () => IEditLaunchFormInput;
  trigger: any;
  fieldWatchers: EditLaunchFieldWatchers;
}

export const EditLaunchReason: FunctionComponent<IEditLaunchReasonInput> = (props) =>{

  return (
    <>
      <Container header={
        <Header variant="h2" description="Required for name, date, or tier change" {...getDataTestIdProp(props, EditLaunchReasonTestIdSuffix.header)}>
          Reason For Editing Launch
        </Header>
      }
      >
        <form {...getDataTestIdProp(props, EditLaunchReasonTestIdSuffix.form)}>
          <EditLaunchReasonFormFields {...props} />
        </form>
      </Container>
    </>
  )
}
