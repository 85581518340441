import { LoadingStatus } from "../models/vos/DetailLoadingVO";
import * as React from "react";
import { defaultDisplay, loadingDisplayByStatus } from "../models/loadingStatusDisplay";
import { IContextualSwrResponse } from "../models/types/IContextualSwrResponse";
import { LoadStateEnum } from "../models/LoadStateEnum";

export function getLoadingStatusIcon(status: LoadingStatus): React.ReactElement {
  return (loadingDisplayByStatus[status] || defaultDisplay).icon;
}

export function getLoadingStatusColor(status: LoadingStatus): string {
  return (loadingDisplayByStatus[status] || defaultDisplay).color;
}

export function isFullyLoaded(swrResponses: IContextualSwrResponse<any>[] = [], loadingStates: LoadStateEnum[] = []): boolean {
  const hasUnloadData: boolean = swrResponses.some((resp) => !resp.response.data);
  if (hasUnloadData) {
    return false;
  }
  const hasNonLoadedStates: boolean = loadingStates.some((state) => state !== LoadStateEnum.Loaded);
  return !hasNonLoadedStates;
}
