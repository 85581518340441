import React, { useCallback, useState } from "react";
import RegionsTable from "../common/TableComponents/RegionsTable";
import { ServiceInRegionLinkViaRegion } from "../common/Links";
import {
  defaultTableConfig,
  genAriaLabel,
  remapColumnWidthsChange,
  sortCategory,
  sortConfidenceDeep,
  sortDates,
  sortDeliveryDateDeep,
  sortRIP,
  sortSortableName,
  sortStatusDeep,
} from "../../utils/tableUtils";
import { getConfidenceCell, getDateCell, getStatusCell } from "../../utils/singleRegionUtils";
import { CATEGORY_FIELD } from "../../constants";
import { ProjectedDate } from "../common/ProjectedDate";
import { getRmsProjectedFinishDateFromRegionDetail, isRegionApplicable } from "../../utils/rmsProjectedDateUtil";
import { RmsProjectedDateColumnHeader } from "../common/RmsProjectedDateColumnHeader";
import { ExportTableToCSVExcel } from "../ExportTableToCSVExcel/ExportTableToCSVExcel";
import { generateSingleRegionNotCompletedWorkbook } from "./SingleRegionNotCompletedCSVExport";
import { getNonEmptyProperties } from "../../utils/collectionUtil";
import { getServiceDisplayName } from "../../utils/serviceMetadataUtil";

export const SingleRegionNotCompletedTestId = {
  table: "SingleRegionNotCompletedListTable-table",
  exportTable: "SingleRegionNotCompletedListTable-exportTable",
}

const fields = {
  0: "nameSortable",
  1: "nameRip",
  2: CATEGORY_FIELD,
  3: "status",
  4: "confidence",
  5: "date",
  6: "rmsProjectedDate",
};

const sortRmsProjectedFinishedDate = (regionName) => (l, r) => {
  const ld = getRmsProjectedFinishDateFromRegionDetail(l, regionName);
  const lr = getRmsProjectedFinishDateFromRegionDetail(r, regionName);
  return sortDates(ld, lr);
};

const getColumnDefinitions = (regionName, widths, userAbilities) => {
  const columns = [
    {
      id: fields[0],
      header: "Service Name",
      cell: (item) => (
        <ServiceInRegionLinkViaRegion regionName={regionName} serviceName={item.nameRip}>
          {getServiceDisplayName(item, "-")}
        </ServiceInRegionLinkViaRegion>
      ),
      label: sortState => genAriaLabel(sortState, "Service Name", fields[0]),
      width: widths[fields[0]],
      minWidth: 142,
    },
    {
      id: fields[1],
      header: "RIP Name",
      cell: ({ nameRip }) => (
        <ServiceInRegionLinkViaRegion regionName={regionName} serviceName={nameRip}>
          {nameRip ?? "-"}
        </ServiceInRegionLinkViaRegion>
      ),
      label: sortState => genAriaLabel(sortState, "RIP Name", "nameRip"),
      width: widths.nameRip,
      minWidth: 142,
    },
    {
      id: fields[2],
      header: "Category",
      cell: ({ plan }) => (
        <div className="awsui-util-t-l">{plan ?? "-"}</div>
      ),
      label: sortState => genAriaLabel(sortState, "Category", CATEGORY_FIELD),
      width: widths.category,
      minWidth: 120,
    },
    {
      id: fields[3],
      header: "RIP Build Status",
      cell: getStatusCell,
      label: sortState => genAriaLabel(sortState, "RIP Build Status", "status"),
      width: widths.status,
      minWidth: 120,
    },
    {
      id: fields[4],
      header: "Confidence",
      cell: getConfidenceCell,
      label: sortState => genAriaLabel(sortState, "Confidence", "confidence"),
      width: widths.confidence,
      minWidth: 120,
      canReadDates: userAbilities.canReadDates,
    },
    {
      id: fields[5],
      header: "Planned Launch Date",
      cell: item => getDateCell(item, false),
      label: sortState => genAriaLabel(sortState, "Planned Launch Date", "date"),
      width: widths.date,
      minWidth: 120,
      canReadDates: userAbilities.canReadDates,
    }
  ];

  if (isRegionApplicable(regionName)) {
    columns.push({
      id: "rmsProjectedDate",
      header: <RmsProjectedDateColumnHeader />,
      cell: (row) => (<div className="awsui-util-t-l">
        <ProjectedDate
          ripName={row.nameRip}
          regionName={regionName}
          projected={getRmsProjectedFinishDateFromRegionDetail(row, regionName)} />
      </div>),
      label: sortState => genAriaLabel(sortState, "RMS Projected GA Date", "rmsProjectedDate"),
      width: widths.rmsProjectedDate,
      minWidth: 120,
      canReadDates: userAbilities.canReadDates,
    });
  }
  return columns.filter(columnDefinition => columnDefinition.canReadDates !== false);
};

function getSortableColumns(regionName) {
  const columns = [
    { id: fields[0], field: fields[0], comparator: sortSortableName },
    { id: "nameRip", field: fields[1], comparator: sortRIP },
    { id: CATEGORY_FIELD, field: fields[2], comparator: sortCategory },
    { id: "status", field: fields[3], comparator: sortStatusDeep },
    { id: "confidence", field: fields[4], comparator: sortConfidenceDeep },
    { id: "date", field: fields[5], comparator: sortDeliveryDateDeep },
  ];

  if (isRegionApplicable(regionName)) {
    columns.push(
      { id: "rmsProjectedDate", field: "rmsProjectedDate", comparator: sortRmsProjectedFinishedDate(regionName) }
    );
  }

  return columns;
}

const getFilteringOptions = (items, userAbilities) => [
  {
    groupValuesLabel: "Service Names",
    propertyKey: fields[0],
    propertyLabel: "Service Name",
    values: getNonEmptyProperties(items, (item) => getServiceDisplayName(item)),
  },
  {
    groupValuesLabel: "RIP Names",
    propertyKey: fields[1],
    propertyLabel: "RIP Name",
    values: items.map(item => item[fields[1]]),
  },
  {
    groupValuesLabel: "Categories",
    propertyKey: fields[2],
    propertyLabel: "Category",
    values: items.map(item => item[fields[2]]),
  },
  {
    groupValuesLabel: "Statuses",
    propertyKey: fields[3],
    propertyLabel: "Status",
    values: items.map(item => item[fields[3]]),
  },
  {
    groupValuesLabel: "Confidence",
    propertyKey: fields[4],
    propertyLabel: "Confidence",
    values: items.map(item => item[fields[4]]),
    canReadDates: userAbilities.canReadDates,
  },
  {
    groupValuesLabel: "Planned Launch Date",
    propertyKey: fields[5],
    propertyLabel: "Planned Launch Date",
    values: items.map(item => item[fields[5]]),
    canReadDates: userAbilities.canReadDates,
  },
].filter(columnDefinition => columnDefinition.canReadDates !== false);

export function getTableContentSelectorOptions(regionName, userAbilities) {
  const visibleColumns = [
    { id: fields[0], label: "Service Name", visible: true },
    { id: "nameRip", label: "RIP Name", visible: false },
    { id: CATEGORY_FIELD, label: "Category", visible: true },
    { id: "status", label: "Status", visible: true },
    { id: "confidence", label: "Confidence", visible: true, canReadDates: userAbilities.canReadDates, },
    { id: "date", label: "Planned Launch Date", visible: true, canReadDates: userAbilities.canReadDates, },
  ];

  if (isRegionApplicable(regionName)) {
    visibleColumns.push(
      { id: "rmsProjectedDate", label: "RMS Projected GA Date", visible: true, canReadDates: userAbilities.canReadDates, },
    );
  }

  return {
    title: "Select visible columns",
    options: [
      {
        label: "Columns",
        options: visibleColumns.filter(selectorOption => selectorOption.canReadDates !== false),
      }
    ]
  }
}

const getTableConfig = args => {
  const {
    regionName,
    items,
    loading,
    columnDefinitions,
    header,
    filteringOptions,
    sortingColumn,
    sortingDescending,
    handleColumnWidthsChange,
    handleSortingChange,
    userAbilities,
  } = args;
  return defaultTableConfig({
    features: [
      "TablePropertyFiltering",
      "TablePagination",
      "TableSorting",
      "TablePreferences",
      "TablePageSizeSelector",
      "TableWrapLines",
      "TableContentSelector",
    ],
    variant: "borderless",
    columnDefinitions,
    header,
    items,
    loading,
    onColumnWidthsChange: handleColumnWidthsChange,
    onSortingChange: handleSortingChange,
    filteringOptions,
    sortableColumns: getSortableColumns(regionName),
    contentSelectorOptions: getTableContentSelectorOptions(regionName, userAbilities),
    sortingColumn,
    sortingDescending,
  });
};

const SingleRegionNotCompletedTable = ({
  regionName,
  items,
  loading = true,
  sortingState,
  handleSortingChange,
  userAbilities,
}) => {

  const [columnWidths, setColumnWidths] = useState({
    [fields[0]]: undefined,
    nameRip: undefined,
    category: undefined,
    plan: undefined,
    status: undefined,
    confidence: undefined,
    date: undefined,
    rmsProjectedDate: undefined,
  });

  const getExportDataBook = useCallback(() => {
    return generateSingleRegionNotCompletedWorkbook(items, userAbilities.canReadDates, regionName)
  }, [items, regionName, userAbilities.canReadDates]);

  const header = RegionsTable.getTableHeader({
    primary: "Not Completed",
    secondary: undefined,
    actionButtons: <ExportTableToCSVExcel
      generateDataBook={getExportDataBook}
      fileNamePrefix={`SingleRegion`}
      // When this file is converted to tsx then we should add a dom test that this component exists
      data-testid={SingleRegionNotCompletedTestId.exportTable}
    />
  });

  const handleColumnWidthsChange = ({ detail: { widths } }) => {
    const mappedWidths = remapColumnWidthsChange(fields, widths);
    setColumnWidths(mappedWidths);
  };

  const columnDefinitions = getColumnDefinitions(regionName, columnWidths, userAbilities);
  const filteringOptions = getFilteringOptions(items, userAbilities);
  const tableConfig = getTableConfig({
    regionName,
    items,
    loading,
    columnDefinitions,
    header,
    filteringOptions,
    handleColumnWidthsChange,
    handleSortingChange,
    ...sortingState,
    userAbilities,
  });

  return <RegionsTable testId={SingleRegionNotCompletedTestId.table} {...tableConfig} />;
};

export default SingleRegionNotCompletedTable;
