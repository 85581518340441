import { InstanceName } from "../models/InstanceName";
import { fromInstanceText } from "../parsers/instanceParser";
import { RegionPartitionId } from "../models/RegionPartition";
import { ServiceContactsVO } from "../models/vos/ServiceContactsVO";
import { updateItemInList } from "./collectionUtil";

export function doesServiceContactInstanceBelongToInstance(serviceContactInstance: string, partition: string): boolean {
  return getPartitionFromInstance(serviceContactInstance) === partition;
}

export function getPartitionFromInstance(serviceContactInstance: string): string {
  const instance: InstanceName = fromInstanceText(serviceContactInstance);
  const instanceOnlyHasServiceId: boolean = (instance.names.length === 1);
  const partitionPartIndex = 1;
  return instanceOnlyHasServiceId ? RegionPartitionId.public : instance.names[partitionPartIndex];
}

export function updateContactInList(contact: ServiceContactsVO, list: ServiceContactsVO[]): ServiceContactsVO[] {
  return updateItemInList(contact, list, (a: ServiceContactsVO, b: ServiceContactsVO) => {
    return (a.instance === b.instance && a.partition === b.partition)
  });
}
