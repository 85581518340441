import { QaChecker } from "../models/QaChecker";
import { getErrorResult, successResult } from "../models/QaItemResult";

export const noAccessChecker: QaChecker<any> = (val) => {
  if (val.success) {
    return getErrorResult("Should not have access");
  }

  return (val.error) ? successResult : getErrorResult("No error is returned");
}
