export const NO_ABILITIES_FRAGMENT = "none"

export type ServerAbilityName = "read" |
  "read_dates" |
  "read_undisclosed_regions" |
  "view_dashboards" |
  "view_reports" |
  "write" |
  "write_categories" |
  "write_contacts" |
  "write_business_case_override";

export class UserAbilities {
  readonly canRead: boolean = false;
  readonly canReadDates: boolean = false;
  readonly canReadUndisclosedRegions: boolean = false;
  readonly canViewDashboards: boolean = false;
  readonly canViewReports: boolean = false;
  readonly canWrite: boolean = false;
  readonly canWriteCategories: boolean = false;
  readonly canWriteContacts: boolean = false;
  readonly canWriteBusinessCaseOverride: boolean = false;

  private static abilityToVariableName = Object.freeze({
    "read": "canRead",
    "read_dates": "canReadDates",
    "read_undisclosed_regions": "canReadUndisclosedRegions",
    "view_dashboards": "canViewDashboards",
    "view_reports": "canViewReports",
    "write": "canWrite",
    "write_categories": "canWriteCategories",
    "write_contacts": "canWriteContacts",
    "write_business_case_override": "canWriteBusinessCaseOverride",
  });

  private static abilityToDescription = Object.freeze({
    "canRead": "Generally Read",
    "canReadDates": "See Dates",
    "canReadUndisclosedRegions": "See Undisclosed Regions",
    "canViewDashboards": "View Dashboards",
    "canViewReports": "View Reports",
    "canWrite": "Generally Write",
    "canWriteCategories": "Change Categories",
    "canWriteContacts": "Change Contacts",
    "canWriteBusinessCaseOverride": "Add business case overrides",
  });

  constructor(abilities: ServerAbilityName[] = []) {
    for (const ability of abilities) {
      this[UserAbilities.abilityToVariableName[ability]] = true;
    }

    Object.freeze(this);
  }

  getAbilities(): string[] {
    return Object.keys(this).filter(ability => this[ability])
  }

  getAbilityDescription(): string {
    const abilities = this.getAbilities().map(ability => UserAbilities.abilityToDescription[ability])
    return "Your permissions: ".concat(abilities.length ? abilities.join(", ") : NO_ABILITIES_FRAGMENT)
  }

  merge(updatedAbilities: Partial<UserAbilities>): UserAbilities {
    const newAbilities = { ...this };

    for (let ability in updatedAbilities) {
      newAbilities[ability] = updatedAbilities[ability];
    }

    Object.freeze(newAbilities);
    return newAbilities;
  }
}

export const USER_ABILITIES_SALES = new UserAbilities(["read"]);
export const USER_ABILITIES_READ_DATES = new UserAbilities(["read", "read_dates"]);
export const USER_ABILITIES_READ_DATES_WRITE = new UserAbilities(["read", "read_dates", "write"]);
export const USER_ABILITIES_READ_DATES_WRITE_CATEGORIES = new UserAbilities(["read", "read_dates", "write", "write_categories"]);
export const USER_ABILITIES_EVERYTHING = new UserAbilities([
  "read",
  "read_dates",
  "read_undisclosed_regions",
  "view_dashboards",
  "view_reports",
  "write",
  "write_categories",
  "write_contacts",
  "write_business_case_override"
]);
