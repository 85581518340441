import { RegionCollection } from "@amzn/api-parity-react-component/lib/models/collections/RegionCollection";
import {
  AwsServiceCollection,
  AwsServiceCollectionView,
  AwsServiceVO,
  RegionCollectionView,
  RegionVO,
  ServiceBuildPlanCollection,
  ServiceBuildPlanCollectionView,
  ServiceBuildPlanStatusEnum,
  ServiceBuildPlanVO
} from "@amzn/api-parity-react-component";
import {
  FeatureCollection,
  FeatureCollectionView,
  FeatureParityCollection,
  FeatureParityCollectionView
} from "../models/collections";
import { isInParity } from "@amzn/api-parity-react-component/lib/utils/serviceBuildPlanUtil";
import { computeServiceParityId } from "@amzn/api-parity-react-component/lib/utils/modelUtil";
import { FeatureParityVO, FeatureVO } from "../models/vos";
import { ParityStatusEnum } from "@amzn/api-parity-react-component/lib/models/ParityStatus";
import { computeFeatureParityId } from "./modelUtil";
import { FeatureInRegionStatusEnum } from "../models/FeatureInRegionStatus";

export interface IFeatureParityGlobalAvailabilityFillerLookup {
  regions: RegionCollection | RegionCollectionView;
  features: FeatureCollection | FeatureCollectionView;
  services: AwsServiceCollection | AwsServiceCollectionView;
}

/**
 * Class which would fill global services'/features' availability
 */
export class FeatureParityGlobalAvailabilityFiller {
  private readonly _lookup: IFeatureParityGlobalAvailabilityFillerLookup;

  constructor(lookup: IFeatureParityGlobalAvailabilityFillerLookup) {
    this._lookup = lookup;
  }

  get lookup(): IFeatureParityGlobalAvailabilityFillerLookup {
    return this._lookup;
  }

  fill(
    featureParities: FeatureParityCollection,
    serviceParities: ServiceBuildPlanCollection,
  ): void {
    this.fillServiceParities(serviceParities);
    this.fillFeatureParities(featureParities);
  }

  fillServiceParities(serviceParities: ServiceBuildPlanCollection): void {
    const servicesByPartitionsToFill = this.getAvailableServiceByPartition(serviceParities);

    const parityModifier = (parity: ServiceBuildPlanVO): void => {
      parity.status = ServiceBuildPlanStatusEnum.GA;
    };

    const availablePartitions: string[] = Array.from(servicesByPartitionsToFill.keys());
    for (const partition of availablePartitions) {
      const services: AwsServiceVO[] = Array.from(servicesByPartitionsToFill.get(partition) as Set<AwsServiceVO>);
      const regionsOfPartition: readonly RegionVO[] = this.lookup.regions.byPartition(partition);
      for (const region of regionsOfPartition) {
        this.fillServiceParityInRegion(services, serviceParities, region, parityModifier);
      }
    }
  }

  /**
   * What services are available in each partition
   * and the services also don't have any features
   * @param serviceParities
   */
  getAvailableServiceByPartition(
    serviceParities: ServiceBuildPlanCollection,
  ): Map<string, Set<AwsServiceVO>> {
    const globalServicesSet: Set<AwsServiceVO> = new Set(this.lookup.services.byIsGlobal(true));
    const availableParities: ServiceBuildPlanCollectionView = new ServiceBuildPlanCollectionView(
      serviceParities, {
        filter: ( plan ) => {
          return globalServicesSet.has(plan.service)
            && this.lookup.features.byService(plan.service).length === 0
            && isInParity(plan)
        }
      }
    );

    const result: Map<string, Set<AwsServiceVO>> = new Map<string, Set<AwsServiceVO>>();
    availableParities.items.forEach((parity) => {
      const partition: string = parity.region.partition;
      if (!result.has(partition)) {
        result.set(partition, new Set<AwsServiceVO>());
      }
      result.get(partition)?.add(parity.service);
    });

    return result;
  }

  fillServiceParityInRegion(
    services: readonly AwsServiceVO[],
    serviceParities: ServiceBuildPlanCollection,
    region: RegionVO,
    parityModifier: (parity: ServiceBuildPlanVO) => void) {

    for (const service of services) {
      if (serviceParities.byServiceRegion(service, region)) {
        continue;
      }
      const parity: ServiceBuildPlanVO = new ServiceBuildPlanVO({
        id: computeServiceParityId(service, region),
        service,
        region,
      });
      parityModifier(parity);
      serviceParities.add(parity);
    }
  }

  fillFeatureParities(featureParities: FeatureParityCollection): void {
    const featuresByPartitionsToFill = this.getAvailableGlobalFeatureByPartition(featureParities);
    const parityModifier = (parity: FeatureParityVO): void => {
      parity.parity = ParityStatusEnum.Available;
      parity.status = FeatureInRegionStatusEnum.GA;
    };

    const availablePartitions: string[] = Array.from(featuresByPartitionsToFill.keys());
    for (const partition of availablePartitions) {
      const features: FeatureVO[] = Array.from(featuresByPartitionsToFill.get(partition) as Set<FeatureVO>);
      const regionsOfPartition: readonly RegionVO[] = this.lookup.regions.byPartition(partition);
      for (const region of regionsOfPartition) {
        this.fillFeatureParityInRegion(features, featureParities, region, parityModifier);
      }
    }
  }

  getAvailableGlobalFeatureByPartition(
    parities: FeatureParityCollection,
  ): Map<string, Set<FeatureVO>> {
    const featureSet: Set<FeatureVO> = new Set(this.lookup.features.byIsGlobal(true));
    const availableParities: FeatureParityCollectionView = new FeatureParityCollectionView(
      parities, {
        filter: ( fp ) => {
          return fp.parity === ParityStatusEnum.Available && featureSet.has(fp.feature);
        }
      }
    );

    const result: Map<string, Set<FeatureVO>> = new Map<string, Set<FeatureVO>>();
    availableParities.items.forEach((parity) => {
      const partition: string = parity.region.partition;
      if (!result.has(partition)) {
        result.set(partition, new Set<FeatureVO>());
      }
      result.get(partition)?.add(parity.feature);
    });


    return result;
  }

  fillFeatureParityInRegion(
    features: readonly FeatureVO[],
    parities: FeatureParityCollection,
    region: RegionVO,
    parityModifier: (parity: FeatureParityVO) => void,
  ) {
    for (const feature of features) {
      if (parities.byFeatureRegion(feature, region)) {
        continue;
      }
      const parity: FeatureParityVO = new FeatureParityVO({
        id: computeFeatureParityId(feature, region),
        feature,
        region,
      });
      parityModifier(parity);
      parities.add(parity);
    }
  }
}
