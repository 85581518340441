import React, { FunctionComponent, useEffect, useState } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import { Button, Container, Form, Header, SpaceBetween } from "@amzn/awsui-components-react-v3";
import { LaunchDocumentFormGroup } from "./LaunchDocumentFormGroup/LaunchDocumentFormGroup";
import { getDataTestIdProp } from "../../../utils/testUtil";
import { EditLaunchDocReviewsProps, MappedLaunchDocument } from "./EditLaunchDocReviews.types";
import { LoadingIndicator } from "../../LoadingIndicator/LoadingIndicator";

export enum LaunchDocumentType {
  "PRFAQ" = "PRFAQ",
  "Pricing doc" = "Pricing doc",
  "Naming doc" = "Naming doc",
  "Messaging framework" = "Messaging framework",
  "Messaging FAQ" = "Messaging FAQ",
  "What's New" = "What's New",
  "Blog" = "Blog",
  "Channel Blog" = "Channel Blog",
  "Forum" = "Forum",
  "Press Release" = "Press Release",
  "Social Media" = "Social Media",
  "Other/Internal" = "Other/Internal",
  "Web" = "Web",
  "Trouble Ticket" = "Trouble Ticket",
  "Change Management" = "Change Management",
  "Internal Goal" = "Internal Goal",
  "S-Team Goal" = "S-Team Goal",
}

export enum LaunchReviewLevel {
  VP = "VP",
  STeam = "STeam",
  CHOP = "CHOP",
  Other = "Other",
}

export enum LaunchReviewStatus {
  "N/A" = "N/A",
  "Not Scheduled" = "Not Scheduled",
  Scheduled = "Scheduled",
  "Not Approved" = "Not Approved",
  Approved = "Approved"
}

export const EditLaunchDocReviewsTestIdSuffixes = {
  submitButton: "-submitButton",
  cancelButton: "-cancelButton",
  topAction: "-topAction",
  bottomAction: "-bottomAction",
  addButton: "-addButton",
  root: "-root"
}

export interface EditLaunchDocumentsFormValues {
  documents: MappedLaunchDocument[];
}

const EditLaunchDocReviewsActions = ({ onCancel, clearErrors, disabled, validating, ...props }) => (
  <SpaceBetween direction="horizontal" size="xs">
    <Button
      {...getDataTestIdProp(props, EditLaunchDocReviewsTestIdSuffixes.cancelButton)}
      formAction="none"
      variant="normal"
      onClick={onCancel}
    >
      Cancel
    </Button>
    <Button
      {...getDataTestIdProp(props, EditLaunchDocReviewsTestIdSuffixes.submitButton)}
      variant="primary"
      disabled={disabled}
      onClick={() => clearErrors()}
      loading={validating}
    >Submit</Button>
  </SpaceBetween>
);

export const EditLaunchDocReviews: FunctionComponent<EditLaunchDocReviewsProps> = (props) => {
  const { docReviews, onSubmit, onCancel, loading, validatingSubmit } = props;

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
    clearErrors
  } = useForm<EditLaunchDocumentsFormValues>({
    defaultValues: {
      documents: docReviews
    },
    mode: "onChange",
    shouldUnregister: false,
  });

  const { fields, append, remove } = useFieldArray<MappedLaunchDocument>({
    name: "documents",
    control
  });

  useEffect(() => {
    reset({ documents: docReviews });
  }, [docReviews, reset]);

  return (
    <form onSubmit={handleSubmit((values) => {onSubmit(values)})}>
      <Form
        {...getDataTestIdProp(props, EditLaunchDocReviewsTestIdSuffixes.root)}
        header={
          <Header
            variant="h1"
            actions={
              <SpaceBetween size="xs" direction="horizontal">
                <Button
                  {...getDataTestIdProp(props, EditLaunchDocReviewsTestIdSuffixes.addButton)}
                  onClick={() => {
                    append({
                      docReviewType: { label: "-", value: "" },
                      docLink: "",
                    });
                  }}
                  disabled={loading}
                >
                  Add document
                </Button>
                <EditLaunchDocReviewsActions
                  {...props}
                  {...getDataTestIdProp(props, EditLaunchDocReviewsTestIdSuffixes.topAction)}
                  clearErrors={clearErrors}
                  onCancel={onCancel}
                  disabled={loading}
                  validating={validatingSubmit}
                />
              </SpaceBetween>
            }
          >
            Documents & Reviews
          </Header>
        }
        actions={
          fields.length > 0 && <EditLaunchDocReviewsActions
            {...props}
            {...getDataTestIdProp(props, EditLaunchDocReviewsTestIdSuffixes.bottomAction)}
            clearErrors={clearErrors}
            disabled={loading}
            onCancel={onCancel}
            validating={validatingSubmit}
          />
        }
      >
        { loading
          ? <Container>
            <LoadingIndicator/>
          </Container>
          : <LaunchDocumentFormGroup
          control={control}
          errors={errors.documents ?? undefined}
          remove={remove}
          documents={fields}
          root="documents"
        />}
      </Form>
    </form>
  );
}
