import * as React from "react";
import { useCallback, useEffect, useState } from "react";
import { NoAccessError } from "../../components/common/NoAccessError";
import { getAppState } from "../../models/ApplicationState";
import { useHistory, useParams } from "react-router-dom";
import { LaunchDao } from "../../daos/LaunchDao";
import { launchDtosToVOs } from "../../parsers/launchParser";
import {
  Alert,
  CollectionPreferencesProps,
  Link,
  PaginationProps,
  PropertyFilterProps
} from "@amzn/awsui-components-react-v3";
import { REPORT_A_BUG_URL } from "../../constants";
import { ProductLaunches } from "../../components/ProductLaunches/ProductLaunches";
import { useProduct } from "../../utils/productUtil";
import { useLocation } from "react-router";
import { ProductVO } from "../../models/vos/ProductVO";
import { useProductContext } from "../../contexts/ProductContext";
import useBreadcrumbs from "../../components/Breadcrumbs/useBreadcrumbs";
import { ILocationState } from "../../models/LocationState";
import { NonCancelableCustomEvent } from "@amzn/awsui-components-react-v3/polaris/internal/events";
import { DEFAULT_TABLE_PREFERENCES } from "../../components/ProductLaunches/ProductLaunchesProperties";
import useTitleBanner from "../../components/unified/TitleBanner/useTitleBanner";
import { LaunchVO } from "../../models/vos/LaunchVO";
import { useLoadInfinitePages } from "../../utils/swrUtil";
import { getSwrConfig } from "../../daos/swrUtil";

const getFilter = (search) => {
  try {
    return JSON.parse(atob(new URLSearchParams(search).get("filter")!))
  } catch {
    return undefined;
  }
}

export interface IProductLaunchesContainerParam {
  productId: string;
}

export const ProductLaunchesContainer = () => {
  const { productId } = useParams<IProductLaunchesContainerParam>();
  const location = useLocation<ILocationState>();
  const history = useHistory();
  const { product, setProduct } = useProductContext();

  const [page, setPage] = useState<number>(Number(new URLSearchParams(location.search).get("page")) || 1);
  const [filter, setFilter] = useState<any>(getFilter(location.search));

  const userAbilities = getAppState().userAbilities;

  const { data: productData } = useProduct(productId);

  const setBreadcrumbs = useBreadcrumbs();
  const setTitleBanner = useTitleBanner({ visible: true });

  useEffect(() => {
    setTitleBanner({
      visible: true,
      title: product?.name,
      details: {
        info: product?.description
      }
    });
  }, [product?.description, product?.name, setTitleBanner]);

  useEffect(() => {
    setBreadcrumbs([
      "Products=/alm/products",
      `${product?.name || ""}=/alm/products/${productId}/launches`,
      "Launches"
    ]);
  }, [product?.name, productId, setBreadcrumbs]);

  useEffect(() => {
    if (product.id !== productId) {
      const newProduct = productData
        ?? (location.state?.product?.id === productId ? location.state?.product : null)
        ?? new ProductVO();

      setProduct(newProduct);
    }
  }, [location.state?.product, setProduct, productData, productId, product.id]);

  const { data: launches, error, loadingMore } = useLoadInfinitePages<LaunchVO>(
    `/alm/launches/${productId}`,
    async (request, token) => {
      const dao = new LaunchDao();
      const dto = await dao.getLaunches(productId, 200, token);
      return { items: launchDtosToVOs(dto.items), nextToken: dto.nextToken }
    },
    { ...getSwrConfig() }
  );

  const getPreferences = useCallback(() => {
    try {
      const storedPreferences = JSON.parse(localStorage.getItem("almProductLaunchesPreferences") ?? "{}");

      if (storedPreferences.visibleContent.includes("name") && storedPreferences.visibleContent.includes("owner")) {
        return storedPreferences;
      }
    } catch (e) {
      localStorage.setItem("almProductLaunchesPreferences", JSON.stringify(DEFAULT_TABLE_PREFERENCES));
      return DEFAULT_TABLE_PREFERENCES;
    }
  }, []);

  const paginationChangeHandler = useCallback((event: NonCancelableCustomEvent<PaginationProps.ChangeDetail>) => {
    const queryParams = new URLSearchParams(location.search);
    queryParams.set("page", event.detail.currentPageIndex.toString());
    history.push({
      search: queryParams.toString()
    })
    setPage(event.detail.currentPageIndex);
  }, [history, location.search]);

  const preferenceChangeHandler = useCallback((event: NonCancelableCustomEvent<CollectionPreferencesProps.Preferences>) => {
    localStorage.setItem("almProductLaunchesPreferences", JSON.stringify(event.detail))
  }, []);

  const filterChangeHandler = useCallback((event: NonCancelableCustomEvent<PropertyFilterProps.Query>) => {
    const queryParams = new URLSearchParams(location.search);
    queryParams.set("filter", btoa(JSON.stringify(event.detail)));
    history.push({
      search: queryParams.toString()
    });
    setFilter(event.detail);
  }, [history, location.search]);

  if (true) {
    if (error) {
      return (
        <Alert
          type="error"
          header="Data Fetching Error"
        >
          {"There was an error fetching the products launches, please try again or submit a "}
          <Link
            external
            externalIconAriaLabel="Opens in a new tab"
            href={REPORT_A_BUG_URL}
          >
            ticket
          </Link>.
        </Alert>
      );
    }
    return <ProductLaunches
      items={launches ?? []}
      loading={loadingMore}
      filter={filter}
      page={page}
      onPreferenceChange={preferenceChangeHandler}
      onPaginationChange={paginationChangeHandler}
      onFilterChange={filterChangeHandler}
      preferences={getPreferences()}
    />
  }
  return <NoAccessError/>
}
