import { FeatureInRegionCommentVO, Reason } from "../../models/vos/FeatureInRegionCommentVO";

export interface IFeatureInRegionCommentFormInput {
  featureInput: LabelWithValue[];
  selectedRegionsInput: string[];
  reasonInput: LabelWithValue<Reason>;
  simInput: string;
  whatsNewInput: string;
  noteInput: string;
}

export type LabelWithValue<T = string> = {
  label: T,
  value: T,
}

type DefaultInputValues = {
  serviceLongName: string
}

type NoAction = {
  action: "noAction"
}

export const NO_ACTION = {
  action: "noAction"
} as const

type EditExistingComment = {
  action: "edit",
  comment: FeatureInRegionCommentVO,
  onResolve: (comment: FeatureInRegionCommentVO) => Promise<void>;
}

type AddNewComment = {
  action: "add",
  service: string,
  features: LabelWithValue[],
  regions: string[],
  partitionToRegions: Map<string, string[]>,
}

export type CommentModalAction = (NoAction | EditExistingComment | AddNewComment);

export type IFeatureInRegionCommentModalInput = DefaultInputValues & CommentModalAction;

export type IFeatureInRegionCommentModalEvent = {
  onDismiss: () => void;
  onSubmit: (comments: FeatureInRegionCommentVO[]) => Promise<boolean>;
}

export type FeatureInRegionCommentModalProps = IFeatureInRegionCommentModalInput & IFeatureInRegionCommentModalEvent;
