import { IServiceMetaData } from "../daos/types/IServiceMetaData";
import { Optional } from "../models/types/Optional";
import { IHasServiceName } from "../daos/types/IHasServiceName";

export function getRipName(instance: string): string {
    return instance.split(":")[0] as string;
}

export function getServiceDisplayName(dto: IHasServiceName, fallback = ""): string {
  return dto.nameSortable || dto.nameLong || dto.nameRip || fallback;
}

export function isInternal(serviceMetadata: Optional<IServiceMetaData>): boolean {
  return serviceMetadata?.visibility === "INTERNAL";
}

export function isProgrammaticallyCategorized(serviceMetadata: Optional<IServiceMetaData>): boolean {
  return !!serviceMetadata?.plan && !serviceMetadata?.manuallyCategorized;
}
