import { isString } from "lodash";
import { Method } from "axios";
import { Optional } from "../models/types/Optional";
import { Lookup } from "@amzn/api-parity-react-component/lib/models/Lookup";

export const RequestMethod: Lookup<Method> = Object.freeze({
  get: "GET",
  post: "POST",
  patch: "PATCH",
  put: "PUT",
  delete: "DELETE",
});


export const SupportedMethods: Method[] = [
  RequestMethod.get,
  RequestMethod.patch,
  RequestMethod.post,
  RequestMethod.delete,
  RequestMethod.put
];

/**
 * @param method Method name to normalize
 * @param defaultMethod Fallback method name if the supplied method is not within the known method
 */
export function normalizeRequestMethod(method: Optional<Method>, defaultMethod: Method = RequestMethod.get): Method {
  if (!method || !isString(method)) {
    return defaultMethod;
  }

  const adjustedMethod: Method = method.toUpperCase() as Method;
  return SupportedMethods.includes(adjustedMethod) ? adjustedMethod : defaultMethod;
}
