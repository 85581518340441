import { AwsServiceVO } from "@amzn/api-parity-react-component";

/**
 * Ensure a service list is loaded or being loaded
 */
export const ENSURE_SERVICE_LIST = "@service/EnsureServiceList";
export const LOAD_SERVICE_LIST = "@service/LoadServiceList";
export const LOAD_SERVICE_LIST_SUCCESS = "@service/LoadServiceListSuccess";

export interface IEnsureServiceListAction {
  type: typeof ENSURE_SERVICE_LIST;
}

export interface ILoadServiceListAction {
  type: typeof LOAD_SERVICE_LIST;
}

export interface ILoadServiceListActionSuccessAction {
  type: typeof LOAD_SERVICE_LIST_SUCCESS;
  payload: {
    services: AwsServiceVO[];
  }
}

export type ServiceActionTypes =
  IEnsureServiceListAction
|  ILoadServiceListAction
| ILoadServiceListActionSuccessAction;

