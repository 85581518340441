import * as React from "react";
import { useCallback, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import useSWR from "swr";
import { AxiosResponse } from "axios";
import { Alert, Link } from "@amzn/awsui-components-react-v3";
import { getAppState } from "../../models/ApplicationState";
import { LaunchVO } from "../../models/vos/LaunchVO";
import { LaunchDao } from "../../daos/LaunchDao";
import { launchDtoToVO } from "../../parsers/launchParser";
import { ILaunchDto, IUpdateLaunchDto } from "../../daos/types/ILaunchDto";
import {
  EditLaunchErrorAlerts
} from "../../components/LaunchDetails/EditLaunch/EditLaunchErrorAlerts/EditLaunchErrorAlerts.helpers";
import { REPORT_A_BUG_URL } from "../../constants";
import { NoAccessError } from "../../components/common/NoAccessError";
import { EditLaunchRegions } from "../../components/LaunchDetails/EditLaunchRegions/EditLaunchRegions";
import { RegionMetadataVO } from "../../models/vos/RegionMetadataVO";
import { RegionDao } from "../../daos/RegionDao";
import { parseRegionMetadataDto, parseRipRegionMetadataDto } from "../../parsers/regionMetadataParser";
import { Loading } from "../../components/common/Loading";
import { EditLaunchContainerParam } from "../EditLaunch/EditLaunchContainer";
import useTitleBanner from "../../components/unified/TitleBanner/useTitleBanner";
import { ProductVO } from "../../models/vos/ProductVO";
import { useLaunchContext } from "../../contexts/LaunchContext";
import { useProductContext } from "../../contexts/ProductContext";
import { useProduct } from "../../utils/productUtil";
import { getSwrConfig } from "../../daos/swrUtil";

export const EditLaunchRegionsContainer: React.FunctionComponent = () => {
  const { launchId } = useParams<EditLaunchContainerParam>();
  const [loading, setLoading] = useState(false);
  const userAbilities = getAppState().userAbilities;
  const [updateResponse, setUpdateResponse] = useState({ status: 200, text: {} });

  const { launch, setLaunch } = useLaunchContext();
  const { product, setProduct } = useProductContext();

  const { data: productData } = useProduct(launch?.productId);

  const { data: launchData, error: launchError } = useSWR<LaunchVO>(() => `launch/${launchId}`, async () => {
    const dao = new LaunchDao();
    const dto = await dao.getLaunch(launchId);
    return launchDtoToVO(dto);
  }, getSwrConfig());

  const { data: regionsData, error: regionsError } = useSWR<RegionMetadataVO[]>(() => `launchRegions`, async () => {
    const dao = new RegionDao();
    const dto = (await dao.getRipRegions());

    return dto.regions.items.map(value => parseRipRegionMetadataDto(value));
  }, getSwrConfig());

  const setTitleBanner = useTitleBanner({ visible: true });

  useEffect(() => {
    setTitleBanner({
      visible: true,
      title: launch?.name,
      details: {
        info: launch?.description
      }
    });
  }, [launch?.description, launch?.name, setTitleBanner]);

  useEffect(() => {
    if (launch.id !== launchId) {
      const newLaunch = launchData
        ?? new LaunchVO();

      setLaunch(newLaunch);
    }
  }, [launch.id, launchData, launchId, setLaunch])

  useEffect(() => {
    if (product.id !== launch?.productId) {
      const newProduct = productData
        ?? new ProductVO();

      setProduct(newProduct);
    }
  }, [launch?.productId, product.id, productData, setProduct])

  const history = useHistory();

  const onSubmit = useCallback(async (launchId: string, launch: Partial<IUpdateLaunchDto>): Promise<ILaunchDto> => {
    setLoading(true)
    const dao = new LaunchDao();
    const response: AxiosResponse = await dao.updateLaunch(launchId, launch);
    setUpdateResponse({ status: response.status, text: response.data });
    setLoading(false)
    if (response.status === 200 || response.status === 201) {
      history.push(`/alm/launches/${launchId}?tabId=regions`);
    } else {
      window.scrollTo(0, 0)
    }
    return response.data;
  }, [history]);

  const error = launchError || regionsError
  const data = launchData && regionsData

  if (!data && !error) {
    return <Loading/>
  }

  // TODO (jsoumya): Fix condition once API for write permission exists
  if (true) {
    if (error || !data) {
      return (
        <Alert
          type="error"
          header="Data Fetching Error"
        >
          {"There was an error fetching launch details, please try again later or submit a "}
          <Link
            external
            externalIconAriaLabel="Opens in a new tab"
            href={REPORT_A_BUG_URL}
          >
            ticket
          </Link>.
        </Alert>
      )
    }
    return (
      <>
        {
          EditLaunchErrorAlerts[updateResponse.status] && EditLaunchErrorAlerts[updateResponse.status](updateResponse.text)
        }
        {
          <EditLaunchRegions
            data-testid={"EditLaunchRegions"}
            launch={launchData}
            regions={regionsData}
            onSubmit={onSubmit}
            loading={loading}/>
        }
      </>
    )
  }
  return <NoAccessError/>
}

