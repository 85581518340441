import { ILaunchContact, ILaunchDto, IUpdateLaunchDto } from "../daos/types/ILaunchDto";
import { LaunchContactsVO, LaunchVO } from "../models/vos/LaunchVO";

export enum LaunchContactType {
  PM = "PM",
  ENGINEERING_LEAD = "ENGINEERING_LEAD",
  PRODUCT_MARKETING_LEAD = "PRODUCT_MARKETING_LEAD",
  OTHER = "OTHER",
}

export const camelCaseContactToEnum = {
  "productManager": LaunchContactType.PM,
  "marketingLead": LaunchContactType.PRODUCT_MARKETING_LEAD,
  "engineeringLead": LaunchContactType.ENGINEERING_LEAD,
}

/**
 * @desc parses ILaunchContact from DTO to ContactsVO object
 */
export function parseLaunchContacts(contacts: ILaunchContact[]): LaunchContactsVO {
  const contactVO = new LaunchContactsVO();

  for (const contact of contacts) {
      switch (contact.contactType) {
      case LaunchContactType.PM:
        contactVO.productManager = contactVO.productManager ?? contact.alias;
        break;
      case LaunchContactType.ENGINEERING_LEAD:
        contactVO.engineeringLead = contactVO.engineeringLead ?? contact.alias;
        break;
      case LaunchContactType.PRODUCT_MARKETING_LEAD:
        contactVO.marketingLead = contactVO.marketingLead ?? contact.alias;
        break;
      default:
        const otherRegex = /^OTHER:\s*([\S ]*\S)/
        const match = otherRegex.exec(contact.contactType);
        if (match && match[1]) {
          contactVO.other = contactVO.other ?? [];
          contactVO.other.push({
            contactType: match[1],
            alias: contact.alias
          });
        }
    }
  }
  return contactVO;
}

/**
 * @desc parses ILaunchContact from form to ContactsVO object
 */
export function parseLaunchContactsFromForm(contacts: ILaunchContact[]): LaunchContactsVO {
  const contactVO = new LaunchContactsVO();

  for (const contact of contacts) {
    switch (contact.contactType) {
      case LaunchContactType.PM:
        contactVO.productManager = contactVO.productManager ?? contact.alias;
        break;
      case LaunchContactType.ENGINEERING_LEAD:
        contactVO.engineeringLead = contactVO.engineeringLead ?? contact.alias;
        break;
      case LaunchContactType.PRODUCT_MARKETING_LEAD:
        contactVO.marketingLead = contactVO.marketingLead ?? contact.alias;
        break;
      default:
        contactVO.other = contactVO.other ?? [];
        contactVO.other.push({
          contactType: contact.contactType,
          alias: contact.alias
        });
    }
  }
  return contactVO;
}

export function launchDtoToVO(dto: ILaunchDto): LaunchVO {
  // TODO implement more members from ILaunchDto to LaunchVO
  return new LaunchVO({
    id: dto.id,
    checkListIds: dto.checkListIds,
    name: dto.name,
    codename: dto.codename,
    ripId: dto.ripId,
    productId: dto.parentProductId,
    type: dto.type,
    iteration: dto.iteration,
    tier: dto.tier,
    scope: dto.scope,
    date: dto.date,
    status: dto.status,
    audience: dto.audience,
    confidential: dto.confidential,
    contacts: parseLaunchContacts(dto.contacts ?? []),
    docReviews: dto.docReviews,
    tags: dto.tags,
    state: dto.launchState,
    slips: dto.numberOfSlips,
    daysLate: dto.daysLate,
    disclosureLevel: dto.customerDisclosureLevel,
    regions: dto.regions,
    description: dto.description,
    reasonForChange : dto.reasonForChange,
    goal: dto.goal,
    goalLink: dto.goalLink,
    originalLaunchDate: dto.originalLaunchDate,
    previousLaunchDate: dto.previousLaunchDate,
    trackerLink: dto.trackerLink,
    rowId: dto.rowId,
    ownerAlias: dto.ownerAlias,
    ownerType: dto.ownerType,
    blueprintUid: dto.blueprintUid,

    questionnaire: {
      regulated: dto.regulated,
      pricingChangeInvolved: dto.pricingChangeInvolved,
      namingInvolved: dto.namingInvolved,
      prfaqRequired: dto.prfaqRequired
    },

    readGroups: dto.readGroups,
    writeGroups: dto.writeGroups,
  });
}

export function launchDtosToVOs(dtos: ILaunchDto[]): LaunchVO[] {
  return dtos.map((dto) => launchDtoToVO(dto));
}

function launchVOContactsToDtoContacts(contacts?: LaunchContactsVO): ILaunchContact[]{
  const result: ILaunchContact[] = []

  if (contacts) {
    contacts.productManager && result.push({ alias: contacts.productManager, contactType: "PM" })
    contacts.marketingLead && result.push({ alias: contacts.marketingLead, contactType: "PRODUCT_MARKETING_LEAD" })
    contacts.engineeringLead && result.push({ alias: contacts.engineeringLead, contactType: "ENGINEERING_LEAD" })
    contacts.other && result.push(...contacts.other)
  }

  return result;
}


export function launchVOToCreateDto(vo: LaunchVO): ILaunchDto {
  // TODO implement more members from LaunchVO to ILaunchDto including contacts
  return {
    id: vo.id,
    checkListIds: vo.checkListIds,
    name: vo.name,
    codename: vo.codename,
    ripId: vo.ripId,
    parentProductId: vo.productId,
    type: vo.type,
    iteration: vo.iteration,
    tier: vo.tier,
    scope: vo.scope,
    date: vo.date,
    status: vo.status,
    audience: vo.audience,
    confidential: vo.confidential,
    contacts: launchVOContactsToDtoContacts(vo.contacts),
    docReviews: vo.docReviews,
    tags: vo.tags,
    launchState: vo.state,
    numberOfSlips: vo.slips,
    daysLate: vo.daysLate,
    customerDisclosureLevel: vo.disclosureLevel,
    regions: vo.regions,
    description: vo.description,
    goal: vo.goal,
    goalLink: vo.goalLink,
    originalLaunchDate: vo.originalLaunchDate,
    previousLaunchDate: vo.previousLaunchDate,
    trackerLink: vo.trackerLink,
  

    regulated: vo.questionnaire.regulated,
    pricingChangeInvolved: vo.questionnaire.pricingChangeInvolved,
    namingInvolved: vo.questionnaire.namingInvolved,
    prfaqRequired: vo.questionnaire.prfaqRequired
  };
}

  export function launchVOToUpdateDto(vo: LaunchVO, reasonForChange?: string): IUpdateLaunchDto {
    // TODO implement more members from LaunchVO to ILaunchDto including contacts
    return {
      id: vo.id,
      date: vo.date,
      status: vo.status,
      tags: vo.tags,
      launchState: vo.state,
      customerDisclosureLevel: vo.disclosureLevel,
      description: vo.description,
      trackerLink: vo.trackerLink,

      name: vo.name,
      codename: vo.codename,
      ripId: vo.ripId,
      type: vo.type,
      iteration: vo.iteration,
      tier: vo.tier,
      scope: vo.scope,
      audience: vo.audience,
      confidential: vo.confidential,
      goal: vo.goal,
      goalLink: vo.goalLink,

      regulated: vo.questionnaire.regulated,
      pricingChangeInvolved: vo.questionnaire.pricingChangeInvolved,
      namingInvolved: vo.questionnaire.namingInvolved,
      prfaqRequired: vo.questionnaire.prfaqRequired,

      contacts: launchVOContactsToDtoContacts(vo.contacts),

      reasonForChange: reasonForChange,
    };
}
