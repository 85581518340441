import React, { FunctionComponent } from "react";
import { Controller } from "react-hook-form";
import { FormField, Select } from "@amzn/awsui-components-react-v3";
import { Attribute } from "@amzn/api-parity-react-component/lib/components/attribute/Attribute";
import { SelectControllerProps } from "./SelectController.types";
import { getDataTestIdProp } from "../../../../utils/testUtil";

export const SelectControllerTestIdSuffixes = {
  formField: "-formField",
  select: "-select"
}

export const SelectController: FunctionComponent<SelectControllerProps> = (props) => {
  const { id, control, label, options, rules, defaultValue, error } = props;
  return (
    <Controller
      name={id}
      control={control}
      rules={rules}
      defaultValue={defaultValue}
      render={(
        { onChange, onBlur, value, ref },
        { invalid }
      ) => (
        <FormField
          {...getDataTestIdProp(props, SelectControllerTestIdSuffixes.formField)}
          label={<Attribute label={label}/>}
          errorText={(invalid && error)}
        >
          <Select
            {...getDataTestIdProp(props, SelectControllerTestIdSuffixes.select)}
            ariaRequired
            onBlur={onBlur}
            selectedOption={value}
            options={options}
            ref={ref}
            onChange={({ detail }) => onChange(detail.selectedOption)}
          />
        </FormField>
      )}
    />
  );
}
