import React, { FunctionComponent } from "react";
// import styled from '@emotion/styled';
import { BannerContainerProps } from "./BannerContainer.types";
import { Optional } from "../../models/types/Optional";
import { getAppState } from "../../models/ApplicationState";
import { Banner } from "../../components/common/Banner";

export const needsBlueprintStaleBanner = (): boolean => {
  const highWaterMark: Optional<Date> = getAppState().blueprintHighWaterMarkUtc;

  if (highWaterMark === undefined) {
    return false;
  }

  const timeDiffMs = new Date().getTime() - highWaterMark.getTime();
  const oneHourInMs = 1000 * 60 * 60;
  const timeDiffHours = timeDiffMs / oneHourInMs;

  return timeDiffHours >= 1;
}


export const BannerContainer: FunctionComponent<BannerContainerProps> = (props) => {
  return <Banner needsBlueprintStaleBanner={needsBlueprintStaleBanner()}/>
};
