import * as React from "react";
import { FunctionComponent, useEffect, useState } from "react";
import {
  ISingleServiceResourceParityViewModel,
  SingleServiceResourceParityProps
} from "./SingleServiceResourceParity.types";
import {
  ApiViewContainer,
  NoDataContainer
} from "../../../views/SingleService/SingleServiceApiParity/SingleServiceApiParity.style";
import { ResourceParityByServiceView } from "@amzn/api-parity-react-component/lib/components/parity-grid/ResourceParityByServiceView/ResourceParityByServiceView";
import { ResourceParitySettingVO } from "@amzn/api-parity-react-component/lib/models/vos/ResourceParitySettingVO";
import { viewModelSelector } from "./SingleServiceResourceParity.view.model.composer";
import { legacyParityReportHeight } from "../../../views/SingleService/SingleServiceParity/SingleServiceParityContainer.style";

const setting = new ResourceParitySettingVO().byService;

export const SingleServiceResourceParityTestId = {
  gridContainer: "SingleServiceResourceParity-gridContainer",
  noData: "SingleServiceResourceParity-noData",
};

export const SingleServiceResourceParity: FunctionComponent<SingleServiceResourceParityProps> = (props) => {
  const [viewModel, setViewModel] = useState<ISingleServiceResourceParityViewModel>(viewModelSelector(props));

  useEffect(() => {
    const viewModel = viewModelSelector(props);
    setViewModel(viewModel);
  }, [props]);

  if (viewModel.service) {
    return <ApiViewContainer data-testid={SingleServiceResourceParityTestId.gridContainer}>
      <ResourceParityByServiceView
        constraintHeight={props.constraintHeight ?? legacyParityReportHeight}
        service={viewModel.service}
        regions={viewModel.regions}
        resources={viewModel.resources}
        resourceParities={viewModel.resourceParities}
        propertyTypes={viewModel.propertyTypes}
        propertyParities={viewModel.propertyParities}
        buildPlans={viewModel.nonGaBuildPlans}
        setting={setting}
        hideBackButton={true}
      />
    </ApiViewContainer>
  }

  return <NoDataContainer data-testid={SingleServiceResourceParityTestId.noData}>
    No Resource Parity Data is found for the service.
  </NoDataContainer>;
};
