import { getReconApiClient } from "./daoUtil";


class InvalidQuickSightDashboardIdError extends Error {
}

export class QuickSightDao {
  /**
   * Get a signed URL to QuickSight based on a symbolic name for a dashboard.
   * Symbolic names are defined in BackendLambda based on internal IDs from
   * QuickSight.
   * @return {Promise<URL>}
   */
  async getQuickSightDashboardUrl(dashboardId: string): Promise<URL> {
    if (!dashboardId.match(/^[0-9a-zA-Z_-]+$/))
      throw new InvalidQuickSightDashboardIdError(dashboardId);

    const dashboardUrl = "/api/dashboards/" + dashboardId; // no need to escape, only contains chars that don't need escaping
    const client = getReconApiClient();
    const res = await client.get(dashboardUrl);
    const { url } = res.data;

    return new URL(url);
  }
}
