import { IHasDataTestId } from "../../../../models/IHasDataTestId";
import { LaunchVO } from "../../../../models/vos/LaunchVO";
import React, { FunctionComponent, useEffect, useState } from "react";
import { Alert, Box, Link, Spinner } from "@amzn/awsui-components-react-v3";
import { REPORT_A_BUG_URL } from "../../../../constants";


export interface LaunchSynchronizingBannerProps extends IHasDataTestId {
  launch?: LaunchVO;
}

export const LaunchSynchronizingBanner: FunctionComponent<LaunchSynchronizingBannerProps> = (props) => {

  const [visible, setVisible] = useState(false);

  useEffect(() => {
    if (props.launch && !props.launch?.rowId) {
      setVisible(true);
    } else {
      setVisible(false);
    }
  }, [props.launch])

  return <Alert
    visible={visible}
    header={<><Spinner/>Syncing launch with blueprint</>}
  >
    <Box>
      {"Launch is locked while syncing to blueprint. Please allow 1-2 minutes before trying to make changes to your launch. If launch does not sync, please submit a "}
      <Link
        external
        externalIconAriaLabel="Opens in a new tab"
        href={REPORT_A_BUG_URL}
      >
        ticket
      </Link>.
    </Box>
  </Alert>
}
