import { LaunchVO } from "../../models/vos/LaunchVO";
import { getConfig } from "../../utils/environmentUtil";

export const formatLaunchDate = (date: string): string => {
  const parseDate = new Date(date);
  return parseDate.toISOString().split("T")[0];
}

export const formatHistoryDate = (date:string): string => {
  const parseDate = new Date(date);
  return parseDate.toLocaleString();
}

export const generateALCLink = (launch: LaunchVO) => {
  const launchRow = launch?.rowId?.replace("row:", "") ?? "";

  const workbookId = getConfig()?.blueprintWorkbookId;
  const appId = "34ec35b2-22f9-4cf5-a2b9-a1e99feb4827";
  const sheetId = "f8dbefd9-5a94-4509-94b3-636e739576d8";
  const viewId = "a04d4cf4-66e7-4ffa-9adf-8ff40477e13f";
  const region = "us-west-2";
  const accountId = "122162422134";

  const encodedRoute = encodeURIComponent(
    `[{"expressionId":"refId:expression:${viewId}","value":"arn:aws:sheets:${region}:${accountId}:row:${workbookId}/${launchRow}"}]`
  );
  return `https://app.honeycode.aws/arn%3Aaws%3Asheets%3A${region}%3A${accountId}%3Amobileapp%3A${workbookId}%2F${appId}/${sheetId}?navigationParameters=${encodedRoute}`;
}
