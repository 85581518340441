import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { SinglePageHeader } from "../../components/common/SinglePageHeader";
import { RMS_URL_BASE } from "../../constants";
import ServiceInRegionHistory from "../../components/ServiceInRegion/ServiceInRegionHistory";
import ServiceInRegionDetails from "../../components/ServiceInRegion/ServiceInRegionDetails";
import useBreadcrumbs from "../../components/Breadcrumbs/useBreadcrumbs";
import _ from "lodash";
import { Loading } from "../../components/common/Loading";
import useSWR from "swr";
import { ServiceDao } from "../../daos/ServiceDao";
import { getSwrConfig } from "../../daos/swrUtil";
import { getRipName } from "../../utils/serviceMetadataUtil"
import { getAppState } from "../../models/ApplicationState";
import { Flash } from "@amzn/awsui-components-react";
import { NoAccessError } from "../../components/common/NoAccessError";
import { shouldShowDateInformation } from "../../components/ServiceInRegion/ServiceInRegionDetailsUtil";
import { normalizeServiceName } from "../../daos/daoUtil";
import { filterServiceInRegionHistory } from "../../components/ServiceInRegion/ServiceInRegionHistoryUtil";

export const getValidName = (serviceMetadata, nameSortable, nameLong) => {
  const instance = serviceMetadata?.instance ?? undefined;
  const nameRip = _.isString(instance) ? getRipName(instance) : undefined;
  const normalizedNameRip = normalizeServiceName(nameRip);
  
  return nameSortable ?? nameLong ?? normalizedNameRip ?? "";
};

const ServiceInRegionContainer = () => {
  const { serviceName, regionName } = useParams();
  const userAbilities = getAppState().userAbilities;

  const { data, isValidating: loading, error } = useSWR("serviceInRegion", () => {
    const dao = new ServiceDao();
    return dao.getServiceRegionHistory(serviceName, regionName);
  }, getSwrConfig());

  // TODO: use view model so the data below can be properly unit tested
  const { serviceMetadata, servicePlanHistory: history = [] } = data ?? {};
  const { category = "-", plan: serviceMetadataPlan = "-", nameSortable, nameLong } = serviceMetadata ?? {};
  const validName = getValidName(serviceMetadata, nameSortable, nameLong);

  const setBreadcrumbs = useBreadcrumbs();
  useEffect(() => {
    if (validName)
      setBreadcrumbs([
        "Services=/services",
        `${validName}=/services/${serviceName}`,
        `${regionName}=/regions/${regionName}`,
        `${validName} in ${regionName}`,
      ]);
  }, [validName, regionName, serviceName, setBreadcrumbs]);

  const latestServiceHistory = history[0] ?? {
    updated: "-",
    updater: "-",
    status: "-",
    date: "-",
    confidence: "-",
    note: "-",
  };

  const shouldShowDates = shouldShowDateInformation(userAbilities, latestServiceHistory.status);

  const filteredHistory = filterServiceInRegionHistory(history)

  if (loading) {
    return <Loading/>;
  }
  if (userAbilities.canRead) {
    if (error) {
      return (
        <Flash
          type="error"
          header="Data Fetching Error"
          content="We are having an issue getting this data, 
          please try checking the spelling of the ripname in the url
          or first onboarding to RIP.  Otherwise, please reach out to us if you continue
          to have issues"
        />
      );
    }
    return (
      <>
        <SinglePageHeader
        headerText={`${validName} in ${regionName}`}
        subText={"Service details in region"}
        manageButtonText="View in RMS"
        href={`${RMS_URL_BASE}/services/${serviceName}?serviceStatusTableFilter=${regionName}`}
        />
        <ServiceInRegionDetails {...{ category, latestServiceHistory, serviceMetadataPlan, ripName: serviceName, regionName, userAbilities }} />
        {shouldShowDates && <ServiceInRegionHistory {...{ history: filteredHistory, userAbilities }} />}
      </>
    );
  }
  return <NoAccessError/>
};

export default ServiceInRegionContainer;
