import * as React from "react";
import { useCallback, useState } from "react";
import ServicesListTable from "../../components/ServicesList/ServicesListTable";
import useBreadcrumbs from "../../components/Breadcrumbs/useBreadcrumbs";
import useSWR from "swr";
import { ServiceDao } from "../../daos/ServiceDao";
import { getSwrConfig } from "../../daos/swrUtil";
import { NoAccessError } from "../../components/common/NoAccessError";
import { Flash } from "@amzn/awsui-components-react";
import { Loading } from "../../components/common/Loading";
import { getAppState } from "../../models/ApplicationState";
import { parseAllFromReconServiceDto } from "../../parsers/serviceParser";

const ServicesListContainer = () => {

  const [currentLoadAll, currentLoadAllSet] = useState(false);
  const [loadTrigger, loadTriggerSet] = useState(1);
  const [items, itemsSet] = useState({ allServices: [], categoryServices: [] });

  const userAbilities = getAppState().userAbilities;

  const { isValidating, error } = useSWR(() => `services${loadTrigger}`, async () => {
    const dao = new ServiceDao();
    const displayItems = { ...items }
    if (currentLoadAll) {
      const dto = await dao.getServices(true);
      const vo = parseAllFromReconServiceDto(dto);
      displayItems.allServices = vo;
    } else {
      const dto = await dao.getServices();
      const vo = parseAllFromReconServiceDto(dto);
      displayItems.categoryServices = vo;
    }
    itemsSet(displayItems)
  }, getSwrConfig());

  const tableOnToggle = useCallback((includeNonCategorizedServices) => {
    currentLoadAllSet(includeNonCategorizedServices);
    if (includeNonCategorizedServices ? items.allServices.length === 0 : items.categoryServices.length === 0) {
      loadTriggerSet(Date.now())
    }
  }, [items.allServices, items.categoryServices]);

  useBreadcrumbs([ "Services" ]);

  const tableItems = currentLoadAll ? items.allServices : items.categoryServices;

  if (isValidating) {
    return <Loading/>;
  }
  if (userAbilities.canRead) {
    if (error) {
      return (
        <Flash
          type="error"
          header="Data Fetching Error"
          content="We are having an issue getting this data right now,
          try refreshing or please reach out to us if you continue to have issues"
        />
      );
    }
    return <ServicesListTable
      items={tableItems}
      loadAllServices={currentLoadAll}
      onToggle={tableOnToggle}
      loading={isValidating}
    />;
  }
  return <NoAccessError/>

};

export default ServicesListContainer;
