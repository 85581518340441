import * as React from "react";
import Alert from "@amzn/awsui-components-react/polaris/alert";
import { FunctionComponent } from "react";
import { getDataTestIdProp } from "../../../../utils/testUtil";
import { IHasDataTestId } from "../../../../models/IHasDataTestId";

export interface LaunchInformationBannerProps extends IHasDataTestId {
  header: string;
  text: string;
  visible: boolean;
  setVisible: (visible: boolean) => void;
}

export const LaunchInformationBannerTestIdSuffixes = {
  span: "-span",
}

export const LaunchInformationBanner: FunctionComponent<LaunchInformationBannerProps> = (props) => {
  return (
    <>
    <span
      {...getDataTestIdProp(props, LaunchInformationBannerTestIdSuffixes.span)}
    >
    <Alert
      onDismiss={() => props.setVisible(false)}
      visible={props.visible}
      header={props.header}
    >
      {props.text}
    </Alert>
    </span>
    </>
  )
}
