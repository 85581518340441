import * as React from "react";
import { FunctionComponent, useEffect, useState } from "react";
import { useJsonLoader } from "../../../utils/containerUtil";
import { IReconServiceParityItemDto, IRegionMetadataDto } from "@amzn/api-parity-react-component/lib/models/dtos/recon";
import { IContextualSwrResponse } from "../../../models/types/IContextualSwrResponse";
import { Optional } from "../../../models/types/Optional";
import {
  IApiParityBootstrapDataInput,
} from "@amzn/api-parity-react-component/lib/models/BoostrapDataInput";
import memoize from "micro-memoize";
import { DetailLoadingVO } from "../../../models/vos/DetailLoadingVO";
import { getSwrStatus } from "../../../utils/swrUtil";
import { DetailLoader } from "../../DetailLoader/DetailLoader";
import { loadStateToLoadingStatus } from "../../../utils/modelUtil";
import { ApiParityLoaderProps } from "./ApiParityLoader.types";
import { IAwsServiceIdMapDto, IAwsServiceSdkApi } from "@amzn/api-parity-react-component/lib/models/dtos/type";
import { IServiceNoDataReasonDto } from "@amzn/api-parity-react-component/lib/models/dtos/IServiceNoDataReason";
import { IAutoCreateServiceDto } from "@amzn/api-parity-react-component/lib/models/dtos/IAutoCreateServiceDto";
import { ICecmRegionEventDto } from "@amzn/api-parity-react-component/lib/models/dtos/cecm";
import { AwsServiceVO } from "@amzn/api-parity-react-component";
import { isFullyLoaded } from "../../../utils/loadingStatusUtil";

function combineDataImpl(
  serviceIdMap: Optional<IAwsServiceIdMapDto[]>,
  serviceNoDataReason: Optional<IServiceNoDataReasonDto[]>,
  autoCreateService: Optional<IAutoCreateServiceDto>,
  exemptedApi: Optional<string>,
  reconServiceParity: Optional<IReconServiceParityItemDto[]>,
  reconRegions: Optional<IRegionMetadataDto[]>,
  reconServices: Optional<AwsServiceVO[]>,
  cloudTrailCecmData: Optional<ICecmRegionEventDto[]>,
  sdkApi: Optional<IAwsServiceSdkApi[]>,
  apiParityOverride: Optional<string>,
): Partial<IApiParityBootstrapDataInput> {
  return {
    serviceIdMap,
    serviceNoDataReason,
    autoCreateService,
    exemptedApi,
    reconServiceParity,
    reconRegions,
    reconServices,
    cloudTrailCecmData,
    sdkApi,
    apiParityOverride,
  };
}

const combineData = memoize(combineDataImpl);

export const ApiParityLoader: FunctionComponent<ApiParityLoaderProps> = (props) => {
  const {
    serviceList,
    serviceListLoadState,
    onDataLoaded,
  } = props;

  // @ts-ignore type mismatch has to do with contact
  const reconParitySwr = useJsonLoader<IReconServiceParityItemDto[]>(() => props.getServiceParities(), "RECON Parity");
  const reconRegionListSwr = useJsonLoader<IRegionMetadataDto[]>(() => props.getRegions(), "RECON Regions");
  const serviceIdMapSwr = useJsonLoader<IAwsServiceIdMapDto[]>(() => props.getServiceIdMap(), "RIP/SDK/CloudTrail Service ID Mapping");
  const serviceNoDataReasonSwr = useJsonLoader<IServiceNoDataReasonDto[]>(() => props.getServiceNoDataReason(), "Service Explanation");
  const autoAddServiceSwr = useJsonLoader<IAutoCreateServiceDto>(() => props.getAutoAddServices(), "Supplemental Service Metadata");
  const cloudTrailCecmParitySwr = useJsonLoader<ICecmRegionEventDto[]>(() => props.getCecmParities(), "Cloudtrail CECM Parity");
  const cloudTrailExemptedApiSwr = useJsonLoader<string>(() => props.getCloudTrailExemptedApis(), "Cloudtrail Exempted APIs");
  const apiParityOverrideSwr = useJsonLoader<string>(() => props.getApiParityOverrides(), "API Parity Overrides");
  const sdkApiSwr = useJsonLoader<IAwsServiceSdkApi[]>(() => props.getAwsSdkMetadata(), "AWS SDK API Definitions");

  const allLoaders: IContextualSwrResponse<any>[] = [
    reconParitySwr,
    reconRegionListSwr,
    serviceIdMapSwr,
    serviceNoDataReasonSwr,
    autoAddServiceSwr,
    cloudTrailCecmParitySwr,
    cloudTrailExemptedApiSwr,
    sdkApiSwr,
    apiParityOverrideSwr,
  ];

  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const [data, setData] = useState<Optional<Partial<IApiParityBootstrapDataInput>>>();

  useEffect(() => {
    const allDataLoaded: boolean = isFullyLoaded(allLoaders, [serviceListLoadState]);

    setIsLoaded(allDataLoaded);

    if (allDataLoaded) {
      setData(combineData(
        serviceIdMapSwr.response.data,
        serviceNoDataReasonSwr.response.data,
        autoAddServiceSwr.response.data,
        cloudTrailExemptedApiSwr.response.data,
        reconParitySwr.response.data,
        reconRegionListSwr.response.data,
        serviceList,
        cloudTrailCecmParitySwr.response.data,
        sdkApiSwr.response.data,
        apiParityOverrideSwr.response.data,
      ));
    } else {
      setData(undefined);
    }
  }, [
    serviceIdMapSwr,
    serviceNoDataReasonSwr,
    autoAddServiceSwr,
    cloudTrailExemptedApiSwr,
    reconParitySwr,
    reconRegionListSwr,
    serviceList,
    cloudTrailCecmParitySwr,
    sdkApiSwr,
    apiParityOverrideSwr,
  ]);

  useEffect(() => {
    if (data && onDataLoaded) {
      onDataLoaded(data as IApiParityBootstrapDataInput);
    }
  }, [data, onDataLoaded]);

  let content: React.ReactElement;
  if (!isLoaded) {
    const loadingStatus: DetailLoadingVO[] = allLoaders.map((loader) => {
      return {
        label: loader.context,
        status: getSwrStatus(loader.response),
        error: loader.response.error?.message,
      };
    });

    loadingStatus.push({
      label: "All Services",
      status: loadStateToLoadingStatus(serviceListLoadState),
    });

    content = <DetailLoader items={loadingStatus} />;
  } else {
    content = <React.Fragment />
  }

  return content;
};
