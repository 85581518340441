import { Optional } from "../models/types/Optional";
import { TreeItem } from "../models/TreeItem";
import { FilterableTreeItem, MatchedState } from "../models/FilterableTreeItem";

/**
 * Get all the items and their descendants
 * @param items
 * @param getChildrenFn
 */
export function getDescendants<T extends TreeItem>(items: readonly T[], getChildrenFn: (item: T) => Optional<readonly T[]>): T[] {
  const result: T[] = [];

  for (const item of items) {
    result.push(item);
    const children: Optional<readonly T[]> = getChildrenFn(item);
    if (children && children.length) {
      const descendants: T[] = getDescendants(children, getChildrenFn);
      result.push(...descendants);
    }
  }

  return result;
}

export function getAutoExpandItems<T extends FilterableTreeItem>(items: T[]): readonly T[] {
  const result: T[] = [];
  for (const item of items) {
    if (item.matchState === MatchedState.MatchDescendant) {
      result.push(item);
      result.push(...getAutoExpandItems(item.childItems as T[]));
    }
  }
  return result;
}
