import { AwsServiceVO, CollectionViewBase, RegionVO } from "@amzn/api-parity-react-component";
import { FeatureParityOverrideVO } from "../vos/FeatureParityOverrideVO";
import { FeatureParityOverrideCollection } from "./FeatureParityOverrideCollection";
import { FeatureVO } from "../vos/FeatureVO";
import { Optional } from "../types/Optional";

export class FeatureParityOverrideCollectionView extends CollectionViewBase<FeatureParityOverrideVO, FeatureParityOverrideCollection | FeatureParityOverrideCollectionView> {
  byFeature(feature: FeatureVO): readonly FeatureParityOverrideVO[] {
    return this.applyFilterAndSort(this.source.byFeature(feature));
  }

  byService(service: AwsServiceVO): readonly FeatureParityOverrideVO[] {
    return this.applyFilterAndSort(this.source.byService(service))
  }

  byRegion(region: RegionVO): readonly FeatureParityOverrideVO[] {
    return this.applyFilterAndSort(this.source.byRegion(region))
  }

  byServiceRegion(service: AwsServiceVO, region: RegionVO): readonly FeatureParityOverrideVO[] {
    return this.applyFilterAndSort(this.source.byServiceRegion(service, region));
  }

  byFeatureRegion(feature: FeatureVO, region: RegionVO): Optional<FeatureParityOverrideVO> {
    return this.returnItemIfPassesFilter(
      this.source.byFeatureRegion(feature, region)
    );
  }
}
