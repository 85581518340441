import { getDateForFileName } from "../../utils/dateUtil";
import { ProductVO } from "../../models/vos/ProductVO";
import { ExportDataBook, ExportDataCell } from "@amzn/excelerator";
import { dataToDataBook, emptyExportValue } from "../ExportTableToCSVExcel/ExportTableToCSVExcel";
import { formulaEscape } from "../../utils/reportsUtils";

export const productsListCsvHeaders = [
  "Name",
  "Id",
  "Codename",
  "State",
  "Parent product id",
  "RIP id",
  "Type",
  "Audience",
  "Confidential",
  "Category",
  "VP",
  "GM",
  "CTI",
  "Tags",
  "Description",
  "Product owner",
  "Secondary owners",
  "Read permissions",
  "Write permissions"
];

export const productsListCsvFilename = `ProductsList-${getDateForFileName()}.csv`;

export const productsListDataModel = (products: ProductVO[]): ExportDataCell[][] => {
  return products.map((product) => {
    return formulaEscape([
      product.name,
      product.id,
      product.codename ?? emptyExportValue,
      product.state ?? emptyExportValue,
      product.parentProductId ?? emptyExportValue,
      product.ripId ?? emptyExportValue,
      product.type ?? emptyExportValue,
      product.audience ?? emptyExportValue,
      product.confidential ?? emptyExportValue,
      product.category ?? emptyExportValue,
      product.vp ?? emptyExportValue,
      product.gm ?? emptyExportValue,
      product.cti ?? emptyExportValue,
      product.tags && product.tags?.length !== 0 ? product.tags?.join(", ") : emptyExportValue,
      product.description ?? emptyExportValue,
      product.primaryOwner?.alias ?? emptyExportValue,
      product.secondaryOwners?.map(
        secondaryOwner => `${secondaryOwner.contactType}: ${secondaryOwner.alias}`
      ).join(", ") ?? emptyExportValue,
      product.readGroups?.join(", ") ?? emptyExportValue,
      product.writeGroups?.join(", ") ?? emptyExportValue,
    ]);
  });
}

export const generateProductsListWorkbook = (products: ProductVO[]): ExportDataBook => {
  let productsListExcelData: ExportDataCell[][] = [productsListCsvHeaders];

  productsListExcelData = productsListExcelData.concat(productsListDataModel(products));

  return dataToDataBook(productsListExcelData);
}
