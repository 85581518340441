import { IHasId, IHasParity } from "@amzn/api-parity-react-component/lib/models/IHasId";
import { ExcludeFromPercentage, RegionVO } from "@amzn/api-parity-react-component/lib/models/vos";
import { hydrateModel } from "../../utils/modelUtil";
import { ParityStatusEnum } from "@amzn/api-parity-react-component/lib/models/ParityStatus";
import { FeatureVO } from "./FeatureVO";
import { FeatureInRegionStatusEnum } from "../FeatureInRegionStatus";
import { ServiceBuildPlanVO } from "@amzn/api-parity-react-component/lib/models/vos/ServiceBuildPlanVO";

export class FeatureParityVO implements IHasId, IHasParity {
  id: string = "";
  feature: FeatureVO = new FeatureVO();
  region: RegionVO = new RegionVO();
  parity: ParityStatusEnum = ParityStatusEnum.NoData;

  status: FeatureInRegionStatusEnum = FeatureInRegionStatusEnum.NotPlanned;
  statusInferredFrom?: FeatureParityVO | ServiceBuildPlanVO;
  plannedDate?: Date;

  /**
   * A calculation hint whether the parity should be excluded from % calculation
   * A feature parity should be excluded if
   * - There is an exception associated with the Parity such as not launching
   */
  excludeFromPercentage: ExcludeFromPercentage = ExcludeFromPercentage.Include;


  constructor(from?: Partial<FeatureParityVO>) {
    hydrateModel(from, this);
  }
}
