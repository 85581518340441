import { normalizeServiceName, getReconApiClient } from "./daoUtil";
import { ComponentFeatureVO } from "../models/vos/ComponentFeatureVO";


const convertSlashAndEncodeURIComponent = (param: string): string => {
  return encodeURIComponent(normalizeServiceName(param));
}

export class ComponentFeatureDao {

  getComponentFeatureServiceUrl(serviceName: string): string {
    return `/api/services/${convertSlashAndEncodeURIComponent(serviceName)}/taggedfeatures`;
  }

  getComponentFeatureComponentServiceUrl(serviceName: string, componentServiceName: string): string {
    return `/api/services/${convertSlashAndEncodeURIComponent(serviceName)}/taggedfeatures/${convertSlashAndEncodeURIComponent(componentServiceName)}`;
  }

  getComponentFeaturePostRequestBody(componentServiceName: string) {
    return { "rip_component_service_name": componentServiceName }
  }

  async postComponentFeatures(componentFeatures: ComponentFeatureVO[]): Promise<string[]> {
    const client = getReconApiClient();
    const results: any[] = []

    for (const componentFeature of componentFeatures) {
      const res = await client.post(
        this.getComponentFeatureServiceUrl(componentFeature.serviceName),
        this.getComponentFeaturePostRequestBody(componentFeature.serviceComponentName)
      );

      results.push(res.data.message)
    }

    return results;
  }

  async deleteComponentFeatures(componentFeatures: ComponentFeatureVO[]): Promise<string[]> {
    const client = getReconApiClient();
    const results: any[] = []

    for (const componentFeature of componentFeatures) {
      const res = await client.delete(
        this.getComponentFeatureComponentServiceUrl(
          componentFeature.serviceName,
          componentFeature.serviceComponentName
        ));

      results.push(res.data.message)
    }

    return results;
  }

}
