import React, { FunctionComponent } from "react";
import { Box, Container, SpaceBetween } from "@amzn/awsui-components-react-v3";
import { LaunchDocumentFormGroupProps } from "./LaunchDocumentFormGroup.types";
import { LaunchDocumentForm } from "./LaunchDocumentForm/LaunchDocumentForm";
import { getDataTestIdProp } from "../../../../utils/testUtil";

export const LaunchDocumentFormGroupTestIdSuffixes = {
  root: "-root",
  documents: "-documents",
}

const EmptyDocuments = () => {
  return (
    <Container>
      <Box textAlign="center" color="inherit">
        <Box variant="strong" textAlign="center" color="inherit">
          No documents
        </Box>
        <Box variant="p" padding={{ bottom: "s" }} color="inherit">
          There are no documents for this launch
        </Box>
      </Box>
    </Container>
  );
}

export const LaunchDocumentFormGroup: FunctionComponent<LaunchDocumentFormGroupProps> = (props) => {
  const { control, errors, documents, remove, root } = props;

  return (
    <div
      {...getDataTestIdProp(props, LaunchDocumentFormGroupTestIdSuffixes.root)}
    >
      {documents.length > 0
        ? <SpaceBetween
          size="l"
          direction="vertical"
          {...getDataTestIdProp(props, LaunchDocumentFormGroupTestIdSuffixes.documents)}
        >
          {documents.map((document, index) =>
            <div key={document.id}>
              <LaunchDocumentForm
                control={control}
                errors={errors?.[index]}
                remove={() => remove(index)}
                document={document}
                root={`${root}[${index}]`}
              />
            </div>
          )}
        </SpaceBetween>
        : <EmptyDocuments/>
      }
    </div>
  );
}
