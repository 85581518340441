import * as React from "react"
import { Flashbar, FlashbarProps, Link } from "@amzn/awsui-components-react-v3"
import { FAQ_URL } from "../../constants";
import { getAppState } from "../../models/ApplicationState";

export const NoAccessErrorTestId = {
  flash: "NoAccessErrorTestId-flash"
}

export function NoAccessError() {

  const content = <React.Fragment>
    You do not have the permission to view this part of Recon. {getAppState().userAbilities.getAbilityDescription()}.
    Please visit <Link href={FAQ_URL} external={true} color="inverted">our FAQs</Link> for more information.
  </React.Fragment>

  const items: FlashbarProps.MessageDefinition[] = [
    {
      type: "error",
      content
    }
  ];

  return (
    <Flashbar
      items={items}
      data-testid={NoAccessErrorTestId.flash}
    />
  );
}

export const getComponentOrNoAccess = (canAccess: boolean,
                                       componentSupplier: () => JSX.Element,
                                       noAccessErrorSupplier: () => JSX.Element = NoAccessError): JSX.Element => {
  return canAccess ? componentSupplier() : noAccessErrorSupplier();
}
