import * as React from "react"
import { FunctionComponent } from "react"
import { PartitionFormFieldsProps } from "./PartitionFormFields.types";
import { FormField, Select } from "@amzn/awsui-components-react-v3";
import {
  getIndividualFormFieldLabel,
  IndividualContactFormFieldWithControllerTestIdSuffix
} from "../IndividualContactFormFieldWithController/IndividualContactFormFieldWithController";
import { partitionFormFields } from "../AddOrEditServiceContactModalFormFields";
import { getDataTestIdProp } from "../../../../../utils/testUtil";
import { Controller } from "react-hook-form";
import { Optional } from "../../../../../models/types/Optional";

let defaultPartition: Optional<string> = "aws";
const VALIDATE_NON_EMPTY_STRING = (str: string): boolean => !!str;

// Hardcoded while we figure out what the proper rip call is.
export const PARTITION_TO_LONG_NAME = {
  "aws": "Public AWS Partition",
  "aws-us-gov": "AWS GovCloud",
  "aws-cn": "AWS China",
  "aws-iso": "aws-iso",
  "aws-iso-b": "aws-iso-b",
  "aws-iso-e": "aws-iso-e",
  "aws-iso-f": "aws-iso-f",
}

const SELECT_PARTITION_OPTIONS = Array.from(Object.keys(PARTITION_TO_LONG_NAME)).map(partition => {
  return { label: `${PARTITION_TO_LONG_NAME[partition]} (${partition})`, value: partition }
})

const VALUE_TO_PARTITION_OPTION = {}

SELECT_PARTITION_OPTIONS.forEach(partition => {
  VALUE_TO_PARTITION_OPTION[partition.value] = partition
})

let modifiedOptions = SELECT_PARTITION_OPTIONS;

function removeItem<T>(arr: Array<T>, value: T): Array<T> { 
  const index = arr.indexOf(value);
  if (index > -1) {
    arr.splice(index, 1);
  }
  return arr;
}


export const PartitionFormFields: FunctionComponent<PartitionFormFieldsProps> = (props) => {
  const formField = partitionFormFields.partition;

  if (props.removeEntries) {
    props.removeEntries.forEach(partitionToRemove => {
      modifiedOptions = removeItem(modifiedOptions, VALUE_TO_PARTITION_OPTION[partitionToRemove])
    })
    defaultPartition = ""
  }

  return (
    <Controller
      name={formField.id}
      id={formField.id}
      control={props.control}
      rules={{
        required: "Required",
        validate: VALIDATE_NON_EMPTY_STRING
      }}
      defaultValue={defaultPartition}
      render={({ onChange, value }, { invalid }) =>
        <FormField
          {...getDataTestIdProp(props, IndividualContactFormFieldWithControllerTestIdSuffix.formField)}
          label={getIndividualFormFieldLabel(formField.placeholder, formField.validation)}
          errorText={(invalid && props.errors[formField.id].message)}
          key={formField.id}
        >
          {props.removeEntries ? 
            <Select
            {...getDataTestIdProp(props, IndividualContactFormFieldWithControllerTestIdSuffix.input)}
            onChange={event => onChange(event.detail.selectedOption.value)}
            options={modifiedOptions}
            selectedOption={VALUE_TO_PARTITION_OPTION[value]}
            key={formField.id + "SelectNewPartition"}/> :

            <Select
            {...getDataTestIdProp(props, IndividualContactFormFieldWithControllerTestIdSuffix.input)}
            onChange={event => onChange(event.detail.selectedOption.value)}
            options={SELECT_PARTITION_OPTIONS}
            selectedOption={VALUE_TO_PARTITION_OPTION[value]}
            key={formField.id + "Select"}/>
        }
        </FormField>
      }
    />
  );

}
