import { ICosApiCecmEventDto, IsoTimeStamp } from "@amzn/api-parity-react-component/lib/models/dtos/cecm";
import { JSONSchemaType } from "ajv";

export interface ICosApiRawEventDto {
  eventName: string;
  serviceName: string;
  region: string;
  lastCecmDate: IsoTimeStamp;
}

export interface ICosApiCecmEventSchemaErrorDto {
  /**
   * The data entry with issue
   */
  entry: ICosApiRawEventDto;
  errorFields: (keyof ICosApiRawEventDto)[]
}

export interface ICosApiListEventStatusForOneRegionDto {
  errors?: ICosApiCecmEventSchemaErrorDto[];
  usages: ICosApiCecmEventDto[];
  airportCode: string;
}

// TODO: This patterns would be moved to a more centralize place
export const AIRPORT_PATTERN: RegExp = /^[A-Z]{3}$/;

/**
 * Service API are alphanumerics, and dash (-), underscore(_), squared brackets ([ ]), period (.) and colon (:)
 */
export const SERVICE_API_PATTERN: RegExp = /^[[\].:A-Za-z0-9_-]*$/;

/**
 * credit: https://stackoverflow.com/questions/12756159/regex-and-iso8601-formatted-datetime
 * limitation: it does not check bad month like 19, or bad combination such as Feb. 30
 */
export const ISO_DATE_TIME_PATTERN: RegExp = /^([+-]?\d{4}(?!\d{2}\b))((-?)((0[1-9]|1[0-2])(\3([12]\d|0[1-9]|3[01]))?|W([0-4]\d|5[0-2])(-?[1-7])?|(00[1-9]|0[1-9]\d|[12]\d{2}|3([0-5]\d|6[1-6])))([T\s]((([01]\d|2[0-3])((:?)[0-5]\d)?|24:?00)([.,]\d+(?!:))?)?(\17[0-5]\d([.,]\d+)?)?([zZ]|([+-])([01]\d|2[0-3]):?([0-5]\d)?)?)?)?$/;

// TODO: Harden this function and move it to generic util
export function regexToString(re: RegExp): string {
  let result: string = re.toString();
  if (result.startsWith("/")) {
    result = result.substr(1);
  }

  // Edge case such as regex option is not handled here
  if (result.endsWith("/")) {
    result = result.substr(0, result.length - 1);
  }
  return result;
}

// @ts-ignore
export const cosApiCecmEventDtoSchema: JSONSchemaType<ICosApiCecmEventDto> = {
  type: "object",
  properties: {
    api: {
      type: "string",
      minLength: 3,
      maxLength: 128, // 50 character should be long enough for API name
      pattern: regexToString(SERVICE_API_PATTERN),
    },
    service: {
      type: "string",
      minLength: 2,
      maxLength: 50, // 50 character should be long enough for service name
      pattern: regexToString(SERVICE_API_PATTERN),
    },
    lastCecm: {
      type: "string",
      minLength: 10, // Date only
      maxLength: 30, // With timezone and milliseconds
      pattern: regexToString(ISO_DATE_TIME_PATTERN),
    },
  },
  required: ["api", "service", "lastCecm"],
  additionalProperties: false
};

// @ts-ignore
export const cosApiCecmEventSchemaErrorDtoSchema: JSONSchemaType<ICosApiCecmEventSchemaErrorDto> = {
  type: "object",
  properties: {
    entry: {
      type: "object",
      properties: {
        // There are no patterns restrictions in error entries because they are here
        // because some of them have pattern violations
        eventName: {
          type: "string",
        },
        serviceName: {
          type: "string",
        },
        region: {
          type: "string",
        },
        lastCecmDate: {
          type: "string",
        }
      },
      required: ["eventName", "serviceName"]
    },
    errorFields: {
      type: "array",
      items: {
        type: "string",
      }
    }
  }
}


// @ts-ignore
export const cosApiListEventStatusForOneRegionDtoSchema: JSONSchemaType<ICosApiListEventStatusForOneRegionDto> = {
  type: "object",
  properties: {
    airportCode: {
      type: "string",
      minLength: 3,
      maxLength: 3,
      pattern: regexToString(AIRPORT_PATTERN),
    },
    usages: {
      type: "array",
      items: cosApiCecmEventDtoSchema,
    },
    errors: {
      type: "array",
      items: cosApiCecmEventSchemaErrorDtoSchema,
    }
  },
  required: ["usages", "airportCode"],
  additionalProperties: false,
};
