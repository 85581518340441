import moment from "moment";
import { DateLike } from "../models/DateLike";
import { Optional } from "../models/types/Optional"
import { Nullable } from "../models/types/Nullable";
import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import relativeTime from "dayjs/plugin/relativeTime";
import { DateTime } from "luxon";

dayjs.extend(duration);
dayjs.extend(relativeTime);

export const SHORT_DATE_FORMAT = "YYYY-MM-DD";
export const COMPARABLE_DATE_IGNORE_TIME_FORMAT = "YYYY-MM-DD";

const PARITY_CELL_FORMAT = "M/YY"

export function formatEstimatedLaunchDate(date) {
  return moment(date).format(PARITY_CELL_FORMAT)
}

export function parseDate(dateString: Nullable<string>) : Optional<Date> {
  if (dateString) {
    const m = moment(dateString);
    return m.isValid() ? m.toDate() : undefined;
  }
  return;
}

export function toShortDate(date : Nullable<DateLike>, default_value: Optional<string> = undefined) : Optional<string> {
  const dateMoment = moment(date)
  return date && dateMoment.isValid() ? dateMoment.format(SHORT_DATE_FORMAT) : default_value
}

export function getDateDifference(a: DateLike, b: DateLike) : number{
  const ma = moment(a).startOf("day");
  const mb = moment(b).startOf("day");
  return ma.diff(mb, "day");
}

/**
 * Display a past time in a humanized form such as "a month ago"
 * @param pastTime
 */
export function getTimeAgo(pastTime: Date): string {
  const timeMs: number = pastTime.getTime() - Date.now();
  return dayjs.duration(timeMs).humanize(true);
}

/**
 * Return a date string to be used as part of filename
 * @param date
 */
export function getDateForFileName(date: Date = new Date()): string {
  return dayjs(date).format("YYYYMMDD_HHmm");
}

export const hasDisposition = (disposition: Optional<string>): boolean => {
  return !!disposition && disposition !== "None";
}

/**
 * Return a date in MM/YY format
 * @param date
 */
export function getParityShortDate(date: Date): string {
  return dayjs(date).format("MM/YY");
}

export function getDateFromISOString(isoString: string): Optional<string> {
  const splitIsoDate = isoString.split("T");
  if (splitIsoDate.length === 2) {
    return splitIsoDate[0]
  }
  return;
}

export function getPstDateToday(): string {
  const today = DateTime.local().setZone("America/Los_Angeles");
  // @ts-ignore
  return getDateFromISOString(today.toISO());
}

