import * as React from "react";
import { Alert, Link } from "@amzn/awsui-components-react-v3";
import { RECON_URL_CREATE_ISSUE } from "../../constants";
import { DataFetchingErrorProps } from "./DataFetchingError.types";
import { getDataTestIdProp } from "../../utils/testUtil";

function refresh_onClick() {
  window.location.reload();
}


export const DataFetchingError: React.FunctionComponent<DataFetchingErrorProps> = (props) => {

  const reportLink = (
    <div>
      Sorry it looks like we have encountered an error, please try to {" "}
      <a { ...getDataTestIdProp(props, DataFetchingErrorTestIdSuffixes.reloadLink) } onClick={refresh_onClick}>refresh the browser</a>{" "}
      to see if the problem goes away;
      if this issue persists, please{" "}
      <Link
        href={RECON_URL_CREATE_ISSUE}
        external={true}>
        report
      </Link>
      {" "}
      it to the Recon Team.
    </div>
  );

  return (
    <Alert
      header={
        <span { ...getDataTestIdProp(props, DataFetchingErrorTestIdSuffixes.header) }>{props?.error?.data?.message ?? "Unknown Error"}</span>
      }
      type="error">{reportLink}</Alert>
  );
};

export const DataFetchingErrorTestIdSuffixes = {
  reloadLink: "-reload",
  header: "-header",
}

export default DataFetchingError;
