import { ServiceListVM } from "./ServicesListTable.types";
import { NonOptionalKeys } from "../../models/types/NonOptionalKeys";

export type ServiceListSortConfig = {
  id: string,
  field: string,
  comparator: (a: ServiceListVM, b: ServiceListVM) => number
}

/**
 * This method provides a default sort config which compares string values
 * @param serviceListVMKey a non-optional key of ServiceListVM which maps to a string value
 */
export const getServiceListDefaultSortConfig = (serviceListVMKey: NonOptionalKeys<ServiceListVM>): ServiceListSortConfig => {
  return {
    id: serviceListVMKey,
    field: serviceListVMKey,
    comparator: (a: ServiceListVM, b: ServiceListVM) => a[serviceListVMKey].localeCompare(b[serviceListVMKey])
  }
}


export const getServiceListDefaultSortConfigs = (serviceListVMKeys: NonOptionalKeys<ServiceListVM>[]): ServiceListSortConfig[] => {
  return serviceListVMKeys.map(getServiceListDefaultSortConfig)
}
