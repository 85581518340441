import React from "react";

const TableEmpty = ({ primary, secondary }) => {
  return (
    <div className="awsui-util-t-c">
      <div className="awsui-util-pt-s awsui-util-mb-xs">
        <b>{primary}</b>
      </div>
      <p className="awsui-util-mb-s">{secondary}</p>
    </div>
  );
};

export default TableEmpty;
