import { ISortComparator, SortByDirection } from "@amzn/api-parity-react-component/lib/models/SortBy";
import { FeatureTreeItem } from "../../../../../models/FeatureTreeItem";
import memoize from "micro-memoize";
import { getFeatureTreeItemService, getGm, getVp } from "../../../../../utils/featureTreeItemUtil";
import { Nullable } from "../../../../../models/types/Nullable";
import { TreeItemEntity } from "../../../../../utils/FeatureParityScoreCalculator";

export const FeatureParityDataSortKey = {
  parityScore: "parityScore",
  gm: "gm",
  vp: "vp",
  visibility: "visibility",
  category: "category",
};

/**
 * When an alias is absent, what value the code should use for sorting
 * The value of z's is to ensure it would be sorted last.
 */
export const absentAliasSortValue = "zzzzzzzzzzzz";

export const parityScoreExtractFn = (parityScore: Map<TreeItemEntity, Nullable<number>>) => {
  return (item: FeatureTreeItem) => parityScore.get(item.entity);
}
export const gmExtractFn = (item: FeatureTreeItem) => getGm(item) ?? absentAliasSortValue;
export const vpExtractFn = (item: FeatureTreeItem) => getVp(item) ?? absentAliasSortValue;
export const categoryExtractFn = (item: FeatureTreeItem) => getFeatureTreeItemService(item)?.expansionPlan ?? "";
export const nameExtractFn = (item: FeatureTreeItem) => item.name;
export const visibilityExtractFn = (item: FeatureTreeItem) => item.entity.visibility as string;

// The sorting functions below are intentionally left looking similar instead of generalizing them,
// That would make tweaking sorting easier.
// The memoization is to return the same sort

function getParityScoreSortImpl<T extends FeatureTreeItem>(direction: SortByDirection, parityScore: Map<TreeItemEntity, Nullable<number>>): readonly ISortComparator<T>[] {
  return [
    {
      direction,
      // @ts-ignore
      valueExtractFn: parityScoreExtractFn(parityScore),
    },
    {
      direction: SortByDirection.Ascending,
      valueExtractFn: nameExtractFn,
    }
  ]
}

export const getParityScoreSort = memoize(getParityScoreSortImpl, {
  maxSize: 10
});

function getCategorySortImpl<T extends FeatureTreeItem>(direction: SortByDirection, parityScore: Map<TreeItemEntity, Nullable<number>>): readonly ISortComparator<T>[] {
  return [
    {
      direction,
      valueExtractFn: categoryExtractFn,
    },
    {
      direction: SortByDirection.Ascending,
      // @ts-ignore
      valueExtractFn: parityScoreExtractFn(parityScore),
    }
  ]
}

export const getCategorySort = memoize(getCategorySortImpl, {
  maxSize: 10
});

function getGmSortImpl<T extends FeatureTreeItem>(direction: SortByDirection): readonly ISortComparator<T>[] {
  return [
    {
      direction,
      valueExtractFn: gmExtractFn,
    },
    {
      direction: SortByDirection.Ascending,
      valueExtractFn: nameExtractFn,
    }
  ]
}

export const getGmSort = memoize(getGmSortImpl, {
  maxSize: 10
});

function getVpSortImpl<T extends FeatureTreeItem>(direction: SortByDirection): readonly ISortComparator<T>[] {
  return [
    {
      direction,
      valueExtractFn: vpExtractFn,
    },
    {
      direction: SortByDirection.Ascending,
      valueExtractFn: nameExtractFn,
    }
  ]
}

export const getVpSort = memoize(getVpSortImpl, {
  maxSize: 10
});

function getVisibilitySortImpl<T extends FeatureTreeItem>(direction: SortByDirection): readonly ISortComparator<T>[] {
  return [
    {
      direction,
      valueExtractFn: visibilityExtractFn,
    },
    {
      direction: SortByDirection.Ascending,
      valueExtractFn: nameExtractFn,
    }
  ]
}

export const getVisibilitySort = memoize(getVisibilitySortImpl, {
  maxSize: 10
});
