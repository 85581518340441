import * as React from "react";
import { useCallback, useState } from "react";
import { Button } from "@amzn/awsui-components-react";
import BusinessCaseOverrideModal from "./BusinessCaseOverrideModal";
import { businessCaseOverrideOptions, IBusinessCaseOverrideOption } from "../../models/BusinessCaseOverrideOptions";
import { PermissionError } from "../common/PermissionError";
import { BusinessCaseOverrideSimTemplateLink } from "../common/SimLinks/BusinessCaseOverrideSimTemplateLink";
import { BusinessCaseOverrideProps } from "./BusinessCaseOverride.types";
import { Optional } from "../../models/types/Optional";

export const BusinessCaseOverrideButtonTestId = {
  button: "BusinessCaseOverrideButton-button"
}

export const BusinessCaseOverridePermissionErrorTestId = {
  modal: "BusinessCaseOverridePermissionError-modal",
  alert: "BusinessCaseOverridePermissionError-alert"
}

const BusinessCaseOverride: React.FunctionComponent<BusinessCaseOverrideProps> = (props) => {
  const {
    region,
    nameSortable,
    nameRip,
    selected,
    handleSubmitChange,
    userAbilities
  } = props;
  const [visible, setVisible] = useState(false);
  const [modalKey, setModalKey] = useState<string>("1");
  const [businessCaseOverridePermissionError, setBusinessCaseOverridePermissionError] = useState(false);

  const showFormModal = useCallback(() => {
    setModalKey(new Date().getTime().toString());
    setVisible(true)
  }, [ setVisible, setModalKey ]);

  const showPermissionModal = useCallback(() => {
    setBusinessCaseOverridePermissionError(true);
  }, [setBusinessCaseOverridePermissionError]);

  const toggleModal = useCallback(() => {
    userAbilities.canWriteBusinessCaseOverride ? showFormModal() : showPermissionModal();
  },[showFormModal, showPermissionModal, userAbilities.canWriteBusinessCaseOverride]);

  const hidePermissionModal = useCallback(() => {
    setBusinessCaseOverridePermissionError(false);
  }, [setBusinessCaseOverridePermissionError]);

  const handleDismiss = useCallback(({ detail: { reason } }) => {
    if (reason === "submit") {
      handleSubmitChange?.();
    }
    setVisible(false);
  }, [handleSubmitChange]);

  const currentSimLink = selected?.sim || "";
  const currentNote = selected?.exceptionNote || "";
  const currentOverride = selected?.disposition || "";
  const overrideOptions = businessCaseOverrideOptions;
  const currentOverrideOption: Optional<IBusinessCaseOverrideOption> = businessCaseOverrideOptions.find((option) => option.value === currentOverride);

  return (
    <>
      <BusinessCaseOverrideModal
        key={modalKey}
        selected={selected}
        region={region}
        nameSortable={nameSortable}
        nameRip={nameRip}
        handleDismiss={handleDismiss}
        overrideOptions={overrideOptions}
        currentOverrideOption={currentOverrideOption}
        currentSimLink={currentSimLink}
        currentNote={currentNote}
        visible={visible}
        onSubmit={props.onSubmit}
      />
      <PermissionError 
        header="Access Denied"
        body={<>
          Need Admin privileges to add/remove a business case override,
          please use {BusinessCaseOverrideSimTemplateLink} to submit a business case override request.
        </>}
        visible={businessCaseOverridePermissionError}
        onDismiss={hidePermissionModal}
        testIds={BusinessCaseOverridePermissionErrorTestId}
      />
      <Button
        variant="normal"
        onClick={toggleModal}
        disabled={!selected}
        data-testid={BusinessCaseOverrideButtonTestId.button}
      >
        Business Case Override
      </Button>
    </>
  );
};

export default BusinessCaseOverride;
