import React, { FunctionComponent, useCallback, useState } from "react";
import useBreadcrumbs from "../../components/Breadcrumbs/useBreadcrumbs";
import { ApiParity } from "../../components/Parity/ApiParity/ApiParity";
import { ApiParityContainerProps } from "./ApiParityContainer.types";
import { NoAccessError } from "../../components/common/NoAccessError";
import { getAppState } from "../../models/ApplicationState";
import { Optional } from "../../models/types/Optional";
import { ApiParityLoader } from "../ApiParityLoader/ApiParityLoader";
import { IApiParityBootstrapDataInput } from "@amzn/api-parity-react-component/lib/models/";

export const ApiParityContainer: FunctionComponent<ApiParityContainerProps> = (props) => {
  return getAppState().userAbilities.canReadDates ? AccessibleApiParityContainer(props) : <NoAccessError/>
};

const AccessibleApiParityContainer: FunctionComponent<ApiParityContainerProps> = (props) => {
  useBreadcrumbs([ "Parity=/parity", "API Parity" ]);
  const [data, setData] = useState<Optional<Partial<IApiParityBootstrapDataInput>>>();

  const loader_onDataLoaded = useCallback((d: Partial<IApiParityBootstrapDataInput>) => {
    setData(d);
  }, [])

  return <React.Fragment>
    <ApiParityLoader onDataLoaded={loader_onDataLoaded} />
    {
      data && <ApiParity data={data} />
    }
  </React.Fragment>
};
