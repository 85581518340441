import * as React from "react";
import { FunctionComponent, useCallback, useEffect, useState } from "react";
import { getDataTestIdProp } from "../../../../utils/testUtil";
import {
  Box,
  Button,
  Header,
  Pagination,
  PropertyFilter,
  PropertyFilterProps,
  SpaceBetween,
  Table
} from "@amzn/awsui-components-react-v3";
import { LaunchRegionsProps } from "./LaunchRegions.types";
import { displayOr } from "../../../../utils/displayUtil";
import { useCollection } from "@amzn/awsui-collection-hooks";
import { DEFAULT_I18N_STRINGS } from "../../../ProductLaunches/ProductLaunchesProperties";
import { RegionMetadataVO } from "../../../../models/vos/RegionMetadataVO";

export const LaunchRegionsTestIdSuffixes = {
  table: "-table",
  button: "-button",
}

// write click handler
const TableHeader = ({ count, clickHandler, ...props }) => (
  <Header
    counter={
      "(" + count + ")"
    }
    actions={props.headerActionsOverride ??
      (!props.hideActions
        ? <SpaceBetween size="xs" direction="horizontal">
          <Button
            disabled={!props.canEdit}
            onClick={clickHandler}
            {...getDataTestIdProp(props, LaunchRegionsTestIdSuffixes.button)}
          >
            Edit
          </Button>
        </SpaceBetween>
        : <></>)
    }
  >
    Regions
  </Header>
)

const FILTERING_PROPERTIES: PropertyFilterProps.FilteringProperty[] = [
  {
    key: "airportCode",
    operators: ["=", "!=", ":"],
    propertyLabel: "Region code",
    groupValuesLabel: "Region codes"
  },
  {
    key: "nameLong",
    operators: ["=", "!=", ":", "!:"],
    propertyLabel: "Name",
    groupValuesLabel: "Names"
  },
  {
    key: "description",
    operators: ["=", ":"],
    propertyLabel: "Region name",
    groupValuesLabel: "Region names"
  },
  {
    key: "codename",
    operators: ["=", ":"],
    propertyLabel: "Code name",
    groupValuesLabel: "Code names"
  },
  {
    key: "partition",
    operators: ["=", "!="],
    propertyLabel: "Partition",
    groupValuesLabel: "Partitions"
  },
  {
    key: "status",
    operators: ["=", "!="],
    propertyLabel: "Status",
    groupValuesLabel: "Statuses"
  },
];

const COLUMN_DEFINITIONS = [
  {
    id: "code",
    header: "Region code",
    cell: item => displayOr(item.airportCode),
  },
  {
    id: "name",
    header: "Name",
    cell: item => displayOr(item.nameLong),
  },
  {
    id: "external",
    header: "Region name",
    cell: item => displayOr(item.description)
  },
  {
    id: "codename",
    header: "Code name",
    cell: item => displayOr(item.codename)
  },
  {
    id: "partition",
    header: "Partition",
    cell: item => displayOr(item.partition)
  },
  {
    id: "status",
    header: "Status",
    cell: item => displayOr(item.status)
  }
]

const EmptyState = ({ title, subtitle, action }) => (
  <Box textAlign="center" color="inherit">
    <Box variant="strong" textAlign="center" color="inherit">
      {title}
    </Box>
    <Box variant="p" padding={{ bottom: "s" }} color="inherit">
      {subtitle}
    </Box>
    {action}
  </Box>
);

export const LaunchRegions: FunctionComponent<LaunchRegionsProps> = (props) => {
  const [tableItems, setTableItems] = useState<RegionMetadataVO[]>([]);

  const { items: data, filteredItemsCount, collectionProps, propertyFilterProps, paginationProps } = useCollection(
    tableItems,
    {
      propertyFiltering: {
        filteringProperties: FILTERING_PROPERTIES,
        empty: (
          <EmptyState
            title="No regions"
            subtitle="No regions associated with this launch."
            action={<></>}
          />
        ),
        noMatch: (
          <EmptyState
            title="No matches"
            subtitle="No matching items found."
            action={<></>}
          />
        )
      },
      pagination: {
        pageSize: 10
      },
      selection: {}
    }
  )

  useEffect(() => {
    setTableItems(props.regions?.reduce<RegionMetadataVO[]>((result, item) => {
      const matchedRegion = props.regionSource.find((match) => match.airportCode === item);
      if (matchedRegion)
        result.push(matchedRegion);
      return result;
    }, []) ?? []);
  }, [props.regionSource, props.regions]);

  const edit_onClick = useCallback(() => {
    props.onEdit?.();
  }, [props]);

  return (
    <Table
      {...collectionProps}
      items={data}
      loading={props.loading}
      columnDefinitions={COLUMN_DEFINITIONS}
      header={<TableHeader clickHandler={edit_onClick} count={tableItems.length ?? 0} {...props} />}
      filter={
        <PropertyFilter
          i18nStrings={DEFAULT_I18N_STRINGS}
          virtualScroll
          countText={`${filteredItemsCount} matches`}
          {...propertyFilterProps}
        />
      }
      pagination={
        <Pagination
          {...paginationProps}
        />
      }
      sortingDisabled
      {...getDataTestIdProp(props, LaunchRegionsTestIdSuffixes.table)}
    />
  );
}
