import React from "react";
import { Link } from "react-router-dom";

const PageNotFoundContainer = () => {
  return (
    <div className="awsui-util-p-l awsui-util-t-c">
      <h1 className="awsui-text-large">404 Error</h1>
      <h2>Page not found</h2>
      <p>Please go back to the homepage.</p>
      <Link to="/">Reporting homepage</Link>
    </div>
  );
};

export default PageNotFoundContainer;
