import * as React from "react";
import { FunctionComponent, useCallback, useState } from "react";
import { Optional } from "../../models/types/Optional";
import useBreadcrumbs from "../../components/Breadcrumbs/useBreadcrumbs";
import { FeatureParityLoaderContainer } from "../FeatureParityLoaderContainer/FeatureParityLoaderContainer";
import { FeatureParityContainerProps } from "./FeatureParityContainer.types";
import { getAppState } from "../../models/ApplicationState";
import { NoAccessError } from "../../components/common/NoAccessError";
import { IFeatureParityBootStrapData, IFeatureParityDataInput } from "../../models/featureParityBootstrap";
import { bootstrapFeatureParityData } from "../../utils/featureParityGridBootstrap";
import { FeatureParityOverviewSettingVO } from "../../models/vos/FeatureParityOverviewSettingVO";
import { FeatureParityOverviewView } from "../../components/Parity/FeatureParity/FeatureParityOverview/FeatureParityOverviewView/FeatureParityOverviewView";

export const FeatureParityContainer: FunctionComponent<FeatureParityContainerProps> = (props) => {
  return getAppState().userAbilities.canReadDates ? AccessibleFeatureParityContainer(props) : <NoAccessError/>
};

const setting  = new FeatureParityOverviewSettingVO();

export const AccessibleFeatureParityContainer: FunctionComponent<FeatureParityContainerProps> = (props) => {
  useBreadcrumbs([ "Parity=/parity", "Feature Parity" ]);
  const [data, setData] = useState<Optional<IFeatureParityBootStrapData>>();

  const loader_onDataLoaded = useCallback((d: IFeatureParityDataInput) => {
    setData(bootstrapFeatureParityData(d, console.log));
  }, [])

  return <React.Fragment>
    <FeatureParityLoaderContainer onDataLoaded={loader_onDataLoaded} />
    {
      data && <FeatureParityOverviewView
        setting={setting}
        services={data.services}
        features={data.features}
        regions={data.regions}
        featureParities={data.parities}
        serviceParities={data.buildPlans}
        featureParityRollupByService={data.featureParityRollupByService}
        zeroAvailabilityFeatures={data.zeroAvailabilityFeatures}
        zeroAvailabilityServices={data.zeroAvailabilityServices}
      />
    }
  </React.Fragment>
};
