export const headerText = "Service Availability Reports";
export const subText = "This is the report submitted to WBR meetings for David Nolton.";
export const formFieldLabel = "Please choose a report to generate";

export const GEParityTables = {
  label: "Globally Expanding Parity Tables",
  id: "1",
  templateType: "GE_PT",
  filenamePrefix: "GE Service Availability Report",
};

export const GEAndNGEParityTables = {
  label: "Globally Expanding & Non-Globally Expanding Parity Tables",
  id: "2",
  templateType: "GE_NGE_PT",
  filenamePrefix: "All Service Availability Report",
};

export const GEAndNGEDetails = {
  label: "Globally Expanding & Non-Globally Expanding Details",
  id: "3",
  templateType: "GE_NGE_D",
  filenamePrefix: "All Service Availability Details",
};

export const reportSelectOptions = [GEParityTables, GEAndNGEParityTables, GEAndNGEDetails];

export const ServiceAvailabilityReportTestId = {
  button: "ServiceAvailabilityReport-button",
  formField: "ServiceAvailabilityReportForm-button",
  typeSelect: "ServiceAvailabilityReportForm-typeSelect",
  error: "ServiceAvailabilityReportForm-error",
}

export const errorHeaderText = "There was an issue generating the Service Availability Report";
