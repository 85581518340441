import * as React from "react";
import { EditLaunchErrorAlert } from "./EditLaunchErrorAlerts";

type ErrorAlert = {
  [num: number]: (errorDescription?: object) => JSX.Element
}

/**
 * @param word - the camel-case word to convert case. If all caps, keep the same
 * @returns string - camel-case word converted to space-seperated words with first letter capitalized
 *  (e.g. camelCaseWord -> Camel Case Word) OR the same word if original word was all-caps (e.g. WORD -> WORD).
 */
export const camelCaseToNormal = (word: string) => {
  if (word === word.toUpperCase()) {
    return word
  }
  return word.replace(/([A-Z])/g, " $1").replace(/^./, (str) => {
    return str.toUpperCase();
  });
}

/**
 * @returns object mapping error codes to their associated displayed error text
 */
export const EditLaunchErrorAlerts: ErrorAlert = {
  // OK - no error
  200: () => (<></>),
  // BAD REQUEST - odds are user has submitted an invalid enum. Also may have type 2 validation issue
  400: (errorDescription) => {
    try{
      // type 1 validation case
      // @ts-ignore
      const errorFields: object[] = JSON.parse(errorDescription["error"]["message"]);
      const errorFieldNames = errorFields.map((field) => {
        return camelCaseToNormal(field["property"]);
      })
      return <EditLaunchErrorAlert header={"Data Updating Error"} description={`Invalid values for the following fields ${JSON.stringify(errorFieldNames)}.`}/> //generateAlert("Data Updating Error", `Invalid values for the following fields ${JSON.stringify(errorFieldNames)}.`)
    }
    catch{
      // type 2 and other 400 cases
      if (errorDescription && errorDescription["error"]) {
        const errorCode = errorDescription["error"]["code"]
        const errorMessage = errorDescription["error"]["message"]
        const errorName = errorDescription["error"]["name"]

        return <EditLaunchErrorAlert header={"Data Updating Error"} description={`${errorCode} ${errorName}: ${errorMessage}`}/>
      }
      return <EditLaunchErrorAlert header={"Data Updating Error"} description={JSON.stringify(errorDescription)}/> //generateAlert("Data Updating Error", JSON.stringify(errorDescription))
    }
  },
  // UNAUTHENTICATED
  401: () =>  <EditLaunchErrorAlert header={"Data Updating Error"} description={"Please authenticate with Midway."}/> ,
  // UNAUTHORIZED - somehow got to the edit page without write permissions
  403: () => <EditLaunchErrorAlert header={"Data Updating Error"} description={"You do not have permission to update this launch."}/>,
  // NOT FOUND - updating a launch that doesn't exist
  404: () => <EditLaunchErrorAlert header={"Data Updating Error"} description={"Launch not found."}/>,
  // CONFLICT
  409: () => <EditLaunchErrorAlert header={"Data Updating Error"} description={"There was a conflict updating your launch."}/>,
  // THROTTLED
  429: () => <EditLaunchErrorAlert header={"Data Updating Error"} description={"Sorry, we're receiving a high volume of requests."}/> ,
  // INTERNAL SERVER ERROR
  500: () => <EditLaunchErrorAlert header={"Data Updating Error"} description={"There was an error updating your launch."}/>,
}
