import { IFormFieldData } from "../../common/FormTypes";
import {
  LaunchAudience,
  LaunchDisclosure,
  LaunchGoalType,
  LaunchIteration,
  LaunchScope,
  LaunchState,
  LaunchStatus, LaunchTier, LaunchType,
  ReasonForChange,
} from "../../ProductLaunches/LaunchWizard/LaunchWizard.types";
import { enumToAutoSuggestOptions, enumToSelectOptions } from "../../ProductLaunches/LaunchWizard/LaunchWizardFormFields";
import {
  REQUIRED_AND_VALID_CHAR_REGEX,
  REQUIRED_AND_VALID_DESCRIPTION_REGEX,
  VALID_ALIAS_CHAR_REGEX_AND_MAXLENGTH,
  VALID_CHAR_REGEX,
  VALID_DATE_NOT_PAST_TWO_WEEKS_NOR_WEEKEND,
  VALID_DESCRIPTION_REGEX,
  VALID_LINK,
  VALID_TAGS
} from "../../IndividualFormFieldWithController/FormConstants";

export interface IEditFormFieldsValidationListLaunch {
  date: IFormFieldData;
  description: IFormFieldData;
  tags: IFormFieldData;
  state: IFormFieldData;
  status: IFormFieldData;
  disclosureLevel: IFormFieldData;
  trackerLink: IFormFieldData;
  reasonForChange: IFormFieldData;
  reasonForChangeRequired: IFormFieldData;
  reasonForChangeDescription: IFormFieldData;
  reasonForChangeDescriptionRequired: IFormFieldData;


  codename: IFormFieldData;
  name: IFormFieldData;
  iteration: IFormFieldData;
  type: IFormFieldData;
  scope: IFormFieldData;
  ripId: IFormFieldData;
  tier: IFormFieldData;
  audience: IFormFieldData;
  confidential: IFormFieldData;
  goalLink: IFormFieldData;
  goal: IFormFieldData;
  productManager: IFormFieldData;
  marketingLead: IFormFieldData;
  engineeringLead: IFormFieldData;
  otherContacts: IFormFieldData;
  pricingChangeInvolved: IFormFieldData;
  namingInvolved: IFormFieldData;
  prfaqRequired: IFormFieldData;
  regulated: IFormFieldData;
}

export const RemoveChoice = {
  "": "-",
}

export const editLaunchFormFields: IEditFormFieldsValidationListLaunch = {
  // Properties
  date: {
    placeholder: "Launch date",
    id: "date",
    validation: VALID_DATE_NOT_PAST_TWO_WEEKS_NOR_WEEKEND,
    helpId: "LAUNCH_CURRENT_DATE",
  },
  description: {
    placeholder: "Description",
    id: "description",
    validation: VALID_DESCRIPTION_REGEX,
    helpId: "LAUNCH_DESCRIPTION",
  },
  tags: {
    placeholder: "Tags",
    id: "tags",
    validation: VALID_TAGS,
  },
  state: {
    placeholder: "Launch state",
    id: "state",
    validation: VALID_CHAR_REGEX,
    options: enumToSelectOptions(LaunchState),
    helpId: "LAUNCH_STATE",
  },
  status: {
    placeholder: "Launch status",
    id: "status",
    validation: VALID_CHAR_REGEX,
    options: enumToSelectOptions(LaunchStatus),
    helpId: "LAUNCH_STATUS",
  },
  disclosureLevel: {
    placeholder: "Customer disclosure level",
    id: "disclosureLevel",
    validation: VALID_CHAR_REGEX,
    options: enumToSelectOptions({ ...RemoveChoice, ...LaunchDisclosure }),
    helpId: "LAUNCH_DISCLOSURE",
  },
  trackerLink: {
    placeholder: "Tracker link",
    id: "trackerLink",
    validation: VALID_LINK,
    helpId: "LAUNCH_TRACKER_LINK",
  },
  reasonForChange: {
    placeholder: "Reason For Change",
    id: "reasonForChange",
    validation: VALID_CHAR_REGEX,
    options: enumToSelectOptions({ ...ReasonForChange }),
  },
  reasonForChangeRequired: {
    placeholder: "Reason For Change",
    id: "reasonForChange",
    validation: REQUIRED_AND_VALID_CHAR_REGEX,
    options: enumToSelectOptions({ ...ReasonForChange })
  },
  reasonForChangeDescription: {
    placeholder: "Reason For Change Description",
    id: "reasonForChangeDescription",
    validation: VALID_DESCRIPTION_REGEX,
  },
  reasonForChangeDescriptionRequired: {
    placeholder: "Reason For Change Description",
    id: "reasonForChangeDescription",
    validation: REQUIRED_AND_VALID_DESCRIPTION_REGEX,
  },

  // General
  codename: {
    placeholder: "Codename",
    id: "codename",
    helpId: "LAUNCH_CODENAME",
  },
  name: {
    placeholder: "Launch name",
    id: "name",
    helpId: "LAUNCH_NAME",
  },
  iteration: {
    placeholder: "Launch iteration",
    id: "iteration",
    validation: VALID_CHAR_REGEX,
    options: enumToSelectOptions(LaunchIteration),
    helpId: "LAUNCH_ITERATION",
  },
  type: {
    placeholder: "Launch type",
    id: "type",
    validation: VALID_CHAR_REGEX,
    options: enumToSelectOptions(LaunchType),
    helpId: "LAUNCH_TYPE",
  },
  scope: {
    placeholder: "Launch scope",
    id: "scope",
    validation: VALID_CHAR_REGEX,
    options: enumToSelectOptions({ ...RemoveChoice, ...LaunchScope }),
    helpId: "LAUNCH_SCOPE",
  },
  ripId: {
    placeholder: "RIP name",
    id: "ripId",
    validation: VALID_CHAR_REGEX,
    helpId: "LAUNCH_RIP",
  },
  tier: {
    placeholder: "Marketing tier",
    id: "tier",
    validation: VALID_CHAR_REGEX,
    options: enumToSelectOptions({ ...RemoveChoice, ...LaunchTier }),
    helpId: "LAUNCH_TIER",
  },
  audience: {
    placeholder: "Audience",
    id: "audience",
    validation: VALID_CHAR_REGEX,
    options: enumToSelectOptions({ ...RemoveChoice, ...LaunchAudience }),
    helpId: "LAUNCH_AUDIENCE",
  },
  confidential: {
    placeholder: "Confidential",
    id: "confidential",
    helpId: "LAUNCH_CONFIDENTIAL",
  },
  goalLink: {
    placeholder: "Goal link",
    id: "goalLink",
    validation: VALID_LINK,
    helpId: "LAUNCH_GOAL",
  },
  goal: {
    placeholder: "Goal type",
    id: "goal",
    validation: VALID_CHAR_REGEX,
    options: enumToAutoSuggestOptions((() => { const { OTHER, ...enumWithoutOther } = { ...LaunchGoalType }; return enumWithoutOther})()),
    helpId: "LAUNCH_GOAL"
  },
  productManager: {
    placeholder: "Product Manager",
    id: "productManager",
    validation: VALID_ALIAS_CHAR_REGEX_AND_MAXLENGTH,
  },
  marketingLead: {
    placeholder: "Marketing Lead",
    id: "marketingLead",
    validation: VALID_ALIAS_CHAR_REGEX_AND_MAXLENGTH,
  },
  engineeringLead: {
    placeholder: "Engineering Lead",
    id: "engineeringLead",
    validation: VALID_ALIAS_CHAR_REGEX_AND_MAXLENGTH,
  },
  otherContacts: {
    placeholder: "Other Contacts",
    id: "otherContacts",
  },
  pricingChangeInvolved: {
    placeholder: "Pricing Involved",
    id: "pricingChangeInvolved",
    helpId: "LAUNCH_PRICING_INVOLVED",
  },
  namingInvolved: {
    placeholder: "Naming Involved",
    id: "namingInvolved",
    helpId: "LAUNCH_NAMING_INVOLVED",
  },
  prfaqRequired: {
    placeholder: "PRFAQ",
    id: "prfaqRequired",
    helpId: "LAUNCH_PRFAQ",
  },
  regulated: {
    placeholder: "Regulated Elements",
    id: "regulated",
    helpId: "LAUNCH_REGULATED",
  },
}
