import { toShortDate } from "../../utils/dateUtil";
import { ExportDataBook, ExportDataCell } from "@amzn/excelerator";
import { IServicePlan } from "../../models/types/IServicePlan";
import { getBlueprintDate, isRmsManaged } from "./SingleServiceNotCompletedTableUtil";
import { getRmsProjectedFinishDateFromServiceRegionItem, isCompletedDate } from "../../utils/rmsProjectedDateUtil";
import { Optional } from "../../models/types/Optional";
import { dataToDataBook, emptyExportValue } from "../ExportTableToCSVExcel/ExportTableToCSVExcel";
import {
  getConfidenceFromServicePlan,
  getLaunchDateFromServicePlan,
  getPlannedDateFromServicePlan,
  getStatusFromServicePlan
} from "../../utils/planUtil";

export const SingleServiceNotCompletedListCsvHeaders: string[] = [
  "Region",
  "Region Launch Date",
  "RIP Build Status",
  "Confidence",
  "Planned Launch Date",
  "Blueprint Projected GA Date",
];

export const SingleServiceNotCompletedNoDatesListCsvHeaders: string[] = [
  "Region",
  "RIP Build Status",
];

export const rmsDateHeader = "RMS Projected GA Date";

export const rmsDateIndex = 5;

export const completedDateValue: string = "Completed";

export function getRMSDateForExport(isRMSManagedService: boolean, rmsDate: Date | undefined): string {
  if (!rmsDate || !isRMSManagedService){
    return emptyExportValue
  }
  else if (isCompletedDate(rmsDate)){
    return completedDateValue
  }

  return toShortDate(rmsDate) as string;
}

export function getSingleServiceNotCompletedHeadersWithRMSDate(): string[]{
  let headers = [...SingleServiceNotCompletedListCsvHeaders]
  headers.splice(rmsDateIndex, 0, rmsDateHeader);
  return headers
}

export const generateSingleServiceNotCompletedWorkbook = (services: IServicePlan[], showRmsDate: Boolean): ExportDataBook => {

  let singleServiceNotCompletedListExcelData: ExportDataCell[][] = [showRmsDate ? getSingleServiceNotCompletedHeadersWithRMSDate(): SingleServiceNotCompletedListCsvHeaders]

  services.forEach((singleServicePlan: IServicePlan) => {

    let rmsDate: Optional<Date> = getRmsProjectedFinishDateFromServiceRegionItem(singleServicePlan)

    let serviceInRegionRow: Optional<string>[] = [
      singleServicePlan.region,
      getLaunchDateFromServicePlan(singleServicePlan),
      getStatusFromServicePlan(singleServicePlan),
      getConfidenceFromServicePlan(singleServicePlan),
      getPlannedDateFromServicePlan(singleServicePlan),
      getBlueprintDate(singleServicePlan) ?? `No Date`,
    ]

    if (showRmsDate){
      // @ts-ignore that region is optional. All rows should have a region but I can't confirm this for all usages of
      // IServicePlan so I can't safely make it not optional in the interface itself
      let isRMSManagedService = isRmsManaged(singleServicePlan.region, singleServicePlan.regionObject.date, singleServicePlan.plan)
      serviceInRegionRow.splice(rmsDateIndex, 0,  getRMSDateForExport(isRMSManagedService, rmsDate))
    }

    singleServiceNotCompletedListExcelData.push(serviceInRegionRow);
  })

  return dataToDataBook(singleServiceNotCompletedListExcelData);
}

export const generateSingleServiceNotCompletedNoDatesWorkbook = (services: IServicePlan[]): ExportDataBook => {
  let singleServiceNotCompletedListExcelData: ExportDataCell[][] = [SingleServiceNotCompletedNoDatesListCsvHeaders]

  services.forEach((singleServicePlan: IServicePlan) => {
    singleServiceNotCompletedListExcelData.push([
      singleServicePlan.region,
      getStatusFromServicePlan(singleServicePlan),
    ]);
  })

  return dataToDataBook(singleServiceNotCompletedListExcelData);
}
