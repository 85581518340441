import * as React from "react";
import { useCallback, useEffect, useState } from "react";
import useSWR from "swr";
import { useHistory, useParams } from "react-router-dom";
import { useLocation } from "react-router";
import { useSelector } from "react-redux";
import { AwsServiceVO } from "@amzn/api-parity-react-component";
import { Alert, Link } from "@amzn/awsui-components-react-v3";
import { useProduct } from "../../utils/productUtil";
import { getAppState } from "../../models/ApplicationState";
import { NoAccessError } from "../../components/common/NoAccessError";
import { LaunchWizard } from "../../components/ProductLaunches/LaunchWizard/LaunchWizard";
import { RegionDao } from "../../daos/RegionDao";
import { ProductVO } from "../../models/vos/ProductVO";
import { useProductContext } from "../../contexts/ProductContext";
import { ILocationState } from "../../models/LocationState";
import { Loading } from "../../components/common/Loading";
import { REPORT_A_BUG_URL } from "../../constants";
import { RegionMetadataVO } from "../../models/vos/RegionMetadataVO";
import { parseRegionMetadataDto, parseRipRegionMetadataDto } from "../../parsers/regionMetadataParser";
import { IProductLaunchesContainerParam } from "../ProductLaunchesContainer/ProductLaunchesContainer";
import { ILaunchDto } from "../../daos/types/ILaunchDto";
import { LaunchDao } from "../../daos/LaunchDao";
import { AxiosResponse } from "axios";
import { EditLaunchErrorAlerts } from "../../components/LaunchDetails/EditLaunch/EditLaunchErrorAlerts/EditLaunchErrorAlerts.helpers";
import { EventDateDao } from "../../daos/EventDateDao";
import { EventDateVO } from "../../models/vos/EventDateVO";
import { serviceListSelector, serviceLoadStateSelector } from "../../redux/selectors/serviceSelector";
import { LoadStateEnum } from "../../models/LoadStateEnum";
import { eventDateDtosToVos } from "../../parsers/eventDateParser";
import { useLoadInfinitePages } from "../../utils/swrUtil";
import { getSwrConfig } from "../../daos/swrUtil";

export const LaunchWizardContainer = () => {
  const userAbilities = getAppState().userAbilities;
  const [loading, setLoading] = useState(false);
  const [updateResponse, setUpdateResponse] = useState({ status: 200, text: {} });
  const { productId } = useParams<IProductLaunchesContainerParam>();
  const location = useLocation<ILocationState>();
  const { product, setProduct } = useProductContext();
  const { data: productData, error: productError } = useProduct(productId);
  const allServices: AwsServiceVO[] = useSelector(serviceListSelector);
  const serviceLoadState: LoadStateEnum = useSelector(serviceLoadStateSelector);

  const {
    data: regions = [],
    error: regionsError
  } = useSWR<RegionMetadataVO[]>(() => `launchRegions`, async () => {
    const dao = new RegionDao();
    const dto = (await dao.getRipRegions());

    return dto.regions.items.map(value => parseRipRegionMetadataDto(value));
  })

  const { data: dates, error: datesError } = useLoadInfinitePages<EventDateVO>(
    `eventDates`,
    async (request, token) => {
      const dao = new EventDateDao();
      const dto = await dao.getAllEventDates(200, token);
      return { items: eventDateDtosToVos(dto.items[0]), nextToken: dto.NextToken }
    },
    { ...getSwrConfig() }
  );

  const error = productError || regionsError || datesError || (serviceLoadState === LoadStateEnum.Error && userAbilities.canRead)
  const data = productData && regions && (serviceLoadState === LoadStateEnum.Loaded || !userAbilities.canRead)

  useEffect(() => {
    if (product.id !== productId) {
      const newProduct = productData
        ?? (location.state?.product?.id === productId ? location.state?.product : null)
        ?? new ProductVO();

      setProduct(newProduct);
    }
  }, [location.state?.product, setProduct, productData, productId, product.id]);

  const history = useHistory();

  const onSubmit = useCallback(async (launch: Partial<ILaunchDto>): Promise<ILaunchDto> => {
    setLoading(true)
    const dao = new LaunchDao();
    const response: AxiosResponse = await dao.createLaunch(launch);
    setUpdateResponse({ status: response.status, text: response.data });
    setLoading(false)
    if (response.status === 200 || response.status === 201) {
      history.push(`/alm/launches/${response.data.id}`);
    }
    else {
      window.scrollTo(0, 0)
    }
    return response.data;
  }, [history]);

  if (!data && !error) {
    return <Loading/>
  }

  // TODO (jsoumya): Fix condition once API for write permission exists
  if (true) {
    if (error || !data) {
      return (
        <Alert
          type="error"
          header="Data Fetching Error"
        >
          {"There was an error fetching product details, please try again or submit a "}
          <Link
            external
            externalIconAriaLabel="Opens in a new tab"
            href={REPORT_A_BUG_URL}
          >
            ticket
          </Link>.
        </Alert>
      )
    }
    return (
      <>
        {
          EditLaunchErrorAlerts[updateResponse.status] && EditLaunchErrorAlerts[updateResponse.status](updateResponse.text)
        }
        <LaunchWizard
          blockedDates={dates ?? []}
          product={product}
          services={allServices}
          regions={regions}
          onFormSubmit={onSubmit}
          isLoading={loading}
        />
      </>
    )

  }
  return <NoAccessError/>
}
