import * as React from "react";
import { FunctionComponent, useEffect } from "react";
import { Controller } from "react-hook-form";
import styled from "styled-components";
import { Box, Checkbox, Container, Grid, Header, SpaceBetween, Toggle } from "@amzn/awsui-components-react-v3";
import { RegionMetadataVO } from "../../../../models/vos/RegionMetadataVO";
import { RipStatusEnum } from "../../../../views/SingleService/ReconFeatureTableCSVHelpers";
import { getDataTestIdProp } from "../../../../utils/testUtil";
import { ILaunchWizardRegionsInput } from "../LaunchWizard.types";

const RegionsGrid = styled("div")`
  column-count: 3;
  padding-left: 23px;
`;

const GridElement = styled("div")`
  -webkit-column-break-inside: avoid;
  page-break-inside: avoid;
  break-inside: avoid;
  padding-bottom: 8px;
`;

const AlignExpectedLaunchDate = styled("div")`
  padding-left: 23px;
`;

export const RegionPickerTestID = {
  "selectAllGaRegions" : "-selectAllGaRegions",
  "selectAllUpcomingRegions": "-selectAllUpcomingRegions",
  "showGaRegions" : "-showGaRegions",
  "showUpcomingRegions" : "showUpcomingRegions",
  "expectedLaunchDate" : "expectedLaunchDate"
}

export const PARTITION_TO_LONG_NAME = {
  "aws": "AWS Commercial Partition",
  "aws-us-gov": "GovCloud Partition",
  "aws-cn": "China Partition",
  "aws-iso": "Top Secret Partition",
  "aws-iso-b": "Secret Partition",
  "aws-iso-e": "Top Secret Partition",
  "aws-iso-f": "Top Secret Partition",
}

export const RegionPicker: FunctionComponent<ILaunchWizardRegionsInput> = (props) => {
  const { control, selectedRegions } = props;
  const { getValues, setValue } = control
  const [displayGaRegions, setDisplayGaRegions] = React.useState(true);
  const [displayUpcomingRegions, setDisplayUpcomingRegions] = React.useState(true);
  const [selectAllGaRegions, setSelectAllGaRegions] = React.useState(false);
  const [selectAllUpcomingRegions, setSelectAllUpcomingRegions] = React.useState(false);

  useEffect(() => {
    if (selectedRegions) {
      selectedRegions.forEach((airportCode) => {
        const regionFormId = Object.keys(getValues()).find((entry) => entry.startsWith("region:") && entry.split(":")[2] === airportCode)
        if (regionFormId) {
          setValue(regionFormId, true)
        }
      })
    }
  }, [])

  const gaRegionsByPartition = props.regions.reduce((groups, item) => {
    if (item.status === RipStatusEnum.GA) {
      const group = (groups[item.partition] || []);
      group.push(item);
      groups[item.partition] = group;
    }
    return groups;
  }, {});

  const upcomingRegionsByPartition = props.regions.reduce((groups, item) => {
    if (item.status !== RipStatusEnum.GA) {
      const group = (groups[item.partition] || []);
      group.push(item);
      groups[item.partition] = group;
    }
    return groups;
  }, {});


  function getPartitionName(partitionCode: string, onlyGA: boolean) {
    return "partition:" + partitionCode + (onlyGA ? ":GA" : ":Upcoming")
  }

  function getRegionName(partitionCode: string, airportCode: string, onlyGA: boolean) {
    return "region:" + partitionCode + ":" + airportCode + (onlyGA ? ":GA" : ":Upcoming")
  }

  // If all regions are selected in a partition, set the partition checkbox to true
  function checkPartitionCheckbox(onlyGA: boolean, partitionCode: string) {
    if (onlyGA) {
      if (gaRegionsByPartition[partitionCode].every(region => getValues(getRegionName(partitionCode, region.airportCode, onlyGA)) === true)) {
        setValue(getPartitionName(partitionCode, onlyGA), true)
      }
    } else {
      if (upcomingRegionsByPartition[partitionCode].every(region => getValues(getRegionName(partitionCode, region.airportCode, onlyGA)) === true)) {
        setValue(getPartitionName(partitionCode, onlyGA), true)
      }
    }
  }

  // If all partitions are selected, set select all regions checkbox to true
  function checkSelectAllCheckbox(onlyGA: boolean) {
    if (onlyGA) {
      if (Object.keys(gaRegionsByPartition).every((partitionCode) => getValues(getPartitionName(partitionCode, onlyGA)) === true)) {
        setSelectAllGaRegions(true)
      }
    } else {
      if (Object.keys(upcomingRegionsByPartition).every((partitionCode) => getValues(getPartitionName(partitionCode, onlyGA)) === true)) {
        setSelectAllUpcomingRegions(true)
      }
    }
  }

  function handleSelectAll(onlyGA: boolean, value: boolean, onChange, partitionCode?: string) {
    onChange(value)

    if (partitionCode) {
      if (!value) {
        setValue(getPartitionName(partitionCode, onlyGA), false)
        onlyGA ? setSelectAllGaRegions(false) : setSelectAllUpcomingRegions(false)
      } else {
        checkSelectAllCheckbox(onlyGA)
      }

      Object.keys(getValues()).map((entry) => {
        if (entry.startsWith("region:" + partitionCode + ":") && entry.endsWith(onlyGA ? ":GA" : ":Upcoming")) {
          setValue(entry, value)
        }
      })
    } else {
      Object.keys(getValues()).map((entry) => {
        if (entry.endsWith(onlyGA ? ":GA" : ":Upcoming")) {
          setValue(entry, value)
        }
      })
    }
  }

  function handleRegionSelection(onlyGA: boolean, value: boolean, onChange, partitionCode: string) {
    onChange(value)

    // If partition checkbox is true but one region in partition is false,
    // set partition and select all checkbox to false
    if (!value) {
      setValue(getPartitionName(partitionCode, onlyGA), false)
      onlyGA ? setSelectAllGaRegions(false) : setSelectAllUpcomingRegions(false)
    } else {
      checkPartitionCheckbox(onlyGA, partitionCode)
      checkSelectAllCheckbox(onlyGA)
    }

  }

  function regionPartitionCheckbox(partitionCode: string, regions: RegionMetadataVO[], onlyGA: boolean = false) {
    const isNotEmpty: boolean = regions.some((region) => {
      return (region.status === RipStatusEnum.GA && onlyGA) || (region.status !== RipStatusEnum.GA && !onlyGA)
    })

    if (isNotEmpty) {
      return (
        <Box>
          <Controller
            name={getPartitionName(partitionCode, onlyGA)}
            id={getPartitionName(partitionCode, onlyGA)}
            control={control}
            defaultValue={false}
            render={({ onChange, value }) => {
              return <Checkbox
                {...getDataTestIdProp(props, (onlyGA ? "-ga-" : "-upcoming-") + partitionCode)}
                checked={value}
                onChange={(e) => {
                  handleSelectAll(onlyGA, e.detail.checked, onChange, partitionCode)
                }}>
                <b>{(PARTITION_TO_LONG_NAME[partitionCode] ?? "")}</b> {` (${partitionCode})`}
              </Checkbox>
            }}
          />
          <RegionsGrid>
            {
              regions.map((region) => {
                const parsedDescription = region.description.match(/\(([^)]+)\)/) ? region.description.match(/\(([^)]+)\)/)![1] : region.description
                if ((onlyGA && region.status === RipStatusEnum.GA) || (!onlyGA && region.status !== RipStatusEnum.GA)) {
                  return (
                    <Controller
                      name={getRegionName(partitionCode, region.airportCode, onlyGA)}
                      id={getRegionName(partitionCode, region.airportCode, onlyGA)}
                      control={props.control}
                      defaultValue={false}
                      render={({ onChange, value, onBlur }, { invalid }) => {
                        return <>
                          <GridElement>
                            <Checkbox
                              {...getDataTestIdProp(props, "-" + region.airportCode)}
                              checked={value}
                              onChange={(e) => handleRegionSelection(onlyGA, e.detail.checked, onChange, partitionCode)}>
                              { region.airportCode + " " }
                              { <small>{parsedDescription + " (" + region.nameLong + ")"}</small> }
                            </Checkbox>
                            {
                              !onlyGA &&
                              <AlignExpectedLaunchDate {...getDataTestIdProp(props, RegionPickerTestID.expectedLaunchDate)}>
                                <small>{"Expected Launch: " + new Date(region.date?.toString() ?? "Invalid Date").toDateString()}</small>
                              </AlignExpectedLaunchDate>
                            }
                          </GridElement>
                        </>
                      }}
                    />
                  )
                }
              })
            }
          </RegionsGrid>
        </Box>
      )
    }
  }

  return (
    <SpaceBetween size="xl">
      <Grid gridDefinition={[{ colspan: 6 }, { colspan: 6 }]}>
        <Container>
          <Checkbox
            {...getDataTestIdProp(props, RegionPickerTestID.showGaRegions)}
            onChange={({ detail }) =>
              setDisplayGaRegions(detail.checked)
            }
            checked={displayGaRegions}
          >
            Existing (GA) AWS Regions
          </Checkbox>
        </Container>
        <Container>
          <Checkbox
            {...getDataTestIdProp(props, RegionPickerTestID.showUpcomingRegions)}
            onChange={({ detail }) =>
              setDisplayUpcomingRegions(detail.checked)
            }
            checked={displayUpcomingRegions}
          >
            Upcoming AWS Regions
          </Checkbox>
        </Container>
      </Grid>

      {
        displayGaRegions &&
        <Container header={<Header variant="h2">Existing (GA) AWS Regions</Header>}>
          <SpaceBetween size="l">
            <Toggle
              {...getDataTestIdProp(props, RegionPickerTestID.selectAllGaRegions)}
              onChange={({ detail }) => {
                handleSelectAll(true, detail.checked, setSelectAllGaRegions)
              }
              }
              checked={selectAllGaRegions}
            >
              Select All Available Regions
            </Toggle>
            {
              Object.keys(gaRegionsByPartition).sort().map((regionPartition) => {
                return regionPartitionCheckbox(regionPartition, gaRegionsByPartition[regionPartition], true)
              })
            }
          </SpaceBetween>
        </Container>
      }
      {
        displayUpcomingRegions &&
        <Container header={<Header variant="h2">Upcoming AWS Regions</Header>}>
          <SpaceBetween size="l">
            <Toggle
              {...getDataTestIdProp(props, RegionPickerTestID.selectAllUpcomingRegions)}
              onChange={({ detail }) => {
                handleSelectAll(false, detail.checked, setSelectAllUpcomingRegions)
              }
              }
              checked={selectAllUpcomingRegions}
            >
              Select All Upcoming Regions
            </Toggle>
            {
              Object.keys(upcomingRegionsByPartition).sort().map((regionPartition) => {
                return regionPartitionCheckbox(regionPartition, upcomingRegionsByPartition[regionPartition], false)
              })
            }
          </SpaceBetween>
        </Container>
      }
    </SpaceBetween>
  );
}
