import { Optional } from "../models/types/Optional";
import { RMS_URL_BASE } from "../constants";
import { normalizeServiceName } from "../daos/daoUtil";
import urljoin from "url-join";
import { getConfig } from "./environmentUtil";
import { BASE_URLS, InternalAppNames, Stage } from "@amzn/region-services-react-library-v4";

export const UNIFIED_UX_PATH = "/insights";

export function isUnifiedUx(): boolean {
  return window.location.pathname.startsWith(UNIFIED_UX_PATH);
}

export function getRmsServiceDetailUrl(pathName: string): Optional<string> {
  // Return undefined url for unified ux so the relevant button will be hidden
  // this is because View in RMS is deprecated under unified url
  return isUnifiedUx() ? undefined : `${RMS_URL_BASE}${pathName}`;
}

export function getCreateFeatureUrl(serviceId: string): Optional<string> {
  // Return undefined url because there isn't one for creating feature
  // but once unified ux has a url for creating features, fill the undefined with the url
  return isUnifiedUx() ? undefined : `${RMS_URL_BASE}/services/${encodeURIComponent(serviceId)}/features/new`;
}


export function getFeatureDetailUrl(serviceId: string, featureId: string): string {
  const normalizedRipName = normalizeServiceName(serviceId);
  const normalizedRipFeatureName = normalizeServiceName(featureId);

  const unifiedStage: Stage = getConfig()?.unifiedStage ?? Stage.ALPHA;
  const baseUrl: string = BASE_URLS[InternalAppNames.RIP][unifiedStage];
  return urljoin(baseUrl, "services", "features", "details", normalizedRipName, normalizedRipFeatureName);
}

export function getFeatureStatusUrl(serviceId: string, featureId: string): string {
  const normalizedRipName = normalizeServiceName(serviceId);
  const normalizedRipFeatureName = normalizeServiceName(featureId);

  const unifiedStage: Stage = getConfig()?.unifiedStage ?? Stage.ALPHA;
  const baseUrl: string = BASE_URLS[InternalAppNames.RIP][unifiedStage];
  return urljoin(baseUrl, "services", "features", "statuses", normalizedRipName, normalizedRipFeatureName);
}


export function getServiceDetailUrl(serviceId: string): string {
  const normalizedRipName = normalizeServiceName(serviceId);

  const unifiedStage: Stage = getConfig()?.unifiedStage ?? Stage.ALPHA;
  const baseUrl: string = BASE_URLS[InternalAppNames.RIP][unifiedStage];
  return urljoin(baseUrl, "services", "details", normalizedRipName);
}
