import { Icon } from "@amzn/awsui-components-react-v3";
import * as React from "react";

/**
 * Define visualization of feature parity for non-date related status
 */
export interface IFeatureParityDisplay {
  plain: string;
  visual: JSX.Element;
}

export const inParityFeatureParityDisplay: IFeatureParityDisplay = {
  plain: "GA",
  visual: <Icon variant="success" name="status-positive" />,
};

export const inProgressFeatureParityDisplay: IFeatureParityDisplay = {
  plain: "In-Progress",
  visual: <Icon variant="link" name="status-in-progress" />
};

export const notAvailableParityFeatureParityDisplay: IFeatureParityDisplay = {
  plain: "Not Available",
  visual: <Icon variant="error" name="status-negative" />
};

export const atoParityFeatureParityDisplay: IFeatureParityDisplay = {
  plain: "ATO",
  visual: <span>ATO</span>
};

export const notLaunchingParityFeatureParityDisplay: IFeatureParityDisplay = {
  plain: "NL",
  visual: <span>NL</span>
};

export const plannedWithNoDateParityFeatureParityDisplay: IFeatureParityDisplay = {
  plain: "Planned",
  visual: <span>Pl</span>
};
