import * as React from "react";
import { FunctionComponent, useCallback, useState } from "react";
import { useParams } from "react-router-dom";
import { getAppState } from "../../../models/ApplicationState";
import { NoAccessError } from "../../../components/common/NoAccessError";
import { Container, SegmentedControl, SegmentedControlProps } from "@amzn/awsui-components-react-v3";
import { ENABLE_RESOURCE_PARITY } from "../../../constants";
import { NonCancelableCustomEvent } from "@amzn/awsui-components-react-v3/polaris/internal/events";
import { PageContainer } from "./SingleServiceParityContainer.style";
import { SingleServiceApiParity } from "../SingleServiceApiParity/SingleServiceApiParity";
import { SingleServiceResourceParityContainer } from "../SingleServiceResourceParity/SingleServiceResourceParityContainer";
import { SingleServiceFeatureParityContainer } from "../SingleServiceFeatureParity/SingleServiceFeatureParityContainer";
import { useSelector } from "react-redux";
import { serviceLoadStateSelector } from "../../../redux/selectors/serviceSelector";
import { LoadStateEnum } from "../../../models/LoadStateEnum";
import { LoadingIndicator } from "../../../components/LoadingIndicator/LoadingIndicator";
import { SingleServiceHeaderContainer } from "../SingleServiceHeader/SingleServiceHeaderContainer";
import { ISingleServiceContainerPathParam } from "../SingleServiceContainer.types";
import { fixFormatServiceId } from "../../../utils/urlUtil";

export const SingleServiceParityContainerTestId = {
  chooser: "SingleServiceParityContainer-chooser",
  header:  "SingleServiceParityContainer-header",
  content: {
    featureParity: "SingleServiceParityContainer-content-featureParity",
    apiParity: "SingleServiceParityContainer-content-apiParity",
    resourceParity: "SingleServiceParityContainer-content-resourceParity",
  }
}

export const ContentKey = {
  feature: "feature",
  api: "api",
  resource: "resource",
}

const unifiedUxContentHeight = "calc(100vh - 29rem)";

export interface IAccessibleSingleServiceParityContainerProps {
  serviceId: string;
  showHeader?: boolean;
  contentHeight?: string;
}

// This view is only for unified ux
export const SingleServiceParityContainer: FunctionComponent<{}> = () => {
  const { serviceName } = useParams<ISingleServiceContainerPathParam>();
  const canAccess = !!serviceName && getAppState().userAbilities.canReadDates;
  return canAccess ?
    <AccessibleSingleServiceParityContainer
      contentHeight={unifiedUxContentHeight}
      serviceId={serviceName}
      showHeader={true}  />
    : <NoAccessError />;
}

export const AccessibleSingleServiceParityContainer: FunctionComponent<IAccessibleSingleServiceParityContainerProps> = (props): JSX.Element => {
  const { serviceId, showHeader } = props;
  const [selected, setSelected] = useState<string>(ContentKey.feature);
  const serviceLoadState: LoadStateEnum = useSelector(serviceLoadStateSelector);

  const chooser_onChange = useCallback((evt: NonCancelableCustomEvent<SegmentedControlProps.ChangeDetail>) => {
    setSelected(evt.detail.selectedId);
  }, [
    setSelected,
  ]);

  const parities: SegmentedControlProps.Option[] = [
    {
      id: ContentKey.feature,
      text: "Feature Parity"
    },
    {
      id: ContentKey.api,
      text: "API Parity"
    },
  ];

  if (ENABLE_RESOURCE_PARITY) {
    parities.push({
      id: ContentKey.resource,
      text: "Resource Parity"
    });
  }

  if (serviceLoadState === LoadStateEnum.Loading) {
    return <LoadingIndicator />
  }

  return (
    <PageContainer>
      {
        (showHeader) &&
        <SingleServiceHeaderContainer
          data-testid={SingleServiceParityContainerTestId.header}
          serviceId={fixFormatServiceId(serviceId)} />
      }

      <Container
        disableContentPaddings={true}
        header={<SegmentedControl
          data-testid={SingleServiceParityContainerTestId.chooser}
          selectedId={selected}
          options={parities}
          onChange={chooser_onChange}/>}
      >
        {
          (selected === ContentKey.feature) &&
          <div data-testid={SingleServiceParityContainerTestId.content.featureParity}>
            <SingleServiceFeatureParityContainer serviceId={props.serviceId} />
          </div>

        }

        {
          (selected === ContentKey.api) &&
          <div data-testid={SingleServiceParityContainerTestId.content.apiParity}>
            <SingleServiceApiParity constraintHeight={props.contentHeight} serviceId={props.serviceId} />
          </div>
        }

        {
          (selected === ContentKey.resource) &&
          <div data-testid={SingleServiceParityContainerTestId.content.resourceParity}>
            <SingleServiceResourceParityContainer constraintHeight={props.contentHeight} serviceId={props.serviceId} />
          </div>
        }
      </Container>
    </PageContainer>
  );
}
