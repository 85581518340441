import React, { useState } from "react";
import _ from "lodash";
import TabsNoPadding from "../common/StyledComponents/TabsNoPadding";
import SingleRegionNotCompletedTable from "./SingleRegionNotCompletedTable";
import SingleRegionCompletedTable from "./SingleRegionCompletedTable";
import { getStatus, getConfidence, getEstimatedDate } from "../../utils/singleRegionUtils"
import { Service, SingleRegionTabsProps, SortingDetails, TabSwitchDetails } from "./SingleRegionTabs.types";
import { Tabs } from "@amzn/awsui-components-react";
import { GENERALLY_AVAILABLE } from "../../constants";

export function addRegionInfoToItems(items: Service[]): void {
  items.map(service => {
    service.status = getStatus(service) ?? "-"
    service.confidence = getConfidence(service) ?? "-"
    service.date = getEstimatedDate(service) ?? "-"
    return service
  })
}

export function partitionCompleteItems(items: Service[]): Service[][] {
  return _.partition(items, item => {
    try {
      const regionKey = Object.keys((item?.belongsToInstance || item?.region) ?? {})[0];
      if (regionKey) {
        const region = item?.region ?? {};
        const regionMetadata = region[regionKey] ?? {};
        const status = regionMetadata?.status ?? "";

        return status === GENERALLY_AVAILABLE;
      }
    } catch (err) {
      console.error("SingleRegionTabs partition error:", err);
      console.error("SingleRegionTabs partition error item:", item);
      return false;
    }
  });
}

const SingleRegionTabs = ({ regionName, loading = true, items = [], userAbilities }: SingleRegionTabsProps) => {
  addRegionInfoToItems(items);
  const [complete, notCompleted] = partitionCompleteItems(items);
  const totalNumItems = (items === undefined) ? 0 : items.length;
  const commonProps = { regionName, totalNumItems, loading };
  const [activeTab, setActiveTab] = useState("notCompleted");

  const [notCompletedSortingState, setNotCompletedSortingState] = useState({
    sortingColumn: "date",
    sortingDescending: false,
  });

  const [completedSortingState, setCompletedSortingState] = useState({
    sortingColumn: "date",
    sortingDescending: true,
  });

  const handleNotCompletedSortingChange = ({
    detail: { sortingColumn, sortingDescending },
  }: SortingDetails): void => {
    setNotCompletedSortingState({ sortingColumn, sortingDescending });
  };

  const handleCompletedSortingChange = ({
    detail: { sortingColumn, sortingDescending },
  }: SortingDetails): void => {
    setCompletedSortingState({ sortingColumn, sortingDescending });
  };

  const handleChange = ({ detail: { activeTabId } }: TabSwitchDetails): void => {
    setActiveTab(activeTabId);
  };

  const tabs: Tabs.Tab[] = [
    {
      label: "Not Completed",
      id: "notCompleted",
      content: (
        <SingleRegionNotCompletedTable
          items={notCompleted}
          sortingState={notCompletedSortingState}
          handleSortingChange={handleNotCompletedSortingChange}
          userAbilities={userAbilities}
          {...commonProps}
        />
      ),
    },
    {
      label: "Completed",
      id: "completed",
      content: (
        <SingleRegionCompletedTable
          items={complete}
          sortingState={completedSortingState}
          handleSortingChange={handleCompletedSortingChange}
          {...commonProps}
        />
      ),
    },
  ];

  return (
    <TabsNoPadding
      tabs={tabs}
      variant="container"
      activeTabId={activeTab}
      onChange={handleChange}
    />
  );
};

export default SingleRegionTabs;
