import * as React from "react";
import { UserProp } from "./User.types";
import { UserLink } from "@amzn/api-parity-react-component/lib/components/user/UserLink";

export const User: React.FunctionComponent<UserProp> = (props) => {
  return (
    <UserLink userId={props.alias} withAvatar={true}>{props.alias}</UserLink>
  )
};

export default User;
