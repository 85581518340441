import { ParseErrorReason } from "@amzn/api-parity-react-component/lib/models/ParseResult";
import { FeatureCollection, FeatureParityCollection } from "../models/collections";
import {
  AwsServiceAvailabilityLevelEnum,
  AwsServiceCollection,
  AwsServiceCollectionView,
  AwsServiceVO, reconStatusToEnumLookup,
  RegionCollection,
  RegionCollectionView,
  RegionVO,
  ServiceBuildPlanCollection, ServiceBuildPlanVO
} from "@amzn/api-parity-react-component";
import {
  IFeatureParityRaw,
  ITaggedFeaturePlan,
  RipFeatureInstance,
  RipFeatureMetadata
} from "../daos/types/FeaturesDaoTypes";
import { FeatureParityVO, FeatureVO } from "../models/vos";
import { Optional } from "../models/types/Optional";
import { IServiceComponentDto } from "../daos/types/IGetServiceComponentsResponse";
import { FeatureInRegionStatusEnum, ripStatusToFeatureInRegionStatusEnumMap } from "../models/FeatureInRegionStatus";
import { computeFeatureParityId } from "../utils/modelUtil";
import { ParityStatusEnum } from "@amzn/api-parity-react-component/lib/models/ParityStatus";
import { Visibility } from "../daos/types/Visibility";
import { fixFormatServiceId } from "../utils/urlUtil";
import { computeUniqueFeatureId } from "../utils/featureUtil";
import { fromInstanceText } from "./instanceParser";
import { ServiceBuildPlanStatusEnum } from "@amzn/api-parity-react-component/lib/models/vos/ServiceBuildPlanVO";
import { computeServiceParityId } from "@amzn/api-parity-react-component/lib/utils/modelUtil";

export enum FeatureParityParseErrorReason {
  MissingFeature = "MissingFeature",
  MissingRegion = "MissingRegion",
  MissingService = "MissingService",
  MissingTaggedService = "MissingTaggedService",
  MissingTaggedServiceInPlan = "MissingTaggedServiceInPlan",
  UnknownStatus = "UnknownStatus",
}

export class FeatureParityParseError extends ParseErrorReason<string, FeatureParityParseErrorReason>{}

export class FeatureParityParseResult {
  features: FeatureCollection = new FeatureCollection();
  featureParities: FeatureParityCollection = new FeatureParityCollection();
  serviceParities: ServiceBuildPlanCollection = new ServiceBuildPlanCollection();
  errors: FeatureParityParseError[] = [];
}

/**
 * Look up data such as service regions etc during parsing
 */
export class IFeatureParityParseLookup {
  services: AwsServiceCollection | AwsServiceCollectionView = new AwsServiceCollection();
  regions: RegionCollection | RegionCollectionView = new RegionCollection();
  serviceParities: ServiceBuildPlanCollection = new ServiceBuildPlanCollection();
}

export class FeatureParityParser {
  private _result: FeatureParityParseResult = new FeatureParityParseResult();
  readonly lookup: IFeatureParityParseLookup;

  constructor(lookup: IFeatureParityParseLookup) {
    this.lookup = lookup;
  }

  reset(): void {
    this._result = new FeatureParityParseResult();
    this._result.serviceParities = this.lookup.serviceParities;
  }

  parse(rawData: IFeatureParityRaw): void {
    this.reset();
    this.parseFeatures(rawData);
    this.parsePlans(rawData);
  }

  parseFeatures(rawData: IFeatureParityRaw): void {
    this.result.features.pauseChangeEvent();
    rawData.features.metadata.forEach((featureMetaData) => {
      const feature: Optional<FeatureVO> = this.parseBonaFideFeature(featureMetaData);
      if (feature) {
        this.result.features.add(feature);
      }
    });

    rawData.taggedFeatures.forEach((tagged) => {
      const feature: Optional<FeatureVO> = this.parseTaggedFeature(tagged);
      if (feature) {
        this.result.features.add(feature);
      }
    });
    this.result.features.resumeChangeEvent();
  }

  parsePlans(rawData: IFeatureParityRaw): void {
    this.result.featureParities.pauseChangeEvent();
    rawData.features.instances.forEach((featureInstance) => {
      const parity: Optional<FeatureParityVO> = this.parseBonaFideFeaturePlan(featureInstance);
      if (parity) {
        this.result.featureParities.add(parity);
      }
    });
    this.result.featureParities.resumeChangeEvent();

    rawData.taggedFeaturePlans.forEach((plan) => {
      const additionalServiceParities: Optional<ServiceBuildPlanVO[]> = this.parseTaggedFeaturePlan(plan);
      if (additionalServiceParities && additionalServiceParities.length > 0) {
        this.result.serviceParities.addRange(additionalServiceParities);
      }
    });

  }

  parseBonaFideFeature(metaData: RipFeatureMetadata): Optional<FeatureVO> {
    const serviceId = metaData.belongsToService;
    const service: Optional<AwsServiceVO> = this.lookup.services.getById(serviceId) ?? this.lookup.services.getById(fixFormatServiceId(serviceId));
    if (!service) {
      const errorIdentifier = `Feature: ${metaData.ripId}'s service: ${metaData.belongsToService}`;
      this.appendError(errorIdentifier, FeatureParityParseErrorReason.MissingService);
      return;
    }

    let availabilityLevels: Set<AwsServiceAvailabilityLevelEnum> = new Set<AwsServiceAvailabilityLevelEnum>();
    if (metaData.availabilityLevel) {
      metaData.availabilityLevel?.forEach((level) => {
        availabilityLevels.add(level);
      });
    } else {
      // if availability level is not sent in the dto or absent
      // use the parent service's availability level
      availabilityLevels = new Set<AwsServiceAvailabilityLevelEnum>(service.availabilityLevels);
    }
    return new FeatureVO({
      id: computeUniqueFeatureId(service.id, metaData.ripId),
      name: metaData.longName,
      service,
      visibility: metaData.visibility as Visibility,
      ripId: metaData.ripId,
      availabilityLevels,
    });
  }

  parseBonaFideFeaturePlan(plan: RipFeatureInstance): Optional<FeatureParityVO> {
    const regionId: string = plan.belongsToDimensionName;
    const serviceId: string = plan.parentId;
    const featureId: string = computeUniqueFeatureId(serviceId, plan.ripId);

    const region: Optional<RegionVO> = this.lookup.regions.getById(regionId);
    const feature: Optional<FeatureVO> = this.result.features.getById(featureId) ?? this.result.features.getById(fixFormatServiceId(featureId));

    if (!region) {
      const errorIdentifier = `Region: ${regionId} is not found for feature plan (ripId: ${featureId})`;
      this.appendError(errorIdentifier, FeatureParityParseErrorReason.MissingRegion);
      return;
    }

    if (!feature) {
      const errorIdentifier = `Feature: ${featureId} is not found for feature plan (regionId: ${regionId})`;
      this.appendError(errorIdentifier, FeatureParityParseErrorReason.MissingFeature);
      return;
    }

    const status: Optional<FeatureInRegionStatusEnum> = ripStatusToFeatureInRegionStatusEnumMap.get(plan.status);
    if (!status) {
      const errorIdentifier = `Feature: ${featureId} in ${regionId} has an unsupported status "${plan.status}"`;
      this.appendError(errorIdentifier, FeatureParityParseErrorReason.UnknownStatus);
      return;
    }

    const vo = new FeatureParityVO({
      id: computeFeatureParityId(feature, region),
      feature,
      region,
      status,
      plannedDate: (plan.estimatedLaunchDate) ? new Date(plan.estimatedLaunchDate) : undefined,
    });

    if (status === FeatureInRegionStatusEnum.GA) {
      vo.parity = ParityStatusEnum.Available;
    }

    return vo;
  }

  parseTaggedFeature(tagged: IServiceComponentDto): Optional<FeatureVO> {
    const componentServiceId: string = fixFormatServiceId(tagged.ripComponentServiceName ?? tagged.ripTopLevelServiceName);
    const parentId: string = tagged.ripServiceName;
    const componentService: Optional<AwsServiceVO> = this.lookup.services.getById(componentServiceId);
    const parentService: Optional<AwsServiceVO> = this.lookup.services.getById(parentId);
    if (!componentService) {
      const errorIdentifier = `Tagged Feature: ${componentServiceId} does not exists`;
      this.appendError(errorIdentifier, FeatureParityParseErrorReason.MissingTaggedService);
      return;
    }
    if (!parentService) {
      const errorIdentifier = `Tagged Feature's parent service: ${parentId} does not exists`;
      this.appendError(errorIdentifier, FeatureParityParseErrorReason.MissingService);
      return;
    }
    return new FeatureVO({
      id: tagged.instance,
      name: componentService.name,
      service: parentService,
      markedAsFrom: componentService,
      ripId: componentServiceId,
      visibility: componentService.visibility
    });
  }

  parseTaggedFeaturePlan(plan: ITaggedFeaturePlan): Optional<ServiceBuildPlanVO[]> {
    const serviceId: string = fromInstanceText(plan.service.instance).names[0];
    const service: Optional<AwsServiceVO> = this.lookup.services.getById(serviceId);

    if (!service) {
      const errorIdentifier = `Tagged feature plan: ${serviceId} does not exists`;
      this.appendError(errorIdentifier, FeatureParityParseErrorReason.MissingTaggedServiceInPlan);
      return;
    }

    const result: Optional<ServiceBuildPlanVO[]> = [];

    for (const planDto of plan.plans) {
      const regionId: string = planDto.belongsToInstance;
      const region: Optional<RegionVO> = this.lookup.regions.getById(regionId);
      if (!region) {
        const errorIdentifier = `Tagged feature plan: ${regionId} does not exists`;
        this.appendError(errorIdentifier, FeatureParityParseErrorReason.MissingRegion);
        continue;
      }

      if (this.lookup.serviceParities.byServiceRegion(service, region)) {
        continue;
      }

      const status: Optional<ServiceBuildPlanStatusEnum> = reconStatusToEnumLookup[planDto.status];
      if (!status) {
        const errorIdentifier = `Tagged Feature service: ${serviceId} in ${regionId} has an unsupported status "${planDto.status}"`;
        this.appendError(errorIdentifier, FeatureParityParseErrorReason.UnknownStatus);
        continue;
      }

      const vo: ServiceBuildPlanVO = new ServiceBuildPlanVO({
        id: computeServiceParityId(service, region),
        service,
        region,
        status,
      });

      result.push(vo);
    }
    return result;
  }

  appendError(description: string, reason: FeatureParityParseErrorReason): void {
    const err = new FeatureParityParseError({
      item: description,
      reason,
    });
    this.result.errors.push(err);
  }

  get result(): FeatureParityParseResult {
    return this._result;
  }
}
