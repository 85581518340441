import { ColumnLayout, ExpandableSection, Header, Link, SpaceBetween } from "@amzn/awsui-components-react-v3";
import * as React from "react";
import { displayOr } from "../../../../utils/displayUtil";
import { LaunchGeneralPropertiesProps } from "./LaunchGeneralProperties.types";
import { FunctionComponent } from "react";
import { getDataTestIdProp } from "../../../../utils/testUtil";
import User from "../../../common/User";
import { LoadingIndicator } from "../../../LoadingIndicator/LoadingIndicator";
import { LoadingContainer } from "../../../common/LoadingContainer/LoadingContainer";
import { ProductLink } from "../../../ProductsList/ProductLink/ProductLink";
import { formatLaunchDate } from "../../launchDetailsUtils";
import {
  LaunchAudience,
  LaunchGoalType,
  LaunchIteration,
  LaunchScope,
  LaunchTier,
  LaunchType
} from "../../../ProductLaunches/LaunchWizard/LaunchWizard.types";
import { ReactRouterButton } from "../../../common/RouterComponents/ReactRouterButton";
import { LaunchPropertiesTestIdSuffixes } from "../../LaunchProperties/LaunchProperties";
import { ValueWithLabel } from "../../../common/ValueWithLabel/ValueWithLabel";

export const LaunchGeneralPropertiesTestIdSuffixes = {
  type: "-type",
  tier: "-tier",
  scope: "-scope",
  audience: "-audience",
  slips: "-slips",
  disclosure: "-disclosure",
  confidential: "-confidential",
  goalLink: "-goalLink",
  id: "-id",
  blueprintUid: "-blueprintUid",
  product: "-product",
  ripNameLink: "-ripNameLink",
}

export const LaunchGeneralProperties: FunctionComponent<LaunchGeneralPropertiesProps> = (props) => {

  const { launch, product, loading, loadingProduct, canEdit } = props;

  function getRipNameLink(ripId: string) {
    return (
      <Link
        {...getDataTestIdProp(props, LaunchGeneralPropertiesTestIdSuffixes.ripNameLink)}
        external
        href={`https://regions.aws.dev/services/statuses/${encodeURI(ripId)}`}
      >
        {ripId}
      </Link>
    )
  }

  /**
   * renderContacts
   * @description returns a <ColumnLayout> with 3 contact columns
   * @return JSX.Element
   */
  const renderContacts = () => {
    /**
     * buildColumn
     * @description returns an array of LaunchLabelWithValues containing contacts for a specific column
     * @param column which column to build
     * @param increment how many columns total
     * @return JSX.Element[]
     */
    const buildColumn = (column: number, increment: number) => {
      const elements: JSX.Element[] = [];

      if (launch.contacts && launch.contacts.other) {
        for (let i = column; i < (launch.contacts.other.length || 0); i += increment) {
          const contact = launch.contacts.other[i];
          elements.push(
            <ValueWithLabel label={contact.contactType} key={i}>
              <User alias={contact.alias}/>
            </ValueWithLabel>
          );
        }
      }

      return elements;
    }

    return (
      <ColumnLayout columns={3} variant="text-grid">
        <SpaceBetween size="l">
          <ValueWithLabel label="Product Manager">
            {displayOr(
              launch.contacts?.productManager,
              () => <User alias={launch.contacts?.productManager!}/>
            )}
          </ValueWithLabel>
          {buildColumn(0, 3)}
        </SpaceBetween>
        <SpaceBetween size="l">
          <ValueWithLabel label="Marketing Lead">
            {displayOr(
              launch.contacts?.marketingLead,
              () => <User alias={launch.contacts?.marketingLead!}/>
            )}
          </ValueWithLabel>
          {buildColumn(1, 3)}
        </SpaceBetween>
        <SpaceBetween size="l">
          <ValueWithLabel label="Engineering Lead">
            {displayOr(
              launch.contacts?.engineeringLead,
              () => <User alias={launch.contacts?.engineeringLead!}/>
            )}
          </ValueWithLabel>
          {buildColumn(2, 3)}
        </SpaceBetween>
      </ColumnLayout>
    );
  }

  /**
   * renderQuestionnaire
   * @description returns a <ColumnLayout> with 4 questionnaire columns
   * @return JSX.Element
   */
  const renderQuestionnaire = () => {
    return (
      <ColumnLayout columns={4} variant="text-grid">
        <SpaceBetween size="l">
          <ValueWithLabel
            label="Regulated Elements"
            helpId="LAUNCH_REGULATED"
          >
            {displayOr(
              launch.questionnaire.regulated,
              () => "Yes",
              "No"
            )}
          </ValueWithLabel>
        </SpaceBetween>
        <SpaceBetween size="l">
          <ValueWithLabel
            label="Pricing Involved"
            helpId="LAUNCH_PRICING_INVOLVED"
          >
            {displayOr(
              launch.questionnaire.pricingChangeInvolved,
              () => "Yes",
              "No"
            )}
          </ValueWithLabel>
        </SpaceBetween>
        <SpaceBetween size="l">
          <ValueWithLabel
            label="Naming Involved"
            helpId="LAUNCH_NAMING_INVOLVED"
          >
            {displayOr(
              launch.questionnaire.namingInvolved,
              () => "Yes",
              "No"
            )}
          </ValueWithLabel>
        </SpaceBetween>
        <SpaceBetween size="l">
          <ValueWithLabel
            label="PRFAQ"
            helpId="LAUNCH_PRFAQ"
          >
            {displayOr(
              launch.questionnaire.prfaqRequired,
              () => "Yes",
              "No"
            )}
          </ValueWithLabel>
        </SpaceBetween>
      </ColumnLayout>
    );
  }

  const renderIdInfo = () => {
    return (
      <ColumnLayout columns={2} variant="text-grid">
        <ValueWithLabel
          label="ALM Launch ID"
          {...getDataTestIdProp(props, LaunchGeneralPropertiesTestIdSuffixes.id)}
        >
          {displayOr(launch.id)}
        </ValueWithLabel>
        <ValueWithLabel
          label="Blueprint UID"
          {...getDataTestIdProp(props, LaunchGeneralPropertiesTestIdSuffixes.blueprintUid)}
        >
          {displayOr(launch.blueprintUid)}
        </ValueWithLabel>
      </ColumnLayout>
    );
  }

  return (
    <SpaceBetween size="l">
      <LoadingContainer
        header={
          <Header variant="h2" actions={
            <ReactRouterButton
              {...getDataTestIdProp(props, LaunchPropertiesTestIdSuffixes.editButton)}
              disabled={!canEdit}
              to={`/alm/launches/${props.launch.id}/edit`}>Edit
            </ReactRouterButton>}>
            General info
          </Header>}
        loading={loading}
      >
        <ColumnLayout columns={3} variant="text-grid">
          <SpaceBetween size="l">
            <ValueWithLabel
              label="Codename"
              helpId="LAUNCH_CODENAME"
            >
              {displayOr(launch.codename)}
            </ValueWithLabel>
            <ValueWithLabel
              label="Launch iteration"
              helpId="LAUNCH_ITERATION"
            >
              {displayOr(
                launch.iteration,
                () => LaunchIteration[launch.iteration!] ?? launch.iteration
              )}
            </ValueWithLabel>
            <ValueWithLabel
              label="Launch type"
              helpId="LAUNCH_TYPE"
              {...getDataTestIdProp(props, LaunchGeneralPropertiesTestIdSuffixes.type)}
            >
              {displayOr(
                launch.type,
                () => LaunchType[launch.type!] ?? launch.type
              )}
            </ValueWithLabel>
            <ValueWithLabel
              label="Goal type"
              helpId="LAUNCH_GOAL"
            >
              {displayOr(
                launch.goal,
                () => LaunchGoalType[launch.goal!] ?? launch.goal
              )}
            </ValueWithLabel>
          </SpaceBetween>
          <SpaceBetween size="l">
            <ValueWithLabel
              label="Launch name"
              helpId="LAUNCH_NAME"
            >
              {displayOr(launch.name)}
            </ValueWithLabel>
            <ValueWithLabel
              label="Launch scope"
              helpId="LAUNCH_SCOPE"
              {...getDataTestIdProp(props, LaunchGeneralPropertiesTestIdSuffixes.scope)}
            >
              {displayOr(
                launch.scope,
                () => LaunchScope[launch.scope!] ?? launch.scope
              )}
            </ValueWithLabel>
            <ValueWithLabel
              label="Audience"
              helpId="LAUNCH_AUDIENCE"
              {...getDataTestIdProp(props, LaunchGeneralPropertiesTestIdSuffixes.audience)}
            >
              {displayOr(
                launch.audience,
                () => LaunchAudience[launch.audience!] ?? launch.audience
              )}
            </ValueWithLabel>
            <ValueWithLabel
              label="Goal link"
              helpId="LAUNCH_GOAL"
              {...getDataTestIdProp(props, LaunchGeneralPropertiesTestIdSuffixes.goalLink)}
            >
              {displayOr(
                launch.goalLink,
                () => <Link external href={launch.goalLink}>
                  {launch.goalLink}
                </Link>
              )}
            </ValueWithLabel>
          </SpaceBetween>
          <SpaceBetween size="l">
            <ValueWithLabel
              label="Product"
              {...getDataTestIdProp(props, LaunchGeneralPropertiesTestIdSuffixes.product)}
            >
              {loadingProduct
                ? <LoadingIndicator/>
                : displayOr(
                  product?.name,
                  () => <ProductLink displayName={product?.name!} id={product?.id!}/>,
                )
              }
            </ValueWithLabel>
            <ValueWithLabel
              label="Marketing tier"
              helpId="LAUNCH_TIER"
              
              {...getDataTestIdProp(props, LaunchGeneralPropertiesTestIdSuffixes.tier)}
            >
              {displayOr(
                launch.tier,
                () => {
                  return LaunchTier[launch.tier!] ?? launch.tier
                }
              )}
            </ValueWithLabel>
            <ValueWithLabel
              label="RIP name"
              helpId="LAUNCH_RIP"
            >
               {displayOr(launch.ripId, () => getRipNameLink(launch.ripId ?? ""))}
             
            </ValueWithLabel>

            <ValueWithLabel
              label="Confidential"
              helpId="LAUNCH_CONFIDENTIAL"
              {...getDataTestIdProp(props, LaunchGeneralPropertiesTestIdSuffixes.confidential)}    
            >
             {displayOr(launch.confidential, () => (
                launch.confidential ? "Yes" : "No"
              ), "No")}
            </ValueWithLabel>
          </SpaceBetween>
        </ColumnLayout>
      </LoadingContainer>
      <LoadingContainer header={<Header variant="h2" actions={
        <ReactRouterButton
          {...getDataTestIdProp(props, LaunchPropertiesTestIdSuffixes.editButton)}
          disabled={!canEdit}
          to={`/alm/launches/${props.launch.id}/edit`}>Edit
        </ReactRouterButton>}>Contacts</Header>} loading={loading}>
        {renderContacts()}
      </LoadingContainer>
      <LoadingContainer header={<Header variant="h2" actions={
        <ReactRouterButton
          {...getDataTestIdProp(props, LaunchPropertiesTestIdSuffixes.editButton)}
          disabled={!canEdit}
          to={`/alm/launches/${props.launch.id}/edit`}>Edit
        </ReactRouterButton>}>Questionnaire</Header>} loading={loading}>
        {renderQuestionnaire()}
      </LoadingContainer>
      <LoadingContainer header={<Header variant="h2">Dates</Header>} loading={loading}>
        <ColumnLayout columns={2} variant="text-grid">
          <SpaceBetween size="l">
            <ValueWithLabel
              label="Original launch date"
              helpId="LAUNCH_ORIGINAL_DATE"
            >
              {displayOr(launch.originalLaunchDate, () => formatLaunchDate(launch.originalLaunchDate!))}
            </ValueWithLabel>
            <ValueWithLabel
              label="Previous launch date"
              helpId="LAUNCH_PREVIOUS_DATE"
            >
              {displayOr(launch.previousLaunchDate, () => formatLaunchDate(launch.previousLaunchDate!))}
            </ValueWithLabel>
          </SpaceBetween>
          <SpaceBetween size="l">
            <ValueWithLabel
              label="Launch slips"
              helpId="LAUNCH_SLIPS"
              {...getDataTestIdProp(props, LaunchGeneralPropertiesTestIdSuffixes.slips)}
            >
              {displayOr(launch.slips)}
            </ValueWithLabel>
            <ValueWithLabel
              label="Days late"
              helpId="LAUNCH_DAYS_LATE"
            >
              {displayOr(launch.daysLate)}
            </ValueWithLabel>
          </SpaceBetween>
        </ColumnLayout>
      </LoadingContainer>

      <LoadingContainer
        header={
          <Header variant="h2">
            ID info
          </Header>
        }
        loading={loading}
      >
        {renderIdInfo()}
      </LoadingContainer>
    </SpaceBetween>
  );
};
