import * as React from "react";
import styled from "styled-components";
import * as awsui from "@amzn/awsui-design-tokens/polaris";
import { getImpersonation, isImpersonating } from "../../utils/environmentUtil";
import { EnvironmentDecoratorProp } from "./EnvironmentDecorator.types";
import { IEnvironmentConfig } from "../../configs/environmentConfig";

interface IRibbonProp {
  ribbonColor: string;
}

const Ribbon = styled.div<IRibbonProp>`
  position: fixed;
  z-index: 100001;
  left: auto;
  top: 9px;
  right: -18px;
  width: 75px;
  background: ${ (props) => props.ribbonColor };
  color: #fff;
  text-align: center;
  font-size: 0.9rem;
  line-height: 2rem;
  text-transform: uppercase;
  transform: rotate(45deg);
`;

export const EnvironmentDecorator: React.FunctionComponent<EnvironmentDecoratorProp> = (props) => {
  const config: IEnvironmentConfig = props.config;

  if (!config.ribbon) {
    return (<React.Fragment />);
  }

  let label: string;
  let color: string;

  if (isImpersonating()) {
    label = `= ${getImpersonation()}`;
    color = awsui.colorTextHeadingSecondary;
  } else {
    label = config.label;
    color = config.theme;
  }

  return (
    <Ribbon ribbonColor={color}>{label}</Ribbon>
  );
}
