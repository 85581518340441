import React, { FunctionComponent } from "react";
import { QaPlayerProps } from "./QaPlayer.types";
import useSWR from "swr";
import { IQaRunItem } from "../models/QaRunItem";
import { run } from "../qaItemRunner";
import { getSwrConfig } from "../../daos/swrUtil";
import { Loading } from "../../components/common/Loading";
import styled, { css } from "styled-components";
import { QaItemResultType } from "../models/QaItemResult";
import { Container, Header, StatusIndicator } from "@amzn/awsui-components-react-v3";
import { CurrentUser } from "../CurrentUser/CurrentUser";

const Content = styled("div")`
  min-height: 15rem;
  transition: all 0.3s ease-out;
`;

const Table = styled("div")`
  display: grid;
  grid-template-columns: 45rem auto;
`;

const cell = css`
  background: #ffffff;
  border-bottom: #cccccc 1px dotted;
  padding: 0.5rem 1rem;
`;

const DescriptionCell = styled("div")`
  ${cell};
`
const ResultCell = styled("div")`
  ${cell};
`

export const QaPlayer: FunctionComponent<QaPlayerProps> = (props) => {
  const swr = useSWR<IQaRunItem[]>(props.title, () => {
    return run(props.items);
  }, getSwrConfig());

  let content: JSX.Element;

  if (swr.isValidating) {
    content = <Loading />
  } else if (swr.data) {
    content = (
      <Table>
        {
          swr.data.map((item, index) => (
              <React.Fragment key={index}>
                <DescriptionCell>{item.item.description}</DescriptionCell>
                <ResultCell>
                  {
                    (item.result.type === QaItemResultType.Success) ?
                      (<StatusIndicator type={"success"}>Success</StatusIndicator>)
                      : (
                        <StatusIndicator type={"error"}><span role="error">{item.result.error}</span></StatusIndicator>
                      )
                  }
                </ResultCell>
              </React.Fragment>
            )
          )
        }
      </Table>
    )
  } else {
    content = <StatusIndicator type={"error"}><span role="error">Runner error: { JSON.stringify(swr.error) }</span></StatusIndicator>
  }

  return (
    <Container
      header={<Header description={<CurrentUser />}>
        {props.title}
      </Header>}>
      <Content>{content}</Content>
    </Container>
  );
};
