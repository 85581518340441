import { AwsServiceVO } from "@amzn/api-parity-react-component";
import { hydrateModel } from "../../utils/modelUtil";

export class ServiceListVM {
  category: string = "None";
  visibility: string = "";
  nameSortable?: string;
  nameRip?: string;
  nameLong?: string;

  constructor(from?: Partial<ServiceListVM>) {
    hydrateModel(from, this);
  }
}

export interface IServicesListTableInput {
  loadAllServices: boolean;
  items: AwsServiceVO[];
  loading?: boolean;
}

export interface IServicesListTableEvent {
  onToggle?: (includeNonCategorizeServices: boolean) => void;
}

export type ServicesListTableProps = IServicesListTableInput & IServicesListTableEvent;
