import { createSelector, Selector } from "reselect";
import { RootState } from "../reducers/reducers";
import { IServiceState } from "../reducers/remote/serviceReducer";
import { LoadStateEnum } from "../../models/LoadStateEnum";
import { AwsServiceVO } from "@amzn/api-parity-react-component";

const serviceSelector: Selector<RootState, IServiceState> = ( store ) => store.remote.service;

  export const serviceLoadStateSelector: Selector<RootState, LoadStateEnum> = createSelector(
  serviceSelector,
  ( state ) => state.loadState
);


export const serviceListSelector: Selector<RootState, AwsServiceVO[]> = createSelector(
  serviceSelector,
  ( state ) => {
    return Object.values(state.map) as AwsServiceVO[];
  }
);
