import * as React from "react";
import { QuickSightDashboard } from "../../components/QuickSightDashboard/QuickSightDashboard"
import useBreadcrumbs from "../../components/Breadcrumbs/useBreadcrumbs";
import { getComponentOrNoAccess } from "../../components/common/NoAccessError";
import { getAppState } from "../../models/ApplicationState";
import { Link } from "@amzn/awsui-components-react-v3";
import { getQueryParameter } from "../../utils/urlUtil";


const DashboardInterface = (dashboardId, featureRequestLink) => {
  return <QuickSightDashboard
    dashboardId={dashboardId}
    links= {<Link href={featureRequestLink} external={true}> Submit a feature request</Link>}
    params={getQueryParameter()}
  />
}

export const serviceTeamFeatureRequestLink = "https://tiny.amazon.com/63iy2w8l/ServiceTeamFeatureRequest";

export const ServiceManagerDashboard = () => {
  useBreadcrumbs(["Service Manager Dashboard"]);
  const serviceManagerDashboardSupplier = () => DashboardInterface("servicemanager", "https://tiny.amazon.com/12fu4dd0n/ServiceManagerFeatureRequest")

  return getComponentOrNoAccess(getAppState().userAbilities.canViewDashboards, serviceManagerDashboardSupplier)
};


export const LaunchManagerDashboard = () => {
  useBreadcrumbs(["Build Manager Dashboard"]);
  const launchManagerDashboardSupplier = () => DashboardInterface("launchmanager", "https://tiny.amazon.com/s7hb6ryw/BuildManagerFeatureRequest")

  return getComponentOrNoAccess(getAppState().userAbilities.canViewDashboards, launchManagerDashboardSupplier)
};


export const ServiceTeamDashboard = () => {
  useBreadcrumbs(["Service Team Dashboard"]);
  const serviceTeamDashboardSupplier = () => DashboardInterface("serviceteam", serviceTeamFeatureRequestLink)

  return getComponentOrNoAccess(getAppState().userAbilities.canViewDashboards, serviceTeamDashboardSupplier)
};


export const LeadershipDashboard = () => {
  useBreadcrumbs(["Leadership Dashboard"]);
  const leadershipDashboardSupplier = () => DashboardInterface("leadership", "https://tiny.amazon.com/1i65run6e/LeadershipFeatureRequest")

  return getComponentOrNoAccess(getAppState().userAbilities.canViewDashboards, leadershipDashboardSupplier)
};

export const LaunchManagerBetaDashboard = () => {
  useBreadcrumbs(["Build Manager Dashboard [BETA]"]);
  const launchManagerBetaDashboardSupplier = () => DashboardInterface("launchmanagerbeta", "https://tiny.amazon.com/s7hb6ryw/BuildManagerFeatureRequest")

  return getComponentOrNoAccess(getAppState().userAbilities.canViewDashboards, launchManagerBetaDashboardSupplier)
};

export const ServiceManagerBetaDashboard = () => {
  useBreadcrumbs(["Service Manager Dashboard [BETA]"]);
  const serviceManagerBetaDashboardSupplier = () => DashboardInterface("servicemanagerbeta", "https://tiny.amazon.com/12fu4dd0n/ServiceManagerFeatureRequest")

  return getComponentOrNoAccess(getAppState().userAbilities.canViewDashboards, serviceManagerBetaDashboardSupplier)
};

export const ServiceTeamBetaDashboard = () => {
  useBreadcrumbs(["Service Team Dashboard [BETA]"]);
  const serviceTeamBetaDashboardSupplier = () => DashboardInterface("serviceteambeta", "https://tiny.amazon.com/63iy2w8l/ServiceTeamFeatureRequest")

  return getComponentOrNoAccess(getAppState().userAbilities.canViewDashboards, serviceTeamBetaDashboardSupplier)
};

export const LeadershipBetaDashboard = () => {
  useBreadcrumbs(["Leadership Dashboard [BETA]"]);
  const leadershipBetaDashboardSupplier = () => DashboardInterface("leadershipbeta", "https://tiny.amazon.com/1i65run6e/LeadershipFeatureRequest")

  return getComponentOrNoAccess(getAppState().userAbilities.canViewDashboards, leadershipBetaDashboardSupplier)
};

export const MailerStatsBetaDashboard = () => {
  useBreadcrumbs(["Mailer Stats Dashboard [BETA]"]);
  const mailerStatsBetaDashboardSupplier = () => DashboardInterface("mailerstatsbeta", "https://tiny.amazon.com/19t1q50qb/MailerStatsFeatureRequest")

  return getComponentOrNoAccess(getAppState().userAbilities.canViewDashboards, mailerStatsBetaDashboardSupplier)
};

export const MailerStatsDashboard = () => {
  useBreadcrumbs(["Mailer Stats Dashboard"]);
  const mailerStatsDashboardSupplier = () => DashboardInterface("mailerstats", "https://tiny.amazon.com/19t1q50qb/MailerStatsFeatureRequest")

  return getComponentOrNoAccess(getAppState().userAbilities.canViewDashboards, mailerStatsDashboardSupplier)
};

export const RegionReadinessScoreDashboard = () => {
  useBreadcrumbs(["Region Readiness Score Dashboard"]);
  const regionReadinessScoreDashboardSupplier = () => DashboardInterface("regionreadinessscore", "https://tiny.amazon.com/11n6fdmf4/FeatureRequestRegionReadinessScore")

  return getComponentOrNoAccess(getAppState().userAbilities.canViewDashboards, regionReadinessScoreDashboardSupplier)
};

export const AutomationDashboard = () => {
  useBreadcrumbs(["Automation Dashboard"]);
  const automationDashboardSupplier = () => DashboardInterface("automationdashboard", "https://tiny.amazon.com/uf6t5slt/AutomationDashboard")

  return getComponentOrNoAccess(getAppState().userAbilities.canViewDashboards, automationDashboardSupplier)
};

export const DataQualityDashboard = () => {
  useBreadcrumbs(["Data Quality Dashboard"]);
  const dataQualityDashboardSupplier = () => DashboardInterface("dataqualitydashboard", "https://tiny.amazon.com/uf6t5slt/DataQualityDashboard")

  return getComponentOrNoAccess(getAppState().userAbilities.canViewDashboards, dataQualityDashboardSupplier)
};

export const RegionBuildHealthDashboard = () => {
  useBreadcrumbs(["Region Build Health Dashboard"]);
  const regionBuildHealthDashboardSupplier = () => DashboardInterface("regionbuildhealthdashboard", "https://tiny.amazon.com/yg83o87k/RegionBuildHealthDashboard")

  return getComponentOrNoAccess(getAppState().userAbilities.canViewDashboards, regionBuildHealthDashboardSupplier)
};

export const DependenciesAndSuccessorsDashboard = () => {
  useBreadcrumbs(["Dependencies and Successors Dashboard"]);
  const predecessorsAndSuccessorsDashboardSupplier = () => DashboardInterface("predecessorsandsuccessorsdashboard", "https://tiny.amazon.com/9bw07skl/DependenciesAndSuccessors")

  return getComponentOrNoAccess(getAppState().userAbilities.canViewDashboards, predecessorsAndSuccessorsDashboardSupplier)
};

export const UnblockedItemsDashboard = () => {
  useBreadcrumbs(["Unblocked Items Dashboard"]);
  const unblockedItemsDashboardSupplier = () => DashboardInterface("unblockeditemsdashboard", "https://tiny.amazon.com/yg83o87k/RegionBuildHealthDashboard")

  return getComponentOrNoAccess(getAppState().userAbilities.canViewDashboards, unblockedItemsDashboardSupplier)
};
