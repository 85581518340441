import { createSelector, Selector } from "reselect";
import { IResourceParityViewModel, ResourceParityProps } from "./ResourceParity.types";
import { IResourceParityBootstrapInput } from "@amzn/api-parity-react-component/lib/models/ResourceParityBootstrapInput";
import { bootstrapResourceParityData } from "@amzn/api-parity-react-component/lib/utils/resourceParityGridBootstrap";
import { AwsServiceVO } from "@amzn/api-parity-react-component/lib/models/vos/AwsServiceVO";
import { AwsServiceCollectionView } from "@amzn/api-parity-react-component/lib/models/collections/AwsServiceCollectionView";
import { AwsServiceCollection } from "@amzn/api-parity-react-component/lib/models/collections/AwsServiceCollection";
import { RegionVO } from "@amzn/api-parity-react-component/lib/models/vos/RegionVO";
import { isResourceParityRegion } from "../../../utils/resourceParityUtil";
import { RegionCollectionView } from "@amzn/api-parity-react-component/lib/models/collections/RegionCollectionView";
import { RegionCollection } from "@amzn/api-parity-react-component/lib/models/collections/RegionCollection";
import { ServiceBuildPlanVO } from "@amzn/api-parity-react-component/lib/models/vos/ServiceBuildPlanVO";
import { isApiParityExemptFromBuildPlan } from "@amzn/api-parity-react-component/lib/utils/serviceBuildPlanUtil";
import { ServiceBuildPlanCollectionView } from "@amzn/api-parity-react-component/lib/models/collections/ServiceBuildPlanCollectionView";
import { AwsResourceTypeCollectionView } from "@amzn/api-parity-react-component/lib/models/collections/AwsResourceTypeCollectionView";
import { AwsResourcePropertyParityCollectionView } from "@amzn/api-parity-react-component/lib/models/collections/AwsResourcePropertyParityCollectionView";
import { AwsResourceParityCollectionView } from "@amzn/api-parity-react-component/lib/models/collections/AwsResourceParityCollectionView";
import { AwsResourcePropertyTypeCollectionView } from "@amzn/api-parity-react-component/lib/models/collections/AwsResourcePropertyTypeCollectionView";

export type PropSelector<R> = Selector<ResourceParityProps, R>;


// region input selector
const dataInputSelector: PropSelector<IResourceParityBootstrapInput> = (props) => props.data;
// endregion

const parsedDataSelector = createSelector(
  dataInputSelector,
  (data) => bootstrapResourceParityData(data)
);

export const servicesSelector: PropSelector<AwsServiceCollectionView> = createSelector(
  parsedDataSelector,
  (parsed) => {
    const services: AwsServiceVO[] = parsed.serviceMap.items.map((map) => map.service);
    return new AwsServiceCollectionView(
      new AwsServiceCollection(services)
    );
  }
);

export const regionsSelector: PropSelector<RegionCollectionView> = createSelector(
  parsedDataSelector,
  (parsed) => {
    const regions: RegionVO[] = parsed.regions.items.filter(isResourceParityRegion);
    return new RegionCollectionView(
      new RegionCollection(regions)
    );
  }
);

export const nonGaBuildPlansSelector: PropSelector<ServiceBuildPlanCollectionView> = createSelector(
  parsedDataSelector,
  servicesSelector,
  regionsSelector,
  (
    parsed,
    services,
    regions,
  ) => {
    return new ServiceBuildPlanCollectionView(parsed.serviceBuildPlans,{
      filter: (plan: ServiceBuildPlanVO) =>
        services.hasId(plan.service.id)
        && regions.hasId(plan.region.id)
        && isApiParityExemptFromBuildPlan(plan),
    });
  }
);

export const resourcesSelector: PropSelector<AwsResourceTypeCollectionView> = createSelector(
  parsedDataSelector,
  (parsed) => {
    return new AwsResourceTypeCollectionView(parsed.resourceTypes)
  }
);

export const resourceParitiesSelector: PropSelector<AwsResourceParityCollectionView> = createSelector(
  parsedDataSelector,
  (parsed) => {
    return new AwsResourceParityCollectionView(parsed.resourceParities);
  }
);

export const propertyTypesSelector: PropSelector<AwsResourcePropertyTypeCollectionView> = createSelector(
  parsedDataSelector,
  (parsed) => {
    return new AwsResourcePropertyTypeCollectionView(parsed.propertyTypes);
  }
);

export const propertyParitiesSelector: PropSelector<AwsResourcePropertyParityCollectionView> = createSelector(
  parsedDataSelector,
  (parsed) => {
    return new AwsResourcePropertyParityCollectionView(parsed.propertyParities);
  }
);

export const viewModelSelector: PropSelector<IResourceParityViewModel> = createSelector(
  servicesSelector,
  regionsSelector,
  resourcesSelector,
  resourceParitiesSelector,
  propertyTypesSelector,
  propertyParitiesSelector,
  nonGaBuildPlansSelector,
  (
    services,
    regions,
    resources,
    resourceParities,
    propertyTypes,
    propertyParities,
    nonGaBuildPlans,
  ) => {
    return {
      services,
      regions,
      resources,
      resourceParities,
      propertyTypes,
      propertyParities,
      nonGaBuildPlans,
    };
  }
);
