import { getDateForFileName } from "../../utils/dateUtil";
import { LaunchVO } from "../../models/vos/LaunchVO";
import { ExportDataBook, ExportDataCell } from "@amzn/excelerator";
import { dataToDataBook, emptyExportValue } from "../ExportTableToCSVExcel/ExportTableToCSVExcel";
import { formulaEscape } from "../../utils/reportsUtils";

export const productLaunchesCsvHeaders = [
  "Name",
  "Id",
  "Codename",
  "RIP id",
  "Product id",
  "Iteration",
  "Type",
  "Tier",
  "Scope",
  "Date",
  "Status",
  "Audience",
  "Confidential",
  "State",
  "Slips",
  "Days late",
  "Disclosure level",
  "Regions",
  "Reasons For Change",
  "Description",
  "Tracker link type",
  "Tracker link",
  "Goal type",
  "Goal link",
  "Original launch date",
  "Previous launch date",
  "Naming involved",
  "PRFAQ required",
  "Pricing change involved",
  "Regulated",
  "Tags",
  "Product Manager",
  "Engineering Lead",
  "Marketing Lead",
  "Other contacts",
  "Documents & Reviews",
  "Read permissions",
  "Write permissions"
];

export const productLaunchesCsvFilename = (name: string) => `${name}-Launches-${getDateForFileName()}.csv`;

export const productLaunchesDataModel = (launches: LaunchVO[]): ExportDataCell[][] => {
  return launches.map((launch) => {
    return formulaEscape([
      launch.name,
      launch.id,
      launch.codename ?? emptyExportValue,
      launch.ripId ?? emptyExportValue,
      launch.productId ?? emptyExportValue,
      launch.iteration ?? emptyExportValue,
      launch.type ?? emptyExportValue,
      launch.tier ?? emptyExportValue,
      launch.scope ?? emptyExportValue,
      launch.date ?? emptyExportValue,
      launch.status ?? emptyExportValue,
      launch.audience ?? emptyExportValue,
      launch.confidential ?? emptyExportValue,
      launch.state ?? emptyExportValue,
      launch.slips ?? emptyExportValue,
      launch.daysLate ?? emptyExportValue,
      launch.disclosureLevel ?? emptyExportValue,
      launch.regions?.join(", ") ?? emptyExportValue,
      launch.reasonForChange ?? emptyExportValue,
      launch.description ?? emptyExportValue,
      launch.trackerLinkType ?? emptyExportValue,
      launch.trackerLink ?? emptyExportValue,
      launch.goal ?? emptyExportValue,
      launch.goalLink ?? emptyExportValue,
      launch.originalLaunchDate ?? emptyExportValue,
      launch.previousLaunchDate ?? emptyExportValue,
      launch.questionnaire.namingInvolved ?? emptyExportValue,
      launch.questionnaire.prfaqRequired ?? emptyExportValue,
      launch.questionnaire.pricingChangeInvolved ?? emptyExportValue,
      launch.questionnaire.regulated ?? emptyExportValue,
      launch.tags && launch.tags?.length !== 0 ? launch.tags?.join(", ") : emptyExportValue,
      launch.contacts?.productManager ?? emptyExportValue,
      launch.contacts?.engineeringLead ?? emptyExportValue,
      launch.contacts?.marketingLead ?? emptyExportValue,
      launch.contacts?.other && launch.contacts.other.length > 0
        ? launch.contacts.other.map((contact) => {
          return `${contact.contactType}: ${contact.alias}`;
        }).join(", ")
        : emptyExportValue,
      launch.docReviews && launch.docReviews.length > 0
        ? launch.docReviews.map((review) => {
          return JSON.stringify(review);
        }).join(", ")
        : emptyExportValue,
      launch.readGroups?.join(", ") ?? emptyExportValue,
      launch.writeGroups?.join(", ") ?? emptyExportValue,
    ]);
  });
}

export const generateProductLaunchesWorkbook = (launches: LaunchVO[]): ExportDataBook => {
  let productLaunchesExcelData: ExportDataCell[][] = [productLaunchesCsvHeaders];

  productLaunchesExcelData = productLaunchesExcelData.concat(productLaunchesDataModel(launches));

  return dataToDataBook(productLaunchesExcelData);
}
