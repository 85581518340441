import { ServiceContactOrchestrator, ServiceContactUpdateResult } from "../../orchestrators/ServiceContactOrchestrator";
import { IAddOrEditFormInput } from "../../components/ServiceContacts/EnterServiceContact/AddOrEditServiceContactModal/AddOrEditServiceContactModal.types";
import { Optional } from "../../models/types/Optional";
import { ServiceContactsVO } from "../../models/vos/ServiceContactsVO";

const orchestrator: ServiceContactOrchestrator = new ServiceContactOrchestrator();

export async function addOrUpdateServiceContact( update: IAddOrEditFormInput, contact: Optional<ServiceContactsVO> ): Promise<ServiceContactUpdateResult> {
  return (contact) ? orchestrator.update(contact, update) : orchestrator.add(update);
}
