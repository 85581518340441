import { hydrateModel } from "../../utils/modelUtil";
import { AwsServiceAvailabilityLevelEnum, AwsServiceVO } from "@amzn/api-parity-react-component/lib/models/vos/";
import { Visibility } from "../../daos/types/Visibility";

export class FeatureVO {
  id: string = "";
  name: string = "";
  service: AwsServiceVO = new AwsServiceVO();
  visibility?: Visibility;
  ripId: string = "";

  // If a service or component service is marked as feature
  // This would indicate the service marked as feature from
  markedAsFrom?: AwsServiceVO;
  availabilityLevels: Set<AwsServiceAvailabilityLevelEnum> = new Set<AwsServiceAvailabilityLevelEnum>();

  constructor(from?: Partial<FeatureVO>) {
    hydrateModel(from, this);
  }
}
