import * as React from "react";
import { isCompletedDate } from "../../utils/rmsProjectedDateUtil";
import { toShortDate } from "../../utils/dateUtil";
import { Optional } from "../../models/types/Optional";
import { normalizeServiceName } from "../../daos/daoUtil";
import { Link } from "@amzn/awsui-components-react-v3";

export class ProjectedDateViewModel {
  date: Optional<Date> = undefined;
  dateText: string = "-";
  link: Optional<string> = undefined;
}

export function getViewModel(projected: Optional<Date>,
                             ripName?: Optional<string>,
                             regionName?: Optional<string>): ProjectedDateViewModel {
  const result = new ProjectedDateViewModel();

  if (!projected) {
    return result;
  }

  result.date = projected;


  if (ripName && regionName) {
    result.link = `https://chainreaction.corp.amazon.com/services/${encodeURIComponent(normalizeServiceName(ripName))}/builds/${encodeURIComponent(regionName)}`;
  }

  result.dateText = isCompletedDate(projected) ? "Completed" : toShortDate(projected) as string;

  return result;
}


export function ProjectedDate({ projected, ripName, regionName }): JSX.Element {
  const viewModel = getViewModel(projected, ripName, regionName);

  if (viewModel.link) {
    return (
      <Link
        href={viewModel.link}
        external={true}>{viewModel.dateText}</Link>
    )
  } else {
    return (
      <span>{viewModel.dateText}</span>
    )
  }
}
