import { toShortDate } from "../../utils/dateUtil";
import { ExportDataBook, ExportDataCell, ExportDataSheet } from "@amzn/excelerator";
import { IRegionMetadata } from "../../daos/types/IRegionMetaData";

export const regionsListCsvHeaders: string[] = [
  "Airport Code",
  "External Name",
  "Region Name",
  "Partition",
  "RIP Build Status",
  "Region Launch Date",
];

export const generateRegionsListWorkbook = (regions: IRegionMetadata[]): ExportDataBook => {

  let regionsListExcelData: ExportDataCell[][] = [regionsListCsvHeaders]

  regions.forEach((region: IRegionMetadata) => {
    regionsListExcelData.push([
      region.nameSortable,
      region.nameLong,
      region.description,
      region.partition,
      region.status,
      toShortDate(region.date),
    ]);
  })
  const databook: ExportDataBook = new ExportDataBook();
  let datasheet: ExportDataSheet = new ExportDataSheet({ rows: regionsListExcelData })
  databook.sheets.push( datasheet );

  return databook;

}
