import * as React from "react";
import { useEffect, useState } from "react";
import { AwsServiceVO } from "@amzn/api-parity-react-component";
import { useSelector } from "react-redux";
import { serviceListSelector, serviceLoadStateSelector } from "../../../redux/selectors/serviceSelector";
import { LoadStateEnum } from "../../../models/LoadStateEnum";
import { LoadedServiceStyle, LoadingServiceStyle } from "./SingleServiceHeaderContainer.style";
import { SingleServiceHeaderTestIdSuffix } from "./SingleServiceHeaderTestId";
import { Optional } from "../../../models/types/Optional";
import { Header } from "@amzn/awsui-components-react-v3";
import { IHasDataTestId } from "../../../models/IHasDataTestId";
import { getDataTestIdProp } from "../../../utils/testUtil";
import { LoadingIndicator } from "../../../components/LoadingIndicator/LoadingIndicator";


export interface ISingleServiceHeaderContainerProps extends IHasDataTestId {
  serviceId: string;
}

export const LoadingServiceName: React.FunctionComponent<ISingleServiceHeaderContainerProps> = (props) => {
  return <LoadingServiceStyle {...getDataTestIdProp(props, SingleServiceHeaderTestIdSuffix.loading)}>&nbps;</LoadingServiceStyle>
};

export const LoadedServiceName: React.FunctionComponent<ISingleServiceHeaderContainerProps> = (props) => {
  return <LoadedServiceStyle {...getDataTestIdProp(props, SingleServiceHeaderTestIdSuffix.loadedText)}>{props.children}</LoadedServiceStyle>
};

export const ServiceNotFound: React.FunctionComponent<ISingleServiceHeaderContainerProps> = (props) => {
  return <LoadedServiceStyle {...getDataTestIdProp(props, SingleServiceHeaderTestIdSuffix.noMatch)}>{props.serviceId} not found</LoadedServiceStyle>
};


/**
 * Shared page header component which displays name of a service, this component
 * expects a correct service id
 * @param props
 * @constructor
 */
export const SingleServiceHeaderContainer: React.FunctionComponent<ISingleServiceHeaderContainerProps> = (props) => {
  const {
    serviceId
  } = props;

  const allServices: AwsServiceVO[] = useSelector(serviceListSelector);
  const serviceLoadState: LoadStateEnum = useSelector(serviceLoadStateSelector);
  const [serviceDisplay, setServiceDisplay] = useState<JSX.Element>(<LoadingServiceName serviceId={serviceId} />)

  useEffect(() => {
    let display: JSX.Element;

    if (serviceLoadState === LoadStateEnum.Loaded) {
      const selectedService: Optional<AwsServiceVO> = allServices.find((service) => service.id === serviceId);
      display = (selectedService)
        ? <LoadedServiceName {...props}>{selectedService.name}</LoadedServiceName>
        : <ServiceNotFound {...props} />;
    } else if (serviceLoadState === LoadStateEnum.Error) {
      display = <ServiceNotFound {...props} />;
    } else {
      display = <LoadingServiceName {...props} />;
    }
    setServiceDisplay(display);
  }, [
    allServices,
    serviceLoadState,
    serviceId,
    props,
  ]);

  return (
    <Header
      {...getDataTestIdProp(props)}
      info={(serviceLoadState === LoadStateEnum.Loading) && <LoadingIndicator />}
      variant="h1">{serviceDisplay}</Header>
  );
}
