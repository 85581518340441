import * as React from "react";
import { Box } from "@amzn/awsui-components-react-v3";
import { ButtonBar } from "../ButtonBar/ButtonBar";

/**
 * DialogFooterButtonBar simplifies modal dialog footer button by
 * offering prescribed right align layout and correct spacing between buttons
 *
 * @param props
 * @constructor
 */
export const DialogFooterButtonBar: React.FunctionComponent = (props) => {
  return (
    <Box float="right">
      <ButtonBar>{props.children}</ButtonBar>
    </Box>
  )
}
