import * as React from "react";
import { useState, useEffect, useCallback, useRef } from "react";
import styled from "styled-components";
import moment from "moment";
import HorizontalBar from "../common/StyledComponents/HorizontalBar";
import { HTTP_STATUS, RECON_URL_CREATE_ISSUE } from "../../constants";
import { SHORT_DATE_FORMAT, toShortDate } from "../../utils/dateUtil";
import { RemoveDateSimTemplateLink } from "../common/SimLinks/RemoveDateSimTemplateLink";
import {
  Select,
  Textarea,
  Flashbar,
  DatePicker,
  Form,
  ColumnLayout,
  Modal,
  Button,
  Checkbox,
  Link,
  FormField,
  SelectProps,
  SpaceBetween,
  CheckboxProps
} from "@amzn/awsui-components-react-v3";
import * as awsui from "@amzn/awsui-design-tokens"
import { EnterEstimatedDateModalProps } from "./EnterEstimatedDateModal.types";
import { IUpdateServicePlanEstimatedDate } from "../../models/types/IUpdateServicePlanEstimatedDate";
import { Nullable } from "../../models/types/Nullable";
import { PlanConfidence } from "../../models/types/PlanConfidence";
import { DatePickerProps } from "@amzn/awsui-components-react-v3/polaris/date-picker/interfaces";
import {
  NonCancelableCustomEvent,
} from "@amzn/awsui-components-react-v3/polaris/internal/events";
import { InputProps } from "@amzn/awsui-components-react-v3/polaris/input/interfaces";
import { DialogFooterButtonBar } from "../common/Dialog/DialogFooterButtonBar";
import { CompactHeader } from "../../styles/polaris";
import { AxiosError } from "axios";
import { setTracking } from "../../utils/analyticUtil";

export const EnterEstimatedDateTestId = {
  removeCheckbox: "EnterEstimatedDate-removeCheckbox",
  dateInput: "EnterEstimatedDate-dateInput",
  dateFormField: "EnterEstimatedDate-dateFormField",
  confidenceInput: "EnterEstimatedDate-confidenceInput",
  confidenceFormField: "EnterEstimatedDate-confidenceFormField",
  noteInput: "EnterEstimatedDate-noteInput",
  noteFormField: "EnterEstimatedDate-noteFormField",
  submit: "EnterEstimatedDate-submit",
  cancel: "EnterEstimatedDate-cancel",

  unknownError: "EnterEstimatedDate-unknownError",
  noPermissionError: "EnterEstimatedDate-noPermission",
  dayWarning: "EnterEstimatedDate-dayWarning",
  modal: "EnterEstimatedDate-modal"
}

const FormContent = styled("div")`
  padding: 1rem 0;
  border-bottom: ${awsui.colorBorderDividerDefault} 1x solid;
`;

export const EnterEstimatedDateModal: React.FC<EnterEstimatedDateModalProps> = (props) => {

  const {
    selected,
    region,
    nameSortable,
    nameRip,
    visible,
    todaysDate,
    handleDismiss,
    userAbilities
  } = props;

  const plan = selected;
  const curDeliveryDate = (plan?.date) ? moment(plan.date) : undefined;
  const defaultDate: string = toShortDate(curDeliveryDate ?? todaysDate) as string;
  const [error403, setError403] = useState(false);
  const [unknownError, setUnknownError] = useState(false);
  const [selectedOption, setSelectedOption] = useState<SelectProps.Option | null>(null);
  const [datePickerDate, setDatePickerDate] = useState<string>(defaultDate);
  const [textAreaValue, setTextAreaValue] = useState("");
  const [textValidation, setTextValidation] = useState<Nullable<string>>(null);
  const [dateValidation, setDateValidation] = useState("");
  const [formValidation, setFormValidation] = useState<Nullable<string>>(null);
  const [submitDisabled, setSubmitDisabled] = useState(true);
  const [selectValidation, setSelectValidation] = useState<Nullable<string>>(null);
  const [disableConfidenceAndDate, setDisableConfidenceAndDate] = useState(false);
  const [previousSelectValidation, setPreviousSelectValidation] = useState<Nullable<string>>("");
  const [previousDateValidation, setPreviousDateValidation] = useState("");

  const [removeConfidenceAndDate, removeConfidenceAndDateSet] = useState(false);
  const [loading, loadingSet] = useState<boolean>(false);

  const mounted = useRef(false);
  useEffect(() => {
    mounted.current = true; // Will set it to true on mount ...
    return () => { mounted.current = false; }; // ... and to false on unmount
  }, []);

  // If the modal is not visible, we set the date to the default date. Without this check, the user can't update the date.
  if (!visible && toShortDate(datePickerDate) !== defaultDate) {
    setDatePickerDate(defaultDate);
  }

  useEffect(() => {
    setSubmitDisabled(
      !(
        textValidation === "" &&
        selectValidation === "" &&
        dateValidation === ""
      )
    );
  }, [dateValidation, selectValidation, textValidation]);

  const handleSelectionChange = useCallback(( event: NonCancelableCustomEvent<SelectProps.ChangeDetail> ) => {
    let selected = event.detail.selectedOption;
    const invalidMessage = "A Confidence Level must be selected.";
    if (selected) {
      setSelectedOption(selected);
      setFormValidation("");
      setSelectValidation("");
    } else {
      setSelectValidation(invalidMessage);
    }
  }, []);

  const handleTextAreaChange = useCallback(( evt: NonCancelableCustomEvent<InputProps.ChangeDetail>) => {
    const value: string = evt.detail.value;
    const invalidEmptyMessage = "A note is required.";
    const invalidIllegalCharacterMessage = "Your note cannot contain the following character: ";
    const singleLegalCharacterNoteValidator = /[A-Za-z0-9 !@#&*():;"',./?`\r\n~’-]/;
    let containsInvalidCharacter: boolean = false;
    let invalidCharacter = "";

    for (let i: number = 0; i < value.length; i++) {
      if(!singleLegalCharacterNoteValidator.test(value.charAt(i))) {
        containsInvalidCharacter = true;
        invalidCharacter = value.charAt(i);
        break;
      }
    }

    setTextAreaValue(value);

    if (containsInvalidCharacter) {
      setTextValidation(invalidIllegalCharacterMessage + invalidCharacter);
    } else if (value === "") {
      setTextValidation(invalidEmptyMessage);
    } else {
      setFormValidation("");
      setTextValidation("");
    }
  }, []);

  const handleDatePickerChange = useCallback( (evt: NonCancelableCustomEvent<DatePickerProps.ChangeDetail>) => {
    const value: string = evt.detail.value;
    const invalidMessage = "Must be a valid date in the future.";
    const date = moment(value);
    if (date.isValid() && date >= todaysDate) {
      setDatePickerDate(value);
      setFormValidation("");
      setDateValidation("");
    } else {
      setDatePickerDate(value);
      setDateValidation(invalidMessage);
    }
  }, [todaysDate]);

  const handleConfidenceAndDateRemoval = useCallback((evt: NonCancelableCustomEvent<CheckboxProps.ChangeDetail>) => {
    const checked = evt.detail.checked;
    removeConfidenceAndDateSet(checked);
    if (checked) {
      setDisableConfidenceAndDate(true);
      setPreviousSelectValidation(selectValidation);
      setPreviousDateValidation(dateValidation);
      setSelectValidation("");
      setDateValidation("");
    } else {
      setDisableConfidenceAndDate(false);
      setSelectValidation(previousSelectValidation);
      setDateValidation(previousDateValidation);
    }
  }, [selectValidation, dateValidation, previousDateValidation, previousSelectValidation]);

  const handleFormSubmit = useCallback(async () => {
    if (!props.onSubmit) {
      return;
    }

    if (removeConfidenceAndDate && !userAbilities.canWriteCategories) {
      setError403(true);
      return;
    }
    const updateDescriptor: IUpdateServicePlanEstimatedDate = {
      confidence: selectedOption?.label as PlanConfidence,
      date: datePickerDate,
      note: textAreaValue || "",
      removeConfidenceAndDate,
    };
    loadingSet(true);
    try {
      await props.onSubmit(plan, updateDescriptor);
      if (mounted.current) {
        loadingSet(false);
        handleDismiss?.({ detail: { reason: "submit" } });
      }
    } catch (error) {
      const statusCode = parseInt((error as AxiosError).response?.status?.toString() ?? "");
      if (statusCode === HTTP_STATUS.NO_PERMISSION) {
        setError403(true);
        setUnknownError(false);
      } else {
        setError403(false);
        setUnknownError(true);
      }
    }
  }, [
    handleDismiss,
    userAbilities.canWriteCategories,
    selectedOption,
    datePickerDate,
    textAreaValue,
    removeConfidenceAndDate,
    plan,
    props.onSubmit,
  ]);

  const handleCancel = useCallback(() => {
    handleDismiss?.({ detail: { reason: "cancel" } });
  }, [handleDismiss]);

  const isDateEnabled = useCallback((date) => {
    const DD = ("0" + date.getDate()).slice(-2);
    const MM = ("0" + (1 + date.getMonth())).slice(-2);
    const YYYY = date.getFullYear();
    const d = `${YYYY}-${MM}-${DD}`;
    return moment(d, SHORT_DATE_FORMAT) >= todaysDate;
  }, [todaysDate]);

  return (
    <Modal
      data-testid={EnterEstimatedDateTestId.modal}
      visible={visible}
      size="large"
      header={
        <CompactHeader
          description={`${nameSortable ?? nameRip} in ${region}`}
        >Enter Planned Launch Date</CompactHeader>
      }
      onDismiss={handleDismiss}>
        <Form
          errorText={formValidation}
          actions={
            <DialogFooterButtonBar>
              <Button
                {...setTracking("Enter Estimated Date - Cancel")}
                data-testid={EnterEstimatedDateTestId.cancel}
                variant="link" onClick={handleCancel}>Cancel</Button>
              <Button
                {...setTracking("Enter Estimated Date - Submit")}
                data-testid={EnterEstimatedDateTestId.submit}
                variant="primary"
                loading={loading}
                disabled={submitDisabled}
                onClick={handleFormSubmit}>Submit</Button>
            </DialogFooterButtonBar>
          }
        >
          <Flashbar
            items={[
              {
                type: "info",
                content: (
                  <div data-testid={EnterEstimatedDateTestId.dayWarning}>
                    For public regions (commercial, GovCloud, China), enter the planned GA launch date. For DCA and LCK, services without ATO should enter the planned accreditation ready date (IA + artifacts collected and accreditation paperwork complete). After ATO is granted, services should enter the planned GA launch date.
                    <p>
                      Please provide context regarding your update, identifying any service or feature blockers by name. The information provided will be used to build reports on service parity for AWS leadership. In your note, special characters are not permitted: please only use letters, numbers, spaces, and simple punctuation.
                    </p>
                  </div>
                )
              }
            ]} />
          {
            error403 &&
            <Flashbar
              items={[
                {
                  type: "error",
                  content: (
                    <div data-testid={EnterEstimatedDateTestId.noPermissionError}>
                      Need Admin privileges to remove date/confidence, please use {RemoveDateSimTemplateLink} to submit a date removal request.
                    </div>
                  )
                }
              ]}
            />
          }

          {
            unknownError &&
            <Flashbar
              items={[
                {
                  type: "error",
                  content: (
                    <div data-testid={EnterEstimatedDateTestId.unknownError}>
                      An unknown error has occurred, if this continues to happen, please <Link
                        href={RECON_URL_CREATE_ISSUE}
                        external={true}
                        color="inverted"
                      >file a ticket with us</Link>
                    </div>
                  )
                }
              ]}
            />
          }
          <FormContent>
            <ColumnLayout columns={2}>
              <SpaceBetween direction={"vertical"} size={"s"}>
                <FormField
                  data-testid={EnterEstimatedDateTestId.confidenceFormField}
                  label="Confidence:"
                  description="Select Confidence of the Planned Launch Date."
                  errorText={selectValidation}
                  info={<Link
                    href="https://w.amazon.com/bin/view/Kingpin_Goal_Update_Guidelines/#Goal_Status_"
                    variant="info"
                    external={true}
                  >Info</Link>}>
                  <Select
                    data-testid={EnterEstimatedDateTestId.confidenceInput}
                    options={[
                      {
                        label: "Green",
                        value: "Green",
                      },
                      {
                        label: "Yellow",
                        value: "Yellow",
                      },
                      {
                        label: "Red",
                        value: "Red",
                      },
                    ]}
                    selectedOption={selectedOption}
                    selectedAriaLabel="Selected"
                    placeholder={"Select a confidence level..."}
                    ariaLabel={"Select a confidence level"}
                    onChange={handleSelectionChange}
                    disabled={disableConfidenceAndDate}
                  />
                </FormField>

                <FormField
                  data-testid={EnterEstimatedDateTestId.dateFormField}
                  label="Planned Launch Date:"
                  errorText={dateValidation}>
                  <DatePicker
                    data-testid={EnterEstimatedDateTestId.dateInput}
                    value={datePickerDate}
                    isDateEnabled={isDateEnabled}
                    placeholder="YYYY/MM/DD"
                    todayAriaLabel="Today"
                    nextMonthAriaLabel="Next month"
                    previousMonthAriaLabel="Previous month"
                    onChange={handleDatePickerChange}
                    disabled={disableConfidenceAndDate}
                  />
                </FormField>

                <Checkbox data-testid={EnterEstimatedDateTestId.removeCheckbox}
                          checked={disableConfidenceAndDate}
                          onChange={handleConfidenceAndDateRemoval}>
                  Remove Confidence and Date (requires Note)
                </Checkbox>
              </SpaceBetween>

              <div>
                <FormField
                  data-testid={EnterEstimatedDateTestId.noteFormField}
                  stretch={true}
                  label="Note:"
                  errorText={textValidation}>
                  <Textarea
                    data-testid={EnterEstimatedDateTestId.noteInput}
                    value={textAreaValue}
                    placeholder="Why you are launching on this date (Required)"
                    rows={10}
                    onChange={handleTextAreaChange}
                  />
                </FormField>
              </div>
            </ColumnLayout>
          </FormContent>
          <HorizontalBar />
        </Form>
    </Modal>
  );
};

export default EnterEstimatedDateModal;
