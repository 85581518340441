import { QaItemDefinition } from "../models/QaItemDefinition";
import { IGetRegionsResponse } from "../../daos/types/IGetRegionsResponse";
import { getRegionListOperation } from "../remoteOperations/getRegionListOperation";
import { noAccessChecker } from "../checkers/noAccessChecker";
import { getServiceListOperation } from "../remoteOperations/serviceOperation";
import { IServiceMetaData } from "../../daos/types/IServiceMetaData";
import * as noAbility from "./abilityItems/noAbility";

export const noAccessQaItems: QaItemDefinition<any>[] = [
  new QaItemDefinition<IGetRegionsResponse>(
    "/api/regions should be rejected",
    getRegionListOperation,
    noAccessChecker
  ),

  new QaItemDefinition<IServiceMetaData[]>(
    "/api/services should be rejected",
    getServiceListOperation,
    noAccessChecker
  ),

  noAbility.cannotUpdateServicePlan,
  noAbility.cannotUpdateBusinessCaseOverride,
  noAbility.cannotUpdateEstimatedDate,
  noAbility.cannotRemoveEstimatedDate,
];
