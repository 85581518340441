import React, { FunctionComponent, useCallback, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Alert, Link, NonCancelableCustomEvent, SpaceBetween, TabsProps } from "@amzn/awsui-components-react-v3";
import useSWR from "swr";
import { useLocation } from "react-router";
import { getAppState } from "../../models/ApplicationState";
import { LaunchVO } from "../../models/vos/LaunchVO";
import { REPORT_A_BUG_URL } from "../../constants";
import { LaunchDetails } from "../../components/LaunchDetails/LaunchDetails";
import { NoAccessError } from "../../components/common/NoAccessError";
import { launchDtoToVO } from "../../parsers/launchParser";
import { LaunchDao } from "../../daos/LaunchDao";
import { RegionDao } from "../../daos/RegionDao";
import { RegionMetadataVO } from "../../models/vos/RegionMetadataVO";
import { useProduct } from "../../utils/productUtil";
import { useLaunchContext } from "../../contexts/LaunchContext";
import { ILocationState } from "../../models/LocationState";
import { useProductContext } from "../../contexts/ProductContext";
import { ProductVO } from "../../models/vos/ProductVO";
import { ChangeVO } from "../../models/vos/ChangeVO";
import { ChangeDao } from "../../daos/ChangeDao";
import { changeDtosToVOs } from "../../parsers/changeParser";
import useTitleBanner from "../../components/unified/TitleBanner/useTitleBanner";
import { DeleteLaunchModal } from "../../components/LaunchDetails/DeleteLaunchModal/DeleteLaunchModal";
import {
  EditLaunchErrorAlerts
} from "../../components/LaunchDetails/EditLaunch/EditLaunchErrorAlerts/EditLaunchErrorAlerts.helpers";
import { getSwrConfig } from "../../daos/swrUtil";
import { useLoadInfinitePages } from "../../utils/swrUtil";
import { LaunchDraftBanner } from "../../components/LaunchDetails/banners/LaunchDraftBanner/LaunchDraftBanner";
import {
  LaunchSynchronizingBanner
} from "../../components/LaunchDetails/banners/LaunchSynchronizingBanner/LaunchSynchronizingBanner";
import { parseRipRegionMetadataDto } from "../../parsers/regionMetadataParser";

export interface ILaunchDetailsContainerParam {
  launchId: string;
}

export const LaunchDetailsContainer: FunctionComponent = () => {
  const { launchId } = useParams<ILaunchDetailsContainerParam>();

  const history = useHistory();
  const location = useLocation<ILocationState>();
  const { search } = useLocation();
  const userAbilities = getAppState().userAbilities;
  const { launch, setLaunch } = useLaunchContext();
  const { product, setProduct } = useProductContext();
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [deleteValidating, setDeleteValidating] = useState(false);
  const [pageError, setPageError] = useState<any>({
    status: 200,
    text: {}
  });
  const [canEdit, setCanEdit] = useState(false);

  const setTitleBanner = useTitleBanner({ visible: true });

  // call swr to fetch launches
  const {
    data: launchData,
    error,
    isValidating: isValidatingLaunch
  } = useSWR<LaunchVO>(() => `launch/${launchId}`, async () => {
    const dao = new LaunchDao();
    const dto = await dao.getLaunch(launchId);

    return launchDtoToVO(dto);
  }, {
    ...getSwrConfig(),
    refreshInterval: canEdit ? 0 : 10000,
  });

  useEffect(() => {
    if (launchData?.rowId) {
      setCanEdit(true);
    }
  }, [launchData]);

  // call swr to fetch products
  const { data: productData, isValidating: isValidatingProduct } = useProduct(launchData?.productId);

  // call swr to fetch regions
  const { data: regionsData } = useSWR<RegionMetadataVO[]>(() => `launchRegions`, async () => {
    const dao = new RegionDao();
    const dto = (await dao.getRipRegions());

    return dto.regions.items.map(value => parseRipRegionMetadataDto(value));
  }, getSwrConfig());

  const { data: changes } = useLoadInfinitePages<ChangeVO>(
    `/alm/changes/${launchId}`,
    async (request, token) => {
      const dao = new ChangeDao();
      const dto = await dao.getChanges(launchId, 200, token);
      return { items: changeDtosToVOs(dto.items), nextToken: dto.nextToken };
    },
    { ...getSwrConfig() }
  );

  // callback handler for LaunchDetails onChange
  //  sets urlQueryParam "tabId" to the currently selected tab
  //  to persist selected tab between reloads.
  const tabsHandler = useCallback((event: NonCancelableCustomEvent<TabsProps.ChangeDetail>) => {
    const queryParams = new URLSearchParams(search);
    queryParams.set("tabId", event.detail.activeTabId);
    history.push({
      search: queryParams.toString()
    });
  }, [history, search]);

  const deleteHandler = async () => {
    setDeleteValidating(true);
    const dao = new LaunchDao();
    const response = await dao.deleteLaunch(launchId);
    setDeleteValidating(false);
    setDeleteModalVisible(false);
    if (response.status === 200 || response.status === 201) {
      // in case the launch is missing productId in case of malformed launch.
      if (launch.productId) {
        history.push(`/alm/products/${launch.productId}/launches`);
      } else {
        history.push(`/alm/products`);
      }
    } else {
      setPageError({
        status: response.status,
        text: response.data,
      });
    }
  }

  useEffect(() => {
    setTitleBanner({
      visible: true,
      title: launch?.name,
      details: {
        info: launch?.description
      }
    });
  }, [launch?.description, launch?.name, setTitleBanner]);

  useEffect(() => {
    if (launch.id !== launchId || launchData) {
      const newLaunch = launchData
        ?? (location.state?.launch?.id === launchId ? location.state?.launch : null)
        ?? new LaunchVO();

      setLaunch(newLaunch);
    }
  }, [launch.id, launchData, launchId, location.state?.launch, setLaunch])

  useEffect(() => {
    if (product.id !== launch?.productId) {
      const newProduct = productData
        ?? (location.state?.product?.id === launch.productId ? location.state?.product : null)
        ?? new ProductVO();

      setProduct(newProduct);
    }
  }, [launch.productId, location.state?.product, product.id, productData, setProduct])

  if (true) {
    if (error && !isValidatingLaunch) {
      return (
        <Alert
          type="error"
          header="Data Fetching Error"
        >
          {"There was an error fetching launch details, please try again later or submit a "}
          <Link
            external
            externalIconAriaLabel="Opens in a new tab"
            href={REPORT_A_BUG_URL}
          >
            ticket
          </Link>.
        </Alert>
      )
    }
    return (
      <>
        {EditLaunchErrorAlerts[pageError.status] &&
          EditLaunchErrorAlerts[pageError.status](pageError.text)}
        {deleteModalVisible
          && <DeleteLaunchModal
            launch={launchData ?? new LaunchVO()}
            visible={deleteModalVisible}
            validating={deleteValidating}
            onDelete={() => deleteHandler()}
            onCancel={() => setDeleteModalVisible(false)}
          />
        }
        <SpaceBetween size={"s"}>
          <LaunchSynchronizingBanner launch={launchData}/>
          <LaunchDraftBanner launch={launch}/>
          <LaunchDetails
            launch={launchData ?? new LaunchVO()}
            onChange={tabsHandler}
            onDelete={() => setDeleteModalVisible(true)}
            selectedTab={new URLSearchParams(search).get("tabId") ?? undefined}
            tasks={[]}
            changes={changes ?? []}
            regions={regionsData ?? []}
            product={productData}
            loading={!launchData && isValidatingLaunch}
            loadingProduct={!productData && isValidatingProduct}
            canEdit={canEdit}
          />
        </SpaceBetween>
      </>
    );
  }
  return <NoAccessError/>
}
