import { IHasDataTestId } from "../../models/IHasDataTestId";
import { Control, DeepMap, FieldError } from "react-hook-form";
import { IFormFieldData } from "../common/FormTypes";
import { Displayable } from "@amzn/api-parity-react-component/lib/models/Displayable";
import { IKeyValueFormInput, ISelectableFormInput } from "../LaunchDetails/EditLaunch/EditLaunchForm.types";

export enum FormFieldTypes {
  INPUT_ENTRY,
  DATE_ENTRY,
  SELECT_ENTRY,
  TEXT_ENTRY,
  TOKEN_ENTRY,
  CHECKBOX_ENTRY,
  RADIO_ENTRY,
  AUTOSUGGEST_ENTRY,
  KEY_VALUE_ENTRY
}

export interface IIndividualFormFieldWithControllerInput extends IHasDataTestId {
  control: Control;
  errors: DeepMap<any, FieldError>;
  formField: IFormFieldData;
  defaultValue?: string | IKeyValueFormInput[] | string[] | ISelectableFormInput | boolean;
  popoverInfo?: Displayable;
  fieldType: FormFieldTypes;
  label?: Displayable
  placeholder?: string
}
