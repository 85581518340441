import { getRmsApiClient } from "./daoUtil";
import { getConfig } from "../utils/environmentUtil";
import { produce } from "immer";
import {
  IFeatureInRegion,
  IFeatureInRegionMetadataPagedResult,
  IFeatureInRegionPagedResult,
  IFeatureServiceDefinition,
  IFeatureServiceItem,
  IGenericCollectionParameter,
  IServiceInRegion,
  IServiceInRegionPagedResult
} from "./types/IGetFeatureDto"
import { IRmsComponentsForServiceDto } from "./types/IRmsComponentsForServiceDto";

export class RmsDao {
  async getAllFeatureDataForService(serviceName : string) : Promise<IFeatureServiceItem[]> {
    return this.getFeaturesForService(serviceName).then(data => {
      let featureNames : IFeatureServiceDefinition[] = data.map((feature : IFeatureServiceItem) => { return { longName: feature.longName as string, id: feature.id as string }});
      return this.getAllFeatureInstances(serviceName, featureNames).then(allData => {
        return allData;
      });
    });
  };

  async getFeaturesForService(serviceName : string) : Promise<IFeatureServiceItem[]> {
    const parameters = {
      Dimension: {
        Name: serviceName,
        Type: "SERVICE",
      }
    }

    const apiResponse : Promise<IFeatureServiceItem[]> = this.getSimpleCollection(
      "ListFeatureMetadata",
      parameters,
      (response: IFeatureInRegionMetadataPagedResult) => response.featureMetadata
    );

    return apiResponse;
  }

  async getComponentsForService(serviceName : string) : Promise<IRmsComponentsForServiceDto[]> {
    return this.getSimpleCollection(
      "ListServices",
      { "ParentServiceName": serviceName },
      (response: any) => response.serviceMetadata
    );
  }

  async getStatusesForService(serviceName : string) : Promise<IServiceInRegion[]> {
    const parameters : IGenericCollectionParameter = {
      "ServiceName": serviceName
    }

    const apiResponse = this.getSimpleCollection<IServiceInRegion, IServiceInRegionPagedResult>(
      "ListServiceInstances",
      parameters,
      (response) => response.serviceInstances
    );

    return apiResponse;
  }

  async getAllFeatureInstances(serviceName : string, featureNameList : IFeatureServiceDefinition[]) : Promise<IFeatureServiceItem[]> {
    const allFeaturePromises : Promise<any>[] = [];
    let allDataCombined : IFeatureServiceItem[] = [];

    featureNameList.forEach(featureName => {
      const singleFeatureData = this.getFeatureInstances(serviceName, featureName.id)
      allFeaturePromises.push(singleFeatureData);
    });

    return Promise.all(allFeaturePromises).then(featureInstancesData => {
      allDataCombined = this.combineFeatureNamesWithInstanceData(featureNameList, featureInstancesData);
      return allDataCombined;
    });
  }

  async getFeatureInstances(serviceName : string, featureName : string) : Promise<IFeatureInRegion[]> {
    const parameters = {
      Dimension: {
        Name: serviceName,
        Type: "SERVICE",
      },
      Id: featureName
    }

    const apiResponse = this.getSimpleCollection<IFeatureInRegion, IFeatureInRegionPagedResult>(
      "ListFeatureInstances",
      parameters,
      (response) => response.featureInstances
    );

    return apiResponse;
  }

  combineFeatureNamesWithInstanceData(featureNamesList : IFeatureServiceDefinition[] = [] , featureInstancesData: IFeatureInRegion[][] = []) : IFeatureServiceItem[] {
    let allDataCombined : IFeatureServiceItem[] = [];
    featureNamesList.forEach(feature => {
      const featureId = feature.id;

      featureInstancesData.forEach(potentialFeatureMatch => {
        const potentialFeatureId = potentialFeatureMatch[0]?.id;
        if (featureId === potentialFeatureId) {
          const featureInstancesMatch = potentialFeatureMatch;
          const combinedData = this.associateFeatureMetadataAndInstanceData(feature, featureInstancesMatch);
          allDataCombined.push(combinedData);
        }
      });
    });

    // in scenarios where we got no data back from API - make sure we include anyways
    const allDataKeysSet = new Set(allDataCombined.map(item => item.id));
    const missingFeatures = featureNamesList.filter( (f) => !allDataKeysSet.has( f.id ) );
    allDataCombined = allDataCombined.concat(missingFeatures);

    return allDataCombined;
  }

  associateFeatureMetadataAndInstanceData(featureMetadata: IFeatureServiceDefinition, featureInstanceData: IFeatureInRegion[] = []) : IFeatureServiceItem {
    const combinedObject : IFeatureServiceItem = {
      id: featureMetadata.id,
      longName: featureMetadata.longName
    };

    featureInstanceData.forEach(featureInRegion => {
      const { estimatedLaunchDate, status, parentDimensionKey: { name: regionName } } = featureInRegion;
      combinedObject[regionName] = {
        estimatedLaunchDate,
        status
      }
    });

    return combinedObject;
  }

  async getSimpleCollection<ItemT, PageT>(
    operation : string,
    parameters : IGenericCollectionParameter,
    listExtractor : (pagedResult: PageT) => ItemT[],
    maxResults : number = 100
  ) : Promise<ItemT[]>
  {
    let nextToken = null;
    let parameterWithPaging = this.getNextPageParameter(parameters, maxResults, nextToken);
    let result : ItemT[] = [];
    let reachesEnd = false

    while (!reachesEnd) {
      const pagedResult = await this.callOperation(operation, parameterWithPaging);
      const pagedList = listExtractor(pagedResult);
      result = result.concat(pagedList);
      nextToken = pagedResult.nextToken;
      if (nextToken) {
        parameterWithPaging = this.getNextPageParameter(parameters, maxResults, nextToken);
      } else {
        reachesEnd = true;
      }
    }

    return result;
  }

  getNextPageParameter(parameters, maxResults, token) {
    return produce(parameters, (next) => {
      next.MaxResults = maxResults;
      next.NextToken = token;
    });
  }

  async callOperation(operation : string, parameters : object) {
    const client = getRmsApiClient();
    const url = getConfig()?.rmsApiUrl as string;
    const res = await client.post(url, parameters, {
      headers: {
        operation
      }
    });

    return res.data;
  }
}
