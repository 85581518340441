import { getRegionMap } from "./regionUtil";
import { IServicePlan, ServicePlanAndStubbed, StubbedServicePlan } from "../models/types/IServicePlan";
import { IRegionMetadata } from "../daos/types/IRegionMetaData";
import { IServiceRegionPlanDto } from "../daos/types/IServiceRegionPlanDto";
import { Optional } from "../models/types/Optional";
import { toShortDate } from "./dateUtil";
import { emptyExportValue } from "../components/ExportTableToCSVExcel/ExportTableToCSVExcel";
import { DEFAULT_STATUS_STRING } from "./singleRegionUtils";
import { getRenderedDate, isGaStatus } from "../components/ServiceInRegion/ServiceInRegionDetailsUtil";
import { upperFirst } from "lodash";
import { GENERALLY_AVAILABLE, NON_GLOBALLY_EXPANDING } from "../constants";

/**
 * Attach region detail information on each of the plan
 */
export function attachRegionObject(plans: IServicePlan[], regions: IRegionMetadata[]): IServicePlan[] {
  const regionMap: Map<string, IRegionMetadata> = getRegionMap(regions);
  return plans.map((plan) => {
    const regionCode: string = plan.belongsToInstance;
    let region: IRegionMetadata = regionMap.get(regionCode) as IRegionMetadata;

    return {
      ...plan,
      regionLaunchDate: region.date,
      regionObject: region
    }
  });
}


/**
 * Ensure all the regions supplied would have a corresponding service instance (plan), if there isn't one
 * create a stubbed record of the service instance, if a plan references a region not in "regions", remove the
 * plan instance from list
 * @param serviceInstances
 * @param regions
 * @return
 */
export function equalizeWithRegions(serviceInstances: IServiceRegionPlanDto[], regions: IRegionMetadata[]): ServicePlanAndStubbed[] {
  const regionsToAdd: StubbedServicePlan[] = [];
  regions.forEach((region) => {
    let regionInInstance = false;
    serviceInstances.forEach(({ belongsToInstance }) => {
      if (belongsToInstance === region.nameSortable) {
        regionInInstance = true;
      }
    });
    if (!regionInInstance) {
      regionsToAdd.push(
        {
          belongsToInstance: region.nameSortable as string,
          isStubbed: true,
        } as StubbedServicePlan);
    }
  });

  const plans: ServicePlanAndStubbed[] = (serviceInstances as ServicePlanAndStubbed[]).concat(regionsToAdd);
  const regionMap = getRegionMap(regions);
  // Filer our plan that is not in the region list
  // https://issues.amazon.com/issues/RECON-5230
  return plans.filter((plan) => {
    const regionCode = plan.belongsToInstance;
    return regionMap.has(regionCode);
  });
}

export function getRegionFromInstanceField(instance: string): string {
  return instance.split(":")[2];
}

export const getServicePlansInLaunchedRegions = (servicePlans: IServiceRegionPlanDto[]): IServiceRegionPlanDto[] => {
  return servicePlans.filter(plan => plan.regionStatus === GENERALLY_AVAILABLE);
}

export const getServicePlansInLaunchedRegionsOrServiceIsNge = (servicePlans: IServiceRegionPlanDto[]): IServiceRegionPlanDto[] => {
  return servicePlans.filter(plan => plan.plan === NON_GLOBALLY_EXPANDING || plan.regionStatus === GENERALLY_AVAILABLE);
}
// NOTE: This is actually not optional since we're passing in a default value to toShortDate
// but the types are angry so we'll appease them
export function getLaunchDateFromServicePlan(singleServicePlan: IServicePlan): Optional<string> {
  return toShortDate(singleServicePlan.regionObject.date, emptyExportValue);
}

export function getStatusFromServicePlan(singleServicePlan: IServicePlan): string {
  return (!!singleServicePlan.status && singleServicePlan.status !== "-") ? singleServicePlan.status: DEFAULT_STATUS_STRING;
}

export function getCategoryFromPlanForDisplay(singleServiceData: IServicePlan): string {
  return singleServiceData.plan ?? emptyExportValue;
}

export function getPlannedDateFromServicePlan(singleServicePlan: IServicePlan): string {
  const regionObj = singleServicePlan?.region ?? {};
  const regionKeys = Object.keys(regionObj);
  const regionKey : Optional<string> = regionKeys.length === 0 ? regionKeys[0] : undefined;

  const exceptionDisposition = (regionKey !== undefined) ? regionObj[regionKey]?.disposition : undefined;
  return getRenderedDate(isGaStatus(singleServicePlan.status), exceptionDisposition, singleServicePlan.date, singleServicePlan.plan);
}

export function getConfidenceFromServicePlan(singleServicePlan: IServicePlan): string {
  return !!singleServicePlan.confidence ? upperFirst(singleServicePlan.confidence?.toLowerCase()) : emptyExportValue;
}
