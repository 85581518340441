import * as React from "react";
import { LaunchContactsVO } from "../../models/vos/LaunchVO";
import { Optional } from "@amzn/react-bot";
import { IKeyValueFormInput } from "../../components/LaunchDetails/EditLaunch/EditLaunchForm.types";
import { LaunchDao } from "../../daos/LaunchDao";
import { launchDtoToVO } from "../../parsers/launchParser";
import { AxiosResponse } from "axios";
import { IUpdateLaunchDto } from "../../daos/types/ILaunchDto";

export const getOtherContactsForForm = (launchContacts: Optional<LaunchContactsVO>): IKeyValueFormInput[] => {
  return (launchContacts?.other ?? []).map((contact) => {
    return {
      key: contact.contactType.replace("OTHER: ", ""),
      value: contact.alias,
    }
  });
}

export const setLaunchState = async (launchId: string, setLaunch: React.Dispatch<any>, setError: React.Dispatch<any>) => {
  const dao = new LaunchDao();
  await dao.getLaunch(launchId).then((launch) => {
    setLaunch(launchDtoToVO(launch))
  }).catch((fetchError) => {
    setError(fetchError)
  });
}

export const submitLaunchEditToApi = async(launchId: string, launch: Partial<IUpdateLaunchDto>, setUpdateResponse: React.Dispatch<any>, history) => {
  const dao = new LaunchDao();
  const response: AxiosResponse = await dao.updateLaunch(launchId, launch);
  setUpdateResponse({ status: response.status, text: response.data });
  if (response.status === 200 || response.status === 201) {
    history.push(`/alm/launches/${launchId}`);
  }
  return response.data;
}

