import { DateTime } from "luxon"
import { ILaunchQuestionnaire, LaunchContactsVO, LaunchVO } from "../../../../models/vos/LaunchVO";
import { ILaunchContact, ILaunchState } from "../../../../daos/types/ILaunchDto";
import { editLaunchFormFields } from "../EditLaunchFormFields";
import { Audience } from "../../../../daos/types/Audience";
import {
  camelCaseContactToEnum,
  parseLaunchContactsFromForm
} from "../../../../parsers/launchParser";
import { convertYesNoRadioToBoolean } from "../../../IndividualFormFieldWithController/FormFieldTypeMapper";
import { LaunchGoalType, LaunchState } from "../../../ProductLaunches/LaunchWizard/LaunchWizard.types";
import { IKeyValueFormInput } from "../EditLaunchForm.types";
import { Optional } from "@amzn/react-bot";
import { isEmpty } from "lodash";
import { getPstDateToday } from "../../../../utils/dateUtil";

export interface dropDownFieldType {
  label: string,
  value: string
}

export interface IEditLaunchFormValues {
  // properties
  id: string;
  date?: string;
  status?: dropDownFieldType;
  state?: dropDownFieldType;
  disclosureLevel?: dropDownFieldType;
  description?: string;
  trackerLink?: string;
  tags?: string[];

  reasonForChange?: dropDownFieldType;
  reasonForChangeDescription?: string;

  // general
  codename?: string;
  name?: string;
  product?: string;
  iteration?: dropDownFieldType;
  type?: dropDownFieldType;
  scope?: dropDownFieldType;
  ripName?: string;
  tier?: dropDownFieldType;
  audience?: dropDownFieldType;
  confidential?: string;
  goalLink?: string;
  goal?: string;

  productManager?: string;
  marketingLead?: string;
  engineeringLead?: string;
  otherContacts?: IKeyValueFormInput[];

  pricingChangeInvolved?: boolean;
  namingInvolved?: boolean;
  prfaqRequired?: boolean;
  regulated?: boolean;
}

export interface IEditLaunchSimpleFormValues {
  // properties
  id: string;
  date?: string;
  status?: string;
  state?: ILaunchState;
  disclosureLevel?: string;
  description?: string;
  trackerLink?: string;
  tags?: string[]

  reasonForChange?: string;
  reasonForChangeDescription?: string;

  // general
  codename?: string;
  name?: string;
  product?: string;
  iteration?: string;
  type?: string;
  scope?: string;
  ripName?: string;
  tier?: string;
  audience?: Audience;  // need to decide if we're going uppercase or lower
  confidential?: boolean;
  goalLink?: string;
  goal?: string;
  productManager?: string;
  marketingLead?: string;
  engineeringLead?: string;
  otherContacts?: IKeyValueFormInput[];
  pricingChangeInvolved?: boolean;
  namingInvolved?: boolean;
  prfaqRequired?: boolean;
  regulated?: boolean;
}

export interface QuestionnaireGroupedFormValues extends Omit<IEditLaunchSimpleFormValues, "pricingChangeInvolved"|"namingInvolved"|"prfaqRequired"|"regulated"> {
  questionnaire?: ILaunchQuestionnaire;
}

export interface ContactsGroupedFormValues extends Omit<QuestionnaireGroupedFormValues, "productManager"|"marketingLead"|"engineeringLead"|"otherContacts"> {
  contacts?: LaunchContactsVO;
}

export const formMultipleChoiceToSimpleValue = (launchId: string, editLaunchFormValues: IEditLaunchFormValues): IEditLaunchSimpleFormValues => {
  const formFixedValues: IEditLaunchSimpleFormValues = { id: launchId };
  Object.keys(editLaunchFormValues).forEach((attribute) => {
    if (editLaunchFormFields[attribute as keyof typeof editLaunchFormFields]?.options && Object.keys(editLaunchFormValues[attribute] ?? {}).includes("value")){
      formFixedValues[attribute] = editLaunchFormValues[attribute]["value"]
    }
    else {
      formFixedValues[attribute] = editLaunchFormValues[attribute]
    }
  });
  formFixedValues.confidential = convertYesNoRadioToBoolean(editLaunchFormValues.confidential)
  formFixedValues.goalLink = isEmpty(formFixedValues.goalLink) ? undefined : formFixedValues.goalLink
  return formFixedValues;
}

export const formAutoSuggestOtherCleanup = (editLaunchFormValues: IEditLaunchSimpleFormValues): IEditLaunchSimpleFormValues => {
  const { goal, ...editLaunchFormEnumedValues } = editLaunchFormValues;
  if (goal) {
    editLaunchFormEnumedValues["goal"] = (!Object.values<string>(LaunchGoalType).includes(goal)) ? `OTHER: ${goal}` : Object.keys(LaunchGoalType)[Object.values(LaunchGoalType).indexOf(goal as LaunchGoalType)];
  }
  return editLaunchFormEnumedValues;
}

export const formQuestionnaireGrouper = (editLaunchFormValues: IEditLaunchSimpleFormValues): QuestionnaireGroupedFormValues => {
  const { pricingChangeInvolved, namingInvolved, prfaqRequired, regulated, ...formWithQuestionaire } = editLaunchFormValues;
  formWithQuestionaire["questionnaire"] = { pricingChangeInvolved, namingInvolved, prfaqRequired, regulated }
  return formWithQuestionaire;
}

export const contactsGrouper = (editLaunchFormValues: IEditLaunchSimpleFormValues): ContactsGroupedFormValues => {
  const { productManager, marketingLead, engineeringLead, otherContacts, ...formWithContacts } = editLaunchFormValues;
  const contacts: ILaunchContact[] = [];
  const fields = { productManager, marketingLead, engineeringLead }
  Object.keys(fields).forEach((field) => {
    if (fields[field]) {
      contacts.push({ contactType: camelCaseContactToEnum[field] ?? field, alias: fields[field] });
    }
  });
  (otherContacts ?? []).forEach((contact) => {
    contacts.push({ contactType: `OTHER: ${contact.key ?? ""}`, alias: contact.value ?? "" });
  });
  formWithContacts["contacts"] = parseLaunchContactsFromForm(contacts);
  return formWithContacts;
}

export const consolidateReasonForChange = (editLaunchFormValues: IEditLaunchSimpleFormValues): Optional<string> => {
  let reasonForChange = "";
  if (editLaunchFormValues.reasonForChange && editLaunchFormValues.reasonForChange.length > 0){
    reasonForChange += editLaunchFormValues.reasonForChange
    if (editLaunchFormValues.reasonForChangeDescription && editLaunchFormValues.reasonForChangeDescription.length > 0) {
      reasonForChange +=`: ${(editLaunchFormValues.reasonForChangeDescription ?? "")}`
    }
    return reasonForChange
  }
  else return undefined;
}
/**
 *
 * @desc reinforceDateStringFormat - potential fix for non-deterministic form validation of date. Allowing users
 * to enter dates with dashes or slashes, then convert them to dashes before sending to the backend
 */
export const convertDateStringToDashFormat = (editLaunchFormValues: IEditLaunchFormValues): IEditLaunchFormValues => {
  const editLaunchValuesWithReinforcedDate = { ...editLaunchFormValues }
  if (editLaunchValuesWithReinforcedDate.date) {
    editLaunchValuesWithReinforcedDate.date = editLaunchValuesWithReinforcedDate.date.replace(/\//g,"-")
  }
  return editLaunchValuesWithReinforcedDate;
}

export const convertFormValuesToLaunchVO = (launchId: string, getValues): { launch: LaunchVO, reasonForChange: Optional<string> } => {
  const editLaunchValues: IEditLaunchFormValues = { ...getValues(), id: launchId };
  const editLaunchValuesWithReinforcedDateFormat: IEditLaunchFormValues = convertDateStringToDashFormat(editLaunchValues)
  const simplifiedFormValues: IEditLaunchSimpleFormValues = formMultipleChoiceToSimpleValue(launchId, editLaunchValuesWithReinforcedDateFormat)
  const formWithEnumValues: IEditLaunchSimpleFormValues = formAutoSuggestOtherCleanup(simplifiedFormValues)
  const questionnaireGroupedFormValues: QuestionnaireGroupedFormValues = formQuestionnaireGrouper(formWithEnumValues)
  const contactsGroupedFormValues: ContactsGroupedFormValues = contactsGrouper(questionnaireGroupedFormValues)
  const reasonForChange: Optional<string> = consolidateReasonForChange(simplifiedFormValues)
  const launchVO: LaunchVO = new LaunchVO({ ...contactsGroupedFormValues });
  return { launch: launchVO, reasonForChange };
}

export function warnUserAboutShippedLaunch(launchState: string, updateLaunchDate?: string, ): boolean {
  if (launchState === LaunchState.SHIPPED) {
    const today = DateTime.local().setZone("America/Los_Angeles");
    const todayDate = getPstDateToday()
    // @ts-ignore
    const providedDate = DateTime.fromString(updateLaunchDate ?? todayDate, "yyyy-LL-dd");
    const modifiedLaunchDate = DateTime.min(today, providedDate);
    return modifiedLaunchDate && modifiedLaunchDate !== providedDate
  }
  return false
}
