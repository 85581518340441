import * as React from "react";
import { FunctionComponent } from "react";
import { getAppState } from "../../../models/ApplicationState";
import { NoAccessError } from "../../../components/common/NoAccessError";
import { useParams } from "react-router-dom";
import { QuickSightDashboard } from "../../../components/QuickSightDashboard/QuickSightDashboard";
import { Link } from "@amzn/awsui-components-react-v3";
import { SingleServiceHeaderContainer } from "../SingleServiceHeader/SingleServiceHeaderContainer";
import { ISingleServiceContainerPathParam } from "../SingleServiceContainer.types";
import { fixFormatServiceId } from "../../../utils/urlUtil";

export interface ISingleServiceTeamContainerPathParam {
  serviceName: string;
}

export interface IAccessibleSingleServiceTeamContainerProps {
  serviceId: string;
  showHeader: boolean;
}

// This view is only for unified ux
export const SingleServiceTeamContainer: FunctionComponent<{}> = () => {
  const { serviceName } = useParams<ISingleServiceContainerPathParam>();
  const canAccess = !!serviceName && getAppState().userAbilities.canReadDates;
  return canAccess ? <AccessibleSingleServiceTeamContainer serviceId={serviceName} showHeader={true}  /> : <NoAccessError />;
}

export const AccessibleSingleServiceTeamContainer: FunctionComponent<IAccessibleSingleServiceTeamContainerProps> = (props): JSX.Element => {
  const parameter = {
    "p.ServiceName": props.serviceId,
  };

  return <React.Fragment>
    {
      (props.showHeader) && <SingleServiceHeaderContainer serviceId={fixFormatServiceId(props.serviceId)} />
    }
    <QuickSightDashboard
      dashboardId={"serviceteam"}
      dashboardHeight={"calc(100vh - 22rem)"}
      links={<Link href={"https://tiny.amazon.com/63iy2w8l/ServiceTeamFeatureRequest"} external={true}> Submit a feature request</Link>}
      params={parameter} />
  </React.Fragment>
}
