import * as React from "react";
import { useEffect, useState } from "react";
import useSWR from "swr";
import { Flash } from "@amzn/awsui-components-react";
import { OneLevelLookup } from "@amzn/api-parity-react-component/lib/utils/lookupUtil";
import { getSwrConfig } from "../../../daos/swrUtil";
import { PlanDao } from "../../../daos/PlanDao";
import { RegionDao } from "../../../daos/RegionDao";
import { equalizeWithRegions } from "../../../utils/planUtil";
import { NoAccessError } from "../../../components/common/NoAccessError";
import { SingleServiceFeatureContainer } from "./SingleServiceFeatureContainer";
import { IGetSingleServicePlanResponseLegacy } from "../../../daos/types/IGetSingleServicePlanResponse";
import { IRegionMetadata } from "../../../daos/types/IRegionMetaData";
import { getServiceDisplayName } from "../../../utils/serviceMetadataUtil";
import { ServicePlanAndStubbed } from "../../../models/types/IServicePlan";
import { IServiceMetaData } from "../../../daos/types/IServiceMetaData";
import { Optional } from "../../../models/types/Optional";
import { IServiceRegionPlanDto } from "../../../daos/types/IServiceRegionPlanDto";
import { filterRegionsByAirportCodes, mapPartitionToRegions } from "../../../utils/regionUtil";
import { UserAbilities } from "../../../models/UserAbilities";
import { useUserPermission } from "../../../hooks/useUserPermission";
import { getRegionsForFeaturesContainer } from "../SingleServiceContainerUtil";

export interface ISingleServiceContainerPathParam {
  serviceName: string;
}

const defaultRegionList: IRegionMetadata[] = [];
const defaultServiceInstances: IServiceRegionPlanDto[] = [];

export interface ISingleServiceFeatureParityContainerProps {
  serviceId: string;
}

export const SingleServiceFeatureParityContainer: React.FunctionComponent<ISingleServiceFeatureParityContainerProps> = (props) => {
  const serviceName = props.serviceId;

  const userAbilities: UserAbilities = useUserPermission(serviceName);

  const planSwr = useSWR<IGetSingleServicePlanResponseLegacy>(serviceName + "plans", () => {
    const planDao = new PlanDao();
    return planDao.getSingleServicePlans(serviceName);
  }, getSwrConfig());

  const service: Optional<IGetSingleServicePlanResponseLegacy> = planSwr.data;
  const regionListSwr = useSWR<IRegionMetadata[]>(serviceName + "regions", () => {
    const regionDao = new RegionDao();
    return regionDao.getRegions();
  }, getSwrConfig());

  const regions = regionListSwr.data ?? defaultRegionList;

  const [completedRegions, setCompletedRegions] = useState<string[]>([]);

  const serviceMetadata: Optional<IServiceMetaData> = service?.serviceMetadata;
  const serviceInstances: IServiceRegionPlanDto[] = service?.serviceInstances ?? defaultServiceInstances;

  const displayName = (serviceMetadata) ? getServiceDisplayName(serviceMetadata) : "";

  useEffect(() => {
    const combined: ServicePlanAndStubbed[] = equalizeWithRegions(serviceInstances, regions);
    setCompletedRegions(getRegionsForFeaturesContainer(combined));
  }, [serviceInstances, regions])

  const [partitionToRegions, setPartitionToRegions] = useState<OneLevelLookup<string[]>>(
    mapPartitionToRegions(filterRegionsByAirportCodes(regions, completedRegions))
  )

  useEffect(() => {
    const regionsToConsider = filterRegionsByAirportCodes(regions, completedRegions)
    setPartitionToRegions(mapPartitionToRegions(regionsToConsider))
  }, [regions, completedRegions])

  if (userAbilities.canRead) {
    if (planSwr.error || regionListSwr.error) {
      return (
        <Flash
          type="error"
          header="Data Fetching Error"
          content="We are having an issue getting this data,
          please try checking the spelling of the ripname in the url
          or first onboarding to RIP"
        />
      );
    }

    return (
      <SingleServiceFeatureContainer
        serviceName={serviceName}
        completedRegions={completedRegions}
        displayName={displayName}
        userAbilities={userAbilities}
        partitionToRegions={partitionToRegions}
      />
    );
  }
  return <NoAccessError />
}
