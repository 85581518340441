import * as React from "react";
import { Icon, Tooltip } from "@amzn/awsui-components-react";

export function RmsProjectedDateColumnHeader() {
  return (
    <React.Fragment>
      RMS Projected GA Date{" "}
      <Tooltip text={"This Projected Date will override your Estimated Date in the near future."}>
        <Icon size="normal" variant="normal" name="status-info" />
      </Tooltip>
    </React.Fragment>
  )
}
