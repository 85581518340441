import { LaunchVO } from "../../../../models/vos/LaunchVO";
import { questionnaireFields, requiredFieldsForInProgress } from "./LaunchDraftBanner.constants";

export const formulateEndOfDraftAlertString = (launch: LaunchVO) => {
  let fields = ""
  requiredFieldsForInProgress.forEach((field) => {
    if(questionnaireFields.includes(field)){
      if ((!launch.questionnaire[field]) && (launch.questionnaire[field] !== false)) {
        fields += `, ${field}`;
      }
    }
    else if ((!launch[field as keyof typeof launch]) && (launch[field as keyof typeof launch] !== false)) {
      fields += `, ${field}`
    }
  })
  fields += "."
  return fields;
}
