import styled, { css } from "styled-components";


interface IFixedContainerProps {
  width:     string;   // We expect people to pass in width with unit (such as px, rem, %)
  height?:   string;   // Height is optional
  centered?: boolean;  // Optional.  If true, centers horizontally and vertically
}


/*
 * Generic <div> with fixed size based on user arguments.
 * Use this when you want to avoid React and the browser resizing things, potentially
 * changing the placement of things.  Pass the width and height to fix as STRINGS:
 * Values like "150px" or "6em".
 * Example usage:
 *        <FixedContainer width="150px" height="120px">
 *            <Spinner />
 *        </FixedContainer>
 *
 * CAREFUL!  Don't just pass integers assuming they're pixels or points.  Be specific
 *           and pass in STRINGS.
 */
export const FixedContainer = styled("div")<IFixedContainerProps>`
  width:  ${ (props) => props.width };
  height: ${ (props) => props.height ?? "auto" };
  ${ (props) => (props.centered ?? false) && css`
                  display: flex;
                  align-items: center;
                  justify-content: center;
                `
  }
`;
