import * as React from "react";
import { FunctionComponent, useCallback, useState } from "react";
import { SingleServiceResourceParityContainerProps } from "./SingleServiceResourceParityContainer.types";
import { Optional } from "../../../models/types/Optional";
import { setFeature } from "@amzn/api-parity-react-component/lib/feature/featureToggle";
import { DataLoaderContainer } from "../SingleServiceApiParity/SingleServiceApiParity.style";
import { IResourceParityBootstrapInput } from "@amzn/api-parity-react-component/lib/models/ResourceParityBootstrapInput";
import { ResourceParityLoaderContainer } from "../../ResourceParityLoaderContainer/ResourceParityLoaderContainer";
import { SingleServiceResourceParity } from "../../../components/SingleService/SingleServiceResourceParity/SingleServiceResourceParity";

export const SingleServiceResourceParityContainer: FunctionComponent<SingleServiceResourceParityContainerProps> = (props) => {
  const [data, setData] = useState<Optional<IResourceParityBootstrapInput>>();

  setFeature({
    commenting: false
  });

  const loader_onDataLoaded = useCallback((rawData: IResourceParityBootstrapInput) => {
    setData(rawData);
  }, []);

  if (!data) {
    return <DataLoaderContainer>
      <ResourceParityLoaderContainer onDataLoaded={loader_onDataLoaded} />
    </DataLoaderContainer>;
  }

  return (
    <SingleServiceResourceParity constraintHeight={props.constraintHeight} data={data} serviceId={props.serviceId} />
  );
};
