import validator from "validator";
import moment from "moment";
import { IReactHookFormValidation } from "../common/FormTypes";
import { aliasValidation } from "../ServiceContacts/EnterServiceContact/BulkEditServiceContactWizard/BulkEditServiceContactWizardConstants";

export const REQUIRED_ERROR_MESSAGE: string = "Required"
export const INVALID_ALIAS_REGEX_ERROR_MESSAGE: string = "Invalid character. Aliases can only contain lowercase letters 'a' through 'z'.";
export const ALIAS_EXCEEDS_MAX_LENGTH_ERROR_MESSAGE: string = "Exceeds max length. Aliases can be up to eight characters long."
export const INVALID_LINK_ERROR_MESSAGE: string = "The link must either begin with http:// or https:// and must not contain any whitespace"
export const INVALID_CHAR_REGEX_ERROR_MESSAGE: string = "Invalid character. Entry must contain only letters, numbers, .-_+/ punctuation, and cannot be only spaces.";
export const INVALID_DATE_ERROR_MESSAGE: string = "Invalid date. Expected date format YYYY/MM/DD";
export const INVALID_TAGS_ERROR_MESSAGE: string = "Invalid tags. Must be a comma-separated list of alphanumeric strings";
export const PAST_DATE_ERROR_MESSAGE: string = "Date cannot be in the past. Enter a future date"
export const PAST_DATE_EDIT_ERROR_MESSAGE: string = "Date can be up to two weeks in the past. Enter a future date"
export const INVALID_DESC_REGEX_ERROR_MESSAGE: string = "Invalid character. Entry must contain only letters, numbers, and standard punctuation";
export const COMMA_SEPERATED_REGEX: RegExp = /^([a-zA-Z0-9]+)(,\s*[a-zA-Z0-9]+)*$/;
export const VALID_CHAR_REGEX_PATTERN: RegExp = /^[A-Za-z0-9._+/ -]+$/;
export const VALID_DESCRIPTION_REGEX_PATTERN: RegExp = /(^[A-Za-zÀ-ÖØ-öø-ÿ0-9\\!.,#@$%^&*()\-_+=[\]{}|/<>;:"'`~ \n\t\r]*)$/
export const VALID_DATE_REGEX: RegExp = /^\d{4}([-/])(0[1-9]|1[0-2])([-/])(0[1-9]|[12][0-9]|3[01])$/
export const VALID_LINK_REGEX: RegExp = /^https?:\/\/[^\s?]+(\?.*)?$/
export function isDateWithinWeeksPrior(value: Date, weeks: number) {
  const today = moment().set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
  return today.subtract(weeks, "weeks").set({ hour: 0, minute: 0,second: 0, millisecond: 0 }).isSameOrBefore(value)
}

export const VALID_CHAR_REGEX: IReactHookFormValidation = {
  pattern: {
    value: VALID_CHAR_REGEX_PATTERN,
    message: INVALID_CHAR_REGEX_ERROR_MESSAGE,
  }
};

export const VALID_DESCRIPTION_REGEX: IReactHookFormValidation = {
  pattern: {
    value: VALID_DESCRIPTION_REGEX_PATTERN,
    message: INVALID_DESC_REGEX_ERROR_MESSAGE,
  }
};

export const REQUIRED_AND_VALID_CHAR_REGEX: IReactHookFormValidation = {
  required: REQUIRED_ERROR_MESSAGE,
  pattern: {
    value: VALID_CHAR_REGEX_PATTERN,
    message: INVALID_CHAR_REGEX_ERROR_MESSAGE,
  }
};

export const REQUIRED_AND_VALID_DESCRIPTION_REGEX: IReactHookFormValidation = {
  required: REQUIRED_ERROR_MESSAGE,
  pattern: {
    value: VALID_DESCRIPTION_REGEX_PATTERN,
    message: INVALID_DESC_REGEX_ERROR_MESSAGE,
  }
};

export const REQUIRED: IReactHookFormValidation = {
  required: REQUIRED_ERROR_MESSAGE,
};


export const VALID_ALIAS_CHAR_REGEX_AND_MAXLENGTH: IReactHookFormValidation = {
  maxLength: {
    value: aliasValidation.maxLength,
    message: ALIAS_EXCEEDS_MAX_LENGTH_ERROR_MESSAGE
  },
  pattern: {
    value: aliasValidation.pattern,
    message: INVALID_ALIAS_REGEX_ERROR_MESSAGE
  }
};

export const VALID_LINK: IReactHookFormValidation = {
  pattern: {
    value: VALID_LINK_REGEX,
    message: INVALID_LINK_ERROR_MESSAGE
  },
  validate: {
    validUrl: url => !!!url || url === "" || validator.isURL(url?.replace(" ", "+")) || "Invalid Link"
  }
};

export const VALID_DATE: IReactHookFormValidation = {
  pattern: {
    value: VALID_DATE_REGEX,
    message: INVALID_DATE_ERROR_MESSAGE
  }
};

export const VALID_TAGS: IReactHookFormValidation = {
  pattern: {
    value: COMMA_SEPERATED_REGEX,
    message: INVALID_TAGS_ERROR_MESSAGE
  }
};

export const VALID_DATE_NOT_PAST_NOR_WEEKEND: IReactHookFormValidation = {
  pattern: {
    value: VALID_DATE_REGEX,
    message: INVALID_DATE_ERROR_MESSAGE
  },
  validate: {
    dateInRange: value => isDateWithinWeeksPrior(value, 0) || PAST_DATE_ERROR_MESSAGE
  }
};

export const VALID_DATE_NOT_PAST_TWO_WEEKS_NOR_WEEKEND: IReactHookFormValidation = {
  pattern: {
    value: VALID_DATE_REGEX,
    message: INVALID_DATE_ERROR_MESSAGE
  },
  validate: {
    dateInRange: value => isDateWithinWeeksPrior(value, 2) || PAST_DATE_EDIT_ERROR_MESSAGE
  }
};

