import * as React from "react";
import { FunctionComponent } from "react";
import { SpaceBetween } from "@amzn/awsui-components-react-v3";
import styled from "styled-components";

const ButtonBarLayout = styled(SpaceBetween)`
  align-items: center;
`;

export const ButtonBar: FunctionComponent = (props) => {
  return (
    <ButtonBarLayout direction="horizontal" size="xs">{props.children}</ButtonBarLayout>
  );
};
