import React from "react"
import { AttentionTip } from "@amzn/api-parity-react-component/lib/components/status-grid/attention-tip/AttentionTip";
import { Icon } from "@amzn/awsui-components-react-v3";
import { ContactsType, ServiceContactsVO } from "../../../models/vos/ServiceContactsVO";                                
import { Optional } from "@amzn/api-parity-react-component/lib/models/Nullable";

export const nonripNoLeadershipChainWarning = "If this contact was recently added please wait 24 hours for the leadership chain to propogate.  Otherwise, the CTI provided is invalid, please update.";
export const ripCtiButNoLeadershipChainWarning = "This CTI is invalid or no longer exists, please update the CTI in RMS";
export const ripNoLeadershipChainOrCtiWarning = "If this contact was recently added please wait 24 hours for the leadership chain to propogate.  Otherwise, please associate a CTI with this service in RMS."

export function serviceContactWarningMessage(item: ServiceContactsVO): Optional<string> {
  if (item.cti && item.leadershipChain) {
    return undefined
  }
  else if (item.cti) {
    return item.contactsType === ContactsType.Rip ? ripCtiButNoLeadershipChainWarning : nonripNoLeadershipChainWarning;
  }
  else {
    return ripNoLeadershipChainOrCtiWarning
  }
}

export function serviceContactWarning(item: ServiceContactsVO): Optional<JSX.Element> {
  const message = serviceContactWarningMessage(item);
  return message ? <AttentionTip tooltip={message} icon={<Icon variant={"subtle"} name={"status-warning"}/>}/> : undefined
}
