export const gaRegionId = "ARN";
export const serviceRipId = "recon-integ";
export const serviceRipIdCategoryChange = "recon-integ-2";
export const bcoChangeRegionId = "ALE";
export const removeDateRegionId = "BAH";

export function generateFakeSim(): string {
  const simId: string = (Math.round(Math.random() * 20000) + 1000000).toString();
  return `https://i.amazon.com/issues/RECON-TEST-${simId}`
}

export function generateFakeNote(): string {
  const trailing: string = Math.round(Math.random() * 30).toString();
  const time: string = new Date().getTime().toString();
  // This will generate a string that looks like Notes ##########-##
  // String can be used to locate the records in DDB or logs
  return `Notes ${time}-${trailing}`;
}

const MS_IN_DAY = 3600 * 1000 * 24;
export function generateFutureDate(): Date {
  const daysInMs: number = Math.round(Math.random() * 90 + 1) * MS_IN_DAY;
  return new Date(Date.now() + daysInMs);
}
