import * as React from "react";
import { useEffect, useState } from "react";
import { ServiceInRegionHistoryTypes } from "./ServiceInRegionHistory.types";
import { Table, TablePagination, TableSorting } from "@amzn/awsui-components-react";
import { sortUpdatedDate } from "../../utils/tableUtils";
import { getColumnDefinitions, getRelevantHistoryVersions } from "./ServiceInRegionHistoryUtil";
import { IServiceInRegionHistory } from "../../daos/types/IServiceInRegionHistory";
import { Optional } from "../../models/types/Optional";

export const ServiceInRegionHistoryTestId = {
  table: "ServiceInRegionHistoryListTable-table"
}

const sortableColumns = [
  {
    id: "updated",
    field: "updated",
    comparator: sortUpdatedDate,
  },
];

const pageSize = 25;

export const ServiceInRegionHistory: React.FunctionComponent<ServiceInRegionHistoryTypes> = (props) => {
  const { history, userAbilities } = props;
  const [ columnDefinitions, columnDefinitionsSet ] = useState<Table.ColumnDefinition<IServiceInRegionHistory>[]>([]);
  const [ pagination, paginationSet ] = useState<Optional<JSX.Element>>(undefined);
  const [ relevantHistory, relevantHistorySet ] = useState<IServiceInRegionHistory[]>([]);

  useEffect(() => {
    const relevant: IServiceInRegionHistory[] = getRelevantHistoryVersions(history);
    relevantHistorySet(relevant);

    const paginationDisplay: Optional<JSX.Element> = (relevant.length > pageSize) ? <TablePagination pageSize={pageSize} /> : undefined;
    paginationSet(paginationDisplay);
  }, [history]);

  useEffect(() => {
    columnDefinitionsSet(getColumnDefinitions(userAbilities));
  }, [userAbilities]);

  return (
    <Table<IServiceInRegionHistory>
      data-testid={ServiceInRegionHistoryTestId.table}
      loadingText="Loading resources"
      columnDefinitions={columnDefinitions}
      items={relevantHistory}
      header={<h2> Previous Delivery Dates (History) </h2>}
      empty={
        <div className="awsui-util-t-c">
          <div className="awsui-util-pt-s awsui-util-mb-xs">
            <b>No previous delivery dates</b>
          </div>
          <p className="awsui-util-mb-s">No history to display.</p>
        </div>
      }
    >
      <TableSorting
        sortingColumn="updated"
        sortingDescending
        sortableColumns={sortableColumns}
      />
      {pagination}
    </Table>
  );
};

export default ServiceInRegionHistory;
