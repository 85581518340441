import React, { useState } from "react";
import RegionsTable from "../common/TableComponents/RegionsTable";
import { Toggle } from "@amzn/awsui-components-react";
import { getColumnDefinitions } from "./ParityColumnDefinitions"
import { getContentSelectorOptions } from "./ParityContentSelectorOptions";
import {
  defaultTableConfig,
  sortCategory,
} from "../../utils/tableUtils";
import { CATEGORY_FIELD } from "../../constants";
import { ParityTableStyleWrapper } from "./ParityTableStyleWrapper";
import { getNonEmptyProperties } from "../../utils/collectionUtil";
import { ParityTableCSVExport } from "./ParityTableExport/ParityTableCSVExport";
import { ButtonBar } from "../common/ButtonBar/ButtonBar";

export const ParityListTableTestId = {
  table: "ParityListTable-table"
}

const SORTABLE_COLUMNS = [
  { id: "nameSortable", field: "nameSortable" },
  { id: "gm", field: "gm" },
  { id: "sTeamDirect", field: "sTeamDirect" },
  { id: CATEGORY_FIELD, field: CATEGORY_FIELD, comparator: sortCategory },
];

const getFilteringOptions = items => [
  {
    groupValuesLabel: "Service Names (Sortable)",
    propertyKey: "nameSortable",
    propertyLabel: "Service Name (Sortable)",
    values: getNonEmptyProperties(items, (item) => item.nameSortable),
  },
  {
    groupValuesLabel: "Service Names (Long)",
    propertyKey: "nameLong",
    propertyLabel: "Service Name (Long)",
    values: getNonEmptyProperties(items, (item) => item.nameLong),
  },
  {
    groupValuesLabel: "RIP Names",
    propertyKey: "nameRip",
    propertyLabel: "RIP Name",
    values: getNonEmptyProperties(items, (item) => item.nameRip),
  },
  {
    groupValuesLabel: "General Managers",
    propertyKey: "gm",
    propertyLabel: "General Manager",
    values: getNonEmptyProperties(items, (item) => item.gm),
  },
  {
    groupValuesLabel: "S-Team Directs",
    propertyKey: "sTeamDirect",
    propertyLabel: "S-Team Direct",
    values: getNonEmptyProperties(items, (item) => item.sTeamDirect),
  },
  {
    groupValuesLabel: "Categories",
    propertyKey: CATEGORY_FIELD,
    propertyLabel: "Category",
    values: getNonEmptyProperties(items, (item) => item[CATEGORY_FIELD]),
  }
];

const PAGINATION_OPTIONS = {
  pageSize: 50
};

const PAGE_SIZE_SELECTOR_OPTIONS = {
  title: "Page size",
  options: [
    { value: 50, label: "50 items" },
    { value: 100, label: "100 items" },
    { value: 200, label: "200 items" }
  ]
};


const getTableConfig = (items, loading, header, columnDefinitions, filteringOptions, tableSelection, contentSelectorOptions) => {
  return defaultTableConfig({
    features: [
      "TablePropertyFiltering",
      "TableSorting",
      "TablePreferences",
      "TableWrapLines",
      "TableSelection",
      "TablePagination",
      "TablePageSizeSelector",
      "TableContentSelector",
    ],
    variant: "default",
    columnDefinitions,
    header,
    items,
    loading,
    filteringOptions,
    tableSelection,
    paginationOptions: PAGINATION_OPTIONS,
    pageSizeSelectorOptions: PAGE_SIZE_SELECTOR_OPTIONS,
    sortableColumns: SORTABLE_COLUMNS,
    sortingColumn: CATEGORY_FIELD,
    contentSelectorOptions
  });
};


const ParityListTable = ({ onExport, canExport, items = [], regions = [], loading = true, onHideFullParityToggle, hideFullParityToggle }) => {
  const [, setSelected] = useState([]);

  const header = RegionsTable.getTableHeader({
    primary: "Parity",
    secondary: `${items.length}`,
    actionButtons: (
      <div className="awsui-util-action-stripe-group">
        <ButtonBar>
          <Toggle
            label="Hide full parity services"
            onChange={onHideFullParityToggle}
            checked={hideFullParityToggle}
          />
          {canExport && <ParityTableCSVExport onExport={onExport}/>}
        </ButtonBar>
      </div>
    ),
  });

  const handleSelectionChange = ({ detail: { selectedItems } }) => {
    setSelected(selectedItems);
  };

  const tableSelection = {
    selectionType: "single",
    trackBy: "nameSortable",
    onSelectionChange: handleSelectionChange
  };

  const columnDefinitions = getColumnDefinitions(regions);
  const contentSelectorOptions = getContentSelectorOptions(regions);
  const filteringOpts = getFilteringOptions(items);
  const tableConfig = getTableConfig(items, loading, header, columnDefinitions, filteringOpts, tableSelection, contentSelectorOptions);
  return (
    <ParityTableStyleWrapper>
      <RegionsTable
        testId={ParityListTableTestId.table}
        {...tableConfig}
      />
    </ParityTableStyleWrapper>
  );
};

export default ParityListTable;
