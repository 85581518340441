import React, { useState } from "react";
import _ from "lodash";
import useOnFollow from "../../hooks/useOnFollow";
import BreadcrumbGroupWithMargins from "../common/StyledComponents/BaseBreadcrumbGroupWithMargins";
import PubSub from "pubsub-js";

export const stringListToCrumbs = (strList) => {
  if (Array.isArray(strList)) {
    return strList.map(str => {
      // Java and Lodash don't have a "split on just the first"
      const firstEquals = str.indexOf("=")
      if (firstEquals < 0) // not found
        return { text: str }; // no "href" attribute
      else
        return {
          text: str.substring(0, firstEquals),
          href: str.substring(firstEquals + 1)
        };
    });
  }
  else {
    return [];
  }
};


const Breadcrumbs = () => {
  const onFollow = useOnFollow();
  const [subs, setSubs] = useState(null);
  const [breadcrumbs, setBreadcrumbs] = useState([]);

  const handleMessage = (topic, data) => {
    if (data === null ) // Special case: take up no UI space at all
      setBreadcrumbs(null);
    else if (_.isEmpty(data))
      setBreadcrumbs([ { text: "...", href: "#" } ]);
    else {
      const home = [ { text: "Home", href: "/" } ];
      const crumbs = stringListToCrumbs(data);
      setBreadcrumbs(home.concat(crumbs));
    }
  };

  if (!subs)
    setSubs(PubSub.subscribe("BREADCRUMBS", handleMessage));

  if (breadcrumbs)
    return (
      <BreadcrumbGroupWithMargins
        items={breadcrumbs}
        onFollow={onFollow}
      />
    );
  else
    return (<></>);
};


export default Breadcrumbs;
