import * as React from "react";
import styled from "styled-components";
import Snowfall from "react-snowfall";

const SnowContainer = styled("div")`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
`;

export const Snow: React.FunctionComponent<{}> = () => {
  return (
    <SnowContainer>
      <Snowfall />
    </SnowContainer>
  )
}
