import { FeatureTreeItem } from "../models/FeatureTreeItem";
import { AwsServiceVO, RegionCollectionView } from "@amzn/api-parity-react-component";
import { Optional } from "../models/types/Optional";
import memoize from "micro-memoize";
import { FeatureVO } from "../models/vos";
import { isService } from "./featureUtil";
import { ServiceInRegionRollupStatusCollectionView } from "../models/collections";
import { ParityRollupStatistics } from "../models/ParityRollupStatistics";
import { accumulateStatistics } from "./parityRollupStatisticsUtil";

/**
 * If the tree item is a service or a service as feature, return the service
 * otherwise return nothing
 * @param item
 */
function getFeatureTreeItemServiceImpl(item: FeatureTreeItem): Optional<AwsServiceVO> {
  if (isService(item.entity)) {
    return item.entity as AwsServiceVO;
  }

  return (item.entity as FeatureVO).markedAsFrom;
}

export const getFeatureTreeItemService = memoize(getFeatureTreeItemServiceImpl, {
  maxSize: 15000,
})

function getGmImpl(item: FeatureTreeItem, defaultValue?: string): Optional<string> {
  const service: Optional<AwsServiceVO> = getFeatureTreeItemService(item);
  return service?.contacts?.gm ?? defaultValue;
}

export const getGm = memoize(getGmImpl, {
  maxSize: 15000,
});

function getVpImpl(item: FeatureTreeItem, defaultValue?: string): Optional<string> {
  const service: Optional<AwsServiceVO> = getFeatureTreeItemService(item);
  return service?.contacts?.vp ?? defaultValue;
}

export const getVp = memoize(getVpImpl, {
  maxSize: 15000,
});


export interface IFeatureParityStatistics {
  serviceCount: number;
  featureCount: number;
  overallParityRatio: number;
}

export function getFeatureServiceCountStatistics(
  items: readonly FeatureTreeItem[],
  featureParityRollupByService: ServiceInRegionRollupStatusCollectionView,
  regions: RegionCollectionView,
): IFeatureParityStatistics {
  const stats: IFeatureParityStatistics = {
    serviceCount: 0,
    featureCount: 0,
    overallParityRatio: 0,
  };

  function dfs(item: FeatureTreeItem) {
    const entity = item.entity;
    if (entity instanceof FeatureVO) {
      stats.featureCount++;
    } else {
      stats.serviceCount++;
    }
    if (item.childItems && item.childItems.length) {
      item.childItems.forEach((child) => dfs(child as FeatureTreeItem));
    }
  }

  const totalStats: ParityRollupStatistics = new ParityRollupStatistics();
  const emptyStats: ParityRollupStatistics = new ParityRollupStatistics();
  items.forEach((item) => {
    dfs(item);

    const service: Optional<AwsServiceVO> = getFeatureTreeItemService(item);
    if (service) {
      regions.items.forEach((region) => {
        const byRegionStats = featureParityRollupByService.byServiceRegion(service, region)?.statistics ?? emptyStats;
        accumulateStatistics(byRegionStats, totalStats);
      });
    }
  });
  stats.overallParityRatio = (totalStats.expectedTotal > 0) ? totalStats.ga / totalStats.expectedTotal : 0;
  return stats;
}

