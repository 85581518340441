import * as React from "react";
import { FunctionComponent } from "react";
import useSWR from "swr";
import { getSwrConfig } from "../../daos/swrUtil";
import "react-pivottable/pivottable.css";
import { transformPivot } from "./transformPivot";
import { PivotDao } from "../../daos/PivotDao";
import { Loading } from "../common/Loading";
import { PivotTableContainer } from "./PivotTableContainer"


export const PivotTableLoader: FunctionComponent<{}> = () : JSX.Element => {
  const { data: allPlansData } = useSWR("plans", async () => {
    return new PivotDao().getPlansData();
  }, getSwrConfig());

  const { data: allRegionsData } = useSWR("regions", async () => {
    return new PivotDao().getRegionsData();
  }, getSwrConfig());

  const { data: allServicesData } = useSWR("services", async () => {
    return new PivotDao().getServicesData();
  }, getSwrConfig());

  const { data: allContactsData } = useSWR("contacts", async () => {
    return new PivotDao().getContactsData();
  }, getSwrConfig());

  const { data: allHistoryData } = useSWR("history", async () => {
    return new PivotDao().getHistoryData();
  }, getSwrConfig());

  const pivotData = transformPivot(allRegionsData, allServicesData, allPlansData, allContactsData, allHistoryData);

  const loadingData = ((allPlansData && allPlansData.length > 0) && (allRegionsData && allRegionsData.length > 0) && (allServicesData && allServicesData.length > 0));

  return (
    <div>
      { loadingData ? <PivotTableContainer data={pivotData} /> : <Loading/> }
    </div>
  );
}

export default PivotTableLoader;
