import { getReconApiClient } from "./daoUtil";
import { FixedApiErrorMessage } from "./errors";
import { AxiosError } from "axios";


export const buildTaskNoSpaces = (buildTask: string): string => {
  return buildTask.split(" ").join("");
}


export class PivotDao {
  async getPlansData() {
    return await this.getData("/api/pivot/plans");
  }

  async getRegionsData() {
    return await this.getData("/api/regions");
  }

  async getServicesData() {
    return await this.getData("/api/services");
  }

  async getContactsData() {
    return await this.getData("/api/pivot/contacts");
  }

  async getHistoryData() {
    return await this.getData("/api/pivot/history");
  }

  async getData(apiURL){
    try {
      const client = getReconApiClient();
      const res = await client.get(apiURL);
      return res.data;
    } catch (exception) {
      const exceptionType = (exception as AxiosError).response?.data?.message
      if (exceptionType !== FixedApiErrorMessage.objectNotFound) {
        throw exception
      }
    }
  }
}
