import * as React from "react";
import { useCallback, useEffect, useState } from "react";
import {
  FeatureInRegionCommentModalProps,
  IFeatureInRegionCommentFormInput,
  LabelWithValue,
} from "./FeatureInRegionCommentModal.types";
import {
  Button,
  ColumnLayout,
  Flashbar,
  FormField,
  Modal,
  SegmentedControl,
  Select,
  Textarea
} from "@amzn/awsui-components-react-v3";
import { toShortDate } from "../../utils/dateUtil";
import { FeatureInRegionCommentVO, REASON_OPTIONS } from "../../models/vos/FeatureInRegionCommentVO";
import { Optional } from "../../models/types/Optional";
import { DialogFooterButtonBar } from "../../components/common/Dialog/DialogFooterButtonBar";
import { Controller, useForm } from "react-hook-form";
import { NonCancelableCustomEvent } from "@amzn/awsui-components-react-v3/uxdg";
import { BaseChangeDetail } from "@amzn/awsui-components-react-v3/polaris/input/interfaces";
import { PolarisMultiSelect } from "../../components/MultiSelect/PolarisMultiSelect/PolarisMultiSelect";
import ModalHeader from "../../components/common/StyledComponents/ModalHeader";
import { ReportBugSimTemplateLink } from "../../components/common/SimLinks/ReportBugSimTemplateLink";
import {
  getCommentsToSubmit,
  MAX_BULK_SELECT_FEATURES,
  VALIDATE_SELECTED_FEATURES
} from "./FeatureInRegionCommentUtil";

export const FeatureInRegionCommentModalTestId = {
  featureSelectFormField: "FeatureInRegionCommentModal-featureSelectFormField",
  featureSelect: "FeatureInRegionCommentModal-featureSelectInput",
  regionSelectFormField: "FeatureInRegionCommentModal-regionSelectFormField",
  regionSelect: "FeatureInRegionCommentModal-regionSelectInput",
  reasonSelectFormField: "FeatureInRegionCommentModal-reasonSelectFormField",
  reasonSelect: "FeatureInRegionCommentModal-reasonSelectInput",
  simFormField: "FeatureInRegionCommentModal-simFormField",
  simInput: "FeatureInRegionCommentModal-simInput",
  whatsNewFormField: "FeatureInRegionCommentModal-whatsNewFormField",
  whatsNewInput: "FeatureInRegionCommentModal-whatsNewInput",
  noteFormField: "FeatureInRegionCommentModal-noteFormField",
  noteInput: "FeatureInRegionCommentModal-noteInput",
  submit: "FeatureInRegionCommentModal-submit",
  resolve: "FeatureInRegionCommentModal-resolve",
  cancel: "FeatureInRegionCommentModal-cancel",
  flashBar: "FeatureInRegionCommentModal-flashBar",
  partitionSelect: "FeatureInRegionCommentModal-partitionSelect",
  individualPartitionPrefix: "FeatureInRegionCommentModal-individualPartitionPrefix:",
};


const STRING_TO_OPTION = (value: Optional<string>) => {
  return {
    "label": value,
    "value": value,
  }
}

const REGION_LABEL_FUNCTION = (region: string): string => region;

const SELECTOR_OPTIONS = REASON_OPTIONS.map(
  (reason) => STRING_TO_OPTION(reason)
)

const onReactHookFormTextChange = (onChange: (...event: any[]) => void) => {
  return (event: NonCancelableCustomEvent<BaseChangeDetail>) => onChange(event.detail.value)
}

const onReactHookFormSelectedChange = (onChange: (...event: any[]) => void) => {
  return (event: NonCancelableCustomEvent<any>) => onChange(event.detail.selectedOption)
}

const VALIDATE_NON_EMPTY_STRING = (str: string): boolean => !!str;
const VALIDATE_NON_EMPTY_ARRAY = (array: any[]): boolean => !!array.length;


const INVALID_FEATURE_SELECTION_MESSAGE = `Select between 1 and ${MAX_BULK_SELECT_FEATURES} features.`;

const NO_SELECTED_REGIONS_ERROR_MESSAGE = "At least one region must be selected.";

const setErrorFlashMessage = (setFlashMessage: React.Dispatch<any>) => {
  setFlashMessage([{
    header: <>
      {"Your request failed, please try again later. "}
      {ReportBugSimTemplateLink}
    </>,
    type: "error",
    dismissible: true,
    onDismiss: () => setFlashMessage([])
  }]);
}

export const FeatureInRegionCommentModal: React.FunctionComponent<FeatureInRegionCommentModalProps> = (props) => {
  let { action, onDismiss, onSubmit } = props;

  const comment = props.action === "edit" ? props?.comment : undefined;

  const { control, getValues, setValue, errors, trigger, formState, reset } = useForm<IFeatureInRegionCommentFormInput>({
    mode: "onSubmit",
    shouldUnregister: true
  })
  const [flashMessage, setFlashMessage] = React.useState<any>([]);

  useEffect(() => {
    setValue("reasonInput", comment ? STRING_TO_OPTION(comment.reason) : "")
    setValue("simInput", comment?.sim ?? "")
    setValue("whatsNewInput", comment?.whatsNewLink ?? "")
    setValue("noteInput", comment?.note ?? "")
    reset()
    setFlashMessage([])
  }, [props, comment, setValue, reset])

  const displayTime = toShortDate(comment?.timestamp)

  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const [isResolveLoading, setIsResolveLoading] = useState(false);

  const modal_onSubmit = useCallback(async (resolve: boolean = false) => {
    if (await trigger()) {
      const { simInput, noteInput, reasonInput, whatsNewInput } = getValues();
      let commentsToSubmit: FeatureInRegionCommentVO[] = [];
      if (props.action === "edit") {
        const { feature, region, service } = props.comment
        commentsToSubmit = [new FeatureInRegionCommentVO({
          feature,
          region,
          service,
          sim: simInput,
          note: noteInput,
          reason: reasonInput.value,
          whatsNewLink: whatsNewInput,
          resolved: resolve ? "resolved" : undefined,
        })]
      } else if (props.action === "add") {
        const { selectedRegionsInput, featureInput } = getValues();
        commentsToSubmit = getCommentsToSubmit(
          featureInput,
          selectedRegionsInput,
          props.service, simInput,
          noteInput,
          reasonInput.value,
          whatsNewInput,
        )
      }

      const setLoading = resolve ? setIsResolveLoading : setIsSubmitLoading;
      setLoading(true);

      try {
        await onSubmit(commentsToSubmit);
        onDismiss();
      } catch {
        setErrorFlashMessage(setFlashMessage);
      } finally {
        setLoading(false);
      }
    }
  }, [props, getValues, onDismiss, onSubmit, trigger])

  const modal_onResolve = useCallback(async () => {
    if (formState.isDirty) {
      await modal_onSubmit(true)
    } else {
      setIsResolveLoading(true);

      try {
        // @ts-ignore this button is only visible when props.action === "edit", so these props will exist
        await props.onResolve(props.comment);
        onDismiss();
      } catch {
        setErrorFlashMessage(setFlashMessage);
      } finally {
        setIsResolveLoading(false);
      }
    }
  }, [props, formState.isDirty, modal_onSubmit, onDismiss])

  const showSubmitButton = props.action !== "edit" || formState.isDirty;
  const cancelButtonConfiguration = showSubmitButton
    ? { variant: "normal", text: "Cancel" }
    : { variant: "primary", text: "Close" }


  const modalFooter = (
    <DialogFooterButtonBar>
      <Button
        // @ts-ignore
        variant={cancelButtonConfiguration.variant}
        onClick={onDismiss}
        disabled={isResolveLoading || isSubmitLoading}
        data-testid={FeatureInRegionCommentModalTestId.cancel}
      >
        {cancelButtonConfiguration.text}
      </Button>
      {
        props.action === "edit" && !props.comment.resolved &&
        <Button
          variant={"normal"}
          data-testid={FeatureInRegionCommentModalTestId.resolve}
          onClick={modal_onResolve}
          disabled={isSubmitLoading}
          loading={isResolveLoading}
        >
          {formState.isDirty ? "Resolve and Submit" : "Resolve"}
        </Button>
      }
      {
        showSubmitButton && <Button
          variant={"primary"}
          data-testid={FeatureInRegionCommentModalTestId.submit}
          onClick={() => modal_onSubmit()}
          disabled={isResolveLoading}
          loading={isSubmitLoading}
        >
          Submit
        </Button>
      }
    </DialogFooterButtonBar>
  );

  const modalHeaderText = props.action === "edit"
    ? `Comment for ${props.comment.feature} in ${props.comment.region}`
    : `Add New Feature Comments for ${props.serviceLongName}`;

  const modalHeader = (
    <ModalHeader>
      <Flashbar items={flashMessage} data-testid={FeatureInRegionCommentModalTestId.flashBar}/>
      <h3>{modalHeaderText}</h3>
      {props.action === "edit" && props.comment.resolved &&
      <h5 className="inline-header-subtext">{"This comment is resolved, but you can submit a new comment to reopen. Editing any of the fields will allow you to submit changes."}</h5>}
    </ModalHeader>
  );

  const onPartitionClick = useCallback((event, currentlySelected = []) => {
    const partition = event.detail.selectedId.split(FeatureInRegionCommentModalTestId.individualPartitionPrefix)[1]
    return Array.from(new Set(
        // @ts-ignore
        props.partitionToRegions.get(partition).concat(currentlySelected)
    ))
  }, [props])


  const partitionSelectItems = props.action === "add" ? Array.from(props.partitionToRegions.keys()).map(partition => {
    return {
      text: partition,
      id: FeatureInRegionCommentModalTestId.individualPartitionPrefix + partition,
    }
  }) : []

  return (
    <Modal
      header={modalHeader}
      footer={modalFooter}
      visible={action !== "noAction"}
      onDismiss={onDismiss}
      size="large"
    >

      { // Not using a variable for the props.action === "add" to get TS benefit
        props.action === "add" && <>
          <Controller
            name="featureInput"
            id="featureInput"
            control={control}
            defaultValue={[]}
            rules={{
              required: INVALID_FEATURE_SELECTION_MESSAGE,
              validate: VALIDATE_SELECTED_FEATURES,
            }}
            render={({ onChange, value }, { invalid }) =>
              <FormField
                // @ts-ignore
                errorText={(invalid && INVALID_FEATURE_SELECTION_MESSAGE)}
                data-testid={FeatureInRegionCommentModalTestId.featureSelectFormField}
                stretch={true}
                label={`Select up to ${MAX_BULK_SELECT_FEATURES} features:`}
              >
                <div data-testid={FeatureInRegionCommentModalTestId.featureSelect}>
                  <PolarisMultiSelect
                    idFunction={(feature: LabelWithValue) => feature.value}
                    labelFunction={(feature: LabelWithValue) => feature.label}
                    items={props.features}
                    selected={value}
                    onChange={onChange}
                    placeholder={`(Required) - Select up to ${MAX_BULK_SELECT_FEATURES} features`}
                  />
                </div>
              </FormField>
            }
          />
          <br/>
          <Controller
            name="selectedRegionsInput"
            id="selectedRegionsInput"
            control={control}
            defaultValue={[]}
            rules={{
              required: NO_SELECTED_REGIONS_ERROR_MESSAGE,
              validate: VALIDATE_NON_EMPTY_ARRAY,
            }}
            render={({ onChange, value }, { invalid }) =>
              <FormField
                // @ts-ignore
                errorText={invalid && NO_SELECTED_REGIONS_ERROR_MESSAGE}
                data-testid={FeatureInRegionCommentModalTestId.regionSelectFormField}
                stretch={true}
                label={"Select region(s):"}
              >
                <div data-testid={FeatureInRegionCommentModalTestId.regionSelect}>
                  <PolarisMultiSelect
                    idFunction={REGION_LABEL_FUNCTION}
                    labelFunction={REGION_LABEL_FUNCTION}
                    items={props.regions}
                    selected={value}
                    onChange={onChange}
                    placeholder={"(Required) - Select at least one region, you can click on a partition to select all of the regions in the partition"}
                  />
                </div>
                <br/>
                <div data-testid={FeatureInRegionCommentModalTestId.partitionSelect}>
                  <SegmentedControl
                    options={partitionSelectItems}
                    onChange={event => onChange(onPartitionClick(event, value))}
                    selectedId={null}
                  />
                </div>
              </FormField>
            }
          />
          <br/>
        </>
      }

      <Controller
        name="reasonInput"
        id="reasonInput"
        control={control}
        defaultValue={comment?.reason ? STRING_TO_OPTION(comment?.reason) : ""}
        rules={{
          required: "A reason is required.",
          validate: VALIDATE_NON_EMPTY_STRING,
        }}
        render={({ onChange, value }, { invalid }) =>
          <FormField
            // @ts-ignore
            errorText={(invalid && errors.reasonInput?.message)}
            data-testid={FeatureInRegionCommentModalTestId.reasonSelectFormField}
            stretch={true}
            label={"Reason for comment:"}
          >
            <div data-testid={FeatureInRegionCommentModalTestId.reasonSelect}>
              <Select
                options={SELECTOR_OPTIONS}
                selectedOption={value}
                selectedAriaLabel="Selected"
                ariaLabel={"Select the reason for this comment"}
                onChange={onReactHookFormSelectedChange(onChange)}
                filteringType="auto"
                placeholder={"(Required)"}
              />
            </div>
          </FormField>
        }
      />

      <br/>

      <Controller
        name="simInput"
        id="simInput"
        control={control}
        defaultValue={comment?.sim ?? ""}
        rules={{
          // TODO add a URL regex
        }}
        render={({ onChange, value }, { invalid }) =>
          <FormField
            errorText={(invalid && errors.simInput?.message)}
            data-testid={FeatureInRegionCommentModalTestId.simFormField}
            stretch={true}
            label={"SIM Link:"}
          >
            <div data-testid={FeatureInRegionCommentModalTestId.simInput}>
              <Textarea
                value={value}
                rows={1}
                onChange={onReactHookFormTextChange(onChange)}
                placeholder={"(Optional)"}
              />
            </div>
          </FormField>
        }
      />

      <br/>

      <Controller
        name="whatsNewInput"
        id="whatsNewInput"
        control={control}
        defaultValue={comment?.whatsNewLink ?? ""}
        rules={{
          // TODO add a URL regex
        }}
        render={({ onChange, value }, { invalid }) =>
          <FormField
            errorText={(invalid && errors.whatsNewInput?.message)}
            data-testid={FeatureInRegionCommentModalTestId.whatsNewFormField}
            stretch={true}
            label={"What's New Link:"}
          >
            <div data-testid={FeatureInRegionCommentModalTestId.whatsNewInput}>
              <Textarea
                value={value}
                rows={1}
                onChange={onReactHookFormTextChange(onChange)}
                placeholder={"(Optional) - https://aws.amazon.com/about-aws/whats-new/…"}
              />
            </div>
          </FormField>
        }
      />

      <br/>

      <Controller
        name="noteInput"
        id="noteInput"
        control={control}
        defaultValue={comment?.note ?? ""}
        rules={{
          required: "A note is required.",
          validate: VALIDATE_NON_EMPTY_STRING,
        }}
        render={({ onChange, value }, { invalid }) =>
          <FormField
            errorText={(invalid && errors.noteInput?.message)}
            data-testid={FeatureInRegionCommentModalTestId.noteFormField}
            stretch={true}
            label={"Note:"}
          >
            <div data-testid={FeatureInRegionCommentModalTestId.noteInput}>
              <Textarea
                value={value}
                rows={5}
                onChange={onReactHookFormTextChange(onChange)}
                placeholder={"(Required) - Addition details around launch status (ex: Awaiting Feature B of Service A; Development underway, ETA YY; Assess priority relative to … etc.)"}
              />
            </div>
          </FormField>
        }
      />

      <br/>

      {action === "edit" && <ColumnLayout columns={2}>
        <div>
          <h4>Author</h4>
          <div>{comment?.author}</div>
        </div>

        <div>
          <h4>Creation Date</h4>
          <div>{displayTime}</div>
        </div>

      </ColumnLayout>}

    </Modal>
  )
}
