import * as React from "react";
import { useCallback, useEffect, useRef, useState } from "react";
import moment from "moment";
import { Button } from "@amzn/awsui-components-react";
import EnterEstimatedDateModal from "./EnterEstimatedDateModal";
import { PermissionError } from "../common/PermissionError"
import { WriteAccessSimTemplateLink } from "../common/SimLinks/WriteAccessSimTemplateLink";
import { EnterEstimatedDateProps } from "./EnterEstimatedDate.types";
import { setTracking } from "../../utils/analyticUtil";

export const EnterEstimatedDateButtonTestId = {
  button: "EnterEstimatedDateButtonTestId-button"
}

export const EnterEstimatedDatePermissionErrorTestId = {
  modal: "EnterEstimatedDatePermissionError-modal",
  alert: "EnterEstimatedDatePermissionError-alert"
}

export const EnterEstimatedDate: React.FunctionComponent<EnterEstimatedDateProps> = (props: EnterEstimatedDateProps) => {
  const {
     nameRip,
     region,
     nameSortable,
     selected,
     handleSubmitChange,
     userAbilities
   } = props;

  const plan = selected;
  const [visible, setVisible] = useState<boolean>(false);
  const [modalKey, modalKeySet] = useState<number>(1);
  const [enterEstDatePermissionError, setEnterEstDatePermissionError] = useState<boolean>(false);
  const todaysDate = moment().startOf("day");

  const mounted = useRef(false);
  useEffect(() => {
    mounted.current = true; // Will set it to true on mount ...
    return () => { mounted.current = false; }; // ... and to false on unmount
  }, []);

  const showPermissionErrorModal = useCallback(() => {
    setEnterEstDatePermissionError(true);
  }, []);

  const hidePermissionErrorModal = useCallback(() => {
    setEnterEstDatePermissionError(false);
  }, [setEnterEstDatePermissionError])

  const showFormModal = useCallback(() => {
    modalKeySet(new Date().getTime());
    setVisible(true)
  }, []);

  const hideFormModal = useCallback(() => {
    if (mounted.current) {
      setVisible(false);
    }
  }, []);

  const showModal = useCallback(() => {
    userAbilities.canWrite ? showFormModal() : showPermissionErrorModal();
  },[userAbilities, showFormModal, showPermissionErrorModal]);

  const handleDismiss = useCallback(({ detail: { reason } }) => {
    hideFormModal();
    if (reason === "submit") {
      handleSubmitChange?.();
    }
  },[handleSubmitChange, hideFormModal]);

  return (
    <>
      <EnterEstimatedDateModal
        key={modalKey.toString()}
        selected={plan}
        region={region}
        nameSortable={nameSortable}
        nameRip={nameRip}
        todaysDate={todaysDate}
        userAbilities={userAbilities}
        visible={visible}

        handleDismiss={handleDismiss}
        onSubmit={props.onSubmit}
      />
      <PermissionError
        header="Access Denied"
        body={<>
          Need to have bindle permission for {nameRip} or need Recon Write permission to enter an estimated date,
          please use {WriteAccessSimTemplateLink} to request access to Recon Write permission.
        </>}
        visible={enterEstDatePermissionError}
        onDismiss={hidePermissionErrorModal}
        testIds={EnterEstimatedDatePermissionErrorTestId}
      />
      <Button
        {...setTracking("Enter Estimated Date")}
        variant="primary"
        onClick={showModal}
        disabled={!selected}
        data-testid={EnterEstimatedDateButtonTestId.button}
      >Enter Planned Launch Date</Button>
    </>
  );
};

export default EnterEstimatedDate;
