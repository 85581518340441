import moment from "moment";
import { saveAs } from "file-saver";
import { MIME_TYPE_DOCX } from "../constants";
import { b64toBlob } from "../utils/reportsUtils";
import { getReconApiClient } from "./daoUtil";
import { IGetServiceAvailabilityReportResponse } from "./types/IGetServiceAvailabilityReportResponse";

export class ServiceAvailabilityReportDao {

  /**
   * Get report specified by template type
   */
  async getServiceAvailabilityReport(templateType: string): Promise<IGetServiceAvailabilityReportResponse> {
    const client = getReconApiClient();
    const res = await client.get<IGetServiceAvailabilityReportResponse>(this.getServiceAvailabilityReportUrl(templateType));
    return res.data;
  }

  getServiceAvailabilityReportUrl(templateType: string): string {
    return (`/api/reports/serviceavailability?templateType=${encodeURIComponent(templateType)}`);
  }

  async handleServiceAvailabilityReport(templateType: string, selectedOption) {
    try {
      const data = await this.getServiceAvailabilityReport(templateType);
      const base64Docx = data?.base64Docx;
      if (base64Docx) {
        const blob = b64toBlob(base64Docx, MIME_TYPE_DOCX);
        const fileName = `${selectedOption.filenamePrefix} ${moment().format("DD MM YY HHmm")}.docx`;
        saveAs(blob, fileName);
      }
    } catch(error) {
      console.error("report error:", error);
    }
  };

}
