import * as React from "react";
import { FunctionComponent, useCallback, useEffect, useState } from "react";
import {
  Box,
  Button,
  CollectionPreferences,
  CollectionPreferencesProps,
  Pagination,
  PaginationProps,
  PropertyFilter,
  PropertyFilterProps,
  Table,
} from "@amzn/awsui-components-react-v3";
import { useCollection } from "@amzn/awsui-collection-hooks";
import { ProductsListProps, ProductTableVM } from "./ProductsList.types";
import {
  COLUMN_DEFINITIONS,
  DEFAULT_COLLECTION_PREFERENCES,
  DEFAULT_I18N_STRINGS,
  DEFAULT_TABLE_PREFERENCES,
  FILTERING_PROPERTIES,
} from "./ProductsListProperties";
import { getDataTestIdProp } from "../../utils/testUtil";
import { NonCancelableCustomEvent } from "@amzn/awsui-components-react-v3/polaris/internal/events";
import { ProductVOToProductTableVM } from "./ProductsListUtil";

export const ProductListTestIdSuffixes = {
  table: "-table",
  emptyTableDisplay: "-emptyTableDisplay",
  tableHeader: "-tableHeader",
  propertyFilter: "-propertyFilter",
  pagination: "-pagination",
  collectionPreferences: "-collectionPreferences",
  createButton: "-createButton",
}

const EmptyTableDisplay = (props) => (
  <Box
    textAlign="center"
    color="inherit"
    {...getDataTestIdProp(props, ProductListTestIdSuffixes.emptyTableDisplay)}
  >
    <b>No resources</b>
    <Box
      padding={{ bottom: "s" }}
      variant="p"
      color="inherit"
    >
      No resources to display.
    </Box>
  </Box>
);

const EmptyState = ({ title, subtitle, action }) => (
  <Box textAlign="center" color="inherit">
    <Box variant="strong" textAlign="center" color="inherit">
      {title}
    </Box>
    <Box variant="p" padding={{ bottom: "s" }} color="inherit">
      {subtitle}
    </Box>
    {action}
  </Box>
);

export const ProductsList: FunctionComponent<ProductsListProps> = (props) => {
  const {
    loading = true,
    preferences: propPreferences = DEFAULT_TABLE_PREFERENCES,
    page = 1,
    filter,
    header,
    onPaginationChange,
    onPreferenceChange,
    onFilterChange,
  } = props;
  const [preferences, setPreferences] = useState<any>(propPreferences);
  const [productVMs, setProductVMs] = useState<ProductTableVM[]>(ProductVOToProductTableVM(props.items));
  const { items, actions, filteredItemsCount, collectionProps, propertyFilterProps, paginationProps } = useCollection(
    productVMs,
    {
      propertyFiltering: {
        filteringProperties: FILTERING_PROPERTIES,
        defaultQuery: filter,
        empty: (
          <EmptyState
            title="No items"
            subtitle="No items to display."
            action={<></>}
          />
        ),
        noMatch: (
          <EmptyState
            title="No matches"
            subtitle="No matching items found."
            action={<Button onClick={() => actions.setFiltering("")}>Clear filter</Button>}
          />
        )
      },
      pagination: {
        pageSize: preferences.pageSize,
        defaultPage: page
      },
      sorting: {
        defaultState: {
          sortingColumn: COLUMN_DEFINITIONS[0], // find a better way to define this
          isDescending: false
        }
      },
      selection: {},
    }
  );

  useEffect(() => {
    setProductVMs(ProductVOToProductTableVM(props.items));
  }, [props.items]);

  const preferenceOnChange = useCallback((event: NonCancelableCustomEvent<CollectionPreferencesProps.Preferences>) => {
    setPreferences(event.detail);
    onPreferenceChange?.(event);
  }, [onPreferenceChange]);

  const paginationOnChange = useCallback((event: NonCancelableCustomEvent<PaginationProps.ChangeDetail>) => {
    onPaginationChange?.(event);
    paginationProps.onChange(event)
  }, [onPaginationChange, paginationProps]);

  const filterOnChange = useCallback((event: NonCancelableCustomEvent<PropertyFilterProps.Query>) => {
    onFilterChange?.(event);
    propertyFilterProps.onChange(event);
  }, [onFilterChange, propertyFilterProps]);

  return (
    <Table
      {...collectionProps}
      trackBy="id"
      columnDefinitions={COLUMN_DEFINITIONS}
      items={items}
      loading={loading && (items?.length === 0 || !items)}
      loadingText="Loading resources"
      visibleColumns={preferences.visibleContent}
      empty={<EmptyTableDisplay {...props} />}
      filter={
        <PropertyFilter
          i18nStrings={DEFAULT_I18N_STRINGS}
          virtualScroll // improve performance significantly
          countText={`${filteredItemsCount} matches`}
          {...propertyFilterProps}
          onChange={filterOnChange}
          {...getDataTestIdProp(props, ProductListTestIdSuffixes.propertyFilter)}
        />
      }
      header={header}
      pagination={
        <Pagination
          {...paginationProps}
          ariaLabels={{
            nextPageLabel: "Next page",
            previousPageLabel: "Previous page",
            pageLabel: pageNumber =>
              `Page ${pageNumber} of all pages`
          }}
          onChange={paginationOnChange}
          {...getDataTestIdProp(props, ProductListTestIdSuffixes.pagination)}
        />
      }
      preferences={
        <CollectionPreferences
          {...DEFAULT_COLLECTION_PREFERENCES}
          preferences={preferences}
          onConfirm={preferenceOnChange}
          {...getDataTestIdProp(props, ProductListTestIdSuffixes.collectionPreferences)}
        />
      }
      {...getDataTestIdProp(props, ProductListTestIdSuffixes.table)}
    />
  );
};
